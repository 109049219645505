/*
 * TODO Implement Search Page for horses/matings
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function MatingsSearch() {
  return <PageHeader title="Horses::Matings Search" />
}

export default MatingsSearch
