import { cartesian } from '@/utils'
import { startOfMonth } from 'date-fns'

import { buildPeriod, parsePeriod, serializePeriod } from './period'
import { parseScenario, serializeScenario } from './scenario'
import { CashFlowColumn, Period } from './types'
import { formatDate, parseDate } from './utils'

export interface ColumnRenameProps {
  scenarioNames: string[]
  monthEndDateStrings: string[]
  availableNames?: string[]
}

export const columnRenames = ({
  scenarioNames,
  monthEndDateStrings,
  availableNames,
}: ColumnRenameProps): Record<string, string> => {
  const combinations = cartesian(scenarioNames, monthEndDateStrings)

  return combinations.reduce((acc, combo) => {
    const currentName = combo.map((c) => String(c)).join('__')
    const [scenarioName, monthDate] = combo

    // Skip columns that don't exist
    if (availableNames && !availableNames.includes(currentName)) return acc

    const monthEndDate = parseDate(monthDate, 'yyyy-MM-dd')

    const column = buildCashFlowColumn(scenarioName, 'Month', startOfMonth(monthEndDate))

    return {
      ...acc,
      [currentName]: serializeColumn(column),
    }
  }, {})
}

/**
 * Helper to build a CashFlowColumn
 *
 */
export const buildCashFlowColumn = (
  scenarioName: string,
  periodType: Period['type'],
  startDate: Date,
): CashFlowColumn => {
  return {
    scenario: {
      type: scenarioName === 'Actual' ? 'Actual' : 'Budget',
      name: scenarioName,
    },
    period: buildPeriod({ type: periodType, startDate }),
    calculationType: 'Total',
  }
}

/**
 * Builds a unique columnId for a CashFlowColumn
 *
 * Does not yet support calculations that reference
 * other columns
 */
export const serializeColumn = (column: CashFlowColumn) =>
  [serializePeriod(column.period), serializeScenario(column.scenario), column.calculationType].join(
    '__',
  )

/**
 * Parse column from string
 */
export const parseColumn = (input: string): CashFlowColumn | never => {
  const [periodInput, scenarioInput, calculationType] = input.split('__')

  if (
    calculationType !== 'Total' &&
    calculationType !== 'Variance' &&
    calculationType !== 'Variance %'
  )
    throw new Error(`Cannot parse column '${input}'. Calculation type is invlalid.`)

  try {
    return {
      period: parsePeriod(periodInput),
      scenario: parseScenario(scenarioInput),
      calculationType,
    }
  } catch (error) {
    throw new Error(`Cannot parse cash flow column '${input}' due to ${error}`)
  }
}

// TODO: Improve label derivation
export const columnLabel = ({ scenario, period }: CashFlowColumn): string => {
  return [scenario.name, period.type, formatDate(period.startDate)].join(' / ')
}
