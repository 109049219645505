import React from 'react'

import { Currency } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'

import { StudFeeResource } from '../stud_fee'

const AmountField = ({
  stallionId,
  year,
  form: {
    getValues,
    setValue,
    formState: { dirtyFields },
  },
}) => {
  const { records: studFees } = useResourceQuery(
    StudFeeResource.query.index
      .filter('winstar', year, 'scope')
      .filter('stallionId', stallionId)
      .pageSize(1),
  )

  const [studFee] = studFees

  if (!getValues('amount') || dirtyFields.stallionId !== undefined)
    setValue('amount', studFee?.price)

  return <Currency value={studFee?.price} className="w-max" />
}

export { AmountField }
