import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ThoromanagerWorkout } from '@/types'

const name = 'ThoromanagerWorkout'

const recordNameSingular = 'thoromanagerWorkout'
const recordNamePlural = 'thoromanagerWorkouts'
const i18nKey = 'thoromanager::Workout'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'thoromanagerWorkouts'

const pageHelperKey = 'thoromanagerWorkouts'

const resourcePathName = 'thoromanager_workout'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  trackAbbreviation: {
    kind: 'column',
    name: 'trackAbbreviation',
    type: 'string',
  },
  courseId: {
    kind: 'column',
    name: 'courseId',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'float',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  time: {
    kind: 'column',
    name: 'time',
    type: 'float',
  },
  gate: {
    kind: 'column',
    name: 'gate',
    type: 'string',
  },
  type: {
    kind: 'column',
    name: 'type',
    type: 'string',
  },
  rank: {
    kind: 'column',
    name: 'rank',
    type: 'integer',
  },
  horses: {
    kind: 'column',
    name: 'horses',
    type: 'integer',
  },
  fileName: {
    kind: 'column',
    name: 'fileName',
    type: 'string',
  },
  fileDate: {
    kind: 'column',
    name: 'fileDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  breeder: {
    kind: 'column',
    name: 'breeder',
    type: 'string',
  },
  foalingArea: {
    kind: 'column',
    name: 'foalingArea',
    type: 'string',
  },
}

const modelSpec: ModelSpec<ThoromanagerWorkout> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
