import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ProjectedPurchase } from '@/types'

const name = 'ProjectedPurchase'

const recordNameSingular = 'projectedPurchase'
const recordNamePlural = 'projectedPurchases'
const i18nKey = 'projectedPurchase'

const primaryKey = 'id'
const searchKey = 'label'

const parents: Scope[] = ['horse']

const apiHelperKey = 'projectedPurchases'

const pageHelperKey = 'projectedPurchases'

const resourcePathName = 'projected_purchase'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  account: {
    kind: 'association',
    name: 'account',
    modelName: 'Account',
    foreignKey: 'accountId',
    associationPrimaryKey: 'id',
  },
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  organizationHorse: {
    kind: 'association',
    name: 'organizationHorse',
    modelName: 'OrganizationHorse',
    foreignKey: 'organizationHorseId',
    associationPrimaryKey: 'id',
  },
  inFoalTo: {
    kind: 'association',
    name: 'inFoalTo',
    modelName: 'Horse',
    foreignKey: 'inFoalToId',
    associationPrimaryKey: 'id',
  },
  breedTo: {
    kind: 'association',
    name: 'breedTo',
    modelName: 'Horse',
    foreignKey: 'breedToId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  organizationHorseId: {
    kind: 'column',
    name: 'organizationHorseId',
    type: 'integer',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'crops',
        },
        {
          value: 1,
          key: 'racing',
        },
        {
          value: 2,
          key: 'broodmares',
        },
        {
          value: 3,
          key: 'stallions',
        },
      ],
    },
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  disposition: {
    kind: 'column',
    name: 'disposition',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'retain',
        },
        {
          value: 1,
          key: 'borderline',
        },
        {
          value: 2,
          key: 'sell_as_weanling',
        },
        {
          value: 3,
          key: 'sell_as_yearling',
        },
        {
          value: 4,
          key: 'sell_as_2yo',
        },
        {
          value: 5,
          key: 'sell_as_older_horse',
        },
        {
          value: 6,
          key: 'sell_in_foal',
        },
        {
          value: 7,
          key: 'sell_off_track',
        },
        {
          value: 8,
          key: 'sell_privately',
        },
        {
          value: 9,
          key: 'giveaway',
        },
        {
          value: 10,
          key: 'layup',
        },
        {
          value: 11,
          key: 'claimed',
        },
        {
          value: 12,
          key: 'died',
        },
        {
          value: 13,
          key: 'other',
        },
      ],
    },
  },
  projectedCost: {
    kind: 'column',
    name: 'projectedCost',
    type: 'integer',
  },
  projectedValue: {
    kind: 'column',
    name: 'projectedValue',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  projectedOwnership: {
    kind: 'column',
    name: 'projectedOwnership',
    type: 'decimal',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  numberOfHorses: {
    kind: 'column',
    name: 'numberOfHorses',
    type: 'integer',
  },
  cropYear: {
    kind: 'column',
    name: 'cropYear',
    type: 'integer',
  },
  inFoalToId: {
    kind: 'column',
    name: 'inFoalToId',
    type: 'integer',
  },
  breedToId: {
    kind: 'column',
    name: 'breedToId',
    type: 'integer',
  },
  properties: {
    kind: 'column',
    name: 'properties',
    type: 'json',
  },
  label: {
    kind: 'column',
    name: 'label',
    type: 'string',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  manualFinancials: {
    kind: 'column',
    name: 'manualFinancials',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<ProjectedPurchase> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
