import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { HorseDetailCard } from '@/resources/horse_detail/card'
import { HorseDetail } from '@/types'

const ProgenySummaryCardContent = ({ horseDetails }: { horseDetails: HorseDetail[] }) => {
  return (
    <ScrollArea className="h-[35rem]">
      <div className="flex flex-col gap-2">
        {horseDetails.map((horseDetail) =>
          horseDetail.horseDetailId ? (
            <HorseDetailCard key={horseDetail.horseDetailId} horseDetail={horseDetail} />
          ) : null,
        )}
      </div>
    </ScrollArea>
  )
}

export const ProgenySummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: horseDetails, error, isPending } = useResourceQuery(query)
  const pluralName = 'Progeny'

  return (
    <SummaryCard
      actions={null}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
    >
      {horseDetails.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <ProgenySummaryCardContent horseDetails={horseDetails} />
      )}
    </SummaryCard>
  )
}
