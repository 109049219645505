import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HairSampleBinder } from '@/types'

const name = 'HairSampleBinder'

const recordNameSingular = 'hairSampleBinder'
const recordNamePlural = 'hairSampleBinders'
const i18nKey = 'hairSampleBinder'

const primaryKey = 'id'
const searchKey = 'label'

const parents: Scope[] = []

const apiHelperKey = 'hairSampleBinders'

const pageHelperKey = 'hairSampleBinders'

const resourcePathName = 'hair_sample_binder'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  label: {
    kind: 'column',
    name: 'label',
    type: 'string',
  },
  location: {
    kind: 'column',
    name: 'location',
    type: 'string',
  },
  sections: {
    kind: 'column',
    name: 'sections',
    type: 'integer',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<HairSampleBinder> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
