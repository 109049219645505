import { Outlet, useOutletContext } from 'react-router-dom'

import { PageSuspense } from '@/components/suspense/default'
import { usePageContext } from '@/contexts/page'

import { SheetOutlet } from './sheet-outlet'

export const LayoutOutlet = () => {
  const { layoutType } = usePageContext()
  const outletContext = useOutletContext()

  return (
    <PageSuspense>
      <SheetOutlet context={outletContext} />
      {layoutType === 'none' ? <Outlet /> : null}
    </PageSuspense>
  )
}

export default LayoutOutlet
