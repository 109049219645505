import { Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { BaseDataTable } from '@/components/data-table/base_table'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { useResource } from '@/hooks'
import { LayoutOutlet } from '@/pages/layouts/layout-outlet'
import {
  DBTRacehorseDetailResource,
  dbtRacehorseDetailTable,
} from '@/resources/dbt/racehorse_detail'
import { HorseResource } from '@/resources/horse'

function StallionRacehorses() {
  const { stallionId } = useParams()
  const resource = useResource({ resource: HorseResource, id: stallionId })
  const { record: stallion } = resource.useRecord()

  const query = DBTRacehorseDetailResource.query.index
    .filter('horse__sireId', stallionId)
    ?.filter('contextFilter', true)

  const tableProps = dbtRacehorseDetailTable.props({
    query,
    clientSide: true,
    verticalScroll: true,
  })

  const datatable = useDataTable(tableProps)

  // Use type guards and ensure table.getRowModel().rows is an array
  // const flattened = useMemo(
  //   () =>
  //     Array.isArray(table.getRowModel().rows)
  //       ? flattenData(table.getRowModel().rows, 'comment.tier', 'horse.yob')
  //       : [],
  //   [table],
  // )
  //
  // const groupedData = useMemo(() => {
  //   const aqTable = aq.from(flattened) // Load the flattened data into Arquero
  //   return aqTable
  //     .groupby('comment.tier', 'horse.yob') // Group by tier and yob
  //     .rollup({ count: aq.op.count() }) // Rollup to calculate count
  //     .objects() // Convert back to an array of objects
  // }, [flattened])
  // Add this to below
  // <Card className="h-[30rem] w-[50rem]">
  //   <HeatMap data={groupedData} x="horse.yob" y="comment.tier" value="count" />
  // </Card>

  return (
    <>
      <PageHeader title={`${stallion.title ?? 'Unknown'} Racehorses`} />
      <Suspense>
        <BaseDataTable {...datatable} />
        <LayoutOutlet />
      </Suspense>
    </>
  )
}

export default StallionRacehorses
