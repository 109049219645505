import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { VideoForm } from '@/resources/video/form'

const VideosNew = () => {
  const { videosNew } = useLoaderQueryBuilders()
  const { record: video } = useResourceQuery(videosNew)

  return (
    <Section title="New Video">
      <VideoForm initialValues={video} />
    </Section>
  )
}

export default VideosNew
