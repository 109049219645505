import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HairSampleBox } from '@/types'

const name = 'HairSampleBox'

const recordNameSingular = 'hairSampleBox'
const recordNamePlural = 'hairSampleBoxes'
const i18nKey = 'hairSampleBox'

const primaryKey = 'id'
const searchKey = 'label'

const parents: Scope[] = []

const apiHelperKey = 'hairSampleBoxes'

const pageHelperKey = 'hairSampleBoxes'

const resourcePathName = 'hair_sample_box'

const attributes: Record<string, AttributeSpec> = {
  hairSampleBinder: {
    kind: 'association',
    name: 'hairSampleBinder',
    modelName: 'HairSampleBinder',
    foreignKey: 'hairSampleBinderId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  hairSampleBinderId: {
    kind: 'column',
    name: 'hairSampleBinderId',
    type: 'integer',
  },
  binderSectionKey: {
    kind: 'column',
    name: 'binderSectionKey',
    type: 'integer',
  },
  label: {
    kind: 'column',
    name: 'label',
    type: 'string',
  },
  unusedCards: {
    kind: 'column',
    name: 'unusedCards',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<HairSampleBox> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
