import * as React from 'react'

import { AlertDialogDescription, AlertDialogFooter } from '@/components/ui/alert-dialog'

export const DestroyResource = ({ resource }: { resource: Resource }) => {
  return (
    <AlertDialog>
      <AlertDialogContent>
        <AlertDialogTitle>Are you sure you want to delete?</AlertDialogTitle>
        <AlertDialogDescription>
          This action cannot be undone. It will permanently delete this record.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DestroyResource
