// JsFromRoutes CacheKey ce3d012a208cab359d75ef9190b41238
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DocumentModel } from '@/models/document'

export const documentsApi = {
  index: defineEndpoint({
    controllerName: 'documents',
    actionName: 'index',
    model: DocumentModel,
    parts: ['api', 'documents'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'documents',
    actionName: 'create',
    model: DocumentModel,
    parts: ['api', 'documents'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'documents',
    actionName: 'new',
    model: DocumentModel,
    parts: ['api', 'documents', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'documents',
    actionName: 'edit',
    model: DocumentModel,
    parts: ['api', 'documents', { name: 'id', attributeId: 'Document:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'documents',
    actionName: 'show',
    model: DocumentModel,
    parts: ['api', 'documents', { name: 'id', attributeId: 'Document:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'documents',
    actionName: 'update',
    model: DocumentModel,
    parts: ['api', 'documents', { name: 'id', attributeId: 'Document:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'documents',
    actionName: 'destroy',
    model: DocumentModel,
    parts: ['api', 'documents', { name: 'id', attributeId: 'Document:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
