import React, { useState } from 'react'

import { HorseImage } from '@/components/horse_image'
import { iconText, InfoTile } from '@/components/info_tile'
import { Tile } from '@/components/tile'
import { Tag } from '@/components/ui/tag'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceT } from '@/hooks/use_resource_t'
import { ImageGalleryDialog } from '@/modules/image_gallery'
import { HorseLinkTile, HorseResource } from '@/resources/horse'
import { Horse } from '@/types'
import { formatDate } from '@/utils'

export const HorseInfo = () => {
  const { horsesShow } = useLoaderQueryBuilders()

  const { record: horse } = useResourceQuery(horsesShow) as {
    record: Horse
  }

  const { t } = useResourceT(HorseResource)

  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="grid w-full auto-rows-4rem grid-cols-12 gap-2 @container">
        <div
          className="col-span-12 row-span-4 hover:cursor-pointer @xl:col-span-6 @xl:row-span-3 @2xl:row-span-5 @4xl:col-span-3 @4xl:row-span-3"
          onClick={() => {
            setOpen(true)
          }}
        >
          <HorseImage
            image={horse.defaultImage}
            size="container"
            behavior="cover"
            horseId={horse.id}
          />
        </div>

        <div className="col-span-12 flex gap-2 @xl:hidden">
          <InfoTile
            label="TYPE"
            className="flex-1"
            icon={
              <Tag
                size="lg"
                style={{
                  '--badge-color': `hsl(var(--color-${horse.horseType.toLowerCase()}))`,
                }}
                className={`bg-[--badge-color]`}
                rounded
              >
                {t(`activerecord.attributes.horse.horseTypes.${horse.horseType}`)}
              </Tag>
            }
          />

          <InfoTile
            label="SEX"
            className="flex-1"
            icon={
              <Tag size="lg" rounded>
                {t(`activerecord.attributes.horse.sexes.${horse.sex.toLowerCase()}`)}
              </Tag>
            }
          />
        </div>

        <div className="col-span-12 row-span-3 grid auto-rows-4rem gap-2 @xl:col-span-6 @4xl:col-span-3">
          <HorseLinkTile id={horse.sireId} label="SIRE" />
          <HorseLinkTile id={horse.damId} label="DAM" />
          <HorseLinkTile id={horse.damSireId} label="DAMSIRE" />
        </div>

        <Tile className="col-span-12 row-span-2 grid auto-rows-4rem grid-cols-12 gap-2 p-0 @2xl:col-span-6">
          <InfoTile
            className="col-span-6"
            label={iconText('Calendar', 'Foal Date')}
            variant="ghost"
          >
            {horse.dob ? formatDate(horse.dob) : '-'}
          </InfoTile>
          <InfoTile className="col-span-6" label={iconText('Palette', 'Color')} variant="ghost">
            {horse.color ?? '-'}
          </InfoTile>
          <InfoTile className="col-span-6" label={iconText('Map', 'Foal Location')} variant="ghost">
            {horse.foalLocation ?? '-'}
          </InfoTile>
          <InfoTile className="col-span-6" label="HAPLOGROUP" variant="ghost">
            -
          </InfoTile>
        </Tile>

        <InfoTile className="col-span-12 @xl:col-span-6 @4xl:col-span-3" label="OWNER">
          -
        </InfoTile>
        <InfoTile className="col-span-12 @xl:col-span-6 @4xl:col-span-3" label="BREEDER">
          -
        </InfoTile>
      </div>
      {horse.defaultImage ? (
        <div className="absolute">
          <ImageGalleryDialog
            horseId={horse.id}
            initialImageId={horse.defaultImage.id}
            open={open}
            setOpen={setOpen}
            asChild
          />
        </div>
      ) : null}
    </>
  )
}
