import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { compress, decompress } from 'lz-string'

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 Hours
      staleTime: 60000,
      retry: false,
      suspense: true,
      throwOnError: (error) => error.response?.status >= 500,
    },
    mutations: {
      throwOnError: (error) => error.response?.status >= 500,
    },
  },
}

const persistedQueryClient = new QueryClient(queryClientOptions)

const serializeData = (data) => {
  if (process.env.NODE_ENV === 'development') return JSON.stringify(data)
  else return compress(JSON.stringify(data))
}

const deserializeData = (data) => {
  if (process.env.NODE_ENV === 'development') return JSON.parse(data)
  else return JSON.parse(decompress(data))
}

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  serialize: serializeData,
  deserialize: deserializeData,
})

persistQueryClient({
  queryClient: persistedQueryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
})

export { persistedQueryClient }
