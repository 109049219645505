// JsFromRoutes CacheKey ae173fdf5ce6c4fbb10c3e4aa7b9b996
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const hairSamplesApi = {
  index: definePathHelper('get', '/api/hair_samples'),
  create: definePathHelper('post', '/api/hair_samples'),
  new: definePathHelper('get', '/api/hair_samples/new'),
  edit: definePathHelper('get', '/api/hair_samples/:id/edit'),
  show: definePathHelper('get', '/api/hair_samples/:id'),
  update: definePathHelper('patch', '/api/hair_samples/:id'),
  destroy: definePathHelper('delete', '/api/hair_samples/:id'),
}
