import { HorseModel } from '@/models/horse'

import { defineEndpoint } from './endpoint'

// Meaningless route that is used by noop query
export const noopEndpoint = defineEndpoint({
  actionName: 'noop',
  controllerName: 'noop',
  parts: [],
  httpMethod: 'get',
  model: HorseModel,
})
