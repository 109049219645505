import React from 'react'

import { Input, InputProps } from '@/components/ui/input'
import { cn, formatNumber, isEmptyString } from '@/utils'

interface PercentageInputProps extends InputProps {
  decimals?: number
}

export const PercentageInput = React.forwardRef(
  (
    { value, onChange, className, decimals = 0, ...props }: PercentageInputProps,
    ref: HTMLInputElement,
  ) => {
    const formatAsPercentage = (decimalValue?: number) => {
      if (decimalValue == null) return ''

      return formatNumber(decimalValue * 100, decimals)
    }

    const convertToDecimal = (percentageValue: string) => {
      if (isEmptyString(percentageValue)) return null

      return Number.parseFloat(percentageValue) / 100
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      onChange(convertToDecimal(inputValue))
    }

    return (
      <Input
        ref={ref}
        type="text"
        placeholder="Percentage"
        className={cn('text-right', className)}
        value={formatAsPercentage(value)}
        onChange={handleChange}
        suffix="%"
        {...props}
      />
    )
  },
)
