import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctTrialBalance } from '@/types'

const name = 'IntacctTrialBalance'

const recordNameSingular = 'intacctTrialBalance'
const recordNamePlural = 'intacctTrialBalances'
const i18nKey = 'intacct::TrialBalance'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctTrialBalances'

const pageHelperKey = 'intacctTrialBalances'

const resourcePathName = 'intacct_trial_balance'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  reportingPeriodName: {
    kind: 'column',
    name: 'reportingPeriodName',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  reportingBook: {
    kind: 'column',
    name: 'reportingBook',
    type: 'string',
  },
  currency: {
    kind: 'column',
    name: 'currency',
    type: 'string',
  },
  startBalance: {
    kind: 'column',
    name: 'startBalance',
    type: 'decimal',
  },
  debits: {
    kind: 'column',
    name: 'debits',
    type: 'decimal',
  },
  credits: {
    kind: 'column',
    name: 'credits',
    type: 'decimal',
  },
  adjDebits: {
    kind: 'column',
    name: 'adjDebits',
    type: 'decimal',
  },
  adjCredits: {
    kind: 'column',
    name: 'adjCredits',
    type: 'decimal',
  },
  endBalance: {
    kind: 'column',
    name: 'endBalance',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctTrialBalance> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
