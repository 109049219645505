import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { ConformationNoteForm } from '@/resources/conformation_note/form'

const ConformationNotesNew = () => {
  const { conformationNotesNew } = useLoaderQueryBuilders()
  const { record: conformationNote } = useResourceQuery(conformationNotesNew)

  return (
    <Section title="New Conformation Note">
      <ConformationNoteForm initialValues={conformationNote} />
    </Section>
  )
}

export default ConformationNotesNew
