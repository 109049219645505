// JsFromRoutes CacheKey 387712b369d7ff813bfad1e293cfeaed
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const stallionBreedingTargetsApi = {
  index: definePathHelper('get', '/api/stallion_breeding_targets'),
  create: definePathHelper('post', '/api/stallion_breeding_targets'),
  new: definePathHelper('get', '/api/stallion_breeding_targets/new'),
  edit: definePathHelper('get', '/api/stallion_breeding_targets/:id/edit'),
  show: definePathHelper('get', '/api/stallion_breeding_targets/:id'),
  update: definePathHelper('patch', '/api/stallion_breeding_targets/:id'),
  destroy: definePathHelper('delete', '/api/stallion_breeding_targets/:id'),
}
