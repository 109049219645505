import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'DBTHfmSeason'

const recordNameSingular = 'dbtHfmSeason'
const recordNamePlural = 'dbtHfmSeasons'
const i18nKey = 'dbt::HfmSeason'

const primaryKey = 'hfmSeasonId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtHfmSeasons'

const pageHelperKey = 'dbtHfmSeasons'

const resourcePathName = 'dbt_hfm_season'

const attributes: Record<string, AttributeSpec> = {
  hfmSeasonId: {
    kind: 'column',
    name: 'hfmSeasonId',
    type: 'integer',
  },
  hfmSeasonBookId: {
    kind: 'column',
    name: 'hfmSeasonBookId',
    type: 'integer',
  },
  seasonId: {
    kind: 'column',
    name: 'seasonId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  hfmStallionId: {
    kind: 'column',
    name: 'hfmStallionId',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  hfmWinstarMareInfoId: {
    kind: 'column',
    name: 'hfmWinstarMareInfoId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  hemisphere: {
    kind: 'column',
    name: 'hemisphere',
    type: 'string',
  },
  shareNumber: {
    kind: 'column',
    name: 'shareNumber',
    type: 'string',
  },
  contractType: {
    kind: 'column',
    name: 'contractType',
    type: 'string',
  },
  contractTypeDate: {
    kind: 'column',
    name: 'contractTypeDate',
    type: 'datetime',
  },
  studFeeAmount: {
    kind: 'column',
    name: 'studFeeAmount',
    type: 'money',
  },
  upFrontAmount: {
    kind: 'column',
    name: 'upFrontAmount',
    type: 'money',
  },
  terms: {
    kind: 'column',
    name: 'terms',
    type: 'string',
  },
  bookingConfirmedBy: {
    kind: 'column',
    name: 'bookingConfirmedBy',
    type: 'string',
  },
  bookingConfirmedDate: {
    kind: 'column',
    name: 'bookingConfirmedDate',
    type: 'datetime',
  },
  commissionPayableTo: {
    kind: 'column',
    name: 'commissionPayableTo',
    type: 'string',
  },
  commissionPercent: {
    kind: 'column',
    name: 'commissionPercent',
    type: 'decimal',
  },
  commissionAmountDue: {
    kind: 'column',
    name: 'commissionAmountDue',
    type: 'money',
  },
  commissionDatePaid: {
    kind: 'column',
    name: 'commissionDatePaid',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
