import React from 'react'

import { Text, TextProps } from '@/components/ui/text'
import { BasicTooltip } from '@/components/ui/tooltip'
import { cn } from '@/utils'

import Icon from './icon'

const marginFilter = (size: string) => {
  if (size === 'xl' || size === '2xl') {
    return 'mr-2'
  } else {
    return ''
  }
}

type InlineInfoTile = React.HTMLAttributes<HTMLDivElement> & {
  icon?: string
  size?: TextProps['size']
  label: string
  description?: string
  tooltipText: string
}

export const InlineInfoTile = ({
  icon = '',
  size = 'sm',
  label,
  description,
  tooltipText,
  className,
}) => (
  <BasicTooltip content={tooltipText} align="start">
    <div className={cn('col-span-6 flex flex-col', className)}>
      <div className="flex max-w-full items-center">
        {typeof icon === 'string' ? (
          <Icon className={cn('mr-1 size-3 text-grey-400', marginFilter(size))} name={icon} />
        ) : (
          React.cloneElement(icon, {
            className: cn('mr-1 text-grey-400', marginFilter(size)),
          })
        )}
        <Text as="span" className="truncate" size={size}>
          {label ?? '-'}
        </Text>
      </div>
      {description && (
        <Text as="span" size="sm" className="text-grey-400">
          {description}
        </Text>
      )}
    </div>
  </BasicTooltip>
)
