import { Currency } from '@/components/ui/text'

import { CashFlowColumn } from './types'

export const CashFlowTableFooter = ({
  table,
  column,
  cashFlowColumn,
}: {
  cashFlowColumn: CashFlowColumn
}) => {
  if (!column.accessorFn) return null

  const result = table.getRowModel().rows.reduce((total, row) => total + row.getValue(column.id), 0)

  return <Currency value={result} />
}
