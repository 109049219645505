import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const FinanceMonthlyEntriesIndex = () => {
  const { financeMonthlyEntriesIndexIndex } = useLoaderQueryBuilders()
  const financeMonthlyEntriesIndex = useResourceQuery(financeMonthlyEntriesIndexIndex)

  return <Section title="Finance monthly entries" className="mt-16"></Section>
}

export default FinanceMonthlyEntriesIndex
