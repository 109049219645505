import { horsePanelConfig } from '@/components/panel/config/horse'
import { createRoutes } from '@/libs/router/route'
import { AppraisalResourceRoutes } from '@/resources/appraisal/routes'
import { ConformationNoteResourceRoutes } from '@/resources/conformation_note/routes'
import { CareerRaceSummaryResourceRoutes } from '@/resources/dbt/career_race_summary/routes'
import { OrganizationHorseResourceRoutes } from '@/resources/organization_horse/routes'
import { PedigreeResource } from '@/resources/pedigree/resource'
import { RacehorseCommentResourceRoutes } from '@/resources/racehorse_comment/routes'
import { SaleRecordDetailResource } from '@/resources/sale_record_detail'
import { StudFeeResourceRoutes } from '@/resources/stud_fee/routes'

import { DocumentResourceRoutes } from '../document/routes'
import { HorseDetailResource } from '../horse_detail'
import { ImageResourceRoutes } from '../image/routes'
import { MatingResource } from '../mating'
import { TrainingReportResourceRoutes } from '../training_report/routes'
import { HorseResource } from './resource'

export interface HandlePathSpec {
  key: string
  attributeId: string
}
// sheetsize needs to be checked, should be in a pr
export const HorseResourceRoutes = createRoutes({
  resource: HorseResource,
  routes: [
    {
      path: 'horses',
      children: [
        {
          path: 'new',
          page: 'horses/new',
          query: HorseResource.query.new,
        },
        {
          path: ':id',
          query: HorseResource.query.show,
          handle: { panelConfig: horsePanelConfig },
          children: [
            {
              index: true,
              page: 'horses/sheet',
            },
            {
              path: 'edit',
              query: HorseResource.query.edit,
              page: 'horses/edit',
            },
            {
              path: 'destroy',
              page: 'horses/destroy',
            },
            {
              path: 'five_cross',
              page: 'horses/five-cross',
            },
            createRoutes({
              resource: PedigreeResource,
              routes: [
                {
                  path: 'pedigree',
                  page: 'horses/pedigree',
                  // Do not eager run query so that loading state is indicated better
                  // by rendering page
                  // query: HorseResource.query.pedigree,
                  handle: {
                    layoutType: 'sheet',
                    sheetSize: 'xl',
                  },
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            createRoutes({
              resource: HorseDetailResource,
              routes: [
                {
                  path: 'progeny',
                  page: 'horses/progeny',
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            createRoutes({
              resource: MatingResource,
              routes: [
                {
                  path: 'matings',
                  children: [
                    {
                      index: true,
                      page: 'horses/matings',
                      handle: { sheetSize: 'lg' },
                    },
                    {
                      path: 'new',
                      page: 'matings/new',
                      query: MatingResource.query.new,
                    },
                    {
                      path: ':id',
                      query: MatingResource.query.show,
                      children: [
                        {
                          index: true,
                          page: 'matings/show',
                        },
                        {
                          path: 'edit',
                          query: MatingResource.query.edit,
                          page: 'matings/edit',
                        },
                        {
                          path: 'destroy',
                          page: 'matings/destroy',
                        },
                      ],
                    },
                  ],
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            createRoutes({
              resource: SaleRecordDetailResource,
              routes: [
                {
                  path: 'sales',
                  page: 'sale_record_details/index',
                  query: SaleRecordDetailResource.query.index,
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            AppraisalResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            CareerRaceSummaryResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            ConformationNoteResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            DocumentResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            ImageResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            OrganizationHorseResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            RacehorseCommentResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            StudFeeResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            TrainingReportResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
          ],
        },
      ],
    },
  ],
})

export const HorseRoutes = createRoutes({
  resource: HorseResource,
  routes: [
    {
      path: 'horses',
      children: [
        {
          path: 'new',
          page: 'horses/new',
          query: HorseResource.query.new,
        },
        {
          path: ':id',
          page: 'horses/show',
          query: HorseResource.query.show,
          children: [
            {
              path: 'five_cross',
              page: 'horses/five-cross',
            },
            createRoutes({
              resource: PedigreeResource,
              routes: [
                {
                  path: 'pedigree',
                  page: 'horses/pedigree',
                  // Do not eager run query so that loading state is indicated better
                  // by rendering page
                  // query: HorseResource.query.pedigree,
                  handle: {
                    layoutType: 'sheet',
                    sheetSize: 'xl',
                  },
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            createRoutes({
              resource: HorseDetailResource,
              routes: [
                {
                  path: 'progeny',
                  page: 'horses/progeny',
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            createRoutes({
              resource: MatingResource,
              routes: [
                {
                  path: 'matings',
                  page: 'horses/matings',
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            {
              path: 'edit',
              query: HorseResource.query.edit,
              page: 'horses/edit',
            },
            {
              path: 'destroy',
              page: 'horses/destroy',
            },
            createRoutes({
              resource: SaleRecordDetailResource,
              routes: [
                {
                  path: 'sales',
                  page: 'sale_record_details/index',
                  query: SaleRecordDetailResource.query.index,
                },
              ],
            }).asSubRoute({ layoutType: 'sheet' }),
            AppraisalResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            CareerRaceSummaryResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            ConformationNoteResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            DocumentResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            ImageResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            OrganizationHorseResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            RacehorseCommentResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            StudFeeResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
            TrainingReportResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
          ],
        },
      ],
    },
  ],
})
