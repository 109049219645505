import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { EntityForm } from '@/resources/entity/form'

const EntitiesNew = () => {
  const { entitiesNew } = useLoaderQueryBuilders()
  const { record: entity } = useResourceQuery(entitiesNew)

  return (
    <Section title="New Entity">
      <EntityForm initialValues={entity} />
    </Section>
  )
}

export default EntitiesNew
