import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTCareerSaleSummary } from '@/types'

const name = 'DBTCareerSaleSummary'

const recordNameSingular = 'dbtCareerSaleSummary'
const recordNamePlural = 'dbtCareerSaleSummaries'
const i18nKey = 'dbt::CareerSaleSummary'

const primaryKey = 'horseId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtCareerSaleSummaries'

const pageHelperKey = 'dbtCareerSaleSummaries'

const resourcePathName = 'dbt_career_sale_summary'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  weanlingSaleRecord: {
    kind: 'association',
    name: 'weanlingSaleRecord',
    modelName: 'SaleRecord',
    foreignKey: 'weanlingSaleId',
    associationPrimaryKey: 'id',
  },
  yearlingSaleRecord: {
    kind: 'association',
    name: 'yearlingSaleRecord',
    modelName: 'SaleRecord',
    foreignKey: 'yearlingSaleId',
    associationPrimaryKey: 'id',
  },
  twoYearOldSaleRecord: {
    kind: 'association',
    name: 'twoYearOldSaleRecord',
    modelName: 'SaleRecord',
    foreignKey: 'twoYearOldSaleId',
    associationPrimaryKey: 'id',
  },
  mostRecentSaleRecord: {
    kind: 'association',
    name: 'mostRecentSaleRecord',
    modelName: 'SaleRecord',
    foreignKey: 'mostRecentSaleId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  numSales: {
    kind: 'column',
    name: 'numSales',
    type: 'integer',
  },
  numOuts: {
    kind: 'column',
    name: 'numOuts',
    type: 'integer',
  },
  numRnas: {
    kind: 'column',
    name: 'numRnas',
    type: 'integer',
  },
  maxSalePrice: {
    kind: 'column',
    name: 'maxSalePrice',
    type: 'decimal',
  },
  avgSalePrice: {
    kind: 'column',
    name: 'avgSalePrice',
    type: 'decimal',
  },
  totalSalePrice: {
    kind: 'column',
    name: 'totalSalePrice',
    type: 'decimal',
  },
  weanlingSaleId: {
    kind: 'column',
    name: 'weanlingSaleId',
    type: 'integer',
  },
  weanlingSalePriceSold: {
    kind: 'column',
    name: 'weanlingSalePriceSold',
    type: 'decimal',
  },
  weanlingPrice: {
    kind: 'column',
    name: 'weanlingPrice',
    type: 'decimal',
  },
  weanlingSold: {
    kind: 'column',
    name: 'weanlingSold',
    type: 'boolean',
  },
  weanlingRna: {
    kind: 'column',
    name: 'weanlingRna',
    type: 'boolean',
  },
  weanlingOut: {
    kind: 'column',
    name: 'weanlingOut',
    type: 'boolean',
  },
  yearlingSaleId: {
    kind: 'column',
    name: 'yearlingSaleId',
    type: 'integer',
  },
  yearlingSalePriceSold: {
    kind: 'column',
    name: 'yearlingSalePriceSold',
    type: 'decimal',
  },
  yearlingPrice: {
    kind: 'column',
    name: 'yearlingPrice',
    type: 'decimal',
  },
  yearlingSold: {
    kind: 'column',
    name: 'yearlingSold',
    type: 'boolean',
  },
  yearlingRna: {
    kind: 'column',
    name: 'yearlingRna',
    type: 'boolean',
  },
  yearlingOut: {
    kind: 'column',
    name: 'yearlingOut',
    type: 'boolean',
  },
  twoYearOldSaleId: {
    kind: 'column',
    name: 'twoYearOldSaleId',
    type: 'integer',
  },
  twoYearOldSalePriceSold: {
    kind: 'column',
    name: 'twoYearOldSalePriceSold',
    type: 'decimal',
  },
  twoYearOldPrice: {
    kind: 'column',
    name: 'twoYearOldPrice',
    type: 'decimal',
  },
  twoYearOldSold: {
    kind: 'column',
    name: 'twoYearOldSold',
    type: 'boolean',
  },
  twoYearOldRna: {
    kind: 'column',
    name: 'twoYearOldRna',
    type: 'boolean',
  },
  twoYearOldOut: {
    kind: 'column',
    name: 'twoYearOldOut',
    type: 'boolean',
  },
  recentSaleId: {
    kind: 'column',
    name: 'recentSaleId',
    type: 'integer',
  },
  recentSalePriceSold: {
    kind: 'column',
    name: 'recentSalePriceSold',
    type: 'decimal',
  },
  recentPrice: {
    kind: 'column',
    name: 'recentPrice',
    type: 'decimal',
  },
  recentSold: {
    kind: 'column',
    name: 'recentSold',
    type: 'boolean',
  },
  recentRna: {
    kind: 'column',
    name: 'recentRna',
    type: 'boolean',
  },
  recentOut: {
    kind: 'column',
    name: 'recentOut',
    type: 'boolean',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTCareerSaleSummary> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
