import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { AppraisalSchema } from '@/schemas/appraisal'
import { Appraisal } from '@/types'

import { AppraisalResource } from './resource'

type AppraisalFormProps = Omit<UseFormProps<Appraisal>, 'resource'>

const AppraisalForm = ({ zodSchema = AppraisalSchema, ...props }: AppraisalFormProps) => {
  const formConfig = useForm<Appraisal>({
    resource: AppraisalResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="user" />
          <Field name="date" />
          <Field name="value" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { AppraisalForm }
