import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HairSample } from '@/types'

const name = 'HairSample'

const recordNameSingular = 'hairSample'
const recordNamePlural = 'hairSamples'
const i18nKey = 'hairSample'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'hairSamples'

const pageHelperKey = 'hairSamples'

const resourcePathName = 'hair_sample'

const attributes: Record<string, AttributeSpec> = {
  hairPull: {
    kind: 'association',
    name: 'hairPull',
    modelName: 'HairPull',
    foreignKey: 'hairPullId',
    associationPrimaryKey: 'id',
  },
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  hairPullId: {
    kind: 'column',
    name: 'hairPullId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'stored',
        },
        {
          value: 1,
          key: 'consumed',
        },
      ],
    },
  },
  upc: {
    kind: 'column',
    name: 'upc',
    type: 'string',
  },
  box: {
    kind: 'column',
    name: 'box',
    type: 'string',
  },
  cabinet: {
    kind: 'column',
    name: 'cabinet',
    type: 'string',
  },
  estimatedNumberOfHairs: {
    kind: 'column',
    name: 'estimatedNumberOfHairs',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<HairSample> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
