import { endOfMonth } from 'date-fns'

import { Period } from './period'
import { deriveScenarioForPeriod } from './scenario'
import { CashFlowColumn } from './types'
import { monthsBetweenDates } from './utils'

/**
 * Builds list of dependent column names for a given columns
 * calculation to use
 */
export const dependentColumns = ({ period, scenario }: CashFlowColumn): CashFlowColumn[] => {
  const monthsInPeriod = monthsBetweenDates(period.startDate, period.endDate)

  return monthsInPeriod.map((month: Date) => {
    const newPeriod: Period = {
      type: 'Month',
      startDate: month,
      endDate: endOfMonth(month),
    }

    const newScenario = deriveScenarioForPeriod(newPeriod, scenario)

    return {
      period: newPeriod,
      scenario: newScenario,
      calculationType: 'Total',
    }
  })
}
