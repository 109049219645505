import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react'
import { useNavigate, useLocation, useParams, useNavigation } from 'react-router-dom'

import { DrawerSheet } from '@/components/ui/drawer-sheet'
import { defineRoute } from '@/libs/routes/route'
import { findLocationRoutes } from '@/router'

export type LayoutType = 'sheet' | 'dialog' | 'none'

export type SheetSize = 'sm' | 'md' | 'lg' | 'xl'

export interface PageContextValue {
  navigateBack: () => void
  navigateUp: () => void
  navigateOut: () => void
  navigate: () => void
  sheetOpen: boolean
  setSheetOpen: Dispatch<SetStateAction<boolean>>
  layoutType: LayoutType
}

const PageContext = createContext<PageContextValue | null>(null)

export const usePageContext = (): PageContextValue => {
  const context = useContext(PageContext)

  if (!context) {
    throw new Error('`usePageContext` must be used within a `PageProvider`')
  }

  return context
}

export const PageProvider = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const navigation = useNavigation()
  const params = useParams()
  const [sheetOpen, setSheetOpen] = useState<boolean>(false)
  const [sheetSize, setSheetSize] = useState<SheetSize>('md')

  const routeMatches = useMemo(() => findLocationRoutes(location), [location])
  const currentRouteMatch = useMemo(() => routeMatches[0], [location])

  const nonSheetParentRoute = useMemo(
    () => routeMatches.find((match) => match.route.handle?.layoutType !== 'sheet'),
    [location],
  )

  const currentLayoutType = useMemo(
    () => currentRouteMatch.route.handle?.layoutType,
    [currentRouteMatch],
  ) as LayoutType | undefined

  const currentSheetSize = useMemo(
    () => currentRouteMatch.route.handle?.sheetSize,
    [currentRouteMatch],
  ) as SheetSize

  useEffect(() => {
    if (currentLayoutType === 'sheet') {
      setSheetSize(currentSheetSize)
      setSheetOpen(true)
    } else {
      setSheetOpen(false)
    }
  }, [location.pathname])

  // TODO: This seems to take us to root route, not clear
  // why it happens
  const navigateUp = () => {
    navigate('..')
  }

  const navigateBack = () => {
    // When `key` is default, that usually means it's the first page
    // visited (but doesn't appear to always be the case)
    location.key === 'default' ? navigateUp() : navigate(-1)
  }

  const navigateOut = () => {
    const pathHelper = defineRoute(nonSheetParentRoute?.route.handle?.routeParts ?? [], params, {})
    navigate(pathHelper())
  }

  const value = useMemo(
    () => ({
      navigateBack,
      navigateUp,
      navigateOut,
      navigate,
      layoutType: currentLayoutType ?? 'none',
      sheetSize,
      sheetOpen,
      setSheetOpen,
    }),
    [location, currentLayoutType, sheetSize],
  )

  return (
    <PageContext.Provider value={value}>
      <DrawerSheet open={sheetOpen} onOpenChange={setSheetOpen}>
        {children}
      </DrawerSheet>
    </PageContext.Provider>
  )
}

export default PageContext
