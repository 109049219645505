import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { AppraisalForm } from '@/resources/appraisal/form'

const AppraisalsEdit = () => {
  const { appraisalsEdit } = useLoaderQueryBuilders()
  const { record: appraisal } = useResourceQuery(appraisalsEdit)

  return (
    <Section title={`Edit ${appraisal.title}`}>
      <AppraisalForm initialValues={appraisal} />
    </Section>
  )
}

export default AppraisalsEdit
