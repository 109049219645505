import React from 'react'

import { useLocalStorage } from '@uidotdev/usehooks'

export interface UseRecentlyVisitedProps<T extends object> {
  key: string
  id?: keyof T
  initialValue?: T[]
}

export const useRecentlyVisited = <T extends object>({
  key,
  id = 'id',
  initialValue = [],
}: UseRecentlyVisitedProps<object>) => {
  const [queue, setQueue] = useLocalStorage<T[]>(`useRecentlyVisited-${key}`, initialValue)
  const numRecords = 10

  const add = React.useCallback(
    (element: T) => {
      setQueue((q: T[]) => {
        const filteredQueue = q.filter((item: T) => item[id] !== element[id])
        const newQueue = [element, ...filteredQueue]
        return numRecords ? newQueue.slice(0, numRecords) : newQueue
      })
    },
    [setQueue, numRecords, id],
  )

  const remove = React.useCallback(() => {
    let removedElement

    setQueue(([first, ...q]: T[]) => {
      removedElement = first
      return q
    })

    return removedElement
  }, [setQueue])

  const clear = React.useCallback(() => {
    setQueue([])
  }, [setQueue])

  return {
    queue,
    add,
    remove,
    clear,
    first: queue[0],
    last: queue[queue.length - 1],
    size: queue.length,
  }
}
