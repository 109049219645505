import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { StallionBreedingEstimate } from '@/types'

const name = 'StallionBreedingEstimate'

const recordNameSingular = 'stallionBreedingEstimate'
const recordNamePlural = 'stallionBreedingEstimates'
const i18nKey = 'stallionBreedingEstimate'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'stallionBreedingEstimates'

const pageHelperKey = 'stallionBreedingEstimates'

const resourcePathName = 'stallion_breeding_estimate'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  final: {
    kind: 'column',
    name: 'final',
    type: 'boolean',
  },
  maresBred: {
    kind: 'column',
    name: 'maresBred',
    type: 'integer',
  },
  averageEsn: {
    kind: 'column',
    name: 'averageEsn',
    type: 'decimal',
  },
  liveFoalRate: {
    kind: 'column',
    name: 'liveFoalRate',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<StallionBreedingEstimate> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
