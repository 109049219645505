import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { ConformationNoteCard, ConformationNoteResource } from '@/resources/conformation_note'
import { conformationNotesRoutes } from '@/routes'

const ConformationNotesIndex = () => {
  const queries = useLoaderQueryBuilders()
  const conformationNotes = useResourceQuery(queries.conformationNotesIndex)

  if (conformationNotes.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No conformation notes yet.</Text>
        <div>
          <Action route={conformationNotesRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">Conformation Notes</Text>
        <Action route={conformationNotesRoutes.new} name="new" variant="primary" />
      </div>
      <ConformationNoteCard
        key={conformationNotes.records[0].id}
        query={ConformationNoteResource.query.show.withOptions({
          id: conformationNotes.records[0].id,
        })}
      />
      <Section className="mt-16" flex>
        {conformationNotes.records.slice(1).map((note) => (
          <ConformationNoteCard
            key={note.id}
            query={ConformationNoteResource.query.show.withOptions({ id: note.id })}
          />
        ))}
      </Section>
    </>
  )
}

export default ConformationNotesIndex
