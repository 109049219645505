// JsFromRoutes CacheKey 48797ecfb877d1184aa8c1e244643685
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { HorseModel } from '@/models/horse'

export const horsesApi = {
  autocomplete: defineEndpoint({
    controllerName: 'horses',
    actionName: 'autocomplete',
    model: HorseModel,
    parts: [],
    httpMethod: 'get',
  }),
  pedigree: defineEndpoint({
    controllerName: 'horses',
    actionName: 'pedigree',
    model: HorseModel,
    parts: ['api', 'horses', { name: 'id', attributeId: 'Horse:id' }, 'pedigree'],
    httpMethod: 'get',
    type: 'list',
  }),
  index: defineEndpoint({
    controllerName: 'horses',
    actionName: 'index',
    model: HorseModel,
    parts: ['api', 'horses'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'horses',
    actionName: 'create',
    model: HorseModel,
    parts: ['api', 'horses'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'horses',
    actionName: 'new',
    model: HorseModel,
    parts: ['api', 'horses', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'horses',
    actionName: 'edit',
    model: HorseModel,
    parts: ['api', 'horses', { name: 'id', attributeId: 'Horse:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'horses',
    actionName: 'show',
    model: HorseModel,
    parts: ['api', 'horses', { name: 'id', attributeId: 'Horse:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'horses',
    actionName: 'update',
    model: HorseModel,
    parts: ['api', 'horses', { name: 'id', attributeId: 'Horse:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'horses',
    actionName: 'destroy',
    model: HorseModel,
    parts: [],
    httpMethod: 'delete',
  }),
}
