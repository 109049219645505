import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Shortlist } from '@/types'

const name = 'Shortlist'

const recordNameSingular = 'shortlist'
const recordNamePlural = 'shortlists'
const i18nKey = 'shortlist'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'shortlists'

const pageHelperKey = 'shortlists'

const resourcePathName = 'shortlist'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  saleRecord: {
    kind: 'association',
    name: 'saleRecord',
    modelName: 'SaleRecord',
    foreignKey: 'saleRecordId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  saleRecordId: {
    kind: 'column',
    name: 'saleRecordId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  list: {
    kind: 'column',
    name: 'list',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'unlisted',
        },
        {
          value: 1,
          key: 'first_look',
        },
        {
          value: 2,
          key: 'second_look',
        },
        {
          value: 3,
          key: 'final',
        },
      ],
    },
  },
  appraisal: {
    kind: 'column',
    name: 'appraisal',
    type: 'integer',
  },
  xRay: {
    kind: 'column',
    name: 'xRay',
    type: 'string',
  },
  scope: {
    kind: 'column',
    name: 'scope',
    type: 'string',
  },
  pedigree: {
    kind: 'column',
    name: 'pedigree',
    type: 'boolean',
  },
  physical: {
    kind: 'column',
    name: 'physical',
    type: 'boolean',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  removed: {
    kind: 'column',
    name: 'removed',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<Shortlist> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
