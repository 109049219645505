// JsFromRoutes CacheKey 62e5362ae8dc6652ff130f2ab2e22b5f
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DBTRelativeModel } from '@/models/dbt/relative'

export const dbtRelativesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/relatives',
    actionName: 'index',
    model: DBTRelativeModel,
    parts: ['api', 'dbt', 'relatives'],
    httpMethod: 'get',
    type: 'list',
  }),
}
