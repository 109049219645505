import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { DocumentForm } from '@/resources/document/form'

const DocumentsEdit = () => {
  const { documentsEdit } = useLoaderQueryBuilders()
  const { record: document } = useResourceQuery(documentsEdit)

  return (
    <Section title={`Edit ${document.title}`}>
      <DocumentForm initialValues={document} />
    </Section>
  )
}

export default DocumentsEdit
