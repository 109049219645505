import * as React from 'react'

import { cn, cva, type VariantProps, formatCurrency } from '@/utils'
import { Slot } from '@radix-ui/react-slot'

const textVariants = cva({
  base: '',
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
      '6xl': 'text-6xl',
      '7xl': 'text-7xl',
      '8xl': 'text-8xl',
      '9xl': 'text-9xl',
    },
    family: {
      sans: 'font-sans',
      serif: 'font-serif',
      mono: 'font-mono',
    },
    weight: {
      thin: 'font-thin',
      extralight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
      black: 'font-black',
    },
    muted: {
      true: 'text-muted-foreground',
    },
    leader: {
      true: 'before:content-[attr(before)] before:float-left',
    },
    prefix: {
      true: 'before:content-[attr(before)]',
    },
    suffix: {
      true: 'after:content-[attr(after)]',
    },
    defaultVariants: {
      weight: 'normal',
    },
  },
})

export interface TextProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof textVariants> {
  asChild?: boolean
  as: string
  prefix?: string
  suffix?: string
  leader?: string
}

const Text = React.forwardRef<HTMLButtonElement, TextProps>(
  ({ as = 'span', asChild = false, prefix, leader, suffix, ...props }, ref) => {
    const Comp = asChild ? Slot : as

    return (
      <Comp
        ref={ref}
        before={leader ?? prefix}
        after={suffix}
        {...props}
        className={textVariants({
          leader: !!leader,
          prefix: !!prefix,
          suffix: !!suffix,
          ...props,
        })}
      />
    )
  },
)
Text.displayName = 'Text'

interface CurrencyProps extends TextProps, Parameters<typeof formatCurrency> {
  nonExtended?: boolean
  hideSymbol?: boolean
}

const Currency = React.forwardRef<HTMLButtonElement, CurrencyProps>(
  (
    { value, abbreviation, decimals, className, nonExtended = false, hideSymbol = false, ...props },
    ref,
  ) => {
    const formatted = formatCurrency(value, abbreviation, decimals, false)

    const hasValue = Number.isFinite(Number.parseFloat(value))
    const isNegative = Number.parseFloat(value) < 0

    const currencySymbol = hasValue && !hideSymbol ? '$' : ''

    // Only include dollar sign when present
    const prefix = (isNegative ? '(' : '') + currencySymbol

    if (formatted === 'NaN') console.log('Formatted value ended up NaN: ', value)

    return (
      <Text
        ref={ref}
        leader={nonExtended ? undefined : prefix}
        prefix={nonExtended ? prefix : undefined}
        suffix={')'}
        className={cn(
          'float-right w-full text-right tabular-nums',
          !isNegative && 'after:invisible',
          className,
        )}
        {...props}
      >
        {formatted}
      </Text>
    )
  },
)
Currency.displayName = 'Currency'

export { Text, Currency, textVariants }
