import type { ElementType, ReactNode } from 'react'
import { forwardRef } from 'react'
import { LinkProps as RouterLinkProps, Link as RouterLink, To } from 'react-router-dom'

import { ChevronRight } from '@/components/icon'
import { Button, type ButtonProps } from '@/components/ui/button'
import { hrefFromTo } from '@/hooks/use-resolved-route'

type Ref = HTMLButtonElement

export type SimpleLinkProps = ButtonProps &
  Omit<RouterLinkProps, 'to'> & {
    to?: To
    href?: string
    noRouter?: boolean
    noChevron?: boolean
    noLinkFallback?: ElementType
    children?: ReactNode
  }

/*
 * SimpleLink component allows for easy usage in scenarios
 * where links or actions may be missing. It also allows
 * for both React Router and non-React Router links.
 *
 * By default, a `Button` is rendered with a `RouterLink`
 * child. If `noRouter` is `true`, a normal `<a>` tag
 * will be used instead.
 */
const SimpleLink = forwardRef<Ref, SimpleLinkProps>(
  (
    { noRouter = false, noChevron = false, noLinkFallback = 'button', to, children, ...props },
    ref,
  ) => {
    if (!to) {
      const Fallback = noLinkFallback

      return (
        <Button asChild ref={ref} variant="inline" {...props}>
          <Fallback>{children}</Fallback>
        </Button>
      )
    }

    const { href = hrefFromTo(to), ...otherProps } = props

    const iconRight = noChevron ? null : <ChevronRight />

    return (
      <Button asChild iconRight={iconRight} ref={ref} variant="link" {...otherProps}>
        {noRouter ? <a href={href}>{children}</a> : <RouterLink to={to}>{children}</RouterLink>}
      </Button>
    )
  },
)

SimpleLink.displayName = 'AppSimpleLink'

export { SimpleLink }
