import { useParams } from 'react-router-dom'

import { PageHeader } from '@/components/header'
import { useResourceQuery } from '@/hooks'
import { HorseResource } from '@/resources/horse'

const HorsesPedigree = () => {
  const { horseId } = useParams()
  const query = HorseResource.query.pedigree.withOptions({ horseId })

  const { record: pedigree } = useResourceQuery(query)

  return (
    <>
      <PageHeader title="Pedigree" />
      <iframe
        src={pedigree.url}
        type="application/pdf"
        width="100%"
        className="h-[calc(100svh-12rem)]"
      >
        <p>Could not render pedigree</p>
      </iframe>
    </>
  )
}

export default HorsesPedigree
