import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'DBTKeenelandImage'

const recordNameSingular = 'dbtKeenelandImage'
const recordNamePlural = 'dbtKeenelandImages'
const i18nKey = 'dbt::KeenelandImage'

const primaryKey = 'keenelandImageId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtKeenelandImages'

const pageHelperKey = 'dbtKeenelandImages'

const resourcePathName = 'dbt_keeneland_image'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  blobId: {
    kind: 'column',
    name: 'blobId',
    type: 'integer',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
