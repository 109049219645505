import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { ImageSchema } from '@/schemas/image'
import { Image } from '@/types'

import { ImageResource } from './resource'

type ImageFormProps = Omit<UseFormProps<Image>, 'resource'>

const ImageForm = ({ zodSchema = ImageSchema, ...props }: ImageFormProps) => {
  const formConfig = useForm<Image>({
    resource: ImageResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="file" />
          <Field name="photoDate" />
          <Field name="angleType" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { ImageForm }
