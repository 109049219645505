// JsFromRoutes CacheKey df49305fedb696759c9c68fb3b6d85e9
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { HorseModel } from '@/models/horse'

export const bloodstockSearchApi = {
  search: defineEndpoint({
    controllerName: 'bloodstock_search',
    actionName: 'search',
    model: HorseModel,
    parts: ['api', 'horses', 'search'],
    httpMethod: 'get',
    type: 'list',
  }),
}
