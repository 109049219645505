import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTWorkout } from '@/types'

const name = 'DBTWorkout'

const recordNameSingular = 'dbtWorkout'
const recordNamePlural = 'dbtWorkouts'
const i18nKey = 'dbt::Workout'

const primaryKey = 'workoutId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtWorkouts'

const pageHelperKey = 'dbtWorkouts'

const resourcePathName = 'dbt_workout'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'DBTHorse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  track: {
    kind: 'association',
    name: 'track',
    modelName: 'DBTTrack',
    foreignKey: 'trackId',
    associationPrimaryKey: 'trackId',
  },
  workoutId: {
    kind: 'column',
    name: 'workoutId',
    type: 'text',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'text',
  },
  trackCourseId: {
    kind: 'column',
    name: 'trackCourseId',
    type: 'text',
  },
  trackAbbreviation: {
    kind: 'column',
    name: 'trackAbbreviation',
    type: 'text',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'float',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  time: {
    kind: 'column',
    name: 'time',
    type: 'float',
  },
  gate: {
    kind: 'column',
    name: 'gate',
    type: 'string',
  },
  type: {
    kind: 'column',
    name: 'type',
    type: 'string',
  },
  rank: {
    kind: 'column',
    name: 'rank',
    type: 'integer',
  },
  horses: {
    kind: 'column',
    name: 'horses',
    type: 'integer',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTWorkout> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
