import { Action } from '@/components/actions'
import { BaseDataTable } from '@/components/data-table/base_table'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { MatingResource } from '@/resources/mating'
import { matingTable } from '@/resources/mating/data-table'
import { matingsRoutes } from '@/routes'

const MatingsIndex = ({ urlParams: { year }, ...props }) => {
  const query = MatingResource.query.index.filter('year', year)

  const tableProps = matingTable.props(
    {
      query,
      clientSide: true,
      verticalScroll: true,
      defaultPageSize: 100,
      meta: {
        tableActions: [
          () => (
            <Action
              route={matingsRoutes.new.withOptions({ year })}
              name="new"
              variant="secondary"
              size="sm"
              label="New Mating"
            />
          ),
        ],
      },
    },
    { year: year },
  )

  const dataTable = useDataTable(tableProps)

  if (!year) {
    return <div>Missing year from url.</div>
  }

  return (
    <>
      <PageHeader title="Matings" />
      <BaseDataTable {...dataTable} />
    </>
  )
}

export default MatingsIndex
