import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Account } from '@/types'

const name = 'Account'

const recordNameSingular = 'account'
const recordNamePlural = 'accounts'
const i18nKey = 'account'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'accounts'

const pageHelperKey = 'accounts'

const resourcePathName = 'account'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  location: {
    kind: 'association',
    name: 'location',
    modelName: 'IntacctLocation',
    foreignKey: 'locationId',
    associationPrimaryKey: 'locationId',
  },
  sageClass: {
    kind: 'association',
    name: 'sageClass',
    modelName: 'IntacctSageClass',
    foreignKey: 'sageClassId',
    associationPrimaryKey: 'classId',
  },
  department: {
    kind: 'association',
    name: 'department',
    modelName: 'IntacctDepartment',
    foreignKey: 'departmentId',
    associationPrimaryKey: 'departmentId',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  sageClassId: {
    kind: 'column',
    name: 'sageClassId',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
}

const modelSpec: ModelSpec<Account> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
