import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'Syndicate'

const recordNameSingular = 'syndicate'
const recordNamePlural = 'syndicates'
const i18nKey = 'syndicate'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'syndicates'

const pageHelperKey = 'syndicates'

const resourcePathName = 'syndicate'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  managerEntity: {
    kind: 'association',
    name: 'managerEntity',
    modelName: 'Entity',
    foreignKey: 'managerEntityId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  managerEntityId: {
    kind: 'column',
    name: 'managerEntityId',
    type: 'integer',
  },
  formationDate: {
    kind: 'column',
    name: 'formationDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
