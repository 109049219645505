import { Action, Actions } from '@/components/actions'
import { defineTable } from '@/components/data-table/builder'
import { broodmareProspectTable } from '@/resources/broodmare_prospect'
import { dbtCareerRaceSummaryTable } from '@/resources/dbt/career_race_summary/data-table'
import { dbtSaleResultTable } from '@/resources/dbt/sale_result'
import { broodmareProspectsRoutes } from '@/routes'

import { dbtHorseTable } from '../dbt/horse'
import { dbtRaceResultTable } from '../dbt/race_result'
import { FiveCrossAction } from '../horse'
import { BroodmareProspectDetailResource } from './resource'

export const broodmareProspectDetailTable = defineTable(
  BroodmareProspectDetailResource,
  {
    horse: dbtHorseTable,
    raceResult: dbtRaceResultTable,
    raceEntry: dbtRaceResultTable,
    careerRaceSummary: dbtCareerRaceSummaryTable,
    lastSaleResult: dbtSaleResultTable,
    broodmareProspect: broodmareProspectTable,
  },
  (tbl, props): TableBuilderSpec<BroodmareProspectDetail> => ({
    columns: [
      tbl.col.raceResult.raceType({ label: 'Type', enableHiding: false }),
      tbl.col.horse.nameOverSireWithDam(),
      tbl.displayCol('actions', {
        enableColumnFilter: false,
        size: 100,
        header: () => null,
        cell: ({ row }) => (
          <Actions numToShow={1}>
            {row.original.broodmareProspect.id ? (
              <Action
                name="edit"
                route={broodmareProspectsRoutes.edit.withOptions({
                  broodmareProspectId: row.original.broodmareProspect.id,
                })}
              />
            ) : (
              <Action
                name="new"
                route={broodmareProspectsRoutes.new.withOptions({
                  horseId: row.original.horse.horseId,
                })}
              />
            )}
            <FiveCrossAction name="5-cross" label="5-cross" horseId={row.original.horse.horseId} />
          </Actions>
        ),
      }),

      tbl.col.broodmareProspect.rating(),
      tbl.col.broodmareProspect.comment(),
      tbl.col.careerRaceSummary.bestClassOverRaceRecord(),
      tbl.col.careerRaceSummary.earnings(),
      tbl.col.careerRaceSummary.lastWorkOverLastRace(),
      tbl.col.raceEntry.raceLabel({
        label: 'Entry',
        enableHiding: false,
        enableColumnFilter: false,
      }),
      tbl.col.raceEntry.raceLabelOverDate(),
      tbl.col.raceEntry.claimedPriceOverDistanceWithSurface(),
      tbl.col.raceResult.raceLabelOverDate(),
      tbl.col.raceResult.earningsOverDistanceWithSurface(),
      tbl.col.lastSaleResult.lastSale(),
    ],
  }),
)
