import { BaseDataTable } from '@/components/data-table/base_table'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { LayoutOutlet } from '@/pages/layouts/layout-outlet'
import {
  BroodmareProspectDetailResource,
  broodmareProspectDetailTable,
} from '@/resources/broodmare_prospect_detail'
import { formatDate, today } from '@/utils'

const BroodmareClaiming = () => {
  const query = BroodmareProspectDetailResource.query.index
    .filter('raceEntry__claimedPrice', 1, 'gteq')
    .filter('raceEntry__date', formatDate(today(), 'yyyy-MM-dd'), 'gteq')

  const tableProps = broodmareProspectDetailTable.props({
    query,
    defaultPageSize: 100,
    batchSize: 300,
  })

  const datatable = useDataTable(tableProps)

  return (
    <>
      <PageHeader title="Claiming" />
      <BaseDataTable {...datatable} />
      <LayoutOutlet />
    </>
  )
}

export default BroodmareClaiming
