import { z } from 'zod'

export const SaleSchema = z.object({
  name: z.string(),
  host: z.string(),
  location: z.union([z.string(), z.null()]).optional(),
  code: z.union([z.string(), z.null()]).optional(),
  saleType: z.union([z.string(), z.null()]).optional(),
  startDate: z.union([z.string().date(), z.null()]).optional(),
  endDate: z.union([z.string().date(), z.null()]).optional(),
  year: z.number().int(),
})
