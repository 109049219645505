import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const EntitiesShow = () => {
  const { entitiesShow } = useLoaderQueryBuilders()
  const { record: entity } = useResourceQuery(entitiesShow)

  return <Section title={entity.title} className="mt-16"></Section>
}

export default EntitiesShow
