import ModelSpecs from '@/models'

const findModelSpec = (name: string) => {
  const modelSpec = ModelSpecs[name]

  if (modelSpec == null) {
    throw new Error(`Invalid model spec name provided: ${name}`)
  }

  return modelSpec
}

export { findModelSpec }
