import { ResourceRecord } from '@/libs/resource/record'
import { SortingFn } from '@tanstack/react-table'

export const mappedSorter = (
  valueMap: Record<string | number, unknown>,
  defaultValue?: unknown,
): SortingFn<ResourceRecord> => {
  const sortFn: SortingFn<ResourceRecord> = (rowA, rowB, columnId) => {
    const valueA = valueMap[rowA.getValue(columnId)] ?? defaultValue
    const valueB = valueMap[rowB.getValue(columnId)] ?? defaultValue

    return valueA === valueB ? 0 : valueA > valueB ? 1 : -1
  }

  return sortFn
}
