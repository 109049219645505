import { z } from 'zod'

export const OrganizationHorseSchema = z.object({
  organizationId: z.number().int(),
  horseId: z.number().int(),
  arrived: z.string().date(),
  departed: z.union([z.string().date(), z.null()]).optional(),
  ownership: z.number().default(1),
  plan: z.union([z.string(), z.null()]).optional(),
  rating: z.union([z.string(), z.null()]).optional(),
  throatGrade: z.union([z.string(), z.null()]).optional(),
  conformationGrade: z.union([z.string(), z.null()]).optional(),
  xrayGrade: z.union([z.string(), z.null()]).optional(),
  cost: z.union([z.number(), z.null()]).optional(),
  totalEarnings: z.union([z.number(), z.null()]).optional(),
  totalExpenses: z.union([z.number(), z.null()]).optional(),
  projectedValue: z.union([z.number(), z.null()]).optional(),
  actualValue: z.union([z.number(), z.null()]).optional(),
  studFee: z.union([z.number(), z.null()]).optional(),
  netNet: z.union([z.number(), z.null()]).optional(),
  disposition: z
    .enum([
      'retain',
      'borderline',
      'sell_as_weanling',
      'sell_as_yearling',
      'sell_as_2yo',
      'sell_as_older_horse',
      'sell_in_foal',
      'sell_off_track',
      'sell_privately',
      'giveaway',
      'layup',
      'claimed',
      'died',
      'other',
      'sold',
      'broodmare_band',
    ])
    .optional(),
  category: z.union([z.string(), z.null()]).optional(),
  situation: z.union([z.string(), z.null()]).optional(),
  division: z.enum(['crops', 'racing', 'broodmares', 'stallions']).default('crops'),
  account: z.union([z.string(), z.null()]).optional(),
  accountId: z.union([z.number().int(), z.null()]).optional(),
  expectedDepartureDate: z.union([z.string().date(), z.null()]).optional(),
  manualFinancials: z.boolean().default(false),
})
