import { createRoutes } from '@/libs/router/route'
import { RacehorseCommentResource } from '@/resources/racehorse_comment/resource'

export const RacehorseCommentResourceRoutes = createRoutes({
  resource: RacehorseCommentResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'racehorse_comments',
      children: [
        {
          index: true,
          page: 'racehorse_comments/index',
          query: RacehorseCommentResource.query.index,
        },
        {
          path: 'new',
          page: 'racehorse_comments/new',
          query: RacehorseCommentResource.query.new,
        },
        {
          path: ':id',
          query: RacehorseCommentResource.query.show,
          children: [
            {
              index: true,
              page: 'racehorse_comments/show',
            },
            {
              path: 'edit',
              query: RacehorseCommentResource.query.edit,
              page: 'racehorse_comments/edit',
            },
            {
              path: 'destroy',
              page: 'racehorse_comments/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const RacehorseCommentRoutes = RacehorseCommentResourceRoutes
