import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { TrainingReportCard, TrainingReportResource } from '@/resources/training_report'
import { trainingReportsRoutes } from '@/routes'

const TrainingReportsIndex = () => {
  const queries = useLoaderQueryBuilders()
  const trainingReports = useResourceQuery(queries.trainingReportsIndex)

  if (trainingReports.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No training reports yet.</Text>
        <div>
          <Action route={trainingReportsRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">Training Reports</Text>
        <Action route={trainingReportsRoutes.new} name="new" variant="primary" />
      </div>
      <TrainingReportCard
        query={TrainingReportResource.query.show.withOptions({ id: records[0].id })}
      />
      <Section className="mt-16" flex>
        {trainingReports.records.slice(1).map((trainingReport) => (
          <TrainingReportCard
            key={trainingReport.id}
            query={TrainingReportResource.query.show.withOptions({ id: trainingReport.id })}
          />
        ))}
      </Section>
    </>
  )
}

export default TrainingReportsIndex
