import { snakeToCamel } from '@/utils'

export const enumFormatter =
  ({ options }) =>
  ({ getValue, table, column }) => {
    const {
      options: {
        meta: { t },
      },
    } = table
    const value = getValue()

    // TODO: Remove capitalization once we have proper
    // locale usage
    if (value == null) {
      return '-'
    }

    // TODO: Handle for arbitrary model/resource
    const attrKey = column.id.split('.').slice(-1)[0]

    const tKey = `enums.${attrKey}.${snakeToCamel(options[value])}`
    const label = t(tKey)

    return label
  }
