import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { OrganizationHorse } from '@/types'

const name = 'OrganizationHorse'

const recordNameSingular = 'organizationHorse'
const recordNamePlural = 'organizationHorses'
const i18nKey = 'organizationHorse'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'organizationHorses'

const pageHelperKey = 'organizationHorses'

const resourcePathName = 'organization_horse'

const attributes: Record<string, AttributeSpec> = {
  account: {
    kind: 'column',
    name: 'account',
    type: 'string',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  arrived: {
    kind: 'column',
    name: 'arrived',
    type: 'date',
  },
  departed: {
    kind: 'column',
    name: 'departed',
    type: 'date',
  },
  ownership: {
    kind: 'column',
    name: 'ownership',
    type: 'decimal',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  plan: {
    kind: 'column',
    name: 'plan',
    type: 'string',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  throatGrade: {
    kind: 'column',
    name: 'throatGrade',
    type: 'string',
  },
  conformationGrade: {
    kind: 'column',
    name: 'conformationGrade',
    type: 'string',
  },
  xrayGrade: {
    kind: 'column',
    name: 'xrayGrade',
    type: 'string',
  },
  cost: {
    kind: 'column',
    name: 'cost',
    type: 'decimal',
  },
  totalEarnings: {
    kind: 'column',
    name: 'totalEarnings',
    type: 'decimal',
  },
  totalExpenses: {
    kind: 'column',
    name: 'totalExpenses',
    type: 'decimal',
  },
  projectedValue: {
    kind: 'column',
    name: 'projectedValue',
    type: 'decimal',
  },
  actualValue: {
    kind: 'column',
    name: 'actualValue',
    type: 'decimal',
  },
  studFee: {
    kind: 'column',
    name: 'studFee',
    type: 'decimal',
  },
  netNet: {
    kind: 'column',
    name: 'netNet',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  disposition: {
    kind: 'column',
    name: 'disposition',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'retain',
        },
        {
          value: 1,
          key: 'borderline',
        },
        {
          value: 2,
          key: 'sell_as_weanling',
        },
        {
          value: 3,
          key: 'sell_as_yearling',
        },
        {
          value: 4,
          key: 'sell_as_2yo',
        },
        {
          value: 5,
          key: 'sell_as_older_horse',
        },
        {
          value: 6,
          key: 'sell_in_foal',
        },
        {
          value: 7,
          key: 'sell_off_track',
        },
        {
          value: 8,
          key: 'sell_privately',
        },
        {
          value: 9,
          key: 'giveaway',
        },
        {
          value: 10,
          key: 'layup',
        },
        {
          value: 11,
          key: 'claimed',
        },
        {
          value: 12,
          key: 'died',
        },
        {
          value: 13,
          key: 'other',
        },
        {
          value: 14,
          key: 'sold',
        },
        {
          value: 15,
          key: 'broodmare_band',
        },
      ],
    },
  },
  category: {
    kind: 'column',
    name: 'category',
    type: 'string',
  },
  situation: {
    kind: 'column',
    name: 'situation',
    type: 'text',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'crops',
        },
        {
          value: 1,
          key: 'racing',
        },
        {
          value: 2,
          key: 'broodmares',
        },
        {
          value: 3,
          key: 'stallions',
        },
      ],
    },
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  expectedDepartureDate: {
    kind: 'column',
    name: 'expectedDepartureDate',
    type: 'date',
  },
  manualFinancials: {
    kind: 'column',
    name: 'manualFinancials',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<OrganizationHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
