import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { JockeyClubBreedingStat } from '@/types'

const name = 'JockeyClubBreedingStat'

const recordNameSingular = 'jockeyClubBreedingStat'
const recordNamePlural = 'jockeyClubBreedingStats'
const i18nKey = 'jockeyClub::BreedingStat'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'jockeyClubBreedingStats'

const pageHelperKey = 'jockeyClubBreedingStats'

const resourcePathName = 'jockey_club_breeding_stat'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionName: {
    kind: 'column',
    name: 'stallionName',
    type: 'citext',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  maresBred: {
    kind: 'column',
    name: 'maresBred',
    type: 'integer',
  },
  reportsReceived: {
    kind: 'column',
    name: 'reportsReceived',
    type: 'integer',
  },
  liveFoals: {
    kind: 'column',
    name: 'liveFoals',
    type: 'integer',
  },
  percentLiveFoals: {
    kind: 'column',
    name: 'percentLiveFoals',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<JockeyClubBreedingStat> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
