import { modelNameFromAttributeId } from '../resource/attribute'
import { findModel } from '../resource/find_model'
import { ModelSpecName, PrimaryKeyValue, Resource, ResourceRecord } from '../resource/types'

export interface ModelFrame {
  kind: 'model'
  name: ModelSpecName
}

export interface AttributeFrame {
  kind: 'attribute'
  attributeId: string
  value: unknown
}

export type ContextFrame = ModelFrame | AttributeFrame

export interface BuildFrameProps<T extends ResourceRecord = ResourceRecord> {
  resource?: Resource<T>
  name?: ModelSpecName
  id?: PrimaryKeyValue
  attributeId?: string
  value?: unknown
}

export const isValidFrame = (frame?: ContextFrame) => {
  if (frame == null || typeof frame !== 'object') {
    return false
  }

  if (frame.kind === 'attribute' && frame.attributeId !== undefined) {
    return true
  }

  if (frame.kind === 'model' && frame.name !== undefined) return true

  return false
}

export const buildFrame = <T extends ResourceRecord>({
  resource,
  name,
  attributeId,
  id,
  ...props
}: BuildFrameProps<T>): ContextFrame | undefined => {
  const { value = typeof id === 'number' ? id.toString() : id } = props

  if (attributeId !== undefined) {
    return { kind: 'attribute', attributeId, value }
  }

  if (name == null && resource == null) {
    return
  }

  if (value == null) {
    if (resource != null) {
      return { kind: 'model', name: resource.name }
    }

    if (name != null) {
      return { kind: 'model', name }
    }
  }

  const getPrimaryKeyId = ({ resource, name }: { resource?: Resource; name?: ModelSpecName }) => {
    if (resource != null) {
      const primaryKey = resource.model.getAttribute(resource.model.primaryKey)
      return primaryKey?.globalId
    }

    if (name != null) {
      const model = findModel(name)
      const primaryKey = model?.getAttribute(model.primaryKey)

      return primaryKey?.globalId
    }
  }

  const primaryKeyId = getPrimaryKeyId({ resource, name })

  if (primaryKeyId == null) {
    return
  }

  return { kind: 'attribute', attributeId: primaryKeyId, value }
}

/**
 * Retrieves the corresponding model name from a frame
 */
const extractModelFromFrame = (frame: ContextFrame) =>
  frame.kind === 'model' ? frame.name : modelNameFromAttributeId(frame.attributeId)

/**
 * Check if a new frame is different than an existing one.
 *
 * A frame is 'different' if it belongs to a different Model
 * or if it is more specific than the existing
 * (i.e., new frame is an attribute and old frame is model)
 *
 * 'orthogonal'
 * 'redundant'
 * 'supersedes'
 **/

type FrameCompareResult = 'orthogonal' | 'redundant' | 'supersedes'

export const compareFrames = (
  newFrame?: ContextFrame,
  oldFrame?: ContextFrame,
): FrameCompareResult => {
  if (newFrame === undefined || !isValidFrame(newFrame)) {
    return 'redundant'
  }

  if (oldFrame === undefined || !isValidFrame(oldFrame)) {
    return 'supersedes'
  }

  const newFrameModel = extractModelFromFrame(newFrame)
  const oldFrameModel = extractModelFromFrame(oldFrame)

  if (newFrameModel !== oldFrameModel) {
    return 'orthogonal'
  }

  if (newFrame.kind !== 'attribute') {
    return 'redundant'
  }

  if (oldFrame.kind !== 'attribute') return 'supersedes'

  if (oldFrame.attributeId !== newFrame.attributeId) return 'orthogonal'

  if (oldFrame.value == null && newFrame.value != null) return 'supersedes'

  if (oldFrame.value !== newFrame.value) return 'orthogonal'

  return 'redundant'
}
