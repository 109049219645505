import {
  endOfMonth,
  endOfYear,
  endOfQuarter,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
} from 'date-fns'

import { formatDate, parseDate } from './utils'

export interface Period {
  type: 'Month' | 'Quarter' | 'Year'
  startDate: Date
  endDate: Date
}

export interface BuildPeriodProps {
  type: Period['type']
  startDate: Date
  endDate?: Date
}

/**
 * Helper to build a Period object
 *
 * Will automatically derive the endDate if not provided
 */
export const buildPeriod = ({ type, startDate, ...props }: BuildPeriodProps): Period => {
  const endDate = props.endDate ? props.endDate : derivePeriodEndDate(type, startDate)

  return {
    type,
    startDate,
    endDate,
  }
}

/**
 * Utility to derive the appropriate endDate for a given period
 */
export const derivePeriodEndDate = (type: Period['type'], startDate: Date): Date => {
  if (type === 'Month') {
    return endOfMonth(startDate)
  }

  if (type === 'Quarter') {
    return endOfQuarter(startDate)
  }

  if (type === 'Year') {
    return endOfYear(startDate)
  }

  throw new Error(`Invalid period type '${type}' provided.`)
}

/**
 * Returns list of dates for a given period type in the given interval
 */
export const periodDatesOfInterval = (type: Period['type'], start: Date, end: Date): Date[] => {
  if (type === 'Month') return eachMonthOfInterval({ start, end })

  if (type === 'Quarter') return eachQuarterOfInterval({ start, end })

  if (type === 'Year') return eachYearOfInterval({ start, end })

  throw new Error(`Invalid period type provided ${type}`)
}

/**
 * Generates a list of periods of the specified type between the
 * given dates
 */
export const generatePeriods = (type: Period['type'], start: Date, end: Date) => {
  const dates = periodDatesOfInterval(type, start, end)

  return dates.map((date) => ({
    type,
    startDate: date,
    endDate: derivePeriodEndDate(type, date),
  }))
}

/**
 * Produces an appropriately formatted date
 * for the given period
 */
export const formatPeriod = (period: Period): string => {
  if (period.type === 'Year') return formatDate(period.startDate, 'yyyy')

  if (period.type === 'Quarter') return formatDate(period.startDate, "QQQ ''yy")

  if (period.type === 'Month') return formatDate(period.startDate, "MMM ''yy")

  throw new Error(`Given 'Period' has invlaid type ${period}`)
}

/**
 * Serializes a Period as a parseable string primarily
 * used for building unique IDs
 *
 * <Type>-<StartDate>-<EndDate>
 *
 * e.g., Month-20240101-20240131
 */
export const serializePeriod = (period: Period) => {
  return [period.type, formatDate(period.startDate), formatDate(period.endDate)].join('-')
}

/**
 * Parse scenario from string
 */
export const parsePeriod = (input: string): Period | never => {
  const [type, startDate, endDate] = input.split('-')

  if (type !== 'Month' && type !== 'Quarter' && type !== 'Year')
    throw new Error(`Cannot parse period '${input}'. Type is invlalid.`)

  try {
    return {
      type,
      startDate: parseDate(startDate),
      endDate: parseDate(endDate),
    }
  } catch (error) {
    throw new Error(`Cannot parse period '${input}'. At least one date is invalid: ${error}`)
  }
}
