import { booleanFormatter, currencyFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'

import { StudFeeResource } from './resource'

export const studFeeTable = defineTable(StudFeeResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.stallionId(),
    tbl.col.year(),
    tbl.col.price({ cell: currencyFormatter() }),
    tbl.col.currency(),
    tbl.col.private({ cell: booleanFormatter() }),
    tbl.col.farm(),
    tbl.col.location(),
    tbl.col.status(),
  ],
}))
