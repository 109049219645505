import { enumFormatter, valueFormatter } from '@/components/data-table/formatters'
import i18n from '@/libs/i18n/config'
import { ColumnAttribute, enumDomainToMap } from '@/libs/resource/attribute'
import { type Attribute } from '@/libs/resource/types'

export const defaultCell = (attribute: Attribute) => {
  if (attribute.kind !== 'column') {
    return
  }

  switch (attribute.type) {
    case 'enum':
      return enumFormatter({
        options: enumDomainToMap(attribute.domain),
      })
    default:
      return valueFormatter()
  }
}

export const defaultFilter = (attribute: ColumnAttribute) => {
  if (attribute.name.endsWith('Id')) {
    return {}
  }

  switch (attribute.type) {
    case 'integer':
    case 'float':
    case 'decimal':
      return { key: 'between' }

    case 'enum':
      return { key: 'multiSelect' }

    case 'text':
    case 'citext':
    case 'string':
      return { key: 'startsWith' }

    // TODO: Handle filters for these types
    case 'date':
    case 'datetime':
    default:
      return {}
  }
}

export const defaultAggregationFn = (attribute: ColumnAttribute) => {
  if (attribute.name.endsWith('Id')) {
    return 'count'
  }

  switch (attribute.type) {
    case 'integer':
    case 'float':
    case 'decimal':
      return 'sum'

    case 'enum':
    case 'text':
    case 'citext':
    case 'string':
    case 'date':
    case 'datetime':
    default:
      return
  }
}

export const defaultLabel = (attribute: Attribute) => {
  if (attribute.kind === 'association') {
    return i18n.t(`${attribute.name}.one`, attribute.name)
  }
}
