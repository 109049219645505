import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { InfoTile } from '@/components/info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { formatCurrency, formatDate } from '@/utils'

import { OrganizationHorseResource } from './resource'

export const OrganizationHorseContent = ({
  organizationHorse,
}: {
  organizationHorse: {
    division: string
    account: { name: string }
    disposition: string
    plan: string
    rating: number
    arrived: string
    departed: string
    actualValue: number
    cost: number
    studFee: number
    ownership: number
  }
}) => {
  return (
    <div className="flex-1 @container">
      <div className="mt-2 flex w-full flex-col gap-2 py-0 @2xl:flex-row">
        <div className="flex flex-1 gap-2 @2xl:flex-col">
          <InfoTile className="w-1/2 capitalize @2xl:w-full" label="Division">
            {organizationHorse.division}
          </InfoTile>
          <InfoTile className="flex-1" label="Account">
            {organizationHorse.account.name}
          </InfoTile>
        </div>
        {!organizationHorse.departed && (
          <>
            <div className="flex flex-col gap-2 @2xl:w-1/2">
              <InfoTile className="capitalize" label="Disposition">
                {organizationHorse.disposition.replace(/_/g, ' ')}
              </InfoTile>
              <InfoTile className="capitalize" label="Plan">
                {organizationHorse.plan}
              </InfoTile>
            </div>
            <DataTile className="flex-1" label="Rating">
              {organizationHorse.rating}
            </DataTile>
          </>
        )}
        {organizationHorse.departed && (
          <>
            <div className="flex flex-col gap-2 @2xl:w-1/2">
              <InfoTile label="Arrived">{formatDate(organizationHorse.arrived)}</InfoTile>
              <InfoTile className="capitalize" label="Departed">
                {formatDate(organizationHorse.departed)}
              </InfoTile>
            </div>
            <DataTile className="flex-1" label="Net Sale">
              {formatCurrency(
                (organizationHorse.actualValue -
                  organizationHorse.cost -
                  organizationHorse.studFee) *
                  organizationHorse.ownership,
              )}
            </DataTile>
          </>
        )}
      </div>
    </div>
  )
}

export const OrganizationHorseObject = ({ ...props }) => {
  return (
    <Card {...props}>
      <OrganizationHorseContent {...props} />
    </Card>
  )
}

export const OrganizationHorseSummaryContent = ({ organizationHorses }) => {
  return <OrganizationHorseContent organizationHorse={organizationHorses[0]} />
}

export const OrganizationHorseCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: organizationHorse } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={OrganizationHorseResource.routes.index}>{organizationHorse.title}</Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={OrganizationHorseResource} iconOnly />
        </CardActions>
      </CardHeader>
      <CardContent>
        <OrganizationHorseContent organizationHorse={organizationHorse} />
      </CardContent>
    </Card>
  )
}
export const OwnershipSummaryCard = <T extends ResourceRecord>({
  className,
  query,
}: {
  className?: string
  query: QueryBuilder<T>
}) => {
  const { records: organizationHorses, error, isPending } = useResourceQuery(query)
  const pluralName = 'Ownership'

  return (
    <SummaryCard
      actions={<NewAction resource={OrganizationHorseResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={OrganizationHorseResource.routes.index}
    >
      {organizationHorses.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <OrganizationHorseSummaryContent organizationHorses={organizationHorses} />
      )}
    </SummaryCard>
  )
}
