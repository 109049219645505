import { useState, type Dispatch, type SetStateAction } from 'react'

import type { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table'

interface HookArgs {
  defaultSorting: SortingState
  defaultPageSize: number
}

interface HookRet {
  columnFilters: ColumnFiltersState
  pagination: PaginationState
  sorting: SortingState
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>
  setPagination: Dispatch<SetStateAction<PaginationState>>
  setSorting: Dispatch<SetStateAction<SortingState>>
}

/**
 * This hook takes care of mirroring certain Tanstack Table props in
 * the URL search parameters. It tries to read the initial Table props
 * from the search parameters, and then afterwards updates the search
 * parameters whenever those table props change
 */
export function useTableParams(args: HookArgs): HookRet {
  const { defaultPageSize, defaultSorting } = args

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  })

  const [sorting, setSorting] = useState<SortingState>(defaultSorting)

  return {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  }
}
