import * as React from 'react'

import { cn } from '@/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string | React.ReactNode
  suffix?: string | React.ReactNode
}

function renderAffix(affix: string | React.ReactNode, position: 'prefix' | 'suffix') {
  return (
    <div
      className={cn(
        'flex select-none items-center justify-center rounded bg-muted/70 px-3 text-muted-foreground',
        position === 'prefix' ? 'rounded-r-none' : 'rounded-l-none',
      )}
    >
      {affix}
    </div>
  )
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefix, suffix, ...props }, ref) => {
    return (
      <div className="flex rounded border border-input focus-within:border-input-focus">
        {prefix && renderAffix(prefix, 'prefix')}
        <input
          type={type}
          className={cn(
            'w-full rounded px-2 py-1.5 text-sm',
            'file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
            'focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            prefix && 'rounded-l-none',
            suffix && 'rounded-r-none',
            className,
          )}
          ref={ref}
          {...props}
        />
        {suffix && renderAffix(suffix, 'suffix')}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
