import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'KeenelandSale'

const recordNameSingular = 'keenelandSale'
const recordNamePlural = 'keenelandSales'
const i18nKey = 'keeneland::Sale'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'keenelandSales'

const pageHelperKey = 'keenelandSales'

const resourcePathName = 'keeneland_sale'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  keenelandSaleId: {
    kind: 'column',
    name: 'keenelandSaleId',
    type: 'integer',
  },
  saleType: {
    kind: 'column',
    name: 'saleType',
    type: 'string',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  numberOfSessions: {
    kind: 'column',
    name: 'numberOfSessions',
    type: 'integer',
  },
  summaryName: {
    kind: 'column',
    name: 'summaryName',
    type: 'string',
  },
  isComparative: {
    kind: 'column',
    name: 'isComparative',
    type: 'boolean',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
