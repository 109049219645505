// JsFromRoutes CacheKey 1f3be57cf35dca7500c646c2d3fc60c3
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { AccountModel } from '@/models/account'

export const accountsApi = {
  autocomplete: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'autocomplete',
    model: AccountModel,
    parts: [],
    httpMethod: 'get',
  }),
  index: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'index',
    model: AccountModel,
    parts: ['api', 'accounts'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'create',
    model: AccountModel,
    parts: ['api', 'accounts'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'new',
    model: AccountModel,
    parts: ['api', 'accounts', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'edit',
    model: AccountModel,
    parts: ['api', 'accounts', { name: 'id', attributeId: 'Account:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'show',
    model: AccountModel,
    parts: ['api', 'accounts', { name: 'id', attributeId: 'Account:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'update',
    model: AccountModel,
    parts: ['api', 'accounts', { name: 'id', attributeId: 'Account:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'accounts',
    actionName: 'destroy',
    model: AccountModel,
    parts: ['api', 'accounts', { name: 'id', attributeId: 'Account:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
