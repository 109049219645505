import { LinkTile } from '@/components/link_tile'
import { PanelContent } from '@/components/panel/content'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
} from '@/components/ui/sidebar'
import { useRecentlyVisited } from '@/hooks/use_recently_visited'
import { HorseResource } from '@/resources/horse'

import { stallionsPanelConfig } from '../config/stallions'

// TODO: Needs working links
export const StallionsPanel = () => {
  const { queue } = useRecentlyVisited({ key: 'recentHorses' })

  return (
    <PanelContent configFn={stallionsPanelConfig} title="Stallions">
      {queue.length > 0 ? (
        <ScrollArea>
          <SidebarGroup>
            <SidebarGroupLabel>Recent</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {queue.map((item) => (
                  <LinkTile
                    key={item.id}
                    resource={HorseResource}
                    options={{ horseId: item.id }}
                    id={item.id}
                    label=""
                  >
                    {item.name}
                  </LinkTile>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </ScrollArea>
      ) : null}
    </PanelContent>
  )
}
