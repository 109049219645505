import React from 'react'

import { Header, Actions, Title, Description } from '@/components/header'
import { Tile } from '@/components/tile'
import { Text } from '@/components/ui/text'
import { cva } from '@/utils'

const dataTileVariants = cva({
  base: 'font-normal',
  variants: {
    size: {
      unset: null,
      sm: 'text-xl',
      md: 'text-3xl',
      lg: 'text-4xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type DataTileProps = React.HTMLAttributes<HTMLDivElement> & {
  label: string
  caption: string
  icon: React.ReactNode
  children: React.ReactNode
}

const DataTile = ({
  label,
  caption,
  icon,
  children,
  size = '3xl',
  muted,
  ...props
}: DataTileProps) => {
  return (
    <Tile
      {...props}
      className={dataTileVariants({ class: 'flex flex-col justify-between', ...props })}
    >
      {(label || caption || icon) && (
        <Header className="items-start">
          <Title as="span" className="uppercase" size="sm" muted={muted}>
            {label}
          </Title>
          <Description size="xs">{caption}</Description>
          <Actions>{icon}</Actions>
        </Header>
      )}
      <Text size={size}>{children}</Text>
    </Tile>
  )
}

export { DataTile, dataTileVariants }
