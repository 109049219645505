import { SummaryCard } from '@/components/cards'
import { RankBar } from '@/components/charts/rank_bar'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { FinalTag, Tag } from '@/components/ui/tag'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { Mating } from '@/types'
import { defaultMatingYear } from '@/utils'

import { esnColor } from '../models/fpi_prediction/utils'
import { MatingResource } from './resource'

interface MatingContentProps {
  matings: Mating[]
  label?: string
  inline?: boolean
  muted?: boolean
  latest?: boolean
}

export const MatingSummaryContent = ({ matings, muted = false }: MatingContentProps) => {
  const currentYear = defaultMatingYear()
  const currentYearMatings = matings.filter((mating) => mating.year === currentYear)

  return (
    <div className="flex flex-col gap-6">
      {currentYearMatings.length > 0 ? (
        <Link
          key={matings[0].id}
          className="h-16 w-full justify-between align-middle"
          variant={'outline'}
          route={MatingResource.routes.show.withOptions({ matingId: matings[0].id })}
          muted={muted}
        >
          <div className="flex items-center gap-2">
            <Text as="span" size="lg" className="font-normal">
              {matings[0].stallion.title}
            </Text>
            {matings[0].final ? <FinalTag className="min-h-7 min-w-16" /> : null}
          </div>
          <Tag
            variant="inline"
            size="md"
            muted={muted}
            className={`bg-${esnColor(0.026)} rounded p-2 text-white`}
          >
            2.6%
          </Tag>
        </Link>
      ) : (
        <Text as="span" size="lg" className="text-center font-normal">
          No final mating this year
        </Text>
      )}
      <RankBar
        className="w-full"
        values={[80, 50, 30]}
        marker={{ value: 26, tooltip: 'Rank Goes Here', showAnimation: true }}
        colors={['red', 'yellow', 'green']}
      />
    </div>
  )
}

export const MatingSummaryCard = <T extends ResourceRecord>({
  className,
  query,
}: {
  className?: string
  query: QueryBuilder<T>
}) => {
  const { records: matings, error, isPending } = useResourceQuery(query)

  return (
    <SummaryCard
      actions={<NewAction resource={MatingResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={MatingResource.model.recordNamePlural}
      queryError={error}
      titleRoute={MatingResource.routes.index}
    >
      <MatingSummaryContent matings={matings} inline muted />
    </SummaryCard>
  )
}
