import React from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'

export const RangeFilterControl = ({ label, value, onChange }) => {
  const handleChange = (event, type) => {
    event.preventDefault()
    if (type !== 'min' && type !== 'max') return

    const newValue = event.target.value

    onChange({
      ...value,
      [type]: newValue,
    })
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex w-full items-center justify-between p-2">
        {label}
        <Button
          variant="inline"
          size="sm"
          className="justify-start text-orange"
          onClick={() => onChange(null)}
        >
          clear
        </Button>
      </div>
      <Separator className="w-full bg-grey-500" />
      <div className="flex-inline flex gap-4 p-2">
        <Input
          id="min"
          name="min"
          type="number"
          placeholder={`Min ${label}`}
          value={value?.min ?? ''}
          onChange={(e) => {
            handleChange(e, 'min')
          }}
        />

        <Input
          id="max"
          name="max"
          type="number"
          placeholder={`Max ${label}`}
          value={value?.max ?? ''}
          onChange={(e) => {
            handleChange(e, 'max')
          }}
        />
      </div>
    </div>
  )
}
