import { createRoutes } from '@/libs/router/route'
import { AppraisalResource } from '@/resources/appraisal/resource'

export const AppraisalResourceRoutes = createRoutes({
  resource: AppraisalResource,
  routes: [
    {
      path: 'appraisals',
      children: [
        {
          index: true,
          page: 'appraisals/index',
          query: AppraisalResource.query.index.sort('date', 'desc'),
        },
        {
          path: 'new',
          page: 'appraisals/new',
          query: AppraisalResource.query.new,
        },
        {
          path: ':id',
          query: AppraisalResource.query.show,
          children: [
            {
              index: true,
              page: 'appraisals/show',
            },
            {
              path: 'edit',
              query: AppraisalResource.query.edit,
              page: 'appraisals/edit',
            },
            {
              path: 'destroy',
              page: 'appraisals/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const AppraisalRoutes = AppraisalResourceRoutes
