import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { Action } from '@/components/actions'
import { DataTile } from '@/components/data_tile'
import { Plus } from '@/components/icons'
import { EditAction } from '@/components/resource'
import { Button } from '@/components/ui/button'
import { Card, CardHeader } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { ScrollArea } from '@/components/ui/scroll-area'
import { FinalTag } from '@/components/ui/tag'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { HypotheticalFiveCrossAction } from '@/resources/horse'
import { MatingForm, MatingResource } from '@/resources/mating'
import { matingsRoutes } from '@/routes'
import { cn, formatCurrency } from '@/utils'

const CheckboxLabel = ({ checked, label, ...props }) => (
  <div className="flex items-center gap-2">
    <Checkbox checked={checked} className="rounded-full" {...props} />
    <Text as="span" size="sm" className="text-nowrap font-semibold">
      {label}
    </Text>
  </div>
)

const MatingsIndex = () => {
  const [show, setShow] = React.useState(false)
  const [year, setYear] = React.useState('')

  const { horseId } = useParams()

  const { records: matings } = useResourceQuery(
    MatingResource.query.index.sort('final', 'desc').sort('year', 'desc').filter('mareId', horseId),
  )

  // Group matings by year using a Map to maintain order
  const groupedMatings = matings.reduce((acc, mating) => {
    const year = mating.year
    if (!acc.has(year)) acc.set(year, [])
    acc.get(year).push(mating)
    return acc
  }, new Map())

  if (matings.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No matings yet.</Text>
        <div>
          <Action route={matingsRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <Text as="h3">Matings</Text>
      </div>
      <ScrollArea className="flex h-[calc(100%-1rem)] w-[34rem] flex-col px-4">
        <div
          data-height={show}
          className={cn(
            'origin-top transition-all duration-300 ease-in-out',
            'data-[height=true]:h-[55rem] data-[height=true]:scale-y-100',
            'data-[height=false]:h-0 data-[height=false]:scale-y-0',
          )}
        >
          <Suspense>
            <MatingForm initialValues={{ year }} />
          </Suspense>
        </div>
        {[...groupedMatings.keys()].map((year) => (
          <div key={year} className="flex flex-col gap-2">
            <div className="mb-2 mt-6 flex items-center justify-between">
              <Text as="span" size="xl" className="font-semibold">
                {year}
              </Text>
              <Button
                iconLeft={<Plus />}
                onClick={() => {
                  setShow((current) => !current)
                  setYear(year)
                }}
              >
                New
              </Button>
            </div>
            <div className="flex flex-wrap gap-2">
              {groupedMatings.get(year).map((mating) => (
                <Card key={mating.id} className="w-full justify-center">
                  <CardHeader>
                    <div className="flex items-center gap-2">
                      <Text as="h3" size="xl" className="font-semibold">
                        {mating.stallion.name}
                      </Text>
                      {mating.final && <FinalTag />}
                    </div>
                    <div className="flex gap-2">
                      <HypotheticalFiveCrossAction
                        damId={horseId}
                        sireId={mating.stallion.id}
                        variant="primary"
                        iconOnly
                      />
                      <EditAction
                        resource={MatingResource}
                        route={matingsRoutes.edit.withOptions({ matingId: mating.id })}
                        variant="primary"
                        iconOnly
                      />
                    </div>
                  </CardHeader>
                  <div className="flex justify-between gap-2">
                    <div className="grid grid-cols-2">
                      <CheckboxLabel checked={mating.pedigreePass} label="Pedigree" />
                      <CheckboxLabel checked={mating.physicalPass} label="Physical" />
                      <CheckboxLabel checked={mating.financialsPass} label="Financials" />
                      <CheckboxLabel
                        checked={mating.stallionAvailabilityPass}
                        label="Stallion Availability"
                      />
                    </div>
                    <DataTile label="Stud Fee" className="w-40">
                      {mating.studFee ? `$${formatCurrency(mating.studFee)}` : '-'}
                    </DataTile>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </ScrollArea>
    </>
  )
}

export default MatingsIndex
