import { useState } from 'react'
import { RouteObject, UIMatch, useMatches } from 'react-router-dom'

import { ChevronRight, ChevronUp, Sitemap, Square, X } from '@/components/icons'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Text } from '@/components/ui/text'
import { builtRoutes } from '@/router'
import { cn } from '@/utils'

export const RouteDevtool = () => {
  if (process.env.NODE_ENV !== 'development') return null
  const [collapsed, setCollapsed] = useState(true)
  const match = useMatches().reverse()[0]
  return (
    <ul
      className={cn(
        'fixed z-[55] bg-white',
        collapsed
          ? 'bottom-0 left-10 flex items-center justify-center rounded-t bg-primary px-2 text-xs text-white'
          : 'left-0 top-0 h-svh w-[25rem]',
      )}
    >
      {collapsed ? (
        <Button
          iconRight={<ChevronUp />}
          onClick={() => {
            setCollapsed(false)
          }}
          size="sm"
        >
          Routes
        </Button>
      ) : (
        <ScrollArea className="h-svh p-6">
          <Button
            iconRight={<X />}
            onClick={() => {
              setCollapsed(true)
            }}
            size="sm"
            iconOnly
            className="absolute right-3 top-3"
          />
          {builtRoutes.map((route) => (
            <RouteItem route={route} match={match} key={route.id} />
          ))}
        </ScrollArea>
      )}
    </ul>
  )
}

function RouteItem({ route, match }: { route: RouteObject; match: UIMatch }) {
  const [routeIsOpen, setRouteIsOpen] = useState(false)

  let statusVariant = ''
  if (route.handle?.status === 'preview') {
    statusVariant = 'active'
  } else if (route.handle?.status === 'inProgress') {
    statusVariant = 'wip'
  }
  return (
    <li className="my-1.5">
      <span className="flex items-center gap-1.5">
        {route.children ? (
          <button
            onClick={() => {
              setRouteIsOpen(!routeIsOpen)
            }}
          >
            <ChevronRight
              data-open={routeIsOpen}
              className="-mr-1 size-3 data-[open=true]:rotate-90"
            />
          </button>
        ) : null}
        {route.children ? (
          <Sitemap className={route.children ? '' : 'ml-3.5'} />
        ) : (
          <Square className="ml-3.5 px-0.5" />
        )}
        <Text
          className={cn(
            route.handle?.status === 'planned' ? 'text-muted-foreground' : '',
            JSON.stringify(route.handle.routeParts) === JSON.stringify(match.handle.routeParts)
              ? 'font-bold'
              : '',
          )}
        >
          {route.index ? 'index' : (route.id ?? route.path)}
        </Text>
        <Badge variant={statusVariant} />
      </span>
      {routeIsOpen ? (
        <ul className="pl-8">
          {route.children?.map((child) => <RouteItem route={child} match={match} key={child.id} />)}
        </ul>
      ) : null}
    </li>
  )
}
