import { enumDomainToMap } from '@/libs/resource/attribute'
import { findModel } from '@/libs/resource/find'

export const formatBestClass = (bestClass) => {
  if (bestClass == null) {
    return 'UNR'
  }

  const model = findModel('DBTCareerRaceSummary')

  const classMap = enumDomainToMap(model.attributes.bestClass.domain)

  return classMap[bestClass]?.toUpperCase() ?? '-'
}

export const bestClassStyle = (bestClass) => {
  if (bestClass > 11) {
    return 'text-green font-semibold'
  } else if (bestClass > 3) {
    return 'font-semibold'
  } else {
    return ''
  }
}
