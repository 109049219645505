import { findModelSpec } from './find_model_spec'
import { defineModel } from './model'

const findModel = (name: string) => {
  const modelSpec = findModelSpec(name)
  if (modelSpec == null) {
    return
  }

  return defineModel(modelSpec)
}

const findModelFromAttributeId = (attributeId: string) => findModel(attributeId.split(':')[0])

export { findModel, findModelFromAttributeId }
