import { z } from 'zod'

export const UserSchema = z.object({
  email: z.string(),
  encryptedPassword: z.union([z.string(), z.null()]).optional(),
  resetPasswordToken: z.union([z.string(), z.null()]).optional(),
  resetPasswordSentAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  rememberCreatedAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  signInCount: z.number().int().default(0),
  currentSignInAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  lastSignInAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  currentSignInIp: z.union([z.any(), z.null()]).optional(),
  lastSignInIp: z.union([z.any(), z.null()]).optional(),
  organizationId: z.union([z.number().int(), z.null()]).optional(),
  firstName: z.union([z.string(), z.null()]).optional(),
  lastName: z.union([z.string(), z.null()]).optional(),
  invitationToken: z.union([z.string(), z.null()]).optional(),
  invitationCreatedAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  invitationSentAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  invitationAcceptedAt: z.union([z.string().datetime({ offset: true }), z.null()]).optional(),
  invitationLimit: z.union([z.number().int(), z.null()]).optional(),
  invitedById: z.union([z.number().int(), z.null()]).optional(),
  invitedByType: z.union([z.string(), z.null()]).optional(),
  jti: z.string(),
  apiKey: z.string(),
  active: z.boolean().default(true),
  userType: z.enum(['normal', 'tableau', 'dropbox']).default('normal'),
  tableauUserId: z.union([z.number().int(), z.null()]).optional(),
  userToken: z.union([z.string(), z.null()]).optional(),
})
