import { defineTable } from '@/components/data-table/builder'

import { FinanceCustomerResource } from './resource'

export const financeCustomerTable = defineTable(FinanceCustomerResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.customerId(),
    tbl.col.name(),
    tbl.col.entity(),
    tbl.col.parentId(),
    tbl.col.status(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
  ],
}))
