import { defineTable } from '@/components/data-table/builder'
import { hfmWinstarClientTable } from '@/resources/hfm_winstar/client/data-table'
import { formatNameWithId } from '@/resources/hfm_winstar/client/format'

import { DBTHfmClientDetailResource } from './resource'

export const dbtHfmClientDetailTable = defineTable(
  DBTHfmClientDetailResource,
  { '.': hfmWinstarClientTable },
  (tbl) => {
    return {
      columns: [
        tbl.col.hfmClientId(),

        tbl.col.name(),
        tbl.col.activeFlag(),
        tbl.col.companyId(),
        tbl.col.salutation(),
        tbl.col.accountNumber(),

        tbl.col.phoneNumber({
          label: 'Contact',
          enableHiding: false,
          isVisible: false,
        }),

        tbl.col.phoneDescription({ label: 'Contact Type' }),

        tbl.calculatedCol('nameWithId', (row) => formatNameWithId(row), {
          label: 'Name',
        }),

        tbl.compoundCol('nameContact', [tbl.colId('nameWithId'), tbl.col.phoneNumber.id()]),
      ],
    }
  },
)
