import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTCandidateHorse } from '@/types'

const name = 'DBTCandidateHorse'

const recordNameSingular = 'dbtCandidateHorse'
const recordNamePlural = 'dbtCandidateHorses'
const i18nKey = 'dbt::CandidateHorse'

const primaryKey = 'identifierHorseId'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'dbtCandidateHorses'

const pageHelperKey = 'dbtCandidateHorses'

const resourcePathName = 'dbt_candidate_horse'

const attributes: Record<string, AttributeSpec> = {
  identifierHorseId: {
    kind: 'column',
    name: 'identifierHorseId',
    type: 'text',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'text',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'text',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  nameProper: {
    kind: 'column',
    name: 'nameProper',
    type: 'text',
  },
  namedRef: {
    kind: 'column',
    name: 'namedRef',
    type: 'text',
  },
  unnamedDamRef: {
    kind: 'column',
    name: 'unnamedDamRef',
    type: 'text',
  },
  unnamedParentRef: {
    kind: 'column',
    name: 'unnamedParentRef',
    type: 'text',
  },
  studBookAliasId: {
    kind: 'column',
    name: 'studBookAliasId',
    type: 'text',
  },
  identifierType: {
    kind: 'column',
    name: 'identifierType',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  studBookId: {
    kind: 'column',
    name: 'studBookId',
    type: 'text',
  },
  nameKey: {
    kind: 'column',
    name: 'nameKey',
    type: 'text',
  },
  breeder: {
    kind: 'column',
    name: 'breeder',
    type: 'string',
  },
  damNamedRef: {
    kind: 'column',
    name: 'damNamedRef',
    type: 'text',
  },
  sireNamedRef: {
    kind: 'column',
    name: 'sireNamedRef',
    type: 'text',
  },
  hasUpdate: {
    kind: 'column',
    name: 'hasUpdate',
    type: 'boolean',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'string',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'string',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  hasConflict: {
    kind: 'column',
    name: 'hasConflict',
    type: 'boolean',
  },
  isNew: {
    kind: 'column',
    name: 'isNew',
    type: 'boolean',
  },
  Grp: {
    kind: 'column',
    name: 'Grp',
    type: 'text',
  },
  horseNamedRef: {
    kind: 'column',
    name: 'horseNamedRef',
    type: 'citext',
  },
  horseUnnamedDamRef: {
    kind: 'column',
    name: 'horseUnnamedDamRef',
    type: 'citext',
  },
  horseUnnamedParentRef: {
    kind: 'column',
    name: 'horseUnnamedParentRef',
    type: 'citext',
  },
  nameDupHorseId: {
    kind: 'column',
    name: 'nameDupHorseId',
    type: 'integer',
  },
  hasNameDup: {
    kind: 'column',
    name: 'hasNameDup',
    type: 'boolean',
  },
  damNameDupHorseId: {
    kind: 'column',
    name: 'damNameDupHorseId',
    type: 'integer',
  },
  hasDamNameDup: {
    kind: 'column',
    name: 'hasDamNameDup',
    type: 'boolean',
  },
  damIdDupHorseId: {
    kind: 'column',
    name: 'damIdDupHorseId',
    type: 'integer',
  },
  hasDamIdDup: {
    kind: 'column',
    name: 'hasDamIdDup',
    type: 'boolean',
  },
  namedDupHorseId: {
    kind: 'column',
    name: 'namedDupHorseId',
    type: 'integer',
  },
  hasNamedDup: {
    kind: 'column',
    name: 'hasNamedDup',
    type: 'boolean',
  },
  unnamedDamDupHorseId: {
    kind: 'column',
    name: 'unnamedDamDupHorseId',
    type: 'integer',
  },
  hasUnnamedDamDup: {
    kind: 'column',
    name: 'hasUnnamedDamDup',
    type: 'boolean',
  },
  unnamedParentDupHorseId: {
    kind: 'column',
    name: 'unnamedParentDupHorseId',
    type: 'integer',
  },
  hasUnnamedParentDup: {
    kind: 'column',
    name: 'hasUnnamedParentDup',
    type: 'boolean',
  },
  hasRefDup: {
    kind: 'column',
    name: 'hasRefDup',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTCandidateHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
