import { DBTHfmClientDetail, HfmWinstarClient } from '@/types'
import { compact } from '@/utils'

const isDBTHfmClientDetail = (
  client: HfmWinstarClient | DBTHfmClientDetail,
): client is DBTHfmClientDetail => (client as DBTHfmClientDetail).hfmClientId !== undefined

const formatName = (client: HfmWinstarClient | DBTHfmClientDetail) => {
  const names = compact([client.lastName, client.firstName])
  const personName = names.length > 0 ? names.join(', ') : null

  if (client.businessNameFlag) {
    return client.businessName ?? personName
  } else {
    return personName ?? client.businessName
  }
}

const clientId = (client: HfmWinstarClient | DBTHfmClientDetail) =>
  isDBTHfmClientDetail(client) ? client.hfmClientId : client.clientId

const formatNameWithId = (client: HfmWinstarClient | DBTHfmClientDetail) => {
  if (!formatName(client) && !clientId(client)) {
    return null
  }
  return `${formatName(client)} (${clientId(client)})`
}

export { formatName, formatNameWithId }
