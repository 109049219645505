import React from 'react'

import { ChevronsUpDown } from '@/components/icons'
import { Button, ButtonProps } from '@/components/ui/button'

const AutocompleteButton = React.forwardRef(
  ({ children, ...props }: ButtonProps, ref: HTMLButtonElement) => {
    return (
      <Button
        ref={ref}
        data-testid="autocomplete-button"
        iconRight={<ChevronsUpDown />}
        className="flex min-w-full justify-between"
        {...props}
      >
        {children || '   '}
      </Button>
    )
  },
)

export { AutocompleteButton }
