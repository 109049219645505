import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HorseDetail } from '@/types'

const name = 'HorseDetail'

const recordNameSingular = 'horseDetail'
const recordNamePlural = 'horseDetails'
const i18nKey = 'horseDetail'

const primaryKey = 'horseDetailId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'horseDetails'

const pageHelperKey = 'horseDetails'

const resourcePathName = 'horse_detail'

const attributes: Record<string, AttributeSpec> = {
  horseDetailId: {
    kind: 'column',
    name: 'horseDetailId',
    type: 'integer',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  horse: {
    kind: 'submodel',
    name: 'horse',
    modelName: 'DBTHorse',
    columns: [
      'horseId',
      'name',
      'age',
      'nameAgeSex',
      'yob',
      'sex',
      'foalLocation',
      'sireId',
      'sireName',
      'sireYob',
      'damId',
      'damName',
      'damYob',
      'damSireId',
      'horseType',
    ],
  },
  damSire: {
    kind: 'submodel',
    name: 'damSire',
    modelName: 'DBTHorse',
    columns: ['horseId', 'name', 'yob', 'sex', 'foalLocation'],
  },
  careerRaceSummary: {
    kind: 'submodel',
    name: 'careerRaceSummary',
    modelName: 'DBTCareerRaceSummary',
    columns: [
      'starts',
      'wins',
      'places',
      'shows',
      'totalEarnings',
      'averageEarnings',
      'bestClass',
      'bestClassTitle',
      'lastRacedDate',
      'lastWorkoutDate',
    ],
  },
  lastSaleResult: {
    kind: 'submodel',
    name: 'lastSaleResult',
    modelName: 'DBTSaleResult',
    columns: [
      'saleId',
      'saleResultId',
      'saleName',
      'saleHost',
      'saleTitle',
      'reservePrice',
      'salePrice',
      'salePriceSold',
    ],
  },
  esn: {
    kind: 'submodel',
    name: 'esn',
    modelName: 'ModelsFpiPrediction',
    columns: ['esnId', 'gradedStakesWinner', 'gradedStakesWinnerTier'],
  },
}

const modelSpec: ModelSpec<HorseDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
