import { createMutation, createUpsertMutation } from '@/libs/query/mutation'
import { ResourceRecord } from '@/libs/resource/record'
import { MutableResource } from '@/libs/resource/resource'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useResourceMutation = <T extends ResourceRecord>(resource: MutableResource<T>) => {
  if (resource.api.create == null || resource.api.update == null || resource.api.destroy == null) {
    throw new Error(`Cannot use resource mutations on ImmutableResource ${resource.name}`)
  }

  const queryClient = useQueryClient()

  // Current thought is mutations should always be explicit and not pulled from context or elsewhere...
  // Previously, was accepting a record object and calling useResourceContext
  // then passing the stack to the createMutation helper...

  const create = useMutation(createMutation(resource.api.create, queryClient))

  const update = useMutation(createMutation(resource.api.update, queryClient))

  const upsert = useMutation(
    createUpsertMutation(resource.api.create, resource.api.update, queryClient),
  )

  const destroy = useMutation(createMutation(resource.api.destroy, queryClient))

  return {
    create,
    update,
    destroy,
    upsert,
    remove: destroy,
  }
}
