import { currencyFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'
import { formatBestClass } from '@/resources/dbt/race/best_class_style'
import { raceRecord } from '@/resources/dbt/race/race_record'

import { DBTCareerRaceSummaryResource } from './resource'

const dbtCareerRaceSummaryTable = defineTable(DBTCareerRaceSummaryResource, {}, (tbl) => ({
  columns: [
    tbl.col.bestClass({
      cell: ({ getValue }) => formatBestClass(getValue()),
    }),

    tbl.calculatedCol('raceRecord', (row) => raceRecord(row), {
      label: 'Race Record',
    }),

    tbl.compoundCol('bestClassOverRaceRecord', [tbl.col.bestClass.id(), tbl.colId('raceRecord')]),

    tbl.col.totalEarnings({
      label: 'Earnings',
      cell: currencyFormatter({ decimals: 0 }),
    }),

    tbl.col.averageEarnings({
      label: 'Earn / Start',
      cell: currencyFormatter({ decimals: 0 }),
    }),

    tbl.compoundCol('earnings', [tbl.col.totalEarnings.id(), tbl.col.averageEarnings.id()]),

    tbl.col.daysSinceLastWork({ label: 'DSLB' }),
    tbl.col.daysSinceLastRace({ label: 'DSLR' }),

    tbl.compoundCol('lastWorkOverLastRace', [
      tbl.col.daysSinceLastWork.id(),
      tbl.col.daysSinceLastRace.id(),
    ]),
  ],
}))

export { dbtCareerRaceSummaryTable }
