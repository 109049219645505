import { useCallback } from 'react'

import { BaseDataTable } from '@/components/data-table/base_table'
import { defineTable } from '@/components/data-table/builder'
import { ColumnHelperDef } from '@/components/data-table/builder/types'
import { usePivotTable } from '@/components/data-table/builder/use-pivot-table'
import { useDataTable } from '@/components/data-table/hooks'
import { Currency } from '@/components/ui/text'
import { FinanceMonthlyEntryResource } from '@/resources/finance/monthly_entry'
import { financeMonthlyEntryTable } from '@/resources/finance/monthly_entry/data-table'
import * as aq from 'arquero'

import {
  CashFlowParams,
  createColumnCalcExpression,
  monthlyForecastColumns,
  yearEndColumns,
} from './cash-flow'
import { CashFlowTableFooter } from './cash-flow-table-footer'
import { CashFlowTableHeader } from './cash-flow-table-header'
import { columnLabel, columnRenames, serializeColumn } from './columns'
import { generatePeriods } from './period'
import { CashFlowColumn, Scenario } from './types'
import { firstOfCurrentMonth, formatDate } from './utils'

export interface BuildCashFlowTableProps {
  year?: number
  referenceDate?: Date
}

export const CashFlowTable = ({ query, ...props }: BuildCashFlowTableProps) => {
  const { referenceDate = firstOfCurrentMonth(), ...otherProps } = props

  const { year = referenceDate.getFullYear(), ...rest } = otherProps

  const groupColumns = ['monthlyEntryId']
  const pivotColumns = ['versionLabel', 'monthEnd']
  const valueColumns = ['amountCf']
  const attributeColumns = ['equineActivityGroup', 'equineActivitySubgroup', 'project']

  const scenarioNames = ['Actual', 'v20231029']
  const monthPeriods = generatePeriods(
    'Month',
    new Date(year - 0, 0, 1),
    new Date(year + 1, 11, 31),
  )

  const monthEndDateStrings = monthPeriods.map((period) => formatDate(period.endDate, 'yyyy-MM-dd'))

  const pivotValueSpace = [scenarioNames, monthEndDateStrings]

  const columnRenameMap = columnRenames({
    scenarioNames,
    monthEndDateStrings,
  })

  const { pivotTable, transformData } = usePivotTable(
    financeMonthlyEntryTable,
    groupColumns,
    pivotColumns,
    valueColumns,
    attributeColumns,
    pivotValueSpace,
    columnRenameMap,
  )

  const mainScenario: Scenario = {
    type: 'Budget',
    name: 'v20231029',
  }

  const cashFlowParams: CashFlowParams = {
    year,
    referenceDate,
    scenario: mainScenario,
  }

  const baseColumns = monthlyForecastColumns(cashFlowParams)

  const columnsToCalculate = [...yearEndColumns(cashFlowParams)]

  const expressions = columnsToCalculate.reduce(
    (acc, column) => ({
      ...acc,
      [serializeColumn(column)]: aq.escape(createColumnCalcExpression(column)),
    }),
    {},
  )

  const finalTransform = useCallback(
    (data: TData) => {
      const base = transformData(data)

      const table = aq.from(base)

      const result = table.derive(expressions).objects()

      return result
    },
    [transformData],
  )

  const buildColumnOptions = (cashFlowColumn: CashFlowColumn): ColumnHelperDef => {
    return {
      label: columnLabel(cashFlowColumn),
      cell: ({ getValue }) => <Currency value={getValue()} hideSymbol nonExtended />,
      aggregationFn: 'sum',
      aggregatedCell: ({ getValue }) => <Currency value={getValue()} hideSymbol nonExtended />,
      header: () => <CashFlowTableHeader cashFlowColumn={cashFlowColumn} />,
      footer: (props) => <CashFlowTableFooter cashFlowColumn={cashFlowColumn} {...props} />,
      size: 125,
      isVisible: cashFlowColumn.period.startDate.getFullYear() === year,
      meta: {
        fixedSize: true,
        meta: cashFlowColumn,
      },
    }
  }

  const cashFlowTable = defineTable(FinanceMonthlyEntryResource, { '.': pivotTable }, (tbl) => {
    const forecastCols = baseColumns.map((column) => {
      const columnId = serializeColumn(column)
      return tbl.accessorKeyCol(columnId, buildColumnOptions(column))
    })

    const calculatedCols = columnsToCalculate.map((column) => {
      const columnId = serializeColumn(column)

      return tbl.accessorKeyCol(columnId, buildColumnOptions(column))
    })

    return {
      columns: [tbl.expanderCol(), ...forecastCols, ...calculatedCols],
    }
  })

  const datatableProps = cashFlowTable.props({
    query: query
      .filter('account__cashFlowGroup', ['Operating', 'Investing', 'Financing'], 'includes')
      .filter('entityId', 'Siena Farm'),
    clientSide: true,
    verticalScroll: true,
    defaultPageSize: 100,
    queryBatchSize: 500,
    enableGrouping: true,
    initialGroupedColumns: ['equineActivityGroup', 'equineActivitySubgroup', 'project'],
    initialSorting: [
      { id: 'equineActivityGroup' },
      { id: 'equineActivitySubgroup' },
      { id: 'project' },
    ],
    initialPinnedColumns: {
      left: ['_expander'],
    },
    transformData: finalTransform,
    ...rest,
  })

  const datatable = useDataTable(datatableProps)

  return <BaseDataTable {...datatable} />
}
