import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { JockeyClubMaresBredStat } from '@/types'

const name = 'JockeyClubMaresBredStat'

const recordNameSingular = 'jockeyClubMaresBredStat'
const recordNamePlural = 'jockeyClubMaresBredStats'
const i18nKey = 'jockeyClub::MaresBredStat'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'jockeyClubMaresBredStats'

const pageHelperKey = 'jockeyClubMaresBredStats'

const resourcePathName = 'jockey_club_mares_bred_stat'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionName: {
    kind: 'column',
    name: 'stallionName',
    type: 'citext',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  maresBred: {
    kind: 'column',
    name: 'maresBred',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<JockeyClubMaresBredStat> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
