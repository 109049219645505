import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const StudFeesShow = () => {
  const { studFeesShow } = useLoaderQueryBuilders()
  const { record: studFee } = useResourceQuery(studFeesShow)

  return <Section title={studFee.title} className="mt-16"></Section>
}

export default StudFeesShow
