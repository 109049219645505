export function compact<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((e) => e != null)
}

export function equals(arr1: unknown[], arr2: unknown[]): boolean {
  if (arr1.length !== arr2.length) return false

  return arr1.reduce((result, value, index) => {
    return result && value === arr2[index]
  }, true)
}

export function range(min: number, max: number): number[] {
  const arr = []
  for (let i = min; i <= max; i++) {
    arr.push(i)
  }
  return arr
}

/**
 *  Performs the cartesian product of arbitrary many
 *  arrays
 */
export const cartesian = <T extends unknown[]>(...allEntries: { [K in keyof T]: T[K][] }) =>
  allEntries.reduce<T[]>(
    (results, entries) =>
      results.flatMap((result) => entries.map((entry) => [...result, entry] as T)),
    [[]] as unknown as T[],
  )
