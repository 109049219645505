import React from 'react'

import { EllipsisVertical } from '@/components/icon'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

interface ActionsDropdownProps {
  children?: React.ReactElement
}

const ActionsDropdown = ({ children, ...props }: ActionsDropdownProps) => {
  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" iconLeft={<EllipsisVertical />} />
      </DropdownMenuTrigger>
      <DropdownMenuSeparator />
      <DropdownMenuContent align="end">
        {React.Children.map(children, (child, index) => (
          <DropdownMenuItem key={index}>
            {React.cloneElement(child, { hideTooltip: true, variant: 'inline' })}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { ActionsDropdown }
