import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { StudFeeCard, StudFeeResource } from '@/resources/stud_fee'
import { studFeesRoutes } from '@/routes'

const StudFeesIndex = () => {
  const queries = useLoaderQueryBuilders()
  const studFees = useResourceQuery(queries.studFeesIndex)

  if (studFees.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No stud fees yet.</Text>
        <div>
          <Action route={studFeesRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">Stud Fees</Text>
        <Action route={studFeesRoutes.new} name="new" variant="primary" />
      </div>
      <StudFeeCard
        key={studFees.records[0].id}
        query={StudFeeResource.query.show.withOptions({ id: studFees.records[0].id })}
      />
      <Section className="mt-16" flex>
        {studFees.records.slice(1).map((record) => (
          <StudFeeCard
            key={record.id}
            query={StudFeeResource.query.show.withOptions({ id: record.id })}
          />
        ))}
      </Section>
    </>
  )
}

export default StudFeesIndex
