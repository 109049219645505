import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTEsnSummary } from '@/types'

const name = 'DBTEsnSummary'

const recordNameSingular = 'dbtEsnSummary'
const recordNamePlural = 'dbtEsnSummaries'
const i18nKey = 'dbt::EsnSummary'

const primaryKey = 'esnId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtEsnSummaries'

const pageHelperKey = 'dbtEsnSummaries'

const resourcePathName = 'dbt_esn_summary'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  esnId: {
    kind: 'column',
    name: 'esnId',
    type: 'integer',
  },
  modelVersionId: {
    kind: 'column',
    name: 'modelVersionId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  nonWinner: {
    kind: 'column',
    name: 'nonWinner',
    type: 'decimal',
  },
  winner: {
    kind: 'column',
    name: 'winner',
    type: 'decimal',
  },
  stakesWinner: {
    kind: 'column',
    name: 'stakesWinner',
    type: 'decimal',
  },
  gradedStakesWinner: {
    kind: 'column',
    name: 'gradedStakesWinner',
    type: 'decimal',
  },
  ssi: {
    kind: 'column',
    name: 'ssi',
    type: 'decimal',
  },
  firstRaceAgeDays: {
    kind: 'column',
    name: 'firstRaceAgeDays',
    type: 'decimal',
  },
  isLatestModelVersion: {
    kind: 'column',
    name: 'isLatestModelVersion',
    type: 'boolean',
  },
  gradedStakesWinnerTier: {
    kind: 'column',
    name: 'gradedStakesWinnerTier',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  cropRank: {
    kind: 'column',
    name: 'cropRank',
    type: 'integer',
  },
  cropCount: {
    kind: 'column',
    name: 'cropCount',
    type: 'integer',
  },
  cropSexRank: {
    kind: 'column',
    name: 'cropSexRank',
    type: 'integer',
  },
  cropSexCount: {
    kind: 'column',
    name: 'cropSexCount',
    type: 'integer',
  },
  cropSireRank: {
    kind: 'column',
    name: 'cropSireRank',
    type: 'integer',
  },
  cropSireCount: {
    kind: 'column',
    name: 'cropSireCount',
    type: 'integer',
  },
  cropSexSireRank: {
    kind: 'column',
    name: 'cropSexSireRank',
    type: 'integer',
  },
  cropSexSireCount: {
    kind: 'column',
    name: 'cropSexSireCount',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<DBTEsnSummary> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
