import { ArrowUpRight } from '@/components/icon'
import { Link } from '@/components/link'
import { Tile } from '@/components/tile'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useResourceT } from '@/hooks/use_resource_t'
import { noopQuery } from '@/libs/query/builder'
import { Resource } from '@/libs/resource/resource'
import { PrimaryKeyValue } from '@/libs/resource/types'
import { RouteOptions } from '@/libs/routes/options'
import { cn } from '@/utils'

import { Tag } from './ui/tag'

export interface LinkTileProps {
  resource?: Resource
  id?: PrimaryKeyValue
  label?: string
  Icon?: React.ReactNode
  content?: string
  className?: string
  routeOptions?: Partial<RouteOptions>
}

const LinkTile = ({
  id,
  resource,
  Icon,
  content,
  routeOptions,
  className,
  ...props
}: LinkTileProps) => {
  if (resource === undefined) {
    throw new Error('LinkTile must be supplied a resource')
  }

  const { t } = useResourceT(resource)

  const { label = t(`.${resource.model.recordNameSingular}`) } = props

  const query = id
    ? resource.query.show.withOptions({
        [resource.model.primaryKey]: id,
      })
    : noopQuery

  const { record } = useResourceQuery(query)

  const routeParamKey = resource.routeParamKey('id')

  const route = id
    ? resource.routes.show.withOptions({ [routeParamKey]: id, ...routeOptions })
    : undefined

  return (
    <Tile className={cn('flex flex-row', record && 'hover:bg-card/70 hover:shadow-md', className)}>
      <Link
        className="flex w-full flex-row items-center justify-between hover:no-underline"
        route={route}
        noLinkFallback="div"
        noChevron
      >
        <div className="flex h-full items-center gap-2">
          {Icon && <Icon record={record} />}
          <span className="flex flex-col justify-between">
            <Text size="xs" muted>
              {label}
            </Text>
            <Text size="lg" family="serif" weight="bold" className="truncate">
              {content ?? record?.title ?? '-'}
            </Text>
          </span>
        </div>
        {record ? (
          <Tag className="bg-accent" size="md" iconRight={<ArrowUpRight />} iconOnly rounded />
        ) : null}
      </Link>
    </Tile>
  )
}

export { LinkTile }
