import React from 'react'

import {
  BooleanFilterControl,
  MultiSelectFilterControl,
  RangeFilterControl,
  SelectFilterControl,
  ValueFilterControl,
} from '@/components/filter'
import { OperatorDef } from '@/libs/filter/operators'

export interface DataTableFilterConfig {
  Component: React.ComponentType
  operatorDef: OperatorDef
}

export const DataTableFilters: Record<string, DataTableFilterConfig> = {
  between: {
    Component: RangeFilterControl,
    operatorDef: { min: 'gteq', max: 'lteq' },
  },
  boolean: {
    Component: BooleanFilterControl,
    operatorDef: 'eq',
  },
  multiSelect: {
    Component: MultiSelectFilterControl,
    operatorDef: 'includes',
  },
  select: {
    Component: SelectFilterControl,
    operatorDef: 'includes',
  },
  startsWith: {
    Component: ValueFilterControl,
    operatorDef: 'startsWith',
  },
  value: {
    Component: ValueFilterControl,
    operatorDef: 'eq',
  },
}
