import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { EntitySchema } from '@/schemas/entity'
import { Entity } from '@/types'

import { EntityResource } from './resource'

type EntityFormProps = Omit<UseFormProps<Entity>, 'resource'>

const EntityForm = ({ zodSchema = EntitySchema, ...props }: EntityFormProps) => {
  const formConfig = useForm<Entity>({
    resource: EntityResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="name" />
          <Field name="type" />
          <Field name="externalIdentifier" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { EntityForm }
