import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const CoversShow = () => {
  const { coversShow } = useLoaderQueryBuilders()
  const { record: cover } = useResourceQuery(coversShow)

  return <Section title={cover.title} className="mt-16"></Section>
}

export default CoversShow
