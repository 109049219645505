import { defineTable } from '@/components/data-table/builder'
import { currencyFormatter, dateFormatter } from '@/components/data-table/formatters'

import { dbtHorseTable } from '../dbt/horse'
import { saleRecordTable } from '../sale_record/data-table'
import { SaleRecordDetailResource } from './resource'

export const saleRecordDetailTable = defineTable(
  SaleRecordDetailResource,
  {
    saleRecord: saleRecordTable,
    horse: dbtHorseTable,
  },
  (tbl) => ({
    columns: [
      // tbl.col.saleRecord.id(),

      // tbl.col.saleRecord.saleId(),
      // tbl.col.saleRecord.book(),
      tbl.col.saleRecord.hip(),
      // tbl.col.saleRecord.session(),
      // tbl.col.saleRecord.sessionDate({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
      tbl.col.horse.sex(),
      tbl.col.saleRecord.soldAsCode(),
      tbl.col.horse.nameOverSireWithDam(),
      tbl.col.saleRecord.coveringSire(),
      tbl.col.saleRecord.pregnant(),
      tbl.col.saleRecord.consignor(),
      tbl.col.saleRecord.barn(),
      tbl.col.saleRecord.buyer(),
      tbl.col.saleRecord.out(),

      tbl.col.saleRecord.firstFoal(),
      tbl.col.saleRecord.secondFoal(),

      tbl.col.saleRecord.coverDate({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
      tbl.col.saleRecord.breedersCupElig(),

      tbl.col.saleRecord.rna(),
      // tbl.col.saleRecord.sold(),

      tbl.col.saleRecord.salePrice({ cell: currencyFormatter() }),
      tbl.col.saleRecord.workTime(),
      tbl.col.saleRecord.workSet(),
      tbl.col.saleRecord.workDay(),
      tbl.col.saleRecord.ps(),
      tbl.col.saleRecord.propertyLine(),
      tbl.col.saleRecord.grade(),

      // tbl.col.saleRecord.comment({
      //     cell: iconFormatter({
      //         iconKey: 'Clipboard',
      //         tooltipAccessor: tbl.col.comment.id(),
      //     }),
      // }),
    ],
  }),
)
