import React from 'react'

import type { ResourceRecord } from '@/libs/resource/record'

import type { UseDataTableProps } from './hooks'

import { BaseDataTable } from './base_table'
import { useDataTable } from './hooks'

export const DataTable = <TData extends ResourceRecord>(props: UseDataTableProps<TData>) => {
  const datatable = useDataTable(props)

  return <BaseDataTable {...datatable} />
}
