import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HairPull } from '@/types'

const name = 'HairPull'

const recordNameSingular = 'hairPull'
const recordNamePlural = 'hairPulls'
const i18nKey = 'hairPull'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'hairPulls'

const pageHelperKey = 'hairPulls'

const resourcePathName = 'hair_pull'

const attributes: Record<string, AttributeSpec> = {
  hairSampleBatch: {
    kind: 'association',
    name: 'hairSampleBatch',
    modelName: 'HairSampleBatch',
    foreignKey: 'hairSampleBatchId',
    associationPrimaryKey: 'id',
  },
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  hairSampleBatchId: {
    kind: 'column',
    name: 'hairSampleBatchId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'requested',
        },
        {
          value: 1,
          key: 'pulled',
        },
        {
          value: 2,
          key: 'received',
        },
        {
          value: 3,
          key: 'inventoried',
        },
        {
          value: 4,
          key: 'cut',
        },
        {
          value: 5,
          key: 'completed',
        },
      ],
    },
  },
  quantityRating: {
    kind: 'column',
    name: 'quantityRating',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  hairSamplesCount: {
    kind: 'column',
    name: 'hairSamplesCount',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<HairPull> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
