import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { ConformationNoteForm } from '@/resources/conformation_note/form'

const ConformationNotesEdit = () => {
  const { conformationNotesEdit } = useLoaderQueryBuilders()
  const { record: conformationNote } = useResourceQuery(conformationNotesEdit)

  return (
    <Section title={`Edit ${conformationNote.title}`}>
      <ConformationNoteForm initialValues={conformationNote} />
    </Section>
  )
}

export default ConformationNotesEdit
