import { PageHeader } from '@/components/header'

function StallionSales() {
  return (
    <>
      <PageHeader title="Stallion Sales" />
    </>
  )
}

export default StallionSales
