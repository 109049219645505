import React from 'react'

import { cn, cva, VariantProps } from '@/utils'
import { getAspectRatio, findOrientation } from '@/utils/media'

const imageVariants = cva({
  base: 'rounded object-center',
  variants: {
    size: {
      container: '',
      xs: 'h-[36px]',
      sm: 'h-[48px]',
      md: 'h-[54px]',
      lg: 'h-[72px]',
      xl: 'h-[96px]',
    },
    behavior: {
      cover: 'object-cover',
      fill: 'object-fill',
      contain: 'object-contain',
      scale: 'object-scale-down',
    },
    orientation: {
      landscape: 'w-full',
      portrait: 'h-full',
    },
  },
  compoundVariants: [
    {
      behavior: 'cover',
      size: 'container',
      class: ['h-full w-full'],
    },
  ],
  defaultVariants: {
    behavior: 'scale',
    size: 'container',
  },
})

export type ImageProps = {
  imageWidth?: number
  imageHeight?: number
  placeholder?: React.ReactNode
  className?: string
} & React.ImgHTMLAttributes<HTMLImageElement> &
  VariantProps<typeof imageVariants>

const imageSizes: Record<string, number> = {
  xs: 36,
  sm: 48,
  md: 54,
  lg: 72,
  xl: 96,
}

interface ContainerStyleProps {
  ratioNumerator: number
  ratioDenominator: number
  size: ImageProps['size']
  behavior: ImageProps['behavior']
}

const containerStyle = ({
  ratioNumerator,
  ratioDenominator,
  size,
  behavior,
}: ContainerStyleProps) => {
  if (size === 'container') {
    // If respecting the image shape, set the aspect ratio
    // on the container to match the image aspect ratio
    if (behavior === 'contain' || behavior === 'scale') {
      return { aspectRatio: `${ratioNumerator.toString()} / ${ratioDenominator.toString()}` }
    }

    // If not respecting the image shape, don't set any styling.
    // Allow the fill behavior to apply
    return {}
  }

  // If we have a set size, calculate the known dimensions using the
  // aspect ratio
  const height: number | undefined = size && imageSizes[size]
  if (height) {
    const width = (ratioNumerator / ratioDenominator) * height
    return {
      height: `${height.toString()}px`,
      width: `${Math.round(width).toString()}px`,
    }
  }
  return {}
}

const Image: React.FC<ImageProps> = ({
  className,
  src,
  alt,
  size,
  behavior,
  imageWidth,
  imageHeight,
  placeholder,
  children,
  ...props
}) => {
  const [loaded, setLoaded] = React.useState(false)

  const orientation = findOrientation({ imageHeight, imageWidth })
  const [ratioNumerator, ratioDenominator] = getAspectRatio({
    imageHeight,
    imageWidth,
  })

  return (
    <div
      className={imageVariants({
        size,
        behavior,
        orientation,
        class: cn('relative', (!loaded || !src) && 'bg-muted', !loaded && src && 'animate-pulse'),
      })}
      style={containerStyle({
        ratioNumerator,
        ratioDenominator,
        size,
        behavior,
      })}
    >
      {!loaded && placeholder}
      {src && (
        <img
          className={cn(
            imageVariants({ size, behavior, orientation }),
            className,
            !loaded && 'opacity-0',
          )}
          src={src}
          alt={alt}
          loading="lazy"
          onLoad={() => {
            setLoaded(true)
          }}
          {...props}
        />
      )}
      {children}
    </div>
  )
}

export { Image, imageVariants }
