import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BrisnetReport320Horse } from '@/types'

const name = 'BrisnetReport320Horse'

const recordNameSingular = 'brisnetReport320Horse'
const recordNamePlural = 'brisnetReport320Horses'
const i18nKey = 'brisnet::Report320Horse'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'brisnetReport320Horses'

const pageHelperKey = 'brisnetReport320Horses'

const resourcePathName = 'brisnet_report320_horse'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  brisnetHorseId: {
    kind: 'column',
    name: 'brisnetHorseId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damSireName: {
    kind: 'column',
    name: 'damSireName',
    type: 'citext',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  rawHorseString: {
    kind: 'column',
    name: 'rawHorseString',
    type: 'citext',
  },
  rawStatus: {
    kind: 'column',
    name: 'rawStatus',
    type: 'string',
  },
}

const modelSpec: ModelSpec<BrisnetReport320Horse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
