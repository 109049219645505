import { cn } from '@/utils'

export const GridList = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('grid grid-cols-12 gap-4', className)} {...props} />
}

export const GridListItem = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('col-span-12 h-[21.5rem] sm:col-span-6 2xl:col-span-3', className)}
      {...props}
    />
  )
}
