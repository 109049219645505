import React from 'react'

import { Text } from '@/components/ui/text'
import { cn } from '@/utils'

const Header = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <header
      ref={ref}
      className={cn('grid grid-cols-header p-0', 'items-center gap-x-4 gap-y-0.5', className)}
      {...props}
    />
  ),
)
Header.displayName = 'Header'

const Title = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return (
      <Text
        ref={ref}
        as="h3"
        className={cn('col-start-1 justify-self-start', 'leading-none tracking-tight', className)}
        {...props}
      />
    )
  },
)
Title.displayName = 'Title'

const Labels = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('col-start-2 justify-self-start', 'flex flex-row gap-2', className)}
      {...props}
    />
  ),
)
Labels.displayName = 'Labels'

const Actions = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('col-start-3 row-span-2 justify-self-end', 'flex flex-row gap-2', className)}
      {...props}
    />
  ),
)
Actions.displayName = 'Actions'

const Description = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(
  ({ className, ...props }, ref) =>
    React.Children.count(props.children) > 0 && (
      <Text
        ref={ref}
        as="p"
        muted
        size="sm"
        className={cn('col-span-2 row-start-2', className)}
        {...props}
      />
    ),
)
Description.displayName = 'Description'

export { Actions, Description, Header, Labels, Title }
