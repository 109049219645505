import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { OrganizationHorseSchema } from '@/schemas/organization_horse'
import { OrganizationHorse } from '@/types'

import { OrganizationHorseResource } from './resource'

type OrganizationHorseFormProps = Omit<UseFormProps<OrganizationHorse>, 'resource'>

const OrganizationHorseForm = ({
  zodSchema = OrganizationHorseSchema,
  ...props
}: OrganizationHorseFormProps) => {
  const formConfig = useForm<OrganizationHorse>({
    resource: OrganizationHorseResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <div className="grid grid-cols-12 gap-4">
        <Card className="col-span-9 h-min p-4">
          <CardHeader>
            <CardTitle>Dates</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <div className="flex justify-between gap-3 phone:flex-col">
              <Field name="arrived" className="flex-1" />
              <Field name="departed" className="flex-1" />
              <Field name="ownership" className="flex-1" />
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-3 row-span-2 h-min p-4">
          <CardHeader>
            <CardTitle>Rating</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <Field name="rating" className="flex-1" />
            <Field name="category" className="flex-1" />
          </CardContent>
        </Card>
        <Card className="col-span-9 row-span-3 p-4">
          <CardHeader>
            <CardTitle>Situation</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <div className="flex flex-col justify-between gap-3 phone:flex-col">
              <div className="flex gap-3">
                <div className="flex-1">
                  <Field name="division" />
                  <Field name="disposition" />
                </div>
                <div className="flex-1">
                  <Field name="account" />
                  <Field name="expectedDepartureDate" />
                </div>
              </div>
              <Field name="plan" className="flex-1" />
              <Field name="situation" className="flex-1" />
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-3 row-span-3 h-min p-4">
          <CardHeader>
            <CardTitle>Financial Information</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <Field name="cost" />
            <Field name="studFee" />
            <Field name="actualValue" />
          </CardContent>
        </Card>
      </div>
    </Form>
  )
}

export { OrganizationHorseForm }
