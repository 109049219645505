import { createRoutes } from '@/libs/router/route'
import { EntityResource } from '@/resources/entity/resource'

export const EntityResourceRoutes = createRoutes({
  resource: EntityResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'entities',
      children: [
        {
          index: true,
          page: 'entities/index',
          query: EntityResource.query.index,
        },
        {
          path: 'new',
          page: 'entities/new',
          query: EntityResource.query.new,
        },
        {
          path: ':id',
          query: EntityResource.query.show,
          children: [
            {
              index: true,
              page: 'entities/show',
            },
            {
              path: 'edit',
              query: EntityResource.query.edit,
              page: 'entities/edit',
            },
            {
              path: 'destroy',
              page: 'entities/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const EntityRoutes = EntityResourceRoutes
