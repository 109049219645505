import * as React from 'react'

import { cn, cva, type VariantProps } from '@/utils'

const badgeVariants = cva({
  base: 'rounded-[100px] flex-col justify-center items-center inline-flex font-normal leading-normal w-min text-nowrap font-sans',
  variants: {
    variant: {
      active: '',
      wip: '',
      plain: 'bg-plain',
      dim: 'bg-accent',
      stale: 'bg-zinc-100 text-zinc-400',
      green: 'bg-green-200',
      vibrant: 'bg-vibrant-green',
      decrease: 'bg-orange text-white',
      increase: 'bg-green text-white',
      fixed: 'bg-yellow',
      support: 'bg-dark text-white rounded-br-none opacity-75',
      message: 'bg-green-100 pl-0.5 py-3.5',
      'message-icon': 'bg-white w-6 mr-2',
      'message-close': 'bg-green-100 w-6 mr-2',
      pending: 'bg-white border border-solid border-zinc-100',
      final: 'bg-white border border-solid border-green',
      age: 'bg-dark text-white opacity-75 rounded-sm',
      rating: 'bg-blue rounded-md',
    },
    size: {
      large: 'h-9 px-4 text-sm',
      xsmall: 'h-5 px-2 text-xs',
      small: 'h-6 px-2.5  text-xs',
      'large-icon': 'h-8 w-8 p-2',
      icon: 'h-5 w-5 p-1',
    },
  },
  defaultVariants: {
    variant: 'mare',
    size: 'small',
  },
})

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  if (variant === 'active') {
    return (
      <span className="relative flex size-3">
        <span className="absolute inline-flex size-full animate-ping rounded-full bg-green opacity-75" />
        <span className="relative inline-flex size-3 rounded-full bg-green" />
      </span>
    )
  }
  if (variant === 'wip') {
    return (
      <span className="relative flex size-3">
        <span className="absolute inline-flex size-full animate-ping rounded-full bg-yellow opacity-75" />
        <span className="relative inline-flex size-3 rounded-full bg-yellow" />
      </span>
    )
  }
  return <div className={cn(badgeVariants({ variant, size }), className)} {...props} />
}

export { Badge, badgeVariants }
