import { useState, useEffect } from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { useDebounce } from '@uidotdev/usehooks'

export const ValueFilterControl = ({
  label,
  value,
  onChange,
  hideControls = false,
  ...props
}: {
  label: string
  value: string
  onChange: (value: string) => void
  hideControls?: boolean
}) => {
  const [inputValue, setInputValue] = useState<string>(value)

  const debouncedInputValue = useDebounce(inputValue, 300)

  useEffect(() => {
    onChange(debouncedInputValue)
  }, [debouncedInputValue])

  const filterCore = (
    <Input
      placeholder={label}
      value={inputValue}
      onChange={(event) => {
        setInputValue(event.target.value)
      }}
      {...props}
    />
  )

  if (hideControls) {
    return filterCore
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex w-full items-center justify-between p-2">
        {label}
        <Button
          variant="inline"
          size="sm"
          className="justify-start text-orange"
          onClick={() => {
            setInputValue(undefined)
          }}
        >
          clear
        </Button>
      </div>
      <Separator className="w-full bg-grey-500" />
      <div className="p-2">{filterCore}</div>
    </div>
  )
}
