import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { ImageForm } from '@/resources/image/form'

const ImagesEdit = () => {
  const { imagesEdit } = useLoaderQueryBuilders()
  const { record: image } = useResourceQuery(imagesEdit)

  return (
    <Section title={`Edit ${image.title}`}>
      <ImageForm initialValues={image} />
    </Section>
  )
}

export default ImagesEdit
