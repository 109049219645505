import Fraction from 'fraction.js'
import numbro from 'numbro'

export const formatPercentage = (value: number | string, decimals = 1): string => {
  return numbro(value).format({
    output: 'percent',
    mantissa: decimals,
  })
}

export const formatNumber = (value: number, decimals = 1): string => {
  return numbro(value).format({ mantissa: decimals })
}

export const formatOrdinal = (value: number): string => {
  if (value == null) return

  const s = ['th', 'st', 'nd', 'rd']
  const v = value % 100
  return value + (s[(v - 20) % 10] ?? s[v] ?? s[0])
}

// Takes the decimal value and returns a string representation of the fraction
export const decimalToFraction = (decimal: number): string => {
  const fraction = new Fraction(decimal)
  if (fraction.n === 1) {
    return `${fraction.s * fraction.d}`
  } else {
    return `${fraction.s * fraction.n}/${fraction.d}`
  }
}
