import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTStallionStudDetail } from '@/types'

const name = 'DBTStallionStudDetail'

const recordNameSingular = 'dbtStallionStudDetail'
const recordNamePlural = 'dbtStallionStudDetails'
const i18nKey = 'dbt::StallionStudDetail'

const primaryKey = 'stallionId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtStallionStudDetails'

const pageHelperKey = 'dbtStallionStudDetails'

const resourcePathName = 'dbt_stallion_stud_detail'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  yearEnteredStud: {
    kind: 'column',
    name: 'yearEnteredStud',
    type: 'integer',
  },
  latestStudYear: {
    kind: 'column',
    name: 'latestStudYear',
    type: 'integer',
  },
  stallionType: {
    kind: 'column',
    name: 'stallionType',
    type: 'text',
  },
  numberOfCrops: {
    kind: 'column',
    name: 'numberOfCrops',
    type: 'integer',
  },
  firstYearPrice: {
    kind: 'column',
    name: 'firstYearPrice',
    type: 'integer',
  },
  firstYearPrivate: {
    kind: 'column',
    name: 'firstYearPrivate',
    type: 'boolean',
  },
  firstYearFarm: {
    kind: 'column',
    name: 'firstYearFarm',
    type: 'string',
  },
  firstYearLocation: {
    kind: 'column',
    name: 'firstYearLocation',
    type: 'string',
  },
  latestYearPrice: {
    kind: 'column',
    name: 'latestYearPrice',
    type: 'integer',
  },
  latestYearPrivate: {
    kind: 'column',
    name: 'latestYearPrivate',
    type: 'boolean',
  },
  latestYearFarm: {
    kind: 'column',
    name: 'latestYearFarm',
    type: 'string',
  },
  latestYearLocation: {
    kind: 'column',
    name: 'latestYearLocation',
    type: 'string',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
  strictStudFeeGrade: {
    kind: 'column',
    name: 'strictStudFeeGrade',
    type: 'boolean',
  },
  manualGrade: {
    kind: 'column',
    name: 'manualGrade',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<DBTStallionStudDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
