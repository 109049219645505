import { defineTable } from '@/components/data-table/builder'
import { EsnTag } from '@/resources/models/fpi_prediction'

import { DBTMatingEsnResource } from './resource'

export const dbtMatingEsnTable = defineTable(DBTMatingEsnResource, {}, (tbl) => {
  return {
    columns: [
      tbl.col.mareId(),
      tbl.col.sireId(),

      tbl.col.gradedStakesWinner(),
      tbl.col.gradedStakesWinnerTier(),

      tbl.calculatedCol('esn', (row) => row?.gradedStakesWinner ?? row?.gradedStakesWinnerTier, {
        label: 'ESN',
        cell: ({ row }) => (
          <div className="flex items-center">
            <EsnTag
              esn={row.getValue('gradedStakesWinner')}
              esnTier={row.getValue('gradedStakesWinnerTier')}
              className="p-1"
            />
          </div>
        ),
      }),
    ],
  }
})
