import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { HorseForm } from '@/resources/horse/form'

const HorsesEdit = () => {
  const { horsesEdit } = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(horsesEdit)

  return (
    <Section title={`Edit ${horse.title}`}>
      <HorseForm initialValues={horse} />
    </Section>
  )
}

export default HorsesEdit
