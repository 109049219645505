import React from 'react'

import { Button } from '@/components/ui/button'

export const BooleanFilterControl = ({ label, value, onChange }) => {
  const [inputValue, setInputValue] = React.useState(value)
  const handleClick = (value) => {
    setInputValue(value)
    onChange(value)
  }
  return (
    <>
      <div className="flex w-full items-center justify-between p-2">
        {label}
        <Button
          variant="inline"
          size="sm"
          className="justify-start text-orange"
          onClick={() => {
            handleClick(null)
          }}
        >
          clear
        </Button>
      </div>
      <div className="flex w-full gap-2 bg-background p-2">
        <Button
          variant={inputValue ? 'secondary' : 'ghost'}
          className="flex-1"
          onClick={() => {
            handleClick(true)
          }}
        >
          True
        </Button>
        <Button
          variant={inputValue === false ? 'secondary' : 'ghost'}
          className="flex-1"
          onClick={() => {
            handleClick(false)
          }}
        >
          False
        </Button>
      </div>
    </>
  )
}
