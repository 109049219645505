import React, { useState } from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { ImageGalleryDialog } from '@/modules/image_gallery'
import { ImageCard } from '@/resources/image'
import { VideoCard, VideoResource } from '@/resources/video'
import { imagesRoutes, videosRoutes } from '@/routes'
import { formatDate } from '@/utils'

const ImagesIndex = () => {
  const [open, setOpen] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState(null)

  const { imagesIndex } = useLoaderQueryBuilders()
  const images = useResourceQuery(imagesIndex)

  const { records: videos } = useResourceQuery(
    VideoResource.query.index.filter('horseId', horse.id),
  )

  const media = [
    ...images.records.map((record, index) => ({
      ...record,
      type: 'image',
      position: index,
    })),
    ...videos.records.map((record) => ({ ...record, type: 'video' })),
  ]

  const datedMedia = media.filter((item) => item.date)
  const undatedMedia = media.filter((item) => !item.date)

  const sortedDatedMedia = datedMedia.sort((a, b) => {
    const dateA = new Date(a.date).getTime()
    const dateB = new Date(b.date).getTime()
    return dateA !== dateB
      ? dateB - dateA
      : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })

  const groupedMedia = sortedDatedMedia.reduce((acc, item) => {
    const { date, ageMonths } = item
    const age = formatAgeLabel(ageMonths)
    if (!acc[date]) {
      acc[date] = { items: [], age }
    }
    acc[date].items.push(item)
    return acc
  }, {})

  if (media.length === 0) {
    return (
      <Section title="No images or videos yet." className="mt-16">
        <div className="flex gap-4">
          <Action route={videosRoutes.new} name="new" variant="primary" />
          <Action route={imagesRoutes.new} name="new" variant="primary" />
        </div>
      </Section>
    )
  }

  return (
    <>
      <ImageGalleryDialog open={open} setOpen={setOpen} initialImageId={selectedImageId} />

      <Section title="Photos and Videos" className="relative my-12" flex>
        <div className="absolute left-2.5 top-16 h-full w-1 bg-black" />
        <div className="flex flex-col gap-16">
          {Object.keys(groupedMedia).map((date) => (
            <MediaGroup
              key={date}
              media={groupedMedia[date].items}
              label={formatDate(date)}
              ageLabel={groupedMedia[date].age}
              setOpen={setOpen}
              setSelectedImageId={setSelectedImageId}
            />
          ))}
          {undatedMedia.length > 0 && (
            <MediaGroup
              media={undatedMedia}
              label="Unknown Date"
              setOpen={setOpen}
              setSelectedImageId={setSelectedImageId}
            />
          )}
        </div>
      </Section>
    </>
  )
}

const MediaGroup = ({ media, label, ageLabel, setOpen, setSelectedImageId }) => (
  <div className="flex justify-between">
    <div className="flex h-min flex-col items-start gap-2">
      <div className="flex items-center gap-2">
        <div className="size-6 rounded-full bg-black" />
        <Text size="2xl" weight="medium" className="text-left">
          {label}
        </Text>
      </div>
      {ageLabel && (
        <Text size="xl" className="ml-8 text-left">
          {ageLabel}
        </Text>
      )}
    </div>
    <div className="flex w-2/3 flex-wrap justify-end gap-4">
      {media.map((mediaItem) => (
        <div key={`${mediaItem.type}${mediaItem.id}`}>
          {mediaItem.type === 'image' ? (
            <ImageCard
              image={mediaItem}
              className="h-[250px]"
              onClick={() => {
                setOpen(true)
                setSelectedImageId(mediaItem.id)
              }}
            />
          ) : (
            <VideoCard video={mediaItem} className="h-[250px]" />
          )}
        </div>
      ))}
    </div>
  </div>
)

const formatAgeLabel = (ageMonths) => {
  const ageYears = Math.floor(ageMonths / 12)
  const remainingMonths = ageMonths % 12
  return ageYears > 0
    ? `${ageYears} year${ageYears > 1 ? 's' : ''} ${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`
    : `${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`
}

export default ImagesIndex
