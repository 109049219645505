import * as React from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'

import { useUserSession } from '@/hooks/use_user_session'
import { User } from '@/types'
import { toast } from 'sonner'

type AuthContextType = ReturnType<typeof useUserSession> | {}

const AuthContext = React.createContext<AuthContextType>({})

function AuthProvider({ children }: { children: React.ReactNode }) {
  const session = useUserSession()

  return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
}

function useAuth() {
  const {
    user,
    organization,
    roles = [],
    permissions = {},
    permissionMap,
    signInMutation,
    signOutMutation,
    removeSession,
  } = React.useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation()

  const isAuthenticated = React.useMemo(() => user?.id != null)

  const isAdmin = React.useMemo(() => roles.find((r) => r.name === 'admin') !== undefined, [roles])

  const isSales = React.useMemo(() => roles.find((r) => r.name === 'sales') !== undefined, [roles])

  const isSalesAdmin = React.useMemo(
    () => roles.find((r) => r.name === 'sales_admin') !== undefined,
    [roles],
  )

  const isCoalition = React.useMemo(
    () => roles.find((r) => ['coalition', 'coalition_service'].includes(r.name)) !== undefined,
    [roles],
  )

  const signIn = (data: User) => {
    signInMutation.mutate(data, {
      onSuccess: () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        const newLocation = location.state?.from ?? '/'
        navigate(newLocation === '/login' ? '/' : newLocation, {
          replace: true,
        })
      },
      onError: () => {
        toast.error('There was a problem signing in')
      },
    })
  }

  const signOut = () => {
    if (!isAuthenticated) {
      navigate('/login')
    }

    signOutMutation.mutate(
      {},
      {
        onSuccess: () => {
          navigate('/login')
          toast.success('You are now signed out')
        },
        onError: () => {
          toast.error('There was a problem signing out')
        },
      },
    )
  }

  const hasPermission = (resourceName, action) => {
    const resourcePermissions = permissionMap[resourceName]
    return resourcePermissions ? (resourcePermissions[action] ?? false) : false
  }

  return {
    user,
    organization,
    roles,
    removeSession,
    signIn,
    signOut,
    signInMutation,
    signOutMutation,
    isAuthenticated,
    isAdmin,
    isSales,
    isSalesAdmin,
    isCoalition,
    hasPermission,
  }
}

function RequireAuth({ children }: { children: React.ReactNode }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export { AuthProvider, AuthContext, RequireAuth, useAuth }
