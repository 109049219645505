import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import Icon from '@/components/icon'
import { Card, CardContent } from '@/components/ui/card'
import { ResourceProvider } from '@/contexts'
import { HorseSchema } from '@/schemas/horse'
import { Horse } from '@/types'
import { z } from 'zod'

import { HorseSuggestion } from './horse-suggestion'
import { HorseResource } from './resource'

export type HorseFormProps = Omit<UseFormProps<Horse>, 'resource'>

const HorseForm = ({ zodSchema = HorseSchema, ...props }: HorseFormProps) => {
  const formConfig = useForm<Horse>({
    resource: HorseResource,
    zodSchema: zodSchema.extend({
      yob: z.union([z.coerce.number(), z.null()]).optional(),
    }),
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="name" />
          <Field name="dob" />
          {/* dam and sire show "name" as placeholder instead of damName or sireName */}
          <Field
            name="dam"
            Form={({ initialValues = {}, ...props }) => {
              return (
                <ResourceProvider resource={HorseResource} frames={[]}>
                  <HorseForm initialValues={initialValues} {...props} />
                </ResourceProvider>
              )
            }}
            renderItem={({ suggestion, isSelected }) => {
              if (!suggestion) return <div>No Horses</div>
              else {
                return (
                  <div className="flex w-full gap-0.5">
                    <div className="w-4 py-1">
                      {isSelected && <Icon name="Check" className="self-center" />}
                    </div>
                    <HorseSuggestion horse={suggestion} hideImage={true} hideType={true} />
                  </div>
                )
              }
            }}
          />
          <Field
            name="sire"
            Form={({ initialValues = {}, ...props }) => {
              return (
                <ResourceProvider resource={HorseResource} frames={[]}>
                  <HorseForm initialValues={initialValues} {...props} />
                </ResourceProvider>
              )
            }}
            renderItem={({ suggestion, isSelected }) => (
              <div className="flex w-full gap-0.5">
                <div className="w-4 py-1">
                  {isSelected && <Icon name="Check" className="self-center" />}
                </div>
                <HorseSuggestion horse={suggestion} hideImage={true} hideType={true} />
              </div>
            )}
          />
          <div className="flex gap-2">
            <Field name="sex" />
            <Field name="color" />
          </div>
          <Field name="horseType" />
          <Field name="foalLocation" />
          <Field name="breeder" />
          {/* <Field name="damDam" />
          <Field name="sireSire" />
          <Field name="damSire" />
          <Field name="sireDam" />
          <Field name="user" /> */}
          {/* <Field name="family" /> */}
          {/* <Field name="yob" />
          <Field name="origin" />
          <Field name="familyName" />
          <Field name="damName" />
          <Field name="damYob" />
          <Field name="sireName" />
          <Field name="sireYob" />
          <Field name="di" />
          <Field name="dp" />
          <Field name="cd" />
          <Field name="ptag" />
          <Field name="damDamName" />
          <Field name="damDamYob" />
          <Field name="damSireName" />
          <Field name="damSireYob" />
          <Field name="sireDamName" />
          <Field name="sireDamYob" />
          <Field name="sireSireName" />
          <Field name="sireSireYob" />
          <Field name="secondTwin" />
          <Field name="registrationNumber" />
          <Field name="registrationStatus" />
          <Field name="microchipNumber" />
          <Field name="certificateIssuedDate" />
          <Field name="dateOfDeath" />
          <Field name="timeOfDeath" />
          <Field name="namedRef" />
          <Field name="unnamedDamRef" />
          <Field name="unnamedParentRef" />
          <Field name="nameProper" />
          <Field name="studBookCode" />
          <Field name="identifierType" />
          <Field name="migrationStatus" /> */}
        </CardContent>
      </Card>
    </Form>
  )
}

export { HorseForm }
