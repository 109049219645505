import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsTracksRaceDate } from '@/types'

const name = 'DRFChartsTracksRaceDate'

const recordNameSingular = 'drfChartsTracksRaceDate'
const recordNamePlural = 'drfChartsTracksRaceDates'
const i18nKey = 'drf::ChartsTracksRaceDate'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsTracksRaceDates'

const pageHelperKey = 'drfChartsTracksRaceDates'

const resourcePathName = 'drf_charts_tracks_race_date'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsTracksRaceDatesId: {
    kind: 'column',
    name: 'chartsTracksRaceDatesId',
    type: 'integer',
  },
  equibaseTrackId: {
    kind: 'column',
    name: 'equibaseTrackId',
    type: 'string',
  },
  trackName: {
    kind: 'column',
    name: 'trackName',
    type: 'string',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFChartsTracksRaceDate> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
