import React from 'react'
import { useParams } from 'react-router-dom'

import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { HorseDetailResource } from '@/resources/horse_detail'
import { HorseDetailCard } from '@/resources/horse_detail/card'

const ProgenyIndex = () => {
  const { horseId } = useParams()

  const { records: progenies } = useResourceQuery(
    HorseDetailResource.query.index.sort('horse__yob', 'desc').filter('horse__dam_id', horseId),
  )

  if (progenies.length !== 0) {
    return (
      <>
        <div className="mb-4 flex w-full items-center justify-between">
          <Text as="h3">Progeny</Text>
        </div>
        <div className="flex flex-col gap-2">
          {progenies.map((progeny) => (
            <HorseDetailCard
              key={progeny.horse.horseId}
              id={progeny.horseDetailId}
              horseDetail={progeny}
            />
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="mb-4 flex w-full items-center justify-between">
      <Text as="h3">No progeny yet.</Text>
    </div>
  )
}

export default ProgenyIndex
