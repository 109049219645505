// JsFromRoutes CacheKey 470ef6e55fa053d31e3c7590d00d3133
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const physicalMeasurementsApi = {
  index: definePathHelper('get', '/api/physical_measurements'),
  create: definePathHelper('post', '/api/physical_measurements'),
  new: definePathHelper('get', '/api/physical_measurements/new'),
  edit: definePathHelper('get', '/api/physical_measurements/:id/edit'),
  show: definePathHelper('get', '/api/physical_measurements/:id'),
  update: definePathHelper('patch', '/api/physical_measurements/:id'),
  destroy: definePathHelper('delete', '/api/physical_measurements/:id'),
}
