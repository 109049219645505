import { useResourceContext } from '@/contexts'
import { modelNameFromAttributeId } from '@/libs/resource/attribute'
import { findResource } from '@/libs/resource/find'
import { Resource } from '@/libs/resource/resource'
import { ResourceRecord } from '@/libs/resource/types'
import { RouteOptions } from '@/libs/routes/options'
import { BuildFrameProps } from '@/libs/stack'

import { useResourceMutation } from './use_resource_mutation'
import { useResourceQuery } from './use_resource_query'
import { UseResourceRecordProps, useResourceRecord } from './use_resource_record'
import { useResourceT } from './use_resource_t'

export const useResource = <T extends ResourceRecord>(frame?: BuildFrameProps<T>) => {
  const stack = useResourceContext(frame)

  if (stack?.current === undefined) {
    throw new Error('No resource context set')
  }

  const { current } = stack

  const resource: Resource<T> | undefined =
    frame?.resource ??
    findResource<T>(
      current.kind === 'attribute' ? modelNameFromAttributeId(current.attributeId) : current.name,
    )

  if (resource === undefined) {
    throw new Error('Resource or resource context must exist for `useResource`')
  }

  const { t } = useResourceT(resource)

  const primaryKeyAttribute = resource.model.getAttribute(resource.model.primaryKey)

  const isRecord =
    stack.current.kind === 'attribute' &&
    stack.current.attributeId === primaryKeyAttribute?.globalId &&
    stack.current.value != null

  const recordId = isRecord && stack.current.kind === 'attribute' ? stack.current.value : undefined

  const useRecord = (props: Omit<UseResourceRecordProps<T>, 'resource'> = {}) =>
    useResourceRecord({
      resource,
      id: recordId,
      ...props,
    })

  const useRecords = (options?: RouteOptions) => {
    const action = resource.query.index

    const query = options ? action.withOptions(options) : action

    return useResourceQuery(query)
  }

  const useMutate = () => useResourceMutation(resource)

  return {
    ...resource,
    t,
    useRecord,
    useRecords,
    useMutate,
    isRecord,
    recordId,
    stack,
  }
}
