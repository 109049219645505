import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'KeenelandSearchSale'

const recordNameSingular = 'keenelandSearchSale'
const recordNamePlural = 'keenelandSearchSales'
const i18nKey = 'keeneland::SearchSale'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'keenelandSearchSales'

const pageHelperKey = 'keenelandSearchSales'

const resourcePathName = 'keeneland_search_sale'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  keenelandSearchSaleId: {
    kind: 'column',
    name: 'keenelandSearchSaleId',
    type: 'integer',
  },
  keenelandCode: {
    kind: 'column',
    name: 'keenelandCode',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'integer',
  },
  hipCount: {
    kind: 'column',
    name: 'hipCount',
    type: 'integer',
  },
  keenelandSalesSystemSaleId: {
    kind: 'column',
    name: 'keenelandSalesSystemSaleId',
    type: 'integer',
  },
  saleStatus: {
    kind: 'column',
    name: 'saleStatus',
    type: 'string',
  },
  isPastSale: {
    kind: 'column',
    name: 'isPastSale',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
