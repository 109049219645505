import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BrisnetReport210Horse } from '@/types'

const name = 'BrisnetReport210Horse'

const recordNameSingular = 'brisnetReport210Horse'
const recordNamePlural = 'brisnetReport210Horses'
const i18nKey = 'brisnet::Report210Horse'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'brisnetReport210Horses'

const pageHelperKey = 'brisnetReport210Horses'

const resourcePathName = 'brisnet_report210_horse'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  brisnetHorseId: {
    kind: 'column',
    name: 'brisnetHorseId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  secondTwin: {
    kind: 'column',
    name: 'secondTwin',
    type: 'boolean',
  },
  rawHorseString: {
    kind: 'column',
    name: 'rawHorseString',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<BrisnetReport210Horse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
