import { defineTable } from '@/components/data-table/builder'

import { FinanceEmployeeResource } from './resource'

export const financeEmployeeTable = defineTable(FinanceEmployeeResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.employeeId(),
    tbl.col.departmentId(),
    tbl.col.locationId(),
    tbl.col.parentKey(),
    tbl.col.entity(),
    tbl.col.birthDate(),
    tbl.col.startDate(),
    tbl.col.endDate(),
    tbl.col.employeeType(),
    tbl.col.employee1099Type(),
    tbl.col.status(),
    tbl.col.contactName(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
  ],
}))
