import React from 'react'

import { User } from '@/components/icons'
import { Link } from '@/components/link'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useAuth } from '@/contexts/auth'

const Account = () => {
  const { isSalesAdmin, signOut } = useAuth()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {/* Dropdown doesn't work when using Item */}
        <Link
          noChevron
          iconOnly
          noRouter
          iconLeft={<User />}
          className="size-9 rounded-lg bg-background p-2 focus:bg-foreground focus:text-white"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" side="right" className="z-[65]">
        <DropdownMenuLabel>Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {!isSalesAdmin && (
          <DropdownMenuItem>
            <Link to="/profile" size="sm">
              User Settings
            </Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <Link
            size="sm"
            onClick={() => {
              signOut()
            }}
          >
            Sign Out
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { Account }
