// JsFromRoutes CacheKey 453897eda6753452489d544bb87194e3
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DBTRaceResultModel } from '@/models/dbt/race_result'

export const dbtRaceResultsApi = {
  index: defineEndpoint({
    controllerName: 'dbt/race_results',
    actionName: 'index',
    model: DBTRaceResultModel,
    parts: ['api', 'dbt', 'race_results'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/race_results',
    actionName: 'show',
    model: DBTRaceResultModel,
    parts: [
      'api',
      'dbt',
      'race_results',
      { name: 'raceResultId', attributeId: 'DBTRaceResult:raceResultId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}
