import {
  DataTableColumnHeader,
  DataTableStackedColumnHeader,
} from '@/components/data-table/columns'
import { stackedFormatter, valueFormatter } from '@/components/data-table/formatters'
import i18n from '@/libs/i18n/config'
import { type Attribute } from '@/libs/resource/types'
import { constructPath, extractPath, removePrefix } from '@/utils/path'
import { createColumnHelper, RowData } from '@tanstack/react-table'

import { ExpanderColumn } from '../columns/expander-column'
import {
  defaultAggregationFn,
  defaultCell,
  defaultFilter,
  defaultLabel,
} from './attribute-column-helper'
import { buildFilterMeta } from './filter'
import {
  AttributeColumnBuilderSpec,
  CalculatedColumnBuilderSpec,
  ColumnBuilderSpec,
  CompoundColumnBuilderSpec,
  DisplayColumnBuilderSpec,
} from './types'

const extractDomain = (attribute: Attribute) => {
  if (attribute.kind !== 'column') {
    return null
  }

  if (attribute.type !== 'enum') {
    return null
  }

  return attribute.domain?.values.map(({ value, key }) => ({
    value: value,
    label: key,
  }))
}

export const columnHelper = createColumnHelper()

export const baseColumnDefOptions = <TData extends RowData>(
  columnSpec: ColumnBuilderSpec<TData>,
) => {
  const {
    label,
    filter,
    domain,
    isExpander,
    cell,
    aggregatedCell,
    isVisible = columnSpec.opts.enableHiding ?? true,
    showHeader = true,
    meta,
    ...otherOps
  } = columnSpec.opts

  const filterMeta = buildFilterMeta(filter, domain)

  return {
    id: columnSpec.colId,
    header: (props) => {
      if (!showHeader) {
        return null
      }

      return <DataTableColumnHeader table={props.table} column={props.column} title={label} />
    },
    filterFn: filterMeta?.filterFn,
    enableColumnFilter: !!filterMeta,
    meta: {
      label,
      filter: filterMeta,
      contextPath: columnSpec.contextPath,
      columnSpecKind: columnSpec.kind,
      isVisible,
      ...meta,
    },
    cell: isExpander ? (props) => <ExpanderColumn columnDefCell={cell} {...props} /> : cell,
    aggregatedCell: isExpander
      ? (props) => <ExpanderColumn columnDefCell={aggregatedCell} {...props} />
      : aggregatedCell,
    ...otherOps,
  }
}

export const defaultColumnHelper = (columnSpec: AttributeColumnBuilderSpec) => {
  const baseOptions = baseColumnDefOptions(columnSpec)

  return columnHelper.accessor(columnSpec.colId, baseOptions)
}

export const attributeColumnHelper = (columnSpec: AttributeColumnBuilderSpec) => {
  const {
    label = defaultLabel(columnSpec.attribute) ?? i18n.t(columnSpec.colId),
    filter = defaultFilter(columnSpec.attribute),
    aggregationFn = defaultAggregationFn(columnSpec.attribute),
    domain = extractDomain(columnSpec.attribute),
    cell = defaultCell(columnSpec.attribute),
    ...otherOps
  } = columnSpec.opts

  const baseOptions = baseColumnDefOptions({
    ...columnSpec,
    opts: { label, filter, domain, cell, aggregationFn, ...otherOps },
  })

  return columnHelper.accessor(columnSpec.colId, baseOptions)
}

export const calculatedColumnHelper = <TData extends RowData>(
  columnSpec: CalculatedColumnBuilderSpec<TData>,
) => {
  const accessorFn = (row: TData, index: number) =>
    columnSpec.accessorFn(extractPath(row, columnSpec.contextPath), index)

  const wrapCell =
    (cell) =>
    ({ row, ...props }) => {
      const newRow = {
        ...row,
        original: extractPath(row.original, columnSpec.contextPath),
        getValue: (columnId: string) =>
          row.getValue(constructPath(columnSpec.contextPath, columnId)),
        renderValue: (columnId: string) =>
          row.renderValue(constructPath(columnSpec.contextPath, columnId)),
        getUniqueValues: (columnId: string) =>
          row.getUniqueValues(constructPath(columnSpec.contextPath, columnId)),
      }

      return cell({ row: newRow, ...props })
    }

  const { cell, ...otherOpts } = columnSpec.opts

  const cellOpts = (cell) =>
    cell != null ? { cell: wrapCell(cell) } : { cell: wrapCell(valueFormatter()) }

  const baseOptions = baseColumnDefOptions({
    ...columnSpec,
    opts: {
      ...otherOpts,
      ...cellOpts(cell),
    },
  })

  return columnHelper.accessor(accessorFn, baseOptions)
}

export const compoundColumnHelper = (columnSpec: CompoundColumnBuilderSpec) => {
  const { cell, ...columnOpts } = columnSpec.opts

  const wrapCell =
    (cell) =>
    ({ table, row }) => {
      const getValue = () =>
        columnSpec.columnIds.reduce((acc, colId) => {
          const col = table.getColumn(colId)
          // TODO probably something wrong with this
          const localId = removePrefix(colId, col.columnDef.meta.contextPath)
          return { ...acc, [localId]: row.getValue(colId) }
        }, {})

      return cell({ getValue })
    }

  const { meta, ...baseOptions } = baseColumnDefOptions(columnSpec)

  return columnHelper.display({
    ...baseOptions,
    id: columnSpec.colId,
    accessorKey: columnSpec.colId,
    cell: cell ? wrapCell(cell) : stackedFormatter(),
    header: (props) => <DataTableStackedColumnHeader table={props.table} column={props.column} />,
    meta: {
      ...meta,
      columnIds: columnSpec.columnIds,
      contextPath: columnSpec.contextPath,
    },
    ...columnOpts,
  })
}

export const expanderColumnHelper = () => {
  return columnHelper.display({
    id: '_expander',
    cell: ExpanderColumn,
    aggregatedCell: ExpanderColumn,
    header: () => null,
    meta: {
      isVisible: true,
    },
  })
}

export const displayColumnHelper = (columnSpec: DisplayColumnBuilderSpec) =>
  columnHelper.display(baseColumnDefOptions(columnSpec))

export const columnSpecHelper = <TData extends RowData>(columnSpec: ColumnBuilderSpec<TData>) => {
  switch (columnSpec.kind) {
    case 'attribute':
      return attributeColumnHelper(columnSpec)
    case 'calculated':
      return calculatedColumnHelper(columnSpec)
    case 'compound':
      return compoundColumnHelper(columnSpec)
    case 'display':
      return displayColumnHelper(columnSpec)
    case 'expander':
      return expanderColumnHelper()
    default:
      throw new Error(`Invalid columnSpec kind '${columnSpec.kind}' for '${columnSpec.colId}'`)
  }
}
