import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HairSampleBatch } from '@/types'

const name = 'HairSampleBatch'

const recordNameSingular = 'hairSampleBatch'
const recordNamePlural = 'hairSampleBatches'
const i18nKey = 'hairSampleBatch'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'hairSampleBatches'

const pageHelperKey = 'hairSampleBatches'

const resourcePathName = 'hair_sample_batch'

const attributes: Record<string, AttributeSpec> = {
  requestingUser: {
    kind: 'association',
    name: 'requestingUser',
    modelName: 'User',
    foreignKey: 'requestingUserId',
    associationPrimaryKey: 'id',
  },
  pullingUser: {
    kind: 'association',
    name: 'pullingUser',
    modelName: 'User',
    foreignKey: 'pullingUserId',
    associationPrimaryKey: 'id',
  },
  inventoryingUser: {
    kind: 'association',
    name: 'inventoryingUser',
    modelName: 'User',
    foreignKey: 'inventoryingUserId',
    associationPrimaryKey: 'id',
  },
  cuttingUser: {
    kind: 'association',
    name: 'cuttingUser',
    modelName: 'User',
    foreignKey: 'cuttingUserId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  requestingUserId: {
    kind: 'column',
    name: 'requestingUserId',
    type: 'integer',
  },
  pullingUserId: {
    kind: 'column',
    name: 'pullingUserId',
    type: 'integer',
  },
  inventoryingUserId: {
    kind: 'column',
    name: 'inventoryingUserId',
    type: 'integer',
  },
  cuttingUserId: {
    kind: 'column',
    name: 'cuttingUserId',
    type: 'integer',
  },
  tag: {
    kind: 'column',
    name: 'tag',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'requested',
        },
        {
          value: 1,
          key: 'pulled',
        },
        {
          value: 2,
          key: 'received',
        },
        {
          value: 3,
          key: 'inventoried',
        },
        {
          value: 4,
          key: 'cut',
        },
        {
          value: 5,
          key: 'completed',
        },
      ],
    },
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  pullingDueDate: {
    kind: 'column',
    name: 'pullingDueDate',
    type: 'date',
  },
  storingDueDate: {
    kind: 'column',
    name: 'storingDueDate',
    type: 'date',
  },
  hairPullsCount: {
    kind: 'column',
    name: 'hairPullsCount',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<HairSampleBatch> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
