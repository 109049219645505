import { PanelContent } from '@/components/panel/content'
import { PanelItem } from '@/components/panel/item'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { useAuth } from '@/contexts/auth'
import { booksRoutes } from '@/routes'
import { defaultMatingYear } from '@/utils'

// TODO: fix season routes after generator fix
export const HomePanel = () => {
  const { organization, isSales } = useAuth()
  const year = defaultMatingYear()

  return (
    <PanelContent title={organization.title}>
      <SidebarGroup>
        <SidebarGroupLabel>Season Manager</SidebarGroupLabel>
        <SidebarMenu>
          <PanelItem label="Seasons" route={booksRoutes.seasons.withOptions({ bookYear: year })} />
          {isSales ? (
            <PanelItem label="Targets" route={booksRoutes.targets({ bookYear: year })} />
          ) : null}
        </SidebarMenu>
      </SidebarGroup>
    </PanelContent>
  )
}
