import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Role } from '@/types'

const name = 'Role'

const recordNameSingular = 'role'
const recordNamePlural = 'roles'
const i18nKey = 'role'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'roles'

const pageHelperKey = 'roles'

const resourcePathName = 'role'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  resourceType: {
    kind: 'column',
    name: 'resourceType',
    type: 'string',
  },
  resourceId: {
    kind: 'column',
    name: 'resourceId',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<Role> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
