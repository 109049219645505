import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctSageClass } from '@/types'

const name = 'IntacctSageClass'

const recordNameSingular = 'intacctSageClass'
const recordNamePlural = 'intacctSageClasses'
const i18nKey = 'intacct::SageClass'

const primaryKey = 'classId'
const searchKey = 'name'

const parents: Scope[] = ['horse']

const apiHelperKey = 'intacctSageClasses'

const pageHelperKey = 'intacctSageClasses'

const resourcePathName = 'intacct_sage_class'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'eponaId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  parentKey: {
    kind: 'column',
    name: 'parentKey',
    type: 'integer',
  },
  parentId: {
    kind: 'column',
    name: 'parentId',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  dateOfBirth: {
    kind: 'column',
    name: 'dateOfBirth',
    type: 'date',
  },
  sire: {
    kind: 'column',
    name: 'sire',
    type: 'citext',
  },
  dam: {
    kind: 'column',
    name: 'dam',
    type: 'citext',
  },
  dateOfDisposal: {
    kind: 'column',
    name: 'dateOfDisposal',
    type: 'date',
  },
  horseLocation: {
    kind: 'column',
    name: 'horseLocation',
    type: 'string',
  },
  horseGender: {
    kind: 'column',
    name: 'horseGender',
    type: 'string',
  },
  horseType: {
    kind: 'column',
    name: 'horseType',
    type: 'string',
  },
  ownership: {
    kind: 'column',
    name: 'ownership',
    type: 'text',
  },
  dateOfPurchase: {
    kind: 'column',
    name: 'dateOfPurchase',
    type: 'date',
  },
  sold: {
    kind: 'column',
    name: 'sold',
    type: 'boolean',
  },
  eponaId: {
    kind: 'column',
    name: 'eponaId',
    type: 'string',
  },
  notesOnHorse: {
    kind: 'column',
    name: 'notesOnHorse',
    type: 'string',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  forecastToSell: {
    kind: 'column',
    name: 'forecastToSell',
    type: 'boolean',
  },
  ownershipPercent: {
    kind: 'column',
    name: 'ownershipPercent',
    type: 'decimal',
  },
  appraisedValue: {
    kind: 'column',
    name: 'appraisedValue',
    type: 'decimal',
  },
  studFeePaid: {
    kind: 'column',
    name: 'studFeePaid',
    type: 'decimal',
  },
  studFeePaidBmif: {
    kind: 'column',
    name: 'studFeePaidBmif',
    type: 'decimal',
  },
  allocatedBmCost: {
    kind: 'column',
    name: 'allocatedBmCost',
    type: 'decimal',
  },
  allocatedOverhead: {
    kind: 'column',
    name: 'allocatedOverhead',
    type: 'decimal',
  },
  purchasePrice: {
    kind: 'column',
    name: 'purchasePrice',
    type: 'decimal',
  },
}

const modelSpec: ModelSpec<IntacctSageClass> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
