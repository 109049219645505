import { Header, Actions, Title, Description } from '@/components/header'
import { Card, type CardProps } from '@/components/ui/card'
import { cn } from '@/utils'

export type TileProps = CardProps & {
  label: string
  caption: string
  icon: React.ReactNode
}

const Tile = ({ label, caption, icon, children, className, ...props }: TileProps) => {
  return (
    <Card {...props} className={cn('flex flex-col justify-between shadow-none', className)}>
      {(label || caption || icon) && (
        <Header className="items-start">
          <Title as="span" className="uppercase" size="sm">
            {label}
          </Title>
          <Description size="xs">{caption}</Description>
          <Actions>{icon}</Actions>
        </Header>
      )}
      {children}
    </Card>
  )
}

export { Tile }
