import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { StudFeeForm } from '@/resources/stud_fee/form'

const StudFeesNew = () => {
  const { studFeesNew } = useLoaderQueryBuilders()
  const { record: studFee } = useResourceQuery(studFeesNew)

  return (
    <Section title="New Stud Fee">
      <StudFeeForm initialValues={studFee} />
    </Section>
  )
}

export default StudFeesNew
