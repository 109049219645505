import { CaretDownFilled, CaretRightFilled } from '@/components/icons'
import { Button } from '@/components/ui/button'
import { flexRender } from '@tanstack/react-table'

export const ExpanderColumn = ({ table, row, cell, columnDefCell }) => {
  if (!row.getIsGrouped() || !row.getCanExpand())
    return (
      <div
        style={{
          marginLeft: `${row.depth}rem`,
        }}
      >
        {flexRender(columnDefCell ?? cell.column.columnDef.cell, cell.getContext())}
      </div>
    )

  const groupingColumn = table.getColumn(row.groupingColumnId)
  const leafRow = row.leafRows[0]
  const getValue = () => row.groupingValue

  return (
    <>
      <Button
        onClick={row.getToggleExpandedHandler()}
        variant="inline"
        style={{
          marginLeft: `${row.depth}rem`,
        }}
        iconLeft={row.getIsExpanded() ? <CaretDownFilled /> : <CaretRightFilled />}
      >
        {flexRender(groupingColumn.columnDef.cell, {
          table,
          column: groupingColumn,
          row: leafRow,
          getValue,
          renderValue: getValue,
        })}
      </Button>
    </>
  )
}
