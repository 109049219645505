import { RouterProvider } from 'react-router-dom'

import { queryClient } from '@/libs/query/client'
import { router } from '@/router'
import { QueryClientProvider } from '@tanstack/react-query'
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6'

import ErrorPage from './pages/error'

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NuqsAdapter>
        <RouterProvider
          router={router}
          HydrateFallback={ErrorPage}
          future={{
            v7_startTransition: true,
          }}
        />
      </NuqsAdapter>
    </QueryClientProvider>
  )
}
