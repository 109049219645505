import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTHorse } from '@/types'

const name = 'DBTHorse'

const recordNameSingular = 'dbtHorse'
const recordNamePlural = 'dbtHorses'
const i18nKey = 'dbt::Horse'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'dbtHorses'

const pageHelperKey = 'dbtHorses'

const resourcePathName = 'dbt_horse'

const attributes: Record<string, AttributeSpec> = {
  dam: {
    kind: 'association',
    name: 'dam',
    modelName: 'DBTHorse',
    foreignKey: 'damId',
    associationPrimaryKey: 'id',
  },
  sire: {
    kind: 'association',
    name: 'sire',
    modelName: 'DBTHorse',
    foreignKey: 'sireId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'text',
  },
  rawName: {
    kind: 'column',
    name: 'rawName',
    type: 'citext',
  },
  nameAge: {
    kind: 'column',
    name: 'nameAge',
    type: 'text',
  },
  nameAgeSex: {
    kind: 'column',
    name: 'nameAgeSex',
    type: 'text',
  },
  nameAgeSexSire: {
    kind: 'column',
    name: 'nameAgeSexSire',
    type: 'text',
  },
  yobSexSire: {
    kind: 'column',
    name: 'yobSexSire',
    type: 'text',
  },
  horseString: {
    kind: 'column',
    name: 'horseString',
    type: 'text',
  },
  horseStringNoDam: {
    kind: 'column',
    name: 'horseStringNoDam',
    type: 'text',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  age: {
    kind: 'column',
    name: 'age',
    type: 'integer',
  },
  foalDayOffset: {
    kind: 'column',
    name: 'foalDayOffset',
    type: 'decimal',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'enum',
    subtype: 'string',
    domain: {
      values: [
        {
          value: 'b',
          key: 'b',
        },
        {
          value: 'bro',
          key: 'bro',
        },
        {
          value: 'blk',
          key: 'blk',
        },
        {
          value: 'ch',
          key: 'ch',
        },
        {
          value: 'dkbbr',
          key: 'dkbbr',
        },
        {
          value: 'grro',
          key: 'grro',
        },
        {
          value: 'gr',
          key: 'gr',
        },
        {
          value: 'ro',
          key: 'ro',
        },
        {
          value: 'pal',
          key: 'pal',
        },
        {
          value: 'wh',
          key: 'wh',
        },
      ],
    },
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'enum',
    subtype: 'string',
    domain: {
      values: [
        {
          value: 'C',
          key: 'C',
        },
        {
          value: 'H',
          key: 'H',
        },
        {
          value: 'G',
          key: 'G',
        },
        {
          value: 'R',
          key: 'R',
        },
        {
          value: 'F',
          key: 'F',
        },
        {
          value: 'M',
          key: 'M',
        },
        {
          value: 'B',
          key: 'B',
        },
      ],
    },
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  horseTypeRaw: {
    kind: 'column',
    name: 'horseTypeRaw',
    type: 'integer',
  },
  horseType: {
    kind: 'column',
    name: 'horseType',
    type: 'enum',
    subtype: 'text',
    domain: {
      values: [
        {
          value: 'Unknown',
          key: 'Unknown',
        },
        {
          value: 'Foal',
          key: 'Foal',
        },
        {
          value: 'Yearling',
          key: 'Yearling',
        },
        {
          value: 'Racehorse',
          key: 'Racehorse',
        },
        {
          value: 'Mare',
          key: 'Mare',
        },
        {
          value: 'Stallion',
          key: 'Stallion',
        },
      ],
    },
  },
  familyId: {
    kind: 'column',
    name: 'familyId',
    type: 'integer',
  },
  familyName: {
    kind: 'column',
    name: 'familyName',
    type: 'string',
  },
  haplogroup: {
    kind: 'column',
    name: 'haplogroup',
    type: 'string',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  damAgeAtFoaling: {
    kind: 'column',
    name: 'damAgeAtFoaling',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  sireAgeAtFoaling: {
    kind: 'column',
    name: 'sireAgeAtFoaling',
    type: 'integer',
  },
  secondTwin: {
    kind: 'column',
    name: 'secondTwin',
    type: 'boolean',
  },
  breederId: {
    kind: 'column',
    name: 'breederId',
    type: 'integer',
  },
  namedRef: {
    kind: 'column',
    name: 'namedRef',
    type: 'citext',
  },
  unnamedDamRef: {
    kind: 'column',
    name: 'unnamedDamRef',
    type: 'citext',
  },
  unnamedParentRef: {
    kind: 'column',
    name: 'unnamedParentRef',
    type: 'citext',
  },
  identifierType: {
    kind: 'column',
    name: 'identifierType',
    type: 'integer',
  },
  studBookAliasId: {
    kind: 'column',
    name: 'studBookAliasId',
    type: 'string',
  },
  damNamedRef: {
    kind: 'column',
    name: 'damNamedRef',
    type: 'citext',
  },
  sireNamedRef: {
    kind: 'column',
    name: 'sireNamedRef',
    type: 'citext',
  },
  damSireId: {
    kind: 'column',
    name: 'damSireId',
    type: 'integer',
  },
  damDamId: {
    kind: 'column',
    name: 'damDamId',
    type: 'integer',
  },
  sireSireId: {
    kind: 'column',
    name: 'sireSireId',
    type: 'integer',
  },
  sireDamId: {
    kind: 'column',
    name: 'sireDamId',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
