import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ModelsModel } from '@/types'

const name = 'ModelsModel'

const recordNameSingular = 'modelsModel'
const recordNamePlural = 'modelsModels'
const i18nKey = 'models::Model'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'modelsModels'

const pageHelperKey = 'modelsModels'

const resourcePathName = 'models_model'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<ModelsModel> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
