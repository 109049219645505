import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Mating } from '@/types'

const name = 'Mating'

const recordNameSingular = 'mating'
const recordNamePlural = 'matings'
const i18nKey = 'mating'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'matings'

const pageHelperKey = 'matings'

const resourcePathName = 'mating'

const attributes: Record<string, AttributeSpec> = {
  account: {
    kind: 'association',
    name: 'account',
    modelName: 'Account',
    foreignKey: 'accountId',
    associationPrimaryKey: 'id',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  final: {
    kind: 'column',
    name: 'final',
    type: 'boolean',
  },
  cc: {
    kind: 'column',
    name: 'cc',
    type: 'decimal',
  },
  ct: {
    kind: 'column',
    name: 'ct',
    type: 'decimal',
  },
  tt: {
    kind: 'column',
    name: 'tt',
    type: 'decimal',
  },
  coltACardio: {
    kind: 'column',
    name: 'coltACardio',
    type: 'decimal',
  },
  fillyACardio: {
    kind: 'column',
    name: 'fillyACardio',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  plan: {
    kind: 'column',
    name: 'plan',
    type: 'string',
  },
  projectedMareValue: {
    kind: 'column',
    name: 'projectedMareValue',
    type: 'decimal',
  },
  projectedColtValue: {
    kind: 'column',
    name: 'projectedColtValue',
    type: 'decimal',
  },
  projectedFillyValue: {
    kind: 'column',
    name: 'projectedFillyValue',
    type: 'decimal',
  },
  projectedFoalValue: {
    kind: 'column',
    name: 'projectedFoalValue',
    type: 'decimal',
  },
  studFee: {
    kind: 'column',
    name: 'studFee',
    type: 'decimal',
  },
  netNet: {
    kind: 'column',
    name: 'netNet',
    type: 'decimal',
  },
  inFoalTo: {
    kind: 'column',
    name: 'inFoalTo',
    type: 'string',
  },
  sexCarrying: {
    kind: 'column',
    name: 'sexCarrying',
    type: 'string',
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  barren: {
    kind: 'column',
    name: 'barren',
    type: 'boolean',
  },
  slipped: {
    kind: 'column',
    name: 'slipped',
    type: 'boolean',
  },
  deadFoal: {
    kind: 'column',
    name: 'deadFoal',
    type: 'boolean',
  },
  approved: {
    kind: 'column',
    name: 'approved',
    type: 'boolean',
  },
  pedigreePass: {
    kind: 'column',
    name: 'pedigreePass',
    type: 'boolean',
  },
  pedigreeNotes: {
    kind: 'column',
    name: 'pedigreeNotes',
    type: 'text',
  },
  physicalPass: {
    kind: 'column',
    name: 'physicalPass',
    type: 'boolean',
  },
  physicalNotes: {
    kind: 'column',
    name: 'physicalNotes',
    type: 'text',
  },
  financialsPass: {
    kind: 'column',
    name: 'financialsPass',
    type: 'boolean',
  },
  financialsNotes: {
    kind: 'column',
    name: 'financialsNotes',
    type: 'text',
  },
  stallionAvailabilityPass: {
    kind: 'column',
    name: 'stallionAvailabilityPass',
    type: 'boolean',
  },
  stallionAvailabilityNotes: {
    kind: 'column',
    name: 'stallionAvailabilityNotes',
    type: 'text',
  },
  notes: {
    kind: 'column',
    name: 'notes',
    type: 'text',
  },
  notBred: {
    kind: 'column',
    name: 'notBred',
    type: 'boolean',
  },
  lastDateBred: {
    kind: 'column',
    name: 'lastDateBred',
    type: 'date',
  },
  shortlist: {
    kind: 'column',
    name: 'shortlist',
    type: 'boolean',
  },
  fiveCross: {
    kind: 'attachment',
    name: 'fiveCross',
    multiple: false,
  },
}

const modelSpec: ModelSpec<Mating> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
