import * as React from 'react'

import {
  ArrowDown,
  ArrowUp,
  CalendarCheck,
  CalendarClock,
  CheckCircle,
  Clock,
  MessageCircle,
  Minus,
  iconSizes,
} from '@/components/icon'
import { cn, cva, type VariantProps } from '@/utils'

const tagVariants = cva({
  base: 'rounded-sm flex gap-1 justify-center items-center inline-flex font-normal leading-normal w-min text-nowrap font-sans',
  variants: {
    variant: {
      default: 'bg-card',
      outline: 'border-2 border-card',
      ghost: '',
      inline: 'rounded-none',
    },
    iconOnly: {
      true: '',
      false: '',
    },
    size: {
      xxs: 'h-3 text-xs',
      xs: 'h-4 text-xs',
      sm: 'h-6 text-xs',
      md: 'h-7 text-sm',
      lg: 'h-9 text-lg',
      xl: 'h-12 text-xl',
    },
    rounded: {
      true: 'rounded-full',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'sm',
    iconOnly: false,
    rounded: false,
  },
  compoundVariants: [
    {
      iconOnly: true,
      size: 'xxs',
      class: 'min-w-3 min-h-3',
    },
    {
      iconOnly: true,
      size: 'xs',
      class: 'min-w-4 min-h-4',
    },
    {
      iconOnly: true,
      size: 'sm',
      class: 'min-w-6 min-h-6',
    },
    {
      iconOnly: true,
      size: 'md',
      class: 'min-w-7 min-h-7',
    },
    {
      iconOnly: true,
      size: 'lg',
      class: 'min-w-9 min-h-9',
    },
    {
      iconOnly: true,
      size: 'xl',
      class: 'size-12',
    },
    {
      iconOnly: false,
      variant: ['default', 'outline', 'ghost'],
      size: 'xs',
      class: 'px-1',
    },
    {
      iconOnly: false,
      variant: ['default', 'outline', 'ghost'],
      size: 'sm',
      class: 'px-2',
    },
    {
      iconOnly: false,
      variant: ['default', 'outline', 'ghost'],
      size: 'lg',
      class: 'px-3',
    },
  ],
})

export type TagProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof tagVariants> & {
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    children?: React.ReactElement
  }

function Tag({ iconLeft, iconRight, rounded, size = 'sm', children, ...props }: TagProps) {
  const iconSize = iconSizes[size]
  const iconProps = { style: { width: iconSize, height: iconSize } }

  const leftIcon = React.isValidElement(iconLeft) ? React.cloneElement(iconLeft, iconProps) : null
  const rightIcon = React.isValidElement(iconRight)
    ? React.cloneElement(iconRight, iconProps)
    : null

  const {
    iconOnly = React.Children.count(children) === 0 && (!!leftIcon || !!rightIcon),
    className,
    ...rest
  } = props

  return (
    <div {...rest} className={tagVariants({ size, iconOnly, rounded, className, ...rest })}>
      {iconLeft && leftIcon}
      {children}
      {iconRight && rightIcon}
    </div>
  )
}

export { Tag, tagVariants }

export const IncreaseTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      iconLeft={<ArrowUp />}
      className={cn('bg-green text-white', className)}
      iconOnly
      rounded
      {...props}
    />
  )
}

export const DecreaseTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      iconLeft={<ArrowDown />}
      className={cn('bg-orange text-white', className)}
      iconOnly
      rounded
      {...props}
    />
  )
}

export const SteadyTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      iconLeft={<Minus />}
      className={cn('bg-yellow text-foreground', className)}
      iconOnly
      rounded
      {...props}
    />
  )
}

export const PendingTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      className={cn('border-muted-foreground', className)}
      iconLeft={<Clock className="text-muted-foreground" />}
      variant="outline"
      rounded
      {...props}
    >
      Pending
    </Tag>
  )
}

export const FinalTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      className={cn('border-green', className)}
      iconLeft={<CheckCircle className="text-green" />}
      variant="outline"
      rounded
      {...props}
    >
      Final
    </Tag>
  )
}

export const StaleTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      className={cn('bg-muted-background text-muted-foreground', className)}
      iconLeft={<CalendarClock />}
      rounded
      {...props}
    >
      Stale
    </Tag>
  )
}

export const NewTag = ({ className, ...props }: TagProps) => {
  return (
    <Tag
      className={cn('bg-green text-white', className)}
      iconLeft={<CalendarCheck className="text-primary-foreground" />}
      rounded
      {...props}
    >
      New
    </Tag>
  )
}

export const MessageTag = ({ className, children, ...props }: TagProps) => {
  return (
    <Tag
      className={cn('w-full justify-start bg-green-100 p-1', className)}
      size="lg"
      iconLeft={
        <Tag
          className="min-h-7 min-w-7"
          iconLeft={<MessageCircle className="text-black" />}
          rounded
        />
      }
      rounded
      {...props}
    >
      <span className="truncate">{children}</span>
    </Tag>
  )
}

export const PositiveTag = ({ className, ...props }: TagProps) => {
  return <Tag className={cn('bg-green text-white', className)} rounded {...props}></Tag>
}

export const NegativeTag = ({ className, ...props }: TagProps) => {
  return <Tag className={cn('bg-orange text-white', className)} rounded {...props}></Tag>
}

export const NeutralTag = ({ className, ...props }: TagProps) => {
  return <Tag className={cn('bg-yellow', className)} rounded {...props}></Tag>
}

export const ChangeTag = ({ value, previousValue }: { value?: number; previousValue?: number }) => {
  if (!Number.isFinite(Number(value)) || !Number.isFinite(Number(previousValue))) {
    return null
  }
  if (value === previousValue) {
    return <SteadyTag />
  } else if (value > previousValue) {
    return <IncreaseTag />
  } else {
    return <DecreaseTag />
  }
}
