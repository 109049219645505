import { BaseDataTable } from '@/components/data-table/base_table'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { MonthlyOwnershipsByDivisionResource } from '@/resources/monthly_ownerships_by_division'
import { monthlyOwnershipsByDivisionTable } from '@/resources/monthly_ownerships_by_division'

const BloodstockIndex = () => {
  const today = new Date()

  const query = MonthlyOwnershipsByDivisionResource.query.index
    .filter('year', today.getFullYear())
    .filter('month', today.getMonth() + 1)

  const tableProps = monthlyOwnershipsByDivisionTable.props({
    query,
    clientSide: true,
    verticalScroll: true,
    defaultPageSize: 300,
    enableGrouping: true,
    initialGroupedColumns: ['division', 'account'],
    initialSorting: [{ id: 'division' }, { id: 'account' }, { id: 'label' }],
  })

  const datatable = useDataTable(tableProps)

  return (
    <>
      <PageHeader title="Bloodstock" />
      <BaseDataTable {...datatable} />
    </>
  )
}

export default BloodstockIndex
