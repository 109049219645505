import { Horse } from '@/types'
import { currentYear, isEmptyObject, isEmptyString } from '@/utils'

export const thoromanagerLink = ({ name, yob, damName, damYob }: Horse) => {
  const url = new URL('http://www.tmracingdata.com/Go/Go.aspx')

  if (!isEmptyString(name) && yob != null) {
    url.searchParams.set('PID', '3001')
    url.searchParams.set('FROM', 'rgHSearch')
    url.searchParams.set('H', name)
    url.searchParams.set('YOB', yob.toString())
  } else if (!isEmptyString(damName) && damYob != null) {
    url.searchParams.set('PID', 5001)
    url.searchParams.set('FROM', 'H_LT_PP')
    url.searchParams.set('H', damName)
    url.searchParams.set('YOB', damYob.toString())
  } else {
    return
  }

  url.searchParams.set('BR', 'TB')

  return url.href
}

export const formatParentNames = (name1?: string, name2?: string) => {
  const firstName = name1 ?? 'Unknown'
  const secondName = name2 ?? 'Unknown'

  return `${firstName}--${secondName}`
}

export const sireDamString = (horse?: Horse) => {
  if (horse == null || isEmptyObject(horse)) {
    return ''
  }

  return formatParentNames(horse.sireName, horse.damName)
}

export const nameYob = (horse?: Horse) => {
  if (horse == null || isEmptyObject(horse)) {
    return ''
  }

  const name = horse.name ?? 'Unnamed'

  return `${name}, ${horse.yob}`
}

export const nameYobAbbr = (horse?: Horse) => {
  if (horse == null || isEmptyObject(horse)) {
    return ''
  }

  const name = horse.name ?? 'Unnamed'

  const yobPart = String(horse.yob).slice(-2)

  return `${name}, '${yobPart}`
}

export const nameAgeSex = (horse?: Horse) => {
  if (horse == null || isEmptyObject(horse)) {
    return ''
  }

  const name = horse.name ?? 'Unnamed'
  const age = currentYear() - horse.yob
  const sex = horse.sex?.toLowerCase() ?? ''

  return `${name}, ${age}${sex}`
}
