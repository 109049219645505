import { z } from 'zod'

export const VideoSchema = z.object({
  horseId: z.number().int(),
  userId: z.union([z.number().int(), z.null()]).optional(),
  date: z
    .union([z.string().date().default('2024-09-08'), z.null().default('2024-09-08')])
    .default('2024-09-08'),
  videoType: z.enum(['unknown', 'walking', 'breeze', 'race', 'other', 'gallop']).optional(),
  ageMonths: z.union([z.number().int(), z.null()]).optional(),
  ageDays: z.union([z.number().int(), z.null()]).optional(),
  file: z.union([z.string(), z.null()]).optional(),
})
