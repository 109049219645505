import { HorseImage } from '@/components/horse_image'
import { LinkTile, LinkTileProps } from '@/components/link_tile'
import { Horse } from '@/types'

import { HorseResource } from './resource'

export const HorseLinkTile = ({ label = 'Horse', ...props }: LinkTileProps) => {
  return (
    <LinkTile
      label={label}
      resource={HorseResource}
      Icon={({ record }: { record?: Horse }) => (
        <HorseImage image={record?.defaultImage} size="xs" behavior="cover" horseId={record?.id} />
      )}
      {...props}
    />
  )
}
