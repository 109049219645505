import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Organization } from '@/types'

const name = 'Organization'

const recordNameSingular = 'organization'
const recordNamePlural = 'organizations'
const i18nKey = 'organization'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'organizations'

const pageHelperKey = 'organizations'

const resourcePathName = 'organization'

const attributes: Record<string, AttributeSpec> = {
  primaryContact: {
    kind: 'association',
    name: 'primaryContact',
    modelName: 'User',
    foreignKey: 'primaryContactId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  salesExpense: {
    kind: 'column',
    name: 'salesExpense',
    type: 'integer',
  },
  overheadPerHorse: {
    kind: 'column',
    name: 'overheadPerHorse',
    type: 'integer',
  },
  salesCommissionRate: {
    kind: 'column',
    name: 'salesCommissionRate',
    type: 'decimal',
  },
  consignorCommissionRate: {
    kind: 'column',
    name: 'consignorCommissionRate',
    type: 'decimal',
  },
  primaryContactId: {
    kind: 'column',
    name: 'primaryContactId',
    type: 'integer',
  },
  onFarmDailyExpense: {
    kind: 'column',
    name: 'onFarmDailyExpense',
    type: 'integer',
  },
  racehorseDailyExpense: {
    kind: 'column',
    name: 'racehorseDailyExpense',
    type: 'integer',
  },
  logo: {
    kind: 'attachment',
    name: 'logo',
    multiple: false,
  },
}

const modelSpec: ModelSpec<Organization> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
