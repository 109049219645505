import { DataTable } from '@/components/data-table'
import { PageHeader } from '@/components/header'
import { useResourceQuery } from '@/hooks'
import { SaleResource } from '@/resources/sale'
import { SaleRecordDetailResource } from '@/resources/sale_record_detail'
import { saleRecordDetailTable } from '@/resources/sale_record_detail/data-table'

const SalesShow = (props) => {
  const {
    urlParams: { id },
  } = props
  const { record: sale } = useResourceQuery(SaleResource.query.show.withOptions({ id }))

  const query = SaleRecordDetailResource.query.index
    .filter('sale_record__sale_id', sale.id)
    .sort('hip', 'desc')

  const tableProps = saleRecordDetailTable.props({
    query,
    clientSide: true,
    verticalScroll: true,
  })

  return (
    <>
      <PageHeader title={`${sale.title} - ${sale.saleType} Sale`} />
      <DataTable {...tableProps} />
    </>
  )
}

export default SalesShow
