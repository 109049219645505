import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFStart } from '@/types'

const name = 'DRFStart'

const recordNameSingular = 'drfStart'
const recordNamePlural = 'drfStarts'
const i18nKey = 'drf::Start'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfStarts'

const pageHelperKey = 'drfStarts'

const resourcePathName = 'drf_start'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsHorsesId: {
    kind: 'column',
    name: 'chartsHorsesId',
    type: 'integer',
  },
  registrationNumber: {
    kind: 'column',
    name: 'registrationNumber',
    type: 'string',
  },
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'string',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'string',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  dayEvening: {
    kind: 'column',
    name: 'dayEvening',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  earningsForeign: {
    kind: 'column',
    name: 'earningsForeign',
    type: 'float',
  },
  earningsUsa: {
    kind: 'column',
    name: 'earningsUsa',
    type: 'float',
  },
  odds: {
    kind: 'column',
    name: 'odds',
    type: 'integer',
  },
  favoriteIndicator: {
    kind: 'column',
    name: 'favoriteIndicator',
    type: 'string',
  },
  medication: {
    kind: 'column',
    name: 'medication',
    type: 'string',
  },
  equipment: {
    kind: 'column',
    name: 'equipment',
    type: 'string',
  },
  weightCarried: {
    kind: 'column',
    name: 'weightCarried',
    type: 'integer',
  },
  horseWeight: {
    kind: 'column',
    name: 'horseWeight',
    type: 'integer',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  postPosition: {
    kind: 'column',
    name: 'postPosition',
    type: 'integer',
  },
  positionAtStart: {
    kind: 'column',
    name: 'positionAtStart',
    type: 'integer',
  },
  positionAtPointOfCall1: {
    kind: 'column',
    name: 'positionAtPointOfCall1',
    type: 'integer',
  },
  positionAtPointOfCall2: {
    kind: 'column',
    name: 'positionAtPointOfCall2',
    type: 'integer',
  },
  positionAtPointOfCall3: {
    kind: 'column',
    name: 'positionAtPointOfCall3',
    type: 'integer',
  },
  positionAtPointOfCall4: {
    kind: 'column',
    name: 'positionAtPointOfCall4',
    type: 'integer',
  },
  positionAtPointOfCall5: {
    kind: 'column',
    name: 'positionAtPointOfCall5',
    type: 'integer',
  },
  positionAtFinish: {
    kind: 'column',
    name: 'positionAtFinish',
    type: 'integer',
  },
  officialPosition: {
    kind: 'column',
    name: 'officialPosition',
    type: 'integer',
  },
  deadHeatIndicator: {
    kind: 'column',
    name: 'deadHeatIndicator',
    type: 'string',
  },
  lengthAheadAtPoc1: {
    kind: 'column',
    name: 'lengthAheadAtPoc1',
    type: 'integer',
  },
  lengthAheadAtPoc2: {
    kind: 'column',
    name: 'lengthAheadAtPoc2',
    type: 'integer',
  },
  lengthAheadAtPoc3: {
    kind: 'column',
    name: 'lengthAheadAtPoc3',
    type: 'integer',
  },
  lengthAheadAtPoc4: {
    kind: 'column',
    name: 'lengthAheadAtPoc4',
    type: 'integer',
  },
  lengthAheadAtPoc5: {
    kind: 'column',
    name: 'lengthAheadAtPoc5',
    type: 'integer',
  },
  lengthAheadAtFinish: {
    kind: 'column',
    name: 'lengthAheadAtFinish',
    type: 'integer',
  },
  lengthBehindAtPoc1: {
    kind: 'column',
    name: 'lengthBehindAtPoc1',
    type: 'integer',
  },
  lengthBehindAtPoc2: {
    kind: 'column',
    name: 'lengthBehindAtPoc2',
    type: 'integer',
  },
  lengthBehindAtPoc3: {
    kind: 'column',
    name: 'lengthBehindAtPoc3',
    type: 'integer',
  },
  lengthBehindAtPoc4: {
    kind: 'column',
    name: 'lengthBehindAtPoc4',
    type: 'integer',
  },
  lengthBehindAtPoc5: {
    kind: 'column',
    name: 'lengthBehindAtPoc5',
    type: 'integer',
  },
  lengthBehindAtFinish: {
    kind: 'column',
    name: 'lengthBehindAtFinish',
    type: 'integer',
  },
  claimedPriceUsa: {
    kind: 'column',
    name: 'claimedPriceUsa',
    type: 'float',
  },
  claimIndicator: {
    kind: 'column',
    name: 'claimIndicator',
    type: 'string',
  },
  coupledType: {
    kind: 'column',
    name: 'coupledType',
    type: 'string',
  },
  coupledFinish: {
    kind: 'column',
    name: 'coupledFinish',
    type: 'integer',
  },
  jockeyId: {
    kind: 'column',
    name: 'jockeyId',
    type: 'integer',
  },
  jockeyType: {
    kind: 'column',
    name: 'jockeyType',
    type: 'string',
  },
  apprenticeType: {
    kind: 'column',
    name: 'apprenticeType',
    type: 'string',
  },
  trainerId: {
    kind: 'column',
    name: 'trainerId',
    type: 'integer',
  },
  trainerType: {
    kind: 'column',
    name: 'trainerType',
    type: 'string',
  },
  ownerId: {
    kind: 'column',
    name: 'ownerId',
    type: 'integer',
  },
  ownerType: {
    kind: 'column',
    name: 'ownerType',
    type: 'string',
  },
  troubleIndicator: {
    kind: 'column',
    name: 'troubleIndicator',
    type: 'string',
  },
  nonbettingIndicator: {
    kind: 'column',
    name: 'nonbettingIndicator',
    type: 'string',
  },
  scratchIndicator: {
    kind: 'column',
    name: 'scratchIndicator',
    type: 'string',
  },
  dateInserted: {
    kind: 'column',
    name: 'dateInserted',
    type: 'string',
  },
  shortComment: {
    kind: 'column',
    name: 'shortComment',
    type: 'string',
  },
  longComment: {
    kind: 'column',
    name: 'longComment',
    type: 'string',
  },
  winPayoff: {
    kind: 'column',
    name: 'winPayoff',
    type: 'float',
  },
  placePayoff: {
    kind: 'column',
    name: 'placePayoff',
    type: 'float',
  },
  showPayoff: {
    kind: 'column',
    name: 'showPayoff',
    type: 'float',
  },
  morningLineOdds: {
    kind: 'column',
    name: 'morningLineOdds',
    type: 'float',
  },
  scratchReason: {
    kind: 'column',
    name: 'scratchReason',
    type: 'string',
  },
  overweight: {
    kind: 'column',
    name: 'overweight',
    type: 'integer',
  },
  correctedWeightIndicator: {
    kind: 'column',
    name: 'correctedWeightIndicator',
    type: 'string',
  },
  nonstarterIndicator: {
    kind: 'column',
    name: 'nonstarterIndicator',
    type: 'string',
  },
  tempBreederName: {
    kind: 'column',
    name: 'tempBreederName',
    type: 'string',
  },
  apprenticeWeightAllowance: {
    kind: 'column',
    name: 'apprenticeWeightAllowance',
    type: 'integer',
  },
  racingOwnerSilks: {
    kind: 'column',
    name: 'racingOwnerSilks',
    type: 'string',
  },
  eligibilityText: {
    kind: 'column',
    name: 'eligibilityText',
    type: 'float',
  },
  registrationStatus: {
    kind: 'column',
    name: 'registrationStatus',
    type: 'string',
  },
  damRegistrationNumber: {
    kind: 'column',
    name: 'damRegistrationNumber',
    type: 'string',
  },
  sireRegistrationNumber: {
    kind: 'column',
    name: 'sireRegistrationNumber',
    type: 'string',
  },
  breedType: {
    kind: 'column',
    name: 'breedType',
    type: 'string',
  },
  foalingDate: {
    kind: 'column',
    name: 'foalingDate',
    type: 'string',
  },
  certificateIssuedDate: {
    kind: 'column',
    name: 'certificateIssuedDate',
    type: 'string',
  },
  dateOfDeath: {
    kind: 'column',
    name: 'dateOfDeath',
    type: 'string',
  },
  dateOfDeathPrecision: {
    kind: 'column',
    name: 'dateOfDeathPrecision',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  breedingYear: {
    kind: 'column',
    name: 'breedingYear',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFStart> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
