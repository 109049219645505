// JsFromRoutes CacheKey be7b521b524868afc6fb055468b61ed4
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { OrganizationModel } from '@/models/organization'

export const organizationsApi = {
  autocomplete: defineEndpoint({
    controllerName: 'organizations',
    actionName: 'autocomplete',
    model: OrganizationModel,
    parts: [],
    httpMethod: 'get',
  }),
  index: defineEndpoint({
    controllerName: 'organizations',
    actionName: 'index',
    model: OrganizationModel,
    parts: ['api', 'organizations'],
    httpMethod: 'get',
    type: 'list',
  }),
  edit: defineEndpoint({
    controllerName: 'organizations',
    actionName: 'edit',
    model: OrganizationModel,
    parts: ['api', 'organizations', { name: 'id', attributeId: 'Organization:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'organizations',
    actionName: 'show',
    model: OrganizationModel,
    parts: ['api', 'organizations', { name: 'id', attributeId: 'Organization:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'organizations',
    actionName: 'update',
    model: OrganizationModel,
    parts: ['api', 'organizations', { name: 'id', attributeId: 'Organization:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
}
