import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctJournalEntry } from '@/types'

const name = 'IntacctJournalEntry'

const recordNameSingular = 'intacctJournalEntry'
const recordNamePlural = 'intacctJournalEntries'
const i18nKey = 'intacct::JournalEntry'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctJournalEntries'

const pageHelperKey = 'intacctJournalEntries'

const resourcePathName = 'intacct_journal_entry'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  batchNo: {
    kind: 'column',
    name: 'batchNo',
    type: 'integer',
  },
  batchTitle: {
    kind: 'column',
    name: 'batchTitle',
    type: 'string',
  },
  balance: {
    kind: 'column',
    name: 'balance',
    type: 'decimal',
  },
  journal: {
    kind: 'column',
    name: 'journal',
    type: 'string',
  },
  batchDate: {
    kind: 'column',
    name: 'batchDate',
    type: 'date',
  },
  module: {
    kind: 'column',
    name: 'module',
    type: 'string',
  },
  referenceNo: {
    kind: 'column',
    name: 'referenceNo',
    type: 'string',
  },
  reversed: {
    kind: 'column',
    name: 'reversed',
    type: 'date',
  },
  modified: {
    kind: 'column',
    name: 'modified',
    type: 'datetime',
  },
  schOpKey: {
    kind: 'column',
    name: 'schOpKey',
    type: 'integer',
  },
  journalSeqNo: {
    kind: 'column',
    name: 'journalSeqNo',
    type: 'text',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  taxImplications: {
    kind: 'column',
    name: 'taxImplications',
    type: 'string',
  },
  vatVendorId: {
    kind: 'column',
    name: 'vatVendorId',
    type: 'string',
  },
  vatCustomerId: {
    kind: 'column',
    name: 'vatCustomerId',
    type: 'string',
  },
  vatContactId: {
    kind: 'column',
    name: 'vatContactId',
    type: 'string',
  },
  taxSolutionId: {
    kind: 'column',
    name: 'taxSolutionId',
    type: 'string',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctJournalEntry> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
