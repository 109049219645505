import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'DBTMeasurement'

const recordNameSingular = 'dbtMeasurement'
const recordNamePlural = 'dbtMeasurements'
const i18nKey = 'dbt::Measurement'

const primaryKey = 'measurementId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtMeasurements'

const pageHelperKey = 'dbtMeasurements'

const resourcePathName = 'dbt_measurement'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  measurementId: {
    kind: 'column',
    name: 'measurementId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'timestamptz',
  },
  ageMonths: {
    kind: 'column',
    name: 'ageMonths',
    type: 'integer',
  },
  ageDays: {
    kind: 'column',
    name: 'ageDays',
    type: 'integer',
  },
  height: {
    kind: 'column',
    name: 'height',
    type: 'decimal',
  },
  heightReference: {
    kind: 'column',
    name: 'heightReference',
    type: 'float',
  },
  heightRelative: {
    kind: 'column',
    name: 'heightRelative',
    type: 'float',
  },
  weight: {
    kind: 'column',
    name: 'weight',
    type: 'decimal',
  },
  weightReference: {
    kind: 'column',
    name: 'weightReference',
    type: 'float',
  },
  weightRelative: {
    kind: 'column',
    name: 'weightRelative',
    type: 'float',
  },
  length: {
    kind: 'column',
    name: 'length',
    type: 'decimal',
  },
  shoulderWidth: {
    kind: 'column',
    name: 'shoulderWidth',
    type: 'decimal',
  },
  chestWidth: {
    kind: 'column',
    name: 'chestWidth',
    type: 'decimal',
  },
  hindWidth: {
    kind: 'column',
    name: 'hindWidth',
    type: 'decimal',
  },
  hindHeight: {
    kind: 'column',
    name: 'hindHeight',
    type: 'decimal',
  },
  forelimbLength: {
    kind: 'column',
    name: 'forelimbLength',
    type: 'decimal',
  },
  bodyLength: {
    kind: 'column',
    name: 'bodyLength',
    type: 'decimal',
  },
  collectionMethod: {
    kind: 'column',
    name: 'collectionMethod',
    type: 'integer',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
