/*
 * TODO Implement Index Page for home
 */
import React from 'react'

import { PageHeader } from '@/components/header'

const HomeIndex = () => {
  return (
    <>
      <PageHeader title="Home Page" />
    </>
  )
}

export default HomeIndex
