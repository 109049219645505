import { defineTable } from '@/components/data-table/builder'
import { currencyFormatter } from '@/components/data-table/formatters'

import { DBTSaleResultResource } from './resource'

const dbtSaleResultTable = defineTable(DBTSaleResultResource, {}, (tbl) => ({
  columns: [
    tbl.col.salePrice({
      label: 'Price',
      cell: currencyFormatter({ decimals: 0 }),
    }),

    tbl.col.saleTitle({ label: 'Sale' }),

    tbl.compoundCol('lastSale', [tbl.col.salePrice.id(), tbl.col.saleTitle.id()]),
  ],
}))

export { dbtSaleResultTable }
