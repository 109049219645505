import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTMareProduceRecord } from '@/types'

const name = 'DBTMareProduceRecord'

const recordNameSingular = 'dbtMareProduceRecord'
const recordNamePlural = 'dbtMareProduceRecords'
const i18nKey = 'dbt::MareProduceRecord'

const primaryKey = 'mareProduceRecordId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtMareProduceRecords'

const pageHelperKey = 'dbtMareProduceRecords'

const resourcePathName = 'dbt_mare_produce_record'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'DBTHorse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<DBTMareProduceRecord> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
