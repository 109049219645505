import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { RacehorseCommentCard, RacehorseCommentResource } from '@/resources/racehorse_comment'
import { racehorseCommentsRoutes } from '@/routes'

const RacehorseCommentsIndex = () => {
  const queries = useLoaderQueryBuilders()
  const racehorseComments = useResourceQuery(queries.racehorseCommentsIndex)

  if (racehorseComments.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No racehorse comments yet.</Text>
        <div>
          <Action route={racehorseCommentsRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">Racehorse Comments</Text>
        <Action route={racehorseCommentsRoutes.new} name="new" variant="primary" />
      </div>
      <RacehorseCommentCard
        key={racehorseComments.records[0].id}
        query={RacehorseCommentResource.query.show.withOptions({
          id: racehorseComments.records[0].id,
        })}
      />
      <Section className="mt-16" flex>
        {racehorseComments.records.slice(1).map((comment) => (
          <RacehorseCommentCard
            key={comment.id}
            query={RacehorseCommentResource.query.show.withOptions({ id: comment.id })}
          />
        ))}
      </Section>
    </>
  )
}

export default RacehorseCommentsIndex
