import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { StudFee } from '@/types'

const name = 'StudFee'

const recordNameSingular = 'studFee'
const recordNamePlural = 'studFees'
const i18nKey = 'studFee'

const primaryKey = 'id'
const searchKey = 'stallion.name'

const parents: Scope[] = []

const apiHelperKey = 'studFees'

const pageHelperKey = 'studFees'

const resourcePathName = 'stud_fee'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  price: {
    kind: 'column',
    name: 'price',
    type: 'integer',
  },
  currency: {
    kind: 'column',
    name: 'currency',
    type: 'string',
  },
  private: {
    kind: 'column',
    name: 'private',
    type: 'boolean',
  },
  farm: {
    kind: 'column',
    name: 'farm',
    type: 'string',
  },
  location: {
    kind: 'column',
    name: 'location',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'confirmed',
        },
        {
          value: 1,
          key: 'estimated',
        },
        {
          value: 2,
          key: 'previous_year',
        },
      ],
    },
  },
}

const modelSpec: ModelSpec<StudFee> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
