import { dateFormatter, enumFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'
import { enumDomainToMap } from '@/libs/resource/attribute'
import { DBTReproductionStatusModel } from '@/models/dbt/reproduction_status'

import { DBTReproductionStatusResource } from './resource'

export const dbtReproductionStatusTable = defineTable(DBTReproductionStatusResource, {}, (tbl) => ({
  columns: [
    tbl.col.coverDate({
      label: 'Cover Date',
      cell: dateFormatter({ format: 'MM/dd/yyyy' }),
    }),

    tbl.col.isMaiden(),

    tbl.col.status({
      label: 'Status',
    }),

    tbl.calculatedCol('statusWithMaiden', (row) => (row.isMaiden ? 'Maiden' : row.status), {
      label: 'Status',
      cell: (props) => {
        return props.getValue() === 'Maiden'
          ? 'Maiden'
          : enumFormatter({
              options: enumDomainToMap(DBTReproductionStatusModel.attributes.status.domain),
            })(props)
      },
    }),

    tbl.compoundCol('coverDateOverStatus', [tbl.colId('coverDate'), tbl.colId('statusWithMaiden')]),
  ],
}))
