import { z } from 'zod'

export const MatingSchema = z.object({
  year: z.number().int(),
  stallionId: z.number().int(),
  mareId: z.number().int(),
  organizationId: z.number().int(),
  final: z.boolean().default(false),
  cc: z.union([z.number(), z.null()]).optional(),
  ct: z.union([z.number(), z.null()]).optional(),
  tt: z.union([z.number(), z.null()]).optional(),
  coltACardio: z.union([z.number(), z.null()]).optional(),
  fillyACardio: z.union([z.number(), z.null()]).optional(),
  rating: z.union([z.string(), z.null()]).optional(),
  plan: z.union([z.string(), z.null()]).optional(),
  projectedMareValue: z.union([z.number(), z.null()]).optional(),
  projectedColtValue: z.union([z.number(), z.null()]).optional(),
  projectedFillyValue: z.union([z.number(), z.null()]).optional(),
  projectedFoalValue: z.union([z.number(), z.null()]).optional(),
  studFee: z.union([z.number(), z.null()]).optional(),
  netNet: z.union([z.number(), z.null()]).optional(),
  inFoalTo: z.union([z.string(), z.null()]).optional(),
  sexCarrying: z.union([z.string(), z.null()]).optional(),
  accountId: z.union([z.number().int(), z.null()]).optional(),
  barren: z.boolean().default(false),
  slipped: z.boolean().default(false),
  deadFoal: z.boolean().default(false),
  approved: z.union([z.boolean(), z.null()]).optional(),
  pedigreePass: z.union([z.boolean(), z.null()]).optional(),
  pedigreeNotes: z.union([z.string(), z.null()]).optional(),
  physicalPass: z.union([z.boolean(), z.null()]).optional(),
  physicalNotes: z.union([z.string(), z.null()]).optional(),
  financialsPass: z.union([z.boolean(), z.null()]).optional(),
  financialsNotes: z.union([z.string(), z.null()]).optional(),
  stallionAvailabilityPass: z.union([z.boolean(), z.null()]).optional(),
  stallionAvailabilityNotes: z.union([z.string(), z.null()]).optional(),
  notes: z.union([z.string(), z.null()]).optional(),
  notBred: z.boolean().default(false),
  lastDateBred: z.union([z.string().date(), z.null()]).optional(),
  shortlist: z.boolean().default(true),
  fiveCross: z.union([z.string(), z.null()]).optional(),
})
