import { formatDate } from '@/utils'

export const dateFormatter =
  ({ format = "MMM do ''yy" } = {}) =>
  ({ getValue }) => {
    const value = getValue()

    if (value) {
      return formatDate(value, format)
    } else {
      return value
    }
  }
