import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { ImageForm } from '@/resources/image/form'

const ImagesNew = () => {
  const { imagesNew } = useLoaderQueryBuilders()
  const { record: image } = useResourceQuery(imagesNew)

  return (
    <Section title="New Image">
      <ImageForm initialValues={image} />
    </Section>
  )
}

export default ImagesNew
