import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { CareerRaceSummaryCard } from '@/resources/dbt/career_race_summary'

const DBTCareerRaceSummariesShow = () => {
  const { dbtcareerRaceSummariesShow } = useLoaderQueryBuilders()
  const { record: dbtcareerRaceSummary } = useResourceQuery(dbtcareerRaceSummariesShow)

  return (
    <Section title={dbtcareerRaceSummary.title}>
      <CareerRaceSummaryCard
        key={dbtcareerRaceSummary.id}
        dbtcareerRaceSummary={dbtcareerRaceSummary}
      />
    </Section>
  )
}

export default DBTCareerRaceSummariesShow
