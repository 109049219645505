import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Horse } from '@/types'

const name = 'Foal'

const recordNameSingular = 'foal'
const recordNamePlural = 'foals'
const i18nKey = 'foal'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'foals'

const pageHelperKey = 'foals'

const resourcePathName = 'foal'

const attributes: Record<string, AttributeSpec> = {
  dam: {
    kind: 'association',
    name: 'dam',
    modelName: 'Horse',
    foreignKey: 'damId',
    associationPrimaryKey: 'id',
  },
  sire: {
    kind: 'association',
    name: 'sire',
    modelName: 'Horse',
    foreignKey: 'sireId',
    associationPrimaryKey: 'id',
  },
  damDam: {
    kind: 'association',
    name: 'damDam',
    modelName: 'Horse',
    foreignKey: 'damDamId',
    associationPrimaryKey: 'id',
  },
  sireSire: {
    kind: 'association',
    name: 'sireSire',
    modelName: 'Horse',
    foreignKey: 'sireSireId',
    associationPrimaryKey: 'id',
  },
  damSire: {
    kind: 'association',
    name: 'damSire',
    modelName: 'Horse',
    foreignKey: 'damSireId',
    associationPrimaryKey: 'id',
  },
  sireDam: {
    kind: 'association',
    name: 'sireDam',
    modelName: 'Horse',
    foreignKey: 'sireDamId',
    associationPrimaryKey: 'id',
  },
  breeder: {
    kind: 'association',
    name: 'breeder',
    modelName: 'Organization',
    foreignKey: 'breederId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  family: {
    kind: 'association',
    name: 'family',
    modelName: 'Family',
    foreignKey: 'familyId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  origin: {
    kind: 'column',
    name: 'origin',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'enum',
    subtype: 'string',
    domain: {
      values: [
        {
          value: 'b',
          key: 'b',
        },
        {
          value: 'bro',
          key: 'bro',
        },
        {
          value: 'blk',
          key: 'blk',
        },
        {
          value: 'ch',
          key: 'ch',
        },
        {
          value: 'dkbbr',
          key: 'dkbbr',
        },
        {
          value: 'grro',
          key: 'grro',
        },
        {
          value: 'gr',
          key: 'gr',
        },
        {
          value: 'ro',
          key: 'ro',
        },
        {
          value: 'pal',
          key: 'pal',
        },
        {
          value: 'wh',
          key: 'wh',
        },
      ],
    },
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'enum',
    subtype: 'string',
    domain: {
      values: [
        {
          value: 'C',
          key: 'C',
        },
        {
          value: 'H',
          key: 'H',
        },
        {
          value: 'G',
          key: 'G',
        },
        {
          value: 'R',
          key: 'R',
        },
        {
          value: 'F',
          key: 'F',
        },
        {
          value: 'M',
          key: 'M',
        },
        {
          value: 'B',
          key: 'B',
        },
      ],
    },
  },
  familyName: {
    kind: 'column',
    name: 'familyName',
    type: 'string',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  di: {
    kind: 'column',
    name: 'di',
    type: 'string',
  },
  dp: {
    kind: 'column',
    name: 'dp',
    type: 'string',
  },
  cd: {
    kind: 'column',
    name: 'cd',
    type: 'string',
  },
  ptag: {
    kind: 'column',
    name: 'ptag',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  damDamName: {
    kind: 'column',
    name: 'damDamName',
    type: 'string',
  },
  damDamYob: {
    kind: 'column',
    name: 'damDamYob',
    type: 'integer',
  },
  damSireName: {
    kind: 'column',
    name: 'damSireName',
    type: 'string',
  },
  damSireYob: {
    kind: 'column',
    name: 'damSireYob',
    type: 'integer',
  },
  sireDamName: {
    kind: 'column',
    name: 'sireDamName',
    type: 'string',
  },
  sireDamYob: {
    kind: 'column',
    name: 'sireDamYob',
    type: 'integer',
  },
  sireSireName: {
    kind: 'column',
    name: 'sireSireName',
    type: 'string',
  },
  sireSireYob: {
    kind: 'column',
    name: 'sireSireYob',
    type: 'integer',
  },
  familyId: {
    kind: 'column',
    name: 'familyId',
    type: 'integer',
  },
  secondTwin: {
    kind: 'column',
    name: 'secondTwin',
    type: 'boolean',
  },
  horseType: {
    kind: 'column',
    name: 'horseType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'unknown',
        },
        {
          value: 1,
          key: 'foal',
        },
        {
          value: 2,
          key: 'yearling',
        },
        {
          value: 3,
          key: 'racehorse',
        },
        {
          value: 4,
          key: 'mare',
        },
        {
          value: 5,
          key: 'stallion',
        },
      ],
    },
  },
  breederId: {
    kind: 'column',
    name: 'breederId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  damDamId: {
    kind: 'column',
    name: 'damDamId',
    type: 'integer',
  },
  sireSireId: {
    kind: 'column',
    name: 'sireSireId',
    type: 'integer',
  },
  damSireId: {
    kind: 'column',
    name: 'damSireId',
    type: 'integer',
  },
  sireDamId: {
    kind: 'column',
    name: 'sireDamId',
    type: 'integer',
  },
  oppenheimSireLineId: {
    kind: 'column',
    name: 'oppenheimSireLineId',
    type: 'integer',
  },
  registrationNumber: {
    kind: 'column',
    name: 'registrationNumber',
    type: 'string',
  },
  registrationStatus: {
    kind: 'column',
    name: 'registrationStatus',
    type: 'string',
  },
  microchipNumber: {
    kind: 'column',
    name: 'microchipNumber',
    type: 'string',
  },
  certificateIssuedDate: {
    kind: 'column',
    name: 'certificateIssuedDate',
    type: 'date',
  },
  dateOfDeath: {
    kind: 'column',
    name: 'dateOfDeath',
    type: 'date',
  },
  timeOfDeath: {
    kind: 'column',
    name: 'timeOfDeath',
    type: 'time',
  },
  namedRef: {
    kind: 'column',
    name: 'namedRef',
    type: 'citext',
  },
  unnamedDamRef: {
    kind: 'column',
    name: 'unnamedDamRef',
    type: 'citext',
  },
  unnamedParentRef: {
    kind: 'column',
    name: 'unnamedParentRef',
    type: 'citext',
  },
  nameProper: {
    kind: 'column',
    name: 'nameProper',
    type: 'citext',
  },
  studBookCode: {
    kind: 'column',
    name: 'studBookCode',
    type: 'string',
  },
  studBookAliasId: {
    kind: 'column',
    name: 'studBookAliasId',
    type: 'string',
  },
  identifierType: {
    kind: 'column',
    name: 'identifierType',
    type: 'integer',
  },
  migrationStatus: {
    kind: 'column',
    name: 'migrationStatus',
    type: 'string',
  },
}

const modelSpec: ModelSpec<Horse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
