import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import Icon from '@/components/icon'
import { Button } from '@/components/ui/button'

const emptyDefault: Record<string, unknown> = {}

const NestedRecords = ({
  name,
  renderRecord,
  defaultValues = emptyDefault,
  label = 'Add Record',
}) => {
  const { fields, append, remove, update } = useFieldArray({
    name,
    keyName: 'reactFieldId',
  })

  const { formState } = useFormContext()

  const handleRemove = (field, index) => {
    field.id ? update(index, { id: field.id, _destroy: true }) : remove(index)
  }

  const lastField = fields[fields.length - 1]

  // TODO Not handling intial values properly
  //
  // TODO Conditionally render Append button (possibly based on validation of the nested records)
  //(lastField && getFieldState(`${name}.${fields.length-1}${(primaryKey ? `.${primaryKey}` : '')}`).isDirty)
  return (
    <div className="flex flex-col gap-2">
      {fields.map((field, index) => {
        if (field._destroy) return null
        return (
          <div className="flex justify-between" key={`nestedInput-${field.id}`}>
            {renderRecord(field, index)}
            <Button
              key={`remove-${field.id}`}
              variant="primary"
              size="sm"
              type="button"
              iconRight={<Icon name="X" />}
              onClick={() => {
                handleRemove(field, index)
              }}
            />
          </div>
        )
      })}
      {
        <Button
          variant="primary"
          type="button"
          size="sm"
          iconRight={<Icon name="Plus" />}
          className="w-max"
          onClick={() => {
            append(defaultValues)
          }}
        >
          {label}
        </Button>
      }
    </div>
  )
}

export default NestedRecords
