import React from 'react'

import { ActionsDropdown } from './actions-dropdown'

interface ActionsProps {
  numToShow?: number
  children?: React.ReactNode
}

const Actions = ({ numToShow = 2, children, ...props }: ActionsProps) => {
  const actions = React.Children.toArray(children)

  const visibleActions = actions.slice(0, numToShow)

  if (actions.length === 0) {
    return
  }

  return (
    <div className="flex gap-1">
      {visibleActions.map((action: React.ReactNode) =>
        React.cloneElement(action, { iconOnly: true }),
      )}

      {actions.length > numToShow ? <ActionsDropdown {...props}>{children}</ActionsDropdown> : null}
    </div>
  )
}

export { Actions }
