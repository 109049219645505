import React from 'react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { useAuth } from '@/contexts/auth'

function Login() {
  const [data, setData] = React.useState({
    email: '',
    password: '',
    remember: false,
  })

  const { signIn } = useAuth()

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }))
  }

  function handleCheckboxChange(e: React.FormEvent<HTMLInputElement>) {
    setData((data) => ({ ...data, [e.target.name]: e.target.checked }))
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    signIn(data)
  }

  return (
    <div className="absolute left-0 top-0 h-dvh w-screen bg-background text-xl">
      <div className="flex size-full items-center justify-center">
        <div className="w-1/3 rounded-2xl bg-card p-16 shadow-xl phone:size-full">
          <form onSubmit={handleSubmit} className="grid gap-4">
            <div className="grid gap-2">
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                name="email"
                className="text-lg"
                type="email"
                placeholder="Email"
                value={data.email}
                onChange={handleChange}
                autoFocus
                required
              />
            </div>
            <div className="grid gap-2">
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                name="password"
                className="text-lg"
                type="password"
                placeholder="Password"
                value={data.password}
                onChange={handleChange}
                required
              />
              <div className="flex justify-between text-xs">
                <div className="flex items-center gap-1">
                  <Checkbox
                    id="remember"
                    name="remember"
                    checked={data.remember}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="remember">Remember me</label>
                </div>
                <span className="cursor-pointer text-accent-foreground hover:text-foreground">
                  Forgot Password?
                </span>
              </div>
              <Button className="mt-8 w-full" type="submit">
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { Login }

export default Login
