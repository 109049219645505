import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ThoromanagerRaceEntry } from '@/types'

const name = 'ThoromanagerRaceEntry'

const recordNameSingular = 'thoromanagerRaceEntry'
const recordNamePlural = 'thoromanagerRaceEntries'
const i18nKey = 'thoromanager::RaceEntry'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'thoromanagerRaceEntries'

const pageHelperKey = 'thoromanagerRaceEntries'

const resourcePathName = 'thoromanager_race_entry'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  damDamName: {
    kind: 'column',
    name: 'damDamName',
    type: 'citext',
  },
  damDamYob: {
    kind: 'column',
    name: 'damDamYob',
    type: 'integer',
  },
  damSireName: {
    kind: 'column',
    name: 'damSireName',
    type: 'citext',
  },
  damSireYob: {
    kind: 'column',
    name: 'damSireYob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  sireDamName: {
    kind: 'column',
    name: 'sireDamName',
    type: 'citext',
  },
  sireDamYob: {
    kind: 'column',
    name: 'sireDamYob',
    type: 'integer',
  },
  sireSireName: {
    kind: 'column',
    name: 'sireSireName',
    type: 'citext',
  },
  sireSireYob: {
    kind: 'column',
    name: 'sireSireYob',
    type: 'integer',
  },
  raceName: {
    kind: 'column',
    name: 'raceName',
    type: 'string',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  cardId: {
    kind: 'column',
    name: 'cardId',
    type: 'string',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'string',
  },
  corLong: {
    kind: 'column',
    name: 'corLong',
    type: 'string',
  },
  official: {
    kind: 'column',
    name: 'official',
    type: 'boolean',
  },
  offTurf: {
    kind: 'column',
    name: 'offTurf',
    type: 'boolean',
  },
  ageRestriction: {
    kind: 'column',
    name: 'ageRestriction',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  courseId: {
    kind: 'column',
    name: 'courseId',
    type: 'string',
  },
  aboutDistance: {
    kind: 'column',
    name: 'aboutDistance',
    type: 'string',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'float',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  purse: {
    kind: 'column',
    name: 'purse',
    type: 'float',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'string',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'string',
  },
  starters: {
    kind: 'column',
    name: 'starters',
    type: 'integer',
  },
  maxClaimPrice: {
    kind: 'column',
    name: 'maxClaimPrice',
    type: 'float',
  },
  temporaryRailDistance: {
    kind: 'column',
    name: 'temporaryRailDistance',
    type: 'integer',
  },
  chuteStart: {
    kind: 'column',
    name: 'chuteStart',
    type: 'string',
  },
  sealed: {
    kind: 'column',
    name: 'sealed',
    type: 'string',
  },
  runUpDistance: {
    kind: 'column',
    name: 'runUpDistance',
    type: 'integer',
  },
  leaderFraction1Time: {
    kind: 'column',
    name: 'leaderFraction1Time',
    type: 'float',
  },
  leaderFraction2Time: {
    kind: 'column',
    name: 'leaderFraction2Time',
    type: 'float',
  },
  leaderFraction3Time: {
    kind: 'column',
    name: 'leaderFraction3Time',
    type: 'float',
  },
  leaderFraction4Time: {
    kind: 'column',
    name: 'leaderFraction4Time',
    type: 'float',
  },
  leaderFraction5Time: {
    kind: 'column',
    name: 'leaderFraction5Time',
    type: 'float',
  },
  raceStretchTime: {
    kind: 'column',
    name: 'raceStretchTime',
    type: 'float',
  },
  raceWinTime: {
    kind: 'column',
    name: 'raceWinTime',
    type: 'float',
  },
  leaderPaceRating1: {
    kind: 'column',
    name: 'leaderPaceRating1',
    type: 'float',
  },
  leaderPaceRating2: {
    kind: 'column',
    name: 'leaderPaceRating2',
    type: 'float',
  },
  leaderPaceRating3: {
    kind: 'column',
    name: 'leaderPaceRating3',
    type: 'float',
  },
  leaderPaceRating4: {
    kind: 'column',
    name: 'leaderPaceRating4',
    type: 'float',
  },
  leaderPaceRating5: {
    kind: 'column',
    name: 'leaderPaceRating5',
    type: 'float',
  },
  leaderPaceRatingStretch: {
    kind: 'column',
    name: 'leaderPaceRatingStretch',
    type: 'float',
  },
  raceCsr: {
    kind: 'column',
    name: 'raceCsr',
    type: 'float',
  },
  dailyVariant: {
    kind: 'column',
    name: 'dailyVariant',
    type: 'integer',
  },
  postTimeEst: {
    kind: 'column',
    name: 'postTimeEst',
    type: 'time',
  },
  statebred: {
    kind: 'column',
    name: 'statebred',
    type: 'string',
  },
  raceQualityType: {
    kind: 'column',
    name: 'raceQualityType',
    type: 'string',
  },
  age: {
    kind: 'column',
    name: 'age',
    type: 'integer',
  },
  earnings: {
    kind: 'column',
    name: 'earnings',
    type: 'float',
  },
  odds: {
    kind: 'column',
    name: 'odds',
    type: 'float',
  },
  favorite: {
    kind: 'column',
    name: 'favorite',
    type: 'boolean',
  },
  medication: {
    kind: 'column',
    name: 'medication',
    type: 'string',
  },
  equipment: {
    kind: 'column',
    name: 'equipment',
    type: 'string',
  },
  weightCarried: {
    kind: 'column',
    name: 'weightCarried',
    type: 'integer',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  postPosition: {
    kind: 'column',
    name: 'postPosition',
    type: 'integer',
  },
  call1Position: {
    kind: 'column',
    name: 'call1Position',
    type: 'integer',
  },
  call2Position: {
    kind: 'column',
    name: 'call2Position',
    type: 'integer',
  },
  call3Position: {
    kind: 'column',
    name: 'call3Position',
    type: 'integer',
  },
  call4Position: {
    kind: 'column',
    name: 'call4Position',
    type: 'integer',
  },
  stretchPosition: {
    kind: 'column',
    name: 'stretchPosition',
    type: 'integer',
  },
  finishPosition: {
    kind: 'column',
    name: 'finishPosition',
    type: 'integer',
  },
  finishPositionOfficial: {
    kind: 'column',
    name: 'finishPositionOfficial',
    type: 'integer',
  },
  deadHeat: {
    kind: 'column',
    name: 'deadHeat',
    type: 'boolean',
  },
  call1LengthsBehind: {
    kind: 'column',
    name: 'call1LengthsBehind',
    type: 'float',
  },
  call2LengthsBehind: {
    kind: 'column',
    name: 'call2LengthsBehind',
    type: 'float',
  },
  call3LengthsBehind: {
    kind: 'column',
    name: 'call3LengthsBehind',
    type: 'float',
  },
  call4LengthsBehind: {
    kind: 'column',
    name: 'call4LengthsBehind',
    type: 'float',
  },
  stretchLengthsBehind: {
    kind: 'column',
    name: 'stretchLengthsBehind',
    type: 'decimal',
  },
  finishLengthsBehind: {
    kind: 'column',
    name: 'finishLengthsBehind',
    type: 'decimal',
  },
  claimedPrice: {
    kind: 'column',
    name: 'claimedPrice',
    type: 'float',
  },
  claimed: {
    kind: 'column',
    name: 'claimed',
    type: 'boolean',
  },
  coupledType: {
    kind: 'column',
    name: 'coupledType',
    type: 'string',
  },
  jockeyFName: {
    kind: 'column',
    name: 'jockeyFName',
    type: 'string',
  },
  jockeyMName: {
    kind: 'column',
    name: 'jockeyMName',
    type: 'string',
  },
  jockeyLName: {
    kind: 'column',
    name: 'jockeyLName',
    type: 'string',
  },
  trainerFName: {
    kind: 'column',
    name: 'trainerFName',
    type: 'string',
  },
  trainerMName: {
    kind: 'column',
    name: 'trainerMName',
    type: 'string',
  },
  trainerLName: {
    kind: 'column',
    name: 'trainerLName',
    type: 'string',
  },
  ownerFullName: {
    kind: 'column',
    name: 'ownerFullName',
    type: 'string',
  },
  trouble: {
    kind: 'column',
    name: 'trouble',
    type: 'boolean',
  },
  nonbetting: {
    kind: 'column',
    name: 'nonbetting',
    type: 'boolean',
  },
  scratched: {
    kind: 'column',
    name: 'scratched',
    type: 'boolean',
  },
  scratchReason: {
    kind: 'column',
    name: 'scratchReason',
    type: 'text',
  },
  shortComment: {
    kind: 'column',
    name: 'shortComment',
    type: 'text',
  },
  fraction1Time: {
    kind: 'column',
    name: 'fraction1Time',
    type: 'float',
  },
  fraction2Time: {
    kind: 'column',
    name: 'fraction2Time',
    type: 'float',
  },
  fraction3Time: {
    kind: 'column',
    name: 'fraction3Time',
    type: 'float',
  },
  fraction4Time: {
    kind: 'column',
    name: 'fraction4Time',
    type: 'float',
  },
  fraction5Time: {
    kind: 'column',
    name: 'fraction5Time',
    type: 'float',
  },
  stretchTime: {
    kind: 'column',
    name: 'stretchTime',
    type: 'float',
  },
  stretchRanTime: {
    kind: 'column',
    name: 'stretchRanTime',
    type: 'float',
  },
  lateRanTime: {
    kind: 'column',
    name: 'lateRanTime',
    type: 'float',
  },
  finishTime: {
    kind: 'column',
    name: 'finishTime',
    type: 'float',
  },
  careerRaceNumber: {
    kind: 'column',
    name: 'careerRaceNumber',
    type: 'integer',
  },
  runningStyleActual: {
    kind: 'column',
    name: 'runningStyleActual',
    type: 'string',
  },
  runningStyle: {
    kind: 'column',
    name: 'runningStyle',
    type: 'string',
  },
  runningStyleType: {
    kind: 'column',
    name: 'runningStyleType',
    type: 'string',
  },
  runningStylePosition: {
    kind: 'column',
    name: 'runningStylePosition',
    type: 'integer',
  },
  daysSinceLastRaced: {
    kind: 'column',
    name: 'daysSinceLastRaced',
    type: 'integer',
  },
  paceRating1: {
    kind: 'column',
    name: 'paceRating1',
    type: 'integer',
  },
  paceRating2: {
    kind: 'column',
    name: 'paceRating2',
    type: 'integer',
  },
  paceRating3: {
    kind: 'column',
    name: 'paceRating3',
    type: 'integer',
  },
  paceRating4: {
    kind: 'column',
    name: 'paceRating4',
    type: 'integer',
  },
  paceRating5: {
    kind: 'column',
    name: 'paceRating5',
    type: 'integer',
  },
  paceRatingStretch: {
    kind: 'column',
    name: 'paceRatingStretch',
    type: 'integer',
  },
  paceRatingLate: {
    kind: 'column',
    name: 'paceRatingLate',
    type: 'integer',
  },
  hCsr: {
    kind: 'column',
    name: 'hCsr',
    type: 'integer',
  },
  trackAbbreviation: {
    kind: 'column',
    name: 'trackAbbreviation',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  fileName: {
    kind: 'column',
    name: 'fileName',
    type: 'string',
  },
  fileDate: {
    kind: 'column',
    name: 'fileDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  breeder: {
    kind: 'column',
    name: 'breeder',
    type: 'string',
  },
  projectedSpeedRating: {
    kind: 'column',
    name: 'projectedSpeedRating',
    type: 'integer',
  },
  equibaseRaceConditions: {
    kind: 'column',
    name: 'equibaseRaceConditions',
    type: 'string',
  },
  equibaseRaceType: {
    kind: 'column',
    name: 'equibaseRaceType',
    type: 'string',
  },
}

const modelSpec: ModelSpec<ThoromanagerRaceEntry> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
