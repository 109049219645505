import { snakeCase } from '@js-from-routes/core'

import { FilterDef, PaginationDef, SortDef } from './builder'
import { FilterPrimitive, OperatorName, Operators } from './operators'

type SortParam = string

type FilterParam = Record<string, FilterPrimitive | undefined>

interface PaginationParam {
  page?: number | string
  items?: number
}

export interface RansackQueryParams {
  q?: {
    sorts?: SortParam[]
    filters?: Record<string, FilterParam>
  }
  pagination?: PaginationParam
}

export const parameterKey = (field: string, opName: OperatorName): string => {
  const operator = Operators[opName]
  if (operator === undefined) {
    throw new Error(`Invalid operator name: ${opName}`)
  }

  return operator.predicate ? [field, operator.predicate].join('_') : field
}

// TODO: Need to support compound operator defs
export const filterToParams = (filter: FilterDef) => ({
  [parameterKey(filter.field, filter.opName)]: filter.value,
})

export const sortToParams = (sort: SortDef) => {
  if (sort.direction == null) {
    return snakeCase(sort.field)
  }

  return `${snakeCase(sort.field)} ${sort.direction}`
}

export const paginationToParams = (pagination: PaginationDef) => ({
  page: pagination.page,
  items: pagination.pageSize,
})
