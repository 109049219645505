import React from 'react'
import { Outlet } from 'react-router-dom'

import { RouteDevtool } from '@/components/devtools/route-devtool'
import { TailwindIndicator } from '@/components/devtools/tailwind-indicator'
import { DefaultErrorBoundary } from '@/components/errors'
import { PWABadge } from '@/components/pwa'
import { DefaultSuspense } from '@/components/suspense/default'
import { Toaster } from '@/components/ui/sonner'
import { TooltipProvider } from '@/components/ui/tooltip'
import { ResourceProvider } from '@/contexts'
import { AuthProvider } from '@/contexts/auth'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const RootLayout = ({
  children,
  removeOutlet = false,
}: {
  children: React.Node
  removeOutlet: boolean
}) => {
  return (
    <AuthProvider>
      <ResourceProvider>
        <DefaultErrorBoundary>
          <DefaultErrorBoundary>
            <DefaultSuspense>
              <TooltipProvider>
                {removeOutlet ? null : <Outlet />}
                {children}
              </TooltipProvider>
            </DefaultSuspense>
          </DefaultErrorBoundary>

          <div className="absolute hidden lg:inline-block">
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
          </div>
          <TailwindIndicator />
          <RouteDevtool />
          <Toaster />
          <PWABadge />
        </DefaultErrorBoundary>
      </ResourceProvider>
    </AuthProvider>
  )
}

export default RootLayout
