import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctBudget } from '@/types'

const name = 'IntacctBudget'

const recordNameSingular = 'intacctBudget'
const recordNamePlural = 'intacctBudgets'
const i18nKey = 'intacct::Budget'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctBudgets'

const pageHelperKey = 'intacctBudgets'

const resourcePathName = 'intacct_budget'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  budgetId: {
    kind: 'column',
    name: 'budgetId',
    type: 'string',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'string',
  },
  systemGenerated: {
    kind: 'column',
    name: 'systemGenerated',
    type: 'boolean',
  },
  defaultBudget: {
    kind: 'column',
    name: 'defaultBudget',
    type: 'string',
  },
  user: {
    kind: 'column',
    name: 'user',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  isConsolidated: {
    kind: 'column',
    name: 'isConsolidated',
    type: 'boolean',
  },
  currency: {
    kind: 'column',
    name: 'currency',
    type: 'string',
  },
  ispaBudget: {
    kind: 'column',
    name: 'ispaBudget',
    type: 'boolean',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctBudget> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
