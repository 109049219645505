import React from 'react'

import { Actions, Description, Header, Labels, Title } from '@/components/header'

export type PageHeaderProps = React.ComponentProps<typeof Header> & {
  title?: string
  as?: string
  description?: string
  badges?: React.ReactNode
  actions?: React.ReactNode
}

const PageHeader = ({
  title,
  as = 'h2',
  description,
  badges,
  actions,
  ...props
}: PageHeaderProps) => {
  return (
    <Header className="mb-2 phone:flex phone:flex-wrap phone:justify-between" {...props}>
      <Title as={as}>{title}</Title>
      <Description>{description}</Description>
      <Labels>{badges}</Labels>
      <Actions>{actions}</Actions>
    </Header>
  )
}

export { PageHeader }
