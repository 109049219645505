import type { ModelSpec } from './model_spec'
import type { ResourceRecord } from './record'

import { attributesFromSpec, type Attribute, defineGetAttribute } from './attribute'
import { PrimaryKeyValue } from './types'

export interface Model<T extends ResourceRecord = ResourceRecord>
  extends Omit<ModelSpec, 'attributes'> {
  attributes: Record<keyof T, Attribute>

  getAttribute: (name: string) => Attribute | undefined
  getAttributeById: (globalId: string) => Attribute | undefined
  getRecordId: (record?: ResourceRecord) => PrimaryKeyValue | undefined
  isPersisted: (record?: T) => boolean
}

const defineModel = <T extends ResourceRecord>(modelSpec: ModelSpec<T>) => {
  const getRecordId = (record?: ResourceRecord) => {
    if (record == null) return

    return record[modelSpec.primaryKey]
  }

  const attributes = attributesFromSpec(modelSpec)

  const getAttribute = defineGetAttribute(modelSpec)

  const getAttributeById = (attributeId: string) => {
    const attributeKey = Object.keys(attributes).find(
      (key: string) =>
        attributes[key]?.globalId === attributeId ||
        attributes[key]?.referencesGlobalId === attributeId,
    )

    if (attributeKey !== undefined) {
      return attributes[attributeKey]
    }
  }

  const isPersisted = (record?: T) => record && getRecordId(record) != null

  return {
    ...modelSpec,
    attributes,
    getAttribute,
    getAttributeById,
    getRecordId,
    isPersisted,
  }
}

export { defineModel }
