import { QueryDef, queryDefToParams } from '@/libs/filter/builder'
import { Model, ResourceRecord } from '@/libs/resource/types'
import { ContextStack, findAttributeFrame } from '@/libs/stack'
import { cleanObject, compact, filterObject } from '@/utils'

import { RoutePart, RoutePartAttribute } from './types'

type UrlParamPrimitive = string | number | boolean | Date | undefined

export interface QueryParams {
  [key: string]: UrlParamPrimitive | UrlParamPrimitive[] | QueryParams | QueryParams[]
}

export type PathParams = Record<string, UrlParamPrimitive | undefined>

export interface UrlParams {
  queryParams?: QueryParams
  pathParams?: PathParams
}

/**
 * Removes invalid values from params
 */
const sanitizeParams = (pathParams?: PathParams): PathParams | undefined => {
  if (pathParams == null || typeof pathParams !== 'object') {
    return {}
  }

  return filterObject(pathParams, (val) => val != null)
}

/**
 * Builds a single object from the query and ransack query route options that
 * can be used to generate proper URLSearchParams and be serialized in the request
 * when needed.
 */
const buildQueryParams = ({
  q = {},
  query = {},
}: {
  q?: QueryDef
  query?: QueryParams
}): QueryParams | undefined => {
  const params = cleanObject({ ...queryDefToParams(q), ...query })

  return params
}

interface ExtractParamsFromStackProps<T extends ResourceRecord> {
  parts: RoutePart[]
  model?: Model<T>
  stack?: ContextStack
}

/**
 * Extracts route parameters for a route from a given reosource stack
 */
const extractParamsFromStack = <T extends ResourceRecord>({
  parts,
  model,
  stack,
}: ExtractParamsFromStackProps<T>) => {
  if (stack == null) {
    return {}
  }

  const attributeParts = parts.filter(
    (part: RoutePart): part is RoutePartAttribute =>
      typeof part !== 'string' && typeof part === 'object' && 'attributeId' in part,
  )

  // Translates a given attributeId to the proper one for matching in the stack
  // If the attributeId is a foreign key to an association, it
  // grabs the primary key attribute from the associated model instead
  // Otherwise, if it's for a column, it is returned intact
  const fetchSearchAttributeFromModel = (attributeId: string) => {
    if (model == null) return

    const attribute = model.getAttributeById(attributeId)

    if (attribute === undefined) {
      return
    }

    if (attribute.kind !== 'association' && attribute.kind !== 'column') {
      return
    }

    return attribute.kind === 'association'
      ? `${attribute.modelName}:${attribute.associationPrimaryKey}`
      : attributeId
  }

  const params = attributeParts.reduce((acc, part) => {
    const { name, attributeId } = part

    const searchAttributeId = model ? fetchSearchAttributeFromModel(attributeId) : attributeId

    if (searchAttributeId == null) return acc

    const stackFrame = findAttributeFrame({
      stack,
      attributeId: searchAttributeId,
    })

    return stackFrame == null ? acc : { ...acc, [name]: stackFrame.value }
  }, {})

  return sanitizeParams(params)
}

const pathTemplateFromParts = (parts: RoutePart[] = [], absolute = false): string => {
  const transformed = compact(
    parts.map((part: RoutePart) => (typeof part === 'object' ? `:${part.name}` : part)),
  )

  const path = transformed.join('/')

  if (absolute) {
    return path.startsWith('/') ? path : `/${path}`
  }

  return path.startsWith('/') ? path.substring(1) : path
}

export { extractParamsFromStack, buildQueryParams, pathTemplateFromParts, sanitizeParams }
