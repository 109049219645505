import { PageHeader } from '@/components/header'
import { ExternalLink } from '@/components/icons'
import { Link } from '@/components/link'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { HorseInfo } from '@/resources/horse/horse_info'
import { horsesRoutes } from '@/routes/paths'

const HorsesSheet = () => {
  const { horsesShow } = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(horsesShow)

  const route = horsesRoutes.show.withOptions({
    relative: false,
    horseId: horse.id,
  })

  return (
    <>
      <PageHeader
        title={horse.name}
        actions={<Link size="3xl" route={route} iconLeft={<ExternalLink />} noChevron />}
      />
      <HorseInfo />
    </>
  )
}

export default HorsesSheet
