import { useParams } from 'react-router-dom'

import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { SaleRecordDetailCard, SaleRecordDetailResource } from '@/resources/sale_record_detail'

const SaleRecordDetailsIndex = () => {
  const { horseId } = useParams()
  const query = SaleRecordDetailResource.query.index
    .sort('sale__start_date', 'desc')
    .filter('horseId', horseId)
  const { records: saleRecordDetails } = useResourceQuery(query)

  if (saleRecordDetails.length === 0) {
    return 'No sale records'
  }

  return (
    <>
      <div className="mb-4 flex w-full items-center justify-between">
        <Text as="h3">Sales</Text>
      </div>
      <div className="flex flex-col gap-2">
        {saleRecordDetails.map((saleRecordDetail) => (
          <SaleRecordDetailCard
            key={saleRecordDetail.saleRecordDetailId}
            saleRecordDetail={saleRecordDetail}
          />
        ))}
      </div>
    </>
  )
}

export default SaleRecordDetailsIndex
