import React, { createContext, useContext } from 'react'
import { useMatches } from 'react-router-dom'

import { ResourceRecord } from '@/libs/resource/record'
import {
  type ContextFrame,
  type ContextStack,
  BuildFrameProps,
  constructAndMergeStacks,
  extractStackFromRoutes,
  isValidStack,
} from '@/libs/stack'

const ResourceContext = createContext<ContextStack | undefined>(undefined)

type UseResourceContextProps<T extends ResourceRecord> = BuildFrameProps<T> & {
  frames?: ContextFrame[]
}

export const useResourceContext = <T extends ResourceRecord>(
  props: UseResourceContextProps<T> = {},
) => {
  const stack = useContext(ResourceContext)
  const routeMatches = useMatches()

  const currentStack = isValidStack(stack) ? stack : extractStackFromRoutes(routeMatches)

  return constructAndMergeStacks({ currentStack, ...props })
}

interface ResourceProviderProps<T extends ResourceRecord> extends BuildFrameProps<T> {
  frames?: ContextFrame[]
  children?: React.ReactElement
}

export const ResourceProvider = <T extends ResourceRecord>({
  children,
  ...props
}: ResourceProviderProps<T>) => {
  const stack = useContext(ResourceContext)
  const routeMatches = useMatches()

  const currentStack = isValidStack(stack) ? stack : extractStackFromRoutes(routeMatches)

  const newStack = constructAndMergeStacks({ currentStack, ...props })

  return <ResourceContext.Provider value={newStack}>{children}</ResourceContext.Provider>
}

export default ResourceContext
