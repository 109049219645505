import React, { Suspense, useState, useMemo } from 'react'

import type { Table, Column, ColumnFilter } from '@tanstack/react-table'

import { FilterChip } from '@/components/filter'
import Icon from '@/components/icon'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ScrollArea } from '@/components/ui/scroll-area'
import { snakeToCamel } from '@/utils'

interface FilterControlProps<TData> {
  table: Table<TData>
}

export function FilterControl<TData>({ table }: FilterControlProps<TData>) {
  const initialFilterChips = useMemo(
    () =>
      table
        .getAllColumns()
        .filter((col: Column<TData>) => col.columnDef.meta?.filter?.initialChip)
        .map((col: Column<TData>) => col.id),
    [table],
  )

  const [shownFilters, setShownFilters] = useState<string[]>(initialFilterChips)

  const filteredColumns = useMemo(
    () =>
      table
        .getState()
        .columnFilters.filter((filter: ColumnFilter) => !shownFilters.includes(filter.id))
        .map((filter: ColumnFilter) => filter.id),
    [table, shownFilters],
  )

  const renderFilter = (column: Column<TData>) => {
    const filter = column.columnDef.meta?.filter

    if (filter == null) {
      return
    }

    const FilterComponent = filter?.Component

    if (!FilterComponent) return null

    const onChange = (value: unknown) => {
      if (value == null) {
        column.setFilterValue(undefined)
      } else {
        column.setFilterValue({
          opName: filter.opName,
          value,
        })
      }
    }

    // TODO Re-work this to put unique columns in domain, also probably won't work on server side
    const {
      options = Array.from(column.getFacetedUniqueValues().keys()).map((key) => ({
        key,
        value: key,
        label: key,
      })),
      ...restFilterProps
    } = filter.props

    const {
      options: {
        meta: { t },
      },
    } = table

    // TODO This shouldn't be needed when we update Filters to take
    // attribute/column objects since the attribute/column object will have their
    // translation key available
    const translatedOptions = options.map((option) => {
      const attrKey = column.id.split('.').slice(-1)[0]
      const tKey = option.label ? `enums.${attrKey}.${snakeToCamel(option.label)}` : 'missing'
      return { ...option, label: t(tKey) }
    })

    return (
      <FilterChip
        key={column.id}
        value={column.getFilterValue()?.value}
        label={filter.props.label || column.columnDef.meta.label}
      >
        <FilterComponent
          value={column.getFilterValue()?.value}
          onChange={onChange}
          label={filter.props.label || column.columnDef.meta.label}
          options={translatedOptions}
          {...restFilterProps}
        />
      </FilterChip>
    )
  }

  const isFiltered = table.getState().columnFilters.length > 0

  return (
    <Suspense>
      <div className="flex items-center gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="secondary"
              size="sm"
              className="ml-auto"
              iconLeft={<Icon name="Filter" />}
            >
              Filter
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Add Filter</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <ScrollArea className="flex max-h-[30rem] flex-col overflow-y-auto">
              {table
                .getAllColumns()
                .filter(
                  (column: Column) =>
                    column.getCanFilter() && column.id !== table.options.meta.searchColumn,
                )
                .map((column: Column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      checked={shownFilters.includes(column.id)}
                      onCheckedChange={(value) => {
                        if (value) {
                          setShownFilters((prev) => [...prev, column.id])
                        } else {
                          column.setFilterValue()
                          setShownFilters((prev) => {
                            const index = prev.indexOf(column.id)
                            if (index === -1) return prev
                            prev.splice(index, 1)
                            return [...prev]
                          })
                        }

                        return value
                      }}
                      className="pr-4"
                    >
                      {column.columnDef.meta.label}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </ScrollArea>
          </DropdownMenuContent>
        </DropdownMenu>

        {shownFilters
          .concat(filteredColumns)
          .filter((id: string) => id !== table.options.meta.searchColumn)
          .map((id: string) => table.getColumn(id))
          .filter((col) => col?.columnDef.meta?.filter?.Component)
          .map((col) => renderFilter(col))}

        {isFiltered && (
          <Button
            variant="ghost"
            size="sm"
            iconLeft={<Icon name="X" />}
            onClick={() => {
              table.resetColumnFilters()
            }}
          >
            Reset
          </Button>
        )}
      </div>
    </Suspense>
  )
}
