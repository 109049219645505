import React from 'react'

import { Button } from '@/components/ui/button'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'

export interface Preset {
  id: string
  label: string
  [key: string]: unknown
}

interface PresetProps {
  presets: Preset[]
  defaultPresetId?: string
  onChange: (value: string) => void
}

const defaultPresets: Preset[] = []

export const Presets = ({ presets = defaultPresets, defaultPresetId, onChange }: PresetProps) => {
  return (
    <ToggleGroup
      type="single"
      defaultValue={defaultPresetId}
      onValueChange={onChange}
      size="xs"
      className="rounded border border-input bg-white"
    >
      {presets.map((preset) => (
        <ToggleGroupItem key={preset.id} value={preset.id} asChild>
          <Button variant="inline" size="sm">
            {preset.label}
          </Button>
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  )
}
