import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsMain } from '@/types'

const name = 'DRFChartsMain'

const recordNameSingular = 'drfChartsMain'
const recordNamePlural = 'drfChartsMains'
const i18nKey = 'drf::ChartsMain'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsMains'

const pageHelperKey = 'drfChartsMains'

const resourcePathName = 'drf_charts_main'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  chartsTracksRaceDatesId: {
    kind: 'column',
    name: 'chartsTracksRaceDatesId',
    type: 'integer',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  postTime: {
    kind: 'column',
    name: 'postTime',
    type: 'string',
  },
  ageRestriction: {
    kind: 'column',
    name: 'ageRestriction',
    type: 'string',
  },
  stateRestriction: {
    kind: 'column',
    name: 'stateRestriction',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  racePurse: {
    kind: 'column',
    name: 'racePurse',
    type: 'string',
  },
  raceDisplayMoney: {
    kind: 'column',
    name: 'raceDisplayMoney',
    type: 'string',
  },
  claimingRace: {
    kind: 'column',
    name: 'claimingRace',
    type: 'integer',
  },
  minClaimPrice: {
    kind: 'column',
    name: 'minClaimPrice',
    type: 'string',
  },
  maxClaimPrice: {
    kind: 'column',
    name: 'maxClaimPrice',
    type: 'string',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  raceCourse: {
    kind: 'column',
    name: 'raceCourse',
    type: 'string',
  },
  distanceId: {
    kind: 'column',
    name: 'distanceId',
    type: 'integer',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'string',
  },
  distanceAbbreviated: {
    kind: 'column',
    name: 'distanceAbbreviated',
    type: 'string',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  classRating: {
    kind: 'column',
    name: 'classRating',
    type: 'string',
  },
  classRatingBiasColor: {
    kind: 'column',
    name: 'classRatingBiasColor',
    type: 'integer',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'string',
  },
  raceTypeLong: {
    kind: 'column',
    name: 'raceTypeLong',
    type: 'string',
  },
  raceTypeWithClaimingPrice: {
    kind: 'column',
    name: 'raceTypeWithClaimingPrice',
    type: 'string',
  },
  raceDescriptionText: {
    kind: 'column',
    name: 'raceDescriptionText',
    type: 'string',
  },
  winnerText1: {
    kind: 'column',
    name: 'winnerText1',
    type: 'string',
  },
  winnerText2: {
    kind: 'column',
    name: 'winnerText2',
    type: 'string',
  },
  raceFootnotes: {
    kind: 'column',
    name: 'raceFootnotes',
    type: 'string',
  },
  claimedHorsesBold: {
    kind: 'column',
    name: 'claimedHorsesBold',
    type: 'string',
  },
  claimedHorsesDetail: {
    kind: 'column',
    name: 'claimedHorsesDetail',
    type: 'string',
  },
  scratchedHorsesBold: {
    kind: 'column',
    name: 'scratchedHorsesBold',
    type: 'string',
  },
  scratchedHorsesDetail: {
    kind: 'column',
    name: 'scratchedHorsesDetail',
    type: 'string',
  },
  fraction1Official: {
    kind: 'column',
    name: 'fraction1Official',
    type: 'string',
  },
  fraction2Official: {
    kind: 'column',
    name: 'fraction2Official',
    type: 'string',
  },
  fraction3Official: {
    kind: 'column',
    name: 'fraction3Official',
    type: 'string',
  },
  fraction4Official: {
    kind: 'column',
    name: 'fraction4Official',
    type: 'string',
  },
  finalTimeOfficial: {
    kind: 'column',
    name: 'finalTimeOfficial',
    type: 'string',
  },
  fraction1Adjusted: {
    kind: 'column',
    name: 'fraction1Adjusted',
    type: 'string',
  },
  fraction2Adjusted: {
    kind: 'column',
    name: 'fraction2Adjusted',
    type: 'string',
  },
  fraction3Adjusted: {
    kind: 'column',
    name: 'fraction3Adjusted',
    type: 'string',
  },
  fraction4Adjusted: {
    kind: 'column',
    name: 'fraction4Adjusted',
    type: 'string',
  },
  finalTimeAdjusted: {
    kind: 'column',
    name: 'finalTimeAdjusted',
    type: 'string',
  },
  fr1Color: {
    kind: 'column',
    name: 'fr1Color',
    type: 'integer',
  },
  fr2Color: {
    kind: 'column',
    name: 'fr2Color',
    type: 'integer',
  },
  fr3Color: {
    kind: 'column',
    name: 'fr3Color',
    type: 'integer',
  },
  fr4Color: {
    kind: 'column',
    name: 'fr4Color',
    type: 'integer',
  },
  distanceHeader1: {
    kind: 'column',
    name: 'distanceHeader1',
    type: 'string',
  },
  distanceHeader2: {
    kind: 'column',
    name: 'distanceHeader2',
    type: 'string',
  },
  distanceHeader3: {
    kind: 'column',
    name: 'distanceHeader3',
    type: 'string',
  },
  distanceHeader4: {
    kind: 'column',
    name: 'distanceHeader4',
    type: 'string',
  },
  distanceHeader5: {
    kind: 'column',
    name: 'distanceHeader5',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  raceClass: {
    kind: 'column',
    name: 'raceClass',
    type: 'string',
  },
  stakesName: {
    kind: 'column',
    name: 'stakesName',
    type: 'string',
  },
}

const modelSpec: ModelSpec<DRFChartsMain> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
