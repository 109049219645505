import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { SaleRecord } from '@/types'

const name = 'SaleRecord'

const recordNameSingular = 'saleRecord'
const recordNamePlural = 'saleRecords'
const i18nKey = 'saleRecord'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse', 'sale']

const apiHelperKey = 'saleRecords'

const pageHelperKey = 'saleRecords'

const resourcePathName = 'sale_record'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  sale: {
    kind: 'association',
    name: 'sale',
    modelName: 'Sale',
    foreignKey: 'saleId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  saleId: {
    kind: 'column',
    name: 'saleId',
    type: 'integer',
  },
  session: {
    kind: 'column',
    name: 'session',
    type: 'integer',
  },
  sessionDate: {
    kind: 'column',
    name: 'sessionDate',
    type: 'date',
  },
  book: {
    kind: 'column',
    name: 'book',
    type: 'integer',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  barn: {
    kind: 'column',
    name: 'barn',
    type: 'string',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  firstFoal: {
    kind: 'column',
    name: 'firstFoal',
    type: 'string',
  },
  secondFoal: {
    kind: 'column',
    name: 'secondFoal',
    type: 'string',
  },
  pregnant: {
    kind: 'column',
    name: 'pregnant',
    type: 'boolean',
  },
  coveringSire: {
    kind: 'column',
    name: 'coveringSire',
    type: 'string',
  },
  coverDate: {
    kind: 'column',
    name: 'coverDate',
    type: 'date',
  },
  breedersCupElig: {
    kind: 'column',
    name: 'breedersCupElig',
    type: 'boolean',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  consignorId: {
    kind: 'column',
    name: 'consignorId',
    type: 'integer',
  },
  buyer: {
    kind: 'column',
    name: 'buyer',
    type: 'string',
  },
  buyerId: {
    kind: 'column',
    name: 'buyerId',
    type: 'integer',
  },
  rna: {
    kind: 'column',
    name: 'rna',
    type: 'boolean',
  },
  out: {
    kind: 'column',
    name: 'out',
    type: 'boolean',
  },
  salePrice: {
    kind: 'column',
    name: 'salePrice',
    type: 'decimal',
  },
  workTime: {
    kind: 'column',
    name: 'workTime',
    type: 'decimal',
  },
  workSet: {
    kind: 'column',
    name: 'workSet',
    type: 'string',
  },
  workDay: {
    kind: 'column',
    name: 'workDay',
    type: 'string',
  },
  ps: {
    kind: 'column',
    name: 'ps',
    type: 'string',
  },
  propertyLine: {
    kind: 'column',
    name: 'propertyLine',
    type: 'string',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'string',
  },
  soldAsCode: {
    kind: 'column',
    name: 'soldAsCode',
    type: 'string',
  },
  soldAsDesc: {
    kind: 'column',
    name: 'soldAsDesc',
    type: 'text',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<SaleRecord> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
