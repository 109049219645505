import React, { useMemo } from 'react'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { cn } from '@/utils'
import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon, EyeNoneIcon } from '@radix-ui/react-icons'
import { Column, Table } from '@tanstack/react-table'

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  table: Table
}

interface DataTableStackedColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  table: Table
}

interface DataTableColumnLabelProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  sortColumn: Column<TData, TValue>
}

const DataTableColumnLabel = <TData, TValue>({
  column,
  sortColumn,
  className,
}: DataTableColumnLabelProps<TData, TValue>) => {
  const SortIcon = (props) => {
    if (!sortColumn.getCanSort()) {
      return
    }

    const direction = sortColumn.getIsSorted()
    if (direction === 'desc') {
      return <ArrowDownIcon {...props} />
    }

    if (direction === 'asc') {
      return <ArrowUpIcon {...props} />
    }

    return <CaretSortIcon {...props} />
  }

  return (
    <div className="flex justify-between">
      <span className={cn('text-foreground', className)}>{column.columnDef.meta.label}</span>
      <SortIcon className="ml-2 size-4" />
    </div>
  )
}

export function DataTableStackedColumnHeader<TData, TValue>({
  column,
  className,
  ...props
}: DataTableStackedColumnHeaderProps<TData, TValue>) {
  const { table } = props
  const columnIds = column.columnDef.meta.columnIds

  const columnA = useMemo(() => {
    const column = table.getColumn(columnIds[0])

    return {
      column,
      sortColumn: column.columnDef.sortColumnId
        ? table.getColumn(column.columnDef.sortColumnId)
        : column,
    }
  }, [table, columnIds])

  const columnB = useMemo(() => {
    const column = table.getColumn(columnIds[1])

    return {
      column,
      sortColumn: column.columnDef.sortColumnId
        ? table.getColumn(column.columnDef.sortColumnId)
        : column,
    }
  }, [table, columnIds])

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-9 data-[state=open]:bg-accent">
            <div className="flex flex-col">
              <DataTableColumnLabel {...columnA} />
              <DataTableColumnLabel {...columnB} className="text-muted-foreground" />
            </div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>{columnA.column.columnDef.meta.label}</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => columnA.sortColumn.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => columnA.sortColumn.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>{columnB.column.columnDef.meta.label}</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => columnB.sortColumn.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => columnB.sortColumn.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              column.toggleVisibility(false)
            }}
          >
            <EyeNoneIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  table,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const sortColumn = useMemo(
    () => (column.columnDef.sortColumnId ? table.getColumn(column.columnDef.sortColumnId) : column),
    [column, table],
  )

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-9 data-[state=open]:bg-accent">
            <DataTableColumnLabel column={column} sortColumn={sortColumn} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => sortColumn.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => sortColumn.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              column.toggleVisibility(false)
            }}
          >
            <EyeNoneIcon className="mr-2 size-2.5 text-muted-foreground/70" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
