import { DBTCareerRaceSummary } from '@/types'

export const raceRecord = (dbtCareerRaceSummary: DBTCareerRaceSummary) => {
  if (dbtCareerRaceSummary.starts == null) {
    return '0-0-0-0'
  }

  const record = [
    dbtCareerRaceSummary.starts,
    dbtCareerRaceSummary.wins,
    dbtCareerRaceSummary.places,
    dbtCareerRaceSummary.shows,
  ]

  return record.map((x) => x ?? 0).join('-')
}
