import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceMonthlyEntry } from '@/types'

const name = 'FinanceMonthlyEntry'

const recordNameSingular = 'financeMonthlyEntry'
const recordNamePlural = 'financeMonthlyEntries'
const i18nKey = 'finance::MonthlyEntry'

const primaryKey = 'monthlyEntryId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeMonthlyEntries'

const pageHelperKey = 'financeMonthlyEntries'

const resourcePathName = 'finance_monthly_entry'

const attributes: Record<string, AttributeSpec> = {
  monthlyEntryId: {
    kind: 'column',
    name: 'monthlyEntryId',
    type: 'text',
  },
  monthEnd: {
    kind: 'column',
    name: 'monthEnd',
    type: 'date',
  },
  entryDimensionId: {
    kind: 'column',
    name: 'entryDimensionId',
    type: 'text',
  },
  scenario: {
    kind: 'column',
    name: 'scenario',
    type: 'text',
  },
  version: {
    kind: 'column',
    name: 'version',
    type: 'date',
  },
  versionLabel: {
    kind: 'column',
    name: 'versionLabel',
    type: 'text',
  },
  entityId: {
    kind: 'column',
    name: 'entityId',
    type: 'text',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  segment: {
    kind: 'column',
    name: 'segment',
    type: 'text',
  },
  bloodstockSituationId: {
    kind: 'column',
    name: 'bloodstockSituationId',
    type: 'text',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
  equineActivityGroup: {
    kind: 'column',
    name: 'equineActivityGroup',
    type: 'text',
  },
  equineActivitySubgroup: {
    kind: 'column',
    name: 'equineActivitySubgroup',
    type: 'text',
  },
  projectGroup: {
    kind: 'column',
    name: 'projectGroup',
    type: 'text',
  },
  project: {
    kind: 'column',
    name: 'project',
    type: 'text',
  },
  subject: {
    kind: 'column',
    name: 'subject',
    type: 'text',
  },
  detail: {
    kind: 'column',
    name: 'detail',
    type: 'text',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'decimal',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'decimal',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  amountNb: {
    kind: 'column',
    name: 'amountNb',
    type: 'decimal',
  },
  amountCf: {
    kind: 'column',
    name: 'amountCf',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  account: {
    kind: 'submodel',
    name: 'account',
    modelName: 'Account',
    columns: [
      'accountNo',
      'accountPrefix',
      'title',
      'accountLabel',
      'accountType',
      'normalBalance',
      'accountTypeFull',
      'accountGroup',
      'accountSector',
      'accountExpenseType',
      'eponaAccountExpenseGroup',
      'iscfAccountGroup',
      'cashFlowGroup',
    ],
  },
  bloodstockEntity: {
    kind: 'submodel',
    name: 'bloodstockEntity',
    modelName: 'BloodstockEntity',
    columns: [
      'resourceType',
      'resourceId',
      'name',
      'nameAge',
      'nameAgeSex',
      'horseString',
      'label',
    ],
  },
}

const modelSpec: ModelSpec<FinanceMonthlyEntry> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
