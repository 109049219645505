import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { iconText, InfoTile } from '@/components/info_tile'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { Tile } from '@/components/tile'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tag } from '@/components/ui/tag'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { SaleRecordResource } from '@/resources/sale_record/resource'
import { salesRoutes } from '@/routes'
import { Horse, Sale, SaleRecord } from '@/types'
import { formatCurrency } from '@/utils'

export const SaleRecordDetailContent = ({
  saleRecordDetail,
}: {
  saleRecordDetail: {
    id: number
    horse: Horse
    sale: Sale
    saleRecord: SaleRecord
  }
}) => {
  return (
    <>
      <div className="flex justify-between">
        <InfoTile label={'Hip'} variant="ghost" className="w-1/2">
          {saleRecordDetail.saleRecord.hip}
        </InfoTile>
        <InfoTile label={'Sale Price'} variant="ghost" className="flex-1">
          {saleRecordDetail.saleRecord.salePrice
            ? iconText('DollarSign', formatCurrency(saleRecordDetail.saleRecord.salePrice))
            : '-'}
        </InfoTile>
      </div>
      <div className="flex justify-between">
        <InfoTile label={'Consignor'} variant="ghost" className="w-1/2" title="Consignor">
          <span title={saleRecordDetail.saleRecord.consignor}>
            {saleRecordDetail.saleRecord.consignor ? saleRecordDetail.saleRecord.consignor : '-'}
          </span>
        </InfoTile>
        <InfoTile label={'Buyer'} variant="ghost" className="flex-1">
          <span title={saleRecordDetail.saleRecord.buyer}>
            {saleRecordDetail.saleRecord.buyer ? saleRecordDetail.saleRecord.buyer : '-'}
          </span>
        </InfoTile>
      </div>
      <InfoTile label={'Sire'} variant="ghost">
        {saleRecordDetail.horse.sireName ? saleRecordDetail.horse.sireName : '-'}
      </InfoTile>
    </>
  )
}

const SaleRecordSummaryContent = ({
  saleRecordDetails,
}: {
  saleRecordDetails: {
    id: number
    horse: Horse
    sale: Sale
    saleRecord: SaleRecord
  }[]
}) => {
  const latestSaleRecordDetail = saleRecordDetails[0]
  // assuming this wont be done in the frontend eventually
  const topSaleRecordDetail = saleRecordDetails.reduce((acc, curr) => {
    if (curr.saleRecord.salePrice > acc.saleRecord.salePrice) {
      return curr
    }
    return acc
  })
  return (
    <div className="flex justify-between">
      <Tile label="Latest Sale" muted className="h-[9.5rem] w-56 justify-between">
        <InlineInfoTile
          label={formatCurrency(latestSaleRecordDetail.saleRecord.salePrice)}
          icon="DollarSign"
          tooltipText={'Sale Price'}
          className="mb-4"
        />
        <InlineInfoTile
          label={latestSaleRecordDetail.sale.name}
          icon="Map"
          tooltipText={'Location'}
        />
      </Tile>
      <div className="flex h-1/2 flex-col gap-2 truncate">
        <span className="text-sm text-gray-500">Top Sale</span>
        <InlineInfoTile
          label={formatCurrency(topSaleRecordDetail.saleRecord.salePrice)}
          icon="DollarSign"
          tooltipText={'Sale Price'}
        />
        <InlineInfoTile label={topSaleRecordDetail.sale.name} icon="Map" tooltipText={'Location'} />
      </div>
    </div>
  )
}

export const SaleRecordDetailCard = ({ saleRecordDetail, className }: { className: string }) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={salesRoutes.show.withOptions({ saleId: saleRecordDetail.sale.id })}>
            {saleRecordDetail.sale.name}
          </Link>
        </CardTitle>
        <CardActions>
          <Tag size="sm" className="border">
            {saleRecordDetail.sale.year}
          </Tag>
        </CardActions>
      </CardHeader>
      <CardContent>
        <SaleRecordDetailContent saleRecordDetail={saleRecordDetail} />
      </CardContent>
    </Card>
  )
}

export const SaleRecordSummaryCard = <T extends ResourceRecord>({
  className,
  query,
}: {
  className?: string
  query: QueryBuilder<T>
}) => {
  const { records: saleRecords, error, isPending } = useResourceQuery(query)
  const pluralName = 'Sale Records'

  return (
    <SummaryCard
      // actions={<NewAction resource={SaleRecordDetailResource} iconOnly />}
      actions={null}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={SaleRecordResource.routes.index}
    >
      {saleRecords.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <SaleRecordSummaryContent saleRecordDetails={saleRecords} />
      )}
    </SummaryCard>
  )
}
