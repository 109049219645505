import React from 'react'

import type { Organization } from '@/types'

import EponaLogo from '@/assets/logos/epona-circle.svg'
import SienaLogo from '@/assets/logos/siena-light-bg.svg'
import WinStarLogo from '@/assets/logos/winstar-star.svg'
import { Link } from '@/components/link'

interface OrganizationSelectProps {
  organization: Organization
  hideTitle?: boolean
}

const OrganizationSelect = ({ organization, hideTitle = false }: OrganizationSelectProps) => {
  const Logo = organization.id === 9 ? SienaLogo : organization.id === 2 ? WinStarLogo : EponaLogo

  return (
    <Link
      variant="inline"
      className="flex items-center justify-start gap-2 rounded-lg leading-none hover:cursor-pointer"
      noChevron
      to="/"
    >
      <Logo height="40px" />
      {!hideTitle && organization.title}
    </Link>
  )
}

export { OrganizationSelect }
