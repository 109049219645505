import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { UserForm } from '@/resources/user/form'

const UsersEdit = () => {
  const { usersEdit } = useLoaderQueryBuilders()
  const { record: user } = useResourceQuery(usersEdit)

  return (
    <Section title={`Edit ${user.title}`}>
      <UserForm initialValues={user} />
    </Section>
  )
}

export default UsersEdit
