import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'BloodstockInventoryMonthly'

const recordNameSingular = 'bloodstockInventoryMonthly'
const recordNamePlural = 'bloodstockInventoryMonthlies'
const i18nKey = 'bloodstockInventoryMonthly'

const primaryKey = 'bloodstockInventoryMonthlyId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'bloodstockInventoryMonthlies'

const pageHelperKey = 'bloodstockInventoryMonthlies'

const resourcePathName = 'bloodstock_inventory_monthly'

const attributes: Record<string, AttributeSpec> = {
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'decimal',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'decimal',
  },
  horsesTotal: {
    kind: 'column',
    name: 'horsesTotal',
    type: 'integer',
  },
  horsesAcquired: {
    kind: 'column',
    name: 'horsesAcquired',
    type: 'integer',
  },
  horsesDeparted: {
    kind: 'column',
    name: 'horsesDeparted',
    type: 'integer',
  },
  horsesRetained: {
    kind: 'column',
    name: 'horsesRetained',
    type: 'integer',
  },
  horsesMonthStart: {
    kind: 'column',
    name: 'horsesMonthStart',
    type: 'integer',
  },
  horsesMonthEnd: {
    kind: 'column',
    name: 'horsesMonthEnd',
    type: 'integer',
  },
  wholeHorseValueMonthStart: {
    kind: 'column',
    name: 'wholeHorseValueMonthStart',
    type: 'decimal',
  },
  wholeHorseValueMonthEnd: {
    kind: 'column',
    name: 'wholeHorseValueMonthEnd',
    type: 'decimal',
  },
  ourValueMonthStart: {
    kind: 'column',
    name: 'ourValueMonthStart',
    type: 'decimal',
  },
  ourValueMonthEnd: {
    kind: 'column',
    name: 'ourValueMonthEnd',
    type: 'decimal',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
