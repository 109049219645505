import { TableHeader, TableRow, TableHead } from '@/components/ui/table'
import { cn } from '@/utils'
import { flexRender } from '@tanstack/react-table'

export const DataTableHeader = ({ table }) => {
  const cellClassName = (isPinned) => cn('sticky top-0 z-[3] bg-white', isPinned && 'z-[4]')

  const cellStyle = (column) => {
    if (column.getIsPinned())
      return {
        left: `${column.getStart('left')}px`,
        minWidth: `${column.getSize()}px`,
        maxWidth: `${column.getSize()}px`,
      }

    if (column.columnDef.meta.fixedSize)
      return {
        minWidth: `${column.getSize()}px`,
        maxWidth: `${column.getSize()}px`,
      }

    return {}
  }

  return (
    <TableHeader>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className="hover:bg-inherit">
          {headerGroup.headers.map((header) => (
            <TableHead
              key={header.id}
              colSpan={header.colSpan}
              className={cellClassName(header.column.getIsPinned())}
              style={cellStyle(header.column)}
            >
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext())}
            </TableHead>
          ))}
        </TableRow>
      ))}
    </TableHeader>
  )
}
