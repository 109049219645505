import { defineTable } from '@/components/data-table/builder'
import {
  booleanFormatter,
  currencyFormatter,
  dateFormatter,
  iconFormatter,
} from '@/components/data-table/formatters'

import { SaleRecordResource } from './resource'

export const saleRecordTable = defineTable(SaleRecordResource, {}, (tbl) => ({
  columns: [
    // tbl.col.id(),

    // tbl.col.saleId(),
    tbl.col.book(),
    tbl.col.hip(),
    // tbl.col.session(),
    // tbl.col.sessionDate({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
    tbl.col.barn(),
    tbl.col.horseId(),

    tbl.col.firstFoal({ cell: booleanFormatter() }),
    tbl.col.secondFoal({ cell: booleanFormatter() }),
    tbl.col.pregnant({ cell: booleanFormatter() }),

    tbl.col.coveringSire(),
    tbl.col.coverDate({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
    tbl.col.breedersCupElig({ cell: booleanFormatter() }),

    tbl.col.consignor(),
    tbl.col.buyer(),

    tbl.col.rna({ cell: booleanFormatter() }),
    tbl.col.out({ cell: booleanFormatter() }),
    // tbl.col.sold({ cell: booleanFormatter() }),

    tbl.col.salePrice({ cell: currencyFormatter() }),
    tbl.col.workTime(),
    tbl.col.workSet(),
    tbl.col.workDay(),
    tbl.col.ps(),
    tbl.col.propertyLine(),
    tbl.col.grade(),
    tbl.col.soldAsCode({ label: 'Sold As' }),

    tbl.col.comment({
      cell: iconFormatter({
        iconKey: 'Clipboard',
        tooltipAccessor: tbl.col.comment.id(),
      }),
    }),

    tbl.col.createdAt({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
    tbl.col.updatedAt({ cell: dateFormatter({ format: 'MMMM do yyyy' }) }),
  ],
}))
