import {
  BinaryTree,
  BinaryTree2,
  Bone,
  Certificate,
  ChartBar,
  CheckupList,
  CirclesRelation,
  ClipboardText,
  Coins,
  Dna,
  DollarSign,
  FileText,
  LaurelWreath1,
  Message,
  PhotoVideo,
  Plus,
  ReportMedical,
  Scale,
  Stopwatch,
  Tag,
  Wind,
} from '@/components/icons'
import { Link } from '@/components/link'
import { stallionPanelConfig } from '@/components/panel/config/stallion'
import { PanelConfig, PanelGroupConfig } from '@/components/panel/panel-types'
import {
  appraisalsRoutes,
  conformationNotesRoutes,
  documentsRoutes,
  horsesRoutes,
  imagesRoutes,
  organizationHorsesRoutes,
  racehorseCommentsRoutes,
  trainingReportsRoutes,
} from '@/routes/paths'

// General
export function generalPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'General',
    items: [
      {
        label: 'Appraisals',
        route: appraisalsRoutes.index,
        icon: <DollarSign />,
        action: (
          <Link
            route={appraisalsRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
      {
        label: 'Photos & Videos',
        route: imagesRoutes.index,
        icon: <PhotoVideo />,
        action: (
          <Link route={imagesRoutes.new} iconLeft={<Plus className="text-black/50" />} noChevron />
        ),
      },
      {
        label: 'Conformation Notes',
        route: conformationNotesRoutes.index,
        icon: <ClipboardText />,
        action: (
          <Link
            route={conformationNotesRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
      {
        label: 'Sale Results',
        route: horsesRoutes.show.sales,
        icon: <Tag />,
      },
      {
        label: 'Siblings & Pedigree',
        icon: <BinaryTree />,
      },
    ],
  }
}

// Analytics
export function analyticsPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'Analytics',
    items: [
      {
        label: 'ESN',
        icon: <ChartBar />,
      },
      {
        label: 'Genetics',
        icon: <Dna />,
      },
    ],
  }
}

// Management
export function managementPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'Management',
    items: [
      {
        label: 'Ownership Details',
        route: organizationHorsesRoutes.index,
        icon: <Certificate />,
        action: (
          <Link
            route={organizationHorsesRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
      {
        label: 'Documents',
        route: documentsRoutes.index,
        icon: <FileText />,
        action: (
          <Link
            route={documentsRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
      {
        label: 'Financials',
        icon: <Coins />,
      },
      {
        label: 'Crop Reports',
        icon: <CheckupList />,
      },
    ],
  }
}

// Health
export function healthPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'Health',
    items: [
      {
        label: 'Vet Reports',
        icon: <ReportMedical />,
      },
      {
        label: 'Height & Weight',
        icon: <Scale />,
      },
      {
        label: 'Scopes',
        icon: <Wind />,
      },
      {
        label: 'X-Rays',
        icon: <Bone />,
      },
    ],
  }
}

// Racing
export function racingPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'Racing',
    items: [
      {
        label: 'Race Results',
        icon: <LaurelWreath1 />,
        route: horsesRoutes.show.racing,
      },
      {
        label: 'Workouts',
        icon: <Stopwatch />,
      },
      {
        label: 'Racehorse Comments',
        route: racehorseCommentsRoutes.index,
        icon: <Message />,
        action: (
          <Link
            route={racehorseCommentsRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
      {
        label: 'Training Reports',
        route: trainingReportsRoutes.index,
        icon: <CheckupList />,
        action: (
          <Link
            route={trainingReportsRoutes.new}
            iconLeft={<Plus className="text-black/50" />}
            noChevron
          />
        ),
      },
    ],
  }
}

// Breeding (Broodmare)
export function breedingPanelGroupConfig(params, data): PanelGroupConfig {
  const horseId = params?.horseId ?? data?.horseId
  return {
    label: 'Breeding',
    items: [
      {
        label: 'Matings',
        icon: <CirclesRelation />,
        route: horsesRoutes.show.matings,
      },
      {
        label: 'Progeny',
        icon: <BinaryTree2 />,
        route: horsesRoutes.show.progeny,
      },
    ],
  }
}

// Stallion
export function stallionPanelGroupConfig(params, data): PanelGroupConfig {
  const sections = stallionPanelConfig(params, data) ?? []
  return sections[0] || { label: 'Stallion', items: [] }
}

// Foal / Yearling
export function foalYearlingPanelConfig(params, data): PanelConfig {
  return [
    generalPanelGroupConfig(params, data),
    // analyticsPanelGroupConfig(params, data),
    managementPanelGroupConfig(params, data),
    healthPanelGroupConfig(params, data),
  ]
}

// Racehorse
export function racehorsePanelConfig(params, data): PanelConfig {
  return [
    generalPanelGroupConfig(params, data),
    // analyticsPanelGroupConfig(params, data),
    racingPanelGroupConfig(params, data),
    managementPanelGroupConfig(params, data),
    healthPanelGroupConfig(params, data),
  ]
}

// Broodmare
export function broodmarePanelConfig(params, data): PanelConfig {
  return [
    generalPanelGroupConfig(params, data),
    breedingPanelGroupConfig(params, data),
    // analyticsPanelGroupConfig(params, data),
    managementPanelGroupConfig(params, data),
    racingPanelGroupConfig(params, data),
    healthPanelGroupConfig(params, data),
  ]
}

// Stallion
export function bloodstockStallionPanelConfig(params, data): PanelConfig {
  return [
    stallionPanelGroupConfig(params, data),
    // analyticsPanelGroupConfig(params, data),
    racingPanelGroupConfig(params, data),
    managementPanelGroupConfig(params, data),
    generalPanelGroupConfig(params, data),
    healthPanelGroupConfig(params, data),
  ]
}
