import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { OrganizationHorseCard, OrganizationHorseResource } from '@/resources/organization_horse'
import { organizationHorsesRoutes } from '@/routes'

const OrganizationHorsesIndex = () => {
  const queries = useLoaderQueryBuilders()
  const organizationHorses = useResourceQuery(queries.organizationHorsesIndex)

  if (organizationHorses.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No organization horses yet.</Text>
        <div>
          <Action route={organizationHorsesRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">Organization Horses</Text>
        <Action route={organizationHorsesRoutes.new} name="new" variant="primary" />
      </div>
      <OrganizationHorseCard
        query={OrganizationHorseResource.query.show.withOptions({
          id: organizationHorses.records[0].id,
        })}
      />
      <Section className="mt-16" flex>
        {organizationHorses.records.slice(1).map((organizationHorse) => (
          <OrganizationHorseCard
            key={organizationHorse.id}
            query={OrganizationHorseResource.query.show.withOptions({ id: organizationHorse.id })}
          />
        ))}
      </Section>
    </>
  )
}

export default OrganizationHorsesIndex
