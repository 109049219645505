import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BrisnetSearchHorse } from '@/types'

const name = 'BrisnetSearchHorse'

const recordNameSingular = 'brisnetSearchHorse'
const recordNamePlural = 'brisnetSearchHorses'
const i18nKey = 'brisnet::SearchHorse'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'brisnetSearchHorses'

const pageHelperKey = 'brisnetSearchHorses'

const resourcePathName = 'brisnet_search_horse'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  rawHorseString: {
    kind: 'column',
    name: 'rawHorseString',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<BrisnetSearchHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
