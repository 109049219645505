import { QueryClient } from '@tanstack/react-query'

export const queryClientOptions = {
  defaultOptions: {
    queries: {
      staleTime: 60000,
      retry: false,
      suspense: true,
      throwOnError: (error) => error.response?.status >= 500,
    },
    mutations: {
      throwOnError: (error) => error.response?.status >= 500,
    },
  },
}

export const queryClient = new QueryClient(queryClientOptions)
