import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { WeatherbysRaceResult } from '@/types'

const name = 'WeatherbysRaceResult'

const recordNameSingular = 'weatherbysRaceResult'
const recordNamePlural = 'weatherbysRaceResults'
const i18nKey = 'weatherbys::RaceResult'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'weatherbysRaceResults'

const pageHelperKey = 'weatherbysRaceResults'

const resourcePathName = 'weatherbys_race_result'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  course: {
    kind: 'column',
    name: 'course',
    type: 'string',
  },
  courseAbbreviation: {
    kind: 'column',
    name: 'courseAbbreviation',
    type: 'string',
  },
  courseId: {
    kind: 'column',
    name: 'courseId',
    type: 'integer',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  raceId: {
    kind: 'column',
    name: 'raceId',
    type: 'integer',
  },
  raceNo: {
    kind: 'column',
    name: 'raceNo',
    type: 'integer',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'date',
  },
  raceTime: {
    kind: 'column',
    name: 'raceTime',
    type: 'time',
  },
  raceName: {
    kind: 'column',
    name: 'raceName',
    type: 'string',
  },
  raceNameShort: {
    kind: 'column',
    name: 'raceNameShort',
    type: 'string',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'string',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'integer',
  },
  raceValueInGbp: {
    kind: 'column',
    name: 'raceValueInGbp',
    type: 'integer',
  },
  localRaceValue: {
    kind: 'column',
    name: 'localRaceValue',
    type: 'integer',
  },
  localRaceValueCurrency: {
    kind: 'column',
    name: 'localRaceValueCurrency',
    type: 'string',
  },
  handicapType: {
    kind: 'column',
    name: 'handicapType',
    type: 'string',
  },
  raceGroup: {
    kind: 'column',
    name: 'raceGroup',
    type: 'string',
  },
  awt: {
    kind: 'column',
    name: 'awt',
    type: 'string',
  },
  going: {
    kind: 'column',
    name: 'going',
    type: 'string',
  },
  ageMin: {
    kind: 'column',
    name: 'ageMin',
    type: 'string',
  },
  ageMax: {
    kind: 'column',
    name: 'ageMax',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  noOfRunners: {
    kind: 'column',
    name: 'noOfRunners',
    type: 'integer',
  },
  winningTime: {
    kind: 'column',
    name: 'winningTime',
    type: 'string',
  },
  resultType: {
    kind: 'column',
    name: 'resultType',
    type: 'string',
  },
  flatBtRace: {
    kind: 'column',
    name: 'flatBtRace',
    type: 'string',
  },
  jumpBtRace: {
    kind: 'column',
    name: 'jumpBtRace',
    type: 'string',
  },
  distanceInMeters: {
    kind: 'column',
    name: 'distanceInMeters',
    type: 'integer',
  },
  sellClaimAuction: {
    kind: 'column',
    name: 'sellClaimAuction',
    type: 'string',
  },
  maidenNoviceType: {
    kind: 'column',
    name: 'maidenNoviceType',
    type: 'string',
  },
  animalId: {
    kind: 'column',
    name: 'animalId',
    type: 'integer',
  },
  animalName: {
    kind: 'column',
    name: 'animalName',
    type: 'citext',
  },
  breedType: {
    kind: 'column',
    name: 'breedType',
    type: 'string',
  },
  sire: {
    kind: 'column',
    name: 'sire',
    type: 'citext',
  },
  dam: {
    kind: 'column',
    name: 'dam',
    type: 'citext',
  },
  damSire: {
    kind: 'column',
    name: 'damSire',
    type: 'citext',
  },
  finishPosition: {
    kind: 'column',
    name: 'finishPosition',
    type: 'string',
  },
  prizeMoneyInGbp: {
    kind: 'column',
    name: 'prizeMoneyInGbp',
    type: 'integer',
  },
  localPrizeMoney: {
    kind: 'column',
    name: 'localPrizeMoney',
    type: 'integer',
  },
  localPrizeMoneyCurrency: {
    kind: 'column',
    name: 'localPrizeMoneyCurrency',
    type: 'string',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  weight: {
    kind: 'column',
    name: 'weight',
    type: 'string',
  },
  owner: {
    kind: 'column',
    name: 'owner',
    type: 'string',
  },
  jockey: {
    kind: 'column',
    name: 'jockey',
    type: 'string',
  },
  trainer: {
    kind: 'column',
    name: 'trainer',
    type: 'string',
  },
  breeder: {
    kind: 'column',
    name: 'breeder',
    type: 'string',
  },
  drawNo: {
    kind: 'column',
    name: 'drawNo',
    type: 'integer',
  },
  finishingDistances: {
    kind: 'column',
    name: 'finishingDistances',
    type: 'string',
  },
  birthDate: {
    kind: 'column',
    name: 'birthDate',
    type: 'date',
  },
  foalYear: {
    kind: 'column',
    name: 'foalYear',
    type: 'integer',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  colour: {
    kind: 'column',
    name: 'colour',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<WeatherbysRaceResult> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
