import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Notification } from '@/types'

const name = 'Notification'

const recordNameSingular = 'notification'
const recordNamePlural = 'notifications'
const i18nKey = 'notification'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'notifications'

const pageHelperKey = 'notifications'

const resourcePathName = 'notification'

const attributes: Record<string, AttributeSpec> = {
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  notificableType: {
    kind: 'column',
    name: 'notificableType',
    type: 'string',
  },
  notificableId: {
    kind: 'column',
    name: 'notificableId',
    type: 'integer',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'unread',
        },
        {
          value: 1,
          key: 'read',
        },
      ],
    },
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<Notification> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
