import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFRace } from '@/types'

const name = 'DRFRace'

const recordNameSingular = 'drfRace'
const recordNamePlural = 'drfRaces'
const i18nKey = 'drf::Race'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfRaces'

const pageHelperKey = 'drfRaces'

const resourcePathName = 'drf_race'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'string',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'string',
  },
  raceDatePrecision: {
    kind: 'column',
    name: 'raceDatePrecision',
    type: 'string',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  dayEvening: {
    kind: 'column',
    name: 'dayEvening',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  conditionsOfRace: {
    kind: 'column',
    name: 'conditionsOfRace',
    type: 'string',
  },
  officialIndicator: {
    kind: 'column',
    name: 'officialIndicator',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'string',
  },
  ageRestriction: {
    kind: 'column',
    name: 'ageRestriction',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  stakesIndicator: {
    kind: 'column',
    name: 'stakesIndicator',
    type: 'string',
  },
  distanceId: {
    kind: 'column',
    name: 'distanceId',
    type: 'integer',
  },
  distanceUnit: {
    kind: 'column',
    name: 'distanceUnit',
    type: 'string',
  },
  aboutDistanceIndicator: {
    kind: 'column',
    name: 'aboutDistanceIndicator',
    type: 'string',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  courseType: {
    kind: 'column',
    name: 'courseType',
    type: 'string',
  },
  scheduledSurface: {
    kind: 'column',
    name: 'scheduledSurface',
    type: 'string',
  },
  purseForeign: {
    kind: 'column',
    name: 'purseForeign',
    type: 'float',
  },
  purseUsa: {
    kind: 'column',
    name: 'purseUsa',
    type: 'float',
  },
  revertsMoney: {
    kind: 'column',
    name: 'revertsMoney',
    type: 'float',
  },
  availableMoney: {
    kind: 'column',
    name: 'availableMoney',
    type: 'float',
  },
  paidToOthers: {
    kind: 'column',
    name: 'paidToOthers',
    type: 'float',
  },
  guaranteedIndicator: {
    kind: 'column',
    name: 'guaranteedIndicator',
    type: 'string',
  },
  addedMoneyForeign: {
    kind: 'column',
    name: 'addedMoneyForeign',
    type: 'float',
  },
  addedMoneyUsa: {
    kind: 'column',
    name: 'addedMoneyUsa',
    type: 'float',
  },
  wpsPool: {
    kind: 'column',
    name: 'wpsPool',
    type: 'float',
  },
  wagerTypes: {
    kind: 'column',
    name: 'wagerTypes',
    type: 'float',
  },
  wagerTypesCancelled: {
    kind: 'column',
    name: 'wagerTypesCancelled',
    type: 'float',
  },
  postTime: {
    kind: 'column',
    name: 'postTime',
    type: 'string',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  weather: {
    kind: 'column',
    name: 'weather',
    type: 'string',
  },
  fraction1: {
    kind: 'column',
    name: 'fraction1',
    type: 'integer',
  },
  fraction2: {
    kind: 'column',
    name: 'fraction2',
    type: 'integer',
  },
  fraction3: {
    kind: 'column',
    name: 'fraction3',
    type: 'integer',
  },
  fraction4: {
    kind: 'column',
    name: 'fraction4',
    type: 'integer',
  },
  fraction5: {
    kind: 'column',
    name: 'fraction5',
    type: 'integer',
  },
  winningTime: {
    kind: 'column',
    name: 'winningTime',
    type: 'integer',
  },
  breedType: {
    kind: 'column',
    name: 'breedType',
    type: 'string',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'string',
  },
  startDescription: {
    kind: 'column',
    name: 'startDescription',
    type: 'string',
  },
  minimumClaimPrice: {
    kind: 'column',
    name: 'minimumClaimPrice',
    type: 'float',
  },
  maximumClaimPrice: {
    kind: 'column',
    name: 'maximumClaimPrice',
    type: 'float',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'string',
  },
  numberOfRunners: {
    kind: 'column',
    name: 'numberOfRunners',
    type: 'integer',
  },
  raceClassStakeRule: {
    kind: 'column',
    name: 'raceClassStakeRule',
    type: 'integer',
  },
  raceClassBlktypeRule: {
    kind: 'column',
    name: 'raceClassBlktypeRule',
    type: 'integer',
  },
  gradedStakeId: {
    kind: 'column',
    name: 'gradedStakeId',
    type: 'integer',
  },
  abbreviatedName: {
    kind: 'column',
    name: 'abbreviatedName',
    type: 'string',
  },
  raceName: {
    kind: 'column',
    name: 'raceName',
    type: 'string',
  },
  programSelections: {
    kind: 'column',
    name: 'programSelections',
    type: 'string',
  },
  temporaryRailDistance: {
    kind: 'column',
    name: 'temporaryRailDistance',
    type: 'integer',
  },
  chuteStart: {
    kind: 'column',
    name: 'chuteStart',
    type: 'string',
  },
  trackSealedIndicator: {
    kind: 'column',
    name: 'trackSealedIndicator',
    type: 'string',
  },
  gradeException: {
    kind: 'column',
    name: 'gradeException',
    type: 'string',
  },
  runUpDistance: {
    kind: 'column',
    name: 'runUpDistance',
    type: 'float',
  },
  raceQualityType: {
    kind: 'column',
    name: 'raceQualityType',
    type: 'string',
  },
  minusPlacePool: {
    kind: 'column',
    name: 'minusPlacePool',
    type: 'float',
  },
  minusShowPool: {
    kind: 'column',
    name: 'minusShowPool',
    type: 'float',
  },
  call1: {
    kind: 'column',
    name: 'call1',
    type: 'string',
  },
  call2: {
    kind: 'column',
    name: 'call2',
    type: 'string',
  },
  call3: {
    kind: 'column',
    name: 'call3',
    type: 'string',
  },
  call4: {
    kind: 'column',
    name: 'call4',
    type: 'string',
  },
  stretch: {
    kind: 'column',
    name: 'stretch',
    type: 'string',
  },
  finish: {
    kind: 'column',
    name: 'finish',
    type: 'string',
  },
  restrictionType: {
    kind: 'column',
    name: 'restrictionType',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFRace> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
