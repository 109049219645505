import { cleanObject, isEmptyObject } from '@/utils'

import { type FilterPrimitive, type OperatorName } from './operators'
import { filterToParams, sortToParams, paginationToParams, type RansackQueryParams } from './params'
import { Sort, SortDirection } from './sort'

export interface FilterDef {
  field: string
  opName: OperatorName
  value?: FilterPrimitive
}

export type SortDef = Sort

export interface PaginationDef {
  page?: number | string
  pageSize?: number
}

export interface QueryDef {
  filters?: FilterDef[]
  sorts?: SortDef[]
  pagination?: PaginationDef
}

export const mergeQueryDef = (baseDef: QueryDef = {}, newDef: QueryDef = {}) => {
  const {
    filters: baseFilters = [],
    sorts: baseSorts = [],
    pagination: basePagination = {},
  } = baseDef

  const { filters: newFilters = [], sorts: newSorts = [], pagination: newPagination = {} } = newDef

  // Need to keep sorts unique by field
  const sortMap = new Map([...baseSorts, ...newSorts].map((s) => [s.field, s]))

  return {
    filters: [...baseFilters, ...newFilters],
    sorts: [...sortMap.values()],
    pagination: {
      page: newPagination.page ?? basePagination.page,
      pageSize: newPagination.pageSize ?? basePagination.pageSize,
    },
  }
}

export const queryDefToParams = (query: QueryDef = {}): RansackQueryParams => {
  const { filters = [], sorts = [], pagination = {} } = query

  const filterParams = filters.reduce((acc, f) => ({ ...acc, ...filterToParams(f) }), {})

  const qParams = cleanObject({
    ...filterParams,
    sorts: sorts.map((s) => sortToParams(s)),
  })

  return {
    q: isEmptyObject(qParams) ? undefined : qParams,
    ...paginationToParams(pagination),
  }
}

export interface QueryDefBuilder {
  (query?: QueryDef): QueryDef

  query: QueryDef

  filter: (field: string, value: FilterPrimitive, opName?: OperatorName) => QueryDefBuilder
  scope: (scope: string, value?: FilterPrimitive) => QueryDefBuilder
  sort: (field: string, dir: SortDirection) => QueryDefBuilder
  page: (pageNum: number) => QueryDefBuilder
  pageSize: (pageSize: number) => QueryDefBuilder

  setFilters: (filters: FilterDef[]) => QueryDefBuilder
  setSorts: (sorts: SortDef[]) => QueryDefBuilder
  setPagination: (pagination: PaginationDef) => QueryDefBuilder

  toParams: (addlOptions?: QueryDef) => RansackQueryParams
}

export type QueryDefBuilderFnsOnly = Omit<QueryDefBuilder, 'query' | 'toParams'>

const createQueryDefBuilder = (baseQuery: QueryDef = {}): QueryDefBuilder => {
  const builder = (query: QueryDef = {}) => mergeQueryDef(baseQuery, query)

  builder.filter = (field: string, value: FilterPrimitive, opName = 'eq') =>
    createQueryDefBuilder(mergeQueryDef(baseQuery, { filters: [{ field, opName, value }] }))

  builder.scope = (scope: string, value: FilterPrimitive = true) =>
    builder.filter(scope, value, 'scope')

  builder.sort = (field: string, direction: SortDirection = 'asc') =>
    createQueryDefBuilder(mergeQueryDef(baseQuery, { sorts: [{ field, direction }] }))

  builder.page = (page: number) =>
    createQueryDefBuilder(mergeQueryDef(baseQuery, { pagination: { page } }))

  builder.pageSize = (pageSize: number) =>
    createQueryDefBuilder(mergeQueryDef(baseQuery, { pagination: { pageSize } }))

  builder.setFilters = (filters: FilterDef[]) => createQueryDefBuilder({ ...baseQuery, filters })

  builder.setSorts = (sorts: SortDef[]) => createQueryDefBuilder({ ...baseQuery, sorts })

  builder.setPagination = (pagination: PaginationDef) =>
    createQueryDefBuilder({ ...baseQuery, pagination })

  builder.query = baseQuery

  builder.toParams = (addlQuery: QueryDef = {}) =>
    queryDefToParams(mergeQueryDef(baseQuery, addlQuery))

  return builder
}

export { createQueryDefBuilder }
