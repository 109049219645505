import * as React from 'react'

import { cva, cn, type VariantProps } from '@/utils'

import { Text } from './text'

export const cardVariants = cva({
  base: 'rounded-lg border bg-card text-card-foreground shadow-sm p-3 flex flex-col gap-3',
  variants: {
    variant: {
      default: '',
      outline: 'border-2 border-white bg-transparent',
      ghost: 'border-none shadow-none bg-transparent',
      inline: 'p-0 bg-transparent border-none shadow-none rounded-none',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export type CardProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardVariants>

const Card = React.forwardRef<HTMLDivElement, CardProps>((props, ref) => (
  <div ref={ref} {...props} className={cardVariants({ ...props })} />
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <header
      ref={ref}
      className={cn('grid grid-cols-header p-0', 'items-center gap-x-4 gap-y-0.5', className)}
      {...props}
    />
  ),
)
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return (
      <Text
        ref={ref}
        as="h5"
        className={cn('col-start-1 justify-self-start', 'leading-none tracking-tight', className)}
        {...props}
      />
    )
  },
)
CardTitle.displayName = 'CardTitle'

const CardLabels = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('justify-self-starti col-start-2 flex flex-row gap-2', className)}
      {...props}
    />
  ),
)
CardLabels.displayName = 'CardLabels'

const CardActions = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('col-start-3 row-span-2 justify-self-end', 'flex flex-row gap-2', className)}
      {...props}
    />
  ),
)
CardActions.displayName = 'CardActions'

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(
  ({ className, ...props }, ref) =>
    React.Children.count(props.children) > 0 && (
      <Text
        ref={ref}
        as="p"
        muted
        size="sm"
        className={cn('col-span-2 row-start-2', className)}
        {...props}
      />
    ),
)
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('p-0', className)} {...props} />,
)
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center p-6 pt-0', className)} {...props} />
  ),
)
CardFooter.displayName = 'CardFooter'

export {
  Card,
  CardActions,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardLabels,
  CardTitle,
}
