/*
 * TODO Implement Index Page for covers
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function CoversIndex() {
  return (
    <>
      <PageHeader title="Covers" />
    </>
  )
}

export default CoversIndex
