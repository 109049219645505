import { PageHeader } from '@/components/header'
import { CashFlowTable } from '@/modules/finance/cash-flow-table'
import { FinanceMonthlyEntryResource } from '@/resources/finance/monthly_entry'

const FinanceIndex = () => {
  const query = FinanceMonthlyEntryResource.query.index.filter('year', 2024)

  return (
    <>
      <PageHeader title="Finance" />
      <CashFlowTable query={query} />
    </>
  )
}

export default FinanceIndex
