import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import Icon from '@/components/icon'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { BroodmareProspectSchema } from '@/schemas/broodmare_prospect'
import { BroodmareProspect } from '@/types'

import { HorseSuggestion } from '../horse/horse-suggestion'
import { BroodmareProspectResource } from './resource'

type BroodmareProspectFormProps = Omit<UseFormProps<BroodmareProspect>, 'resource'>

const BroodmareProspectForm = ({
  zodSchema = BroodmareProspectSchema,
  initialValues,
  ...props
}: BroodmareProspectFormProps) => {
  const formConfig = useForm<BroodmareProspect>({
    resource: BroodmareProspectResource,
    zodSchema,
    initialValues,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardHeader>
          <CardTitle>Contract Details</CardTitle>
        </CardHeader>
        <CardContent>
          <Field
            name="horse"
            renderItem={({ suggestion, isSelected }) => (
              <div className="flex w-full gap-0.5">
                <div className="w-4 py-1">
                  {isSelected && <Icon name="Check" className="self-center" />}
                </div>
                <HorseSuggestion horse={suggestion} hideImage={true} hideType={true} />
              </div>
            )}
          />
          <Field name="rating" />
          <Field name="comment" />
          <Field name="removed" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { BroodmareProspectForm }
