import { createBrowserRouter, matchRoutes, type Location } from 'react-router-dom'

import { queryClient } from '@/libs/query/client'
import { routes } from '@/routes/routes'

const basePageModules = import.meta.glob('@/pages/**/*.{tsx,jsx}', {
  import: 'default',
  eager: true,
})

const pageModules = Object.keys(basePageModules).reduce((acc, page) => {
  // Remove everything up to and including 'pages/' as well as the file
  // extension
  const name = page.substring(page.indexOf('pages/') + 6).split('.')[0]

  return {
    ...acc,
    [name]: basePageModules[page],
  }
}, {})

export const builtRoutes = routes.build(queryClient, {}, pageModules)

export const findLocationRoutes = (location: Partial<Location> | string) => {
  const matches = matchRoutes(builtRoutes, location)

  return matches?.reverse() ?? []
}

export const findLocationRoute = (location: Partial<Location> | string) => {
  const routes = findLocationRoutes(location)

  return routes[0]
}

export const router = createBrowserRouter(builtRoutes, {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
})
