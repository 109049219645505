import React from 'react'

import { Action, Actions } from '@/components/actions'
import { defineTable } from '@/components/data-table/builder'
import { TableBuilderSpec } from '@/components/data-table/builder/types'
import { booleanFormatter } from '@/components/data-table/formatters'
import Icon from '@/components/icon' // Assuming the resource exists
import { dbtHorseTable } from '@/resources/dbt/horse'
import { matingsRoutes } from '@/routes'
import { Mating } from '@/types'

import { HypotheticalFiveCrossAction } from '../horse'
import { MatingResource } from './resource'

export const matingTable = defineTable(
  MatingResource,
  {
    mare: dbtHorseTable,
    stallion: dbtHorseTable,
  },
  (tbl): TableBuilderSpec<Mating> => {
    return {
      searchColumn: tbl.col.mare.name.id(),
      initialSorting: [{ id: 'year', desc: false }],
      initialPinnedColumns: {
        left: [tbl.col.stallion.name.id(), tbl.col.mare.name.id()],
      },
      columns: [
        tbl.col.id({
          label: 'ID',
          enableColumnFilter: false,
          size: 100,
        }),

        tbl.col.year({
          enableHiding: false,
          enableColumnFilter: false,
          filter: { key: 'value' },
        }),

        tbl.col.mare.name({
          label: 'Mare',
          enableHiding: false,
          isVisible: false,
          enableColumnFilter: true,
        }),
        tbl.col.mare.nameOverSireWithDam({ size: 250 }),

        tbl.col.stallion.name({
          label: 'Stallion',
          filter: { key: 'multiSelect', initialChip: true },
          enableHiding: false,
          isVisible: false,
          enableColumnFilter: true,
        }),
        tbl.col.stallion.nameOverSireWithDam({ size: 250 }),

        tbl.col.mare.nameAgeSex({
          label: 'Mare',
          enableHiding: false,
          isVisible: false,
        }),

        tbl.col.stallion.nameAgeSex({
          label: 'Stallion',
          enableHiding: false,
          isVisible: false,
        }),

        tbl.col.final({
          label: 'Final',
          enableColumnFilter: true,
          cell: booleanFormatter(),
        }),

        tbl.col.notes({
          label: 'Notes',
          enableHiding: false,
          enableColumnFilter: false,
          size: 200,
        }),
        tbl.col.id({
          enableColumnFilter: false,
          size: 100,
          header: () => null,
          cell: ({ row }) => {
            return (
              <Actions numToShow={2}>
                <Action
                  name="edit"
                  route={matingsRoutes.edit.withOptions({
                    id: row.original.id,
                  })}
                />
                <HypotheticalFiveCrossAction
                  name="5-cross"
                  label="5-Cross"
                  damId={row.original.mareId}
                  sireId={row.original.stallionId}
                />
                <Action
                  label="View"
                  disabled
                  iconLeft={<Icon name="Eye" />}
                  route={matingsRoutes.show.withOptions({
                    id: row.original.id,
                  })}
                />
                <Action
                  name="destroy"
                  disabled
                  route={matingsRoutes.destroy.withOptions({
                    id: row.original.id,
                  })}
                />
              </Actions>
            )
          },
        }),
      ],
    }
  },
)
