// This file is automatically generated. Do not edit.

import { defineRoute } from '@/libs/routes/route'

const indexRoutes = defineRoute([], {}, {})

const accountsRoutes = {
  index: defineRoute(['accounts'], {}, {}),

  new: defineRoute(['accounts', 'new'], {}, {}),

  show: defineRoute(['accounts', { name: 'accountId', attributeId: 'Account:id' }], {}, {}),

  edit: defineRoute(['accounts', { name: 'accountId', attributeId: 'Account:id' }, 'edit'], {}, {}),

  destroy: defineRoute(
    ['accounts', { name: 'accountId', attributeId: 'Account:id' }, 'destroy'],
    {},
    {},
  ),
}

const appraisalsRoutes = {
  index: defineRoute(['appraisals'], {}, {}),

  new: defineRoute(['appraisals', 'new'], {}, {}),

  show: defineRoute(['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }], {}, {}),

  edit: defineRoute(
    ['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }, 'destroy'],
    {},
    {},
  ),
}

const bloodstockRoutes = {
  index: defineRoute(['bloodstock'], {}, {}),

  crops: defineRoute(['bloodstock', 'crops'], {}, {}),

  racehorses: defineRoute(['bloodstock', 'racehorses'], {}, {}),

  broodmares: defineRoute(['bloodstock', 'broodmares'], {}, {}),

  stallions: defineRoute(['bloodstock', 'stallions'], {}, {}),
}

const booksRoutes = {
  index: defineRoute(['books'], {}, {}),

  show: defineRoute(['books', { name: 'bookYear', attributeId: 'Season:year' }], {}, {}),

  targets: defineRoute(
    ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'targets'],
    {},
    {},
  ),

  seasons: defineRoute(
    ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'seasons'],
    {},
    {
      new: defineRoute(
        ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'seasons', 'new'],
        {},
        {},
      ),

      show: defineRoute(
        [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
        ],
        {},
        {},
      ),

      edit: defineRoute(
        [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
          'edit',
        ],
        {},
        {},
      ),

      destroy: defineRoute(
        [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
          'destroy',
        ],
        {},
        {},
      ),

      horses: {
        new: defineRoute(
          ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'seasons', 'horses', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
          ],
          {},
          {},
        ),

        fiveCross: defineRoute(
          [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'five_cross',
          ],
          {},
          {},
        ),

        pedigree: defineRoute(
          [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'pedigree',
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'destroy',
          ],
          {},
          {},
        ),

        appraisals: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
              'destroy',
            ],
            {},
            {},
          ),
        },

        conformationNotes: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
              'destroy',
            ],
            {},
            {},
          ),
        },

        trainingReports: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
              'destroy',
            ],
            {},
            {},
          ),
        },

        organizationHorses: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
              'destroy',
            ],
            {},
            {},
          ),
        },

        studFees: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
              'destroy',
            ],
            {},
            {},
          ),
        },

        racehorseComments: {
          index: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
            ],
            {},
            {},
          ),

          new: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              'new',
            ],
            {},
            {},
          ),

          show: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
            ],
            {},
            {},
          ),

          edit: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
              'edit',
            ],
            {},
            {},
          ),

          destroy: defineRoute(
            [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
              'destroy',
            ],
            {},
            {},
          ),
        },
      },
    },
  ),
}

const broodmaresRoutes = {
  claiming: defineRoute(
    ['broodmares', 'claiming'],
    {},
    {
      broodmareProspects: {
        index: defineRoute(['broodmares', 'claiming', 'broodmare_prospects'], {}, {}),

        new: defineRoute(['broodmares', 'claiming', 'broodmare_prospects', 'new'], {}, {}),

        show: defineRoute(
          [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
            'destroy',
          ],
          {},
          {},
        ),
      },
    },
  ),
}

const broodmareProspectsRoutes = {
  index: defineRoute(['broodmare_prospects'], {}, {}),

  new: defineRoute(['broodmare_prospects', 'new'], {}, {}),

  show: defineRoute(
    ['broodmare_prospects', { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    [
      'broodmare_prospects',
      { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' },
      'edit',
    ],
    {},
    {},
  ),

  destroy: defineRoute(
    [
      'broodmare_prospects',
      { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' },
      'destroy',
    ],
    {},
    {},
  ),
}

const conformationNotesRoutes = {
  index: defineRoute(['conformation_notes'], {}, {}),

  new: defineRoute(['conformation_notes', 'new'], {}, {}),

  show: defineRoute(
    ['conformation_notes', { name: 'conformationNoteId', attributeId: 'ConformationNote:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    [
      'conformation_notes',
      { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
      'edit',
    ],
    {},
    {},
  ),

  destroy: defineRoute(
    [
      'conformation_notes',
      { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
      'destroy',
    ],
    {},
    {},
  ),
}

const coversRoutes = {
  index: defineRoute(['covers'], {}, {}),

  new: defineRoute(['covers', 'new'], {}, {}),

  show: defineRoute(['covers', { name: 'coverId', attributeId: 'Cover:id' }], {}, {}),

  edit: defineRoute(['covers', { name: 'coverId', attributeId: 'Cover:id' }, 'edit'], {}, {}),

  destroy: defineRoute(['covers', { name: 'coverId', attributeId: 'Cover:id' }, 'destroy'], {}, {}),
}

const dnaSetsRoutes = {
  index: defineRoute(['dna_sets'], {}, {}),

  show: defineRoute(['dna_sets', { name: 'dnaSetId', attributeId: 'DNASet:id' }], {}, {}),
}

const documentsRoutes = {
  index: defineRoute(['documents'], {}, {}),

  new: defineRoute(['documents', 'new'], {}, {}),

  show: defineRoute(['documents', { name: 'documentId', attributeId: 'Document:id' }], {}, {}),

  edit: defineRoute(
    ['documents', { name: 'documentId', attributeId: 'Document:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['documents', { name: 'documentId', attributeId: 'Document:id' }, 'destroy'],
    {},
    {},
  ),
}

const entitiesRoutes = {
  index: defineRoute(['entities'], {}, {}),

  new: defineRoute(['entities', 'new'], {}, {}),

  show: defineRoute(['entities', { name: 'entityId', attributeId: 'Entity:id' }], {}, {}),

  edit: defineRoute(['entities', { name: 'entityId', attributeId: 'Entity:id' }, 'edit'], {}, {}),

  destroy: defineRoute(
    ['entities', { name: 'entityId', attributeId: 'Entity:id' }, 'destroy'],
    {},
    {},
  ),
}

const financeRoutes = defineRoute(['finance'], {}, {})

const horsesRoutes = {
  new: defineRoute(['horses', 'new'], {}, {}),

  edit: defineRoute(['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'edit'], {}, {}),

  show: defineRoute(
    ['horses', { name: 'horseId', attributeId: 'Horse:id' }],
    {},
    {
      fiveCross: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'five_cross'],
        {},
        {},
      ),

      pedigree: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'pedigree'],
        {},
        {},
      ),

      sales: defineRoute(['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'sales'], {}, {}),

      racing: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racing'],
        {},
        {},
      ),

      progeny: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'progeny'],
        {},
        {},
      ),

      matings: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'matings'],
        {},
        {},
      ),

      edit: defineRoute(['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'edit'], {}, {}),

      destroy: defineRoute(
        ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'destroy'],
        {},
        {},
      ),

      appraisals: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      conformationNotes: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'conformation_notes'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'conformation_notes', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      trainingReports: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'training_reports'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'training_reports', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      organizationHorses: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'organization_horses'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'organization_horses', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'destroy',
          ],
          {},
          {},
        ),
      },

      studFees: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      racehorseComments: {
        index: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racehorse_comments'],
          {},
          {},
        ),

        new: defineRoute(
          ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racehorse_comments', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },
    },
  ),
}

const horseDetailsRoutes = {
  index: defineRoute(['horse_details'], {}, {}),

  show: defineRoute(
    [
      'horse_details',
      {
        name: 'horseDetailHorseDetailId',
        attributeId: 'HorseDetail:horseDetailId',
      },
    ],
    {},
    {},
  ),
}

const imagesRoutes = {
  index: defineRoute(['images'], {}, {}),

  new: defineRoute(['images', 'new'], {}, {}),

  show: defineRoute(['images', { name: 'imageId', attributeId: 'Image:id' }], {}, {}),

  edit: defineRoute(['images', { name: 'imageId', attributeId: 'Image:id' }, 'edit'], {}, {}),

  destroy: defineRoute(['images', { name: 'imageId', attributeId: 'Image:id' }, 'destroy'], {}, {}),
}

const matingsRoutes = {
  index: defineRoute(['matings'], {}, {}),

  new: defineRoute(['matings', 'new'], {}, {}),

  show: defineRoute(['matings', { name: 'matingId', attributeId: 'Mating:id' }], {}, {}),

  edit: defineRoute(['matings', { name: 'matingId', attributeId: 'Mating:id' }, 'edit'], {}, {}),

  destroy: defineRoute(
    ['matings', { name: 'matingId', attributeId: 'Mating:id' }, 'destroy'],
    {},
    {},
  ),
}

const operationsRoutes = {
  index: defineRoute(['operations'], {}, {}),
}

const organizationsRoutes = {
  index: defineRoute(['organizations'], {}, {}),

  new: defineRoute(['organizations', 'new'], {}, {}),

  show: defineRoute(
    ['organizations', { name: 'organizationId', attributeId: 'Organization:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    ['organizations', { name: 'organizationId', attributeId: 'Organization:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['organizations', { name: 'organizationId', attributeId: 'Organization:id' }, 'destroy'],
    {},
    {},
  ),
}

const organizationHorsesRoutes = {
  index: defineRoute(['organization_horses'], {}, {}),

  new: defineRoute(['organization_horses', 'new'], {}, {}),

  show: defineRoute(
    ['organization_horses', { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    [
      'organization_horses',
      { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' },
      'edit',
    ],
    {},
    {},
  ),

  destroy: defineRoute(
    [
      'organization_horses',
      { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' },
      'destroy',
    ],
    {},
    {},
  ),
}

const pregnancyChecksRoutes = {
  index: defineRoute(['pregnancy_checks'], {}, {}),

  new: defineRoute(['pregnancy_checks', 'new'], {}, {}),

  show: defineRoute(
    ['pregnancy_checks', { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    ['pregnancy_checks', { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['pregnancy_checks', { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' }, 'destroy'],
    {},
    {},
  ),
}

const racehorseCommentsRoutes = {
  index: defineRoute(['racehorse_comments'], {}, {}),

  new: defineRoute(['racehorse_comments', 'new'], {}, {}),

  show: defineRoute(
    ['racehorse_comments', { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    [
      'racehorse_comments',
      { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
      'edit',
    ],
    {},
    {},
  ),

  destroy: defineRoute(
    [
      'racehorse_comments',
      { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
      'destroy',
    ],
    {},
    {},
  ),
}

const salesRoutes = {
  index: defineRoute(['sales'], {}, {}),

  show: defineRoute(['sales', { name: 'saleId', attributeId: 'Sale:id' }], {}, {}),
}

const saleRecordsRoutes = {
  index: defineRoute(['sale_records'], {}, {}),

  show: defineRoute(
    ['sale_records', { name: 'saleRecordId', attributeId: 'SaleRecord:id' }],
    {},
    {},
  ),
}

const saleRecordDetailsRoutes = {
  index: defineRoute(['sale_record_details'], {}, {}),

  show: defineRoute(
    [
      'sale_record_details',
      {
        name: 'saleRecordDetailSaleRecordDetailId',
        attributeId: 'SaleRecordDetail:saleRecordDetailId',
      },
    ],
    {},
    {},
  ),
}

const seasonsRoutes = defineRoute(
  ['seasons'],
  {},
  {
    new: defineRoute(['seasons', 'new'], {}, {}),

    show: defineRoute(['seasons', { name: 'seasonId', attributeId: 'Season:id' }], {}, {}),

    edit: defineRoute(['seasons', { name: 'seasonId', attributeId: 'Season:id' }, 'edit'], {}, {}),

    destroy: defineRoute(
      ['seasons', { name: 'seasonId', attributeId: 'Season:id' }, 'destroy'],
      {},
      {},
    ),

    horses: {
      new: defineRoute(['seasons', 'horses', 'new'], {}, {}),

      show: defineRoute(
        ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }],
        {},
        {},
      ),

      fiveCross: defineRoute(
        ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'five_cross'],
        {},
        {},
      ),

      pedigree: defineRoute(
        ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'pedigree'],
        {},
        {},
      ),

      edit: defineRoute(
        ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'edit'],
        {},
        {},
      ),

      destroy: defineRoute(
        ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'destroy'],
        {},
        {},
      ),

      appraisals: {
        index: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals'],
          {},
          {},
        ),

        new: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      conformationNotes: {
        index: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'conformation_notes'],
          {},
          {},
        ),

        new: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            'new',
          ],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      trainingReports: {
        index: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'training_reports'],
          {},
          {},
        ),

        new: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            'new',
          ],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      organizationHorses: {
        index: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
          ],
          {},
          {},
        ),

        new: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            'new',
          ],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'destroy',
          ],
          {},
          {},
        ),
      },

      studFees: {
        index: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees'],
          {},
          {},
        ),

        new: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees', 'new'],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },

      racehorseComments: {
        index: defineRoute(
          ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racehorse_comments'],
          {},
          {},
        ),

        new: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            'new',
          ],
          {},
          {},
        ),

        show: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
          ],
          {},
          {},
        ),

        edit: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'edit',
          ],
          {},
          {},
        ),

        destroy: defineRoute(
          [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'destroy',
          ],
          {},
          {},
        ),
      },
    },
  },
)

const sharesRoutes = {
  index: defineRoute(['shares'], {}, {}),

  new: defineRoute(['shares', 'new'], {}, {}),

  show: defineRoute(['shares', { name: 'shareId', attributeId: 'Share:id' }], {}, {}),

  edit: defineRoute(['shares', { name: 'shareId', attributeId: 'Share:id' }, 'edit'], {}, {}),

  destroy: defineRoute(['shares', { name: 'shareId', attributeId: 'Share:id' }, 'destroy'], {}, {}),
}

const shortlistsRoutes = {
  index: defineRoute(['shortlists'], {}, {}),

  new: defineRoute(['shortlists', 'new'], {}, {}),

  show: defineRoute(['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }], {}, {}),

  edit: defineRoute(
    ['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }, 'destroy'],
    {},
    {},
  ),
}

const stallionsRoutes = {
  index: defineRoute(['stallions'], {}, {}),

  prospects: defineRoute(['stallions', 'prospects'], {}, {}),

  racing: defineRoute(['stallions', 'racing'], {}, {}),

  sales: defineRoute(['stallions', 'sales'], {}, {}),

  crops: defineRoute(['stallions', 'crops'], {}, {}),

  show: defineRoute(
    ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }],
    {},
    {
      racehorses: defineRoute(
        ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'racehorses'],
        {},
        {
          racehorseComments: {
            index: defineRoute(
              [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
              ],
              {},
              {},
            ),

            new: defineRoute(
              [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                'new',
              ],
              {},
              {},
            ),

            show: defineRoute(
              [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
              ],
              {},
              {},
            ),

            edit: defineRoute(
              [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
                'edit',
              ],
              {},
              {},
            ),

            destroy: defineRoute(
              [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
                'destroy',
              ],
              {},
              {},
            ),
          },
        },
      ),

      sales: defineRoute(
        ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'sales'],
        {},
        {},
      ),

      crops: defineRoute(
        ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'crops'],
        {},
        {},
      ),
    },
  ),
}

const studFeesRoutes = {
  index: defineRoute(['stud_fees'], {}, {}),

  new: defineRoute(['stud_fees', 'new'], {}, {}),

  show: defineRoute(['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }], {}, {}),

  edit: defineRoute(
    ['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }, 'destroy'],
    {},
    {},
  ),
}

const trainingReportsRoutes = {
  index: defineRoute(['training_reports'], {}, {}),

  new: defineRoute(['training_reports', 'new'], {}, {}),

  show: defineRoute(
    ['training_reports', { name: 'trainingReportId', attributeId: 'TrainingReport:id' }],
    {},
    {},
  ),

  edit: defineRoute(
    ['training_reports', { name: 'trainingReportId', attributeId: 'TrainingReport:id' }, 'edit'],
    {},
    {},
  ),

  destroy: defineRoute(
    ['training_reports', { name: 'trainingReportId', attributeId: 'TrainingReport:id' }, 'destroy'],
    {},
    {},
  ),
}

const usersRoutes = {
  index: defineRoute(['users'], {}, {}),

  new: defineRoute(['users', 'new'], {}, {}),

  show: defineRoute(['users', { name: 'userId', attributeId: 'User:id' }], {}, {}),

  edit: defineRoute(['users', { name: 'userId', attributeId: 'User:id' }, 'edit'], {}, {}),

  destroy: defineRoute(['users', { name: 'userId', attributeId: 'User:id' }, 'destroy'], {}, {}),
}

const videosRoutes = {
  index: defineRoute(['videos'], {}, {}),

  new: defineRoute(['videos', 'new'], {}, {}),

  show: defineRoute(['videos', { name: 'videoId', attributeId: 'Video:id' }], {}, {}),

  edit: defineRoute(['videos', { name: 'videoId', attributeId: 'Video:id' }, 'edit'], {}, {}),

  destroy: defineRoute(['videos', { name: 'videoId', attributeId: 'Video:id' }, 'destroy'], {}, {}),
}

const loginRoutes = defineRoute(['login'], {}, {})

export const Routes = {
  index: indexRoutes,
  accounts: accountsRoutes,
  appraisals: appraisalsRoutes,
  bloodstock: bloodstockRoutes,
  books: booksRoutes,
  broodmares: broodmaresRoutes,
  broodmareProspects: broodmareProspectsRoutes,
  conformationNotes: conformationNotesRoutes,
  covers: coversRoutes,
  dnaSets: dnaSetsRoutes,
  documents: documentsRoutes,
  entities: entitiesRoutes,
  finance: financeRoutes,
  horses: horsesRoutes,
  horseDetails: horseDetailsRoutes,
  images: imagesRoutes,
  matings: matingsRoutes,
  operations: operationsRoutes,
  organizations: organizationsRoutes,
  organizationHorses: organizationHorsesRoutes,
  pregnancyChecks: pregnancyChecksRoutes,
  racehorseComments: racehorseCommentsRoutes,
  sales: salesRoutes,
  saleRecords: saleRecordsRoutes,
  saleRecordDetails: saleRecordDetailsRoutes,
  seasons: seasonsRoutes,
  shares: sharesRoutes,
  shortlists: shortlistsRoutes,
  stallions: stallionsRoutes,
  studFees: studFeesRoutes,
  trainingReports: trainingReportsRoutes,
  users: usersRoutes,
  videos: videosRoutes,
  login: loginRoutes,
}

export {
  indexRoutes,
  accountsRoutes,
  appraisalsRoutes,
  bloodstockRoutes,
  booksRoutes,
  broodmaresRoutes,
  broodmareProspectsRoutes,
  conformationNotesRoutes,
  coversRoutes,
  dnaSetsRoutes,
  documentsRoutes,
  entitiesRoutes,
  financeRoutes,
  horsesRoutes,
  horseDetailsRoutes,
  imagesRoutes,
  matingsRoutes,
  operationsRoutes,
  organizationsRoutes,
  organizationHorsesRoutes,
  pregnancyChecksRoutes,
  racehorseCommentsRoutes,
  salesRoutes,
  saleRecordsRoutes,
  saleRecordDetailsRoutes,
  seasonsRoutes,
  sharesRoutes,
  shortlistsRoutes,
  stallionsRoutes,
  studFeesRoutes,
  trainingReportsRoutes,
  usersRoutes,
  videosRoutes,
  loginRoutes,
}
