import { merge } from './merge'
import { extractPath } from './path'

export function isObject(value: unknown) {
  return value && typeof value === 'object' && value.constructor === Object
}

export function isEmptyObject(value: Record<string | symbol | number, unknown>): boolean {
  return (
    value &&
    typeof value === 'object' &&
    value.constructor === Object &&
    Object.keys(value).length === 0
  )
}

export function resolveValue(template, values) {
  if (typeof template !== 'object') {
    return template
  }

  if (Array.isArray(template)) {
    return template.map((element) => resolveValue(element, values))
  }

  if (!Object.prototype.hasOwnProperty.call(template, 'type')) {
    return Object.keys(template).reduce(
      (result, key) => ({
        ...result,
        [key]: resolveValue(template[key], values),
      }),
      {},
    )
  }

  switch (template.type) {
    case 'literal':
      return template.value
    case 'accessor':
      return extractPath(values, template.value)
    default:
  }
}

export function resolveObject(template, values) {
  return Object.keys(template).reduce((result, key) => {
    const newVal = resolveValue(template[key], values)
    if (newVal === undefined) return result

    return { ...result, [key]: newVal }
  }, {})
}

export const deepMerge = merge

export function deepObjValueTransform(obj, func = (val) => val) {
  const output = {}

  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key])) {
      output[key] = deepObjValueTransform(obj[key], func)
    } else {
      output[key] = func(obj[key])
    }
  })

  return output
}

export function objectSlice(obj, keys) {
  return Object.keys(obj).reduce((result: Record<string, unknown>, key: string) => {
    if (keys.includes(key)) {
      return { ...result, [key]: obj[key] }
    }

    return result
  }, {})
}

export function filterObject<T = unknown>(
  obj: Record<string, T>,
  cond: (val: T) => boolean = (v) => v !== null,
) {
  if (!obj) return

  return Object.keys(obj).reduce(
    (acc, key) => (cond(obj[key]) ? { ...acc, [key]: obj[key] } : acc),
    {},
  )
}

/**
 * Reverses and objects keys and values
 **/
export function invertObject(obj: Record<string, string>) {
  return Object.keys(obj).reduce((acc, key) => ({ ...acc, [obj[key]]: key }), {})
}

/**
 * Removes any keys pointing to:
 * - null
 * - undefined
 * - empty object
 * - empty array
 *
 * Returns undefined if object itself is empty
 **/
export function cleanObject<T extends Record<string, unknown>>(obj?: T): Partial<T> | undefined {
  if (obj == null || typeof obj !== 'object' || isEmptyObject(obj)) {
    return
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]

    if (value == null) {
      return acc
    }

    if (Array.isArray(value) && value.length === 0) {
      return acc
    }

    if (isObject(value)) {
      const newObj = cleanObject(value)
      return newObj ? { ...acc, [key]: newObj } : acc
    }

    return { ...acc, [key]: value }
  }, {})
}
