import { Text } from '@/components/ui/text'

import { formatPeriod } from './period'

export const CashFlowTableHeader = ({ cashFlowColumn }: { cashFlowColumn: CashFlowColumn }) => {
  return (
    <div className="flex flex-col items-center align-bottom">
      <div>{formatPeriod(cashFlowColumn.period)}</div>
      <Text size="sm">{cashFlowColumn.scenario.name}</Text>
      <div>{cashFlowColumn.calculationType}</div>
    </div>
  )
}
