import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { HfmWinstarClient } from '@/types'

const name = 'HfmWinstarClient'

const recordNameSingular = 'hfmWinstarClient'
const recordNamePlural = 'hfmWinstarClients'
const i18nKey = 'hfmWinstar::Client'

const primaryKey = 'clientId'
const searchKey = 'businessName'

const parents: Scope[] = []

const apiHelperKey = 'hfmWinstarClients'

const pageHelperKey = 'hfmWinstarClients'

const resourcePathName = 'hfm_winstar_client'

const attributes: Record<string, AttributeSpec> = {
  clientId: {
    kind: 'column',
    name: 'clientId',
    type: 'integer',
  },
  lastName: {
    kind: 'column',
    name: 'lastName',
    type: 'string',
  },
  firstName: {
    kind: 'column',
    name: 'firstName',
    type: 'string',
  },
  middleName: {
    kind: 'column',
    name: 'middleName',
    type: 'string',
  },
  businessName: {
    kind: 'column',
    name: 'businessName',
    type: 'string',
  },
  prefix: {
    kind: 'column',
    name: 'prefix',
    type: 'string',
  },
  suffix: {
    kind: 'column',
    name: 'suffix',
    type: 'string',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  businessNameFlag: {
    kind: 'column',
    name: 'businessNameFlag',
    type: 'boolean',
  },
  taxType: {
    kind: 'column',
    name: 'taxType',
    type: 'boolean',
  },
  taxNumber: {
    kind: 'column',
    name: 'taxNumber',
    type: 'string',
  },
  farmOwnerFlag: {
    kind: 'column',
    name: 'farmOwnerFlag',
    type: 'boolean',
  },
  maintainBalanceFlag: {
    kind: 'column',
    name: 'maintainBalanceFlag',
    type: 'boolean',
  },
  servicesTaxExemptionCode: {
    kind: 'column',
    name: 'servicesTaxExemptionCode',
    type: 'string',
  },
  standardServiceChargeId: {
    kind: 'column',
    name: 'standardServiceChargeId',
    type: 'integer',
  },
  syndicateServiceChargeId: {
    kind: 'column',
    name: 'syndicateServiceChargeId',
    type: 'integer',
  },
  studFeeServiceChargeId: {
    kind: 'column',
    name: 'studFeeServiceChargeId',
    type: 'integer',
  },
  activeFlag: {
    kind: 'column',
    name: 'activeFlag',
    type: 'boolean',
  },
  companyId: {
    kind: 'column',
    name: 'companyId',
    type: 'integer',
  },
  salutation: {
    kind: 'column',
    name: 'salutation',
    type: 'string',
  },
  accountNumber: {
    kind: 'column',
    name: 'accountNumber',
    type: 'string',
  },
  rowVersion: {
    kind: 'column',
    name: 'rowVersion',
    type: 'binary',
  },
  webAccountFlag: {
    kind: 'column',
    name: 'webAccountFlag',
    type: 'boolean',
  },
  invoiceDistributionMethod: {
    kind: 'column',
    name: 'invoiceDistributionMethod',
    type: 'string',
  },
}

const modelSpec: ModelSpec<HfmWinstarClient> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
