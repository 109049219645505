import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { OrganizationHorseForm } from '@/resources/organization_horse/form'

const OrganizationHorsesNew = () => {
  const { organizationHorsesNew } = useLoaderQueryBuilders()
  const { record: organizationHorse } = useResourceQuery(organizationHorsesNew)

  return (
    <Section title="New Ownership Detail">
      <OrganizationHorseForm initialValues={organizationHorse} />
    </Section>
  )
}

export default OrganizationHorsesNew
