import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTRaceResult } from '@/types'

const name = 'DBTRaceResult'

const recordNameSingular = 'dbtRaceResult'
const recordNamePlural = 'dbtRaceResults'
const i18nKey = 'dbt::RaceResult'

const primaryKey = 'raceResultId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtRaceResults'

const pageHelperKey = 'dbtRaceResults'

const resourcePathName = 'dbt_race_result'

const attributes: Record<string, AttributeSpec> = {
  raceResultId: {
    kind: 'column',
    name: 'raceResultId',
    type: 'text',
  },
  raceId: {
    kind: 'column',
    name: 'raceId',
    type: 'text',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'date',
  },
  raceYear: {
    kind: 'column',
    name: 'raceYear',
    type: 'decimal',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'float',
  },
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'text',
  },
  trackCourseId: {
    kind: 'column',
    name: 'trackCourseId',
    type: 'text',
  },
  thoromanagerRaceId: {
    kind: 'column',
    name: 'thoromanagerRaceId',
    type: 'text',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  raceName: {
    kind: 'column',
    name: 'raceName',
    type: 'string',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  cardId: {
    kind: 'column',
    name: 'cardId',
    type: 'string',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'string',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'integer',
  },
  statebred: {
    kind: 'column',
    name: 'statebred',
    type: 'text',
  },
  raceQualityType: {
    kind: 'column',
    name: 'raceQualityType',
    type: 'text',
  },
  raceConditions: {
    kind: 'column',
    name: 'raceConditions',
    type: 'string',
  },
  isOfficial: {
    kind: 'column',
    name: 'isOfficial',
    type: 'boolean',
  },
  isOffTurf: {
    kind: 'column',
    name: 'isOffTurf',
    type: 'boolean',
  },
  ageRestriction: {
    kind: 'column',
    name: 'ageRestriction',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  aboutDistance: {
    kind: 'column',
    name: 'aboutDistance',
    type: 'text',
  },
  distanceFurlongs: {
    kind: 'column',
    name: 'distanceFurlongs',
    type: 'float',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  purse: {
    kind: 'column',
    name: 'purse',
    type: 'float',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'string',
  },
  starters: {
    kind: 'column',
    name: 'starters',
    type: 'integer',
  },
  maxClaimPrice: {
    kind: 'column',
    name: 'maxClaimPrice',
    type: 'float',
  },
  temporaryRailDistance: {
    kind: 'column',
    name: 'temporaryRailDistance',
    type: 'integer',
  },
  isChuteStart: {
    kind: 'column',
    name: 'isChuteStart',
    type: 'boolean',
  },
  isSealed: {
    kind: 'column',
    name: 'isSealed',
    type: 'boolean',
  },
  runUpDistance: {
    kind: 'column',
    name: 'runUpDistance',
    type: 'integer',
  },
  raceCsr: {
    kind: 'column',
    name: 'raceCsr',
    type: 'float',
  },
  dailyVariant: {
    kind: 'column',
    name: 'dailyVariant',
    type: 'integer',
  },
  postTimeEst: {
    kind: 'column',
    name: 'postTimeEst',
    type: 'time',
  },
  SourceName: {
    kind: 'column',
    name: 'SourceName',
    type: 'text',
  },
  SourceRecordType: {
    kind: 'column',
    name: 'SourceRecordType',
    type: 'text',
  },
  SourceRecordId: {
    kind: 'column',
    name: 'SourceRecordId',
    type: 'integer',
  },
  SourceEventDate: {
    kind: 'column',
    name: 'SourceEventDate',
    type: 'date',
  },
  weatherbysRaceId: {
    kind: 'column',
    name: 'weatherbysRaceId',
    type: 'integer',
  },
  handicapType: {
    kind: 'column',
    name: 'handicapType',
    type: 'text',
  },
  raceWinTime: {
    kind: 'column',
    name: 'raceWinTime',
    type: 'float',
  },
  raceTypeFull: {
    kind: 'column',
    name: 'raceTypeFull',
    type: 'string',
  },
  isStake: {
    kind: 'column',
    name: 'isStake',
    type: 'boolean',
  },
  isGraded: {
    kind: 'column',
    name: 'isGraded',
    type: 'boolean',
  },
  isGradeThree: {
    kind: 'column',
    name: 'isGradeThree',
    type: 'boolean',
  },
  isGradeTwo: {
    kind: 'column',
    name: 'isGradeTwo',
    type: 'boolean',
  },
  isGradeOne: {
    kind: 'column',
    name: 'isGradeOne',
    type: 'boolean',
  },
  isBlackType: {
    kind: 'column',
    name: 'isBlackType',
    type: 'boolean',
  },
  isListed: {
    kind: 'column',
    name: 'isListed',
    type: 'boolean',
  },
  isDirt: {
    kind: 'column',
    name: 'isDirt',
    type: 'boolean',
  },
  isGrass: {
    kind: 'column',
    name: 'isGrass',
    type: 'boolean',
  },
  isArtificial: {
    kind: 'column',
    name: 'isArtificial',
    type: 'boolean',
  },
  isTurf: {
    kind: 'column',
    name: 'isTurf',
    type: 'boolean',
  },
  surfaceGroup: {
    kind: 'column',
    name: 'surfaceGroup',
    type: 'text',
  },
  isSprint: {
    kind: 'column',
    name: 'isSprint',
    type: 'boolean',
  },
  isMiddleDistance: {
    kind: 'column',
    name: 'isMiddleDistance',
    type: 'boolean',
  },
  isRoute: {
    kind: 'column',
    name: 'isRoute',
    type: 'boolean',
  },
  distanceGroup: {
    kind: 'column',
    name: 'distanceGroup',
    type: 'text',
  },
  cpiRegion: {
    kind: 'column',
    name: 'cpiRegion',
    type: 'text',
  },
  startId: {
    kind: 'column',
    name: 'startId',
    type: 'text',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  earnings: {
    kind: 'column',
    name: 'earnings',
    type: 'float',
  },
  odds: {
    kind: 'column',
    name: 'odds',
    type: 'float',
  },
  isNonbetting: {
    kind: 'column',
    name: 'isNonbetting',
    type: 'boolean',
  },
  isFavorite: {
    kind: 'column',
    name: 'isFavorite',
    type: 'boolean',
  },
  medication: {
    kind: 'column',
    name: 'medication',
    type: 'string',
  },
  equipment: {
    kind: 'column',
    name: 'equipment',
    type: 'string',
  },
  weightCarried: {
    kind: 'column',
    name: 'weightCarried',
    type: 'integer',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  postPosition: {
    kind: 'column',
    name: 'postPosition',
    type: 'integer',
  },
  call1Position: {
    kind: 'column',
    name: 'call1Position',
    type: 'integer',
  },
  call2Position: {
    kind: 'column',
    name: 'call2Position',
    type: 'integer',
  },
  call3Position: {
    kind: 'column',
    name: 'call3Position',
    type: 'integer',
  },
  call4Position: {
    kind: 'column',
    name: 'call4Position',
    type: 'integer',
  },
  stretchPosition: {
    kind: 'column',
    name: 'stretchPosition',
    type: 'integer',
  },
  finishPosition: {
    kind: 'column',
    name: 'finishPosition',
    type: 'integer',
  },
  finishPositionOfficial: {
    kind: 'column',
    name: 'finishPositionOfficial',
    type: 'integer',
  },
  isDeadHeat: {
    kind: 'column',
    name: 'isDeadHeat',
    type: 'boolean',
  },
  call1LengthsBehind: {
    kind: 'column',
    name: 'call1LengthsBehind',
    type: 'float',
  },
  call2LengthsBehind: {
    kind: 'column',
    name: 'call2LengthsBehind',
    type: 'float',
  },
  call3LengthsBehind: {
    kind: 'column',
    name: 'call3LengthsBehind',
    type: 'float',
  },
  call4LengthsBehind: {
    kind: 'column',
    name: 'call4LengthsBehind',
    type: 'float',
  },
  stretchLengthsBehind: {
    kind: 'column',
    name: 'stretchLengthsBehind',
    type: 'decimal',
  },
  finishLengthsBehind: {
    kind: 'column',
    name: 'finishLengthsBehind',
    type: 'decimal',
  },
  claimedPrice: {
    kind: 'column',
    name: 'claimedPrice',
    type: 'float',
  },
  isClaimed: {
    kind: 'column',
    name: 'isClaimed',
    type: 'boolean',
  },
  coupledType: {
    kind: 'column',
    name: 'coupledType',
    type: 'string',
  },
  jockeyFName: {
    kind: 'column',
    name: 'jockeyFName',
    type: 'string',
  },
  jockeyMName: {
    kind: 'column',
    name: 'jockeyMName',
    type: 'string',
  },
  jockeyLName: {
    kind: 'column',
    name: 'jockeyLName',
    type: 'string',
  },
  jockeyName: {
    kind: 'column',
    name: 'jockeyName',
    type: 'text',
  },
  trainerFName: {
    kind: 'column',
    name: 'trainerFName',
    type: 'string',
  },
  trainerMName: {
    kind: 'column',
    name: 'trainerMName',
    type: 'string',
  },
  trainerLName: {
    kind: 'column',
    name: 'trainerLName',
    type: 'string',
  },
  trainerName: {
    kind: 'column',
    name: 'trainerName',
    type: 'text',
  },
  ownerName: {
    kind: 'column',
    name: 'ownerName',
    type: 'string',
  },
  isTrouble: {
    kind: 'column',
    name: 'isTrouble',
    type: 'boolean',
  },
  isScratched: {
    kind: 'column',
    name: 'isScratched',
    type: 'boolean',
  },
  scratchReason: {
    kind: 'column',
    name: 'scratchReason',
    type: 'text',
  },
  fraction1Time: {
    kind: 'column',
    name: 'fraction1Time',
    type: 'float',
  },
  fraction2Time: {
    kind: 'column',
    name: 'fraction2Time',
    type: 'float',
  },
  fraction3Time: {
    kind: 'column',
    name: 'fraction3Time',
    type: 'float',
  },
  fraction4Time: {
    kind: 'column',
    name: 'fraction4Time',
    type: 'float',
  },
  fraction5Time: {
    kind: 'column',
    name: 'fraction5Time',
    type: 'float',
  },
  stretchTime: {
    kind: 'column',
    name: 'stretchTime',
    type: 'float',
  },
  stretchRanTime: {
    kind: 'column',
    name: 'stretchRanTime',
    type: 'float',
  },
  lateRanTime: {
    kind: 'column',
    name: 'lateRanTime',
    type: 'float',
  },
  finishTime: {
    kind: 'column',
    name: 'finishTime',
    type: 'float',
  },
  runningStyleActual: {
    kind: 'column',
    name: 'runningStyleActual',
    type: 'string',
  },
  runningStyle: {
    kind: 'column',
    name: 'runningStyle',
    type: 'string',
  },
  runningStyleType: {
    kind: 'column',
    name: 'runningStyleType',
    type: 'string',
  },
  runningStylePosition: {
    kind: 'column',
    name: 'runningStylePosition',
    type: 'integer',
  },
  paceRating1: {
    kind: 'column',
    name: 'paceRating1',
    type: 'integer',
  },
  paceRating2: {
    kind: 'column',
    name: 'paceRating2',
    type: 'integer',
  },
  paceRating3: {
    kind: 'column',
    name: 'paceRating3',
    type: 'integer',
  },
  paceRating4: {
    kind: 'column',
    name: 'paceRating4',
    type: 'integer',
  },
  paceRating5: {
    kind: 'column',
    name: 'paceRating5',
    type: 'integer',
  },
  paceRatingStretch: {
    kind: 'column',
    name: 'paceRatingStretch',
    type: 'integer',
  },
  paceRatingLate: {
    kind: 'column',
    name: 'paceRatingLate',
    type: 'integer',
  },
  speedScore: {
    kind: 'column',
    name: 'speedScore',
    type: 'integer',
  },
  shortComment: {
    kind: 'column',
    name: 'shortComment',
    type: 'text',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  isStart: {
    kind: 'column',
    name: 'isStart',
    type: 'boolean',
  },
  isFinish: {
    kind: 'column',
    name: 'isFinish',
    type: 'boolean',
  },
  isWin: {
    kind: 'column',
    name: 'isWin',
    type: 'boolean',
  },
  isPlace: {
    kind: 'column',
    name: 'isPlace',
    type: 'boolean',
  },
  isShow: {
    kind: 'column',
    name: 'isShow',
    type: 'boolean',
  },
  isWinPlaceShow: {
    kind: 'column',
    name: 'isWinPlaceShow',
    type: 'boolean',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  ageDays: {
    kind: 'column',
    name: 'ageDays',
    type: 'integer',
  },
  entryNumber: {
    kind: 'column',
    name: 'entryNumber',
    type: 'integer',
  },
  entryNumberReverse: {
    kind: 'column',
    name: 'entryNumberReverse',
    type: 'integer',
  },
  startNumber: {
    kind: 'column',
    name: 'startNumber',
    type: 'integer',
  },
  startNumberReverse: {
    kind: 'column',
    name: 'startNumberReverse',
    type: 'integer',
  },
  lastStartDate: {
    kind: 'column',
    name: 'lastStartDate',
    type: 'date',
  },
  daysSinceLastStart: {
    kind: 'column',
    name: 'daysSinceLastStart',
    type: 'integer',
  },
  is2yo: {
    kind: 'column',
    name: 'is2yo',
    type: 'boolean',
  },
  is3yo: {
    kind: 'column',
    name: 'is3yo',
    type: 'boolean',
  },
  is4yo: {
    kind: 'column',
    name: 'is4yo',
    type: 'boolean',
  },
  is4yoPlus: {
    kind: 'column',
    name: 'is4yoPlus',
    type: 'boolean',
  },
  is5yoPlus: {
    kind: 'column',
    name: 'is5yoPlus',
    type: 'boolean',
  },
  ageGroup: {
    kind: 'column',
    name: 'ageGroup',
    type: 'text',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTRaceResult> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
