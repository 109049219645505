import React from 'react'
import { useFormContext } from 'react-hook-form'

import { FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { cn } from '@/utils'

import { FormInput } from './input'

/**
 * NewFormItemProps type
 *
 */
interface FieldProps {
  name: string
  inputType?: string
  [key: string]: unknown
}

/**
 * NewFormItem component
 *
 */
const Field: React.FC<FieldProps> = ({ name, className, hideLabel = false, ...props }) => {
  const { getAttribute } = useFormContext()

  const { attribute = getAttribute(name) } = props

  const formFieldName = () => {
    const scopeParts = name.split('.')
    let lastPart = attribute?.name

    if (attribute?.kind === 'association') {
      lastPart = attribute?.foreignKey
    }

    scopeParts.splice(-1, 1, lastPart)
    return scopeParts.join('.')
  }

  return (
    <FormField
      name={formFieldName()}
      render={({ field }) => (
        <FormItem className={cn('w-full', className)}>
          {!hideLabel && <FormLabel />}
          <FormInput data-testid={field.name} field={field} attribute={attribute} {...props} />
          <FormDescription />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export default Field
