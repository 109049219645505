import { forwardRef } from 'react'

import { useResolvedRoute } from '@/hooks/use-resolved-route'
import { RouteHelper } from '@/libs/routes/route'

import { SimpleLink, SimpleLinkProps } from './simple-link'

type Ref = HTMLButtonElement

export type LinkProps = SimpleLinkProps & {
  route?: RouteHelper
}

/*
 * Link component allows for easy usage in scenarios
 * where links or actions may be missing. It also allows
 * for both React Router and non-React Router links.
 *
 * By default, a `Button` is rendered with a `RouterLink`
 * child. If `noRouter` is `true`, a normal `<a>` tag
 * will be used instead.
 */
const Link = forwardRef<Ref, LinkProps>(({ route, to, ...props }, ref) => {
  const { to: builtTo, href } = useResolvedRoute({ route, to })

  return <SimpleLink to={builtTo} href={href} ref={ref} {...props} />
})

Link.displayName = 'AppLink'

export { Link }
