import React from 'react'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

// TODO This could probably be reworked to expect the user id
export const UserAvatar = ({ name }) => {
  if (name == null || typeof name !== 'string') {
    return null
  }

  const userInitials = name.split(' ').map((firstName) => {
    const [first] = firstName
    return first.toUpperCase()
  })

  const content = (
    <Avatar className="text-xs">
      <AvatarFallback>{userInitials}</AvatarFallback>
    </Avatar>
  )

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{userInitials && content}</TooltipTrigger>
        <TooltipContent>{name}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
