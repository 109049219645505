import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

import { useAuth } from '@/contexts/auth'
import { usePageContext } from '@/contexts/page'
import { toast } from 'sonner'

export const ErrorPage = () => {
  const error = useRouteError()
  const { removeSession } = useAuth()
  const { navigate, navigateBack } = usePageContext()

  if (error instanceof Error && 'response' in error) {
    // What's throwing these errors does query client throw them or only requests from react router
    if (error.response.status === 401) {
      removeSession()
      navigate('/login')
    } else if (error.response.status === 403) {
      toast.error('Forbidden Access')
      navigateBack()
    }
  }

  let errorMessage = ''
  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  }

  console.error(error)

  return (
    <div id="error-page" className="flex h-full items-center justify-center">
      <div className="flex flex-col gap-4">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p className="text-red-500">
          <i>{errorMessage}</i>
        </p>
      </div>
    </div>
  )
}

export default ErrorPage
