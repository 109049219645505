import React from 'react'

import { Action, Actions } from '@/components/actions'
import { Link } from '@/components/link'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { documentsRoutes } from '@/routes'
import { formatDate } from '@/utils'

export const DocumentObject = ({ document, className }) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link to={document.fileUrl} target="_blank" rel="noreferrer" noInertia>
            {document.title}
          </Link>
        </CardTitle>
        <CardActions>
          <Actions numToShow={0}>
            <Action
              route={documentsRoutes.edit.withOptions({ id: document.id })}
              name="edit"
              iconOnly
            />
            <Action
              route={documentsRoutes.destroy.withOptions({ id: document.id })}
              name="destroy"
              iconOnly
            />
          </Actions>
        </CardActions>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        <span>{formatDate(document.dateGenerated)}</span>
        <span>{document.category}</span>
      </CardContent>
    </Card>
  )
}
