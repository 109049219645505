import * as React from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

export const NotFoundPage = () => {
  const error = useRouteError()
  console.error(error)

  let errorMessage = ''
  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>{"This page doesn't exist"}</p>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  )
}

export default NotFoundPage
