import React from 'react'

import { SlidersHorizontal } from '@/components/icons'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { type Table, type Column } from '@tanstack/react-table'

interface ColumnVisibilityControlProps<TData> {
  table: Table<TData>
}

export function ColumnVisibilityControl<TData>({ table }: ColumnVisibilityControlProps<TData>) {
  const columns = React.useMemo(
    () =>
      (table.getAllColumns() ?? []).filter(
        (col: Column<TData>) => col.getCanHide() && col.columnDef.meta?.label,
      ),
    [table],
  )

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="sm" className="ml-auto" iconLeft={<SlidersHorizontal />}>
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[150px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((column: Column<TData>) => (
          <DropdownMenuCheckboxItem
            key={column.id}
            className="capitalize"
            checked={column.getIsVisible()}
            onCheckedChange={(value) => {
              column.toggleVisibility(!!value)
            }}
          >
            {column.columnDef.meta?.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
