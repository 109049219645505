import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { RacehorseCommentSchema } from '@/schemas/racehorse_comment'
import { RacehorseComment } from '@/types'

import { RacehorseCommentResource } from './resource'

type RacehorseCommentFormProps = Omit<UseFormProps<RacehorseComment>, 'resource'>

const RacehorseCommentForm = ({
  zodSchema = RacehorseCommentSchema,
  ...props
}: RacehorseCommentFormProps) => {
  const formConfig = useForm<RacehorseComment>({
    resource: RacehorseCommentResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="horse" />
          <Field name="date" />
          <Field name="tier" />
          <Field name="comment" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { RacehorseCommentForm }
