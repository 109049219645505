import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'FinanceBudgetItem'

const recordNameSingular = 'financeBudgetItem'
const recordNamePlural = 'financeBudgetItems'
const i18nKey = 'finance::BudgetItem'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeBudgetItems'

const pageHelperKey = 'financeBudgetItems'

const resourcePathName = 'finance_budget_item'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  budgetId: {
    kind: 'column',
    name: 'budgetId',
    type: 'integer',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  sageClassId: {
    kind: 'column',
    name: 'sageClassId',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  reportingPeriodId: {
    kind: 'column',
    name: 'reportingPeriodId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  generated: {
    kind: 'column',
    name: 'generated',
    type: 'boolean',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
