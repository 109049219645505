import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTTrack } from '@/types'

const name = 'DBTTrack'

const recordNameSingular = 'dbtTrack'
const recordNamePlural = 'dbtTracks'
const i18nKey = 'dbt::Track'

const primaryKey = 'trackId'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'dbtTracks'

const pageHelperKey = 'dbtTracks'

const resourcePathName = 'dbt_track'

const attributes: Record<string, AttributeSpec> = {
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'text',
  },
  code: {
    kind: 'column',
    name: 'code',
    type: 'text',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'text',
  },
  weatherbysCode: {
    kind: 'column',
    name: 'weatherbysCode',
    type: 'text',
  },
  weatherbysName: {
    kind: 'column',
    name: 'weatherbysName',
    type: 'text',
  },
  weatherbysTrackId: {
    kind: 'column',
    name: 'weatherbysTrackId',
    type: 'integer',
  },
  thoromanagerTrackId: {
    kind: 'column',
    name: 'thoromanagerTrackId',
    type: 'text',
  },
  city: {
    kind: 'column',
    name: 'city',
    type: 'text',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'text',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'text',
  },
  fullLocation: {
    kind: 'column',
    name: 'fullLocation',
    type: 'text',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  source: {
    kind: 'column',
    name: 'source',
    type: 'text',
  },
  cpiRegion: {
    kind: 'column',
    name: 'cpiRegion',
    type: 'text',
  },
}

const modelSpec: ModelSpec<DBTTrack> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
