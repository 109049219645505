import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctDepartment } from '@/types'

const name = 'IntacctDepartment'

const recordNameSingular = 'intacctDepartment'
const recordNamePlural = 'intacctDepartments'
const i18nKey = 'intacct::Department'

const primaryKey = 'id'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'intacctDepartments'

const pageHelperKey = 'intacctDepartments'

const resourcePathName = 'intacct_department'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  parentKey: {
    kind: 'column',
    name: 'parentKey',
    type: 'string',
  },
  parentId: {
    kind: 'column',
    name: 'parentId',
    type: 'string',
  },
  parentName: {
    kind: 'column',
    name: 'parentName',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  custTitle: {
    kind: 'column',
    name: 'custTitle',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctDepartment> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
