import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { BasicTooltip } from '../ui/tooltip'

const DefaultError = ({ error }: { error: Error }) => {
  return (
    <BasicTooltip content={error.message}>
      <div className="w-min text-destructive">
        <p>Error</p>
      </div>
    </BasicTooltip>
  )
}

export const ComponentErrorBoundary = (props: { children: React.ReactNode }) => {
  return <ErrorBoundary FallbackComponent={DefaultError}>{props.children}</ErrorBoundary>
}
