import { Routes } from '@/api/all'
import { Routes as PageRoutes } from '@/routes/paths'

export const findEndpoints = (key: string) => {
  if (key == null || typeof key !== 'string' || key === '') {
    return {}
  }

  return Routes[key] ?? {}
}

export const findRoutes = (key: string) => {
  if (key == null || typeof key !== 'string' || key === '') {
    return {}
  }

  return PageRoutes[key] ?? {}
}
