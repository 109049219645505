import { PageHeader } from '@/components/header'
import { useResourceQuery } from '@/hooks'
import { SaleCard, SaleResource } from '@/resources/sale'

function SalesIndex() {
  const { records: sales } = useResourceQuery(
    SaleResource.query.index.sort('year', 'desc').sort('start_date', 'desc'),
  )
  return (
    <>
      <PageHeader title="Sales" />
      <div className="grid grid-cols-3 gap-4">
        {sales.map((sale) => (
          <SaleCard sale={sale} key={sale.id} hideHeader className="sm:min-w-[25rem]" />
        ))}
      </div>
    </>
  )
}

export default SalesIndex
