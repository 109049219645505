import React from 'react'

import { currencyFormatter, dateFormatter, ellipsisFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'
import { Currency } from '@/components/ui/text'

import { FinishPosition } from './format'
import { DBTRaceResultResource } from './resource'
import { raceLabel, distanceWithSurface } from './utils'

const dbtRaceResultTable = defineTable(DBTRaceResultResource, {}, (tbl) => ({
  columns: [
    tbl.col.raceName({
      label: 'Race',
    }),

    tbl.col.date({
      cell: dateFormatter({ format: "MMM do ''yy" }),
    }),

    tbl.col.raceType({
      label: 'Race Type',
    }),

    tbl.compoundCol('raceNameOverDate', [tbl.col.raceName.id(), tbl.col.date.id()]),

    tbl.col.finishPositionOfficial({
      label: 'Finish',
      cell: ({ getValue }) => <FinishPosition finishPosition={getValue()} />,
    }),

    tbl.compoundCol('raceTypeOverFinishPosition', [
      tbl.col.raceType.id(),
      tbl.col.finishPositionOfficial.id(),
    ]),

    tbl.col.trainerName({
      label: 'Trainer',
      cell: ellipsisFormatter(),
    }),

    tbl.col.ownerName({
      label: 'Owner',
      cell: ellipsisFormatter(),
      enableHiding: false,
    }),

    tbl.col.claimedPrice({
      cell: currencyFormatter(),
    }),

    tbl.calculatedCol('finishPositionWithEarnings', (row) => row.earnings, {
      label: 'Earnings',
      // TODO this needs to be updated so that row.original contains only the submodel
      cell: ({ getValue, row }) => (
        <div className="flex flex-row gap-4">
          <FinishPosition finishPosition={row.original.finishPositionOfficial} />
          <Currency value={getValue()} />
        </div>
      ),
    }),

    // TODO: Finish breeder name
    tbl.calculatedCol('tmpBreederName', () => 'Unknown', {
      label: 'Breeder',
      cell: ellipsisFormatter(),
      enableHiding: false,
    }),

    tbl.compoundCol('ownerOverBreeder', [tbl.col.ownerName.id(), tbl.colId('tmpBreederName')]),

    tbl.calculatedCol('raceLabel', (row) => raceLabel(row), {
      label: 'Race',
    }),

    tbl.compoundCol('raceLabelOverDate', [tbl.colId('raceLabel'), tbl.col.date.id()]),

    tbl.calculatedCol('distanceWithSurface', (row) => distanceWithSurface(row), {
      label: 'Distance / Surface',
    }),

    tbl.compoundCol('claimedPriceOverDistanceWithSurface', [
      tbl.col.claimedPrice.id(),
      tbl.colId('distanceWithSurface'),
    ]),

    tbl.compoundCol('earningsOverDistanceWithSurface', [
      tbl.colId('finishPositionWithEarnings'),
      tbl.colId('distanceWithSurface'),
    ]),
  ],
}))

export { dbtRaceResultTable }
