import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsStarterData } from '@/types'

const name = 'DRFChartsStarterData'

const recordNameSingular = 'drfChartsStarterData'
const recordNamePlural = 'drfChartsStarterData'
const i18nKey = 'drf::ChartsStarterData'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsStarterData'

const pageHelperKey = 'drfChartsStarterData'

const resourcePathName = 'drf_charts_starter_data'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartStarterDataId: {
    kind: 'column',
    name: 'chartStarterDataId',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  chartsHorsesId: {
    kind: 'column',
    name: 'chartsHorsesId',
    type: 'integer',
  },
  registrationNumber: {
    kind: 'column',
    name: 'registrationNumber',
    type: 'string',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'string',
  },
  earlyNumber: {
    kind: 'column',
    name: 'earlyNumber',
    type: 'string',
  },
  lateNumber: {
    kind: 'column',
    name: 'lateNumber',
    type: 'string',
  },
  runningStyle: {
    kind: 'column',
    name: 'runningStyle',
    type: 'string',
  },
  spotlightFigure: {
    kind: 'column',
    name: 'spotlightFigure',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFChartsStarterData> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
