// JsFromRoutes CacheKey 286f972c4d1cf7094b8c95b89c1815b2
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DNASetModel } from '@/models/dna_set'

export const dnaSetsApi = {
  index: defineEndpoint({
    controllerName: 'dna_sets',
    actionName: 'index',
    model: DNASetModel,
    parts: ['api', 'dna_sets'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dna_sets',
    actionName: 'show',
    model: DNASetModel,
    parts: ['api', 'dna_sets', { name: 'id', attributeId: 'DNASet:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
}
