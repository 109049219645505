import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'BroodmareProspectDetail'

const recordNameSingular = 'broodmareProspectDetail'
const recordNamePlural = 'broodmareProspectDetails'
const i18nKey = 'broodmareProspectDetail'

const primaryKey = 'broodmareProspectDetailId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'broodmareProspectDetails'

const pageHelperKey = 'broodmareProspectDetails'

const resourcePathName = 'broodmare_prospect_detail'

const attributes: Record<string, AttributeSpec> = {
  broodmareProspectDetailId: {
    kind: 'column',
    name: 'broodmareProspectDetailId',
    type: 'text',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  horse: {
    kind: 'submodel',
    name: 'horse',
    modelName: 'DBTHorse',
    columns: ['horseId', 'name', 'age', 'yob', 'sex', 'sireId', 'sireName', 'damId', 'damName'],
  },
  careerRaceSummary: {
    kind: 'submodel',
    name: 'careerRaceSummary',
    modelName: 'DBTCareerRaceSummary',
    columns: [
      'starts',
      'wins',
      'places',
      'shows',
      'totalEarnings',
      'averageEarnings',
      'bestClass',
      'bestClassTitle',
      'lastRacedDate',
      'lastWorkoutDate',
    ],
  },
  raceResult: {
    kind: 'submodel',
    name: 'raceResult',
    modelName: 'DBTRaceResult',
    columns: [
      'date',
      'isStart',
      'postPosition',
      'finishPositionOfficial',
      'raceName',
      'raceTypeFull',
      'earnings',
      'distance',
      'surface',
      'trackId',
      'raceNumber',
    ],
  },
  raceEntry: {
    kind: 'submodel',
    name: 'raceEntry',
    modelName: 'DBTRaceResult',
    columns: [
      'date',
      'isStart',
      'postPosition',
      'finishPositionOfficial',
      'raceName',
      'raceTypeFull',
      'claimedPrice',
      'distance',
      'surface',
      'trackId',
      'raceNumber',
    ],
  },
  lastSaleResult: {
    kind: 'submodel',
    name: 'lastSaleResult',
    modelName: 'DBTSaleResult',
    columns: [
      'saleId',
      'saleResultId',
      'saleName',
      'saleHost',
      'saleTitle',
      'reservePrice',
      'salePrice',
      'salePriceSold',
    ],
  },
  broodmareProspect: {
    kind: 'submodel',
    name: 'broodmareProspect',
    modelName: 'BroodmareProspect',
    columns: ['id', 'horseId', 'organizationId', 'rating', 'comment', 'removed'],
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
