/*
 * TODO Implement Index Page for horses/stud_fees
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function StudFeesIndex() {
  return <PageHeader title="Horses::StudFees Index" />
}

export default StudFeesIndex
