import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTMareProductionStat } from '@/types'

const name = 'DBTMareProductionStat'

const recordNameSingular = 'dbtMareProductionStat'
const recordNamePlural = 'dbtMareProductionStats'
const i18nKey = 'dbt::MareProductionStat'

const primaryKey = 'mareId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtMareProductionStats'

const pageHelperKey = 'dbtMareProductionStats'

const resourcePathName = 'dbt_mare_production_stat'

const attributes: Record<string, AttributeSpec> = {
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  numberOfFoals: {
    kind: 'column',
    name: 'numberOfFoals',
    type: 'integer',
  },
  numberOfFoalsRacingAge: {
    kind: 'column',
    name: 'numberOfFoalsRacingAge',
    type: 'integer',
  },
  numberOfFoalsMale: {
    kind: 'column',
    name: 'numberOfFoalsMale',
    type: 'integer',
  },
  numberOfFoalsFemale: {
    kind: 'column',
    name: 'numberOfFoalsFemale',
    type: 'integer',
  },
  totalEarnings: {
    kind: 'column',
    name: 'totalEarnings',
    type: 'float',
  },
  averageEarningsPerStart: {
    kind: 'column',
    name: 'averageEarningsPerStart',
    type: 'float',
  },
  averageEarnings: {
    kind: 'column',
    name: 'averageEarnings',
    type: 'float',
  },
  sumSpeedScore: {
    kind: 'column',
    name: 'sumSpeedScore',
    type: 'decimal',
  },
  maximumSpeedScore: {
    kind: 'column',
    name: 'maximumSpeedScore',
    type: 'integer',
  },
  averageBestDistance: {
    kind: 'column',
    name: 'averageBestDistance',
    type: 'float',
  },
  starts: {
    kind: 'column',
    name: 'starts',
    type: 'decimal',
  },
  averageStarts: {
    kind: 'column',
    name: 'averageStarts',
    type: 'decimal',
  },
  starters: {
    kind: 'column',
    name: 'starters',
    type: 'integer',
  },
  winners: {
    kind: 'column',
    name: 'winners',
    type: 'integer',
  },
  stakesWinners: {
    kind: 'column',
    name: 'stakesWinners',
    type: 'integer',
  },
  gradedStakesWinners: {
    kind: 'column',
    name: 'gradedStakesWinners',
    type: 'integer',
  },
  gradeThreeWinners: {
    kind: 'column',
    name: 'gradeThreeWinners',
    type: 'integer',
  },
  gradeTwoWinners: {
    kind: 'column',
    name: 'gradeTwoWinners',
    type: 'integer',
  },
  gradeOneWinners: {
    kind: 'column',
    name: 'gradeOneWinners',
    type: 'integer',
  },
  gradeOneTwoWinners: {
    kind: 'column',
    name: 'gradeOneTwoWinners',
    type: 'integer',
  },
  stakesPlaced: {
    kind: 'column',
    name: 'stakesPlaced',
    type: 'integer',
  },
  gradedStakesPlaced: {
    kind: 'column',
    name: 'gradedStakesPlaced',
    type: 'integer',
  },
  gradeThreePlaced: {
    kind: 'column',
    name: 'gradeThreePlaced',
    type: 'integer',
  },
  gradeTwoPlaced: {
    kind: 'column',
    name: 'gradeTwoPlaced',
    type: 'integer',
  },
  gradeOnePlaced: {
    kind: 'column',
    name: 'gradeOnePlaced',
    type: 'integer',
  },
  multipleWinners: {
    kind: 'column',
    name: 'multipleWinners',
    type: 'integer',
  },
  multipleStakesWinners: {
    kind: 'column',
    name: 'multipleStakesWinners',
    type: 'integer',
  },
  multipleGradedStakesWinners: {
    kind: 'column',
    name: 'multipleGradedStakesWinners',
    type: 'integer',
  },
  multipleGradeThreeWinners: {
    kind: 'column',
    name: 'multipleGradeThreeWinners',
    type: 'integer',
  },
  multipleGradeTwoWinners: {
    kind: 'column',
    name: 'multipleGradeTwoWinners',
    type: 'integer',
  },
  multipleGradeOneWinners: {
    kind: 'column',
    name: 'multipleGradeOneWinners',
    type: 'integer',
  },
  multipleStakesPlaced: {
    kind: 'column',
    name: 'multipleStakesPlaced',
    type: 'integer',
  },
  multipleGradedStakesPlaced: {
    kind: 'column',
    name: 'multipleGradedStakesPlaced',
    type: 'integer',
  },
  multipleGradeThreePlaced: {
    kind: 'column',
    name: 'multipleGradeThreePlaced',
    type: 'integer',
  },
  multipleGradeTwoPlaced: {
    kind: 'column',
    name: 'multipleGradeTwoPlaced',
    type: 'integer',
  },
  multipleGradeOnePlaced: {
    kind: 'column',
    name: 'multipleGradeOnePlaced',
    type: 'integer',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTMareProductionStat> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
