import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'DBTMeasurementReference'

const recordNameSingular = 'dbtMeasurementReference'
const recordNamePlural = 'dbtMeasurementReferences'
const i18nKey = 'dbt::MeasurementReference'

const primaryKey = 'measurementReferenceId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtMeasurementReferences'

const pageHelperKey = 'dbtMeasurementReferences'

const resourcePathName = 'dbt_measurement_reference'

const attributes: Record<string, AttributeSpec> = {
  ageDays: {
    kind: 'column',
    name: 'ageDays',
    type: 'integer',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  height: {
    kind: 'column',
    name: 'height',
    type: 'float',
  },
  weight: {
    kind: 'column',
    name: 'weight',
    type: 'float',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
