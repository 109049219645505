import { createRoutes } from '@/libs/router/route'
import { SaleRecordDetailResource } from '@/resources/sale_record_detail/resource'

export const SaleRecordDetailResourceRoutes = createRoutes({
  resource: SaleRecordDetailResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'sale_record_details',
      children: [
        {
          index: true,
          page: 'sale_record_details/index',
          handle: {
            sheetSize: 'md',
          },
          query: SaleRecordDetailResource.query.index
            .sort('sale__year', 'desc')
            .sort('created_at', 'desc'),
        },
        {
          path: ':id',
          query: SaleRecordDetailResource.query.show,
          children: [
            {
              index: true,
              page: 'sale_record_details/show',
            },
          ],
        },
      ],
    },
  ],
})

export const SaleRecordDetailRoutes = SaleRecordDetailResourceRoutes
