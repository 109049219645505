import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctEmployee } from '@/types'

const name = 'IntacctEmployee'

const recordNameSingular = 'intacctEmployee'
const recordNamePlural = 'intacctEmployees'
const i18nKey = 'intacct::Employee'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctEmployees'

const pageHelperKey = 'intacctEmployees'

const resourcePathName = 'intacct_employee'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  parentKey: {
    kind: 'column',
    name: 'parentKey',
    type: 'string',
  },
  entity: {
    kind: 'column',
    name: 'entity',
    type: 'string',
  },
  birthDate: {
    kind: 'column',
    name: 'birthDate',
    type: 'date',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  employeeType: {
    kind: 'column',
    name: 'employeeType',
    type: 'string',
  },
  employee1099Type: {
    kind: 'column',
    name: 'employee1099Type',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  contactName: {
    kind: 'column',
    name: 'contactName',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctEmployee> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
