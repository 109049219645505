import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { SNPChip } from '@/types'

const name = 'SNPChip'

const recordNameSingular = 'snpChip'
const recordNamePlural = 'snpChips'
const i18nKey = 'snpChip'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'snpChips'

const pageHelperKey = 'snpChips'

const resourcePathName = 'snp_chip'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  snpChipReport: {
    kind: 'association',
    name: 'snpChipReport',
    modelName: 'SNPChipReport',
    foreignKey: 'snpChipReportId',
    associationPrimaryKey: 'id',
  },
  hairSample: {
    kind: 'association',
    name: 'hairSample',
    modelName: 'HairSample',
    foreignKey: 'hairSampleId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  snpChipReportId: {
    kind: 'column',
    name: 'snpChipReportId',
    type: 'integer',
  },
  hairSampleId: {
    kind: 'column',
    name: 'hairSampleId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  sampleCode: {
    kind: 'column',
    name: 'sampleCode',
    type: 'string',
  },
  plate: {
    kind: 'column',
    name: 'plate',
    type: 'string',
  },
  well: {
    kind: 'column',
    name: 'well',
    type: 'string',
  },
  sentrixPosition: {
    kind: 'column',
    name: 'sentrixPosition',
    type: 'string',
  },
  numberOfCalls: {
    kind: 'column',
    name: 'numberOfCalls',
    type: 'integer',
  },
  numberOfNoCalls: {
    kind: 'column',
    name: 'numberOfNoCalls',
    type: 'integer',
  },
  callRate: {
    kind: 'column',
    name: 'callRate',
    type: 'decimal',
  },
  aaFrequency: {
    kind: 'column',
    name: 'aaFrequency',
    type: 'decimal',
  },
  abFrequency: {
    kind: 'column',
    name: 'abFrequency',
    type: 'decimal',
  },
  bbFrequency: {
    kind: 'column',
    name: 'bbFrequency',
    type: 'decimal',
  },
  minorFrequency: {
    kind: 'column',
    name: 'minorFrequency',
    type: 'decimal',
  },
  gcScore50: {
    kind: 'column',
    name: 'gcScore50',
    type: 'decimal',
  },
  gcScore10: {
    kind: 'column',
    name: 'gcScore10',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  zeroOne: {
    kind: 'column',
    name: 'zeroOne',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<SNPChip> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
