import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { CompanyDocument } from '@/types'

const name = 'CompanyDocument'

const recordNameSingular = 'companyDocument'
const recordNamePlural = 'companyDocuments'
const i18nKey = 'companyDocument'

const primaryKey = 'id'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'companyDocuments'

const pageHelperKey = 'companyDocuments'

const resourcePathName = 'company_document'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  documentType: {
    kind: 'column',
    name: 'documentType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'monthly_financial',
        },
      ],
    },
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  file: {
    kind: 'attachment',
    name: 'file',
    multiple: false,
  },
}

const modelSpec: ModelSpec<CompanyDocument> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
