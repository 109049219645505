import { PageHeader } from '@/components/header'

function StallionsCrops() {
  return (
    <>
      <PageHeader title="Stallions Crops" />
    </>
  )
}

export default StallionsCrops
