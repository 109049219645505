import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'KeenelandResult'

const recordNameSingular = 'keenelandResult'
const recordNamePlural = 'keenelandResults'
const i18nKey = 'keeneland::Result'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'keenelandResults'

const pageHelperKey = 'keenelandResults'

const resourcePathName = 'keeneland_result'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  saleId: {
    kind: 'column',
    name: 'saleId',
    type: 'integer',
  },
  keenelandSaleId: {
    kind: 'column',
    name: 'keenelandSaleId',
    type: 'integer',
  },
  keenelandSalesSystemSaleId: {
    kind: 'column',
    name: 'keenelandSalesSystemSaleId',
    type: 'integer',
  },
  keenelandEntryDetailId: {
    kind: 'column',
    name: 'keenelandEntryDetailId',
    type: 'integer',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'string',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'string',
  },
  isOut: {
    kind: 'column',
    name: 'isOut',
    type: 'boolean',
  },
  isRna: {
    kind: 'column',
    name: 'isRna',
    type: 'boolean',
  },
  buyer: {
    kind: 'column',
    name: 'buyer',
    type: 'string',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  consignorFull: {
    kind: 'column',
    name: 'consignorFull',
    type: 'string',
  },
  coveringSireName: {
    kind: 'column',
    name: 'coveringSireName',
    type: 'string',
  },
  salePrice: {
    kind: 'column',
    name: 'salePrice',
    type: 'decimal',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'text',
  },
  header: {
    kind: 'column',
    name: 'header',
    type: 'string',
  },
  subHeader: {
    kind: 'column',
    name: 'subHeader',
    type: 'string',
  },
  photoLink: {
    kind: 'column',
    name: 'photoLink',
    type: 'string',
  },
  hipLink: {
    kind: 'column',
    name: 'hipLink',
    type: 'string',
  },
  keeBidLink: {
    kind: 'column',
    name: 'keeBidLink',
    type: 'string',
  },
  showHipLink: {
    kind: 'column',
    name: 'showHipLink',
    type: 'boolean',
  },
  showKeeBidLink: {
    kind: 'column',
    name: 'showKeeBidLink',
    type: 'boolean',
  },
  showPhotos: {
    kind: 'column',
    name: 'showPhotos',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  photos: {
    kind: 'attachment',
    name: 'photos',
    multiple: true,
  },
  videos: {
    kind: 'attachment',
    name: 'videos',
    multiple: true,
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
