import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tag } from '@/components/ui/tag'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { cn, formatDate } from '@/utils'

import { RacehorseCommentResource } from './resource'

function tierColor(tier) {
  switch (tier) {
    case 'A':
      return 'bg-green font-semibold'
    case 'P+':
      return 'bg-blue font-semibold'
    case 'P':
      return 'bg-blue'
    default:
      return ''
  }
}

export const RacehorseCommentContent = ({ racehorseComment }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <InlineInfoTile
            label={formatDate(racehorseComment.date, 'MMM d, yyyy')}
            icon="Calendar"
            tooltipText="Date"
          />
          <InlineInfoTile label={racehorseComment.user?.name} icon="User" tooltipText="Person" />
        </div>
        <Tag
          size="xl"
          className={cn('border border-grey-500 text-2xl', tierColor(racehorseComment.tier))}
          iconOnly
        >
          {racehorseComment.tier || '-'}
        </Tag>
      </div>
      <div className="h-16 overflow-x-auto rounded bg-grey-100 p-3">
        <span className="line-clamp-2 text-xs">{racehorseComment.comment}</span>
      </div>
    </div>
  )
}

const RacehorseCommentSummaryContent = ({ racehorseComments }) => {
  return <RacehorseCommentContent racehorseComment={racehorseComments[0]} />
}

export const RacehorseCommentCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: racehorseComment } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={RacehorseCommentResource.routes.index}>{racehorseComment.title}</Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={RacehorseCommentResource} />
        </CardActions>
      </CardHeader>
      <CardContent>
        <RacehorseCommentContent racehorseComment={racehorseComment} />
      </CardContent>
    </Card>
  )
}

export const RacehorseCommentSummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: racehorseComments, error, isPending } = useResourceQuery(query)
  const pluralName = 'Racehorse Comments'

  return (
    <SummaryCard
      actions={<NewAction resource={RacehorseCommentResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={RacehorseCommentResource.routes.index}
    >
      {racehorseComments.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <RacehorseCommentSummaryContent racehorseComments={racehorseComments} />
      )}
    </SummaryCard>
  )
}
