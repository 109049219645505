import * as React from 'react'

import { FilterPrimitive } from '@/libs/filter'
import { type AnyData, type Table } from '@tanstack/react-table'

import { Preset, Presets } from './presets'

export interface FilterPresetItem {
  column: string
  value: FilterPrimitive
}

export interface FilterPreset extends Preset {
  items: FilterPresetItem[]
}

interface FilterPresetsProps<TData extends AnyData> {
  table: Table<TData>
  presets: FilterPreset[]
  defaultPresetId: string
}

const defaultPresets: FilterPreset[] = []

export const FilterPresets = <TData extends AnyData>({
  table,
  presets = defaultPresets,
  defaultPresetId,
}: FilterPresetsProps<TData>) => {
  const onChange = (id: string) => {
    table.resetColumnFilters()

    const filterPreset = presets.find((preset) => preset.id === id)
    if (filterPreset == null) {
      return
    }

    table.setColumnFilters(
      filterPreset.items
        .map((item) => {
          const filter = table.getColumn(item.column)?.columnDef.meta?.filter

          if (filter == null) {
            return null
          }

          return {
            id: item.column,
            value: {
              operatorDef: filter.operatorDef,
              value: item.value,
            },
          }
        })
        .filter((item) => item != null),
    )
  }

  if (presets == null || (Array.isArray(presets) && presets.length === 0)) {
    return null
  }

  return <Presets presets={presets} defaultPresetId={defaultPresetId} onChange={onChange} />
}
