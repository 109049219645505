import { createRoutes } from '@/libs/router/route'
import { TrainingReportResource } from '@/resources/training_report/resource'

export const TrainingReportResourceRoutes = createRoutes({
  resource: TrainingReportResource,
  routes: [
    {
      path: 'training_reports',
      children: [
        {
          index: true,
          page: 'training_reports/index',
          query: TrainingReportResource.query.index.sort('date', 'desc'),
        },
        {
          path: 'new',
          page: 'training_reports/new',
          query: TrainingReportResource.query.new,
        },
        {
          path: ':id',
          query: TrainingReportResource.query.show,
          children: [
            {
              index: true,
              page: 'training_reports/show',
            },
            {
              path: 'edit',
              query: TrainingReportResource.query.edit,
              page: 'training_reports/edit',
            },
            {
              path: 'destroy',
              page: 'training_reports/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const TrainingReportRoutes = TrainingReportResourceRoutes
