import {
  foalYearlingPanelConfig,
  racehorsePanelConfig,
  broodmarePanelConfig,
  bloodstockStallionPanelConfig,
} from '@/components/panel/config/horse/panel-groups'
import { PanelConfig } from '@/components/panel/panel-types'

export const horsePanelConfig = (params, data): PanelConfig => {
  const horseType = data?.data?.horseType ?? params?.horseType

  switch (horseType?.toLowerCase()) {
    case 'foal':
    case 'yearling':
      return foalYearlingPanelConfig(params, data)

    case 'racehorse':
      return racehorsePanelConfig(params, data)

    case 'mare':
      return broodmarePanelConfig(params, data)

    case 'stallion':
      return bloodstockStallionPanelConfig(params, data)

    default:
      return []
  }
}
