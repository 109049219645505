import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Audit } from '@/types'

const name = 'Audit'

const recordNameSingular = 'audit'
const recordNamePlural = 'audits'
const i18nKey = 'audit'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'audits'

const pageHelperKey = 'audits'

const resourcePathName = 'audit'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  auditableType: {
    kind: 'column',
    name: 'auditableType',
    type: 'string',
  },
  auditableId: {
    kind: 'column',
    name: 'auditableId',
    type: 'integer',
  },
  key: {
    kind: 'column',
    name: 'key',
    type: 'string',
  },
  diff: {
    kind: 'column',
    name: 'diff',
    type: 'hstore',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<Audit> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
