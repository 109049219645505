// JsFromRoutes CacheKey 5ad438381084b9fb89531e9977ad1118
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { StallionModel } from '@/models/stallion'

export const stallionsApi = {
  racehorses: defineEndpoint({
    controllerName: 'stallions',
    actionName: 'racehorses',
    model: StallionModel,
    parts: [],
    httpMethod: 'get',
  }),
  index: defineEndpoint({
    controllerName: 'stallions',
    actionName: 'index',
    model: StallionModel,
    parts: ['api', 'stallions'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'stallions',
    actionName: 'show',
    model: StallionModel,
    parts: ['api', 'stallions', { name: 'id', attributeId: 'Stallion:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
}
