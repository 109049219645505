import { TableBody, TableCell, TableRow } from '@/components/ui/table'
import { cn } from '@/utils'
import { flexRender } from '@tanstack/react-table'

export const DataTableBody = ({ table }) => {
  const cellClassName = (column) =>
    cn(
      'px-4 py-0.5',
      column.getIsPinned() && 'sticky z-[3] bg-white transition-colors group-hover:bg-background',
      column.getIsLastColumn('left') && 'z-[2] shadow-2xl',
    )

  const cellStyle = (column) => {
    return column.getIsPinned()
      ? {
          left: `${column.getStart('left')}px`,
          minWidth: `${column.getSize()}px`,
          maxWidth: `${column.getSize()}px`,
        }
      : undefined
  }

  return (
    <TableBody className="whitespace-nowrap">
      {table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} className="group">
          {row.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              className={cellClassName(cell.column)}
              style={cellStyle(cell.column)}
            >
              {cell.getIsPlaceholder()
                ? null
                : cell.getIsAggregated()
                  ? flexRender(
                      cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                      cell.getContext(),
                    )
                  : flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}
