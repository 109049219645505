import { TableFooter, TableRow, TableHead } from '@/components/ui/table'
import { flexRender } from '@tanstack/react-table'

export const DataTableFooter = ({ table }) => {
  const footers = table
    .getFooterGroups()
    .flatMap((group) => group.headers.map((header) => header.column.columnDef.footer))
    .filter(Boolean)

  if (footers.length === 0) return null

  return (
    <TableFooter>
      {table.getFooterGroups().map((footerGroup) => (
        <TableRow key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <TableHead key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext())}
            </TableHead>
          ))}
        </TableRow>
      ))}
    </TableFooter>
  )
}
