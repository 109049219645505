import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FasigTiptonResult } from '@/types'

const name = 'FasigTiptonResult'

const recordNameSingular = 'fasigTiptonResult'
const recordNamePlural = 'fasigTiptonResults'
const i18nKey = 'fasigTipton::Result'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'fasigTiptonResults'

const pageHelperKey = 'fasigTiptonResults'

const resourcePathName = 'fasig_tipton_result'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  fasigTiptonId: {
    kind: 'column',
    name: 'fasigTiptonId',
    type: 'integer',
  },
  fasigTiptonSaleId: {
    kind: 'column',
    name: 'fasigTiptonSaleId',
    type: 'integer',
  },
  session: {
    kind: 'column',
    name: 'session',
    type: 'date',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  sire: {
    kind: 'column',
    name: 'sire',
    type: 'string',
  },
  dam: {
    kind: 'column',
    name: 'dam',
    type: 'string',
  },
  sireOfDam: {
    kind: 'column',
    name: 'sireOfDam',
    type: 'string',
  },
  propertyLine: {
    kind: 'column',
    name: 'propertyLine',
    type: 'string',
  },
  purchaser: {
    kind: 'column',
    name: 'purchaser',
    type: 'string',
  },
  price: {
    kind: 'column',
    name: 'price',
    type: 'float',
  },
  privateSale: {
    kind: 'column',
    name: 'privateSale',
    type: 'boolean',
  },
  yearOfBirth: {
    kind: 'column',
    name: 'yearOfBirth',
    type: 'date',
  },
  foaled: {
    kind: 'column',
    name: 'foaled',
    type: 'string',
  },
  barn: {
    kind: 'column',
    name: 'barn',
    type: 'integer',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  nomToBreedersCup: {
    kind: 'column',
    name: 'nomToBreedersCup',
    type: 'boolean',
  },
  pregStatus: {
    kind: 'column',
    name: 'pregStatus',
    type: 'string',
  },
  coveringSire: {
    kind: 'column',
    name: 'coveringSire',
    type: 'string',
  },
  coverDate: {
    kind: 'column',
    name: 'coverDate',
    type: 'date',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  consignorId: {
    kind: 'column',
    name: 'consignorId',
    type: 'integer',
  },
  consignorName: {
    kind: 'column',
    name: 'consignorName',
    type: 'string',
  },
  soldAsCode: {
    kind: 'column',
    name: 'soldAsCode',
    type: 'string',
  },
  soldAsDescription: {
    kind: 'column',
    name: 'soldAsDescription',
    type: 'string',
  },
  tjcRefNum: {
    kind: 'column',
    name: 'tjcRefNum',
    type: 'string',
  },
  out: {
    kind: 'column',
    name: 'out',
    type: 'boolean',
  },
  outDate: {
    kind: 'column',
    name: 'outDate',
    type: 'date',
  },
  saleGroup: {
    kind: 'column',
    name: 'saleGroup',
    type: 'string',
  },
  transmittal: {
    kind: 'column',
    name: 'transmittal',
    type: 'integer',
  },
  manuallyUpdated: {
    kind: 'column',
    name: 'manuallyUpdated',
    type: 'boolean',
  },
  youtubeUrl: {
    kind: 'column',
    name: 'youtubeUrl',
    type: 'string',
  },
  enhancedhorsephotoSet: {
    kind: 'column',
    name: 'enhancedhorsephotoSet',
    type: 'string',
  },
  generalhorsephotoSet: {
    kind: 'column',
    name: 'generalhorsephotoSet',
    type: 'string',
  },
  underTackShowSurface: {
    kind: 'column',
    name: 'underTackShowSurface',
    type: 'string',
  },
  underTackShowTime: {
    kind: 'column',
    name: 'underTackShowTime',
    type: 'boolean',
  },
  underTackShowVideo: {
    kind: 'column',
    name: 'underTackShowVideo',
    type: 'boolean',
  },
  underTackShowDay: {
    kind: 'column',
    name: 'underTackShowDay',
    type: 'boolean',
  },
  boldForUnderTackShowPdf: {
    kind: 'column',
    name: 'boldForUnderTackShowPdf',
    type: 'boolean',
  },
  underTackShowThumbnail: {
    kind: 'column',
    name: 'underTackShowThumbnail',
    type: 'boolean',
  },
  lastUpdated: {
    kind: 'column',
    name: 'lastUpdated',
    type: 'datetime',
  },
  ppEnabled: {
    kind: 'column',
    name: 'ppEnabled',
    type: 'boolean',
  },
  enhancedPhotoCaption: {
    kind: 'column',
    name: 'enhancedPhotoCaption',
    type: 'string',
  },
  enhancedTextArea: {
    kind: 'column',
    name: 'enhancedTextArea',
    type: 'string',
  },
  enhancedWatchmaker: {
    kind: 'column',
    name: 'enhancedWatchmaker',
    type: 'string',
  },
  enhancedBonusContent: {
    kind: 'column',
    name: 'enhancedBonusContent',
    type: 'string',
  },
  enhancedRacingStats: {
    kind: 'column',
    name: 'enhancedRacingStats',
    type: 'string',
  },
  enhancedRaceReplays: {
    kind: 'column',
    name: 'enhancedRaceReplays',
    type: 'string',
  },
  enhancedStallionRegister: {
    kind: 'column',
    name: 'enhancedStallionRegister',
    type: 'string',
  },
  enhancedRagozin: {
    kind: 'column',
    name: 'enhancedRagozin',
    type: 'boolean',
  },
  enhancedAlanPorter: {
    kind: 'column',
    name: 'enhancedAlanPorter',
    type: 'boolean',
  },
  enhancedFeaturedVideo: {
    kind: 'column',
    name: 'enhancedFeaturedVideo',
    type: 'boolean',
  },
  enhanced5xPedigree: {
    kind: 'column',
    name: 'enhanced5xPedigree',
    type: 'boolean',
  },
  enhancedThoroughmanager: {
    kind: 'column',
    name: 'enhancedThoroughmanager',
    type: 'boolean',
  },
  enhancedThorograph: {
    kind: 'column',
    name: 'enhancedThorograph',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  repository: {
    kind: 'column',
    name: 'repository',
    type: 'string',
  },
  photos: {
    kind: 'attachment',
    name: 'photos',
    multiple: true,
  },
  videos: {
    kind: 'attachment',
    name: 'videos',
    multiple: true,
  },
}

const modelSpec: ModelSpec<FasigTiptonResult> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
