import type { Table } from '@tanstack/react-table'

interface FilterControlProps<TData> {
  table: Table<TData>
}

export function TableSearch<TData>({ table }: FilterControlProps<TData>) {
  const columnId = table.options.meta.searchColumn

  if (!columnId) return null

  const column = table.getColumn(columnId)

  const filter = column?.columnDef.meta?.filter
  if (!filter) return

  const FilterComponent = filter?.Component

  if (!FilterComponent) return null

  const onChange = (value: unknown) => {
    if (!value) {
      column.setFilterValue(undefined)
    } else {
      column.setFilterValue({
        operatorDef: filter.operatorDef,
        value,
      })
    }
  }

  return (
    <FilterComponent
      value={column.getFilterValue()?.value}
      onChange={onChange}
      className="h-8 border border-accent bg-secondary py-1"
      placeholder={column.columnDef.meta?.label}
      hideControls
      {...filter.props}
    />
  )
}
