import { z } from 'zod'

export const ImageSchema = z.object({
  horseId: z.union([z.number().int(), z.null()]).optional(),
  horseName: z.union([z.string(), z.null()]).optional(),
  horseYob: z.union([z.number().int(), z.null()]).optional(),
  horseDob: z.union([z.string().date(), z.null()]).optional(),
  ageMonths: z.union([z.number().int(), z.null()]).optional(),
  ageType: z.enum(['foal', 'yearling', '2yo', '3yo', 'mature', 'unknown']).default('unknown'),
  photoDate: z
    .union([z.string().date().default('2024-09-08'), z.null().default('2024-09-08')])
    .default('2024-09-08'),
  path: z.union([z.string(), z.null()]).optional(),
  damName: z.union([z.string(), z.null()]).optional(),
  damYob: z.union([z.number().int(), z.null()]).optional(),
  sireName: z.union([z.string(), z.null()]).optional(),
  sireYob: z.union([z.number().int(), z.null()]).optional(),
  angleType: z.enum(['side', 'front', 'back', 'other', 'unknown']).default('unknown'),
  cameraModel: z.union([z.string(), z.null()]).optional(),
  sexType: z.union([z.number().int(), z.null()]).optional(),
  originalName: z.union([z.string(), z.null()]).optional(),
  objectType: z.number().int().default(0),
  handSegPath: z.union([z.string(), z.null()]).optional(),
  duplicate: z.boolean().default(false),
  userId: z.union([z.number().int(), z.null()]).optional(),
  file: z.union([z.string(), z.null()]).optional(),
})
