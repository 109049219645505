import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTMatingEsn } from '@/types'

const name = 'DBTMatingEsn'

const recordNameSingular = 'dbtMatingEsn'
const recordNamePlural = 'dbtMatingEsns'
const i18nKey = 'dbt::MatingEsn'

const primaryKey = 'matingEsnId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtMatingEsns'

const pageHelperKey = 'dbtMatingEsns'

const resourcePathName = 'dbt_mating_esn'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  modelVersionId: {
    kind: 'column',
    name: 'modelVersionId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  foalDayOffset: {
    kind: 'column',
    name: 'foalDayOffset',
    type: 'integer',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  nonWinner: {
    kind: 'column',
    name: 'nonWinner',
    type: 'decimal',
  },
  winner: {
    kind: 'column',
    name: 'winner',
    type: 'decimal',
  },
  stakesWinner: {
    kind: 'column',
    name: 'stakesWinner',
    type: 'decimal',
  },
  gradedStakesWinner: {
    kind: 'column',
    name: 'gradedStakesWinner',
    type: 'decimal',
  },
  ssi: {
    kind: 'column',
    name: 'ssi',
    type: 'decimal',
  },
  firstRaceAgeDays: {
    kind: 'column',
    name: 'firstRaceAgeDays',
    type: 'decimal',
  },
  isLatestModelVersion: {
    kind: 'column',
    name: 'isLatestModelVersion',
    type: 'boolean',
  },
  gradedStakesWinnerTier: {
    kind: 'column',
    name: 'gradedStakesWinnerTier',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'red',
        },
        {
          value: 1,
          key: 'yellow',
        },
        {
          value: 2,
          key: 'green',
        },
      ],
    },
  },
  isSelectedSingular: {
    kind: 'column',
    name: 'isSelectedSingular',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTMatingEsn> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
