import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ModelsFpiPrediction } from '@/types'

const name = 'ModelsFpiPrediction'

const recordNameSingular = 'modelsFpiPrediction'
const recordNamePlural = 'modelsFpiPredictions'
const i18nKey = 'models::FpiPrediction'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'modelsFpiPredictions'

const pageHelperKey = 'modelsFpiPredictions'

const resourcePathName = 'models_fpi_prediction'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  modelVersion: {
    kind: 'association',
    name: 'modelVersion',
    modelName: 'ModelsModelVersion',
    foreignKey: 'modelVersionId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  modelVersionId: {
    kind: 'column',
    name: 'modelVersionId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  nonWinner: {
    kind: 'column',
    name: 'nonWinner',
    type: 'decimal',
  },
  winner: {
    kind: 'column',
    name: 'winner',
    type: 'decimal',
  },
  stakesWinner: {
    kind: 'column',
    name: 'stakesWinner',
    type: 'decimal',
  },
  gradedStakesWinner: {
    kind: 'column',
    name: 'gradedStakesWinner',
    type: 'decimal',
  },
  ssi: {
    kind: 'column',
    name: 'ssi',
    type: 'decimal',
  },
  firstRaceAgeDays: {
    kind: 'column',
    name: 'firstRaceAgeDays',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<ModelsFpiPrediction> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
