import { defineConfig } from 'cva'
import { extendTailwindMerge } from 'tailwind-merge'

export const twMerge = extendTailwindMerge({})

export const { cva, cx, compose } = defineConfig({
  hooks: {
    onComplete: (className) => twMerge(className),
  },
})
