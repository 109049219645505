import { useMatches, useLocation } from 'react-router-dom'

import { buildLoaderQuery } from '@/libs/router/loader_query'
import { capitalize, snakeToCamel } from '@/utils/string'

/**
 * This hook provides access to the same `QueryBuilder` objects
 * that are used by the React Router loaders to fetch data. This is important
 * for ensuring that we can properly invoke `useQuery` on a page, with the
 * appropriate queryKey, allowing us to subscribe to cache changes without page reloads.
 *
 * The queries are returned in an object with names matching their
 * corresponding endpoints.
 *
 * For example, if you're on `/horses/:horseId/appraisals`, the response will contain
 * the keys: `horsesShow` and `appraisalsIndex` as would be defined in the routes.
 */
const useLoaderQueryBuilders = () => {
  const matches = useMatches().reverse()
  const { search } = useLocation()

  const queries = matches.reduce((acc, match) => {
    if (match.handle?.query == null) return acc

    const {
      handle: { query, routeParts },
      params,
    } = match

    const queryName =
      `${snakeToCamel(query.route.controllerName)}${capitalize(snakeToCamel(query.route.actionName))}`.replace(
        /\//g,
        '',
      )

    return {
      ...acc,
      [queryName]: buildLoaderQuery(query, routeParts, params, search),
    }
  }, {})

  return queries
}

export { useLoaderQueryBuilders }
