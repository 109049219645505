// JsFromRoutes CacheKey 0f85100d93be9be3c4da8ba83c94fbfa
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const usersPasswordsApi = {
  new: definePathHelper('get', '/api/users/password/new'),
  edit: definePathHelper('get', '/api/users/password/edit'),
  update: definePathHelper('patch', '/api/users/password'),
  create: definePathHelper('post', '/api/users/password'),
}
