import { useParams } from 'react-router-dom'

import { Action } from '@/components/actions'
import { DataTable } from '@/components/data-table'
import { PageHeader } from '@/components/header'
import { Link } from '@/components/link'
import { useAuth } from '@/contexts/auth'
import { DBTSeasonDetailResource, dbtSeasonDetailTable } from '@/resources/dbt/season_detail'
import { booksRoutes } from '@/routes'

import BookSelect from './book-select'

const BooksTargets = () => {
  const { isSales, isSalesAdmin, isCoalition } = useAuth()
  const { bookYear } = useParams()

  const query = DBTSeasonDetailResource.query.index.filter('year', bookYear).filter('target', true)
  // .filter('stallionId', 1808362)

  const tableProps = dbtSeasonDetailTable.props(
    {
      query,
      clientSide: bookYear >= 2025,
      verticalScroll: bookYear >= 2025,
      meta: {
        tableActions: [
          () => (
            <Action
              route={booksRoutes.seasons.new.withOptions({ bookYear })}
              name="new"
              variant="secondary"
              size="sm"
              label="New Season"
            />
          ),
        ],
      },
      // clientSide: true,
      // verticalScroll: true,
    },
    { year: bookYear, isSales, isSalesAdmin, isCoalition, isSeasons: false },
  )

  return (
    <>
      <PageHeader
        title="Targets"
        actions={
          <>
            <Link
              variant="secondary"
              size="sm"
              route={booksRoutes.seasons.withOptions({ bookYear })}
              noChevron
            >
              Seasons
            </Link>
            <BookSelect route={booksRoutes.targets} />
          </>
        }
      />

      <DataTable {...tableProps} />
    </>
  )
}

export default BooksTargets
