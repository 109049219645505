import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTMareEsnSummary } from '@/types'

const name = 'DBTMareEsnSummary'

const recordNameSingular = 'dbtMareEsnSummary'
const recordNamePlural = 'dbtMareEsnSummaries'
const i18nKey = 'dbt::MareEsnSummary'

const primaryKey = 'horseId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtMareEsnSummaries'

const pageHelperKey = 'dbtMareEsnSummaries'

const resourcePathName = 'dbt_mare_esn_summary'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'DBTHorse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  numStallions: {
    kind: 'column',
    name: 'numStallions',
    type: 'integer',
  },
  numStallionsPass: {
    kind: 'column',
    name: 'numStallionsPass',
    type: 'integer',
  },
  numStallionsThreePlus: {
    kind: 'column',
    name: 'numStallionsThreePlus',
    type: 'integer',
  },
  numKyStallions: {
    kind: 'column',
    name: 'numKyStallions',
    type: 'integer',
  },
  numKyStallionsPass: {
    kind: 'column',
    name: 'numKyStallionsPass',
    type: 'integer',
  },
  numKyStallionsThreePlus: {
    kind: 'column',
    name: 'numKyStallionsThreePlus',
    type: 'integer',
  },
  herdAverageEsn: {
    kind: 'column',
    name: 'herdAverageEsn',
    type: 'decimal',
  },
  kyAverageEsn: {
    kind: 'column',
    name: 'kyAverageEsn',
    type: 'decimal',
  },
  maxEsn: {
    kind: 'column',
    name: 'maxEsn',
    type: 'decimal',
  },
  maxKyEsn: {
    kind: 'column',
    name: 'maxKyEsn',
    type: 'decimal',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTMareEsnSummary> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
