import { useMemo } from 'react'

import { usersApi, usersSessionsApi } from '@/api'
import { persistedQueryClient } from '@/libs/query/persisted-client'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useUserSession = () => {
  const sessionQueryKey = ['currentUser']

  const queryClient = persistedQueryClient

  const userQuery = useQuery(
    {
      queryKey: sessionQueryKey,
      queryFn: async () => {
        const response = await usersApi.current.request({
          requestOptions: {
            responseAs: 'response',
          },
        })

        if (!response.ok) {
          return null
        }

        const data = await response.json()

        return data?.data ?? {}
      },
      suspense: false,
      // 2 minutes in ms
      staleTime: 1000 * 60 * 2,
      // 2 hours in ms
      gcTime: 1000 * 60 * 60 * 2,
    },
    queryClient,
  )

  const removeSession = () => {
    queryClient.removeQueries({ queryKey: sessionQueryKey })
  }

  const performSignIn = async (inputs: { email: string; password: string }) => {
    const response = await usersSessionsApi.create.request({
      data: { user: inputs },
      requestOptions: { responseAs: 'response' },
    })

    if (!response.ok) {
      throw new Error('Sign in failed')
    }

    const data = await response.json()

    return data?.data
  }

  const signInMutation = useMutation(
    {
      mutationFn: performSignIn,
      onSuccess: (data) => {
        queryClient.setQueryData(sessionQueryKey, data)
      },
    },
    queryClient,
  )

  const performSignOut = async () => {
    const response = await usersSessionsApi.destroy.request({
      requestOptions: { responseAs: 'response' },
    })

    if (!response.ok) {
      throw new Error('Sign out failed')
    }

    return true
  }

  const signOutMutation = useMutation(
    {
      mutationFn: performSignOut,
      onSuccess: () => {
        removeSession()
      },
    },
    queryClient,
  )

  const { isSuccess, data } = userQuery
  const user = useMemo(() => (isSuccess ? data : null), [isSuccess, data])
  const organization = useMemo(() => (isSuccess ? data.organization : null), [isSuccess, data])
  const roles = useMemo(() => user?.roles ?? [], [user])
  const permissions = useMemo(() => user?.permissions ?? [], [user])
  const permissionMap = useMemo(() =>
    permissions.reduce(
      (acc, permission) => ({
        ...acc,
        [permission.resourceName]: {
          ...acc[permission.resourceName],
          [permission.permission]: true,
        },
      }),
      {},
    ),
  )

  return {
    user,
    organization,
    roles,
    permissions,
    permissionMap,
    userQuery,
    removeSession,
    signInMutation,
    signOutMutation,
  }
}
