import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceLocation } from '@/types'

const name = 'FinanceLocation'

const recordNameSingular = 'financeLocation'
const recordNamePlural = 'financeLocations'
const i18nKey = 'finance::Location'

const primaryKey = 'locationId'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'financeLocations'

const pageHelperKey = 'financeLocations'

const resourcePathName = 'finance_location'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  parentId: {
    kind: 'column',
    name: 'parentId',
    type: 'string',
  },
  entity: {
    kind: 'column',
    name: 'entity',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<FinanceLocation> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
