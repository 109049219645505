import { createRoutes } from '@/libs/router/route'

import { VideoResource } from './resource'

export const VideoRoutesRoutes = createRoutes({
  resource: VideoResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'videos',
      children: [
        {
          index: true,
          page: 'videos/index',
          query: VideoResource.query.index,
        },
        {
          path: 'new',
          page: 'videos/new',
          query: VideoResource.query.new,
        },
        {
          path: ':id',
          query: VideoResource.query.show,
          children: [
            {
              index: true,
              page: 'videos/show',
            },
            {
              path: 'edit',
              query: VideoResource.query.edit,
              page: 'videos/edit',
            },
            {
              path: 'destroy',
              page: 'videos/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const VideoRoutes = VideoRoutesRoutes
