import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Image } from '@/types'

const name = 'Image'

const recordNameSingular = 'image'
const recordNamePlural = 'images'
const i18nKey = 'image'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'images'

const pageHelperKey = 'images'

const resourcePathName = 'image'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'string',
  },
  horseYob: {
    kind: 'column',
    name: 'horseYob',
    type: 'integer',
  },
  horseDob: {
    kind: 'column',
    name: 'horseDob',
    type: 'date',
  },
  ageMonths: {
    kind: 'column',
    name: 'ageMonths',
    type: 'integer',
  },
  ageType: {
    kind: 'column',
    name: 'ageType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'foal',
        },
        {
          value: 1,
          key: 'yearling',
        },
        {
          value: 2,
          key: '2yo',
        },
        {
          value: 3,
          key: '3yo',
        },
        {
          value: 4,
          key: 'mature',
        },
        {
          value: 5,
          key: 'unknown',
        },
      ],
    },
  },
  photoDate: {
    kind: 'column',
    name: 'photoDate',
    type: 'date',
  },
  path: {
    kind: 'column',
    name: 'path',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'string',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'string',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  angleType: {
    kind: 'column',
    name: 'angleType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'side',
        },
        {
          value: 1,
          key: 'front',
        },
        {
          value: 2,
          key: 'back',
        },
        {
          value: 3,
          key: 'other',
        },
        {
          value: 4,
          key: 'unknown',
        },
      ],
    },
  },
  cameraModel: {
    kind: 'column',
    name: 'cameraModel',
    type: 'string',
  },
  sexType: {
    kind: 'column',
    name: 'sexType',
    type: 'integer',
  },
  pid: {
    kind: 'column',
    name: 'pid',
    type: 'integer',
  },
  originalName: {
    kind: 'column',
    name: 'originalName',
    type: 'string',
  },
  objectType: {
    kind: 'column',
    name: 'objectType',
    type: 'integer',
  },
  handSegPath: {
    kind: 'column',
    name: 'handSegPath',
    type: 'string',
  },
  duplicate: {
    kind: 'column',
    name: 'duplicate',
    type: 'boolean',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  estimatedPhotoDate: {
    kind: 'column',
    name: 'estimatedPhotoDate',
    type: 'date',
  },
  file: {
    kind: 'attachment',
    name: 'file',
    multiple: false,
  },
}

const modelSpec: ModelSpec<Image> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
