import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { UserSchema } from '@/schemas/user'
import { User } from '@/types'

import { UserResource } from './resource'

type UserFormProps = Omit<UseFormProps<User>, 'resource'>

const UserForm = ({ zodSchema = UserSchema, ...props }: UserFormProps) => {
  const formConfig = useForm<User>({
    resource: UserResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="organization" />
          <Field name="tableauUser" />
          <Field name="email" />
          <Field name="encryptedPassword" />
          <Field name="resetPasswordToken" />
          <Field name="resetPasswordSentAt" />
          <Field name="rememberCreatedAt" />
          <Field name="signInCount" />
          <Field name="currentSignInAt" />
          <Field name="lastSignInAt" />
          <Field name="currentSignInIp" />
          <Field name="lastSignInIp" />
          <Field name="firstName" />
          <Field name="lastName" />
          <Field name="invitationToken" />
          <Field name="invitationCreatedAt" />
          <Field name="invitationSentAt" />
          <Field name="invitationAcceptedAt" />
          <Field name="invitationLimit" />
          <Field name="invitedByType" />
          <Field name="jti" />
          <Field name="apiKey" />
          <Field name="active" />
          <Field name="userType" />
          <Field name="userToken" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { UserForm }
