import { SummaryCard } from '@/components/cards'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { NewAction } from '@/components/resource'
import { bestClassStyle } from '@/resources/dbt/race/best_class_style'
import { DBTCareerRaceSummary } from '@/types'
import { formatCurrency, formatDate } from '@/utils'

import { DBTCareerRaceSummaryResource } from './resource'

export const CareerRaceSummaryContent = ({
  dbtCareerRaceSummary,
}: {
  dbtCareerRaceSummary?: DBTCareerRaceSummary
}) => {
  if (!dbtCareerRaceSummary?.starts) {
    return 'Unraced'
  }

  return (
    <div className="flex justify-between">
      <div>
        <InlineInfoTile
          label={dbtCareerRaceSummary.bestClassTitle}
          tooltipText="Best Class"
          className={bestClassStyle(dbtCareerRaceSummary.bestClass)}
        />
        <InlineInfoTile
          icon={'Trophy'}
          label={`${dbtCareerRaceSummary.starts}-${dbtCareerRaceSummary.wins}-${dbtCareerRaceSummary.places}-${dbtCareerRaceSummary.shows}`}
          tooltipText="Race Record"
        />
      </div>
      <div>
        <InlineInfoTile
          icon={'DollarSign'}
          label={formatCurrency(dbtCareerRaceSummary.totalEarnings)}
          tooltipText="Total earnings"
          className="w-full font-semibold"
        />
        <InlineInfoTile
          icon={'Flag'}
          label={formatDate(dbtCareerRaceSummary.lastRacedDate, 'MMM do, yyyy')}
          tooltipText="Last Raced Date"
        />
      </div>
    </div>
  )
}

export const CareerRaceSummaryCard = ({
  dbtCareerRaceSummary,
  className,
}: {
  dbtCareerRaceSummary: DBTCareerRaceSummary
  className: string
}) => {
  return (
    <SummaryCard
      actions={<NewAction resource={DBTCareerRaceSummaryResource} iconOnly />}
      className={className}
      isPending={false}
      pluralName={'Career Race Summary'}
      queryError={null}
      titleRoute={DBTCareerRaceSummaryResource.routes.index}
      titleLabel={dbtCareerRaceSummary.title}
    >
      <CareerRaceSummaryContent dbtCareerRaceSummary={dbtCareerRaceSummary} />
    </SummaryCard>
  )
}
