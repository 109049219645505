import { PageHeader } from '@/components/header'

function StallionsIndex() {
  return (
    <>
      <PageHeader title="Stallions Index" />
    </>
  )
}

export default StallionsIndex
