import type { Column } from './column'
import type { EnumDomain } from './domain'
import type { ModelSpecName } from './names'

import { findModelSpec } from './find_model_spec'
import { ModelSpec } from './model_spec'
import { ResourceRecord } from './record'

export type ColumnAttribute = Column & {
  kind: 'column'
}

interface Association {
  kind: 'association'
  name: string
  referencesGlobalId: string
  globalId: string
  foreignKey: string
  modelName: ModelSpecName
  associationPrimaryKey?: string
}

interface Attachment {
  kind: 'attachment'
  name: string
  globalId: string
  multiple?: boolean
}

interface Submodel {
  kind: 'submodel'
  name: string
  globalId: string
  modelName: ModelSpecName
  columns?: string[]
}

export type Attribute = ColumnAttribute | Association | Attachment | Submodel

export const enumDomainToMap = (domain: EnumDomain = { values: [] }) =>
  domain.values.reduce((acc, item) => ({ ...acc, [item.value]: item.key }), {})

export const attributesFromSpec = <T extends ResourceRecord>(
  modelSpec: ModelSpec<T>,
): Partial<Record<keyof T, Attribute>> => {
  const attributeNames = Object.keys(modelSpec.attributes)

  const buildReferenceGlobalId = (attribute) =>
    attribute.kind === 'association'
      ? `${attribute.modelName}:${attribute.associationPrimaryKey}`
      : null

  return attributeNames.reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        globalId: `${modelSpec.name}:${modelSpec.attributes[key].name}`,
        referencesGlobalId: buildReferenceGlobalId(modelSpec.attributes[key]),
        ...modelSpec.attributes[key],
      },
    }),
    {},
  )
}

export const defineGetAttribute = <T extends ResourceRecord>(modelSpec: ModelSpec<T>) => {
  const attributes = attributesFromSpec(modelSpec)

  const getAttribute = (name: string): Attribute | undefined => {
    if (name == null || typeof name !== 'string' || name === '') {
      return
    }

    const [current, ...rest] = name.split('.')

    const attribute = attributes[current]

    if (attribute == null) {
      return
    }

    if (attribute.kind === 'association' || attribute.kind === 'submodel') {
      if (rest.length === 0) {
        return attribute
      }

      const attributeModelSpec = findModelSpec(attribute.modelName)

      return defineGetAttribute(attributeModelSpec)(rest.join('.'))
    }

    return attribute
  }

  return getAttribute
}

export const modelNameFromAttributeId = (attributeId: string) => attributeId.split(':')[0]
