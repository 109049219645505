import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTRace } from '@/types'

const name = 'DBTRace'

const recordNameSingular = 'dbtRace'
const recordNamePlural = 'dbtRaces'
const i18nKey = 'dbt::Race'

const primaryKey = 'raceId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtRaces'

const pageHelperKey = 'dbtRaces'

const resourcePathName = 'dbt_race'

const attributes: Record<string, AttributeSpec> = {
  track: {
    kind: 'association',
    name: 'track',
    modelName: 'DBTTrack',
    foreignKey: 'trackId',
    associationPrimaryKey: 'trackId',
  },
  raceId: {
    kind: 'column',
    name: 'raceId',
    type: 'text',
  },
  raceDate: {
    kind: 'column',
    name: 'raceDate',
    type: 'date',
  },
  raceYear: {
    kind: 'column',
    name: 'raceYear',
    type: 'decimal',
  },
  distance: {
    kind: 'column',
    name: 'distance',
    type: 'float',
  },
  trackId: {
    kind: 'column',
    name: 'trackId',
    type: 'text',
  },
  trackCourseId: {
    kind: 'column',
    name: 'trackCourseId',
    type: 'text',
  },
  thoromanagerRaceId: {
    kind: 'column',
    name: 'thoromanagerRaceId',
    type: 'text',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  raceName: {
    kind: 'column',
    name: 'raceName',
    type: 'string',
  },
  raceNumber: {
    kind: 'column',
    name: 'raceNumber',
    type: 'integer',
  },
  cardId: {
    kind: 'column',
    name: 'cardId',
    type: 'string',
  },
  raceType: {
    kind: 'column',
    name: 'raceType',
    type: 'enum',
    subtype: 'string',
    domain: {
      values: [
        {
          value: 'STK',
          key: 'stk',
        },
        {
          value: 'CLM',
          key: 'clm',
        },
        {
          value: 'ALW',
          key: 'alw',
        },
        {
          value: 'OCL',
          key: 'ocl',
        },
        {
          value: 'MCL',
          key: 'mcl',
        },
        {
          value: 'STR',
          key: 'str',
        },
        {
          value: 'MSW',
          key: 'msw',
        },
      ],
    },
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'integer',
  },
  statebred: {
    kind: 'column',
    name: 'statebred',
    type: 'text',
  },
  raceQualityType: {
    kind: 'column',
    name: 'raceQualityType',
    type: 'text',
  },
  raceConditions: {
    kind: 'column',
    name: 'raceConditions',
    type: 'string',
  },
  isOfficial: {
    kind: 'column',
    name: 'isOfficial',
    type: 'boolean',
  },
  isOffTurf: {
    kind: 'column',
    name: 'isOffTurf',
    type: 'boolean',
  },
  ageRestriction: {
    kind: 'column',
    name: 'ageRestriction',
    type: 'string',
  },
  sexRestriction: {
    kind: 'column',
    name: 'sexRestriction',
    type: 'string',
  },
  aboutDistance: {
    kind: 'column',
    name: 'aboutDistance',
    type: 'text',
  },
  distanceFurlongs: {
    kind: 'column',
    name: 'distanceFurlongs',
    type: 'float',
  },
  surface: {
    kind: 'column',
    name: 'surface',
    type: 'string',
  },
  purse: {
    kind: 'column',
    name: 'purse',
    type: 'float',
  },
  trackCondition: {
    kind: 'column',
    name: 'trackCondition',
    type: 'string',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'string',
  },
  starters: {
    kind: 'column',
    name: 'starters',
    type: 'integer',
  },
  maxClaimPrice: {
    kind: 'column',
    name: 'maxClaimPrice',
    type: 'float',
  },
  temporaryRailDistance: {
    kind: 'column',
    name: 'temporaryRailDistance',
    type: 'integer',
  },
  isChuteStart: {
    kind: 'column',
    name: 'isChuteStart',
    type: 'boolean',
  },
  isSealed: {
    kind: 'column',
    name: 'isSealed',
    type: 'boolean',
  },
  runUpDistance: {
    kind: 'column',
    name: 'runUpDistance',
    type: 'integer',
  },
  raceCsr: {
    kind: 'column',
    name: 'raceCsr',
    type: 'float',
  },
  dailyVariant: {
    kind: 'column',
    name: 'dailyVariant',
    type: 'integer',
  },
  postTimeEst: {
    kind: 'column',
    name: 'postTimeEst',
    type: 'time',
  },
  SourceName: {
    kind: 'column',
    name: 'SourceName',
    type: 'text',
  },
  SourceRecordType: {
    kind: 'column',
    name: 'SourceRecordType',
    type: 'text',
  },
  SourceRecordId: {
    kind: 'column',
    name: 'SourceRecordId',
    type: 'integer',
  },
  SourceEventDate: {
    kind: 'column',
    name: 'SourceEventDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  weatherbysRaceId: {
    kind: 'column',
    name: 'weatherbysRaceId',
    type: 'integer',
  },
  handicapType: {
    kind: 'column',
    name: 'handicapType',
    type: 'text',
  },
  raceWinTime: {
    kind: 'column',
    name: 'raceWinTime',
    type: 'float',
  },
  raceTypeFull: {
    kind: 'column',
    name: 'raceTypeFull',
    type: 'string',
  },
  isStake: {
    kind: 'column',
    name: 'isStake',
    type: 'boolean',
  },
  isGraded: {
    kind: 'column',
    name: 'isGraded',
    type: 'boolean',
  },
  isGradeThree: {
    kind: 'column',
    name: 'isGradeThree',
    type: 'boolean',
  },
  isGradeTwo: {
    kind: 'column',
    name: 'isGradeTwo',
    type: 'boolean',
  },
  isGradeOne: {
    kind: 'column',
    name: 'isGradeOne',
    type: 'boolean',
  },
  isBlackType: {
    kind: 'column',
    name: 'isBlackType',
    type: 'boolean',
  },
  isListed: {
    kind: 'column',
    name: 'isListed',
    type: 'boolean',
  },
  isDirt: {
    kind: 'column',
    name: 'isDirt',
    type: 'boolean',
  },
  isGrass: {
    kind: 'column',
    name: 'isGrass',
    type: 'boolean',
  },
  isArtificial: {
    kind: 'column',
    name: 'isArtificial',
    type: 'boolean',
  },
  isTurf: {
    kind: 'column',
    name: 'isTurf',
    type: 'boolean',
  },
  surfaceGroup: {
    kind: 'column',
    name: 'surfaceGroup',
    type: 'text',
  },
  isSprint: {
    kind: 'column',
    name: 'isSprint',
    type: 'boolean',
  },
  isMiddleDistance: {
    kind: 'column',
    name: 'isMiddleDistance',
    type: 'boolean',
  },
  isRoute: {
    kind: 'column',
    name: 'isRoute',
    type: 'boolean',
  },
  distanceGroup: {
    kind: 'column',
    name: 'distanceGroup',
    type: 'text',
  },
}

const modelSpec: ModelSpec<DBTRace> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
