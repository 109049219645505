import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const HfmWinstarClientsShow = () => {
  const { hfmWinstarClientsShow } = useLoaderQueryBuilders()
  const { record: hfmWinstarClient } = useResourceQuery(hfmWinstarClientsShow)

  return <Section title={hfmWinstarClient.title} className="mt-16"></Section>
}

export default HfmWinstarClientsShow
