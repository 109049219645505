import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'PartnershipMember'

const recordNameSingular = 'partnershipMember'
const recordNamePlural = 'partnershipMembers'
const i18nKey = 'partnershipMember'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'partnershipMembers'

const pageHelperKey = 'partnershipMembers'

const resourcePathName = 'partnership_member'

const attributes: Record<string, AttributeSpec> = {
  partnershipEntity: {
    kind: 'association',
    name: 'partnershipEntity',
    modelName: 'Entity',
    foreignKey: 'partnershipEntityId',
    associationPrimaryKey: 'id',
  },
  memberEntity: {
    kind: 'association',
    name: 'memberEntity',
    modelName: 'Entity',
    foreignKey: 'memberEntityId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  partnershipEntityId: {
    kind: 'column',
    name: 'partnershipEntityId',
    type: 'integer',
  },
  memberEntityId: {
    kind: 'column',
    name: 'memberEntityId',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
