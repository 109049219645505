import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { AppraisalCard } from '@/resources/appraisal/card'

const AppraisalsShow = () => {
  const { appraisalsShow } = useLoaderQueryBuilders()
  const { record: appraisal } = useResourceQuery(appraisalsShow)

  return (
    <Section title={appraisal.title}>
      <AppraisalCard id={appraisal.id} appraisal={appraisal} latest />
    </Section>
  )
}

export default AppraisalsShow
