import { QueryDef, mergeQueryDef } from '@/libs/filter/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { type ContextStack } from '@/libs/stack'

import { Model } from '../resource/types'
import {
  QueryParams,
  PathParams,
  extractParamsFromStack,
  buildQueryParams,
  sanitizeParams,
} from './params'
import { RoutePart } from './types'

export interface RequestOptions {
  responseAs?: string
  headers?: Record<string, string>
}

export interface BaseRouteOptions {
  query?: QueryParams
  pathParams?: PathParams
  stack?: ContextStack
  q?: QueryDef
  data?: unknown
  requestOptions?: RequestOptions
  relative?: boolean
}

export type RouteOptions = BaseRouteOptions

export interface PathHelperOptions {
  queryParams?: QueryParams
  pathParams?: PathParams
  data?: unknown
  requestOptions?: RequestOptions
  relative?: boolean
}

const mergeRouteOptions = (
  baseOptions?: RouteOptions,
  newOptions?: RouteOptions,
): RouteOptions | undefined => {
  if (baseOptions === undefined || newOptions === undefined) {
    return newOptions ?? baseOptions
  }

  const {
    query: baseQuery = {},
    pathParams: basePathParams = {},
    q: baseQ = {},
    data: baseData,
    stack: baseStack,
    requestOptions: baseRequestOptions,
    relative: baseRelative = true,
    ...baseOtherPathParams
  } = baseOptions

  const {
    query: newQuery = {},
    pathParams: newPathParams = {},
    q: newQ = {},
    data: newData,
    stack: newStack,
    requestOptions: newRequestOptions,
    relative: newRelative = true,
    ...newOtherPathParams
  } = newOptions

  const pathParams: PathParams = {
    ...baseOtherPathParams,
    ...basePathParams,
    ...newOtherPathParams,
    ...newPathParams,
  }

  return {
    query: {
      ...baseQuery,
      ...newQuery,
    },
    pathParams,
    q: mergeQueryDef(baseQ, newQ),
    stack: newStack ?? baseStack,
    data: newData ?? baseData,
    requestOptions: { ...newRequestOptions, ...baseRequestOptions },
    relative: baseRelative && newRelative,
  }
}

export interface BuildPathHelperOptionsProps<T extends ResourceRecord> {
  parts: RoutePart[]
  model?: Model<T>
  options?: RouteOptions
}

/**
 * Takes all route options and builds the final `pathParams`, `query`,
 * and `data` objects used before making the request.
 */
const buildPathHelperOptions = <T extends ResourceRecord = ResourceRecord>({
  parts,
  model,
  options,
}: BuildPathHelperOptionsProps<T>): PathHelperOptions => {
  if (options == null) {
    return {}
  }

  const { query, q, data, stack, pathParams = {}, requestOptions = {}, relative = true } = options

  const queryParams = buildQueryParams({ query, q })

  // TODO: Make the model optional and doin't translate attributeids?
  const extractedParams = extractParamsFromStack({ parts, model, stack })

  const finalPathParams = sanitizeParams({
    ...extractedParams,
    ...pathParams,
  })

  return {
    queryParams,
    pathParams: finalPathParams,
    data,
    requestOptions,
    relative,
  }
}

export { mergeRouteOptions, buildPathHelperOptions }
