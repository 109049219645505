import { GridList, GridListItem } from '@/components/grid_list'
import { useResourceQuery } from '@/hooks'
import { HorseCard, HorseResource } from '@/resources/horse'
import { StudFeeResource } from '@/resources/stud_fee'
import { defaultMatingYear } from '@/utils/date'

function StallionsIndex() {
  const query = StudFeeResource.query.index
    .filter('year', defaultMatingYear() - 1)
    .filter('farm', '%winstar%', 'matches')

  const { records } = useResourceQuery(query)

  return (
    <>
      <GridList>
        {records.map((record) => (
          <GridListItem key={record.id} className={'flex-auto'}>
            <HorseCard
              query={HorseResource.query.show.withOptions({ id: record.stallion.id })}
              className="h-full"
            />
          </GridListItem>
        ))}
      </GridList>
    </>
  )
}

export default StallionsIndex
