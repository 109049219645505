import { Receipt, Tag, UsersGroup } from '@/components/icons'
import { stallionsRoutes, studFeesRoutes } from '@/routes/paths'

import { PanelConfig } from '../panel-types'

export const stallionPanelConfig = (params, data): PanelConfig => {
  const horseId = params.stallionId ?? params.horseId

  return [
    {
      label: 'Stallion',
      items: [
        {
          label: 'Stud Fees',
          route: studFeesRoutes.index.withOptions({ horseId }),
          icon: <Receipt />,
        },
        {
          label: 'Syndicate',
          icon: <UsersGroup />,
        },
        {
          label: 'Sales',
          route: stallionsRoutes.show.sales.withOptions({
            stallionId: horseId,
          }),
          icon: <Tag />,
        },
        {
          label: 'Racing',
          route: stallionsRoutes.show.racehorses.withOptions({
            stallionId: horseId,
          }),
        },
        {
          label: 'Crops',
          route: stallionsRoutes.show.crops.withOptions({
            stallionId: horseId,
          }),
        },
        { label: 'Conformation' },
      ],
    },
  ]
}
