import React from 'react'

import { Circle, Home, Search, Tag, Tools } from '@/components/icons'
import { Panel } from '@/components/panel/panel'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar'
import { useAuth } from '@/contexts/auth'
import { bloodstockRoutes, operationsRoutes, salesRoutes } from '@/routes'
import { cn } from '@/utils'

import { Account } from './account'
import { NavItem } from './item'
import { OrganizationSelect } from './organization'

export function Nav(props: React.ComponentProps<typeof Sidebar>) {
  const { isAdmin, isSalesAdmin, organization } = useAuth()
  const { open, setOpen, searchActive, setSearchActive } = useSidebar()

  return (
    <Sidebar
      collapsible="icon"
      className="overflow-hidden rounded [&>[data-sidebar=sidebar]]:flex-row"
      variant="floating"
      {...props}
    >
      {/* This is the first sidebar */}
      {/* We disable collapsible and adjust width to icon. */}
      {/* This will make the sidebar appear as icons. */}
      <Sidebar
        collapsible="none"
        className={cn(
          '!w-[calc(var(--sidebar-width-icon)_+_1px)] rounded-l',
          !open ? 'rounded-r' : 'border-r',
        )}
      >
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <OrganizationSelect organization={organization} hideTitle />
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent className="px-1.5 md:px-0">
              <SidebarMenu>
                <NavItem
                  icon={<Home />}
                  to={'/'}
                  label="Home"
                  className="*:fill-black hover:!bg-black/50 data-[active=true]:!bg-black/50 data-[active=true]:text-white *:data-[active=true]:fill-white"
                />
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
          {isSalesAdmin ? null : (
            <>
              <SidebarGroup>
                <SidebarGroupContent className="px-1.5 md:px-0">
                  <SidebarMenu>
                    <NavItem
                      icon={<Circle className="fill-blue text-blue" />}
                      route={bloodstockRoutes.crops}
                      label="crops"
                      className="hover:!bg-blue/50 data-[active=true]:!bg-blue *:data-[active=true]:fill-accent *:data-[active=true]:text-accent"
                      end={false}
                    />
                    <NavItem
                      icon={<Circle className="fill-green text-green" />}
                      route={bloodstockRoutes.racehorses}
                      label="racehorses"
                      className="hover:!bg-green/50 data-[active=true]:!bg-green *:data-[active=true]:fill-accent *:data-[active=true]:text-accent"
                      end={false}
                    />
                    <NavItem
                      icon={<Circle className="fill-orange text-orange" />}
                      route={bloodstockRoutes.broodmares}
                      label="broodmares"
                      className="hover:!bg-orange/50 data-[active=true]:!bg-orange *:data-[active=true]:fill-accent *:data-[active=true]:text-accent"
                      end={false}
                    />
                    <NavItem
                      icon={<Circle className="fill-purple text-purple" />}
                      route={bloodstockRoutes.stallions}
                      label="stallions"
                      className="hover:!bg-purple/50 data-[active=true]:!bg-purple *:data-[active=true]:fill-accent *:data-[active=true]:text-accent"
                      end={false}
                    />
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>
              <SidebarGroup>
                <SidebarGroupContent className="px-1.5 md:px-0">
                  <SidebarMenu>
                    <NavItem
                      icon={<Tag />}
                      route={salesRoutes.index}
                      label="Sales"
                      className="hover:!bg-black/50 hover:text-white data-[active=true]:!bg-black/50 data-[active=true]:text-white"
                    />
                    {isAdmin && (
                      <NavItem
                        icon={<Tools />}
                        route={operationsRoutes.index}
                        label="Operations"
                        className="hover:!bg-black/50 hover:text-white data-[active=true]:!bg-black/50 data-[active=true]:text-white"
                        end={false}
                      />
                    )}
                    <SidebarMenuItem>
                      <SidebarMenuButton
                        aria-label="Search"
                        tooltip={'Search'}
                        onClick={() => {
                          setOpen(true)
                          setSearchActive((active) => !active)
                        }}
                        variant={'icon'}
                        className={cn(
                          searchActive
                            ? 'bg-black/50 text-white'
                            : 'bg-background text-black hover:bg-black/50 hover:text-white',
                        )}
                      >
                        <Search />
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>
            </>
          )}
        </SidebarContent>
        <SidebarFooter>
          <Account />
        </SidebarFooter>
      </Sidebar>
      <Sidebar
        collapsible="none"
        className={cn(
          'hidden !w-[calc(var(--sidebar-width)_-_var(--sidebar-width-icon)_-_1px)] flex-1 rounded border-0 md:flex',
          !open && 'md:hidden',
        )}
      >
        <Panel />
      </Sidebar>
    </Sidebar>
  )
}

export default Nav
