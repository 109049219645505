// JsFromRoutes CacheKey 81998b86937522ab820336fefc90ef50
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { ConformationNoteModel } from '@/models/conformation_note'

export const conformationNotesApi = {
  index: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'index',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'create',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'new',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'edit',
    model: ConformationNoteModel,
    parts: [
      'api',
      'conformation_notes',
      { name: 'id', attributeId: 'ConformationNote:id' },
      'edit',
    ],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'show',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes', { name: 'id', attributeId: 'ConformationNote:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'update',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes', { name: 'id', attributeId: 'ConformationNote:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'conformation_notes',
    actionName: 'destroy',
    model: ConformationNoteModel,
    parts: ['api', 'conformation_notes', { name: 'id', attributeId: 'ConformationNote:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
