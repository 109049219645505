import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTRelative } from '@/types'

const name = 'DBTRelative'

const recordNameSingular = 'dbtRelative'
const recordNamePlural = 'dbtRelatives'
const i18nKey = 'dbt::Relative'

const primaryKey = 'horseId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtRelatives'

const pageHelperKey = 'dbtRelatives'

const resourcePathName = 'dbt_relative'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  relative: {
    kind: 'association',
    name: 'relative',
    modelName: 'Horse',
    foreignKey: 'relativeId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  relativeId: {
    kind: 'column',
    name: 'relativeId',
    type: 'integer',
  },
  relativeName: {
    kind: 'column',
    name: 'relativeName',
    type: 'string',
  },
  relativeYob: {
    kind: 'column',
    name: 'relativeYob',
    type: 'integer',
  },
  relativeType: {
    kind: 'column',
    name: 'relativeType',
    type: 'text',
  },
  generation: {
    kind: 'column',
    name: 'generation',
    type: 'integer',
  },
  position: {
    kind: 'column',
    name: 'position',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<DBTRelative> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
