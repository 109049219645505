import React from 'react'

import Icon from '@/components/icon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { extractPath } from '@/utils'

export const iconFormatter =
  ({ iconKey, text, tooltipAccessor }) =>
  ({ row }) => {
    const tooltipText = tooltipAccessor ? extractPath(row.original, tooltipAccessor) : ''

    const content = (
      <>
        <Icon name={iconKey} />
        <span className="text-center">{text}</span>
      </>
    )

    const renderWithTooltip = () => {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>{tooltipText && content}</TooltipTrigger>
            <TooltipContent>{tooltipText}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }

    return tooltipAccessor ? renderWithTooltip() : content
  }
