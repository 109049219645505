import { Loader } from '@/components/icons'
import { SidebarMenuItem, SidebarMenuLink } from '@/components/ui/sidebar'
import { useResolvedRoute } from '@/hooks/use-resolved-route'

import { SidebarMenuAction } from '../ui/old-sidebar'

export interface NavItemProps extends React.ComponentProps<typeof SidebarMenuLink> {
  label?: string
  icon?: React.ReactNode
  action?: React.ReactNode
}

const NavItem = ({ label, icon, action, to, route, end = true, ...props }: NavItemProps) => {
  const { isPending, pageStatus, shouldShowRoute } = useResolvedRoute({
    to,
    route,
    end,
  })

  if (!shouldShowRoute) return null

  const isDisabled =
    (pageStatus === 'preview' && process.env.NODE_ENV !== 'development') || pageStatus === 'planned'

  return (
    <SidebarMenuItem>
      <SidebarMenuLink
        variant={'icon'}
        to={to}
        route={route}
        tooltip={label}
        disabled={isDisabled}
        {...props}
      >
        {icon && isPending ? <Loader className="animate-spin" /> : icon}
        {label ? label : null}
      </SidebarMenuLink>
      {action ? <SidebarMenuAction asChild>{action}</SidebarMenuAction> : null}
    </SidebarMenuItem>
  )
}

export { NavItem }
