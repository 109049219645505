// JsFromRoutes CacheKey 86988a1032e75ea629036bbb73b27443
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const bloodstockInventoryMonthlyApi = {
  index: definePathHelper('get', '/api/bloodstock_inventory_monthly'),
  show: definePathHelper('get', '/api/bloodstock_inventory_monthly/:id'),
}
