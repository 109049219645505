import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceSageClass } from '@/types'

const name = 'FinanceSageClass'

const recordNameSingular = 'financeSageClass'
const recordNamePlural = 'financeSageClasses'
const i18nKey = 'finance::SageClass'

const primaryKey = 'sageClassId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeSageClasses'

const pageHelperKey = 'financeSageClasses'

const resourcePathName = 'finance_sage_class'

const attributes: Record<string, AttributeSpec> = {
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  parentId: {
    kind: 'column',
    name: 'parentId',
    type: 'string',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'decimal',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'text',
  },
  eponaId: {
    kind: 'column',
    name: 'eponaId',
    type: 'string',
  },
  allocatedOverhead: {
    kind: 'column',
    name: 'allocatedOverhead',
    type: 'decimal',
  },
  studFeePaid: {
    kind: 'column',
    name: 'studFeePaid',
    type: 'decimal',
  },
  studFeePaidBmif: {
    kind: 'column',
    name: 'studFeePaidBmif',
    type: 'decimal',
  },
  allocatedBmCost: {
    kind: 'column',
    name: 'allocatedBmCost',
    type: 'decimal',
  },
  purchasePrice: {
    kind: 'column',
    name: 'purchasePrice',
    type: 'decimal',
  },
  isActive: {
    kind: 'column',
    name: 'isActive',
    type: 'boolean',
  },
  isHorse: {
    kind: 'column',
    name: 'isHorse',
    type: 'boolean',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
}

const modelSpec: ModelSpec<FinanceSageClass> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
