import { createRoutes } from '@/libs/router/route'

import { ImageResource } from './resource'

export const ImageResourceRoutes = createRoutes({
  resource: ImageResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'images',
      children: [
        {
          index: true,
          page: 'images/index',
          query: ImageResource.query.index,
        },
        {
          path: 'new',
          page: 'images/new',
          query: ImageResource.query.new,
        },
        {
          path: ':id',
          query: ImageResource.query.show,
          children: [
            {
              index: true,
              page: 'images/show',
            },
            {
              path: 'edit',
              query: ImageResource.query.edit,
              page: 'images/edit',
            },
            {
              path: 'destroy',
              page: 'images/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const ImageRoutes = ImageResourceRoutes
