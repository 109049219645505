import React from 'react'

import { Check } from '@/components/icons'
import { CommandItem } from '@/components/ui/command'
import { useResourceQuery } from '@/hooks'
import { ResourceRecord } from '@/libs/resource/record'
import { cn } from '@/utils'

import { useAutocomplete } from './hooks/use_autocomplete'

export type RenderItemFn<T extends ResourceRecord> = ({
  suggestion,
  isSelected,
}: {
  suggestion: T
  isSelected: boolean
}) => React.ReactNode

export interface AutocompleteItemsProps<T extends ResourceRecord> {
  queryOptions: ReturnType<typeof useAutocomplete>['queryOptions']
  selected: ReturnType<typeof useAutocomplete>['selected']
  getValue: ReturnType<typeof useAutocomplete>['getValue']
  getName: ReturnType<typeof useAutocomplete>['getName']
  isSelected: ReturnType<typeof useAutocomplete>['isSelected']
  createOnSelectHandler: ReturnType<typeof useAutocomplete>['createOnSelectHandler']

  renderItem?: RenderItemFn<T>
}

const AutocompleteItems = <T extends ResourceRecord>({
  queryOptions,
  getValue,
  isSelected,
  createOnSelectHandler,
  ...props
}: AutocompleteItemsProps<T>) => {
  const {
    renderItem = ({ suggestion, isSelected }) => (
      <>
        <Check className={cn('icon-sm', !isSelected && 'opacity-0')} />
        {suggestion.title}
      </>
    ),
  } = props

  const { records: suggestions } = useResourceQuery(queryOptions)

  return suggestions.map((suggestion: T) => (
    <CommandItem
      key={getValue(suggestion)}
      value={getValue(suggestion)}
      onSelect={createOnSelectHandler({
        item: suggestion,
        isSelected: isSelected(suggestion),
      })}
    >
      {renderItem({
        suggestion,
        isSelected: isSelected(suggestion),
      })}
    </CommandItem>
  ))
}

export default AutocompleteItems
