import { useDeferredValue, useMemo, useState } from 'react'

import type { Resource, ResourceRecord } from '@/libs/resource/types'

import { useResource, useResourceQuery } from '@/hooks'
import { ListQueryBuilder } from '@/libs/query/list-query'
import { useDebounce } from '@uidotdev/usehooks'

export type Suggestion = Record<string, number | string>

export interface UseAutocompleteProps<T extends ResourceRecord> {
  resource: Resource
  query?: ListQueryBuilder<T>
  searchAttributeName?: string
  initialSelected?: Suggestion
}

function useAutocomplete<T extends ResourceRecord>({
  initialSelected,
  ...props
}: UseAutocompleteProps<T>) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selected, setSelected] = useState<Suggestion | undefined>(initialSelected)

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const deferredSearchTerm = useDeferredValue(debouncedSearchTerm)

  const resource = useResource({ resource: props.resource })

  const { query = resource.query.index, searchAttributeName = resource.model.searchKey } = props

  const queryOptions = useMemo(
    () =>
      query
        .withOptions({
          query: {
            global: true,
            filter: [searchAttributeName],
            term: [deferredSearchTerm],
          },
        })
        .filter(searchAttributeName, deferredSearchTerm, 'startsWith'),
    [deferredSearchTerm, searchAttributeName, query],
  )

  const { records: suggestions } = useResourceQuery(queryOptions)

  const onSearchTermChange = (value: string) => {
    setSearchTerm(value)
  }

  return {
    suggestions,
    selected,
    setSelected,
    searchTerm,
    setSearchTerm,
    onSearchTermChange,
  }
}

export default useAutocomplete
