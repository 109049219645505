import { Card } from '@/components/ui/card'
import { useResourceQuery } from '@/hooks'
import { SaleRecordDetailContent, SaleRecordDetailResource } from '@/resources/sale_record_detail'

const SaleRecordsIndex = (props) => {
  const {
    urlParams: { horseId },
  } = props
  const { records: saleRecordDetails } = useResourceQuery(
    SaleRecordDetailResource.query.index
      .filter('horse__horse_id', horseId)
      .sort('sale__year', 'desc')
      .sort('sale__start_date', 'desc'),
  )

  if (saleRecordDetails.length === 0) {
    return 'No sale records'
  }

  return (
    <div className="grid grid-cols-3 gap-4">
      {saleRecordDetails.map((saleRecordDetail) => (
        <Card key={saleRecordDetail.saleRecordDetailId}>
          <SaleRecordDetailContent saleRecordDetail={saleRecordDetail} />
        </Card>
      ))}
    </div>
  )
}

export default SaleRecordsIndex
