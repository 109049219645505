import { useParams } from 'react-router-dom'

import { horsePanelConfig } from '@/components/panel/config/horse'
import { PanelContent } from '@/components/panel/content'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

export const HorsePanel = () => {
  const { horseId } = useParams<{ horseId: string }>()
  const queries = useLoaderQueryBuilders()
  const {
    record: { horseType },
  } = useResourceQuery(queries.horsesShow)

  const config = horsePanelConfig({ horseId, horseType })

  return <PanelContent title="Horses" config={config} />
}
