export const snakeToCamel = (value: string) =>
  value
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

export const humanize = (str?: string) => {
  if (!str) return

  const parts = str.split('.')
  const lastPart = parts[parts.length - 1]

  return lastPart
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (word) => word.toUpperCase())
}

export const isEmptyString = (value?: string) => value == null || value === ''

export const isString = (value?: unknown): boolean =>
  value != null && (typeof value === 'string' || value instanceof String)

export const capitalize = ([firstLetter, ...restOfWord]: string) =>
  firstLetter.toUpperCase() + restOfWord.join('')
