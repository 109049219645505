import { useMemo } from 'react'

import { useResourceContext } from '@/contexts'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { useQuery } from '@tanstack/react-query'

const useResourceQuery = <T extends ResourceRecord>(query: QueryBuilder<T>) => {
  // const queryClient = useQueryClient()

  // const populateCache = (data: T[]) => {
  //   data.forEach((record) => (
  //     queryClient.setQueryData(
  //       queryBuilder.recordQueryKey(queryBuilder.getRecordId(record)),
  //       record,
  //     )
  //   ))
  // }
  const stack = useResourceContext()

  if (query === undefined) {
    throw new Error('Must provide a query to `useResourceQuery`')
  }

  const queryOptions = useMemo(
    () => (stack ? query.withOptions({ stack }).build() : query.build()),
    [stack, query],
  )

  if (queryOptions.queryKey.length === 1 && queryOptions.queryKey[0] === 'horse')
    throw new Error('Invalid query options for horse view query')

  const { data, ...rest } = useQuery(queryOptions)

  // Trys to intelligently return the correct record if it's a list of length 1
  const record = useMemo(() => {
    if (Array.isArray(data?.data) && data.data.length === 1) return data.data[0]

    return data?.data
  }, [data])

  const records = useMemo(() => {
    if (Array.isArray(data?.data)) return data.data

    return []
  }, [data])

  return {
    record,
    records,
    data,
    ...rest,
  }
}

export { useResourceQuery }
