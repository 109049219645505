import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceVendor } from '@/types'

const name = 'FinanceVendor'

const recordNameSingular = 'financeVendor'
const recordNamePlural = 'financeVendors'
const i18nKey = 'finance::Vendor'

const primaryKey = 'vendorId'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'financeVendors'

const pageHelperKey = 'financeVendors'

const resourcePathName = 'finance_vendor'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  name1099: {
    kind: 'column',
    name: 'name1099',
    type: 'string',
  },
  billingType: {
    kind: 'column',
    name: 'billingType',
    type: 'string',
  },
  vendType: {
    kind: 'column',
    name: 'vendType',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<FinanceVendor> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
