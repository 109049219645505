// JsFromRoutes CacheKey e4dead8ee71efb978c8b8069035cbb33
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { SaleModel } from '@/models/sale'

export const salesApi = {
  index: defineEndpoint({
    controllerName: 'sales',
    actionName: 'index',
    model: SaleModel,
    parts: ['api', 'sales'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'sales',
    actionName: 'show',
    model: SaleModel,
    parts: ['api', 'sales', { name: 'id', attributeId: 'Sale:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
}
