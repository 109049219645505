import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'Owner'

const recordNameSingular = 'owner'
const recordNamePlural = 'owners'
const i18nKey = 'owner'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'owners'

const pageHelperKey = 'owners'

const resourcePathName = 'owner'

const attributes: Record<string, AttributeSpec> = {
  entity: {
    kind: 'association',
    name: 'entity',
    modelName: 'Entity',
    foreignKey: 'entityId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  entityId: {
    kind: 'column',
    name: 'entityId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
