import { createRoutes } from '@/libs/router/route'

export const OperationsRoutes = createRoutes({
  routes: [
    {
      path: 'operations',
      children: [
        {
          index: true,
          page: 'operations/index',
        },
      ],
    },
  ],
})
