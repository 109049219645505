import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { PhysicalMeasurement } from '@/types'

const name = 'PhysicalMeasurement'

const recordNameSingular = 'physicalMeasurement'
const recordNamePlural = 'physicalMeasurements'
const i18nKey = 'physicalMeasurement'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'physicalMeasurements'

const pageHelperKey = 'physicalMeasurements'

const resourcePathName = 'physical_measurement'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'timestamptz',
  },
  height: {
    kind: 'column',
    name: 'height',
    type: 'decimal',
  },
  length: {
    kind: 'column',
    name: 'length',
    type: 'decimal',
  },
  shoulderWidth: {
    kind: 'column',
    name: 'shoulderWidth',
    type: 'decimal',
  },
  weight: {
    kind: 'column',
    name: 'weight',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  ageMonths: {
    kind: 'column',
    name: 'ageMonths',
    type: 'integer',
  },
  ageDays: {
    kind: 'column',
    name: 'ageDays',
    type: 'integer',
  },
  chestWidth: {
    kind: 'column',
    name: 'chestWidth',
    type: 'decimal',
  },
  hindWidth: {
    kind: 'column',
    name: 'hindWidth',
    type: 'decimal',
  },
  hindHeight: {
    kind: 'column',
    name: 'hindHeight',
    type: 'decimal',
  },
  forelimbLength: {
    kind: 'column',
    name: 'forelimbLength',
    type: 'decimal',
  },
  bodyLength: {
    kind: 'column',
    name: 'bodyLength',
    type: 'decimal',
  },
  collectionMethod: {
    kind: 'column',
    name: 'collectionMethod',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'farm',
        },
        {
          value: 1,
          key: 'feed_company',
        },
        {
          value: 2,
          key: 'measuring_stick',
        },
        {
          value: 3,
          key: 'leica',
        },
      ],
    },
  },
}

const modelSpec: ModelSpec<PhysicalMeasurement> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
