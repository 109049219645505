import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTCareerRaceSummary } from '@/types'

const name = 'DBTCareerRaceSummary'

const recordNameSingular = 'dbtCareerRaceSummary'
const recordNamePlural = 'dbtCareerRaceSummaries'
const i18nKey = 'dbt::CareerRaceSummary'

const primaryKey = 'horseId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtCareerRaceSummaries'

const pageHelperKey = 'dbtCareerRaceSummaries'

const resourcePathName = 'dbt_career_race_summary'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  starts: {
    kind: 'column',
    name: 'starts',
    type: 'integer',
  },
  starts2yo: {
    kind: 'column',
    name: 'starts2yo',
    type: 'integer',
  },
  starts3yo: {
    kind: 'column',
    name: 'starts3yo',
    type: 'integer',
  },
  starts4yoPlus: {
    kind: 'column',
    name: 'starts4yoPlus',
    type: 'integer',
  },
  shortStarts: {
    kind: 'column',
    name: 'shortStarts',
    type: 'integer',
  },
  midStarts: {
    kind: 'column',
    name: 'midStarts',
    type: 'integer',
  },
  routeStarts: {
    kind: 'column',
    name: 'routeStarts',
    type: 'integer',
  },
  dirtStarts: {
    kind: 'column',
    name: 'dirtStarts',
    type: 'integer',
  },
  turfStarts: {
    kind: 'column',
    name: 'turfStarts',
    type: 'integer',
  },
  totalEarnings: {
    kind: 'column',
    name: 'totalEarnings',
    type: 'float',
  },
  averageEarnings: {
    kind: 'column',
    name: 'averageEarnings',
    type: 'float',
  },
  sumSpeedScore: {
    kind: 'column',
    name: 'sumSpeedScore',
    type: 'integer',
  },
  averageSpeedScore: {
    kind: 'column',
    name: 'averageSpeedScore',
    type: 'decimal',
  },
  maximumSpeedScore: {
    kind: 'column',
    name: 'maximumSpeedScore',
    type: 'integer',
  },
  averageEarnings2yo: {
    kind: 'column',
    name: 'averageEarnings2yo',
    type: 'float',
  },
  averageEarnings3yo: {
    kind: 'column',
    name: 'averageEarnings3yo',
    type: 'float',
  },
  averageEarnings4yo: {
    kind: 'column',
    name: 'averageEarnings4yo',
    type: 'float',
  },
  averageEarnings5yoPlus: {
    kind: 'column',
    name: 'averageEarnings5yoPlus',
    type: 'float',
  },
  maximumSpeedScore2yo: {
    kind: 'column',
    name: 'maximumSpeedScore2yo',
    type: 'integer',
  },
  maximumSpeedScore3yo: {
    kind: 'column',
    name: 'maximumSpeedScore3yo',
    type: 'integer',
  },
  maximumSpeedScore4yo: {
    kind: 'column',
    name: 'maximumSpeedScore4yo',
    type: 'integer',
  },
  maximumSpeedScore5yoPlus: {
    kind: 'column',
    name: 'maximumSpeedScore5yoPlus',
    type: 'integer',
  },
  wins: {
    kind: 'column',
    name: 'wins',
    type: 'integer',
  },
  places: {
    kind: 'column',
    name: 'places',
    type: 'integer',
  },
  shows: {
    kind: 'column',
    name: 'shows',
    type: 'integer',
  },
  placed: {
    kind: 'column',
    name: 'placed',
    type: 'integer',
  },
  blackTypeWins: {
    kind: 'column',
    name: 'blackTypeWins',
    type: 'integer',
  },
  blackTypePlaced: {
    kind: 'column',
    name: 'blackTypePlaced',
    type: 'integer',
  },
  stakesWins: {
    kind: 'column',
    name: 'stakesWins',
    type: 'integer',
  },
  stakesPlaced: {
    kind: 'column',
    name: 'stakesPlaced',
    type: 'integer',
  },
  gradedStakesWins: {
    kind: 'column',
    name: 'gradedStakesWins',
    type: 'integer',
  },
  gradedStakesWinsAtTwo: {
    kind: 'column',
    name: 'gradedStakesWinsAtTwo',
    type: 'integer',
  },
  gradedStakesWinsAtThree: {
    kind: 'column',
    name: 'gradedStakesWinsAtThree',
    type: 'integer',
  },
  gradedStakesWinsAtFour: {
    kind: 'column',
    name: 'gradedStakesWinsAtFour',
    type: 'integer',
  },
  gradedStakesWinsAtFivePlus: {
    kind: 'column',
    name: 'gradedStakesWinsAtFivePlus',
    type: 'integer',
  },
  gradedStakesPlaced: {
    kind: 'column',
    name: 'gradedStakesPlaced',
    type: 'integer',
  },
  gradeThreeWins: {
    kind: 'column',
    name: 'gradeThreeWins',
    type: 'integer',
  },
  gradeThreePlaced: {
    kind: 'column',
    name: 'gradeThreePlaced',
    type: 'integer',
  },
  gradeTwoWins: {
    kind: 'column',
    name: 'gradeTwoWins',
    type: 'integer',
  },
  gradeTwoPlaced: {
    kind: 'column',
    name: 'gradeTwoPlaced',
    type: 'integer',
  },
  gradeOneWins: {
    kind: 'column',
    name: 'gradeOneWins',
    type: 'integer',
  },
  gradeOnePlaced: {
    kind: 'column',
    name: 'gradeOnePlaced',
    type: 'integer',
  },
  gradeOneWinsAtTwo: {
    kind: 'column',
    name: 'gradeOneWinsAtTwo',
    type: 'integer',
  },
  gradeOneWinsAtThree: {
    kind: 'column',
    name: 'gradeOneWinsAtThree',
    type: 'integer',
  },
  gradeOneWinsAtFour: {
    kind: 'column',
    name: 'gradeOneWinsAtFour',
    type: 'integer',
  },
  gradeOneWinsAtFivePlus: {
    kind: 'column',
    name: 'gradeOneWinsAtFivePlus',
    type: 'integer',
  },
  lastRacedDate: {
    kind: 'column',
    name: 'lastRacedDate',
    type: 'date',
  },
  maxDaysBetweenStarts: {
    kind: 'column',
    name: 'maxDaysBetweenStarts',
    type: 'integer',
  },
  medianDaysBetweenStarts: {
    kind: 'column',
    name: 'medianDaysBetweenStarts',
    type: 'float',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  lastWorkoutDate: {
    kind: 'column',
    name: 'lastWorkoutDate',
    type: 'date',
  },
  daysSinceLastWork: {
    kind: 'column',
    name: 'daysSinceLastWork',
    type: 'integer',
  },
  daysSinceLastRace: {
    kind: 'column',
    name: 'daysSinceLastRace',
    type: 'integer',
  },
  daysSinceLastEvent: {
    kind: 'column',
    name: 'daysSinceLastEvent',
    type: 'integer',
  },
  ssi: {
    kind: 'column',
    name: 'ssi',
    type: 'float',
  },
  globalSsi: {
    kind: 'column',
    name: 'globalSsi',
    type: 'float',
  },
  bestClassTitle: {
    kind: 'column',
    name: 'bestClassTitle',
    type: 'text',
  },
  bestClass: {
    kind: 'column',
    name: 'bestClass',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'unk',
        },
        {
          value: 1,
          key: 'unr',
        },
        {
          value: 2,
          key: 'nw',
        },
        {
          value: 3,
          key: 'wnr',
        },
        {
          value: 4,
          key: 'btp',
        },
        {
          value: 5,
          key: 'btw',
        },
        {
          value: 6,
          key: 'g3p',
        },
        {
          value: 7,
          key: 'g2p',
        },
        {
          value: 8,
          key: 'g1p',
        },
        {
          value: 9,
          key: 'btw_g3p',
        },
        {
          value: 10,
          key: 'btw_g2p',
        },
        {
          value: 11,
          key: 'btw_g1p',
        },
        {
          value: 12,
          key: 'g3w',
        },
        {
          value: 13,
          key: 'g2w',
        },
        {
          value: 14,
          key: 'g1w',
        },
      ],
    },
  },
  firstRaceAgeDays: {
    kind: 'column',
    name: 'firstRaceAgeDays',
    type: 'integer',
  },
  firstRaceTrackId: {
    kind: 'column',
    name: 'firstRaceTrackId',
    type: 'text',
  },
  firstRaceOdds: {
    kind: 'column',
    name: 'firstRaceOdds',
    type: 'float',
  },
  firstRaceSurface: {
    kind: 'column',
    name: 'firstRaceSurface',
    type: 'string',
  },
  firstRaceDistance: {
    kind: 'column',
    name: 'firstRaceDistance',
    type: 'float',
  },
  firstRaceType: {
    kind: 'column',
    name: 'firstRaceType',
    type: 'string',
  },
  firstRaceDate: {
    kind: 'column',
    name: 'firstRaceDate',
    type: 'date',
  },
  firstRaceSpeedScore: {
    kind: 'column',
    name: 'firstRaceSpeedScore',
    type: 'integer',
  },
  firstRaceStarters: {
    kind: 'column',
    name: 'firstRaceStarters',
    type: 'integer',
  },
  firstRacePurse: {
    kind: 'column',
    name: 'firstRacePurse',
    type: 'float',
  },
  firstWinAgeDays: {
    kind: 'column',
    name: 'firstWinAgeDays',
    type: 'integer',
  },
  firstWinTrackId: {
    kind: 'column',
    name: 'firstWinTrackId',
    type: 'text',
  },
  firstWinOdds: {
    kind: 'column',
    name: 'firstWinOdds',
    type: 'float',
  },
  firstWinSurface: {
    kind: 'column',
    name: 'firstWinSurface',
    type: 'string',
  },
  firstWinDistance: {
    kind: 'column',
    name: 'firstWinDistance',
    type: 'float',
  },
  numRacesToWin: {
    kind: 'column',
    name: 'numRacesToWin',
    type: 'integer',
  },
  firstWinType: {
    kind: 'column',
    name: 'firstWinType',
    type: 'string',
  },
  firstWinDate: {
    kind: 'column',
    name: 'firstWinDate',
    type: 'date',
  },
  firstWinSpeedScore: {
    kind: 'column',
    name: 'firstWinSpeedScore',
    type: 'integer',
  },
  firstWinStarters: {
    kind: 'column',
    name: 'firstWinStarters',
    type: 'integer',
  },
  firstWinPurse: {
    kind: 'column',
    name: 'firstWinPurse',
    type: 'float',
  },
  speedScoreOne: {
    kind: 'column',
    name: 'speedScoreOne',
    type: 'integer',
  },
  finishPositionOne: {
    kind: 'column',
    name: 'finishPositionOne',
    type: 'integer',
  },
  distanceOne: {
    kind: 'column',
    name: 'distanceOne',
    type: 'float',
  },
  surfaceOne: {
    kind: 'column',
    name: 'surfaceOne',
    type: 'string',
  },
  paceRating1One: {
    kind: 'column',
    name: 'paceRating1One',
    type: 'integer',
  },
  paceRating2One: {
    kind: 'column',
    name: 'paceRating2One',
    type: 'integer',
  },
  paceRatingLateOne: {
    kind: 'column',
    name: 'paceRatingLateOne',
    type: 'integer',
  },
  trainerNameOne: {
    kind: 'column',
    name: 'trainerNameOne',
    type: 'text',
  },
}

const modelSpec: ModelSpec<DBTCareerRaceSummary> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
