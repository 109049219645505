// JsFromRoutes CacheKey ede9d6247fe8547ba1ac19446412c878
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { accountsApi } from './AccountsApi'
import { appraisalsApi } from './AppraisalsApi'
import { bloodstockFoalsApi } from './Bloodstock/FoalsApi'
import { bloodstockInventoryMonthlyApi } from './BloodstockInventoryMonthlyApi'
import { bloodstockSearchApi } from './BloodstockSearchApi'
import { broodmareProspectDetailsApi } from './BroodmareProspectDetailsApi'
import { broodmareProspectsApi } from './BroodmareProspectsApi'
import { budgetItemsApi } from './BudgetItemsApi'
import { companyDocumentsApi } from './CompanyDocumentsApi'
import { conformationNotesApi } from './ConformationNotesApi'
import { coversApi } from './CoversApi'
import { dbtCandidateHorsesApi } from './DBT/CandidateHorsesApi'
import { dbtCareerRaceSummariesApi } from './DBT/CareerRaceSummariesApi'
import { dbtEsnSummariesApi } from './DBT/EsnSummariesApi'
import { dbtHorsesApi } from './DBT/HorsesApi'
import { dbtRacehorseDetailsApi } from './DBT/RacehorseDetailsApi'
import { dbtRaceResultsApi } from './DBT/RaceResultsApi'
import { dbtRelativesApi } from './DBT/RelativesApi'
import { dbtSaleResultsApi } from './DBT/SaleResultsApi'
import { dbtSeasonDetailsApi } from './DBT/SeasonDetailsApi'
import { deviseInvitationsApi } from './Devise/InvitationsApi'
import { dnaSetsApi } from './DNASetsApi'
import { documentsApi } from './DocumentsApi'
import { entitiesApi } from './EntitiesApi'
import { financeEntriesApi } from './Finance/EntriesApi'
import { financeMonthlyEntriesApi } from './Finance/MonthlyEntriesApi'
import { hairSampleBatchesApi } from './HairSampleBatchesApi'
import { hairSamplesApi } from './HairSamplesApi'
import { hfmWinstarClientsApi } from './HfmWinstar/ClientsApi'
import { horseDetailsApi } from './HorseDetailsApi'
import { horsesApi } from './HorsesApi'
import { imagesApi } from './ImagesApi'
import { matingsApi } from './MatingsApi'
import { monthlyOwnershipsByDivisionsApi } from './MonthlyOwnershipsByDivisionsApi'
import { organizationHorsesApi } from './OrganizationHorsesApi'
import { organizationsApi } from './OrganizationsApi'
import { pedigreesApi } from './PedigreesApi'
import { physicalMeasurementsApi } from './PhysicalMeasurementsApi'
import { pregnancyChecksApi } from './PregnancyChecksApi'
import { projectedPurchasesApi } from './ProjectedPurchasesApi'
import { racehorseCommentsApi } from './RacehorseCommentsApi'
import { saleRecordDetailsApi } from './SaleRecordDetailsApi'
import { saleRecordsApi } from './SaleRecordsApi'
import { salesApi } from './SalesApi'
import { seasonsApi } from './SeasonsApi'
import { sharesApi } from './SharesApi'
import { shortlistsApi } from './ShortlistsApi'
import { stallionBreedingEstimatesApi } from './StallionBreedingEstimatesApi'
import { stallionBreedingTargetsApi } from './StallionBreedingTargetsApi'
import { stallionProspectsApi } from './StallionProspectsApi'
import { stallionsApi } from './StallionsApi'
import { studFeesApi } from './StudFeesApi'
import { trainingReportsApi } from './TrainingReportsApi'
import { usersPasswordsApi } from './Users/PasswordsApi'
import { usersRegistrationsApi } from './Users/RegistrationsApi'
import { usersSessionsApi } from './Users/SessionsApi'
import { usersApi } from './UsersApi'
import { videosApi } from './VideosApi'

export const Routes = {
  accounts: accountsApi,
  appraisals: appraisalsApi,
  bloodstockFoals: bloodstockFoalsApi,
  bloodstockInventoryMonthly: bloodstockInventoryMonthlyApi,
  bloodstockSearch: bloodstockSearchApi,
  broodmareProspectDetails: broodmareProspectDetailsApi,
  broodmareProspects: broodmareProspectsApi,
  budgetItems: budgetItemsApi,
  companyDocuments: companyDocumentsApi,
  conformationNotes: conformationNotesApi,
  covers: coversApi,
  dbtCandidateHorses: dbtCandidateHorsesApi,
  dbtCareerRaceSummaries: dbtCareerRaceSummariesApi,
  dbtEsnSummaries: dbtEsnSummariesApi,
  dbtHorses: dbtHorsesApi,
  dbtRacehorseDetails: dbtRacehorseDetailsApi,
  dbtRaceResults: dbtRaceResultsApi,
  dbtRelatives: dbtRelativesApi,
  dbtSaleResults: dbtSaleResultsApi,
  dbtSeasonDetails: dbtSeasonDetailsApi,
  deviseInvitations: deviseInvitationsApi,
  dnaSets: dnaSetsApi,
  documents: documentsApi,
  entities: entitiesApi,
  financeEntries: financeEntriesApi,
  financeMonthlyEntries: financeMonthlyEntriesApi,
  hairSampleBatches: hairSampleBatchesApi,
  hairSamples: hairSamplesApi,
  hfmWinstarClients: hfmWinstarClientsApi,
  horseDetails: horseDetailsApi,
  horses: horsesApi,
  images: imagesApi,
  matings: matingsApi,
  monthlyOwnershipsByDivisions: monthlyOwnershipsByDivisionsApi,
  organizationHorses: organizationHorsesApi,
  organizations: organizationsApi,
  pedigrees: pedigreesApi,
  physicalMeasurements: physicalMeasurementsApi,
  pregnancyChecks: pregnancyChecksApi,
  projectedPurchases: projectedPurchasesApi,
  racehorseComments: racehorseCommentsApi,
  saleRecordDetails: saleRecordDetailsApi,
  saleRecords: saleRecordsApi,
  sales: salesApi,
  seasons: seasonsApi,
  shares: sharesApi,
  shortlists: shortlistsApi,
  stallionBreedingEstimates: stallionBreedingEstimatesApi,
  stallionBreedingTargets: stallionBreedingTargetsApi,
  stallionProspects: stallionProspectsApi,
  stallions: stallionsApi,
  studFees: studFeesApi,
  trainingReports: trainingReportsApi,
  usersPasswords: usersPasswordsApi,
  usersRegistrations: usersRegistrationsApi,
  usersSessions: usersSessionsApi,
  users: usersApi,
  videos: videosApi,
}

export {
  accountsApi,
  appraisalsApi,
  bloodstockFoalsApi,
  bloodstockInventoryMonthlyApi,
  bloodstockSearchApi,
  broodmareProspectDetailsApi,
  broodmareProspectsApi,
  budgetItemsApi,
  companyDocumentsApi,
  conformationNotesApi,
  coversApi,
  dbtCandidateHorsesApi,
  dbtCareerRaceSummariesApi,
  dbtEsnSummariesApi,
  dbtHorsesApi,
  dbtRacehorseDetailsApi,
  dbtRaceResultsApi,
  dbtRelativesApi,
  dbtSaleResultsApi,
  dbtSeasonDetailsApi,
  deviseInvitationsApi,
  dnaSetsApi,
  documentsApi,
  entitiesApi,
  financeEntriesApi,
  financeMonthlyEntriesApi,
  hairSampleBatchesApi,
  hairSamplesApi,
  hfmWinstarClientsApi,
  horseDetailsApi,
  horsesApi,
  imagesApi,
  matingsApi,
  monthlyOwnershipsByDivisionsApi,
  organizationHorsesApi,
  organizationsApi,
  pedigreesApi,
  physicalMeasurementsApi,
  pregnancyChecksApi,
  projectedPurchasesApi,
  racehorseCommentsApi,
  saleRecordDetailsApi,
  saleRecordsApi,
  salesApi,
  seasonsApi,
  sharesApi,
  shortlistsApi,
  stallionBreedingEstimatesApi,
  stallionBreedingTargetsApi,
  stallionProspectsApi,
  stallionsApi,
  studFeesApi,
  trainingReportsApi,
  usersPasswordsApi,
  usersRegistrationsApi,
  usersSessionsApi,
  usersApi,
  videosApi,
}
