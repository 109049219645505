import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { CoverForm } from '@/resources/cover/form'

const CoversNew = () => {
  const { coversNew } = useLoaderQueryBuilders()
  const { record: cover } = useResourceQuery(coversNew)

  return (
    <Section title="New Cover">
      <CoverForm initialValues={cover} />
    </Section>
  )
}

export default CoversNew
