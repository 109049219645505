import React from 'react'

import { Text } from '@/components/ui/text'
import { cn, formatOrdinal } from '@/utils'

export const FinishPosition = ({ finishPosition }: { finishPosition: number }) => (
  <Text as="span" className={cn(finishPosition <= 3 && 'font-semibold text-green')}>
    {formatOrdinal(finishPosition)}
  </Text>
)
