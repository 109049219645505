import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { formatCurrency } from '@/utils'

import { StudFeeResource } from './resource'

export const StudFeeContent = ({ studFee }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <DataTile>{studFee.private ? 'Private' : `$ ${formatCurrency(studFee.price)}`}</DataTile>
        <div className="flex flex-col gap-2">
          <InlineInfoTile label={studFee.farm} icon="User" tooltipText="Farm" />
          <InlineInfoTile label={studFee.location} icon="Map" tooltipText="Location" />
        </div>
      </div>
    </div>
  )
}

const StudFeeSummaryContent = ({ studFees }) => {
  return <StudFeeContent studFee={studFees[0]} />
}

export const StudFeeCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: studFee } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={StudFeeResource.routes.index}>{studFee.title}</Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={StudFeeResource} />
        </CardActions>
      </CardHeader>
      <CardContent>
        <StudFeeContent studFee={studFee} />
      </CardContent>
    </Card>
  )
}
export const StudFeeSummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: studFees, error, isPending } = useResourceQuery(query)
  const pluralName = 'Stud Fees'

  return (
    <SummaryCard
      actions={<NewAction resource={StudFeeResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={StudFeeResource.routes.index}
    >
      {studFees.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <StudFeeSummaryContent studFees={studFees} />
      )}
    </SummaryCard>
  )
}
