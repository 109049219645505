import React from 'react'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command'
import { cn } from '@/utils'
import { CheckIcon } from '@radix-ui/react-icons'

export const SelectFilterControl = ({ label, options, value, onChange }) => {
  const selectedValue = value ? new Set([value]) : new Set()

  return (
    <Command>
      <CommandInput placeholder={label} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {options.map((option) => {
            const isSelected = selectedValue.has(option.value)
            return (
              <CommandItem
                key={option.value}
                onSelect={() => {
                  const newValue = isSelected ? undefined : option.value
                  onChange(newValue)
                }}
              >
                <div
                  className={cn(
                    'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                    isSelected
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <CheckIcon className={cn('size-4')} />
                </div>
                {option.icon && <option.icon className="mr-2 size-4 text-muted-foreground" />}
                <span>{option.label}</span>
                {option.count && (
                  <span className="ml-auto flex size-4 items-center justify-center font-mono text-xs">
                    {option.count}
                  </span>
                )}
              </CommandItem>
            )
          })}
        </CommandGroup>
        {selectedValue.size > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem
                onSelect={() => onChange(undefined)}
                className="justify-center text-center"
              >
                Clear filter
              </CommandItem>
            </CommandGroup>
          </>
        )}
      </CommandList>
    </Command>
  )
}
