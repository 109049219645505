/*
 * TODO Implement Index Page for bloodstock/broodmares
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function BroodmaresIndex() {
  return (
    <>
      <PageHeader title="Broodmares" />
    </>
  )
}

export default BroodmaresIndex
