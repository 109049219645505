/*
 * TODO Implement New Page for users/passwords
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function PasswordsNew() {
  return <PageHeader title="Users::Passwords New" />
}

export default PasswordsNew
