import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { UserForm } from '@/resources/user/form'

const UsersNew = () => {
  const { usersNew } = useLoaderQueryBuilders()
  const { record: user } = useResourceQuery(usersNew)

  return (
    <Section title="New User">
      <UserForm initialValues={user} />
    </Section>
  )
}

export default UsersNew
