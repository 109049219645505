import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { EntityForm } from '@/resources/entity/form'

const EntitiesEdit = () => {
  const { entitiesEdit } = useLoaderQueryBuilders()
  const { record: entity } = useResourceQuery(entitiesEdit)

  return (
    <Section title={`Edit ${entity.title}`}>
      <EntityForm initialValues={entity} />
    </Section>
  )
}

export default EntitiesEdit
