import React from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'

import { Loader, Trash } from '../icons'

const FormDelete = ({ resource, handleDelete, isDeleting }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button type="button" variant="destructive" iconLeft={<Trash />}>
          Delete
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {`This cannot be undone. This will permanetly delete the ${resource.name}`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction asChild>
            {isDeleting ? (
              <Button
                disabled
                variant="desctructive"
                form={`${resource.name}Form`}
                iconLeft={<Loader className="animate-spin" />}
              >
                Deleting
              </Button>
            ) : (
              <Button
                type="button"
                variant="destructive"
                onClick={handleDelete}
                iconLeft={<Trash />}
              >
                Delete
              </Button>
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export { FormDelete }
