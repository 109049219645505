// JsFromRoutes CacheKey ee6913f077ed48351a28285f0b6a41c7
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const stallionProspectsApi = {
  index: definePathHelper('get', '/api/stallion_prospects'),
  create: definePathHelper('post', '/api/stallion_prospects'),
  new: definePathHelper('get', '/api/stallion_prospects/new'),
  edit: definePathHelper('get', '/api/stallion_prospects/:id/edit'),
  show: definePathHelper('get', '/api/stallion_prospects/:id'),
  update: definePathHelper('patch', '/api/stallion_prospects/:id'),
  destroy: definePathHelper('delete', '/api/stallion_prospects/:id'),
}
