import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { DocumentForm } from '@/resources/document/form'

const DocumentsNew = () => {
  const { documentsNew } = useLoaderQueryBuilders()
  const { record: document } = useResourceQuery(documentsNew)

  return (
    <Section title="New Document">
      <DocumentForm initialValues={document} />
    </Section>
  )
}

export default DocumentsNew
