import React from 'react'

import { cn } from '@/utils'
import { flexRender } from '@tanstack/react-table'

export const stackedFormatter =
  () =>
  ({ row, column }) => {
    const columnIds = column.columnDef.meta.columnIds ?? []

    // Building the map so that we can loop while preserving
    // columnIds order
    const cellMap = row.getAllCells().reduce((acc, cell) => {
      return columnIds.includes(cell.column.id) ? { ...acc, [cell.column.id]: cell } : acc
    }, {})

    return (
      <div className="flex flex-col justify-between">
        {columnIds.map((columnId, idx) => {
          const cell = cellMap[columnId]
          if (cell == null) {
            console.warn(`ColumnId '${columnId}' is not in cells`)
          }

          return (
            <div
              key={columnId}
              className={cn('flex-1', idx !== 0 && 'truncate text-muted-foreground')}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          )
        })}
      </div>
    )
  }
