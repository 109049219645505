import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { User } from '@/types'

const name = 'User'

const recordNameSingular = 'user'
const recordNamePlural = 'users'
const i18nKey = 'user'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'users'

const pageHelperKey = 'users'

const resourcePathName = 'user'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  tableauUser: {
    kind: 'association',
    name: 'tableauUser',
    modelName: 'User',
    foreignKey: 'tableauUserId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  email: {
    kind: 'column',
    name: 'email',
    type: 'string',
  },
  encryptedPassword: {
    kind: 'column',
    name: 'encryptedPassword',
    type: 'string',
  },
  resetPasswordToken: {
    kind: 'column',
    name: 'resetPasswordToken',
    type: 'string',
  },
  resetPasswordSentAt: {
    kind: 'column',
    name: 'resetPasswordSentAt',
    type: 'datetime',
  },
  rememberCreatedAt: {
    kind: 'column',
    name: 'rememberCreatedAt',
    type: 'datetime',
  },
  signInCount: {
    kind: 'column',
    name: 'signInCount',
    type: 'integer',
  },
  currentSignInAt: {
    kind: 'column',
    name: 'currentSignInAt',
    type: 'datetime',
  },
  lastSignInAt: {
    kind: 'column',
    name: 'lastSignInAt',
    type: 'datetime',
  },
  currentSignInIp: {
    kind: 'column',
    name: 'currentSignInIp',
    type: 'inet',
  },
  lastSignInIp: {
    kind: 'column',
    name: 'lastSignInIp',
    type: 'inet',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  firstName: {
    kind: 'column',
    name: 'firstName',
    type: 'string',
  },
  lastName: {
    kind: 'column',
    name: 'lastName',
    type: 'string',
  },
  invitationToken: {
    kind: 'column',
    name: 'invitationToken',
    type: 'string',
  },
  invitationCreatedAt: {
    kind: 'column',
    name: 'invitationCreatedAt',
    type: 'datetime',
  },
  invitationSentAt: {
    kind: 'column',
    name: 'invitationSentAt',
    type: 'datetime',
  },
  invitationAcceptedAt: {
    kind: 'column',
    name: 'invitationAcceptedAt',
    type: 'datetime',
  },
  invitationLimit: {
    kind: 'column',
    name: 'invitationLimit',
    type: 'integer',
  },
  invitedById: {
    kind: 'column',
    name: 'invitedById',
    type: 'integer',
  },
  invitedByType: {
    kind: 'column',
    name: 'invitedByType',
    type: 'string',
  },
  jti: {
    kind: 'column',
    name: 'jti',
    type: 'string',
  },
  apiKey: {
    kind: 'column',
    name: 'apiKey',
    type: 'string',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
  userType: {
    kind: 'column',
    name: 'userType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'normal',
        },
        {
          value: 1,
          key: 'tableau',
        },
        {
          value: 2,
          key: 'dropbox',
        },
      ],
    },
  },
  tableauUserId: {
    kind: 'column',
    name: 'tableauUserId',
    type: 'integer',
  },
  userToken: {
    kind: 'column',
    name: 'userToken',
    type: 'string',
  },
  defaultPedigreeReportType: {
    kind: 'column',
    name: 'defaultPedigreeReportType',
    type: 'enum',
    subtype: 'enum',
    domain: {
      values: [
        {
          value: 'wi',
          key: 'wi',
        },
        {
          value: 'wi_with_sales',
          key: 'wi_with_sales',
        },
        {
          value: 'unkown',
          key: 'unknown',
        },
      ],
    },
  },
}

const modelSpec: ModelSpec<User> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
