import { compact } from '@/utils/array'

import { deepMerge } from './object'

type PathSegment = string
type Path = string

export function extractPath(obj: Record<string, unknown>, path: Path) {
  const objPath = compact(path.split('.')).filter((elem) => elem !== '')

  return objPath.reduce((result, pathPart) => {
    if (result == null || typeof result !== 'object') {
      return result
    }

    return result[pathPart]
  }, obj)
}

export function cleanPathSegments(...parts: PathSegment[]) {
  return parts.filter((part) => part && part !== '' && part !== '.')
}

export function constructPath(...parts: PathSegment[]) {
  const built = cleanPathSegments(...parts).join('.')

  return built === '' ? '.' : built
}

export const pathToPathSegments = (path: Path) => cleanPathSegments(...path.split('.'))

/*
 * Create a object with nested keys from single dot notation keys
 * { 'a.b.c': 1 } => { a: { b : { c: 1 } } }
 */
export function expand(obj: Record<Path, unknown>) {
  return Object.keys(obj).reduce((acc, key) => {
    const keys = cleanPathSegments(...key.split('.')).reverse()

    const expanded = keys.reduce((acc, key) => ({ [key]: acc }), obj[key])

    return deepMerge(acc, expanded)
  }, {})
}

export function removePrefix(path: Path, prefix: Path) {
  const prefixSegments = pathToPathSegments(prefix)
  const pathSegments = pathToPathSegments(path)

  if (prefixSegments.length > pathSegments.length) {
    return path
  }

  const isMatch = prefixSegments.reduce((acc, seg, idx) => acc && seg === pathSegments[idx], true)

  return isMatch ? constructPath(...pathSegments.slice(prefixSegments.length)) : path
}
