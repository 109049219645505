import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const MonthlyOwnershipsByDivisionsIndex = () => {
  const { monthlyOwnershipsByDivisionsIndex } = useLoaderQueryBuilders()
  const monthlyOwnershipsByDivisions = useResourceQuery(monthlyOwnershipsByDivisionsIndex)

  return <Section title="Monthly ownerships by divisions" className="mt-16"></Section>
}

export default MonthlyOwnershipsByDivisionsIndex
