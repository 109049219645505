// JsFromRoutes CacheKey caaf3da203dab0835fbfcc42c71afdd6
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const hairSampleBatchesApi = {
  index: definePathHelper('get', '/api/hair_sample_batches'),
  create: definePathHelper('post', '/api/hair_sample_batches'),
  new: definePathHelper('get', '/api/hair_sample_batches/new'),
  edit: definePathHelper('get', '/api/hair_sample_batches/:id/edit'),
  show: definePathHelper('get', '/api/hair_sample_batches/:id'),
  update: definePathHelper('patch', '/api/hair_sample_batches/:id'),
  destroy: definePathHelper('delete', '/api/hair_sample_batches/:id'),
}
