import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import Icon, { Loader } from '@/components/icon'
import { InfoTile } from '@/components/info_tile'
import { Card, CardContent, CardTitle } from '@/components/ui/card'
import { Currency, Text } from '@/components/ui/text'
import { noopQuery } from '@/libs/query/builder'
import { AmountField } from '@/resources/season'
import { StudFeeResource } from '@/resources/stud_fee'
import { MatingSchema } from '@/schemas/mating'
import { Mating } from '@/types'
import { cn } from '@/utils'

import { MatingResource } from './resource'

type MatingFormProps = Omit<UseFormProps<Mating>, 'resource'>

const MatingForm = ({ zodSchema = MatingSchema, ...props }: MatingFormProps) => {
  const { year } = useParams()

  const formConfig = useForm<Mating>({
    resource: MatingResource,
    zodSchema,
    ...props,
  })

  const selectedStallionId = formConfig.form.watch('stallionId')

  return (
    <div className="flex sm:min-w-[32rem]">
      <Form {...formConfig}>
        <Card>
          <CardContent>
            <div className="flex justify-between gap-3">
              <Field name="mare" />
              <Field
                name="stallion"
                resource={StudFeeResource}
                valueAttributeName="stallionId"
                recordQuery={
                  selectedStallionId
                    ? StudFeeResource.query.index
                        .filter('year', year)
                        .filter('stallionId', selectedStallionId)
                        .pageSize(1)
                    : noopQuery
                }
                query={StudFeeResource.query.index.scope('winstar', year)}
                onChange={(value) => {
                  if (!value) formConfig.form.setValue('amount', null)
                }}
                clientSide
                renderItem={({ suggestion, isSelected }) => (
                  <div className="flex w-full gap-0.5">
                    <div className="w-4 py-1">
                      {isSelected && <Icon name="Check" className="self-center" />}
                    </div>
                    <div className="flex w-full justify-between">
                      <Text as="span" size="md" className={cn('font-serif font-semibold')}>
                        {suggestion.stallion?.name}
                      </Text>
                      <Currency value={suggestion.price} className="w-20" />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="flex justify-end">
              {selectedStallionId && (
                <InfoTile
                  label={`${year} Stud Fee`}
                  className="h-20 w-[calc(50%-0.5rem)] rounded-md"
                  icon={
                    <Suspense fallback={<Loader className="h-9 animate-spin" />}>
                      <AmountField stallionId={selectedStallionId} year={year} {...formConfig} />
                    </Suspense>
                  }
                />
              )}
            </div>
            <div className="flex justify-between gap-3">
              <Field name="year" />
              <InfoTile
                label="Final"
                muted={false}
                className="mt-6 h-9 rounded-md"
                icon={
                  <Field
                    name="final"
                    hideLabel={true}
                    className="flex w-28 justify-end self-center"
                  />
                }
              />
            </div>
            {/* <Field name="organization" /> */}
            <Field name="rating" />
            <Field name="account" />
          </CardContent>
        </Card>
        <Card>
          <div className="flex justify-between">
            <CardTitle>Assessment</CardTitle>
            <InfoTile
              label="Approved"
              muted={false}
              className="h-9 rounded-md"
              icon={
                <Field
                  name="approved"
                  hideLabel={true}
                  className="flex w-12 justify-end self-center"
                />
              }
            />
          </div>
          Pedigree
          <div className="flex gap-3">
            <InfoTile
              label="Pass"
              muted={false}
              className="h-9 rounded-md"
              icon={
                <Field
                  name="pedigreePass"
                  hideLabel={true}
                  className="flex w-28 justify-end self-center"
                />
              }
            />
            <Field name="pedigreeNotes" hideLabel={true} largeTextArea={false} />
          </div>
          Physical
          <div className="flex gap-3">
            <InfoTile
              label="Pass"
              muted={false}
              className="h-9 rounded-md"
              icon={
                <Field
                  name="physicalPass"
                  hideLabel={true}
                  className="flex w-28 justify-end self-center"
                />
              }
            />
            <Field name="physicalNotes" hideLabel={true} largeTextArea={false} />
          </div>
          Financials
          <div className="flex gap-3">
            <InfoTile
              label="Pass"
              muted={false}
              className="h-9 rounded-md"
              icon={
                <Field
                  name="financialsPass"
                  hideLabel={true}
                  className="flex w-28 justify-end self-center"
                />
              }
            />
            <Field name="financialsNotes" hideLabel={true} largeTextArea={false} />
          </div>
          Stallion Availability
          <div className="flex gap-3">
            <InfoTile
              label="Pass"
              muted={false}
              className="h-9 rounded-md"
              icon={
                <Field
                  name="stallionAvailabilityPass"
                  hideLabel={true}
                  className="flex w-28 justify-end self-center"
                />
              }
            />
            <Field name="stallionAvailabilityNotes" hideLabel={true} largeTextArea={false} />
          </div>
          <Field name="notes" />
        </Card>
      </Form>
    </div>
  )
}

export { MatingForm }
