/*
 * TODO Implement Index Page for bloodstock/crops
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function CropsIndex() {
  return (
    <>
      <PageHeader title="Crops" />
    </>
  )
}

export default CropsIndex
