import { defineTable } from '@/components/data-table/builder'

import { FinanceVendorResource } from './resource'

export const financeVendorTable = defineTable(FinanceVendorResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.vendorId(),
    tbl.col.name(),
    tbl.col.name1099(),
    tbl.col.billingType(),
    tbl.col.vendType(),
    tbl.col.status(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.megaEntityKey(),
    tbl.col.megaEntityId(),
    tbl.col.megaEntityName(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
  ],
}))
