import { createRoutes } from '@/libs/router/route'
import { BroodmareProspectRoutes } from '@/resources/broodmare_prospect/routes'

export const BroodmaresRoutes = createRoutes({
  routes: [
    {
      path: 'broodmares',
      children: [
        {
          path: 'claiming',
          page: 'broodmares/claiming',
          children: [BroodmareProspectRoutes.asSubRoute({ layoutType: 'sheet' })],
        },
      ],
    },
  ],
})
