// JsFromRoutes CacheKey 6c03031204a080a22b6c80ff68f81e04
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const budgetItemsApi = {
  index: definePathHelper('get', '/api/budget_items'),
  create: definePathHelper('post', '/api/budget_items'),
  new: definePathHelper('get', '/api/budget_items/new'),
  edit: definePathHelper('get', '/api/budget_items/:id/edit'),
  show: definePathHelper('get', '/api/budget_items/:id'),
  update: definePathHelper('patch', '/api/budget_items/:id'),
  destroy: definePathHelper('delete', '/api/budget_items/:id'),
}
