import { PanelContent } from '@/components/panel/content'
import { PanelItem } from '@/components/panel/item'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { bloodstockRoutes } from '@/routes'

export const CropsPanel = () => {
  return (
    <PanelContent title={'Crops'}>
      <SidebarGroup>
        <SidebarGroupLabel>Dashboard</SidebarGroupLabel>
        <SidebarMenu>
          <PanelItem label="All" to={bloodstockRoutes.crops()} />
          <PanelItem label="Foals" to={bloodstockRoutes.crops()} />
          <PanelItem label="Yearlings" to={bloodstockRoutes.crops()} />
        </SidebarMenu>
      </SidebarGroup>
    </PanelContent>
  )
}
