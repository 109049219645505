import numbro from 'numbro'

type CurrencyAbbreviation = 'K' | 'M' | null

export const scaleBy = (abbreviation: CurrencyAbbreviation) => {
  switch (abbreviation?.toLowerCase()) {
    case 'k':
      return 1_000
    case 'm':
      return 1_000_000
    default:
      return 1
  }
}

export const formatCurrency = (
  value: string,
  abbreviation: CurrencyAbbreviation = null,
  decimals = 0,
  showNegative = true,
) => {
  const number = Number.parseFloat(value)

  if (Number.isNaN(number)) {
    return
  }

  const scaled = number / scaleBy(abbreviation)

  const inputValue = showNegative ? scaled : Math.abs(scaled)

  const result = numbro(inputValue).format({
    mantissa: decimals,
    thousandSeparated: true,
    postfix: abbreviation?.toLowerCase() ?? '',
  })

  return result
}
