import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ModelsFpiStallionPrediction } from '@/types'

const name = 'ModelsFpiStallionPrediction'

const recordNameSingular = 'modelsFpiStallionPrediction'
const recordNamePlural = 'modelsFpiStallionPredictions'
const i18nKey = 'models::FpiStallionPrediction'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'modelsFpiStallionPredictions'

const pageHelperKey = 'modelsFpiStallionPredictions'

const resourcePathName = 'models_fpi_stallion_prediction'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  modelVersion: {
    kind: 'association',
    name: 'modelVersion',
    modelName: 'ModelsModelVersion',
    foreignKey: 'modelVersionId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  modelVersionId: {
    kind: 'column',
    name: 'modelVersionId',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  foalDayOffset: {
    kind: 'column',
    name: 'foalDayOffset',
    type: 'integer',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  nonWinner: {
    kind: 'column',
    name: 'nonWinner',
    type: 'decimal',
  },
  winner: {
    kind: 'column',
    name: 'winner',
    type: 'decimal',
  },
  stakesWinner: {
    kind: 'column',
    name: 'stakesWinner',
    type: 'decimal',
  },
  gradedStakesWinner: {
    kind: 'column',
    name: 'gradedStakesWinner',
    type: 'decimal',
  },
  ssi: {
    kind: 'column',
    name: 'ssi',
    type: 'decimal',
  },
  firstRaceAgeDays: {
    kind: 'column',
    name: 'firstRaceAgeDays',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<ModelsFpiStallionPrediction> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
