import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BudgetItem } from '@/types'

const name = 'BudgetItem'

const recordNameSingular = 'budgetItem'
const recordNamePlural = 'budgetItems'
const i18nKey = 'budgetItem'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'budgetItems'

const pageHelperKey = 'budgetItems'

const resourcePathName = 'budget_item'

const attributes: Record<string, AttributeSpec> = {
  budget: {
    kind: 'association',
    name: 'budget',
    modelName: 'Budget',
    foreignKey: 'budgetId',
    associationPrimaryKey: 'id',
  },
  location: {
    kind: 'association',
    name: 'location',
    modelName: 'IntacctLocation',
    foreignKey: 'locationId',
    associationPrimaryKey: 'locationId',
  },
  account: {
    kind: 'association',
    name: 'account',
    modelName: 'IntacctAccount',
    foreignKey: 'accountNo',
    associationPrimaryKey: 'accountNo',
  },
  department: {
    kind: 'association',
    name: 'department',
    modelName: 'IntacctDepartment',
    foreignKey: 'departmentId',
    associationPrimaryKey: 'departmentId',
  },
  sageClass: {
    kind: 'association',
    name: 'sageClass',
    modelName: 'IntacctSageClass',
    foreignKey: 'sageClassId',
    associationPrimaryKey: 'classId',
  },
  vendor: {
    kind: 'association',
    name: 'vendor',
    modelName: 'IntacctVendor',
    foreignKey: 'vendorId',
    associationPrimaryKey: 'vendorId',
  },
  customer: {
    kind: 'association',
    name: 'customer',
    modelName: 'IntacctCustomer',
    foreignKey: 'customerId',
    associationPrimaryKey: 'customerId',
  },
  employee: {
    kind: 'association',
    name: 'employee',
    modelName: 'IntacctEmployee',
    foreignKey: 'employeeId',
    associationPrimaryKey: 'employeeId',
  },
  reportingPeriod: {
    kind: 'association',
    name: 'reportingPeriod',
    modelName: 'IntacctReportingPeriod',
    foreignKey: 'reportingPeriodId',
    associationPrimaryKey: 'reportingPeriodId',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  budgetId: {
    kind: 'column',
    name: 'budgetId',
    type: 'integer',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  sageClassId: {
    kind: 'column',
    name: 'sageClassId',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  reportingPeriodId: {
    kind: 'column',
    name: 'reportingPeriodId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  generated: {
    kind: 'column',
    name: 'generated',
    type: 'boolean',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
}

const modelSpec: ModelSpec<BudgetItem> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
