import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BrisnetReport782Dam } from '@/types'

const name = 'BrisnetReport782Dam'

const recordNameSingular = 'brisnetReport782Dam'
const recordNamePlural = 'brisnetReport782Dams'
const i18nKey = 'brisnet::Report782Dam'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'brisnetReport782Dams'

const pageHelperKey = 'brisnetReport782Dams'

const resourcePathName = 'brisnet_report782_dam'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'citext',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'citext',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  shippingStatus: {
    kind: 'column',
    name: 'shippingStatus',
    type: 'string',
  },
  rawHorseString: {
    kind: 'column',
    name: 'rawHorseString',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<BrisnetReport782Dam> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
