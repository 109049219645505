import React from 'react'
import { type Field } from 'react-hook-form'

import { Input, type InputProps } from '@/components/ui/input'
import { useDirectUpload } from '@docflow/react-activestorage-provider'

interface FileInputProps extends InputProps {
  field: Field
}

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  ({ field, multiple, ...props }, ref) => {
    const { handleUpload } = useDirectUpload({
      origin: {
        host: window.location.hostname,
        port: window.location.port,
        protocol: window.location.protocol,
      },
      onSuccess: (signedIds) => {
        field.onChange(multiple ? signedIds : signedIds[0])
      },
    })

    return (
      <Input
        ref={ref}
        type="file"
        multiple={multiple}
        {...field}
        {...props}
        onChange={(event) => event.target.files && handleUpload(event.target.files)}
      />
    )
  },
)
