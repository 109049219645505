import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctBudgetItem } from '@/types'

const name = 'IntacctBudgetItem'

const recordNameSingular = 'intacctBudgetItem'
const recordNamePlural = 'intacctBudgetItems'
const i18nKey = 'intacct::BudgetItem'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctBudgetItems'

const pageHelperKey = 'intacctBudgetItems'

const resourcePathName = 'intacct_budget_item'

const attributes: Record<string, AttributeSpec> = {
  sageClass: {
    kind: 'association',
    name: 'sageClass',
    modelName: 'IntacctSageClass',
    foreignKey: 'classId',
    associationPrimaryKey: 'classId',
  },
  account: {
    kind: 'association',
    name: 'account',
    modelName: 'IntacctAccount',
    foreignKey: 'acctNo',
    associationPrimaryKey: 'accountNo',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  budgetKey: {
    kind: 'column',
    name: 'budgetKey',
    type: 'integer',
  },
  budgetId: {
    kind: 'column',
    name: 'budgetId',
    type: 'string',
  },
  accountKey: {
    kind: 'column',
    name: 'accountKey',
    type: 'integer',
  },
  acctNo: {
    kind: 'column',
    name: 'acctNo',
    type: 'string',
  },
  acctTitle: {
    kind: 'column',
    name: 'acctTitle',
    type: 'string',
  },
  deptKey: {
    kind: 'column',
    name: 'deptKey',
    type: 'integer',
  },
  deptNo: {
    kind: 'column',
    name: 'deptNo',
    type: 'string',
  },
  deptTitle: {
    kind: 'column',
    name: 'deptTitle',
    type: 'string',
  },
  locationKey: {
    kind: 'column',
    name: 'locationKey',
    type: 'integer',
  },
  locationNo: {
    kind: 'column',
    name: 'locationNo',
    type: 'string',
  },
  locationTitle: {
    kind: 'column',
    name: 'locationTitle',
    type: 'string',
  },
  periodKey: {
    kind: 'column',
    name: 'periodKey',
    type: 'integer',
  },
  periodName: {
    kind: 'column',
    name: 'periodName',
    type: 'string',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'string',
  },
  basedOn: {
    kind: 'column',
    name: 'basedOn',
    type: 'string',
  },
  growBy: {
    kind: 'column',
    name: 'growBy',
    type: 'decimal',
  },
  periodId: {
    kind: 'column',
    name: 'periodId',
    type: 'string',
  },
  pStartDate: {
    kind: 'column',
    name: 'pStartDate',
    type: 'date',
  },
  pEndDate: {
    kind: 'column',
    name: 'pEndDate',
    type: 'date',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  normalBalance: {
    kind: 'column',
    name: 'normalBalance',
    type: 'integer',
  },
  currency: {
    kind: 'column',
    name: 'currency',
    type: 'string',
  },
  systemGenerated: {
    kind: 'column',
    name: 'systemGenerated',
    type: 'string',
  },
  accountType: {
    kind: 'column',
    name: 'accountType',
    type: 'string',
  },
  statistical: {
    kind: 'column',
    name: 'statistical',
    type: 'string',
  },
  reportingPeriodName: {
    kind: 'column',
    name: 'reportingPeriodName',
    type: 'string',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  customerName: {
    kind: 'column',
    name: 'customerName',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  vendorName: {
    kind: 'column',
    name: 'vendorName',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  employeeName: {
    kind: 'column',
    name: 'employeeName',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  className: {
    kind: 'column',
    name: 'className',
    type: 'string',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctBudgetItem> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
