// JsFromRoutes CacheKey 08971fbe7951024580c875695a91465b
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { SeasonModel } from '@/models/season'

export const seasonsApi = {
  index: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'index',
    model: SeasonModel,
    parts: ['api', 'seasons'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'create',
    model: SeasonModel,
    parts: ['api', 'seasons'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'new',
    model: SeasonModel,
    parts: ['api', 'seasons', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'edit',
    model: SeasonModel,
    parts: ['api', 'seasons', { name: 'id', attributeId: 'Season:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'show',
    model: SeasonModel,
    parts: ['api', 'seasons', { name: 'id', attributeId: 'Season:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'update',
    model: SeasonModel,
    parts: ['api', 'seasons', { name: 'id', attributeId: 'Season:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'seasons',
    actionName: 'destroy',
    model: SeasonModel,
    parts: ['api', 'seasons', { name: 'id', attributeId: 'Season:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
