import { type UIMatch } from 'react-router-dom'

import { modelNameFromAttributeId } from '@/libs/resource/attribute'
import { type ModelSpecName } from '@/libs/resource/names'
import { compact } from '@/utils'

import { ResourceRecord } from '../resource/record'
import {
  AttributeFrame,
  BuildFrameProps,
  ContextFrame,
  buildFrame,
  compareFrames,
  isValidFrame,
} from './frame'

export interface ContextStack {
  current: ContextFrame
  frames?: ContextFrame[]
}

export const isValidStack = (stack?: ContextStack) =>
  stack != null &&
  isValidFrame(stack.current) &&
  (stack.frames == null || Array.isArray(stack.frames))

export interface PushFrameProps {
  frame: ContextFrame
  stack?: ContextStack
}

export const pushFrame = ({ frame, stack }: PushFrameProps) => {
  if (!isValidFrame(frame)) {
    return stack
  }

  if (stack == null) {
    return { current: frame }
  }

  const { current, frames = [] } = stack

  // If the new frame is the same as, or less specific than current,
  // return the existing stack.
  switch (compareFrames(frame, current)) {
    // If orthogonal, push
    case 'orthogonal':
      return {
        current: frame,
        frames: [current, ...frames],
      }

    // If redundant, return current stack
    case 'redundant':
      return stack

    // If supersedes, replace current
    case 'supersedes':
      return {
        current: frame,
        frames,
      }

    default:
      throw new Error('Reached impossible default condition in `pushFrame`')
  }
}

export interface MergeStacksProps {
  newStack?: ContextStack
  oldStack?: ContextStack
}

export const mergeStacks = ({ newStack, oldStack }: MergeStacksProps) => {
  // Return undefined when both stacks invalid
  if (!isValidStack(newStack) && !isValidStack(oldStack)) return

  // Is new stack valid?
  if (!isValidStack(newStack)) {
    return oldStack
  }

  // Is old stack valid?
  if (!isValidStack(oldStack)) {
    return newStack
  }

  // Does new stack have a current frame?
  if (newStack.frames == null) {
    return pushFrame({ frame: newStack.current, stack: oldStack })
  }

  if (newStack.current != null) {
    return newStack
  }

  return pushFrame({
    frame: oldStack.current,
    stack: newStack,
  })
}

export const findAttributeFrame = ({
  stack,
  attributeId,
}: {
  stack?: ContextStack
  attributeId: string
}): AttributeFrame | undefined => {
  if (!stack) {
    return
  }

  const { current, frames = [] } = stack

  const options = current ? [current, ...frames] : frames

  return options.find(
    (frame): frame is AttributeFrame =>
      frame.kind === 'attribute' && frame.attributeId === attributeId,
  )
}

export const findModelFrame = ({
  stack,
  name,
}: {
  stack?: ContextStack
  name: ModelSpecName
}): AttributeFrame | undefined => {
  if (!stack) {
    return
  }

  const { current, frames = [] } = stack

  return [current, ...frames].find(
    (frame): frame is AttributeFrame => frame.kind === 'model' && frame.name === name,
  )
}

export const findFrameMatchingModel = ({
  stack,
  name,
}: {
  stack: ContextStack
  name: ModelSpecName
}) => {
  const all = Array.isArray(stack.frames) ? [stack.current, ...stack.frames] : [stack.current]

  return all.find(
    (frame) =>
      (frame.kind === 'model' && frame.name === name) ||
      (frame.kind === 'attribute' && modelNameFromAttributeId(frame.attributeId) === name),
  )
}

export const extractStackFromRoutes = (routeMatches: UIMatch[]) => {
  const routeParts = routeMatches.flatMap((match: UIMatch) => match.handle?.routePart ?? [])

  const [currentRoute, ...rest] = routeMatches.reverse()

  const { params = {} } = currentRoute

  const frames = routeParts.reverse().map((routePart: RoutePart) => {
    const { attributeId, name } = routePart

    return buildFrame({ attributeId, value: params[name] })
  })

  const [current, ...stack] = compact(frames)

  if (current == null) return

  return { current, frames: stack.length === 0 ? undefined : stack }
}

export type ConstructAndMergeStacksProps<T extends ResourceRecord> = BuildFrameProps<T> & {
  frames?: ContextFrame[]
  currentStack?: ContextStack
}

export const constructAndMergeStacks = <T extends ResourceRecord>({
  currentStack,
  ...newStackProps
}: ConstructAndMergeStacksProps<T>) => {
  const { frames, ...newFrameProps } = newStackProps

  const newFrame = buildFrame(newFrameProps)

  if (!isValidFrame(newFrame)) return currentStack

  return mergeStacks({
    oldStack: currentStack,
    newStack: { current: newFrame, frames: frames },
  })
}
