import React from 'react'

import { HorseImage } from '@/components/horse_image'
import { Link } from '@/components/link'
import { Text } from '@/components/ui/text'
import { HorseResource, HorseTags } from '@/resources/horse'
import { Horse } from '@/types'
import { cn } from '@/utils'

import { SidebarMenuItem } from '../ui/sidebar'

interface HorseSearchResult {
  horse: Horse
  className?: string
}

const HorseSearchItem = ({ horse, className }: HorseSearchResult) => {
  return (
    <SidebarMenuItem>
      <Link
        route={HorseResource.routes.show.withOptions({
          horseId: horse.id,
          relative: false,
        })}
        variant="inline"
        noChevron
        className={cn('flex items-center gap-4 border p-2', className)}
      >
        <div className="hidden self-start 2xl:inline-flex">
          <HorseImage size="xs" behavior="cover" image={horse.defaultImage} />
        </div>
        <div className={'flex flex-1 flex-col'}>
          <div className="-mt-1.5 flex gap-2">
            <span className={cn('leadning-none font-serif text-xl font-semibold')}>
              {horse.name}
            </span>
            <span
              className={cn(
                'size-2 self-center rounded-full',
                horse.bloodstock && 'bg-grey-500',
                horse.bloodstockActive && 'bg-emerald-600',
              )}
            />
          </div>
          <Text as="span" size="xs" className={cn('mb-2 w-full font-sans')}>
            {horse.sireName} • {horse.damName}
          </Text>
          <HorseTags className="px-2.5" horse={horse} />
        </div>
      </Link>
    </SidebarMenuItem>
  )
}

export { HorseSearchItem }
