import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { VideoSchema } from '@/schemas/video'
import { Video } from '@/types'

import { VideoResource } from './resource'

type VideoFormProps = Omit<UseFormProps<Video>, 'resource'>

const VideoForm = ({ zodSchema = VideoSchema, ...props }: VideoFormProps) => {
  const formConfig = useForm<Video>({
    resource: VideoResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="date" />
          <Field name="videoType" />
          <Field name="file" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { VideoForm }
