import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { TargetBreeding } from '@/types'

const name = 'TargetBreeding'

const recordNameSingular = 'targetBreeding'
const recordNamePlural = 'targetBreedings'
const i18nKey = 'targetBreeding'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'targetBreedings'

const pageHelperKey = 'targetBreedings'

const resourcePathName = 'target_breeding'

const attributes: Record<string, AttributeSpec> = {
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  booked: {
    kind: 'column',
    name: 'booked',
    type: 'date',
  },
  submitted: {
    kind: 'column',
    name: 'submitted',
    type: 'date',
  },
  removed: {
    kind: 'column',
    name: 'removed',
    type: 'date',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  required: {
    kind: 'column',
    name: 'required',
    type: 'boolean',
  },
  shareStatus: {
    kind: 'column',
    name: 'shareStatus',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'stands_and_nurses_season',
        },
        {
          value: 1,
          key: 'no_guarantee_season',
        },
        {
          value: 2,
          key: 'x_season',
        },
        {
          value: 3,
          key: 'x_mare_share_foal_share',
        },
        {
          value: 4,
          key: 'breeding_right',
        },
        {
          value: 5,
          key: 'shareholder_usage',
        },
        {
          value: 6,
          key: 'x_complimentary_donated',
        },
        {
          value: 7,
          key: 'pay_from_proceeds',
        },
        {
          value: 8,
          key: 'mare_share',
        },
        {
          value: 9,
          key: 'foal_share',
        },
        {
          value: 10,
          key: 'complimentary',
        },
        {
          value: 11,
          key: 'donated',
        },
      ],
    },
  },
  share: {
    kind: 'column',
    name: 'share',
    type: 'string',
  },
  lastDateBred: {
    kind: 'column',
    name: 'lastDateBred',
    type: 'date',
  },
  terms: {
    kind: 'column',
    name: 'terms',
    type: 'integer',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'integer',
  },
  owner: {
    kind: 'column',
    name: 'owner',
    type: 'string',
  },
  contactDate: {
    kind: 'column',
    name: 'contactDate',
    type: 'date',
  },
  followUpDate: {
    kind: 'column',
    name: 'followUpDate',
    type: 'date',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'lost',
        },
        {
          value: 1,
          key: 'pending',
        },
        {
          value: 2,
          key: 'won',
        },
      ],
    },
  },
  startingStatus: {
    kind: 'column',
    name: 'startingStatus',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'pregnant',
        },
        {
          value: 1,
          key: 'barren',
        },
        {
          value: 2,
          key: 'maiden',
        },
        {
          value: 3,
          key: 'not_bred',
        },
        {
          value: 4,
          key: 'slipped_aborted',
        },
        {
          value: 5,
          key: 'has_foaled',
        },
      ],
    },
  },
  contractStatus: {
    kind: 'column',
    name: 'contractStatus',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'approved',
        },
        {
          value: 1,
          key: 'x_sent',
        },
        {
          value: 2,
          key: 'received',
        },
        {
          value: 3,
          key: 'declined',
        },
        {
          value: 4,
          key: 'hold',
        },
        {
          value: 5,
          key: 'canceled_by_client',
        },
        {
          value: 6,
          key: 'review',
        },
        {
          value: 7,
          key: 'sent_to_client',
        },
        {
          value: 8,
          key: 'sent_to_admin',
        },
        {
          value: 9,
          key: 'admin_issue',
        },
        {
          value: 10,
          key: 'stallion_changed',
        },
      ],
    },
  },
  agent: {
    kind: 'column',
    name: 'agent',
    type: 'string',
  },
  contractDeliveryInstructions: {
    kind: 'column',
    name: 'contractDeliveryInstructions',
    type: 'text',
  },
}

const modelSpec: ModelSpec<TargetBreeding> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
