import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { InfoTile } from '@/components/info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Tile } from '@/components/tile'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tag } from '@/components/ui/tag'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { TrainingReport } from '@/types'
import { formatDate, cn } from '@/utils'

import { TrainingReportResource } from './resource'

const TargetRace = ({
  targetRace,
  targetRaceDate,
  targetRaceTrack,
}: {
  targetRace?: string
  targetRaceDate?: string
  targetRaceTrack?: string
}) => {
  return (
    <Tile
      className="col-span-12 row-span-3 @2xl:col-span-6 @2xl:row-span-2 tablet:col-span-12 phone:row-span-4"
      label="Target Race"
    >
      <div className="grid auto-rows-4rem grid-cols-12 gap-2">
        <InfoTile className="col-span-6 @2xl:col-span-4" label="Race" variant="inline">
          {targetRace}
        </InfoTile>
        <InfoTile className="col-span-6 @2xl:order-1 @2xl:col-span-3" label="Date" variant="inline">
          {targetRaceDate ? formatDate(targetRaceDate) : '-'}
        </InfoTile>
        <InfoTile className="col-span-12 @2xl:col-span-5" label="Track" variant="inline">
          {targetRaceTrack ?? '-'}
        </InfoTile>
      </div>
    </Tile>
  )
}

export const TrainingReportContent = ({
  trainingReport,
  inline = false,
  muted = false,
}: {
  trainingReport: TrainingReport
  inline?: boolean
  muted?: boolean
}) => {
  return (
    <div className="col-span-12 flex @container">
      {trainingReport ? (
        <div className="grid w-full auto-rows-4rem grid-cols-12 gap-2 py-0">
          <InfoTile
            className="col-span-12 @2xl:col-span-2 2xl:col-span-6"
            label="Date"
            muted={muted}
          >
            {formatDate(trainingReport.date)}
          </InfoTile>
          <InfoTile
            className="col-span-12 @2xl:order-1 @2xl:col-span-2 2xl:col-span-6"
            label={'Trainer'}
            muted={muted}
          >
            {trainingReport.trainer || '-'}
          </InfoTile>
          <DataTile
            className="col-span-12 row-span-2 justify-start bg-grey-100 @2xl:col-span-4"
            label="Comment"
            variant="ghost"
            size="md"
            icon={<TrainingStatusTag status={trainingReport.status} />}
            muted={muted}
          >
            <span className="line-clamp-3">{trainingReport.comment}</span>
          </DataTile>
          <TargetRace
            targetRace={trainingReport.targetRace}
            targetRaceDate={trainingReport.targetRaceDate}
            inline={inline}
          />
        </div>
      ) : (
        <Text as="span" size="lg" className="text-center font-normal">
          No training reports on record
        </Text>
      )}
    </div>
  )
}

export const TrainingReportObject = ({ trainingReport, ...props }) => {
  return (
    <Card {...props}>
      <TrainingReportContent trainingReport={trainingReport} {...props} />
    </Card>
  )
}

const TrainingReportSummaryContent = ({ trainingReports }) => {
  return <TrainingReportContent trainingReport={trainingReports[0]} />
}

export const TrainingReportCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: trainingReport } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={TrainingReportResource.routes.index}>{trainingReport.title}</Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={TrainingReportResource} iconOnly />
        </CardActions>
      </CardHeader>
      <CardContent>
        <TrainingReportContent trainingReport={trainingReport} />
      </CardContent>
    </Card>
  )
}

export const TrainingReportSummaryCard = ({
  className,
  query,
}: {
  className?: string
  query: QueryBuilder
}) => {
  const { records: trainingReports, error, isPending } = useResourceQuery(query)
  const pluralName = 'Training Reports'

  return (
    <SummaryCard
      actions={<NewAction resource={TrainingReportResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={TrainingReportResource.routes.index}
    >
      {trainingReports.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <TrainingReportSummaryContent trainingReports={trainingReports} />
      )}
    </SummaryCard>
  )
}
export const TrainingStatusTag = ({
  status,
  className,
  ...props
}: TagProps & { status: string }) => {
  switch (status) {
    case 'in_training':
      return (
        <Tag className={cn('bg-green-200 font-semibold text-white', className)} {...props}>
          Pre Training
        </Tag>
      )
    case 'pre_training':
      return (
        <Tag className={cn('bg-green font-semibold text-white', className)} {...props}>
          In Training
        </Tag>
      )
    case 'breaking':
      return (
        <Tag className={cn('bg-yellow font-semibold capitalize text-black', className)} {...props}>
          {status}
        </Tag>
      )
    case 'rehab':
      return (
        <Tag className={cn('bg-red-500 font-semibold capitalize text-white', className)} {...props}>
          {status}
        </Tag>
      )
    case 'layup':
      return (
        <Tag
          className={cn('bg-blue-500 font-semibold capitalize text-white', className)}
          {...props}
        >
          {status}
        </Tag>
      )
    case 'retire':
      return (
        <Tag
          className={cn('bg-dark-300 font-semibold capitalize text-muted', className)}
          {...props}
        >
          {status}
        </Tag>
      )
    default:
      return null
  }
}
