import React, { Suspense } from 'react'

import type { PresetsConfig } from 'tailwindcss/types/config'

import { DefaultErrorBoundary } from '@/components/errors'
import { Card } from '@/components/ui/card'
import { Table } from '@/components/ui/table'
import { cn } from '@/utils'

import { DataTableBody } from './body'
import { DataTableFooter } from './footer'
import { DataTableHeader } from './header'
import { DataTablePagination } from './pagination'
import { DataTableToolbar } from './toolbar'

export interface BaseDataTableProps {
  table: { options: { meta: { verticalScroll: boolean } } }
  presets: PresetsConfig
  tableActions: Record<string, unknown>
  searchColumn: string
}

export const BaseDataTable: React.FC<BaseDataTableProps> = ({
  table,
  presets,
  tableActions,
  searchColumn,
}) => {
  return (
    <>
      <DataTableToolbar
        table={table}
        presets={presets}
        actions={tableActions}
        searchColumn={searchColumn}
      />

      <Card
        className={cn(
          'mb-3 gap-0 p-2',
          table.options.meta.verticalScroll ? 'max-h-[calc(100svh-16rem)]' : '',
        )}
      >
        <DefaultErrorBoundary>
          <Table>
            <Suspense>
              <DataTableHeader table={table} />
              <DataTableBody table={table} />
              <DataTableFooter table={table} />
            </Suspense>
          </Table>
        </DefaultErrorBoundary>
      </Card>

      <DataTablePagination table={table} />
    </>
  )
}
