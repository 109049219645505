import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTReproductionStatus } from '@/types'

const name = 'DBTReproductionStatus'

const recordNameSingular = 'dbtReproductionStatus'
const recordNamePlural = 'dbtReproductionStatuses'
const i18nKey = 'dbt::ReproductionStatus'

const primaryKey = 'reproductionStatusId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtReproductionStatuses'

const pageHelperKey = 'dbtReproductionStatuses'

const resourcePathName = 'dbt_reproduction_status'

const attributes: Record<string, AttributeSpec> = {
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  pregnancyCheckId: {
    kind: 'column',
    name: 'pregnancyCheckId',
    type: 'integer',
  },
  coverId: {
    kind: 'column',
    name: 'coverId',
    type: 'text',
  },
  coveringStallionId: {
    kind: 'column',
    name: 'coveringStallionId',
    type: 'integer',
  },
  breedingYear: {
    kind: 'column',
    name: 'breedingYear',
    type: 'decimal',
  },
  breedingYearRk: {
    kind: 'column',
    name: 'breedingYearRk',
    type: 'decimal',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'integer',
  },
  coverDate: {
    kind: 'column',
    name: 'coverDate',
    type: 'datetime',
  },
  projectedCoverDate: {
    kind: 'column',
    name: 'projectedCoverDate',
    type: 'datetime',
  },
  isMaiden: {
    kind: 'column',
    name: 'isMaiden',
    type: 'boolean',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'pregnant',
        },
        {
          value: 1,
          key: 'barren',
        },
        {
          value: 2,
          key: 'slipped',
        },
        {
          value: 3,
          key: 'dead_foal',
        },
        {
          value: 4,
          key: 'foaled',
        },
        {
          value: 5,
          key: 'not_bred',
        },
        {
          value: 6,
          key: 'covered',
        },
        {
          value: 7,
          key: 'unknown',
        },
      ],
    },
  },
  foalId: {
    kind: 'column',
    name: 'foalId',
    type: 'integer',
  },
  foalingDate: {
    kind: 'column',
    name: 'foalingDate',
    type: 'date',
  },
  projectedFoalingDate: {
    kind: 'column',
    name: 'projectedFoalingDate',
    type: 'datetime',
  },
  numCovers: {
    kind: 'column',
    name: 'numCovers',
    type: 'integer',
  },
  numStallions: {
    kind: 'column',
    name: 'numStallions',
    type: 'integer',
  },
  firstCoverDate: {
    kind: 'column',
    name: 'firstCoverDate',
    type: 'datetime',
  },
  lastCoverDate: {
    kind: 'column',
    name: 'lastCoverDate',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTReproductionStatus> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
