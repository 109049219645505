import { z } from 'zod'

export const SeasonSchema = z.object({
  stallionId: z.union([z.number().int(), z.null()]).optional(),
  mareId: z.union([z.number().int(), z.null()]).optional(),
  agentHfmWinstarClientId: z.union([z.number().int(), z.null()]).optional(),
  shareId: z.union([z.number().int(), z.null()]).optional(),
  assignedUserId: z.union([z.number().int(), z.null()]).optional(),
  year: z.number().int().default(2025),
  seasonType: z
    .enum([
      'stands_and_nurses',
      'no_guarantee',
      'breeding_right',
      'shareholder_usage',
      'pay_from_proceeds',
      'mare_share',
      'foal_share',
      'complimentary',
      'donated',
    ])
    .optional(),
  target: z.boolean().default(false),
  targetStatus: z.enum(['lost', 'pending', 'won']).optional(),
  contractStatus: z
    .enum([
      'approved',
      'received',
      'declined',
      'hold',
      'canceled_by_client',
      'review',
      'sent_to_client',
      'sent_to_admin',
      'admin_issue',
      'stallion_changed',
      'wait_list',
    ])
    .optional(),
  contractDate: z.union([z.string().date(), z.null()]).optional(),
  amount: z.union([z.number().int(), z.null()]).optional(),
  contractDeliveryInstructions: z.union([z.string(), z.null()]).optional(),
  comment: z.union([z.string(), z.null()]).optional(),
  agentCommission: z.union([z.number(), z.null()]).optional(),
})
