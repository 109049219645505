import { startTransition, useMemo, useState } from 'react'

import { useResourceQuery } from '@/hooks'
import { useResource } from '@/hooks/use_resource'
import { ListQueryBuilder } from '@/libs/query/list-query'
import { ResourceRecord } from '@/libs/resource/record'
import { Resource } from '@/libs/resource/resource'
import { PrimaryKeyValue } from '@/libs/resource/types'
import { useDebounce } from '@uidotdev/usehooks'

export interface UseAutocompleteProps<T extends ResourceRecord> {
  resource: Resource<T>
  query?: ListQueryBuilder<T>
  searchTerm: string
  valueAttributeName?: string
  searchAttributeName?: string
  clientSide?: boolean
  initialSelectedId?: PrimaryKeyValue
  onSelect?: (selected?: T) => void
}

export function useAutocomplete<T extends ResourceRecord>({
  initialSelectedId,
  onSelect,
  clientSide = false,
  ...props
}: UseAutocompleteProps<T>) {
  // remove use of useResource
  const resource = useResource({ resource: props.resource, id: initialSelectedId })

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 250)
  const [open, setOpen] = useState<boolean>(false)

  const {
    recordQuery = resource.useRecord().recordQuery,
    query = resource.query.index,
    valueAttributeName = resource.model.primaryKey,
    searchAttributeName = resource.model.searchKey,
    filterFn = (query, term) => query.scope('searchBy', [searchAttributeName, term]),
  } = props

  const { record: initialSelected } = useResourceQuery(recordQuery)

  const [selected, setSelected] = useState<T | undefined>(initialSelected)

  const getName = (suggestion?: T) =>
    suggestion && searchAttributeName ? suggestion[searchAttributeName] : undefined

  const getValue = (suggestion?: T) => (suggestion ? suggestion[valueAttributeName] : undefined)

  const isSelected = (suggestion?: T): boolean =>
    suggestion && selected && getValue(suggestion) === getValue(selected)

  const createOnSelectHandler =
    ({ item, isSelected }: { item: T; isSelected: boolean }) =>
    () => {
      const newSelected = isSelected ? null : item
      setSelected(newSelected)

      onSelect && onSelect(getValue(newSelected))
      setOpen(false)
    }

  const onSearchTermChange = (value: string) => {
    clientSide
      ? setSearchTerm(value)
      : startTransition(() => {
          setSearchTerm(value)
        })
  }

  const queryOptions = useMemo(
    () =>
      clientSide
        ? query.pageSize(250)
        : filterFn(
            query.withOptions({
              query: {
                global: true,
                filter: [searchAttributeName],
                term: [debouncedSearchTerm],
              },
            }),
            debouncedSearchTerm,
          ),
    [clientSide, debouncedSearchTerm, searchAttributeName, query, filterFn],
  )

  return {
    resource,
    clientSide,
    selected,

    getName,
    getValue,
    isSelected,

    queryOptions,

    open,
    setOpen,

    searchTerm,
    setSearchTerm,
    onSearchTermChange,

    createOnSelectHandler,
  }
}
