import React from 'react'

import { Action } from '@/components/actions'
import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { DocumentObject } from '@/resources/document'
import { documentsRoutes } from '@/routes'

const DocumentsIndex = () => {
  const { documentsIndex } = useLoaderQueryBuilders()
  const documents = useResourceQuery(documentsIndex)

  if (documents.length === 0) {
    return (
      <Section title="No documents yet." className="mt-16">
        <div className="flex gap-4">
          <Action route={documentsRoutes.new} name="new" variant="primary" />
        </div>
      </Section>
    )
  }

  return (
    <Section title="Documents" className="relative my-12" flex>
      <div className="flex flex-wrap gap-4">
        {documents.map((document) => (
          <DocumentObject document={document} key={document.id} />
        ))}
      </div>
    </Section>
  )
}

export default DocumentsIndex
