import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { ConformationNoteSchema } from '@/schemas/conformation_note'
import { ConformationNote } from '@/types'

import { ConformationNoteResource } from './resource'

type ConformationNoteFormProps = Omit<UseFormProps<ConformationNote>, 'resource'>

const ConformationNoteForm = ({
  zodSchema = ConformationNoteSchema,
  ...props
}: ConformationNoteFormProps) => {
  const formConfig = useForm<ConformationNote>({
    resource: ConformationNoteResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="date" />
          <Field name="rating" />
          <Field name="comment" autoFocus />
        </CardContent>
      </Card>
    </Form>
  )
}

export { ConformationNoteForm }
