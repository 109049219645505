import { TableExpr } from 'arquero/dist/types/table/types'

import { dependentColumns } from './calculation'
import { serializeColumn } from './columns'
import { buildPeriod, generatePeriods } from './period'
import { ActualScenario, deriveScenarioForPeriod } from './scenario'
import { CashFlowColumn, Period, Scenario } from './types'

export const createColumnCalcExpression = (column: CashFlowColumn): TableExpr => {
  const columns = dependentColumns(column)

  return (df) => columns.reduce((acc, c: CashFlowColumn) => acc + (df[serializeColumn(c)] || 0), 0)
}

export interface CashFlowParams {
  year: number
  referenceDate: Date
  scenario: Scenario
}

export const monthlyForecastColumns = ({
  year,
  referenceDate,
  scenario,
}: CashFlowParams): CashFlowColumn[] => {
  const periods = generatePeriods('Month', new Date(year, 0, 1), new Date(year, 11, 31))

  const forecastScenario: Scenario = {
    type: 'Forecast',
    name: scenario.name,
    referenceDate,
  }

  return periods.map((period) => ({
    period,
    scenario: deriveScenarioForPeriod(period, forecastScenario),
    calculationType: 'Total',
  }))
}

export const yearEndColumns = ({
  year,
  referenceDate,
  scenario,
}: CashFlowParams): CashFlowColumn[] => {
  const yearPeriod = buildPeriod({
    type: 'Year',
    startDate: new Date(year, 0, 1),
  })
  const priorYearPeriod = buildPeriod({
    type: 'Year',
    startDate: new Date(year - 1, 0, 1),
  })
  const nextYearPeriod = buildPeriod({
    type: 'Year',
    startDate: new Date(year + 1, 0, 1),
  })

  return [
    // Budget
    {
      period: yearPeriod,
      scenario: {
        type: 'Budget',
        name: scenario.name,
      },
      calculationType: 'Total',
    },
    // Actual
    {
      period: yearPeriod,
      scenario: ActualScenario,
      calculationType: 'Total',
    },
    // Forecast
    {
      period: yearPeriod,
      scenario: {
        type: 'Forecast',
        name: scenario.name,
        referenceDate,
      },
      calculationType: 'Total',
    },
    // Prior Year Actual
    {
      period: priorYearPeriod,
      scenario: ActualScenario,
      calculationType: 'Total',
    },
    // Next Year Budget
    {
      period: nextYearPeriod,
      scenario: {
        type: 'Budget',
        name: scenario.name,
      },
      calculationType: 'Total',
    },
  ]
}

export const cashFlowColumnSet = ({
  year,
  referenceDate,
  scenario,
}: CashFlowParams): CashFlowColumn[] => {
  const startOfMonth = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), 1)
  const endOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0)

  const priorYearStartOfMonth = new Date(year - 1, startOfMonth.getMonth(), 1)
  const priorYearEndOfMonth = new Date(
    priorYearStartOfMonth.getFullYear(),
    priorYearStartOfMonth.getMonth() + 1,
    0,
  )

  const startOfYear = new Date(referenceDate.getFullYear(), 0, 1)
  const endOfYear = new Date(referenceDate.getFullYear(), 11, 31)

  const startOfPriorYear = new Date(year - 1, 0, 1)
  const endOfPriorYear = new Date(year - 1, 11, 31)

  const referenceDatePriorYear = new Date(
    year - 1,
    referenceDate.getMonth(),
    referenceDate.getDate(),
  )

  const startOfNextYear = new Date(year + 1, 0, 1)
  const endOfNextYear = new Date(year + 1, 11, 31)

  const monthPeriod: Period = {
    type: 'Month',
    startDate: startOfMonth,
    endDate: endOfMonth,
  }

  const priorYearMonthPeriod: Period = {
    type: 'Month',
    startDate: priorYearStartOfMonth,
    endDate: priorYearEndOfMonth,
  }

  const yearToDatePeriod: Period = {
    type: 'Year',
    startDate: startOfYear,
    endDate: referenceDate,
  }

  const priorYearToDatePeriod: Period = {
    type: 'Year',
    startDate: startOfPriorYear,
    endDate: referenceDatePriorYear,
  }

  const nextYearPeriod: Period = {
    type: 'Year',
    startDate: startOfNextYear,
    endDate: endOfNextYear,
  }

  const monthToDateColumns: CashFlowColumn[] = [
    // Budget
    // Actual
    // Prior Year Actual
  ]

  const monthEndColumns: CashFlowColumn[] = [
    // Budget
    {
      period: monthPeriod,
      scenario: budgetScenario,
      calculationType: 'Total',
    },
    // Actual
    {
      period: monthPeriod,
      scenario: actualScenario,
      calculationType: 'Total',
    },
    // Forecast
    {
      period: monthPeriod,
      scenario: {
        type: 'Forecast',
        name: scenario.name,
        referenceDate,
      },
      calculationType: 'Total',
    },
    // Prior Year
    {
      period: priorYearMonthPeriod,
      scenario: actualScenario,
      calculationType: 'Total',
    },
  ]

  const yearToDateColumns: CashFlowColumn[] = [
    // Budget
    {
      period: yearToDatePeriod,
      scenario: budgetScenario,
      calculationType: 'Total',
    },
    // Actual
    {
      period: yearToDatePeriod,
      scenario: actualScenario,
      calculationType: 'Total',
    },
    // Prior Year
    {
      period: priorYearToDatePeriod,
      scenario: actualScenario,
      calculationType: 'Total',
    },
  ]

  // return [
  //   ...monthToDateColumns,
  //   ...monthEndColumns,
  //   ...yearToDateColumns,
  //   ...yearEndColumns,
  // ];
  //
  return [yearEndColumns[0]]
}
