// JsFromRoutes CacheKey 84af56d342788eb0bd3e818d8cc7feb1
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { MonthlyOwnershipsByDivisionModel } from '@/models/monthly_ownerships_by_division'

export const monthlyOwnershipsByDivisionsApi = {
  index: defineEndpoint({
    controllerName: 'monthly_ownerships_by_divisions',
    actionName: 'index',
    model: MonthlyOwnershipsByDivisionModel,
    parts: ['api', 'monthly_ownerships_by_divisions'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'monthly_ownerships_by_divisions',
    actionName: 'show',
    model: MonthlyOwnershipsByDivisionModel,
    parts: [
      'api',
      'monthly_ownerships_by_divisions',
      { name: 'monthlyOwnershipsByDivisionId', attributeId: null },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}
