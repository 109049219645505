import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'FinanceEntry'

const recordNameSingular = 'financeEntry'
const recordNamePlural = 'financeEntries'
const i18nKey = 'finance::Entry'

const primaryKey = 'entryId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeEntries'

const pageHelperKey = 'financeEntries'

const resourcePathName = 'finance_entry'

const attributes: Record<string, AttributeSpec> = {
  entryId: {
    kind: 'column',
    name: 'entryId',
    type: 'text',
  },
  entryDimensionId: {
    kind: 'column',
    name: 'entryDimensionId',
    type: 'text',
  },
  scenario: {
    kind: 'column',
    name: 'scenario',
    type: 'text',
  },
  version: {
    kind: 'column',
    name: 'version',
    type: 'date',
  },
  versionLabel: {
    kind: 'column',
    name: 'versionLabel',
    type: 'text',
  },
  entityId: {
    kind: 'column',
    name: 'entityId',
    type: 'text',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  segment: {
    kind: 'column',
    name: 'segment',
    type: 'text',
  },
  bloodstockSituationId: {
    kind: 'column',
    name: 'bloodstockSituationId',
    type: 'text',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'datetime',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  amountNb: {
    kind: 'column',
    name: 'amountNb',
    type: 'decimal',
  },
  amountCf: {
    kind: 'column',
    name: 'amountCf',
    type: 'decimal',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'decimal',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'decimal',
  },
  monthEnd: {
    kind: 'column',
    name: 'monthEnd',
    type: 'date',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
