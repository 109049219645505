import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'HorseTransaction'

const recordNameSingular = 'horseTransaction'
const recordNamePlural = 'horseTransactions'
const i18nKey = 'horseTransaction'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'horseTransactions'

const pageHelperKey = 'horseTransactions'

const resourcePathName = 'horse_transaction'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  sellerEntity: {
    kind: 'association',
    name: 'sellerEntity',
    modelName: 'Entity',
    foreignKey: 'sellerEntityId',
    associationPrimaryKey: 'id',
  },
  purchaserEntity: {
    kind: 'association',
    name: 'purchaserEntity',
    modelName: 'Entity',
    foreignKey: 'purchaserEntityId',
    associationPrimaryKey: 'id',
  },
  horseTransactionType: {
    kind: 'association',
    name: 'horseTransactionType',
    modelName: 'HorseTransactionType',
    foreignKey: 'horseTransactionTypeId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  horseTransactionTypeId: {
    kind: 'column',
    name: 'horseTransactionTypeId',
    type: 'integer',
  },
  sellerEntityId: {
    kind: 'column',
    name: 'sellerEntityId',
    type: 'integer',
  },
  purchaserEntityId: {
    kind: 'column',
    name: 'purchaserEntityId',
    type: 'integer',
  },
  transactionDate: {
    kind: 'column',
    name: 'transactionDate',
    type: 'date',
  },
  terms: {
    kind: 'column',
    name: 'terms',
    type: 'string',
  },
  percentage: {
    kind: 'column',
    name: 'percentage',
    type: 'decimal',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
