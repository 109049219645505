// JsFromRoutes CacheKey ae42a7d5efa21aa6881bc473b4ce2a11
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DBTEsnSummaryModel } from '@/models/dbt/esn_summary'

export const dbtEsnSummariesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/esn_summaries',
    actionName: 'index',
    model: DBTEsnSummaryModel,
    parts: ['api', 'dbt', 'esn_summaries'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/esn_summaries',
    actionName: 'show',
    model: DBTEsnSummaryModel,
    parts: ['api', 'dbt', 'esn_summaries', { name: 'esnId', attributeId: 'DBTEsnSummary:esnId' }],
    httpMethod: 'get',
    type: 'view',
  }),
}
