import { Endpoint } from '@/libs/routes/endpoint'
import { findEndpoints, findRoutes } from '@/libs/routes/find'
import { RouteHelper } from '@/libs/routes/route'
import { capitalize } from '@/utils'

import { QueryBuilder, createQueryBuilder } from '../query/builder'
import { Model } from './model'
import { ResourceRecord } from './record'

export interface Resource<T extends ResourceRecord = ResourceRecord> {
  name: string
  model: Model<T>

  api: Record<string, Endpoint<T>>
  routes: Record<string, RouteHelper>
  query: Record<string, QueryBuilder<T>>

  routeParamKey: (attributeName: string) => string
}

const defineResource = <T extends ResourceRecord>(model: Model<T>, name?: string) => {
  const resourceName = name ?? model.name

  const api = findEndpoints(model.apiHelperKey)
  const routes = findRoutes(model.pageHelperKey)

  const routeParamKey = (attributeName: string) =>
    `${model.recordNameSingular}${capitalize(attributeName)}`

  const query = Object.keys(api).reduce<Record<string, QueryBuilder<T>>>((acc, key) => {
    const endpoint = api[key]

    if (endpoint.type === 'list' || endpoint.type === 'view')
      return { ...acc, [key]: createQueryBuilder(endpoint) }

    return acc
  }, {})

  return {
    name: resourceName,
    model,

    api,
    query,
    routes,

    routeParamKey,
  }
}

export { defineResource }
