import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { SeasonTransaction } from '@/types'

const name = 'SeasonTransaction'

const recordNameSingular = 'seasonTransaction'
const recordNamePlural = 'seasonTransactions'
const i18nKey = 'seasonTransaction'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'seasonTransactions'

const pageHelperKey = 'seasonTransactions'

const resourcePathName = 'season_transaction'

const attributes: Record<string, AttributeSpec> = {
  season: {
    kind: 'association',
    name: 'season',
    modelName: 'Season',
    foreignKey: 'seasonId',
    associationPrimaryKey: 'id',
  },
  seasonTransactionType: {
    kind: 'association',
    name: 'seasonTransactionType',
    modelName: 'SeasonTransactionType',
    foreignKey: 'seasonTransactionTypeId',
    associationPrimaryKey: 'id',
  },
  sellerEntity: {
    kind: 'association',
    name: 'sellerEntity',
    modelName: 'Entity',
    foreignKey: 'sellerEntityId',
    associationPrimaryKey: 'id',
  },
  purchaserEntity: {
    kind: 'association',
    name: 'purchaserEntity',
    modelName: 'Entity',
    foreignKey: 'purchaserEntityId',
    associationPrimaryKey: 'id',
  },
  sellerHfmWinstarClient: {
    kind: 'association',
    name: 'sellerHfmWinstarClient',
    modelName: 'HfmWinstarClient',
    foreignKey: 'sellerHfmWinstarClientId',
    associationPrimaryKey: 'clientId',
  },
  purchaserHfmWinstarClient: {
    kind: 'association',
    name: 'purchaserHfmWinstarClient',
    modelName: 'HfmWinstarClient',
    foreignKey: 'purchaserHfmWinstarClientId',
    associationPrimaryKey: 'clientId',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  seasonId: {
    kind: 'column',
    name: 'seasonId',
    type: 'integer',
  },
  seasonTransactionTypeId: {
    kind: 'column',
    name: 'seasonTransactionTypeId',
    type: 'integer',
  },
  sellerEntityId: {
    kind: 'column',
    name: 'sellerEntityId',
    type: 'integer',
  },
  purchaserEntityId: {
    kind: 'column',
    name: 'purchaserEntityId',
    type: 'integer',
  },
  sellerHfmWinstarClientId: {
    kind: 'column',
    name: 'sellerHfmWinstarClientId',
    type: 'integer',
  },
  purchaserHfmWinstarClientId: {
    kind: 'column',
    name: 'purchaserHfmWinstarClientId',
    type: 'integer',
  },
  transactionDate: {
    kind: 'column',
    name: 'transactionDate',
    type: 'date',
  },
  terms: {
    kind: 'column',
    name: 'terms',
    type: 'string',
  },
  percentage: {
    kind: 'column',
    name: 'percentage',
    type: 'decimal',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<SeasonTransaction> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
