import { useMediaQuery } from '@uidotdev/usehooks'

const useBreakpoints = () => {
  const isPhone = useMediaQuery('(max-width: 767px)')
  const isTablet = useMediaQuery('(max-width: 1366px)')
  const isPC = useMediaQuery('(min-width: 1367px)')

  const isSmall = useMediaQuery('(min-width: 640px)')
  const isMedium = useMediaQuery('(min-width: 768px)')
  const isLarge = useMediaQuery('(min-width: 1024px)')
  const isXLarge = useMediaQuery('(min-width: 1280px)')
  const is2XLarge = useMediaQuery('(min-width: 1536px)')

  return {
    isPhone,
    isTablet,
    isPC,

    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    is2XLarge,
  }
}

export { useBreakpoints }
