import { createRoutes } from '@/libs/router/route'
import { AccountResource } from '@/resources/account/resource'

export const AccountResourceRoutes = createRoutes({
  resource: AccountResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'accounts',
      children: [
        {
          index: true,
          page: 'accounts/index',
          query: AccountResource.query.index,
        },
        {
          path: 'new',
          page: 'accounts/new',
          query: AccountResource.query.new,
        },
        {
          path: ':id',
          query: AccountResource.query.show,
          children: [
            {
              index: true,
              page: 'accounts/show',
            },
            {
              path: 'edit',
              page: 'accounts/edit',
            },
            {
              path: 'destroy',
              page: 'accounts/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const AccountRoutes = AccountResourceRoutes
