import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsExotic } from '@/types'

const name = 'DRFChartsExotic'

const recordNameSingular = 'drfChartsExotic'
const recordNamePlural = 'drfChartsExotics'
const i18nKey = 'drf::ChartsExotic'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsExotics'

const pageHelperKey = 'drfChartsExotics'

const resourcePathName = 'drf_charts_exotic'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsExoticsId: {
    kind: 'column',
    name: 'chartsExoticsId',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  ordering: {
    kind: 'column',
    name: 'ordering',
    type: 'integer',
  },
  exoticText: {
    kind: 'column',
    name: 'exoticText',
    type: 'string',
  },
  exoticPaidText: {
    kind: 'column',
    name: 'exoticPaidText',
    type: 'string',
  },
  poolLabel: {
    kind: 'column',
    name: 'poolLabel',
    type: 'string',
  },
  poolValue: {
    kind: 'column',
    name: 'poolValue',
    type: 'float',
  },
  poolCarryover: {
    kind: 'column',
    name: 'poolCarryover',
    type: 'float',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFChartsExotic> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
