import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { CoverForm } from '@/resources/cover/form'

const CoversEdit = () => {
  const { coversEdit } = useLoaderQueryBuilders()
  const { record: cover } = useResourceQuery(coversEdit)

  return (
    <Section title={`Edit ${cover.title}`}>
      <CoverForm initialValues={cover} />
    </Section>
  )
}

export default CoversEdit
