import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFExoticPayoff } from '@/types'

const name = 'DRFExoticPayoff'

const recordNameSingular = 'drfExoticPayoff'
const recordNamePlural = 'drfExoticPayoffs'
const i18nKey = 'drf::ExoticPayoff'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfExoticPayoffs'

const pageHelperKey = 'drfExoticPayoffs'

const resourcePathName = 'drf_exotic_payoff'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsExoticsId: {
    kind: 'column',
    name: 'chartsExoticsId',
    type: 'integer',
  },
  wagerType: {
    kind: 'column',
    name: 'wagerType',
    type: 'string',
  },
  numberOfRights: {
    kind: 'column',
    name: 'numberOfRights',
    type: 'integer',
  },
  winningNumbers: {
    kind: 'column',
    name: 'winningNumbers',
    type: 'string',
  },
  numberOfTicketsBet: {
    kind: 'column',
    name: 'numberOfTicketsBet',
    type: 'integer',
  },
  totalPool: {
    kind: 'column',
    name: 'totalPool',
    type: 'float',
  },
  payoffAmount: {
    kind: 'column',
    name: 'payoffAmount',
    type: 'float',
  },
  carryover: {
    kind: 'column',
    name: 'carryover',
    type: 'float',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFExoticPayoff> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
