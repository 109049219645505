import React from 'react'
import { useTranslation } from 'react-i18next'

import { Tag } from '@/components/ui/tag'
import { cn } from '@/utils'

interface HorseTagsProps {
  horse: {
    sex?: string
    yob?: string
    horseType?: string
  }
}

export const HorseTypeTag = ({ horse, className, ...props }) => {
  const { t } = useTranslation()

  if (!horse) return null

  return (
    <Tag
      style={{
        backgroundColor: `hsl(var(--color-${horse.horseType.toLowerCase()}))`,
      }}
      {...props}
      rounded
    >
      {horse.horseType ? t(`activerecord.attributes.horse.horseTypes.${horse.horseType}`) : 'Unk'}
    </Tag>
  )
}

export const HorseSexTag = ({ horse, className, ...props }) => {
  const { t } = useTranslation()
  if (!horse) return null

  return (
    <Tag className={cn('bg-plain', className)} {...props} rounded>
      {horse.sex ? t(`horse.attributes.sexes.${horse.sex.toLowerCase()}`) : 'Unk'}
    </Tag>
  )
}

export const HorseYobTag = ({ horse, className, ...props }) => {
  if (!horse) return null

  return (
    <Tag className={cn('bg-plain', className)} {...props} rounded>
      {horse.yob}
    </Tag>
  )
}

export const HorseTags: React.FC<HorseTagsProps> = ({ hideType, ...props }) => {
  return (
    <div className="flex justify-between gap-1">
      {!hideType && <HorseTypeTag {...props} />}
      <HorseSexTag {...props} />
      <HorseYobTag {...props} />
    </div>
  )
}
