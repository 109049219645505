import { Attribute } from '@/libs/resource/types'
import { Resource } from '@/resources'
import { constructPath, expand } from '@/utils'
import { type RowData } from '@tanstack/react-table'

import { attributeColumnBuilder } from './column-builder'
import { TableBuilder, TableBuilderConfig, TableDefinition } from './types'
import { includeSubTable } from './utils'

export const tableBuilder = <TData extends RowData>(
  resource: Resource,
  include: Record<string, TableDefinition<TData>>,
  { path = '' }: TableBuilderConfig = {},
): TableBuilder<TData> => {
  const colId = (id: string) => constructPath(path, id)

  const subTables = Object.keys(include).reduce((acc, tableKey) => {
    const subTable = includeSubTable(path, tableKey, include[tableKey])

    return { ...acc, [tableKey]: subTable }
  }, {})

  const resourceCols = Object.values(resource.model.attributes)
    .filter((attr: Attribute) => attr.kind !== 'attachment')
    .reduce(
      (acc, attr: Attribute) => ({
        ...acc,
        [attr.name]: attributeColumnBuilder(attr, colId, path),
      }),
      {},
    )

  const allSubTableColumns = Object.values(subTables)
    .map((table) => table.tbl.spec.columns)
    .flat()

  const allSubTableCols = Object.values(subTables).reduce(
    (acc, subtbl) => ({
      ...acc,
      ...subtbl.cols,
    }),
    {},
  )

  const col = expand({ ...resourceCols, ...allSubTableCols })

  const compoundCol: TableBuilder<TData>['compoundCol'] = (id, columnIds, opts = {}) => ({
    kind: 'compound',
    colId: colId(id),
    contextPath: path,
    columnIds,
    opts,
  })

  const accessorKeyCol: TableBuilder<TData>['accessorKeyCol'] = (id, opts = {}) => ({
    kind: 'default',
    colId: colId(id),
    contextPath: path,
    opts,
  })

  const calculatedCol: TableBuilder<TData>['calculatedCol'] = (id, accessorFn, opts = {}) => ({
    kind: 'calculated',
    colId: colId(id),
    contextPath: path,
    accessorFn,
    opts,
  })

  const expanderCol: TableBuilder<TData>['expanderCol'] = () => ({
    kind: 'expander',
  })

  const displayCol: TableBuilder<TData>['displayCol'] = (id, opts = {}) => ({
    kind: 'display',
    colId: colId(id),
    contextPath: path,
    opts,
  })

  const filterPreset = (id: string, opts) => ({
    id,
    ...opts,
  })

  return {
    resource,
    colId,
    col,
    accessorKeyCol,
    compoundCol,
    calculatedCol,
    expanderCol,
    displayCol,
    filterPreset,
    currentSpec: {
      columns: allSubTableColumns,
      filterPresets: [],
    },
  }
}
