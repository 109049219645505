import { Attribute } from '@/resources'
import { deepMerge, removePrefix } from '@/utils'
import { RowData } from '@tanstack/react-table'

import { ColumnBuilderSpec, ColumnHelperDef } from './types'

export const attributeColumnBuilder = (
  attribute: Attribute,
  colId: (id: string) => string,
  contextPath = '.',
) => {
  const col = (opts: ColumnHelperDef = {}) => ({
    colId: colId(attribute.name),
    kind: 'attribute',
    attribute,
    contextPath,
    opts,
  })

  col.colId = attribute.name
  col.id = () => colId(attribute.name)
  col.attribute = attribute
  col.contextPath = contextPath

  return col
}

export const specColumnBuilder = <TData extends RowData>({
  opts,
  ...spec
}: ColumnBuilderSpec<TData>) => {
  const col = (newOpts: ColumnHelperDef = {}) => ({
    ...spec,
    opts: deepMerge(opts, newOpts),
  })

  col.id = () => spec.colId
  col.colId = spec.colId
  col.baseId = removePrefix(spec.colId, spec.contextPath)
  col.contextPath = spec.contextPath

  return col
}
