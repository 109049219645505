import { defineTable } from '@/components/data-table/builder'

import { formatName } from './format'
import { HfmWinstarClientResource } from './resource'

export const hfmWinstarClientTable = defineTable(HfmWinstarClientResource, {}, (tbl) => ({
  columns: [
    tbl.col.clientId(),

    tbl.calculatedCol('name', (row) => formatName(row), { label: 'Name' }),

    tbl.col.activeFlag(),
    tbl.col.companyId(),
    tbl.col.salutation(),
    tbl.col.accountNumber(),
  ],
}))
