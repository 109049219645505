import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'SeasonTransactionType'

const recordNameSingular = 'seasonTransactionType'
const recordNamePlural = 'seasonTransactionTypes'
const i18nKey = 'seasonTransactionType'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'seasonTransactionTypes'

const pageHelperKey = 'seasonTransactionTypes'

const resourcePathName = 'season_transaction_type'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
