// JsFromRoutes CacheKey 43eafb0a1281b1c2a33c1fcb5f3f559c
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const usersRegistrationsApi = {
  cancel: definePathHelper('get', '/api/users/cancel'),
  new: definePathHelper('get', '/api/users/sign_up'),
  edit: definePathHelper('get', '/api/users/edit'),
  update: definePathHelper('patch', '/api/users'),
  destroy: definePathHelper('delete', '/api/users'),
  create: definePathHelper('post', '/api/users'),
}
