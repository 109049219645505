import { compact } from '@/utils'

import { Period } from './period'
import { formatDate, parseDate } from './utils'

export type Scenario =
  | {
      type: 'Actual' | 'Budget'
      name: string
    }
  | {
      type: 'Forecast'
      name: string
      referenceDate: Date
    }

/**
 * Serializes a Period as a parseable string primarily
 * used for building unique IDs
 *
 * <Type>-<Name>[-<ReferenceDate>]
 *
 * e.g., Budget-v20240810
 * e.g., Forecast-v20240810-20241031
 */
export const serializeScenario = (scenario: Scenario) =>
  compact([
    scenario.type,
    scenario.name,
    scenario.type === 'Forecast' ? formatDate(scenario.referenceDate) : null,
  ]).join('-')

/**
 * Parse scenario from string
 */
export const parseScenario = (input: string): Scenario | never => {
  const [type, name, referenceDate] = input.split('-')

  if (type === 'Actual') {
    return { type, name: 'Actual' }
  }

  if (type === 'Budget') {
    if (name === undefined) {
      throw new Error(`Cannot parse scenario '${input}'. Name is missing.`)
    }

    return { type, name }
  }

  if (type === 'Forecast') {
    if (name === undefined) {
      throw new Error(`Cannot parse scenario '${input}'. Name is missing.`)
    }

    try {
      return {
        type,
        name,
        referenceDate: parseDate(referenceDate),
      }
    } catch (error) {
      throw new Error(`Cannot parse scenario '${input}'. Reference date is invlaid: ${error}`)
    }
  }

  throw new Error(`Cannot parse scenario '${input}'. Type is invalid.`)
}

/**
 * Given a period used in a calculation, and the scenario of the given calculation,
 * derives the appropriate scenario to pull for the underlying period.
 *
 */
export const deriveScenarioForPeriod = (period: Period, parentScenario: Scenario): Scenario => {
  // When the scenario is 'Actual' or 'Budget',
  // then the child period is always the same
  if (parentScenario.type !== 'Forecast') return parentScenario

  // When the scenario is Forecast, the child period's scenario
  // depends on it's date relative to the referenceDate
  if (period.startDate < parentScenario.referenceDate)
    return {
      type: 'Actual',
      name: 'Actual',
    }

  return {
    type: 'Budget',
    name: parentScenario.name,
  }
}

export const ActualScenario: Scenario = {
  type: 'Actual',
  name: 'Actual',
}
