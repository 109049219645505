import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { OrganizationHorseForm } from '@/resources/organization_horse/form'

const OrganizationHorsesEdit = () => {
  const { organizationHorsesEdit } = useLoaderQueryBuilders()
  const { record: organizationHorse } = useResourceQuery(organizationHorsesEdit)

  return (
    <Section title={`Edit ${organizationHorse.title}`}>
      <OrganizationHorseForm initialValues={organizationHorse} />
    </Section>
  )
}

export default OrganizationHorsesEdit
