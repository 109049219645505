// JsFromRoutes CacheKey da6652af6cbf82c88ccd96254bd086fb
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const shortlistsApi = {
  index: definePathHelper('get', '/api/sales/:saleId/shortlists'),
  create: definePathHelper('post', '/api/sales/:saleId/shortlists'),
  new: definePathHelper('get', '/api/sales/:saleId/shortlists/new'),
  edit: definePathHelper('get', '/api/shortlists/:id/edit'),
  show: definePathHelper('get', '/api/shortlists/:id'),
  update: definePathHelper('patch', '/api/shortlists/:id'),
  destroy: definePathHelper('delete', '/api/shortlists/:id'),
}
