import { createRoutes } from '@/libs/router/route'

import { DBTCareerRaceSummaryResource } from './resource'

export const CareerRaceSummaryResourceRoutes = createRoutes({
  resource: DBTCareerRaceSummaryResource,
  routes: [
    {
      path: 'racing',
      children: [
        {
          index: true,
          page: 'dbt/career_race_summaries/index',
          query: DBTCareerRaceSummaryResource.query.index.sort('date', 'desc'),
        },
      ],
    },
  ],
})

export const CareerRaceSummaryRoutes = CareerRaceSummaryResourceRoutes
