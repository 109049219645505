import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { DocumentSchema } from '@/schemas/document'
import { Document } from '@/types'

import { DocumentResource } from './resource'

type DocumentFormProps = Omit<UseFormProps<Document>, 'resource'>

const DocumentForm = ({ zodSchema = DocumentSchema, ...props }: DocumentFormProps) => {
  const formConfig = useForm<Document>({
    resource: DocumentResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="dateGenerated" />
          <Field name="category" />
          <Field name="file" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { DocumentForm }
