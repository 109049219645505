import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'FinanceFinancial'

const recordNameSingular = 'financeFinancial'
const recordNamePlural = 'financeFinancials'
const i18nKey = 'finance::Financial'

const primaryKey = 'financialId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeFinancials'

const pageHelperKey = 'financeFinancials'

const resourcePathName = 'finance_financial'

const attributes: Record<string, AttributeSpec> = {
  recordId: {
    kind: 'column',
    name: 'recordId',
    type: 'text',
  },
  budgetItemId: {
    kind: 'column',
    name: 'budgetItemId',
    type: 'integer',
  },
  recordKey: {
    kind: 'column',
    name: 'recordKey',
    type: 'text',
  },
  bsRecordKey: {
    kind: 'column',
    name: 'bsRecordKey',
    type: 'text',
  },
  scenario: {
    kind: 'column',
    name: 'scenario',
    type: 'text',
  },
  version: {
    kind: 'column',
    name: 'version',
    type: 'date',
  },
  versionLabel: {
    kind: 'column',
    name: 'versionLabel',
    type: 'text',
  },
  entityId: {
    kind: 'column',
    name: 'entityId',
    type: 'text',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'datetime',
  },
  note: {
    kind: 'column',
    name: 'note',
    type: 'text',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  amountNb: {
    kind: 'column',
    name: 'amountNb',
    type: 'decimal',
  },
  amountCf: {
    kind: 'column',
    name: 'amountCf',
    type: 'decimal',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'decimal',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'decimal',
  },
  monthEnd: {
    kind: 'column',
    name: 'monthEnd',
    type: 'date',
  },
  accountGroup: {
    kind: 'column',
    name: 'accountGroup',
    type: 'text',
  },
  accountTitle: {
    kind: 'column',
    name: 'accountTitle',
    type: 'string',
  },
  accountType: {
    kind: 'column',
    name: 'accountType',
    type: 'string',
  },
  accountTypeFull: {
    kind: 'column',
    name: 'accountTypeFull',
    type: 'text',
  },
  normalBalanceSign: {
    kind: 'column',
    name: 'normalBalanceSign',
    type: 'integer',
  },
  accountLabel: {
    kind: 'column',
    name: 'accountLabel',
    type: 'text',
  },
  accountSector: {
    kind: 'column',
    name: 'accountSector',
    type: 'text',
  },
  accountCategory: {
    kind: 'column',
    name: 'accountCategory',
    type: 'string',
  },
  accountExpenseType: {
    kind: 'column',
    name: 'accountExpenseType',
    type: 'text',
  },
  cashFlowGroup: {
    kind: 'column',
    name: 'cashFlowGroup',
    type: 'text',
  },
  cashFlowGroupNoEquineDep: {
    kind: 'column',
    name: 'cashFlowGroupNoEquineDep',
    type: 'text',
  },
  cashFlowAccountGroup: {
    kind: 'column',
    name: 'cashFlowAccountGroup',
    type: 'text',
  },
  cashFlowAccountGroupNoEquineDep: {
    kind: 'column',
    name: 'cashFlowAccountGroupNoEquineDep',
    type: 'text',
  },
  iscfAccountGroup: {
    kind: 'column',
    name: 'iscfAccountGroup',
    type: 'text',
  },
  eponaAccountExpenseGroup: {
    kind: 'column',
    name: 'eponaAccountExpenseGroup',
    type: 'text',
  },
  className: {
    kind: 'column',
    name: 'className',
    type: 'text',
  },
  parentClassId: {
    kind: 'column',
    name: 'parentClassId',
    type: 'string',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'text',
  },
  horseString: {
    kind: 'column',
    name: 'horseString',
    type: 'text',
  },
  horseLabel: {
    kind: 'column',
    name: 'horseLabel',
    type: 'text',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  age: {
    kind: 'column',
    name: 'age',
    type: 'decimal',
  },
  customerName: {
    kind: 'column',
    name: 'customerName',
    type: 'string',
  },
  vendorName: {
    kind: 'column',
    name: 'vendorName',
    type: 'string',
  },
  employeeName: {
    kind: 'column',
    name: 'employeeName',
    type: 'string',
  },
  monthlyOwnershipByDivisionId: {
    kind: 'column',
    name: 'monthlyOwnershipByDivisionId',
    type: 'text',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'integer',
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  accountName: {
    kind: 'column',
    name: 'accountName',
    type: 'citext',
  },
  ownershipGroup: {
    kind: 'column',
    name: 'ownershipGroup',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  normalBalance: {
    kind: 'column',
    name: 'normalBalance',
    type: 'string',
  },
  transactionType: {
    kind: 'column',
    name: 'transactionType',
    type: 'text',
  },
  transactionGroup: {
    kind: 'column',
    name: 'transactionGroup',
    type: 'text',
  },
  eponaExpenseType: {
    kind: 'column',
    name: 'eponaExpenseType',
    type: 'text',
  },
  horseGroup: {
    kind: 'column',
    name: 'horseGroup',
    type: 'text',
  },
  entityExact: {
    kind: 'column',
    name: 'entityExact',
    type: 'text',
  },
  entityGroups: {
    kind: 'column',
    name: 'entityGroups',
    type: 'text',
  },
  entityCombined: {
    kind: 'column',
    name: 'entityCombined',
    type: 'text',
  },
  company: {
    kind: 'column',
    name: 'company',
    type: 'text',
  },
  segment: {
    kind: 'column',
    name: 'segment',
    type: 'text',
  },
  subSegment: {
    kind: 'column',
    name: 'subSegment',
    type: 'text',
  },
  project: {
    kind: 'column',
    name: 'project',
    type: 'text',
  },
  subject: {
    kind: 'column',
    name: 'subject',
    type: 'text',
  },
  detail: {
    kind: 'column',
    name: 'detail',
    type: 'text',
  },
  accountGroupInvestment: {
    kind: 'column',
    name: 'accountGroupInvestment',
    type: 'text',
  },
  accountGroupPerSegment: {
    kind: 'column',
    name: 'accountGroupPerSegment',
    type: 'text',
  },
  entityExactSort: {
    kind: 'column',
    name: 'entityExactSort',
    type: 'integer',
  },
  iscfAccountGroupSort: {
    kind: 'column',
    name: 'iscfAccountGroupSort',
    type: 'integer',
  },
  accountTypeFullSort: {
    kind: 'column',
    name: 'accountTypeFullSort',
    type: 'integer',
  },
  accountGroupInvestmentSort: {
    kind: 'column',
    name: 'accountGroupInvestmentSort',
    type: 'integer',
  },
  horseGroupSort: {
    kind: 'column',
    name: 'horseGroupSort',
    type: 'integer',
  },
  companySort: {
    kind: 'column',
    name: 'companySort',
    type: 'integer',
  },
  segmentSort: {
    kind: 'column',
    name: 'segmentSort',
    type: 'integer',
  },
  subSegmentSort: {
    kind: 'column',
    name: 'subSegmentSort',
    type: 'integer',
  },
  projectSort: {
    kind: 'column',
    name: 'projectSort',
    type: 'integer',
  },
  subjectSort: {
    kind: 'column',
    name: 'subjectSort',
    type: 'integer',
  },
  detailSort: {
    kind: 'column',
    name: 'detailSort',
    type: 'integer',
  },
  segmentProject: {
    kind: 'column',
    name: 'segmentProject',
    type: 'text',
  },
  segmentSubSegmentProject: {
    kind: 'column',
    name: 'segmentSubSegmentProject',
    type: 'text',
  },
  projectSubject: {
    kind: 'column',
    name: 'projectSubject',
    type: 'text',
  },
  subjectDetail: {
    kind: 'column',
    name: 'subjectDetail',
    type: 'text',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
