import { Actions, Action } from '@/components/actions'
import { defineTable } from '@/components/data-table/builder'
import { ellipsisFormatter } from '@/components/data-table/formatters'
import { enumDomainToMap } from '@/libs/resource/attribute'
import { dbtCareerRaceSummaryTable } from '@/resources/dbt/career_race_summary/data-table'
import { dbtHorseTable } from '@/resources/dbt/horse/data-table'
import { dbtRaceResultTable } from '@/resources/dbt/race_result/data-table'
import { dbtSaleResultTable } from '@/resources/dbt/sale_result'
import { RacehorseCommentResource } from '@/resources/racehorse_comment'
import { racehorseCommentTable } from '@/resources/racehorse_comment/data-table'
import { racehorseCommentsRoutes } from '@/routes'

import { DBTRacehorseDetailResource } from './resource'

export const dbtRacehorseDetailTable = defineTable(
  DBTRacehorseDetailResource,
  {
    horse: dbtHorseTable,
    comment: racehorseCommentTable,
    careerRaceSummary: dbtCareerRaceSummaryTable,
    lastSaleResult: dbtSaleResultTable,
    lastStart: dbtRaceResultTable,
  },
  (tbl) => ({
    name: 'RacehorseDetail',
    clientSide: true,
    initialSorting: [
      { id: tbl.col.comment.tier.id(), desc: false },
      { id: tbl.col.careerRaceSummary.bestClass.id(), desc: true },
      { id: tbl.col.careerRaceSummary.totalEarnings.id(), desc: true },
    ],
    columns: [
      tbl.col.comment.tier(),

      tbl.col.horse.nameAgeSex(),

      tbl.displayCol('actions', {
        enableColumnFilter: false,
        size: 100,
        header: () => null,
        cell: ({ row }) => {
          return (
            <Actions numToShow={1}>
              <Action
                route={racehorseCommentsRoutes.new.withOptions({
                  horseId: row.original.horse.horseId,
                })}
                name="new"
                state={{
                  initialValues: {
                    horseId: row.original.horse.horseId,
                    tier: enumDomainToMap(RacehorseCommentResource.model.attributes.tier.domain)[
                      row.original.comment.tier
                    ],
                  },
                }}
              />
              <Action
                route={racehorseCommentsRoutes.destroy.withOptions({
                  racehorseCommentId: row.original.comment.id,
                })}
                name="destroy"
                disabled
              />
            </Actions>
          )
        },
      }),

      tbl.col.comment.comment(),

      tbl.col.careerRaceSummary.bestClassOverRaceRecord(),
      tbl.col.careerRaceSummary.earnings(),

      tbl.col.careerRaceSummary.lastWorkOverLastRace(),

      tbl.col.lastStart.raceTypeOverFinishPosition(),

      tbl.col.lastStart.trainerName({
        label: 'Trainer',
        cell: ellipsisFormatter(),
      }),

      tbl.col.horse.damOverDamSire(),

      tbl.col.lastStart.ownerOverBreeder(),
      tbl.col.lastSaleResult.lastSale(),

      tbl.col.defaultFilter({
        label: '2-4yos or Active',
        enableHiding: false,
        filter: { key: 'boolean', value: true },
      }),

      tbl.col.contextFilter({
        label: 'Active',
        isVisible: false,
        filter: { key: 'value' },
      }),
    ],
    filterPresets: [
      tbl.filterPreset('two_yos', {
        label: '2yos',
        items: [{ columnId: tbl.col.horse.age.id(), value: { min: 2, max: 2 } }],
      }),
      tbl.filterPreset('three_yos', {
        label: '3yos',
        items: [{ columnId: tbl.col.horse.age.id(), value: { min: 3, max: 3 } }],
      }),
      tbl.filterPreset('older', {
        label: '4yos+',
        items: [{ columnId: tbl.col.horse.age.id(), value: { min: 4 } }],
      }),
      tbl.filterPreset('active', {
        label: 'Active',
        items: [{ columnId: tbl.col.defaultFilter.id(), value: true }],
      }),
    ],
  }),
)
