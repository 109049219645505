import React from 'react'

import { Input, InputProps } from '@/components/ui/input'
import { cn } from '@/utils'

export const CurrencyInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, className, ...props }, ref) => {
    const convertFormattedToNumber = (formattedValue: string) => {
      if (!formattedValue) return null
      return Number(formattedValue.replace(/[^0-9.]/g, ''))
    }

    const formatWithCommas = (num: number) => {
      if (!num) return ''
      const cleanedValue = num.toString()
      const formattedValue = cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return formattedValue
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      onChange(convertFormattedToNumber(inputValue))
    }

    return (
      <Input
        ref={ref}
        type="text"
        placeholder="Amount"
        className={cn('text-right', className)}
        value={formatWithCommas(value)}
        onChange={handleChange}
        prefix="$"
        {...props}
      />
    )
  },
)
