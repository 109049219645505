import { createRoutes } from '@/libs/router/route'
import { OrganizationResource } from '@/resources/organization/resource'

export const OrganizationResourceRoutes = createRoutes({
  resource: OrganizationResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'organizations',
      children: [
        {
          index: true,
          page: 'organizations/index',
          query: OrganizationResource.query.index,
        },
        {
          path: 'new',
          page: 'organizations/new',
          query: OrganizationResource.query.new,
        },
        {
          path: ':id',
          query: OrganizationResource.query.show,
          children: [
            {
              index: true,
              page: 'organizations/show',
            },
            {
              path: 'edit',
              page: 'organizations/edit',
            },
            {
              path: 'destroy',
              page: 'organizations/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const OrganizationRoutes = OrganizationResourceRoutes
