import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { TrainingReportSchema } from '@/schemas/training_report'
import { TrainingReport } from '@/types'

import { TrainingReportResource } from './resource'

type TrainingReportFormProps = Omit<UseFormProps<TrainingReport>, 'resource'>

const TrainingReportForm = ({
  zodSchema = TrainingReportSchema,
  ...props
}: TrainingReportFormProps) => {
  const formConfig = useForm<TrainingReport>({
    resource: TrainingReportResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <div className="flex flex-col gap-4">
        <Card className="w-full p-4">
          <CardHeader>
            <CardTitle>Status</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <div className="flex justify-between gap-3 phone:flex-col">
              <Field name="horseId" className="flex-1" />
              <Field name="user" className="flex-1" />
              <Field name="date" className="flex-1" />
            </div>
            <div className="flex justify-between gap-3 phone:flex-col">
              <Field name="location" className="flex-1" />
              <Field name="status" className="flex-1" />
            </div>
            <Field name="comment" className="h-32" />
          </CardContent>
        </Card>
        <Card className="flex-1 p-4">
          <CardHeader>
            <CardTitle>Target Race</CardTitle>
          </CardHeader>
          <CardContent className="flex gap-3 phone:flex-col">
            <Field name="targetRace" className="flex-1" />
            <Field name="status" className="flex-1" />
            <Field name="targetRaceDate" className="flex-1" />
          </CardContent>
        </Card>
        <Card className="p-4">
          <CardHeader>
            <CardTitle>Audio Note</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col">
            <Field name="file" />
          </CardContent>
        </Card>
      </div>
    </Form>
  )
}

export { TrainingReportForm }
