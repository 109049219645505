import { OutletProps, useMatches, useOutlet } from 'react-router-dom'

import { Breadcrumbs } from '@/components/breadcrumbs'
import { PanelContent } from '@/components/panel/content'
import { PageSuspense } from '@/components/suspense/default'
import {
  DrawerSheetContent,
  DrawerSheetContainer,
  DrawerSheetHeader,
  DrawerSheetTitle,
  DrawerSheetSidebar,
} from '@/components/ui/drawer-sheet'
import { usePageContext } from '@/contexts/page'

export const SheetOutlet = (props: OutletProps) => {
  const matches = useMatches().reverse()

  const { layoutType, navigateBack, navigateOut, sheetSize, sheetOpen } = usePageContext()

  const sidebarRoute = matches.find(
    (route) => route.handle?.layoutType === 'sheet' && route.handle.panelConfig,
  )
  const currentRoute = matches[0]

  const routePanelConfig = sidebarRoute?.handle?.panelConfig

  const outlet = useOutlet(props.context)

  // Instantiates the panel config off the associated routes params and data.
  // Could make a function to be passed in if need other flexibility
  const panelConfig = routePanelConfig?.(sidebarRoute?.params, sidebarRoute?.data)

  return (
    <DrawerSheetContainer
      className="overflow-hidden rounded-xl bg-white p-0"
      onPointerDownOutside={(event) => {
        event.preventDefault()
        navigateOut()
      }}
      onEscapeKeyDown={(event) => {
        event.preventDefault()
        // TODO: Want to use navigateUp but it doesn't work
        // navigateUp()
        navigateBack()
      }}
    >
      {sidebarRoute ? (
        <DrawerSheetSidebar>
          <PanelContent config={panelConfig} />
        </DrawerSheetSidebar>
      ) : null}
      <DrawerSheetContent size={sheetSize}>
        <DrawerSheetHeader className="mb-4">
          {/* TODO : Proper title, hidden class for console error */}
          <DrawerSheetTitle className="hidden" />
          <Breadcrumbs layoutType="sheet" />
        </DrawerSheetHeader>
        <PageSuspense>{layoutType === 'sheet' || sheetOpen ? outlet : null}</PageSuspense>
      </DrawerSheetContent>
    </DrawerSheetContainer>
  )
}

export default SheetOutlet
