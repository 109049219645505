import { DBTRaceResult } from '@/types'
import { isEmptyObject } from '@/utils'

export const trackWithRaceNumber = (raceResult?: DBTRaceResult | Partial<DBTRaceResult>) => {
  if (raceResult == null || isEmptyObject(raceResult)) return

  return `${raceResult.trackId} #${raceResult.raceNumber}`
}

export const distanceWithSurface = (raceResult?: DBTRaceResult | Partial<DBTRaceResult>) => {
  if (raceResult == null || isEmptyObject(raceResult)) return

  return `${raceResult.distance}f ${raceResult.surface}`
}

export const raceLabel = (raceResult?: DBTRaceResult | Partial<DBTRaceResult>) => {
  if (raceResult == null || isEmptyObject(raceResult)) return

  return `${raceResult.raceTypeFull} - ${trackWithRaceNumber(raceResult)}`
}
