interface MediaOrientation {
  imageWidth: number
  imageHeight: number
  fallbackOrientation?: string
}

export const findOrientation = ({
  imageWidth,
  imageHeight,
  fallbackOrientation = 'landscape',
}: MediaOrientation) => {
  if (!Number.isInteger(imageWidth) || !Number.isInteger(imageHeight)) {
    return fallbackOrientation
  }

  return imageWidth > imageHeight ? 'landscape' : 'portrait'
}

export const aspectRatioStyle = ({
  imageWidth,
  imageHeight,
  fallbackOrientation = 'landscape',
}: MediaOrientation) => {
  const orientation = findOrientation({
    imageHeight,
    imageWidth,
    fallbackOrientation,
  })

  if (Number.isInteger(imageWidth) && Number.isInteger(imageHeight)) {
    return `${imageWidth.toString()} / ${imageHeight.toString()}`
  }

  return orientation === 'landscape' ? '4 / 3' : '3 / 4'
}

export const getAspectRatio = ({
  imageWidth,
  imageHeight,
  fallbackOrientation = 'landscape',
}: MediaOrientation) => {
  const orientation = findOrientation({
    imageWidth,
    imageHeight,
    fallbackOrientation,
  })

  if (Number.isInteger(imageWidth) && Number.isInteger(imageHeight)) {
    return [imageWidth, imageHeight]
  }

  return orientation === 'landscape' ? [4, 3] : [3, 4]
}
