import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsPaceProjectorLayout } from '@/types'

const name = 'DRFChartsPaceProjectorLayout'

const recordNameSingular = 'drfChartsPaceProjectorLayout'
const recordNamePlural = 'drfChartsPaceProjectorLayouts'
const i18nKey = 'drf::ChartsPaceProjectorLayout'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsPaceProjectorLayouts'

const pageHelperKey = 'drfChartsPaceProjectorLayouts'

const resourcePathName = 'drf_charts_pace_projector_layout'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartPaceProjectorLayoutId: {
    kind: 'column',
    name: 'chartPaceProjectorLayoutId',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  horseRow: {
    kind: 'column',
    name: 'horseRow',
    type: 'integer',
  },
  horseRowOrder: {
    kind: 'column',
    name: 'horseRowOrder',
    type: 'integer',
  },
  distanceBackRow: {
    kind: 'column',
    name: 'distanceBackRow',
    type: 'float',
  },
  distanceBackLeader: {
    kind: 'column',
    name: 'distanceBackLeader',
    type: 'float',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFChartsPaceProjectorLayout> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
