// JsFromRoutes CacheKey f4e8badd034a17c9b7b405b76d9166bc
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const companyDocumentsApi = {
  index: definePathHelper('get', '/api/company_documents'),
  create: definePathHelper('post', '/api/company_documents'),
  new: definePathHelper('get', '/api/company_documents/new'),
  edit: definePathHelper('get', '/api/company_documents/:id/edit'),
  show: definePathHelper('get', '/api/company_documents/:id'),
  update: definePathHelper('patch', '/api/company_documents/:id'),
  destroy: definePathHelper('delete', '/api/company_documents/:id'),
}
