import { forwardRef, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { FileInfo, Pencil, Plus, Sitemap, Stopwatch, Trash } from '@/components/icon'
import { Link, LinkProps } from '@/components/link'
import { SimpleLink } from '@/components/simple-link'
import { BasicTooltip } from '@/components/ui/tooltip'
import { RouteHelper } from '@/libs/routes/route'

export type ActionProps = LinkProps & {
  route: RouteHelper
  name: 'new' | 'edit' | 'destroy' | 'pedigree' | 'thoromanager' | '5-cross'
  label?: string
  hideTooltip?: boolean
}

type Ref = HTMLButtonElement

const defaultIcons: Record<string, ReactNode> = {
  new: <Plus />,
  edit: <Pencil />,
  destroy: <Trash />,
  pedigree: <FileInfo />,
  thoromanager: <Stopwatch />,
  fiveCross: <Sitemap className="-rotate-90" />,
}

const Action = forwardRef<Ref, ActionProps>(
  ({ name, hideTooltip = false, iconOnly = false, simpleLink = false, ...props }, ref) => {
    const { t } = useTranslation()

    const { label = t(`actions.${name}`), iconLeft = defaultIcons[name], ...linkProps } = props

    const L = simpleLink ? SimpleLink : Link

    return (
      <BasicTooltip content={label} disabled={hideTooltip || !iconOnly}>
        <L ref={ref} iconLeft={iconLeft} iconOnly={iconOnly} noChevron {...linkProps}>
          {iconOnly ? null : label}
        </L>
      </BasicTooltip>
    )
  },
)

export { Action }
