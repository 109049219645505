import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const FinanceMonthlyEntriesShow = () => {
  const { financeMonthlyEntriesShow } = useLoaderQueryBuilders()
  const { record: financeMonthlyEntry } = useResourceQuery(financeMonthlyEntriesShow)

  return <Section title={financeMonthlyEntry.title} className="mt-16"></Section>
}

export default FinanceMonthlyEntriesShow
