export const esnColor = (score?: number) => {
  if (!score) {
    return ''
  }

  if (score >= 0.025) {
    return 'green'
  } else if (score >= 0.02) {
    return 'yellow-500'
  } else {
    return 'red-500'
  }
}
