import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { InfoTile } from '@/components/info_tile'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { esnColor } from '@/resources/models/fpi_prediction/utils'
import { DBTEsnSummary } from '@/types'
import { formatPercentage } from '@/utils'

export const EsnSummaryContent = ({ dbtEsnSummary }: { dbtEsnSummary: DBTEsnSummary }) => {
  const { cropSexRank, cropSexCount, gradedStakesWinner, cropSexSireRank, cropSexSireCount } =
    dbtEsnSummary

  return (
    <div className="grid auto-rows-4rem grid-cols-12 gap-2 @container">
      <InfoTile label="Crop Rank" className="col-span-6">
        {cropSexRank}/{cropSexCount}
      </InfoTile>
      <DataTile label="Score" className="col-span-6 row-span-2">
        <span className={`text-${esnColor(gradedStakesWinner)}`}>
          {formatPercentage(gradedStakesWinner, 2)}
        </span>
      </DataTile>
      <InfoTile label="Sire Rank" className="col-span-6">
        {cropSexSireRank}/{cropSexSireCount}
      </InfoTile>
    </div>
  )
}

const EsnSummarySummaryContent = ({ dbtEsnSummaries }: { dbtEsnSummaries: DBTEsnSummary[] }) => {
  return <EsnSummaryContent dbtEsnSummary={dbtEsnSummaries[0]} />
}

export const EsnSummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: dbtEsnSummaries, error, isPending } = useResourceQuery(query)
  const pluralName = 'ESN Summary'

  return (
    <SummaryCard
      actions={null}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
    >
      {dbtEsnSummaries.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <EsnSummarySummaryContent dbtEsnSummaries={dbtEsnSummaries} />
      )}
    </SummaryCard>
  )
}
