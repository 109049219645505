import { generatePath, type Path } from 'react-router-dom'

import qs from 'qs'

import { RouteOptions, mergeRouteOptions } from './options'
import { buildPathHelperOptions } from './options'
import { pathTemplateFromParts } from './params'
import { RoutePart } from './types'

export interface RouteHelper {
  (options?: RouteOptions): Partial<Path>
  canBuildPath: (options?: RouteOptions) => boolean
  withOptions: (options?: RouteOptions) => RouteHelper
  baseOptions?: RouteOptions
  pathTemplate: string
  routeParts: RoutePart[]
  [key: string]: RouteHelper
}

function defineRoute(
  routeParts: RoutePart[] = [],
  options?: RouteOptions,
  children?: Record<string, RouteHelper>,
): RouteHelper {
  const baseOptions = options
  const pathTemplate = pathTemplateFromParts(routeParts)

  const buildOptions = (options?: RouteOptions) =>
    buildPathHelperOptions({
      parts: routeParts,
      options: mergeRouteOptions(baseOptions, options),
    })

  const routeHelper = (options: RouteOptions = {}): Partial<Path> => {
    const { queryParams, pathParams, relative } = buildOptions(options)

    const template = (relative ? '' : '/') + pathTemplate

    let pathname

    try {
      pathname = generatePath(template, pathParams)
    } catch (error) {
      throw new Error(`Failed building path for '${pathTemplate}': ${error}`)
    }

    return {
      pathname,
      search: qs.stringify(queryParams),
    }
  }

  routeHelper.withOptions = (options: RouteOptions) =>
    defineRoute(routeParts, mergeRouteOptions(baseOptions, options))

  /**
   * Checks if the route has all required
   * path parameters
   */
  routeHelper.canBuildPath = (options?: RouteOptions): boolean => {
    const { pathname } = routeHelper(options)
    return !pathname.includes(':') // checks that dynamic segments have all been resolved
  }

  routeHelper.baseOptions = baseOptions
  routeHelper.buildOptions = buildOptions
  routeHelper.pathTemplate = pathTemplate
  routeHelper.routeParts = routeParts

  if (children) {
    Object.keys(children).forEach((name) => (routeHelper[name] = children[name]))
  }

  return routeHelper
}

interface RoutePartTree {
  [key: string]: RoutePart[] | RoutePartTree
}

interface RouteHelperTree {
  [key: string]: RouteHelper | RouteHelperTree
}

const defineRoutesFromTree = (tree: RoutePartTree): RouteHelperTree =>
  Object.keys(tree).reduce((res: RouteHelperTree, key: string) => {
    const entry = tree[key]

    if (!Array.isArray(entry) || typeof entry !== 'object') {
      console.warn('Given invalid segment when building path helper ', entry)
      return res
    }

    const helper = Array.isArray(entry) ? defineRoute(entry) : defineRoutesFromTree(entry)

    return { ...res, [key]: helper }
  }, {})

export { defineRoute, defineRoutesFromTree }
