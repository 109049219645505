import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTSeasonDetail } from '@/types'

const name = 'DBTSeasonDetail'

const recordNameSingular = 'dbtSeasonDetail'
const recordNamePlural = 'dbtSeasonDetails'
const i18nKey = 'dbt::SeasonDetail'

const primaryKey = 'seasonId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtSeasonDetails'

const pageHelperKey = 'dbtSeasonDetails'

const resourcePathName = 'dbt_season_detail'

const attributes: Record<string, AttributeSpec> = {
  seasonId: {
    kind: 'column',
    name: 'seasonId',
    type: 'integer',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  stallionName: {
    kind: 'column',
    name: 'stallionName',
    type: 'text',
  },
  stallionYob: {
    kind: 'column',
    name: 'stallionYob',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  mareName: {
    kind: 'column',
    name: 'mareName',
    type: 'text',
  },
  mareYob: {
    kind: 'column',
    name: 'mareYob',
    type: 'integer',
  },
  agentHfmWinstarClientId: {
    kind: 'column',
    name: 'agentHfmWinstarClientId',
    type: 'integer',
  },
  shareId: {
    kind: 'column',
    name: 'shareId',
    type: 'integer',
  },
  assignedUserId: {
    kind: 'column',
    name: 'assignedUserId',
    type: 'integer',
  },
  assignedUserName: {
    kind: 'column',
    name: 'assignedUserName',
    type: 'text',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  seasonType: {
    kind: 'column',
    name: 'seasonType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'stands_and_nurses',
        },
        {
          value: 1,
          key: 'no_guarantee',
        },
        {
          value: 2,
          key: 'breeding_right',
        },
        {
          value: 3,
          key: 'shareholder_usage',
        },
        {
          value: 4,
          key: 'pay_from_proceeds',
        },
        {
          value: 5,
          key: 'mare_share',
        },
        {
          value: 6,
          key: 'foal_share',
        },
        {
          value: 7,
          key: 'complimentary',
        },
        {
          value: 8,
          key: 'donated',
        },
      ],
    },
  },
  target: {
    kind: 'column',
    name: 'target',
    type: 'boolean',
  },
  targetStatus: {
    kind: 'column',
    name: 'targetStatus',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'lost',
        },
        {
          value: 1,
          key: 'pending',
        },
        {
          value: 2,
          key: 'won',
        },
      ],
    },
  },
  contractStatus: {
    kind: 'column',
    name: 'contractStatus',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'approved',
        },
        {
          value: 1,
          key: 'received',
        },
        {
          value: 2,
          key: 'declined',
        },
        {
          value: 3,
          key: 'hold',
        },
        {
          value: 4,
          key: 'canceled_by_client',
        },
        {
          value: 5,
          key: 'review',
        },
        {
          value: 6,
          key: 'sent_to_client',
        },
        {
          value: 7,
          key: 'sent_to_admin',
        },
        {
          value: 8,
          key: 'admin_issue',
        },
        {
          value: 9,
          key: 'stallion_changed',
        },
        {
          value: 10,
          key: 'wait_list',
        },
      ],
    },
  },
  contractDate: {
    kind: 'column',
    name: 'contractDate',
    type: 'date',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'integer',
  },
  agentCommission: {
    kind: 'column',
    name: 'agentCommission',
    type: 'decimal',
  },
  contractDeliveryInstructions: {
    kind: 'column',
    name: 'contractDeliveryInstructions',
    type: 'text',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  hfmSeasonBookId: {
    kind: 'column',
    name: 'hfmSeasonBookId',
    type: 'integer',
  },
  ownerCount: {
    kind: 'column',
    name: 'ownerCount',
    type: 'integer',
  },
  mare: {
    kind: 'submodel',
    name: 'mare',
    modelName: 'DBTHorse',
    columns: ['horseId', 'name', 'yob', 'sex', 'sireId', 'sireName', 'damId', 'damName'],
  },
  stallion: {
    kind: 'submodel',
    name: 'stallion',
    modelName: 'DBTHorse',
    columns: ['horseId', 'name', 'yob', 'sex'],
  },
  agentClientDetail: {
    kind: 'submodel',
    name: 'agentClientDetail',
    modelName: 'DBTHfmClientDetail',
    columns: [
      'hfmClientId',
      'lastName',
      'firstName',
      'middleName',
      'businessName',
      'prefix',
      'suffix',
      'title',
      'businessNameFlag',
      'taxType',
      'taxNumber',
      'farmOwnerFlag',
      'maintainBalanceFlag',
      'servicesTaxExemptionCode',
      'standardServiceChargeId',
      'syndicateServiceChargeId',
      'studFeeServiceChargeId',
      'activeFlag',
      'companyId',
      'salutation',
      'accountNumber',
      'webAccountFlag',
      'invoiceDistributionMethod',
      'hfmAddressId',
      'addressTable',
      'addressSourceHfmId',
      'addressCategory',
      'mainAddressFlag',
      'address',
      'city',
      'state',
      'country',
      'zipCode',
      'addressFormat',
      'hfmPhoneId',
      'sourceTableName',
      'sourceTableHfmId',
      'phoneNumber',
      'phoneDescription',
      'fullPhoneDescription',
      'mainPhoneFlag',
    ],
  },
  ownerClientDetail: {
    kind: 'submodel',
    name: 'ownerClientDetail',
    modelName: 'DBTHfmClientDetail',
    columns: [
      'hfmClientId',
      'lastName',
      'firstName',
      'middleName',
      'businessName',
      'prefix',
      'suffix',
      'title',
      'businessNameFlag',
      'taxType',
      'taxNumber',
      'farmOwnerFlag',
      'maintainBalanceFlag',
      'servicesTaxExemptionCode',
      'standardServiceChargeId',
      'syndicateServiceChargeId',
      'studFeeServiceChargeId',
      'activeFlag',
      'companyId',
      'salutation',
      'accountNumber',
      'webAccountFlag',
      'invoiceDistributionMethod',
      'hfmAddressId',
      'addressTable',
      'addressSourceHfmId',
      'addressCategory',
      'mainAddressFlag',
      'address',
      'city',
      'state',
      'country',
      'zipCode',
      'addressFormat',
      'hfmPhoneId',
      'sourceTableName',
      'sourceTableHfmId',
      'phoneNumber',
      'phoneDescription',
      'fullPhoneDescription',
      'mainPhoneFlag',
    ],
  },
  reproductionStatus: {
    kind: 'submodel',
    name: 'reproductionStatus',
    modelName: 'DBTReproductionStatus',
    columns: ['isMaiden', 'coverDate', 'sex', 'status'],
  },
  priorYearReproductionStatus: {
    kind: 'submodel',
    name: 'priorYearReproductionStatus',
    modelName: 'DBTReproductionStatus',
    columns: ['isMaiden', 'coverDate', 'sex', 'status'],
  },
  priorYearCoveringStallion: {
    kind: 'submodel',
    name: 'priorYearCoveringStallion',
    modelName: 'DBTHorse',
    columns: ['horseId', 'sex', 'name', 'yob'],
  },
  priorYearStudFee: {
    kind: 'submodel',
    name: 'priorYearStudFee',
    modelName: 'StudFee',
    columns: ['price', 'private', 'farm', 'currency'],
  },
  hfmSeason: {
    kind: 'submodel',
    name: 'hfmSeason',
    modelName: 'DBTHfmSeason',
    columns: [
      'hemisphere',
      'shareNumber',
      'contractType',
      'contractTypeDate',
      'studFeeAmount',
      'upFrontAmount',
      'terms',
      'bookingConfirmedBy',
      'bookingConfirmedDate',
      'commissionPayableTo',
      'commissionPercent',
      'commissionAmountDue',
      'commissionDatePaid',
    ],
  },
}

const modelSpec: ModelSpec<DBTSeasonDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
