import { Suspense } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { HorseSearch } from '@/components/horse-search/horse-search'
import { useSidebar } from '@/components/ui/sidebar'
import { useAuth } from '@/contexts/auth'
import { bloodstockRoutes, booksRoutes, salesRoutes } from '@/routes'

import { PanelContent } from './content'
import { BroodmarePanel } from './panels/broodmare-panel'
import { CropsPanel } from './panels/crops-panel'
import { HomePanel } from './panels/home-panel'
import { HorsePanel } from './panels/horse-panel'
import { RacehorsePanel } from './panels/racehorse-panel'
import { SalesPanel } from './panels/sales-panel'
import { SeasonPanel } from './panels/season-panel'
import { StallionsPanel } from './panels/stallions-panel'

const Panel = () => {
  const location = useLocation()
  const { searchActive } = useSidebar()
  const { organization } = useAuth()

  const routes = [
    [{ path: '/', end: true }, <HomePanel key="home" />],
    [{ path: '/horses', end: false }, <HorsePanel key="horses" />],
    [{ path: bloodstockRoutes.crops.pathTemplate, end: false }, <CropsPanel key="crops" />],
    [
      { path: bloodstockRoutes.racehorses.pathTemplate, end: false },
      <RacehorsePanel key="racehorses" />,
    ],
    [
      { path: bloodstockRoutes.broodmares.pathTemplate, end: false },
      <BroodmarePanel key="broodmares" />,
    ],
    [
      { path: bloodstockRoutes.stallions.pathTemplate, end: false },
      <StallionsPanel key="stallions" />,
    ],
    [{ path: salesRoutes.index.pathTemplate, end: false }, <SalesPanel key="sales" />],
    [{ path: booksRoutes.index.pathTemplate, end: false }, <SeasonPanel key="seasons" />],
  ]

  if (searchActive) {
    return (
      <Suspense>
        <HorseSearch />
      </Suspense>
    )
  }

  const match = routes.find(([pattern]) => matchPath(pattern, location.pathname))

  return match ? match[1] : <PanelContent title={organization.title} />
}

export { Panel }
