import React from 'react'

import { HorseSearch } from '@/components/horse-search/horse-search'
import { Search, X } from '@/components/icons'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogOverlay,
  DialogPortal,
  DialogPrimitiveContent,
} from '@/components/ui/dialog'
import { useSidebar } from '@/components/ui/sidebar'
import { useBreakpoints } from '@/hooks'
import { cn } from '@/utils'

const PageActions = () => {
  const { searchActive, setSearchActive } = useSidebar()
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return (
      <>
        <Button
          className="fixed bottom-5 right-5 z-10 border-2 bg-white xl:hidden"
          size="3xl"
          variant="outline"
          onClick={() => {
            setSearchActive(true)
          }}
          iconLeft={<Search />}
          iconOnly
        />
        <Dialog
          open={searchActive}
          onOpenChange={() => {
            setSearchActive((current) => !current)
          }}
        >
          <DialogPortal>
            <DialogOverlay className="fixed z-30 bg-background" />
            <DialogPrimitiveContent
              className={cn(
                'fixed left-1/2 top-1/2 z-50 h-full w-[700px] -translate-x-1/2 -translate-y-1/2 gap-4 p-0 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg phone:w-dvw',
              )}
            >
              <HorseSearch />
              <DialogClose className="fixed right-0 top-12 phone:top-1" asChild>
                <Button
                  variant="inline"
                  size="2xl"
                  iconLeft={<X />}
                  className="text-muted-foreground"
                  iconOnly
                />
              </DialogClose>
            </DialogPrimitiveContent>
          </DialogPortal>
        </Dialog>
      </>
    )
  }
}

export { PageActions }
