import * as aq from 'arquero'
import { random } from 'lodash'

type RowData = Record<string, unknown>

// Add type for rows and paths to avoid unsafe assignment
export function flattenData(rows: RowData[], ...paths: (keyof RowData)[]): RowData[] {
  return rows.map((row: RowData) => ({
    ...paths.reduce<RowData>((acc, path) => {
      // Use optional chaining and type assertion
      acc[path] = row.getValue ? (row.getValue(path) as RowData[keyof RowData]) : row[path]
      return acc
    }, {}),
  }))
}
// Function to generate tabular data (rows x columns) for the scatter chart
export const generateData = (rows = 4, cols = 6) =>
  [...Array<number>(rows)]
    // Map through rows and columns to create a data structure with 'x', 'y', and 'val' properties
    .map((_, row) =>
      [...Array<number>(cols)].map((_, col) => ({
        y: `Row ${row.toString()}`,
        x: `Col ${col.toString()}`,
        val: random(50, 200),
      })),
    )
    .flat() // Flatten the array of arrays into a single array of objects

// Function to convert categorical data into numeric values
export const uniqueValuesAtKey = <T>(data: T[], key: keyof T) => {
  const set = new Set()

  // Loop through data once to fill xSet and ySet
  data.forEach((item: T) => {
    set.add(item[key])
  })

  return [...set]
}

export const findMinMax = <T>(arr: T[], key: keyof T) => {
  return arr.reduce(
    (acc, curr: T) => {
      if (curr[key] > acc.max) acc.max = curr[key]
      if (curr[key] < acc.min) acc.min = curr[key]
      return acc
    },
    { max: -Infinity, min: Infinity },
  )
}

export function scaleNumber(value, minInput, maxInput, minScaled = 50, maxScaled = 90) {
  return ((maxInput - value) / (maxInput - minInput)) * (maxScaled - minScaled) + minScaled
}

export function calculateCrossTab(
  data: RowData[],
  x: keyof RowData,
  y: keyof RowData,
  totals: boolean,
) {
  if (!data.length) {
    return []
  }
  const aqTable = aq.from(data)
  const groupedData = aqTable.groupby(x, y).rollup({ count: aq.op.count() }).objects()

  if (!totals) {
    return groupedData
  }

  const result = []
  const xTotals: Record<string, number> = {}
  const yTotals: Record<string, number> = {}

  groupedData.forEach((item: RowData) => {
    const xValue = item[x] as string | number
    const yValue = item[y] as string | number
    const count = item.count as number

    result.push(item)

    // Ensure numeric values in xTotals and yTotals
    xTotals[xValue] = (Number(xTotals[xValue]) || 0) + count
    yTotals[yValue] = (Number(yTotals[yValue]) || 0) + count
  })

  Object.keys(xTotals).forEach((xValue) => {
    result.push({
      [x]: isNaN(xValue) ? xValue : Number(xValue),
      [y]: 'Total',
      count: xTotals[xValue],
    })
  })

  Object.keys(yTotals).forEach((yValue) => {
    result.push({
      [x]: 'Total',
      [y]: isNaN(yValue) ? yValue : Number(yValue),
      count: yTotals[yValue],
    })
  })

  const grandTotal = Object.values(xTotals).reduce((acc, cur) => acc + cur, 0)
  result.push({ [x]: 'Total', [y]: 'Total', count: grandTotal })

  return result
}
