import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { VideoForm } from '@/resources/video/form'

const VideosEdit = () => {
  const { videosEdit } = useLoaderQueryBuilders()
  const { record: video } = useResourceQuery(videosEdit)

  return (
    <Section title={`Edit ${video.title}`}>
      <VideoForm initialValues={video} />
    </Section>
  )
}

export default VideosEdit
