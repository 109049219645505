import React, { Suspense } from 'react'
import { type Field } from 'react-hook-form'

import { Autocomplete } from '@/components/autocomplete'
import { AutocompleteButton } from '@/components/autocomplete/button'
import { FileInput } from '@/components/file_input'
import { Loader } from '@/components/icon'
import { CurrencyInput } from '@/components/inputs/currency'
import { PercentageInput } from '@/components/inputs/percentage'
import { Checkbox } from '@/components/ui/checkbox'
import { FormControl, useFormField } from '@/components/ui/form'
import { Input, type InputProps } from '@/components/ui/input'
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import i18n from '@/libs/i18n/config'
import { findResource } from '@/libs/resource/find_resource'
import { type Attribute } from '@/libs/resources/types'

type FormInputType =
  | 'checkbox'
  | 'date'
  | 'file'
  | 'text'
  | 'select'
  | 'switch'
  | 'textarea'
  | 'autocomplete'
  | 'number'

interface FormInputProps extends InputProps {
  field: Field
  inputType?: FormInputType
  attribute: Attribute
}

const defaultInputType = (attribute: Attribute): FormInputType => {
  if (attribute.kind === 'association') {
    return 'autocomplete'
  }
  if (attribute.kind === 'attachment') {
    return 'file'
  }

  switch (attribute.type) {
    case 'text':
      return 'textarea'
    case 'enum':
      return 'select'
    case 'boolean':
      return 'switch'
    case 'decimal':
    case 'integer':
    case 'float':
      return 'number'
    case 'string':
      return 'text'
    case 'date':
      return 'date'
    default:
      return 'text'
  }
}

const onChangeWithCoercion = (type: string, onChange) => (event) => {
  event.preventDefault()

  const value = event.target.value
  if (value === '') return

  switch (type) {
    case 'number':
      onChange(Number(value))
      break
    default:
      onChange(value)
  }
}

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ field: { value, ...field }, inputType, attribute, onChange, ...props }, ref) => {
    const type = inputType ?? defaultInputType(attribute)
    const { label } = useFormField()

    const handleChange = (value) => {
      onChange && onChange(value)
      field.onChange(value)
    }

    const fallback = () => (
      <AutocompleteButton variant="input" iconLeft={<Loader className="animate-spin" />} />
    )

    switch (type) {
      case 'autocomplete':
        return (
          <FormControl>
            <Suspense fallback={fallback()}>
              <Autocomplete
                resource={findResource(attribute.modelName)}
                label={label}
                initialSelectedId={value}
                onSelect={handleChange}
                variant="input"
                {...props}
              />
            </Suspense>
          </FormControl>
        )
      case 'checkbox':
        return (
          <FormControl>
            <Checkbox checked={value} onCheckedChange={handleChange} />
          </FormControl>
        )
      case 'currency':
        return (
          <FormControl>
            <CurrencyInput ref={ref} value={value} onChange={handleChange} {...props} />
          </FormControl>
        )
      case 'percentage':
        return (
          <FormControl>
            <PercentageInput ref={ref} value={value} onChange={handleChange} {...props} />
          </FormControl>
        )
      case 'select':
        return (
          <Select onValueChange={handleChange} value={value} {...props}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={`Select a ${label}`} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {attribute.domain.values.map((item) => (
                <SelectItem key={item.key} value={item.key}>
                  {i18n.t(item.key)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )
      case 'switch':
        return (
          <FormControl>
            <Switch checked={value} onCheckedChange={handleChange} />
          </FormControl>
        )
      case 'textarea':
        return (
          <FormControl>
            <Textarea ref={ref} value={value ?? ''} {...field} {...props} />
          </FormControl>
        )
      case 'file':
        return (
          <FormControl>
            <FileInput ref={ref} field={field} multiple={attribute.multiple} {...props} />
          </FormControl>
        )
      default:
        return (
          <FormControl>
            <Input
              ref={ref}
              type={type}
              value={value ?? ''}
              {...field}
              {...props}
              onChange={onChangeWithCoercion(type, handleChange)}
            />
          </FormControl>
        )
    }
  },
)
