import { RouteObject } from 'react-router-dom'

import { BaseQueryBuilder } from '@/libs/query/builder'
import { Resource } from '@/libs/resource/resource'
import { pathTemplateFromParts } from '@/libs/routes/params'
import { RoutePart } from '@/libs/routes/types'
import { compact } from '@/utils'
import { type QueryClient } from '@tanstack/react-query'

import { buildLoader } from './loader'

export interface ResourceRouteBuilder {
  resource: Resource
  routes: RouteObject[]
  build: (queryClient: QueryClient, context?: RoutePart[]) => RouteObject
}

export type RouteStatus = 'planned' | 'in progress' | 'preview' | 'released'

export type RouteObjectSpec = RouteObject & {
  file?: string
  page?: string
  query?: BaseQueryBuilder
  status?: RouteStatus
  children?: RouteObjectSpec[] | ResourceRouteBuilder
}

export const hasIndexChild = (route: RouteObjectSpec) =>
  route.children?.some((child: RouteObjectSpec) => child.index === true)

export const hasPathSegment = (route: RouteObjectSpec) =>
  route.index === true || (route.path != null && route.path.trim() !== '')

/**
 * Determines which routes are actual pages vs utilities for
 * either specifying layouts or grouping other routes
 */
export const routeIsPage = (route: RouteObjectSpec) => {
  // If route is specified as page or it's an index, return true
  if (route.handle?.isPage === true || route.index === true) return true

  // If a route has an index child, or it doesn't have a path,
  // it's not a page
  if (hasIndexChild(route) || !route.path) return false

  // If a route has some content, it's a page
  if (route.element != null || route.lazy != null || route.file != null || route.page != null)
    return true

  return false
}

const pathPartsFromPath = (path: string) => {
  const parts = path.split('/')

  return parts.filter((value: string) => value.trim() !== '')
}

const pathPartToRoutePart = (part: string, resource?: Resource): RoutePart => {
  if (!part.startsWith(':')) return part

  if (resource == null) {
    throw new Error(`Must supply resource or explicit routePart for path parameter: ${part}`)
  }

  const attributeName = part.replace(':', '')
  const attribute = resource.model.getAttribute(
    attributeName === 'id' ? resource.model.primaryKey : attributeName,
  )

  if (attribute == null)
    throw new Error(
      `Error parsing route parameter '${part}'. Attribute does not exist on model '${resource.name}'`,
    )

  const newName = resource.routeParamKey(attribute.name)

  return { name: newName, attributeId: attribute.globalId }
}

export const routePartsFromRoute = (route: RouteObjectSpec, resource?: Resource): RoutePart[] => {
  if (route.handle?.routePart != null) return route.handle.routePart

  if (route.path == null || route.index === true) return []

  const pathParts = pathPartsFromPath(route.path)

  return pathParts.map((part: string) => pathPartToRoutePart(part, resource))
}

export const createRouteParts = (
  route: RouteObjectSpec,
  parent?: RouteObject,
  resource?: Resource,
): RoutePart[] => {
  if (route.handle?.routeParts != null) return route.handle.routeParts

  const localRouteParts = routePartsFromRoute(route, resource)
  const parentRouteParts = (parent?.handle?.routeParts ?? []) as RoutePart[]

  return {
    routeParts: compact([...parentRouteParts, ...localRouteParts]) as RoutePart[],
    routePart: localRouteParts,
  }
}

export const createPathHelperKey = (route: RouteObjectSpec, routeParts: RoutePart[]) => {
  if (route.helper?.pathHelperKey != null) return route.helper.pathHelperKey

  const pathSegments = routeParts.map((part: RoutePart, idx) => {
    if (typeof part !== 'object') return part

    // TODO: Consider altering this name based on the param name
    //
    // E.g., if param name is `id` or like `horseid`, assume name is `show`.
    // If it's like `bookYear`, rename it as `bookYear`
    return 'show'
  })

  const [lastRoutePart] = routeParts.slice(-1)

  if (typeof lastRoutePart === 'object' && !routeIsPage(route)) return

  const addlSegment = route.index && typeof lastRoutePart !== 'object' ? 'index' : null

  const pathHelperKey = compact([...pathSegments, addlSegment]).join('.')

  return pathHelperKey
}

export interface CreateRoutesProps {
  resource?: Resource
  defaultStatus?: RouteStatus
  routes: RouteObjectSpec[]
}

export const createRoutes = ({
  resource,
  defaultStatus = 'released',
  routes,
}: CreateRoutesProps) => {
  const asSubRoute = ({ layoutType = 'sheet' }) => {
    // TODO: Imnplement filtering to potentially remove included sub routes
    // and also accept arguments like `only` to filter down by
    const newRoutes = routes.map((route: RouteObjectSpec) => ({
      ...route,
      handle: {
        ...route.handle,
        layoutType,
        isSubRoute: true,
      },
    }))

    return createRoutes({ resource, routes: newRoutes, defaultStatus })
  }

  const build = (
    queryClient: QueryClient,
    parent: RouteObject = {},
    modules: Record<string, unknown> = {},
  ): RouteObject[] => {
    const adjustRoute = (route: RouteObjectSpec, parent?: RouteObject): RouteObject => {
      const { routeParts, routePart } = createRouteParts(route, parent, resource)

      // Rename path parameters from :id to :horseId
      const newPath = pathTemplateFromParts(routePart)

      const loader =
        'query' in route ? buildLoader(queryClient, route.query, routeParts) : undefined

      // For some reason, import filepaths need to be relative to this file's location.
      // That is, you need `../../pages/....` to import a page
      const lazy = route.file
        ? () => import(route.file).then((m) => ({ Component: m.default }))
        : undefined

      const Component = route.page ? modules[route.page] : undefined
      const ErrorBoundary = route.errorElement ? undefined : modules.error

      const currentHandle = route.handle ?? {}

      const pathHelperKey = createPathHelperKey(route, routeParts)
      const [pathName] = pathHelperKey?.split('.')?.slice(-1) ?? []

      const actionType = ['show', 'edit', 'new', 'destroy'].includes(pathName) ? pathName : 'index'

      const { layoutType, isSubRoute } = parent?.handle ?? {}

      const newRoute = {
        ...route,
        path: newPath,
        loader,
        lazy: !Component ? lazy : undefined,
        Component,
        ErrorBoundary,
        handle: {
          actionType,
          query: route.query,
          resourceName: resource?.name,
          routePart,
          routeParts,
          pathHelperKey,
          pathName,
          isPage: routeIsPage(route),
          layoutType,
          isSubRoute,
          status: route.status ?? defaultStatus,
          ...currentHandle,
        },
      } as RouteObject

      const children = route.children
        ? route.children.flatMap((child: RouteObjectSpec | ResourceRouteBuilder) =>
            'resource' in child
              ? child.build(queryClient, newRoute, modules)
              : adjustRoute(child, newRoute),
          )
        : undefined

      return { ...newRoute, children } as RouteObject
    }

    return routes.map((route: RouteObjectSpec) => adjustRoute(route, parent))
  }

  return {
    routes,
    resource,
    build,
    asSubRoute,
  }
}
