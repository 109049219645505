import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctReportingPeriod } from '@/types'

const name = 'IntacctReportingPeriod'

const recordNameSingular = 'intacctReportingPeriod'
const recordNamePlural = 'intacctReportingPeriods'
const i18nKey = 'intacct::ReportingPeriod'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'intacctReportingPeriods'

const pageHelperKey = 'intacctReportingPeriods'

const resourcePathName = 'intacct_reporting_period'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  record: {
    kind: 'column',
    name: 'record',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  header1: {
    kind: 'column',
    name: 'header1',
    type: 'string',
  },
  header2: {
    kind: 'column',
    name: 'header2',
    type: 'string',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  budgeting: {
    kind: 'column',
    name: 'budgeting',
    type: 'boolean',
  },
  dateType: {
    kind: 'column',
    name: 'dateType',
    type: 'integer',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  nameOrig: {
    kind: 'column',
    name: 'nameOrig',
    type: 'string',
  },
  header1Orig: {
    kind: 'column',
    name: 'header1Orig',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<IntacctReportingPeriod> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
