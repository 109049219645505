import { useLocation } from 'react-router-dom'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { LayoutOutlet } from '@/pages/layouts/layout-outlet'
import { SeasonForm } from '@/resources/season/form'

const SeasonsEdit = () => {
  const { seasonsEdit } = useLoaderQueryBuilders()
  const { record: season } = useResourceQuery(seasonsEdit)
  const location = useLocation()

  const renderLayout = location.pathname.split('/').reverse()[2] !== 'seasons'

  return (
    <Section title={`Edit ${season.title}`}>
      <SeasonForm initialValues={season} />
      {renderLayout ? <LayoutOutlet /> : null}
    </Section>
  )
}

export default SeasonsEdit
