import React from 'react'

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { useBreakpoints } from '@/hooks'
import { cn, cva } from '@/utils'

import { ScrollArea } from './scroll-area'
import { Sidebar, SidebarContent, SidebarProvider } from './sidebar'

export const DrawerSheetTrigger = (props: React.ComponentPropsWithoutRef<typeof DrawerTrigger>) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerTrigger {...props} />
  } else {
    return <SheetTrigger {...props} />
  }
}

export const DrawerSheetContainer = (
  props: React.ComponentPropsWithoutRef<typeof DrawerContent>,
) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerContent {...props} />
  } else {
    return (
      <SheetContent {...props}>
        <SidebarProvider defaultOpen={false}>{props.children}</SidebarProvider>
      </SheetContent>
    )
  }
}

export const DrawerSheetSidebar = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.ComponentPropsWithoutRef<typeof DrawerContent>>) => {
  return (
    <Sidebar
      collapsible="icon"
      className={cn('absolute rounded-l border-none bg-white pt-4', className)}
      {...props}
    >
      <SidebarContent className="relative">{children}</SidebarContent>
    </Sidebar>
  )
}

const drawerSheetContentVariants = cva({
  base: '',
  variants: {
    size: {
      sm: 'w-[26rem]',
      md: 'w-[30rem]',
      lg: 'w-[36rem]',
      xl: 'w-[42rem]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export const DrawerSheetContent = ({
  children,
  size = 'md',
  className,
  ...props
}: React.PropsWithChildren<React.ComponentPropsWithoutRef<typeof DrawerContent>>) => {
  return (
    <ScrollArea
      className={cn(
        drawerSheetContentVariants({ size }),
        'h-[calc(100svh-2rem)] rounded-xl bg-background p-2 pb-0 transition-all',
        className,
      )}
      {...props}
    >
      {children}
    </ScrollArea>
  )
}

export const DrawerSheetHeader = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerHeader {...props} />
  } else {
    return <SheetHeader {...props} />
  }
}

export const DrawerSheetTitle = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerTitle {...props} />
  } else {
    return <SheetTitle {...props} />
  }
}

export const DrawerSheetDescription = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerDescription {...props} />
  } else {
    return <SheetDescription {...props} />
  }
}

export const DrawerSheetFooter = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerFooter {...props} />
  } else {
    return <SheetFooter {...props} />
  }
}

export const DrawerSheetClose = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <DrawerClose {...props} />
  } else {
    return <SheetClose {...props} />
  }
}

export const DrawerSheet = (props) => {
  const { isPhone } = useBreakpoints()

  if (isPhone) {
    return <Drawer {...props} />
  } else {
    return <Sheet {...props} />
  }
}
