import { type Resource } from '@/libs/resource/resource'

import { AccountResource } from './account/resource'
import { AppraisalResource } from './appraisal/resource'
import { BloodstockEntityResource } from './bloodstock_entity/resource'
import { BloodstockInventoryMonthlyResource } from './bloodstock_inventory_monthly/resource'
import { BroodmareProspectDetailResource } from './broodmare_prospect_detail/resource'
import { BroodmareProspectResource } from './broodmare_prospect/resource'
import { ConformationNoteResource } from './conformation_note/resource'
import { CoverResource } from './cover/resource'
import { DBTCareerRaceSummaryResource } from './dbt/career_race_summary/resource'
import { DBTEsnSummaryResource } from './dbt/esn_summary/resource'
import { DBTHfmClientDetailResource } from './dbt/hfm_client_detail/resource'
import { DBTHorseResource } from './dbt/horse/resource'
import { DBTMareProductionStatResource } from './dbt/mare_production_stat/resource'
import { DBTMatingEsnResource } from './dbt/mating_esn/resource'
import { DBTRaceResultResource } from './dbt/race_result/resource'
import { DBTRacehorseDetailResource } from './dbt/racehorse_detail/resource'
import { DBTRelativeResource } from './dbt/relative/resource'
import { DBTReproductionStatusResource } from './dbt/reproduction_status/resource'
import { DBTSaleResultResource } from './dbt/sale_result/resource'
import { DBTSeasonDetailResource } from './dbt/season_detail/resource'
import { DNASetResource } from './dna_set/resource'
import { DocumentResource } from './document/resource'
import { EntityResource } from './entity/resource'
import { FinanceAccountResource } from './finance/account/resource'
import { FinanceCustomerResource } from './finance/customer/resource'
import { FinanceDepartmentResource } from './finance/department/resource'
import { FinanceEmployeeResource } from './finance/employee/resource'
import { FinanceEntryResource } from './finance/entry/resource'
import { FinanceLocationResource } from './finance/location/resource'
import { FinanceMonthlyEntryResource } from './finance/monthly_entry/resource'
import { FinanceSageClassResource } from './finance/sage_class/resource'
import { FinanceVendorResource } from './finance/vendor/resource'
import { HfmWinstarClientResource } from './hfm_winstar/client/resource'
import { HorseDetailResource } from './horse_detail/resource'
import { HorseResource } from './horse/resource'
import { ImageResource } from './image/resource'
import { MatingResource } from './mating/resource'
import { ModelsFpiPredictionResource } from './models/fpi_prediction/resource'
import { MonthlyOwnershipsByDivisionResource } from './monthly_ownerships_by_division/resource'
import { OrganizationHorseResource } from './organization_horse/resource'
import { OrganizationResource } from './organization/resource'
import { PedigreeResource } from './pedigree/resource'
import { PregnancyCheckResource } from './pregnancy_check/resource'
import { RacehorseCommentResource } from './racehorse_comment/resource'
import { SaleRecordDetailResource } from './sale_record_detail/resource'
import { SaleRecordResource } from './sale_record/resource'
import { SaleResource } from './sale/resource'
import { SeasonResource } from './season/resource'
import { ShareResource } from './share/resource'
import { ShortlistResource } from './shortlist/resource'
import { StudFeeResource } from './stud_fee/resource'
import { TrainingReportResource } from './training_report/resource'
import { UserResource } from './user/resource'
import { VideoResource } from './video/resource'

export const Resources: Record<string, Resource> = {
  Account: AccountResource,
  Appraisal: AppraisalResource,
  BloodstockEntity: BloodstockEntityResource,
  BloodstockInventoryMonthly: BloodstockInventoryMonthlyResource,
  BroodmareProspect: BroodmareProspectResource,
  BroodmareProspectDetail: BroodmareProspectDetailResource,
  ConformationNote: ConformationNoteResource,
  Cover: CoverResource,
  DBTCareerRaceSummary: DBTCareerRaceSummaryResource,
  DBTEsnSummary: DBTEsnSummaryResource,
  DBTHfmClientDetail: DBTHfmClientDetailResource,
  DBTHorse: DBTHorseResource,
  DBTMareProductionStat: DBTMareProductionStatResource,
  DBTMatingEsn: DBTMatingEsnResource,
  DBTRaceResult: DBTRaceResultResource,
  DBTRacehorseDetail: DBTRacehorseDetailResource,
  DBTRelative: DBTRelativeResource,
  DBTReproductionStatus: DBTReproductionStatusResource,
  DBTSaleResult: DBTSaleResultResource,
  DBTSeasonDetail: DBTSeasonDetailResource,
  DNASet: DNASetResource,
  Document: DocumentResource,
  Entity: EntityResource,
  FinanceAccount: FinanceAccountResource,
  FinanceCustomer: FinanceCustomerResource,
  FinanceDepartment: FinanceDepartmentResource,
  FinanceEmployee: FinanceEmployeeResource,
  FinanceEntry: FinanceEntryResource,
  FinanceLocation: FinanceLocationResource,
  FinanceMonthlyEntry: FinanceMonthlyEntryResource,
  FinanceSageClass: FinanceSageClassResource,
  FinanceVendor: FinanceVendorResource,
  HfmWinstarClient: HfmWinstarClientResource,
  Horse: HorseResource,
  HorseDetail: HorseDetailResource,
  Image: ImageResource,
  Mating: MatingResource,
  ModelsFpiPrediction: ModelsFpiPredictionResource,
  MonthlyOwnershipsByDivision: MonthlyOwnershipsByDivisionResource,
  Organization: OrganizationResource,
  OrganizationHorse: OrganizationHorseResource,
  Pedigree: PedigreeResource,
  PregnancyCheck: PregnancyCheckResource,
  RacehorseComment: RacehorseCommentResource,
  Sale: SaleResource,
  SaleRecord: SaleRecordResource,
  SaleRecordDetail: SaleRecordDetailResource,
  Season: SeasonResource,
  Share: ShareResource,
  Shortlist: ShortlistResource,
  StudFee: StudFeeResource,
  TrainingReport: TrainingReportResource,
  User: UserResource,
  Video: VideoResource,
}
