import { type ModelSpec } from '@/libs/resource/model_spec'

import AccountSpec from './account_spec'
import AppraisalSpec from './appraisal_spec'
import ArionSearchHorseSpec from './arion/search_horse_spec'
import AuditSpec from './audit_spec'
import BloodstockEntitySpec from './bloodstock_entity_spec'
import BloodstockInventoryMonthlySpec from './bloodstock_inventory_monthly_spec'
import BreederSpec from './breeder_spec'
import BreedingSpec from './breeding_spec'
import BreedingStatSpec from './breeding_stat_spec'
import BrisnetBreeder8010HorseSpec from './brisnet/breeder8010_horse_spec'
import BrisnetHorseSpec from './brisnet/horse_spec'
import BrisnetNameSpec from './brisnet/name_spec'
import BrisnetReport210HorseSpec from './brisnet/report210_horse_spec'
import BrisnetReport310HorseSpec from './brisnet/report310_horse_spec'
import BrisnetReport320HorseSpec from './brisnet/report320_horse_spec'
import BrisnetReport782DamSpec from './brisnet/report782_dam_spec'
import BrisnetReport782HorseSpec from './brisnet/report782_horse_spec'
import BrisnetReport782ProgenySpec from './brisnet/report782_progeny_spec'
import BrisnetSearchHorseSpec from './brisnet/search_horse_spec'
import BroodmareProspectDetailSpec from './broodmare_prospect_detail_spec'
import BroodmareProspectSpec from './broodmare_prospect_spec'
import BudgetItemSpec from './budget_item_spec'
import BudgetSpec from './budget_spec'
import CalendarEventSpec from './calendar_event_spec'
import CompanyDocumentSpec from './company_document_spec'
import ConformationNoteSpec from './conformation_note_spec'
import CoverSpec from './cover_spec'
import DBTCandidateHorseSpec from './dbt/candidate_horse_spec'
import DBTCareerRaceSummarySpec from './dbt/career_race_summary_spec'
import DBTCareerSaleSummarySpec from './dbt/career_sale_summary_spec'
import DBTEsnSummarySpec from './dbt/esn_summary_spec'
import DBTHfmClientDetailSpec from './dbt/hfm_client_detail_spec'
import DBTHfmSeasonSpec from './dbt/hfm_season_spec'
import DBTHorseSpec from './dbt/horse_spec'
import DBTKeenelandImageSpec from './dbt/keeneland_image_spec'
import DBTMareEsnSummarySpec from './dbt/mare_esn_summary_spec'
import DBTMareProduceRecordSpec from './dbt/mare_produce_record_spec'
import DBTMareProductionStatSpec from './dbt/mare_production_stat_spec'
import DBTMatingEsnSpec from './dbt/mating_esn_spec'
import DBTMatingSpec from './dbt/mating_spec'
import DBTMeasurementReferenceSpec from './dbt/measurement_reference_spec'
import DBTMeasurementSpec from './dbt/measurement_spec'
import DBTOrganizationHorseSpec from './dbt/organization_horse_spec'
import DBTRaceResultSpec from './dbt/race_result_spec'
import DBTRaceSpec from './dbt/race_spec'
import DBTRacehorseDetailSpec from './dbt/racehorse_detail_spec'
import DBTRelativeSpec from './dbt/relative_spec'
import DBTReproductionStatusSpec from './dbt/reproduction_status_spec'
import DBTSaleResultSpec from './dbt/sale_result_spec'
import DBTSeasonDetailSpec from './dbt/season_detail_spec'
import DBTStallionStudDetailSpec from './dbt/stallion_stud_detail_spec'
import DBTTrackSpec from './dbt/track_spec'
import DBTWorkoutSpec from './dbt/workout_spec'
import DNASetSpec from './dna_set_spec'
import DNASetTypeSpec from './dna_set_type_spec'
import DocumentSpec from './document_spec'
import DRFBeyerSpec from './drf/beyer_spec'
import DRFChartsExoticSpec from './drf/charts_exotic_spec'
import DRFChartsHorseSpec from './drf/charts_horse_spec'
import DRFChartsMainSpec from './drf/charts_main_spec'
import DRFChartsPaceProjectorLayoutSpec from './drf/charts_pace_projector_layout_spec'
import DRFChartsPaceProjectorTextSpec from './drf/charts_pace_projector_text_spec'
import DRFChartsRaceDataSpec from './drf/charts_race_data_spec'
import DRFChartsStarterDataSpec from './drf/charts_starter_data_spec'
import DRFChartsTopFinisherSpec from './drf/charts_top_finisher_spec'
import DRFChartsTracksRaceDateSpec from './drf/charts_tracks_race_date_spec'
import DRFExoticPayoffSpec from './drf/exotic_payoff_spec'
import DRFRaceSpec from './drf/race_spec'
import DRFStartSpec from './drf/start_spec'
import EntitySpec from './entity_spec'
import FamilySpec from './family_spec'
import FasigTiptonResultSpec from './fasig_tipton/result_spec'
import FasigTiptonSaleSpec from './fasig_tipton/sale_spec'
import FederalReserveCurrencyExchangeRateSpec from './federal_reserve/currency_exchange_rate_spec'
import FinanceAccountSpec from './finance/account_spec'
import FinanceBudgetItemSpec from './finance/budget_item_spec'
import FinanceBudgetSpec from './finance/budget_spec'
import FinanceCustomerSpec from './finance/customer_spec'
import FinanceDepartmentSpec from './finance/department_spec'
import FinanceEmployeeSpec from './finance/employee_spec'
import FinanceEntrySpec from './finance/entry_spec'
import FinanceFinancialSpec from './finance/financial_spec'
import FinanceLocationSpec from './finance/location_spec'
import FinanceMonthlyEntrySpec from './finance/monthly_entry_spec'
import FinanceSageClassSpec from './finance/sage_class_spec'
import FinanceVendorSpec from './finance/vendor_spec'
import FoalSpec from './foal_spec'
import HairPullSpec from './hair_pull_spec'
import HairSampleBatchSpec from './hair_sample_batch_spec'
import HairSampleBinderSpec from './hair_sample_binder_spec'
import HairSampleBoxSpec from './hair_sample_box_spec'
import HairSampleSpec from './hair_sample_spec'
import HfmWinstarClientSpec from './hfm_winstar/client_spec'
import HorseDetailSpec from './horse_detail_spec'
import HorseSpec from './horse_spec'
import HorseTransactionSpec from './horse_transaction_spec'
import HorseTransactionTypeSpec from './horse_transaction_type_spec'
import ImageSpec from './image_spec'
import IntacctAccountSpec from './intacct/account_spec'
import IntacctBudgetItemSpec from './intacct/budget_item_spec'
import IntacctBudgetSpec from './intacct/budget_spec'
import IntacctCustomerSpec from './intacct/customer_spec'
import IntacctDepartmentSpec from './intacct/department_spec'
import IntacctEmployeeSpec from './intacct/employee_spec'
import IntacctGeneralLedgerDetailSpec from './intacct/general_ledger_detail_spec'
import IntacctJournalEntrySpec from './intacct/journal_entry_spec'
import IntacctLocationSpec from './intacct/location_spec'
import IntacctReportingPeriodSpec from './intacct/reporting_period_spec'
import IntacctSageClassSpec from './intacct/sage_class_spec'
import IntacctTrialBalanceSpec from './intacct/trial_balance_spec'
import IntacctVendorSpec from './intacct/vendor_spec'
import JockeyClubBreedingStatSpec from './jockey_club/breeding_stat_spec'
import JockeyClubMaresBredStatSpec from './jockey_club/mares_bred_stat_spec'
import KeenelandResultSpec from './keeneland/result_spec'
import KeenelandSaleSpec from './keeneland/sale_spec'
import KeenelandSearchResultSpec from './keeneland/search_result_spec'
import KeenelandSearchSaleSpec from './keeneland/search_sale_spec'
import MareSpec from './mare_spec'
import MatingSpec from './mating_spec'
import ModelsFpiMarePredictionSpec from './models/fpi_mare_prediction_spec'
import ModelsFpiPredictionSpec from './models/fpi_prediction_spec'
import ModelsFpiStallionPredictionSpec from './models/fpi_stallion_prediction_spec'
import ModelsModelSpec from './models/model_spec'
import ModelsModelVersionSpec from './models/model_version_spec'
import MonthlyOwnershipsByDivisionSpec from './monthly_ownerships_by_division_spec'
import NotificationSpec from './notification_spec'
import OBSResultSpec from './obs/result_spec'
import OBSSaleSpec from './obs/sale_spec'
import OrganizationHorseSpec from './organization_horse_spec'
import OrganizationSpec from './organization_spec'
import OwnerSpec from './owner_spec'
import PartnershipMemberSpec from './partnership_member_spec'
import PedigreeSpec from './pedigree_spec'
import PermissionSpec from './permission_spec'
import PhysicalMeasurementSpec from './physical_measurement_spec'
import PregnancyCheckSpec from './pregnancy_check_spec'
import ProjectedPurchaseSpec from './projected_purchase_spec'
import RacehorseCommentSpec from './racehorse_comment_spec'
import RacehorseSpec from './racehorse_spec'
import RoleSpec from './role_spec'
import SaleRecordDetailSpec from './sale_record_detail_spec'
import SaleRecordSpec from './sale_record_spec'
import SaleSpec from './sale_spec'
import ScoreSpec from './score_spec'
import SeasonSpec from './season_spec'
import SeasonTransactionSpec from './season_transaction_spec'
import SeasonTransactionTypeSpec from './season_transaction_type_spec'
import ShareSpec from './share_spec'
import ShareTransactionSpec from './share_transaction_spec'
import ShareTransactionTypeSpec from './share_transaction_type_spec'
import ShareTypeSpec from './share_type_spec'
import ShortlistSpec from './shortlist_spec'
import SNPChipReportSpec from './snp_chip_report_spec'
import SNPChipSpec from './snp_chip_spec'
import StallionBreedingEstimateSpec from './stallion_breeding_estimate_spec'
import StallionBreedingTargetSpec from './stallion_breeding_target_spec'
import StallionProspectSpec from './stallion_prospect_spec'
import StallionSpec from './stallion_spec'
import StudFeeSpec from './stud_fee_spec'
import SyndicateSpec from './syndicate_spec'
import TargetBreedingSpec from './target_breeding_spec'
import ThoromanagerRaceEntrySpec from './thoromanager/race_entry_spec'
import ThoromanagerRaceResultSpec from './thoromanager/race_result_spec'
import ThoromanagerWorkoutSpec from './thoromanager/workout_spec'
import TrainingReportSpec from './training_report_spec'
import UserSpec from './user_spec'
import VideoSpec from './video_spec'
import WeatherbysRaceResultSpec from './weatherbys/race_result_spec'
import YearlingSpec from './yearling_spec'

export const ModelSpecs: Record<string, ModelSpec> = {
  Account: AccountSpec,
  Appraisal: AppraisalSpec,
  ArionSearchHorse: ArionSearchHorseSpec,
  Audit: AuditSpec,
  BloodstockEntity: BloodstockEntitySpec,
  BloodstockInventoryMonthly: BloodstockInventoryMonthlySpec,
  Breeder: BreederSpec,
  Breeding: BreedingSpec,
  BreedingStat: BreedingStatSpec,
  BrisnetBreeder8010Horse: BrisnetBreeder8010HorseSpec,
  BrisnetHorse: BrisnetHorseSpec,
  BrisnetName: BrisnetNameSpec,
  BrisnetReport210Horse: BrisnetReport210HorseSpec,
  BrisnetReport310Horse: BrisnetReport310HorseSpec,
  BrisnetReport320Horse: BrisnetReport320HorseSpec,
  BrisnetReport782Dam: BrisnetReport782DamSpec,
  BrisnetReport782Horse: BrisnetReport782HorseSpec,
  BrisnetReport782Progeny: BrisnetReport782ProgenySpec,
  BrisnetSearchHorse: BrisnetSearchHorseSpec,
  BroodmareProspectDetail: BroodmareProspectDetailSpec,
  BroodmareProspect: BroodmareProspectSpec,
  BudgetItem: BudgetItemSpec,
  Budget: BudgetSpec,
  CalendarEvent: CalendarEventSpec,
  CompanyDocument: CompanyDocumentSpec,
  ConformationNote: ConformationNoteSpec,
  Cover: CoverSpec,
  DBTCandidateHorse: DBTCandidateHorseSpec,
  DBTCareerRaceSummary: DBTCareerRaceSummarySpec,
  DBTCareerSaleSummary: DBTCareerSaleSummarySpec,
  DBTEsnSummary: DBTEsnSummarySpec,
  DBTHfmClientDetail: DBTHfmClientDetailSpec,
  DBTHfmSeason: DBTHfmSeasonSpec,
  DBTHorse: DBTHorseSpec,
  DBTKeenelandImage: DBTKeenelandImageSpec,
  DBTMareEsnSummary: DBTMareEsnSummarySpec,
  DBTMareProduceRecord: DBTMareProduceRecordSpec,
  DBTMareProductionStat: DBTMareProductionStatSpec,
  DBTMatingEsn: DBTMatingEsnSpec,
  DBTMating: DBTMatingSpec,
  DBTMeasurementReference: DBTMeasurementReferenceSpec,
  DBTMeasurement: DBTMeasurementSpec,
  DBTOrganizationHorse: DBTOrganizationHorseSpec,
  DBTRaceResult: DBTRaceResultSpec,
  DBTRace: DBTRaceSpec,
  DBTRacehorseDetail: DBTRacehorseDetailSpec,
  DBTRelative: DBTRelativeSpec,
  DBTReproductionStatus: DBTReproductionStatusSpec,
  DBTSaleResult: DBTSaleResultSpec,
  DBTSeasonDetail: DBTSeasonDetailSpec,
  DBTStallionStudDetail: DBTStallionStudDetailSpec,
  DBTTrack: DBTTrackSpec,
  DBTWorkout: DBTWorkoutSpec,
  DNASet: DNASetSpec,
  DNASetType: DNASetTypeSpec,
  Document: DocumentSpec,
  DRFBeyer: DRFBeyerSpec,
  DRFChartsExotic: DRFChartsExoticSpec,
  DRFChartsHorse: DRFChartsHorseSpec,
  DRFChartsMain: DRFChartsMainSpec,
  DRFChartsPaceProjectorLayout: DRFChartsPaceProjectorLayoutSpec,
  DRFChartsPaceProjectorText: DRFChartsPaceProjectorTextSpec,
  DRFChartsRaceData: DRFChartsRaceDataSpec,
  DRFChartsStarterData: DRFChartsStarterDataSpec,
  DRFChartsTopFinisher: DRFChartsTopFinisherSpec,
  DRFChartsTracksRaceDate: DRFChartsTracksRaceDateSpec,
  DRFExoticPayoff: DRFExoticPayoffSpec,
  DRFRace: DRFRaceSpec,
  DRFStart: DRFStartSpec,
  Entity: EntitySpec,
  Family: FamilySpec,
  FasigTiptonResult: FasigTiptonResultSpec,
  FasigTiptonSale: FasigTiptonSaleSpec,
  FederalReserveCurrencyExchangeRate: FederalReserveCurrencyExchangeRateSpec,
  FinanceAccount: FinanceAccountSpec,
  FinanceBudgetItem: FinanceBudgetItemSpec,
  FinanceBudget: FinanceBudgetSpec,
  FinanceCustomer: FinanceCustomerSpec,
  FinanceDepartment: FinanceDepartmentSpec,
  FinanceEmployee: FinanceEmployeeSpec,
  FinanceEntry: FinanceEntrySpec,
  FinanceFinancial: FinanceFinancialSpec,
  FinanceLocation: FinanceLocationSpec,
  FinanceMonthlyEntry: FinanceMonthlyEntrySpec,
  FinanceSageClass: FinanceSageClassSpec,
  FinanceVendor: FinanceVendorSpec,
  Foal: FoalSpec,
  HairPull: HairPullSpec,
  HairSampleBatch: HairSampleBatchSpec,
  HairSampleBinder: HairSampleBinderSpec,
  HairSampleBox: HairSampleBoxSpec,
  HairSample: HairSampleSpec,
  HfmWinstarClient: HfmWinstarClientSpec,
  HorseDetail: HorseDetailSpec,
  Horse: HorseSpec,
  HorseTransaction: HorseTransactionSpec,
  HorseTransactionType: HorseTransactionTypeSpec,
  Image: ImageSpec,
  IntacctAccount: IntacctAccountSpec,
  IntacctBudgetItem: IntacctBudgetItemSpec,
  IntacctBudget: IntacctBudgetSpec,
  IntacctCustomer: IntacctCustomerSpec,
  IntacctDepartment: IntacctDepartmentSpec,
  IntacctEmployee: IntacctEmployeeSpec,
  IntacctGeneralLedgerDetail: IntacctGeneralLedgerDetailSpec,
  IntacctJournalEntry: IntacctJournalEntrySpec,
  IntacctLocation: IntacctLocationSpec,
  IntacctReportingPeriod: IntacctReportingPeriodSpec,
  IntacctSageClass: IntacctSageClassSpec,
  IntacctTrialBalance: IntacctTrialBalanceSpec,
  IntacctVendor: IntacctVendorSpec,
  JockeyClubBreedingStat: JockeyClubBreedingStatSpec,
  JockeyClubMaresBredStat: JockeyClubMaresBredStatSpec,
  KeenelandResult: KeenelandResultSpec,
  KeenelandSale: KeenelandSaleSpec,
  KeenelandSearchResult: KeenelandSearchResultSpec,
  KeenelandSearchSale: KeenelandSearchSaleSpec,
  Mare: MareSpec,
  Mating: MatingSpec,
  ModelsFpiMarePrediction: ModelsFpiMarePredictionSpec,
  ModelsFpiPrediction: ModelsFpiPredictionSpec,
  ModelsFpiStallionPrediction: ModelsFpiStallionPredictionSpec,
  ModelsModel: ModelsModelSpec,
  ModelsModelVersion: ModelsModelVersionSpec,
  MonthlyOwnershipsByDivision: MonthlyOwnershipsByDivisionSpec,
  Notification: NotificationSpec,
  OBSResult: OBSResultSpec,
  OBSSale: OBSSaleSpec,
  OrganizationHorse: OrganizationHorseSpec,
  Organization: OrganizationSpec,
  Owner: OwnerSpec,
  PartnershipMember: PartnershipMemberSpec,
  Pedigree: PedigreeSpec,
  Permission: PermissionSpec,
  PhysicalMeasurement: PhysicalMeasurementSpec,
  PregnancyCheck: PregnancyCheckSpec,
  ProjectedPurchase: ProjectedPurchaseSpec,
  RacehorseComment: RacehorseCommentSpec,
  Racehorse: RacehorseSpec,
  Role: RoleSpec,
  SaleRecordDetail: SaleRecordDetailSpec,
  SaleRecord: SaleRecordSpec,
  Sale: SaleSpec,
  Score: ScoreSpec,
  Season: SeasonSpec,
  SeasonTransaction: SeasonTransactionSpec,
  SeasonTransactionType: SeasonTransactionTypeSpec,
  Share: ShareSpec,
  ShareTransaction: ShareTransactionSpec,
  ShareTransactionType: ShareTransactionTypeSpec,
  ShareType: ShareTypeSpec,
  Shortlist: ShortlistSpec,
  SNPChipReport: SNPChipReportSpec,
  SNPChip: SNPChipSpec,
  StallionBreedingEstimate: StallionBreedingEstimateSpec,
  StallionBreedingTarget: StallionBreedingTargetSpec,
  StallionProspect: StallionProspectSpec,
  Stallion: StallionSpec,
  StudFee: StudFeeSpec,
  Syndicate: SyndicateSpec,
  TargetBreeding: TargetBreedingSpec,
  ThoromanagerRaceEntry: ThoromanagerRaceEntrySpec,
  ThoromanagerRaceResult: ThoromanagerRaceResultSpec,
  ThoromanagerWorkout: ThoromanagerWorkoutSpec,
  TrainingReport: TrainingReportSpec,
  User: UserSpec,
  Video: VideoSpec,
  WeatherbysRaceResult: WeatherbysRaceResultSpec,
  Yearling: YearlingSpec,
}
