import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTHfmClientDetail } from '@/types'

const name = 'DBTHfmClientDetail'

const recordNameSingular = 'dbtHfmClientDetail'
const recordNamePlural = 'dbtHfmClientDetails'
const i18nKey = 'dbt::HfmClientDetail'

const primaryKey = 'hfmClientId'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'dbtHfmClientDetails'

const pageHelperKey = 'dbtHfmClientDetails'

const resourcePathName = 'dbt_hfm_client_detail'

const attributes: Record<string, AttributeSpec> = {
  hfmClientId: {
    kind: 'column',
    name: 'hfmClientId',
    type: 'integer',
  },
  lastName: {
    kind: 'column',
    name: 'lastName',
    type: 'string',
  },
  firstName: {
    kind: 'column',
    name: 'firstName',
    type: 'string',
  },
  middleName: {
    kind: 'column',
    name: 'middleName',
    type: 'string',
  },
  businessName: {
    kind: 'column',
    name: 'businessName',
    type: 'string',
  },
  prefix: {
    kind: 'column',
    name: 'prefix',
    type: 'string',
  },
  suffix: {
    kind: 'column',
    name: 'suffix',
    type: 'string',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  businessNameFlag: {
    kind: 'column',
    name: 'businessNameFlag',
    type: 'boolean',
  },
  taxType: {
    kind: 'column',
    name: 'taxType',
    type: 'boolean',
  },
  taxNumber: {
    kind: 'column',
    name: 'taxNumber',
    type: 'string',
  },
  farmOwnerFlag: {
    kind: 'column',
    name: 'farmOwnerFlag',
    type: 'boolean',
  },
  maintainBalanceFlag: {
    kind: 'column',
    name: 'maintainBalanceFlag',
    type: 'boolean',
  },
  servicesTaxExemptionCode: {
    kind: 'column',
    name: 'servicesTaxExemptionCode',
    type: 'string',
  },
  standardServiceChargeId: {
    kind: 'column',
    name: 'standardServiceChargeId',
    type: 'integer',
  },
  syndicateServiceChargeId: {
    kind: 'column',
    name: 'syndicateServiceChargeId',
    type: 'integer',
  },
  studFeeServiceChargeId: {
    kind: 'column',
    name: 'studFeeServiceChargeId',
    type: 'integer',
  },
  activeFlag: {
    kind: 'column',
    name: 'activeFlag',
    type: 'boolean',
  },
  companyId: {
    kind: 'column',
    name: 'companyId',
    type: 'integer',
  },
  salutation: {
    kind: 'column',
    name: 'salutation',
    type: 'string',
  },
  accountNumber: {
    kind: 'column',
    name: 'accountNumber',
    type: 'string',
  },
  webAccountFlag: {
    kind: 'column',
    name: 'webAccountFlag',
    type: 'boolean',
  },
  invoiceDistributionMethod: {
    kind: 'column',
    name: 'invoiceDistributionMethod',
    type: 'string',
  },
  hfmAddressId: {
    kind: 'column',
    name: 'hfmAddressId',
    type: 'integer',
  },
  addressTable: {
    kind: 'column',
    name: 'addressTable',
    type: 'string',
  },
  addressSourceHfmId: {
    kind: 'column',
    name: 'addressSourceHfmId',
    type: 'integer',
  },
  addressCategory: {
    kind: 'column',
    name: 'addressCategory',
    type: 'string',
  },
  mainAddressFlag: {
    kind: 'column',
    name: 'mainAddressFlag',
    type: 'boolean',
  },
  address: {
    kind: 'column',
    name: 'address',
    type: 'string',
  },
  city: {
    kind: 'column',
    name: 'city',
    type: 'string',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  country: {
    kind: 'column',
    name: 'country',
    type: 'string',
  },
  zipCode: {
    kind: 'column',
    name: 'zipCode',
    type: 'string',
  },
  addressFormat: {
    kind: 'column',
    name: 'addressFormat',
    type: 'integer',
  },
  hfmPhoneId: {
    kind: 'column',
    name: 'hfmPhoneId',
    type: 'integer',
  },
  sourceTableName: {
    kind: 'column',
    name: 'sourceTableName',
    type: 'string',
  },
  sourceTableHfmId: {
    kind: 'column',
    name: 'sourceTableHfmId',
    type: 'integer',
  },
  phoneNumber: {
    kind: 'column',
    name: 'phoneNumber',
    type: 'string',
  },
  phoneDescription: {
    kind: 'column',
    name: 'phoneDescription',
    type: 'string',
  },
  fullPhoneDescription: {
    kind: 'column',
    name: 'fullPhoneDescription',
    type: 'string',
  },
  mainPhoneFlag: {
    kind: 'column',
    name: 'mainPhoneFlag',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<DBTHfmClientDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
