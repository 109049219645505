import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'KeenelandSearchResult'

const recordNameSingular = 'keenelandSearchResult'
const recordNamePlural = 'keenelandSearchResults'
const i18nKey = 'keeneland::SearchResult'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'keenelandSearchResults'

const pageHelperKey = 'keenelandSearchResults'

const resourcePathName = 'keeneland_search_result'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  searchSaleId: {
    kind: 'column',
    name: 'searchSaleId',
    type: 'integer',
  },
  keenelandSearchSaleId: {
    kind: 'column',
    name: 'keenelandSearchSaleId',
    type: 'integer',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'string',
  },
  damYob: {
    kind: 'column',
    name: 'damYob',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'string',
  },
  damDamName: {
    kind: 'column',
    name: 'damDamName',
    type: 'string',
  },
  damSireName: {
    kind: 'column',
    name: 'damSireName',
    type: 'string',
  },
  sireSireName: {
    kind: 'column',
    name: 'sireSireName',
    type: 'string',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  session: {
    kind: 'column',
    name: 'session',
    type: 'string',
  },
  book: {
    kind: 'column',
    name: 'book',
    type: 'string',
  },
  barn: {
    kind: 'column',
    name: 'barn',
    type: 'string',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  consignorFull: {
    kind: 'column',
    name: 'consignorFull',
    type: 'string',
  },
  soldAs: {
    kind: 'column',
    name: 'soldAs',
    type: 'string',
  },
  pregnancyStatus: {
    kind: 'column',
    name: 'pregnancyStatus',
    type: 'string',
  },
  lastServiceDate: {
    kind: 'column',
    name: 'lastServiceDate',
    type: 'date',
  },
  coveringSireName: {
    kind: 'column',
    name: 'coveringSireName',
    type: 'string',
  },
  coveringSireSireName: {
    kind: 'column',
    name: 'coveringSireSireName',
    type: 'string',
  },
  isBcEligible: {
    kind: 'column',
    name: 'isBcEligible',
    type: 'boolean',
  },
  buyer: {
    kind: 'column',
    name: 'buyer',
    type: 'string',
  },
  salePrice: {
    kind: 'column',
    name: 'salePrice',
    type: 'decimal',
  },
  isOut: {
    kind: 'column',
    name: 'isOut',
    type: 'boolean',
  },
  isRna: {
    kind: 'column',
    name: 'isRna',
    type: 'boolean',
  },
  isStakesProducingDam: {
    kind: 'column',
    name: 'isStakesProducingDam',
    type: 'boolean',
  },
  ppFileName: {
    kind: 'column',
    name: 'ppFileName',
    type: 'string',
  },
  stateFoaled: {
    kind: 'column',
    name: 'stateFoaled',
    type: 'string',
  },
  isCurrentSale: {
    kind: 'column',
    name: 'isCurrentSale',
    type: 'boolean',
  },
  isStakesProducer: {
    kind: 'column',
    name: 'isStakesProducer',
    type: 'boolean',
  },
  hasUpdate: {
    kind: 'column',
    name: 'hasUpdate',
    type: 'boolean',
  },
  lastShowDate: {
    kind: 'column',
    name: 'lastShowDate',
    type: 'date',
  },
  isOkToShow: {
    kind: 'column',
    name: 'isOkToShow',
    type: 'string',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'string',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'string',
  },
  showPedigree: {
    kind: 'column',
    name: 'showPedigree',
    type: 'boolean',
  },
  updatesLink: {
    kind: 'column',
    name: 'updatesLink',
    type: 'string',
  },
  catalogPageLink: {
    kind: 'column',
    name: 'catalogPageLink',
    type: 'string',
  },
  keeBidLink: {
    kind: 'column',
    name: 'keeBidLink',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  photos: {
    kind: 'attachment',
    name: 'photos',
    multiple: true,
  },
  videos: {
    kind: 'attachment',
    name: 'videos',
    multiple: true,
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
