import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'ShareTransaction'

const recordNameSingular = 'shareTransaction'
const recordNamePlural = 'shareTransactions'
const i18nKey = 'shareTransaction'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'shareTransactions'

const pageHelperKey = 'shareTransactions'

const resourcePathName = 'share_transaction'

const attributes: Record<string, AttributeSpec> = {
  share: {
    kind: 'association',
    name: 'share',
    modelName: 'Share',
    foreignKey: 'shareId',
    associationPrimaryKey: 'id',
  },
  sellerEntity: {
    kind: 'association',
    name: 'sellerEntity',
    modelName: 'Entity',
    foreignKey: 'sellerEntityId',
    associationPrimaryKey: 'id',
  },
  purchaserEntity: {
    kind: 'association',
    name: 'purchaserEntity',
    modelName: 'Entity',
    foreignKey: 'purchaserEntityId',
    associationPrimaryKey: 'id',
  },
  shareTransactionType: {
    kind: 'association',
    name: 'shareTransactionType',
    modelName: 'ShareTransactionType',
    foreignKey: 'shareTransactionTypeId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  shareId: {
    kind: 'column',
    name: 'shareId',
    type: 'integer',
  },
  shareTransactionTypeId: {
    kind: 'column',
    name: 'shareTransactionTypeId',
    type: 'integer',
  },
  sellerEntityId: {
    kind: 'column',
    name: 'sellerEntityId',
    type: 'integer',
  },
  purchaserEntityId: {
    kind: 'column',
    name: 'purchaserEntityId',
    type: 'integer',
  },
  transactionDate: {
    kind: 'column',
    name: 'transactionDate',
    type: 'date',
  },
  terms: {
    kind: 'column',
    name: 'terms',
    type: 'string',
  },
  percentage: {
    kind: 'column',
    name: 'percentage',
    type: 'decimal',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
