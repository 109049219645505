// JsFromRoutes CacheKey e6213b57f4f1b95646a59468ac8d5c21
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { VideoModel } from '@/models/video'

export const videosApi = {
  index: defineEndpoint({
    controllerName: 'videos',
    actionName: 'index',
    model: VideoModel,
    parts: ['api', 'videos'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'videos',
    actionName: 'create',
    model: VideoModel,
    parts: ['api', 'videos'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'videos',
    actionName: 'new',
    model: VideoModel,
    parts: ['api', 'videos', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'videos',
    actionName: 'edit',
    model: VideoModel,
    parts: ['api', 'videos', { name: 'id', attributeId: 'Video:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'videos',
    actionName: 'show',
    model: VideoModel,
    parts: ['api', 'videos', { name: 'id', attributeId: 'Video:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'videos',
    actionName: 'update',
    model: VideoModel,
    parts: ['api', 'videos', { name: 'id', attributeId: 'Video:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'videos',
    actionName: 'destroy',
    model: VideoModel,
    parts: ['api', 'videos', { name: 'id', attributeId: 'Video:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
