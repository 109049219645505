import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DNASet } from '@/types'

const name = 'DNASet'

const recordNameSingular = 'dnaSet'
const recordNamePlural = 'dnaSets'
const i18nKey = 'dnaSet'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dnaSets'

const pageHelperKey = 'dnaSets'

const resourcePathName = 'dna_set'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  dnaSetType: {
    kind: 'association',
    name: 'dnaSetType',
    modelName: 'DNASetType',
    foreignKey: 'dnaSetTypeId',
    associationPrimaryKey: 'id',
  },
  snpChip: {
    kind: 'association',
    name: 'snpChip',
    modelName: 'SNPChip',
    foreignKey: 'snpChipId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  dnaSetTypeId: {
    kind: 'column',
    name: 'dnaSetTypeId',
    type: 'integer',
  },
  validSNPPer: {
    kind: 'column',
    name: 'validSNPPer',
    type: 'decimal',
  },
  genotype: {
    kind: 'column',
    name: 'genotype',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'requested',
        },
        {
          value: 1,
          key: 'hair_sent',
        },
        {
          value: 2,
          key: 'hair_received',
        },
        {
          value: 3,
          key: 'completed',
        },
      ],
    },
  },
  genotypeSource: {
    kind: 'column',
    name: 'genotypeSource',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'taqman',
        },
        {
          value: 1,
          key: 'snp_chip',
        },
        {
          value: 2,
          key: 'sanger',
        },
        {
          value: 3,
          key: 'unknown',
        },
      ],
    },
  },
  score: {
    kind: 'column',
    name: 'score',
    type: 'decimal',
  },
  snpChipId: {
    kind: 'column',
    name: 'snpChipId',
    type: 'integer',
  },
  published: {
    kind: 'column',
    name: 'published',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<DNASet> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
