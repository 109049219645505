import { createRoutes } from '@/libs/router/route'
import { ShareResource } from '@/resources/share/resource'

export const ShareResourceRoutes = createRoutes({
  resource: ShareResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'shares',
      children: [
        {
          index: true,
          page: 'shares/index',
          query: ShareResource.query.index,
        },
        {
          path: 'new',
          page: 'shares/new',
          query: ShareResource.query.new,
        },
        {
          path: ':id',
          query: ShareResource.query.show,
          children: [
            {
              index: true,
              page: 'shares/show',
            },
            {
              path: 'edit',
              page: 'shares/edit',
            },
            {
              path: 'destroy',
              page: 'shares/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const ShareRoutes = ShareResourceRoutes
