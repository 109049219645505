import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { SNPChipReport } from '@/types'

const name = 'SNPChipReport'

const recordNameSingular = 'snpChipReport'
const recordNamePlural = 'snpChipReports'
const i18nKey = 'snpChipReport'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'snpChipReports'

const pageHelperKey = 'snpChipReports'

const resourcePathName = 'snp_chip_report'

const attributes: Record<string, AttributeSpec> = {
  requestingUser: {
    kind: 'association',
    name: 'requestingUser',
    modelName: 'User',
    foreignKey: 'requestingUserId',
    associationPrimaryKey: 'id',
  },
  fulfillingUser: {
    kind: 'association',
    name: 'fulfillingUser',
    modelName: 'User',
    foreignKey: 'fulfillingUserId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  requestingUserId: {
    kind: 'column',
    name: 'requestingUserId',
    type: 'integer',
  },
  fulfillingUserId: {
    kind: 'column',
    name: 'fulfillingUserId',
    type: 'integer',
  },
  tag: {
    kind: 'column',
    name: 'tag',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'requested',
        },
        {
          value: 1,
          key: 'sent',
        },
        {
          value: 2,
          key: 'completed',
        },
      ],
    },
  },
  samplesSent: {
    kind: 'column',
    name: 'samplesSent',
    type: 'date',
  },
  reportReceived: {
    kind: 'column',
    name: 'reportReceived',
    type: 'date',
  },
  projectName: {
    kind: 'column',
    name: 'projectName',
    type: 'string',
  },
  dnaReportFilename: {
    kind: 'column',
    name: 'dnaReportFilename',
    type: 'string',
  },
  finalReportFilename: {
    kind: 'column',
    name: 'finalReportFilename',
    type: 'string',
  },
  sampleMapFilename: {
    kind: 'column',
    name: 'sampleMapFilename',
    type: 'string',
  },
  snpMapFilename: {
    kind: 'column',
    name: 'snpMapFilename',
    type: 'string',
  },
  locusSummaryFilename: {
    kind: 'column',
    name: 'locusSummaryFilename',
    type: 'string',
  },
  locusxdnaFilename: {
    kind: 'column',
    name: 'locusxdnaFilename',
    type: 'string',
  },
  snpCount: {
    kind: 'column',
    name: 'snpCount',
    type: 'integer',
  },
  sampleCount: {
    kind: 'column',
    name: 'sampleCount',
    type: 'integer',
  },
  gencallVersion: {
    kind: 'column',
    name: 'gencallVersion',
    type: 'string',
  },
  lowGencallScoreCutoff: {
    kind: 'column',
    name: 'lowGencallScoreCutoff',
    type: 'decimal',
  },
  gsgtVersion: {
    kind: 'column',
    name: 'gsgtVersion',
    type: 'string',
  },
  processingTimestamp: {
    kind: 'column',
    name: 'processingTimestamp',
    type: 'datetime',
  },
  content: {
    kind: 'column',
    name: 'content',
    type: 'string',
  },
  totalSnps: {
    kind: 'column',
    name: 'totalSnps',
    type: 'integer',
  },
  totalSamples: {
    kind: 'column',
    name: 'totalSamples',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  source: {
    kind: 'column',
    name: 'source',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'geneseek',
        },
        {
          value: 1,
          key: 'mle',
        },
        {
          value: 2,
          key: 'hsphase',
        },
      ],
    },
  },
}

const modelSpec: ModelSpec<SNPChipReport> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
