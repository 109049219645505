import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { MonthlyOwnershipsByDivision } from '@/types'

const name = 'MonthlyOwnershipsByDivision'

const recordNameSingular = 'monthlyOwnershipsByDivision'
const recordNamePlural = 'monthlyOwnershipsByDivisions'
const i18nKey = 'monthlyOwnershipsByDivision'

const primaryKey = 'monthlyOwnershipsByDivisionId'
const searchKey = 'label'

const parents: Scope[] = []

const apiHelperKey = 'monthlyOwnershipsByDivisions'

const pageHelperKey = 'monthlyOwnershipsByDivisions'

const resourcePathName = 'monthly_ownerships_by_division'

const attributes: Record<string, AttributeSpec> = {
  monthlyOwnershipsByDivisionId: {
    kind: 'column',
    name: 'monthlyOwnershipsByDivisionId',
    type: 'text',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'text',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  organizationHorseId: {
    kind: 'column',
    name: 'organizationHorseId',
    type: 'integer',
  },
  matingId: {
    kind: 'column',
    name: 'matingId',
    type: 'integer',
  },
  projectedPurchaseId: {
    kind: 'column',
    name: 'projectedPurchaseId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
  isProjection: {
    kind: 'column',
    name: 'isProjection',
    type: 'boolean',
  },
  label: {
    kind: 'column',
    name: 'label',
    type: 'text',
  },
  labelTooltip: {
    kind: 'column',
    name: 'labelTooltip',
    type: 'text',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  isHomebred: {
    kind: 'column',
    name: 'isHomebred',
    type: 'boolean',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'crops',
        },
        {
          value: 1,
          key: 'racing',
        },
        {
          value: 2,
          key: 'broodmares',
        },
        {
          value: 3,
          key: 'stallions',
        },
      ],
    },
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  arrived: {
    kind: 'column',
    name: 'arrived',
    type: 'date',
  },
  departed: {
    kind: 'column',
    name: 'departed',
    type: 'date',
  },
  dateRealized: {
    kind: 'column',
    name: 'dateRealized',
    type: 'date',
  },
  isActive: {
    kind: 'column',
    name: 'isActive',
    type: 'boolean',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  throatGrade: {
    kind: 'column',
    name: 'throatGrade',
    type: 'string',
  },
  xrayGrade: {
    kind: 'column',
    name: 'xrayGrade',
    type: 'string',
  },
  disposition: {
    kind: 'column',
    name: 'disposition',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'retain',
        },
        {
          value: 1,
          key: 'borderline',
        },
        {
          value: 2,
          key: 'sell_as_weanling',
        },
        {
          value: 3,
          key: 'sell_as_yearling',
        },
        {
          value: 4,
          key: 'sell_as_2yo',
        },
        {
          value: 5,
          key: 'sell_as_older_horse',
        },
        {
          value: 6,
          key: 'sell_in_foal',
        },
        {
          value: 7,
          key: 'sell_off_track',
        },
        {
          value: 8,
          key: 'sell_privately',
        },
        {
          value: 9,
          key: 'giveaway',
        },
        {
          value: 10,
          key: 'layup',
        },
        {
          value: 11,
          key: 'claimed',
        },
        {
          value: 12,
          key: 'died',
        },
        {
          value: 13,
          key: 'other',
        },
        {
          value: 14,
          key: 'sold',
        },
        {
          value: 15,
          key: 'broodmare_band',
        },
      ],
    },
  },
  plan: {
    kind: 'column',
    name: 'plan',
    type: 'string',
  },
  category: {
    kind: 'column',
    name: 'category',
    type: 'string',
  },
  situation: {
    kind: 'column',
    name: 'situation',
    type: 'text',
  },
  ownership: {
    kind: 'column',
    name: 'ownership',
    type: 'decimal',
  },
  cost: {
    kind: 'column',
    name: 'cost',
    type: 'decimal',
  },
  appraisal: {
    kind: 'column',
    name: 'appraisal',
    type: 'decimal',
  },
  actualValue: {
    kind: 'column',
    name: 'actualValue',
    type: 'decimal',
  },
  studFee: {
    kind: 'column',
    name: 'studFee',
    type: 'decimal',
  },
  summaryStatus: {
    kind: 'column',
    name: 'summaryStatus',
    type: 'text',
  },
  summaryStatusTooltip: {
    kind: 'column',
    name: 'summaryStatusTooltip',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  isArrived: {
    kind: 'column',
    name: 'isArrived',
    type: 'boolean',
  },
  account: {
    kind: 'column',
    name: 'account',
    type: 'citext',
  },
  ownershipGroup: {
    kind: 'column',
    name: 'ownershipGroup',
    type: 'text',
  },
  yearRealized: {
    kind: 'column',
    name: 'yearRealized',
    type: 'integer',
  },
  monthRealized: {
    kind: 'column',
    name: 'monthRealized',
    type: 'integer',
  },
  index: {
    kind: 'column',
    name: 'index',
    type: 'integer',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'datetime',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  nextMonthDate: {
    kind: 'column',
    name: 'nextMonthDate',
    type: 'datetime',
  },
  lengthDays: {
    kind: 'column',
    name: 'lengthDays',
    type: 'decimal',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'decimal',
  },
  month: {
    kind: 'column',
    name: 'month',
    type: 'decimal',
  },
  isQuarterStart: {
    kind: 'column',
    name: 'isQuarterStart',
    type: 'boolean',
  },
  isQuarterEnd: {
    kind: 'column',
    name: 'isQuarterEnd',
    type: 'boolean',
  },
  horseGroup: {
    kind: 'column',
    name: 'horseGroup',
    type: 'text',
  },
  isSell: {
    kind: 'column',
    name: 'isSell',
    type: 'boolean',
  },
  isRetain: {
    kind: 'column',
    name: 'isRetain',
    type: 'boolean',
  },
  isGiveaway: {
    kind: 'column',
    name: 'isGiveaway',
    type: 'boolean',
  },
  daysOwnedInMonth: {
    kind: 'column',
    name: 'daysOwnedInMonth',
    type: 'integer',
  },
  isFoalingYear: {
    kind: 'column',
    name: 'isFoalingYear',
    type: 'boolean',
  },
  ageMonths: {
    kind: 'column',
    name: 'ageMonths',
    type: 'float',
  },
  isDepartingThisMonth: {
    kind: 'column',
    name: 'isDepartingThisMonth',
    type: 'boolean',
  },
  isDepartingFutureMonth: {
    kind: 'column',
    name: 'isDepartingFutureMonth',
    type: 'boolean',
  },
  isArrivingThisMonth: {
    kind: 'column',
    name: 'isArrivingThisMonth',
    type: 'boolean',
  },
  isArrivingFutureMonth: {
    kind: 'column',
    name: 'isArrivingFutureMonth',
    type: 'boolean',
  },
  divisionRk: {
    kind: 'column',
    name: 'divisionRk',
    type: 'integer',
  },
  globalRk: {
    kind: 'column',
    name: 'globalRk',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<MonthlyOwnershipsByDivision> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
