import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { OBSResult } from '@/types'

const name = 'OBSResult'

const recordNameSingular = 'obsResult'
const recordNamePlural = 'obsResults'
const i18nKey = 'obs::Result'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'obsResults'

const pageHelperKey = 'obsResults'

const resourcePathName = 'obs_result'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  saleId: {
    kind: 'column',
    name: 'saleId',
    type: 'integer',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  age: {
    kind: 'column',
    name: 'age',
    type: 'integer',
  },
  color: {
    kind: 'column',
    name: 'color',
    type: 'string',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  foalingDate: {
    kind: 'column',
    name: 'foalingDate',
    type: 'date',
  },
  foalYear: {
    kind: 'column',
    name: 'foalYear',
    type: 'integer',
  },
  foalMonth: {
    kind: 'column',
    name: 'foalMonth',
    type: 'integer',
  },
  foalDay: {
    kind: 'column',
    name: 'foalDay',
    type: 'integer',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'string',
  },
  sireOrDam: {
    kind: 'column',
    name: 'sireOrDam',
    type: 'string',
  },
  sireOrName: {
    kind: 'column',
    name: 'sireOrName',
    type: 'string',
  },
  damName: {
    kind: 'column',
    name: 'damName',
    type: 'string',
  },
  damSireName: {
    kind: 'column',
    name: 'damSireName',
    type: 'string',
  },
  coveringSire: {
    kind: 'column',
    name: 'coveringSire',
    type: 'string',
  },
  lastBred: {
    kind: 'column',
    name: 'lastBred',
    type: 'date',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  foalLocation: {
    kind: 'column',
    name: 'foalLocation',
    type: 'string',
  },
  barn: {
    kind: 'column',
    name: 'barn',
    type: 'string',
  },
  workTime: {
    kind: 'column',
    name: 'workTime',
    type: 'string',
  },
  workSet: {
    kind: 'column',
    name: 'workSet',
    type: 'string',
  },
  workDate: {
    kind: 'column',
    name: 'workDate',
    type: 'date',
  },
  buyer: {
    kind: 'column',
    name: 'buyer',
    type: 'string',
  },
  price: {
    kind: 'column',
    name: 'price',
    type: 'integer',
  },
  postSale: {
    kind: 'column',
    name: 'postSale',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  out: {
    kind: 'column',
    name: 'out',
    type: 'string',
  },
  outDate: {
    kind: 'column',
    name: 'outDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  obsSaleId: {
    kind: 'column',
    name: 'obsSaleId',
    type: 'integer',
  },
  inOutStatus: {
    kind: 'column',
    name: 'inOutStatus',
    type: 'string',
  },
  horseType: {
    kind: 'column',
    name: 'horseType',
    type: 'string',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'string',
  },
  sortDam: {
    kind: 'column',
    name: 'sortDam',
    type: 'integer',
  },
  propertyLine1: {
    kind: 'column',
    name: 'propertyLine1',
    type: 'string',
  },
  propertyLine2: {
    kind: 'column',
    name: 'propertyLine2',
    type: 'string',
  },
  sortNameCentral: {
    kind: 'column',
    name: 'sortNameCentral',
    type: 'integer',
  },
  lastBredStatus: {
    kind: 'column',
    name: 'lastBredStatus',
    type: 'string',
  },
  session: {
    kind: 'column',
    name: 'session',
    type: 'string',
  },
  rna: {
    kind: 'column',
    name: 'rna',
    type: 'boolean',
  },
  taxType: {
    kind: 'column',
    name: 'taxType',
    type: 'string',
  },
  buyerNameWithSortPoints: {
    kind: 'column',
    name: 'buyerNameWithSortPoints',
    type: 'string',
  },
  salePriceRna: {
    kind: 'column',
    name: 'salePriceRna',
    type: 'integer',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  expectedWorkDate: {
    kind: 'column',
    name: 'expectedWorkDate',
    type: 'date',
  },
  workDistance: {
    kind: 'column',
    name: 'workDistance',
    type: 'string',
  },
  workGroup: {
    kind: 'column',
    name: 'workGroup',
    type: 'string',
  },
  hasPhoto: {
    kind: 'column',
    name: 'hasPhoto',
    type: 'boolean',
  },
  photoLink: {
    kind: 'column',
    name: 'photoLink',
    type: 'string',
  },
  hasVideo: {
    kind: 'column',
    name: 'hasVideo',
    type: 'boolean',
  },
  videoLink: {
    kind: 'column',
    name: 'videoLink',
    type: 'string',
  },
  hasWalkVideo: {
    kind: 'column',
    name: 'hasWalkVideo',
    type: 'boolean',
  },
  walkVideoLink: {
    kind: 'column',
    name: 'walkVideoLink',
    type: 'string',
  },
  hasNewUpdate: {
    kind: 'column',
    name: 'hasNewUpdate',
    type: 'boolean',
  },
  updatesLink: {
    kind: 'column',
    name: 'updatesLink',
    type: 'string',
  },
  hasPpPdf: {
    kind: 'column',
    name: 'hasPpPdf',
    type: 'boolean',
  },
  isBt: {
    kind: 'column',
    name: 'isBt',
    type: 'boolean',
  },
  jcDataUpdatedDate: {
    kind: 'column',
    name: 'jcDataUpdatedDate',
    type: 'date',
  },
  obsCreatedDate: {
    kind: 'column',
    name: 'obsCreatedDate',
    type: 'date',
  },
  obsUpdatedDate: {
    kind: 'column',
    name: 'obsUpdatedDate',
    type: 'date',
  },
  pedigreePdfLink: {
    kind: 'column',
    name: 'pedigreePdfLink',
    type: 'string',
  },
  workExpectedDiffFromActual: {
    kind: 'column',
    name: 'workExpectedDiffFromActual',
    type: 'boolean',
  },
  photos: {
    kind: 'attachment',
    name: 'photos',
    multiple: true,
  },
  walkVideo: {
    kind: 'attachment',
    name: 'walkVideo',
    multiple: false,
  },
  breezeVideo: {
    kind: 'attachment',
    name: 'breezeVideo',
    multiple: false,
  },
}

const modelSpec: ModelSpec<OBSResult> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
