import { currencyFormatter, percentFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'

import { MonthlyOwnershipsByDivisionResource } from './resource'

export const monthlyOwnershipsByDivisionTable = defineTable(
  MonthlyOwnershipsByDivisionResource,
  {},
  (tbl) => ({
    columns: [
      // tbl.col.monthlyOwnershipsByDivisionId(),
      tbl.col.id({ isVisible: false }),
      // tbl.col.organizationId(),
      // tbl.col.organizationHorseId(),
      // tbl.col.matingId(),
      // tbl.col.projectedPurchaseId(),
      tbl.col.horseId({ isVisible: false }),
      // tbl.col.bloodstockEntityId(),
      tbl.col.label({
        isExpander: true,
      }),
      tbl.col.isProjection({ isVisible: false }),
      tbl.col.labelTooltip({ isVisible: false }),
      tbl.col.dob({ isVisible: false }),
      tbl.col.yob({ isVisible: false }),
      tbl.col.sex({ isVisible: false }),
      tbl.col.damId({ isVisible: false }),
      tbl.col.sireId({ isVisible: false }),
      tbl.col.isHomebred({ isVisible: false }),
      tbl.col.division(),
      // tbl.col.accountId(),
      tbl.col.arrived(),
      tbl.col.departed(),
      tbl.col.dateRealized(),
      tbl.col.isActive({ isVisible: false }),

      tbl.col.disposition(),
      // tbl.col.plan(),
      tbl.col.ownership({
        cell: percentFormatter({ decimals: 2 }),
      }),
      tbl.col.cost(),
      tbl.col.appraisal({
        label: 'Appraisal',
        cell: currencyFormatter(),
      }),
      // tbl.col.actualValue(),
      // tbl.col.studFee(),
      tbl.col.summaryStatus(),
      tbl.col.summaryStatusTooltip(),
      // tbl.col.createdAt(),
      // tbl.col.updatedAt(),
      // tbl.col.isArrived(),
      tbl.col.account(),
      // tbl.col.ownershipGroup(),
      // tbl.col.yearRealized(),
      // tbl.col.monthRealized(),
      // tbl.col.index(),
      // tbl.col.startDate(),
      // tbl.col.endDate(),
      // tbl.col.nextMonthDate(),
      // tbl.col.lengthDays(),
      // tbl.col.year(),
      // tbl.col.month(),
      // tbl.col.isQuarterStart(),
      // tbl.col.isQuarterEnd(),
      // tbl.col.horseGroup(),
      // tbl.col.isSell(),
      // tbl.col.isRetain(),
      // tbl.col.isGiveaway(),
      // tbl.col.daysOwnedInMonth(),
      // tbl.col.isFoalingYear(),
      // tbl.col.ageMonths(),
      // tbl.col.isDepartingThisMonth(),
      // tbl.col.isDepartingFutureMonth(),
      // tbl.col.isArrivingThisMonth(),
      // tbl.col.isArrivingFutureMonth(),
    ],
  }),
)
