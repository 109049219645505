import React from 'react'

import { Section } from '@/components/section'

const HorsesIndex = () => {
  return <Section title="Horses" className="mt-16"></Section>
}

export { HorsesIndex as Component }
export default HorsesIndex
