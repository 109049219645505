import React, { useCallback } from 'react'

import { bloodstockSearchApi } from '@/api'
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInput,
  SidebarMenu,
  useSidebar,
} from '@/components/ui/sidebar'
import { useRecentlyVisited } from '@/hooks/use_recently_visited'
import { createListQueryBuilder } from '@/libs/query/list-query'
import { HorseResource } from '@/resources/horse'
import { Horse } from '@/types'

import useAutocomplete from './hooks/use_autocomplete'
import { HorseSearchItem } from './item'

const HorseSearch = () => {
  const { queue } = useRecentlyVisited({ key: 'recentHorses' })
  const { searchActive, setSearchActive } = useSidebar()
  // Not generating a url
  const query = createListQueryBuilder<Horse>(bloodstockSearchApi.search)
    .sort('name', 'asc')
    .pageSize(30)

  const { suggestions, searchTerm, onSearchTermChange } = useAutocomplete<Horse>({
    resource: HorseResource,
    query,
  })

  const searchInput = useCallback((inputElement: HTMLInputElement) => {
    inputElement ? inputElement.focus() : undefined
  }, [])

  const renderHorseItems = (horses: Horse[]) => {
    return (
      <SidebarGroupContent>
        <SidebarMenu>
          {horses.map((horse: Horse) => (
            <HorseSearchItem key={horse.id} horse={horse} />
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    )
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    onSearchTermChange(event.target.value)
  }

  return (
    <>
      <SidebarHeader className="border-b">
        <div className="h-9">
          <SidebarInput
            ref={searchActive ? searchInput : undefined}
            placeholder="Search horses"
            value={searchTerm}
            onChange={handleOnChange}
            onBlur={() =>
              !searchTerm
                ? setTimeout(() => {
                    setSearchActive(false)
                  }, 100)
                : undefined
            }
          />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          {!searchTerm && queue.length > 0 ? (
            <>
              <SidebarGroupLabel>Recently Visited</SidebarGroupLabel>
              {renderHorseItems(queue)}
            </>
          ) : null}
          {searchTerm ? renderHorseItems(suggestions) : null}
        </SidebarGroup>
      </SidebarContent>
    </>
  )
}

export { HorseSearch }
