import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'SaleRecordDetail'

const recordNameSingular = 'saleRecordDetail'
const recordNamePlural = 'saleRecordDetails'
const i18nKey = 'saleRecordDetail'

const primaryKey = 'saleRecordDetailId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'saleRecordDetails'

const pageHelperKey = 'saleRecordDetails'

const resourcePathName = 'sale_record_detail'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'submodel',
    name: 'horse',
    modelName: 'DBTHorse',
    columns: [
      'horseId',
      'name',
      'age',
      'nameAgeSex',
      'yob',
      'sex',
      'foalLocation',
      'sireId',
      'sireName',
      'sireYob',
      'damId',
      'damName',
      'damYob',
      'damSireId',
      'horseType',
    ],
  },
  saleRecordDetailId: {
    kind: 'column',
    name: 'saleRecordDetailId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  saleRecord: {
    kind: 'submodel',
    name: 'saleRecord',
    modelName: 'SaleRecord',
    columns: [
      'id',
      'saleId',
      'session',
      'sessionDate',
      'book',
      'hip',
      'barn',
      'horseId',
      'firstFoal',
      'secondFoal',
      'pregnant',
      'coveringSire',
      'coverDate',
      'breedersCupElig',
      'consignor',
      'consignorId',
      'buyer',
      'buyerId',
      'rna',
      'out',
      'sold',
      'salePrice',
      'workTime',
      'workSet',
      'workDay',
      'ps',
      'propertyLine',
      'grade',
      'soldAsCode',
      'soldAsDesc',
      'soldAsCodeMap',
      'comment',
      'createdAt',
      'updatedAt',
    ],
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
