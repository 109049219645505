// JsFromRoutes CacheKey e84dee131133e923ea31899a222f216c
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DBTCareerRaceSummaryModel } from '@/models/dbt/career_race_summary'

export const dbtCareerRaceSummariesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/career_race_summaries',
    actionName: 'index',
    model: DBTCareerRaceSummaryModel,
    parts: ['api', 'dbt', 'career_race_summaries'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/career_race_summaries',
    actionName: 'show',
    model: DBTCareerRaceSummaryModel,
    parts: [
      'api',
      'dbt',
      'career_race_summaries',
      { name: 'horseId', attributeId: 'DBTCareerRaceSummary:horse' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}
