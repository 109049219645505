import { ListLetters, ListSearch, Tag } from '@/components/icons'
import { bloodstockRoutes, stallionsRoutes } from '@/routes'

import { PanelConfig } from '../panel-types'
import { seasonsPanelConfig } from './seasons'

export const stallionsPanelConfig = (params, data): PanelConfig => {
  return [
    {
      label: 'Active Stallions',
      items: [
        { label: 'Roster', route: bloodstockRoutes.stallions },
        {
          label: 'All Stallions',
          route: stallionsRoutes.index,
          icon: <ListLetters />,
        },
        { label: 'Racing', route: stallionsRoutes.racing },
        { label: 'Sales', route: stallionsRoutes.sales, icon: <Tag /> },
        { label: 'Crops', route: stallionsRoutes.crops },
        {
          label: 'Prospects',
          route: stallionsRoutes.prospects,
          icon: <ListSearch />,
        },
      ],
    },
    ...seasonsPanelConfig(params),
    {
      label: 'Research',
      items: [
        ,
        {
          label: 'Stallions All Time',
        },
      ],
    },
  ]
}
