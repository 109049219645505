import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Sale } from '@/types'

const name = 'Sale'

const recordNameSingular = 'sale'
const recordNamePlural = 'sales'
const i18nKey = 'sale'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'sales'

const pageHelperKey = 'sales'

const resourcePathName = 'sale'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  host: {
    kind: 'column',
    name: 'host',
    type: 'string',
  },
  location: {
    kind: 'column',
    name: 'location',
    type: 'string',
  },
  code: {
    kind: 'column',
    name: 'code',
    type: 'string',
  },
  saleType: {
    kind: 'column',
    name: 'saleType',
    type: 'string',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<Sale> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
