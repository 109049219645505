import { DataTile } from '@/components/data_tile'
import { Actions, Header, Title } from '@/components/header'
import { DollarSign, LaurelWreath1, Trophy } from '@/components/icons'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { bestClassStyle } from '@/resources/dbt/race/best_class_style'
import { ThoromanagerAction } from '@/resources/horse'
import { cn, formatCurrency } from '@/utils'

const DBTCareerRaceSummariesIndex = () => {
  const { dbtcareerRaceSummariesIndex } = useLoaderQueryBuilders()
  const { record: dbtCareerRaceSummary } = useResourceQuery(dbtcareerRaceSummariesIndex)

  if (!dbtCareerRaceSummary) {
    return 'No race records on file'
  }

  return (
    <>
      <Header className="mb-4">
        <Title as={'h3'}>{'Race Career'}</Title>
        <Actions>
          <ThoromanagerAction horseId={dbtCareerRaceSummary.horseId} size="lg" iconOnly />
        </Actions>
      </Header>
      <div className="mb-2 flex gap-2">
        <DataTile
          label={
            <div className="flex items-center gap-1">
              <LaurelWreath1 />
              <span>Best Class</span>
            </div>
          }
          className={cn('flex-1', bestClassStyle(dbtCareerRaceSummary.bestClass))}
          muted
        >
          {dbtCareerRaceSummary.bestClassTitle ? dbtCareerRaceSummary.bestClassTitle : '-'}
        </DataTile>

        <DataTile
          label={
            <div className="flex items-center gap-1">
              <Trophy />
              <span>Race Record</span>
            </div>
          }
          className="flex-1"
          muted
        >{`${dbtCareerRaceSummary.starts ?? 0}-${dbtCareerRaceSummary.wins ?? 0}-${dbtCareerRaceSummary.places ?? 0}-${dbtCareerRaceSummary.shows ?? 0}`}</DataTile>

        <DataTile label="Earnings" className="flex-1" muted>
          {dbtCareerRaceSummary.totalEarnings ? (
            <div className="flex items-center gap-1">
              <DollarSign className="text-muted-foreground" />
              {formatCurrency(dbtCareerRaceSummary.totalEarnings)}
            </div>
          ) : (
            '-'
          )}
        </DataTile>
      </div>
      <Text as="h5" className="mt-4">
        Starts
      </Text>
      <Text as="h5" className="mt-4">
        Workouts
      </Text>
    </>
  )
}

export default DBTCareerRaceSummariesIndex
