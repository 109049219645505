import React from 'react'
import { useMatches } from 'react-router-dom'

import { ChevronLeft } from '@/components/icons'
import { Link } from '@/components/link'
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { snakeToTitle } from '@/utils/snake_to_title'

const MAX_VISIBLE_CRUMBS = 3 // Number of crumbs to show before ellipsis

interface MatchHandle {
  layoutType?: string
  label?: ((data: Record<string, unknown>, params: Record<string, string>) => string) | string
  pathName?: string
  routePart?: { name: string }[]
}

interface Match {
  pathname: string
  handle?: MatchHandle
  data?: Record<string, unknown>
  params?: Record<string, string>
}

export const Breadcrumbs = ({ layoutType, ...props }: { layoutType?: string | undefined }) => {
  const matches: Match[] = useMatches() as Match[]
  // Filter out matches that don't match the layoutType
  const filteredMatches = matches.filter((m) => m.handle?.layoutType === layoutType)
  // Construct breadcrumbs
  const breadcrumbs = filteredMatches.map((match) => {
    const { pathname, handle, data, params } = match
    let label = ''
    // If handle.label is a function, use it
    if (typeof handle?.label === 'function') {
      label = handle.label(data, params)
    } else {
      // Otherwise, set the label based on params
      label = handle?.pathName ?? ''
      if (handle?.routePart) {
        for (const part of handle.routePart) {
          if (Object.keys(params).includes(part.name)) {
            if (data?.data?.title) {
              if (typeof data.data === 'object' && 'title' in data.data) {
                label = (data.data as { title: string }).title
              }
            } else if (params) {
              label = params[part.name]
            }
          }
        }
      }
    }
    return {
      pathname,
      label,
    }
  })
  // Filter out unwanted labels (like 'index' or 'show')
  const validCrumbs = breadcrumbs.filter(
    (crumb) => crumb.label && crumb.label !== 'index' && crumb.label !== 'show',
  )

  const visibleCrumbs = []
  let numVisibleCrumbs = 0

  for (const crumb of validCrumbs) {
    if (numVisibleCrumbs < MAX_VISIBLE_CRUMBS) {
      visibleCrumbs.push(crumb)
      numVisibleCrumbs++
    } else {
      break // Stop adding crumbs once the limit is reached
    }
  }

  const renderEllipsis = () => {
    if (validCrumbs.length > MAX_VISIBLE_CRUMBS) {
      return (
        <>
          <BreadcrumbItem className="hidden md:block">
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center gap-1">
                <BreadcrumbEllipsis className="size-4" />
                <span className="sr-only">Toggle menu</span>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                {/* Add logic to render remaining crumbs in the dropdown */}
                {validCrumbs.slice(MAX_VISIBLE_CRUMBS).map((crumb) => (
                  <DropdownMenuItem key={crumb.pathname}>
                    <BreadcrumbLink to={crumb.pathname} className="capitalize">
                      {crumb.label}
                    </BreadcrumbLink>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </BreadcrumbItem>
          <BreadcrumbSeparator className="hidden h-3 md:block" />
        </>
      )
    }
  }

  const secondToLastCrumb = validCrumbs.length > 1 ? validCrumbs[validCrumbs.length - 2] : null

  return (
    <Breadcrumb {...props}>
      <BreadcrumbList>
        {layoutType === 'sheet' && secondToLastCrumb?.pathname ? (
          <Link
            variant="secondary"
            iconLeft={<ChevronLeft />}
            size="sm"
            className="ml-1"
            to={secondToLastCrumb.pathname || undefined}
            noChevron
            iconOnly
          />
        ) : null}
        {visibleCrumbs.map((crumb, index) => {
          const isLast = index === visibleCrumbs.length - 1
          return (
            <React.Fragment key={crumb.pathname}>
              <BreadcrumbItem className="hidden md:block">
                {isLast ? (
                  <div className="flex items-center gap-2">
                    {renderEllipsis()}
                    <BreadcrumbPage className="capitalize">
                      {snakeToTitle(crumb.label)}
                    </BreadcrumbPage>
                  </div>
                ) : (
                  <BreadcrumbLink className="capitalize" to={crumb.pathname}>
                    {snakeToTitle(crumb.label)}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {isLast ? null : <BreadcrumbSeparator className="hidden h-3 md:block" />}
            </React.Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
