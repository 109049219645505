// JsFromRoutes CacheKey e35b125de9ac5815db90a73e683a339e
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { FinanceEntryModel } from '@/models/finance/entry'

export const financeEntriesApi = {
  index: defineEndpoint({
    controllerName: 'finance/entries',
    actionName: 'index',
    model: FinanceEntryModel,
    parts: ['api', 'finance', 'entries'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'finance/entries',
    actionName: 'show',
    model: FinanceEntryModel,
    parts: ['api', 'finance', 'entries', { name: 'entryId', attributeId: 'FinanceEntry:entryId' }],
    httpMethod: 'get',
    type: 'view',
  }),
}
