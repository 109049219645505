import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { IntacctGeneralLedgerDetail } from '@/types'

const name = 'IntacctGeneralLedgerDetail'

const recordNameSingular = 'intacctGeneralLedgerDetail'
const recordNamePlural = 'intacctGeneralLedgerDetails'
const i18nKey = 'intacct::GeneralLedgerDetail'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'intacctGeneralLedgerDetails'

const pageHelperKey = 'intacctGeneralLedgerDetails'

const resourcePathName = 'intacct_general_ledger_detail'

const attributes: Record<string, AttributeSpec> = {
  sageClass: {
    kind: 'association',
    name: 'sageClass',
    modelName: 'IntacctSageClass',
    foreignKey: 'classId',
    associationPrimaryKey: 'classId',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  batchDate: {
    kind: 'column',
    name: 'batchDate',
    type: 'date',
  },
  batchTitle: {
    kind: 'column',
    name: 'batchTitle',
    type: 'string',
  },
  symbol: {
    kind: 'column',
    name: 'symbol',
    type: 'string',
  },
  batchNo: {
    kind: 'column',
    name: 'batchNo',
    type: 'integer',
  },
  bookId: {
    kind: 'column',
    name: 'bookId',
    type: 'string',
  },
  childEntity: {
    kind: 'column',
    name: 'childEntity',
    type: 'string',
  },
  modified: {
    kind: 'column',
    name: 'modified',
    type: 'datetime',
  },
  referenceNo: {
    kind: 'column',
    name: 'referenceNo',
    type: 'string',
  },
  adj: {
    kind: 'column',
    name: 'adj',
    type: 'boolean',
  },
  moduleKey: {
    kind: 'column',
    name: 'moduleKey',
    type: 'string',
  },
  lineNo: {
    kind: 'column',
    name: 'lineNo',
    type: 'integer',
  },
  entryDate: {
    kind: 'column',
    name: 'entryDate',
    type: 'date',
  },
  trType: {
    kind: 'column',
    name: 'trType',
    type: 'integer',
  },
  document: {
    kind: 'column',
    name: 'document',
    type: 'string',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  accountTitle: {
    kind: 'column',
    name: 'accountTitle',
    type: 'string',
  },
  statistical: {
    kind: 'column',
    name: 'statistical',
    type: 'string',
  },
  departmentId: {
    kind: 'column',
    name: 'departmentId',
    type: 'string',
  },
  departmentTitle: {
    kind: 'column',
    name: 'departmentTitle',
    type: 'string',
  },
  locationId: {
    kind: 'column',
    name: 'locationId',
    type: 'string',
  },
  locationName: {
    kind: 'column',
    name: 'locationName',
    type: 'string',
  },
  currency: {
    kind: 'column',
    name: 'currency',
    type: 'string',
  },
  baseCurr: {
    kind: 'column',
    name: 'baseCurr',
    type: 'string',
  },
  description: {
    kind: 'column',
    name: 'description',
    type: 'string',
  },
  debitAmount: {
    kind: 'column',
    name: 'debitAmount',
    type: 'decimal',
  },
  creditAmount: {
    kind: 'column',
    name: 'creditAmount',
    type: 'decimal',
  },
  amount: {
    kind: 'column',
    name: 'amount',
    type: 'decimal',
  },
  trxDebitAmount: {
    kind: 'column',
    name: 'trxDebitAmount',
    type: 'decimal',
  },
  trxCreditAmount: {
    kind: 'column',
    name: 'trxCreditAmount',
    type: 'decimal',
  },
  trxAmount: {
    kind: 'column',
    name: 'trxAmount',
    type: 'decimal',
  },
  cleared: {
    kind: 'column',
    name: 'cleared',
    type: 'string',
  },
  clearDate: {
    kind: 'column',
    name: 'clearDate',
    type: 'date',
  },
  custEntity: {
    kind: 'column',
    name: 'custEntity',
    type: 'string',
  },
  vendEntity: {
    kind: 'column',
    name: 'vendEntity',
    type: 'string',
  },
  empEntity: {
    kind: 'column',
    name: 'empEntity',
    type: 'string',
  },
  locEntity: {
    kind: 'column',
    name: 'locEntity',
    type: 'string',
  },
  recordType: {
    kind: 'column',
    name: 'recordType',
    type: 'string',
  },
  recordId: {
    kind: 'column',
    name: 'recordId',
    type: 'string',
  },
  docNumber: {
    kind: 'column',
    name: 'docNumber',
    type: 'string',
  },
  state: {
    kind: 'column',
    name: 'state',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'date',
  },
  whenDue: {
    kind: 'column',
    name: 'whenDue',
    type: 'date',
  },
  whenPaid: {
    kind: 'column',
    name: 'whenPaid',
    type: 'date',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  prDescription: {
    kind: 'column',
    name: 'prDescription',
    type: 'string',
  },
  prCleared: {
    kind: 'column',
    name: 'prCleared',
    type: 'string',
  },
  prClearDate: {
    kind: 'column',
    name: 'prClearDate',
    type: 'date',
  },
  financialEntity: {
    kind: 'column',
    name: 'financialEntity',
    type: 'string',
  },
  totalEntered: {
    kind: 'column',
    name: 'totalEntered',
    type: 'decimal',
  },
  totalPaid: {
    kind: 'column',
    name: 'totalPaid',
    type: 'decimal',
  },
  totalDue: {
    kind: 'column',
    name: 'totalDue',
    type: 'decimal',
  },
  entryDescription: {
    kind: 'column',
    name: 'entryDescription',
    type: 'string',
  },
  glEntryKey: {
    kind: 'column',
    name: 'glEntryKey',
    type: 'integer',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'string',
  },
  batchState: {
    kind: 'column',
    name: 'batchState',
    type: 'string',
  },
  entryState: {
    kind: 'column',
    name: 'entryState',
    type: 'string',
  },
  customerName: {
    kind: 'column',
    name: 'customerName',
    type: 'string',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  vendorName: {
    kind: 'column',
    name: 'vendorName',
    type: 'string',
  },
  vendorId: {
    kind: 'column',
    name: 'vendorId',
    type: 'string',
  },
  employeeName: {
    kind: 'column',
    name: 'employeeName',
    type: 'string',
  },
  employeeId: {
    kind: 'column',
    name: 'employeeId',
    type: 'string',
  },
  className: {
    kind: 'column',
    name: 'className',
    type: 'string',
  },
  classId: {
    kind: 'column',
    name: 'classId',
    type: 'string',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'string',
  },
}

const modelSpec: ModelSpec<IntacctGeneralLedgerDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
