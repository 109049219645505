import React from 'react'

import { UseFormProps, useForm, Form, Field } from '@/components/form'
import { Card, CardContent } from '@/components/ui/card'
import { StudFeeSchema } from '@/schemas/stud_fee'
import { StudFee } from '@/types'

import { StudFeeResource } from './resource'

type StudFeeFormProps = Omit<UseFormProps<StudFee>, 'resource'>

const StudFeeForm = ({ zodSchema = StudFeeSchema, ...props }: StudFeeFormProps) => {
  const formConfig = useForm<StudFee>({
    resource: StudFeeResource,
    zodSchema,
    ...props,
  })

  return (
    <Form {...formConfig}>
      <Card>
        <CardContent>
          <Field name="stallion" />
          <Field name="year" />
          <Field name="price" />
          <Field name="currency" />
          <Field name="private" />
          <Field name="farm" />
          <Field name="location" />
          <Field name="status" />
        </CardContent>
      </Card>
    </Form>
  )
}

export { StudFeeForm }
