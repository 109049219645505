/*
 * TODO Implement Edit Page for users/passwords
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function PasswordsEdit() {
  return <PageHeader title="Users::Passwords Edit" />
}

export default PasswordsEdit
