import { z } from 'zod'

export const StudFeeSchema = z.object({
  stallionId: z.number().int(),
  year: z.number().int(),
  price: z.union([z.number().int(), z.null()]).optional(),
  currency: z.union([z.string().default('USD'), z.null().default('USD')]).default('USD'),
  private: z.union([z.boolean().default(false), z.null().default(false)]).default(false),
  farm: z.union([z.string(), z.null()]).optional(),
  location: z.union([z.string(), z.null()]).optional(),
  status: z.enum(['confirmed', 'estimated', 'previous_year']).default('confirmed'),
})
