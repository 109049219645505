import { useCallback, useMemo } from 'react'

import { ListQueryBuilder } from '@/libs/query/list-query'
import { ResourceRecord } from '@/libs/resource/record'
import { Resource } from '@/libs/resource/resource'
import { useQuery, useQueries } from '@tanstack/react-query'

export interface DataQueryParams<T extends ResourceRecord> {
  resource: Resource<T>
  query: ListQueryBuilder<T>
  clientSide: boolean
  batchSize: number
}

export const useDataQuery = <T extends ResourceRecord>({
  resource,
  query,
  clientSide = false,
  batchSize = 100,
}: DataQueryParams<T>) => {
  // TODO Need to remove query from queryKey on clientSide
  const { data: initialData } = useQuery(query.build())

  const batches = useMemo(() => {
    const numBatches = Math.ceil(initialData.count / batchSize)

    return clientSide ? [...Array(numBatches).keys()].map((e: number) => e + 1) : []
  }, [initialData, batchSize, clientSide])

  const combineData = useCallback((results: T[]) => {
    const data = results.map((result) => result.data)

    return {
      data,
      count: data.length,
    }
  }, [])

  const queryOptions = useMemo(
    () => ({
      queries: batches.map((pageIndex: number) =>
        query.pageSize(batchSize).page(pageIndex).sort(resource.model.primaryKey, 'asc').build(),
      ),
      combine: combineData,
    }),
    [query, batches],
  )

  if (batches.length > 100) {
    throw new Error(`Too many batches for useDataQuery on resource ${resource.name}`)
  }

  const { data, count } = useQueries(queryOptions)

  const flatData = useMemo(
    () =>
      clientSide ? data.flatMap((queryData: { data: T[] }) => queryData.data) : initialData.data,
    [clientSide, data, initialData],
  )

  return {
    count: clientSide ? initialData.count : count,
    queriesData: data,
    flatData,
  }
}

export default useDataQuery
