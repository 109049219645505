import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { StallionProspect } from '@/types'

const name = 'StallionProspect'

const recordNameSingular = 'stallionProspect'
const recordNamePlural = 'stallionProspects'
const i18nKey = 'stallionProspect'

const primaryKey = 'horseId'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'stallionProspects'

const pageHelperKey = 'stallionProspects'

const resourcePathName = 'stallion_prospect'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  prospectType: {
    kind: 'column',
    name: 'prospectType',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'stallion',
        },
        {
          value: 1,
          key: 'prospect',
        },
        {
          value: 2,
          key: 'other',
        },
      ],
    },
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  classification: {
    kind: 'column',
    name: 'classification',
    type: 'string',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  appraisal: {
    kind: 'column',
    name: 'appraisal',
    type: 'integer',
  },
  studFee: {
    kind: 'column',
    name: 'studFee',
    type: 'integer',
  },
  breedingRights: {
    kind: 'column',
    name: 'breedingRights',
    type: 'string',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  removed: {
    kind: 'column',
    name: 'removed',
    type: 'date',
  },
  retired: {
    kind: 'column',
    name: 'retired',
    type: 'date',
  },
}

const modelSpec: ModelSpec<StallionProspect> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
