import * as React from 'react'

import { App } from '@/app'
import '@/assets/stylesheets/application.css'
import '@/libs/i18n/config'
import { getCookie } from '@/utils/cookie'
import { Config } from '@js-from-routes/client'
import * as ReactDOM from 'react-dom/client'

// JS From Routes config
Config.deserializeData = function (val) {
  return val
}

Config.getCSRFToken = function () {
  const cookieToken = getCookie('CSRF-TOKEN')
  return Config.csrfToken && Config.csrfToken !== '' ? Config.csrfToken : cookieToken
}

const rootElement = document.getElementById('root')

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
