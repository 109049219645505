import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { PregnancyCheck } from '@/types'

const name = 'PregnancyCheck'

const recordNameSingular = 'pregnancyCheck'
const recordNamePlural = 'pregnancyChecks'
const i18nKey = 'pregnancyCheck'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'pregnancyChecks'

const pageHelperKey = 'pregnancyChecks'

const resourcePathName = 'pregnancy_check'

const attributes: Record<string, AttributeSpec> = {
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  checkedAt: {
    kind: 'column',
    name: 'checkedAt',
    type: 'datetime',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'pregnant',
        },
        {
          value: 1,
          key: 'barren',
        },
        {
          value: 2,
          key: 'slipped',
        },
        {
          value: 3,
          key: 'dead_foal',
        },
        {
          value: 4,
          key: 'foaled',
        },
      ],
    },
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'male',
        },
        {
          value: 1,
          key: 'female',
        },
      ],
    },
  },
  notes: {
    kind: 'column',
    name: 'notes',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<PregnancyCheck> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
