import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsTopFinisher } from '@/types'

const name = 'DRFChartsTopFinisher'

const recordNameSingular = 'drfChartsTopFinisher'
const recordNamePlural = 'drfChartsTopFinishers'
const i18nKey = 'drf::ChartsTopFinisher'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsTopFinishers'

const pageHelperKey = 'drfChartsTopFinishers'

const resourcePathName = 'drf_charts_top_finisher'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsTopFinishersId: {
    kind: 'column',
    name: 'chartsTopFinishersId',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  ordering: {
    kind: 'column',
    name: 'ordering',
    type: 'integer',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'string',
  },
  winPayoff: {
    kind: 'column',
    name: 'winPayoff',
    type: 'string',
  },
  placePayoff: {
    kind: 'column',
    name: 'placePayoff',
    type: 'string',
  },
  showPayoff: {
    kind: 'column',
    name: 'showPayoff',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DRFChartsTopFinisher> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
