import { z } from 'zod'

export const ConformationNoteSchema = z.object({
  horseId: z.number().int(),
  organizationId: z.number().int(),
  userId: z.union([z.number().int(), z.null()]).optional(),
  date: z.string().date().default('2024-09-08'),
  rating: z.union([z.string(), z.null()]).optional(),
  comment: z.union([z.string(), z.null()]).optional(),
})
