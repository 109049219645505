import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DRFChartsHorse } from '@/types'

const name = 'DRFChartsHorse'

const recordNameSingular = 'drfChartsHorse'
const recordNamePlural = 'drfChartsHorses'
const i18nKey = 'drf::ChartsHorse'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'drfChartsHorses'

const pageHelperKey = 'drfChartsHorses'

const resourcePathName = 'drf_charts_horse'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  chartsHorsesId: {
    kind: 'column',
    name: 'chartsHorsesId',
    type: 'integer',
  },
  chartId: {
    kind: 'column',
    name: 'chartId',
    type: 'integer',
  },
  registrationNumber: {
    kind: 'column',
    name: 'registrationNumber',
    type: 'string',
  },
  horseName: {
    kind: 'column',
    name: 'horseName',
    type: 'string',
  },
  trainerName: {
    kind: 'column',
    name: 'trainerName',
    type: 'string',
  },
  programNumber: {
    kind: 'column',
    name: 'programNumber',
    type: 'string',
  },
  jockeyName: {
    kind: 'column',
    name: 'jockeyName',
    type: 'string',
  },
  weightCarried: {
    kind: 'column',
    name: 'weightCarried',
    type: 'integer',
  },
  claimingPrice: {
    kind: 'column',
    name: 'claimingPrice',
    type: 'string',
  },
  postPosition: {
    kind: 'column',
    name: 'postPosition',
    type: 'integer',
  },
  positionAtFinish: {
    kind: 'column',
    name: 'positionAtFinish',
    type: 'integer',
  },
  ordering: {
    kind: 'column',
    name: 'ordering',
    type: 'integer',
  },
  lasix: {
    kind: 'column',
    name: 'lasix',
    type: 'integer',
  },
  lasixRed: {
    kind: 'column',
    name: 'lasixRed',
    type: 'integer',
  },
  wraps: {
    kind: 'column',
    name: 'wraps',
    type: 'integer',
  },
  blinkers: {
    kind: 'column',
    name: 'blinkers',
    type: 'integer',
  },
  blinkersRed: {
    kind: 'column',
    name: 'blinkersRed',
    type: 'integer',
  },
  closingOdds: {
    kind: 'column',
    name: 'closingOdds',
    type: 'string',
  },
  performanceFigure: {
    kind: 'column',
    name: 'performanceFigure',
    type: 'string',
  },
  longComment: {
    kind: 'column',
    name: 'longComment',
    type: 'string',
  },
  fr1ThisHorseAdj: {
    kind: 'column',
    name: 'fr1ThisHorseAdj',
    type: 'string',
  },
  fr2ThisHorseAdj: {
    kind: 'column',
    name: 'fr2ThisHorseAdj',
    type: 'string',
  },
  fr3ThisHorseAdj: {
    kind: 'column',
    name: 'fr3ThisHorseAdj',
    type: 'string',
  },
  fr4ThisHorseAdj: {
    kind: 'column',
    name: 'fr4ThisHorseAdj',
    type: 'string',
  },
  ftThisHorseAdj: {
    kind: 'column',
    name: 'ftThisHorseAdj',
    type: 'string',
  },
  fr1ThisHorseIncrAdj: {
    kind: 'column',
    name: 'fr1ThisHorseIncrAdj',
    type: 'string',
  },
  fr2ThisHorseIncrAdj: {
    kind: 'column',
    name: 'fr2ThisHorseIncrAdj',
    type: 'string',
  },
  fr3ThisHorseIncrAdj: {
    kind: 'column',
    name: 'fr3ThisHorseIncrAdj',
    type: 'string',
  },
  fr4ThisHorseIncrAdj: {
    kind: 'column',
    name: 'fr4ThisHorseIncrAdj',
    type: 'string',
  },
  ftThisHorseIncrAdj: {
    kind: 'column',
    name: 'ftThisHorseIncrAdj',
    type: 'string',
  },
  fr1ThisHorseOff: {
    kind: 'column',
    name: 'fr1ThisHorseOff',
    type: 'string',
  },
  fr2ThisHorseOff: {
    kind: 'column',
    name: 'fr2ThisHorseOff',
    type: 'string',
  },
  fr3ThisHorseOff: {
    kind: 'column',
    name: 'fr3ThisHorseOff',
    type: 'string',
  },
  fr4ThisHorseOff: {
    kind: 'column',
    name: 'fr4ThisHorseOff',
    type: 'string',
  },
  ftThisHorseOff: {
    kind: 'column',
    name: 'ftThisHorseOff',
    type: 'string',
  },
  fr1ThisHorseIncrOff: {
    kind: 'column',
    name: 'fr1ThisHorseIncrOff',
    type: 'string',
  },
  fr2ThisHorseIncrOff: {
    kind: 'column',
    name: 'fr2ThisHorseIncrOff',
    type: 'string',
  },
  fr3ThisHorseIncrOff: {
    kind: 'column',
    name: 'fr3ThisHorseIncrOff',
    type: 'string',
  },
  fr4ThisHorseIncrOff: {
    kind: 'column',
    name: 'fr4ThisHorseIncrOff',
    type: 'string',
  },
  ftThisHorseIncrOff: {
    kind: 'column',
    name: 'ftThisHorseIncrOff',
    type: 'string',
  },
  paceFigure1Horse: {
    kind: 'column',
    name: 'paceFigure1Horse',
    type: 'string',
  },
  paceFigure2Horse: {
    kind: 'column',
    name: 'paceFigure2Horse',
    type: 'string',
  },
  paceFigure3Horse: {
    kind: 'column',
    name: 'paceFigure3Horse',
    type: 'string',
  },
  paceFigure4Horse: {
    kind: 'column',
    name: 'paceFigure4Horse',
    type: 'string',
  },
  paceFigureFinal: {
    kind: 'column',
    name: 'paceFigureFinal',
    type: 'string',
  },
  startCallAhead: {
    kind: 'column',
    name: 'startCallAhead',
    type: 'string',
  },
  call1Ahead: {
    kind: 'column',
    name: 'call1Ahead',
    type: 'string',
  },
  call2Ahead: {
    kind: 'column',
    name: 'call2Ahead',
    type: 'string',
  },
  call3Ahead: {
    kind: 'column',
    name: 'call3Ahead',
    type: 'string',
  },
  finishPositionAhead: {
    kind: 'column',
    name: 'finishPositionAhead',
    type: 'string',
  },
  startCallBehind: {
    kind: 'column',
    name: 'startCallBehind',
    type: 'string',
  },
  call1Behind: {
    kind: 'column',
    name: 'call1Behind',
    type: 'string',
  },
  call2Behind: {
    kind: 'column',
    name: 'call2Behind',
    type: 'string',
  },
  call3Behind: {
    kind: 'column',
    name: 'call3Behind',
    type: 'string',
  },
  finishPositionBehind: {
    kind: 'column',
    name: 'finishPositionBehind',
    type: 'string',
  },
  horseAge: {
    kind: 'column',
    name: 'horseAge',
    type: 'float',
  },
  startCallCode: {
    kind: 'column',
    name: 'startCallCode',
    type: 'string',
  },
  call1Code: {
    kind: 'column',
    name: 'call1Code',
    type: 'string',
  },
  call2Code: {
    kind: 'column',
    name: 'call2Code',
    type: 'string',
  },
  dummy1: {
    kind: 'column',
    name: 'dummy1',
    type: 'float',
  },
  dummy2: {
    kind: 'column',
    name: 'dummy2',
    type: 'float',
  },
  call3Code: {
    kind: 'column',
    name: 'call3Code',
    type: 'string',
  },
  finishPositionCode: {
    kind: 'column',
    name: 'finishPositionCode',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  startCallCodeBehind: {
    kind: 'column',
    name: 'startCallCodeBehind',
    type: 'string',
  },
  call1CodeBehind: {
    kind: 'column',
    name: 'call1CodeBehind',
    type: 'string',
  },
  call2CodeBehind: {
    kind: 'column',
    name: 'call2CodeBehind',
    type: 'string',
  },
  call3CodeBehind: {
    kind: 'column',
    name: 'call3CodeBehind',
    type: 'string',
  },
  finishPositionCodeBehind: {
    kind: 'column',
    name: 'finishPositionCodeBehind',
    type: 'string',
  },
}

const modelSpec: ModelSpec<DRFChartsHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
