import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTSaleResult } from '@/types'

const name = 'DBTSaleResult'

const recordNameSingular = 'dbtSaleResult'
const recordNamePlural = 'dbtSaleResults'
const i18nKey = 'dbt::SaleResult'

const primaryKey = 'saleResultId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtSaleResults'

const pageHelperKey = 'dbtSaleResults'

const resourcePathName = 'dbt_sale_result'

const attributes: Record<string, AttributeSpec> = {
  saleId: {
    kind: 'column',
    name: 'saleId',
    type: 'integer',
  },
  saleResultId: {
    kind: 'column',
    name: 'saleResultId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  saleTitle: {
    kind: 'column',
    name: 'saleTitle',
    type: 'text',
  },
  saleName: {
    kind: 'column',
    name: 'saleName',
    type: 'string',
  },
  saleHost: {
    kind: 'column',
    name: 'saleHost',
    type: 'string',
  },
  saleLocation: {
    kind: 'column',
    name: 'saleLocation',
    type: 'string',
  },
  saleCode: {
    kind: 'column',
    name: 'saleCode',
    type: 'string',
  },
  saleType: {
    kind: 'column',
    name: 'saleType',
    type: 'string',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  session: {
    kind: 'column',
    name: 'session',
    type: 'integer',
  },
  sessionDate: {
    kind: 'column',
    name: 'sessionDate',
    type: 'date',
  },
  book: {
    kind: 'column',
    name: 'book',
    type: 'integer',
  },
  hip: {
    kind: 'column',
    name: 'hip',
    type: 'string',
  },
  barn: {
    kind: 'column',
    name: 'barn',
    type: 'string',
  },
  firstFoal: {
    kind: 'column',
    name: 'firstFoal',
    type: 'string',
  },
  secondFoal: {
    kind: 'column',
    name: 'secondFoal',
    type: 'string',
  },
  pregnant: {
    kind: 'column',
    name: 'pregnant',
    type: 'boolean',
  },
  coveringSire: {
    kind: 'column',
    name: 'coveringSire',
    type: 'string',
  },
  coverDate: {
    kind: 'column',
    name: 'coverDate',
    type: 'date',
  },
  coverPrice: {
    kind: 'column',
    name: 'coverPrice',
    type: 'integer',
  },
  breedersCupElig: {
    kind: 'column',
    name: 'breedersCupElig',
    type: 'boolean',
  },
  consignor: {
    kind: 'column',
    name: 'consignor',
    type: 'string',
  },
  consignorId: {
    kind: 'column',
    name: 'consignorId',
    type: 'integer',
  },
  buyer: {
    kind: 'column',
    name: 'buyer',
    type: 'string',
  },
  buyerId: {
    kind: 'column',
    name: 'buyerId',
    type: 'integer',
  },
  rna: {
    kind: 'column',
    name: 'rna',
    type: 'boolean',
  },
  out: {
    kind: 'column',
    name: 'out',
    type: 'boolean',
  },
  sold: {
    kind: 'column',
    name: 'sold',
    type: 'boolean',
  },
  resultPriority: {
    kind: 'column',
    name: 'resultPriority',
    type: 'integer',
  },
  salePrice: {
    kind: 'column',
    name: 'salePrice',
    type: 'decimal',
  },
  salePriceSold: {
    kind: 'column',
    name: 'salePriceSold',
    type: 'decimal',
  },
  reservePrice: {
    kind: 'column',
    name: 'reservePrice',
    type: 'decimal',
  },
  workTime: {
    kind: 'column',
    name: 'workTime',
    type: 'decimal',
  },
  workSet: {
    kind: 'column',
    name: 'workSet',
    type: 'string',
  },
  workDay: {
    kind: 'column',
    name: 'workDay',
    type: 'string',
  },
  ps: {
    kind: 'column',
    name: 'ps',
    type: 'string',
  },
  propertyLine: {
    kind: 'column',
    name: 'propertyLine',
    type: 'string',
  },
  grade: {
    kind: 'column',
    name: 'grade',
    type: 'string',
  },
  soldAsCode: {
    kind: 'column',
    name: 'soldAsCode',
    type: 'string',
  },
  soldAsDesc: {
    kind: 'column',
    name: 'soldAsDesc',
    type: 'text',
  },
  soldAsCodeMap: {
    kind: 'column',
    name: 'soldAsCodeMap',
    type: 'text',
  },
  soldAsAgeGroup: {
    kind: 'column',
    name: 'soldAsAgeGroup',
    type: 'text',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  sireYearGroupRank: {
    kind: 'column',
    name: 'sireYearGroupRank',
    type: 'integer',
  },
  sireYearGroupCount: {
    kind: 'column',
    name: 'sireYearGroupCount',
    type: 'integer',
  },
  saleNumber: {
    kind: 'column',
    name: 'saleNumber',
    type: 'integer',
  },
  saleNumberReverse: {
    kind: 'column',
    name: 'saleNumberReverse',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<DBTSaleResult> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
