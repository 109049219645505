import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { MatingForm } from '@/resources/mating/form'

const MatingsNew = () => {
  const { matingsNew } = useLoaderQueryBuilders()
  const { record: mating } = useResourceQuery(matingsNew)

  return (
    <Section title="New Mating">
      <MatingForm initialValues={mating} />
    </Section>
  )
}

export default MatingsNew
