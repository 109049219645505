import React, { useId } from 'react'

import { Button } from '@/components/ui/button'
import { Form as BaseForm, FormGeneralMessage } from '@/components/ui/form'
import { useResourceT } from '@/hooks/use_resource_t'
import { DevTool } from '@hookform/devtools'

import { Loader } from '../icons'
import { FormDelete } from './form-delete'
import { useForm } from './hooks/use_form'

type FormHookProps = ReturnType<typeof useForm>

type FormProps = FormHookProps & {
  children: React.ReactNode
}

const Form = ({
  form,
  resource,
  handleSubmit,
  handleCancel,
  handleDelete,
  isSaving = false,
  canDelete,
  children,
  ...props
}: FormProps) => {
  const { t } = useResourceT(resource)
  const uniqueId = useId()

  return (
    <BaseForm
      t={t}
      attributes={resource.model.attributes}
      getAttribute={resource.model.getAttribute}
      {...form}
    >
      <form
        id={`${resource.name}Form${uniqueId}`}
        onSubmit={handleSubmit}
        className="w-full min-w-[25rem] space-y-4"
      >
        {children}
        <FormGeneralMessage />
        <div className="flex flex-row justify-between">
          <div className="mb-4 flex flex-col gap-4 sm:flex-row">
            {isSaving ? (
              <Button
                disabled
                form={`${resource.name}Form${uniqueId}`}
                iconLeft={<Loader className="animate-spin" />}
              >
                Saving
              </Button>
            ) : (
              <Button type="submit" form={`${resource.name}Form${uniqueId}`}>
                Save
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={handleCancel}
              form={`${resource.name}Form${uniqueId}`}
            >
              Cancel
            </Button>
          </div>
          {canDelete ? (
            <FormDelete
              resource={resource}
              handleDelete={handleDelete}
              isDeleting={props.isDeleting}
            />
          ) : null}
        </div>
      </form>
      <DevTool control={form.control} />
    </BaseForm>
  )
  // { <DevTool control={form.control}/>}
}

export default Form
