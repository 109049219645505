import { Action, Actions } from '@/components/actions'
import { iconFormatter } from '@/components/data-table'
import { defineTable } from '@/components/data-table/builder'
import { broodmareProspectsRoutes } from '@/routes'

import { BroodmareProspectResource } from './resource'

export const broodmareProspectTable = defineTable(
  BroodmareProspectResource,
  {},
  (tbl, props): TableBuilderSpec<BroodmareProspect> => ({
    columns: [
      tbl.col.id({
        enableColumnFilter: false,
      }),

      tbl.displayCol('actions', {
        enableColumnFilter: false,
        size: 100,
        header: () => null,
        cell: ({ row }) => (
          <Actions numToShow={1}>
            <Action
              name="edit"
              route={broodmareProspectsRoutes.edit.withOptions({
                broodmareProspectId: row.id,
              })}
            />
          </Actions>
        ),
      }),

      tbl.col.rating(),
      tbl.col.comment({
        cell: iconFormatter({
          iconKey: 'Clipboard',
          tooltipAccessor: tbl.col.comment.id(),
        }),
      }),
      tbl.col.removed(),
    ],
  }),
)
