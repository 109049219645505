import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Link } from '@/components/link'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { RouteHelper } from '@/libs/routes/route'
import { currentYear, range } from '@/utils'

interface BookSelectProps {
  route: RouteHelper
}

const BookSelect = ({ route }: BookSelectProps) => {
  const { bookYear = currentYear() } = useParams()
  const maxYear = currentYear(1)
  const rangeSize = maxYear - bookYear
  const minYear = bookYear - (rangeSize < 4 ? 5 - rangeSize : 2)

  const years = useMemo(() => range(minYear, maxYear).sort((a, b) => b - a), [minYear, maxYear])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="sm">
          {bookYear}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Select Year</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {years.map((y) => (
          <DropdownMenuItem key={y} className="p-0">
            <Link
              className="w-full justify-start px-2 py-1.5"
              route={route.withOptions({ bookYear: y })}
              size="sm"
              noChevron
            >
              {y}
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default BookSelect
