import { PageHeader } from '@/components/header'

function StallionsRacing() {
  return (
    <>
      <PageHeader title="Stallions Racing" />
    </>
  )
}

export default StallionsRacing
