import { useParams } from 'react-router-dom'

import { Action } from '@/components/actions'
import { BaseDataTable } from '@/components/data-table/base_table'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { useAuth } from '@/contexts/auth'
import BookSelect from '@/pages/books/book-select'
import { DBTSeasonDetailResource, dbtSeasonDetailTable } from '@/resources/dbt/season_detail'
import { booksRoutes } from '@/routes'

import { LayoutOutlet } from '../layouts/layout-outlet'

const SeasonsIndex = () => {
  const { isSales, isSalesAdmin, isCoalition } = useAuth()
  const { bookYear } = useParams()

  const query = DBTSeasonDetailResource.query.index
    .filter('year', bookYear)
    .filter('onlySeasons', true, 'scope')

  const tableProps = dbtSeasonDetailTable.props(
    {
      query,
      clientSide: true,
      verticalScroll: true,
      defaultPageSize: 100,
      meta: {
        tableActions: [
          () => (
            <Action
              name="new"
              variant="secondary"
              size="sm"
              label="New Season"
              route={booksRoutes.seasons.new.withOptions({ bookYear })}
            />
          ),
        ],
      },
    },
    { year: bookYear, isSales, isSalesAdmin, isCoalition, isSeasons: true },
  )

  const datatable = useDataTable(tableProps)

  return (
    <>
      <PageHeader title="Seasons" actions={<BookSelect route={booksRoutes.seasons} />} />
      <BaseDataTable {...datatable} />
      <LayoutOutlet />
    </>
  )
}

export default SeasonsIndex
