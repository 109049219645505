import { defineTable } from '@/components/data-table/builder'
import { mappedSorter } from '@/components/data-table/sort'

import { FinanceAccountResource } from './resource'

export const financeAccountTable = defineTable(FinanceAccountResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.accountNo(),
    tbl.col.accountPrefix(),
    tbl.col.title(),
    tbl.col.accountLabel(),
    tbl.col.accountType(),
    tbl.col.normalBalance(),
    tbl.col.normalBalanceSign(),
    tbl.col.accountTypeFull(),
    tbl.col.cashFlowGroup({
      sortingFn: mappedSorter({
        Operating: 0,
        Investing: 1,
        Financing: 2,
      }),
    }),
    tbl.col.cashFlowGroupNoEquineDep(),
    tbl.col.cashFlowAccountGroup(),
    tbl.col.cashFlowAccountGroupNoEquineDep(),
    tbl.col.iscfAccountGroup(),
    tbl.col.closingType(),
    tbl.col.closingAccountNo(),
    tbl.col.closingAccountTitle(),
    tbl.col.status(),
    tbl.col.requireDept(),
    tbl.col.requireLoc(),
    tbl.col.taxable(),
    tbl.col.categoryKey(),
    tbl.col.category(),
    tbl.col.taxCode(),
    tbl.col.mrcCode(),
    tbl.col.closeToAcctKey(),
    tbl.col.alternativeAccount(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.subLedgerControlOn(),
    tbl.col.megaEntityKey(),
    tbl.col.megaEntityId(),
    tbl.col.megaEntityName(),
    tbl.col.requireCustomer(),
    tbl.col.requireVendor(),
    tbl.col.requireEmployee(),
    tbl.col.requireClass(),
    tbl.col.recordUrl(),
    tbl.col.nonHorse(),
    tbl.col.unused(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
    tbl.col.accountGroup(),
    tbl.col.accountSector(),
    tbl.col.accountExpenseType(),
    tbl.col.eponaAccountExpenseGroup(),
  ],
}))
