import { PanelContent } from '@/components/panel/content'
import { PanelItem } from '@/components/panel/item'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { salesRoutes } from '@/routes'

export const SalesPanel = () => {
  return (
    <PanelContent title={'Sales'}>
      <SidebarGroup>
        <SidebarGroupLabel>Dashboard</SidebarGroupLabel>
        <SidebarMenu>
          <PanelItem label="Sales" to={salesRoutes.index()} />
        </SidebarMenu>
      </SidebarGroup>
    </PanelContent>
  )
}
