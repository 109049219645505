/*
 * TODO Implement Index Page for bloodstock/racehorses
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function RacehorsesIndex() {
  return (
    <>
      <PageHeader title="Racehorses" />
    </>
  )
}

export default RacehorsesIndex
