// JsFromRoutes CacheKey 04fa174f65e314f67fac937f3e9d6c24
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { HorseDetailModel } from '@/models/horse_detail'

export const horseDetailsApi = {
  index: defineEndpoint({
    controllerName: 'horse_details',
    actionName: 'index',
    model: HorseDetailModel,
    parts: ['api', 'horse_details'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'horse_details',
    actionName: 'show',
    model: HorseDetailModel,
    parts: [
      'api',
      'horse_details',
      { name: 'horseDetailId', attributeId: 'HorseDetail:horseDetailId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}
