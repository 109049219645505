import React from 'react'

import { Header, Title } from '@/components/header'
import { cn } from '@/utils'

export type SectionProps = React.HTMLAttributes<HTMLElement> & {
  title?: string
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  grid?: boolean
  flex?: boolean
}

const Section = React.forwardRef(
  ({ title, as = 'h3', grid, flex, children, ...props }: SectionProps, ref: HTMLElement) => {
    return (
      <section ref={ref} {...props}>
        {title && (
          <Header className="mb-4">
            <Title as={as}>{title}</Title>
          </Header>
        )}
        <div
          className={cn('w-full', grid && 'grid grid-cols-12 gap-2', flex && 'flex flex-col gap-2')}
        >
          {children}
        </div>
      </section>
    )
  },
)

export { Section }
