import React from 'react'

import { Loader } from '@/components/icon'
import { Skeleton } from '@/components/ui/skeleton'

export const DefaultSuspense = (props) => (
  <React.Suspense
    fallback={
      <div className="flex size-full items-center justify-center">
        <Loader className="animate-spin" />
      </div>
    }
    {...props}
  />
)

export const PageSuspense = (props) => (
  <React.Suspense
    fallback={
      <>
        <Skeleton className="my-8 h-12 w-72" />
        <Skeleton className="size-full" />
      </>
    }
    {...props}
  />
)
