// JsFromRoutes CacheKey 389f519320a261237c002766d3338df7
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { DBTHorseModel } from '@/models/dbt/horse'

export const dbtHorsesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/horses',
    actionName: 'index',
    model: DBTHorseModel,
    parts: ['api', 'dbt', 'horses'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/horses',
    actionName: 'show',
    model: DBTHorseModel,
    parts: ['api', 'dbt', 'horses', { name: 'id', attributeId: null }],
    httpMethod: 'get',
    type: 'view',
  }),
}
