import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { Pencil } from '@/components/icons'
import { InfoTile } from '@/components/info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Badge } from '@/components/ui/badge'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardLabels,
  CardTitle,
} from '@/components/ui/card'
import { ChangeTag } from '@/components/ui/tag'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { appraisalsRoutes } from '@/routes'
import { Appraisal } from '@/types'
import { formatDate, formatCurrency, cn } from '@/utils'

import { AppraisalResource } from './resource'

interface AppraisalContentProps {
  appraisal: Appraisal
  previousAppraisal?: Appraisal
  label?: string
  inline?: boolean
  muted?: boolean
  latest?: boolean
}

export const AppraisalContent = ({
  label,
  appraisal,
  previousAppraisal,
  inline = false,
  muted = false,
  latest = false,
}: AppraisalContentProps) => {
  return (
    <div className="col-span-12 flex gap-2 @container">
      <div className="grid w-full auto-rows-4rem grid-cols-12 gap-2">
        {latest ? (
          <InfoTile
            className="col-span-4 row-span-1 text-3xl tablet:col-span-12"
            variant={inline ? 'inline' : ''}
            label={label}
            icon={ChangeTag({
              value: appraisal.value,
              previousValue: previousAppraisal?.value,
            })}
            muted={muted}
          >
            <Text as="span" size="3xl" className="font-normal">
              {formatCurrency(appraisal.value)}
            </Text>
          </InfoTile>
        ) : (
          <DataTile
            className={cn(
              'col-span-12 row-span-2 justify-end @xs:col-span-6 @2xl:col-span-4 @2xl:row-span-1 tablet:col-span-12',
              latest && '@2xl:row-span-2',
              previousAppraisal && 'justify-between',
            )}
            variant={inline ? 'inline' : ''}
            label={label}
            icon={ChangeTag({
              value: appraisal.value,
              previousValue: previousAppraisal?.value,
            })}
            muted={muted}
          >
            <span>{formatCurrency(appraisal.value)}</span>
          </DataTile>
        )}
        <InfoTile
          className="col-span-6 hidden @xs:inline-flex @2xl:col-span-4"
          label="Appraiser"
          variant="ghost"
          muted={muted}
        >
          {appraisal.user.name}
        </InfoTile>
        <InfoTile
          className="col-span-6 hidden @xs:inline-flex @2xl:col-span-4"
          label="Date"
          variant="ghost"
          muted={muted}
        >
          {formatDate(appraisal.date)}
        </InfoTile>
      </div>
    </div>
  )
}

export const AppraisalCard = ({
  appraisal,
  previousAppraisal,
}: {
  appraisal: Appraisal
  previousAppraisal?: Appraisal
}) => {
  const today = new Date()

  const appraisalDate = new Date(appraisal.date)

  const offsetDate = new Date(appraisalDate)
  offsetDate.setDate(offsetDate.getDate() + 14)

  const activeAppraisal = appraisalDate <= today && today <= offsetDate

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Link
            route={AppraisalResource.routes.show.withOptions({
              appraisalId: appraisal.id,
            })}
          >
            {appraisal.title}
          </Link>
        </CardTitle>
        <CardLabels>
          <Badge variant={activeAppraisal ? 'active' : ''} />
        </CardLabels>
        <CardActions>
          <Link
            iconLeft={<Pencil />}
            route={appraisalsRoutes.edit.withOptions({
              appraisalId: appraisal.id,
            })}
            noChevron
          />
        </CardActions>
      </CardHeader>
      <CardContent>
        <AppraisalContent
          appraisal={appraisal}
          previousAppraisal={previousAppraisal}
          inline
          muted
        />
      </CardContent>
    </Card>
  )
}

export const AppraisalSummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: appraisals, error, isPending } = useResourceQuery(query)
  const pluralName = AppraisalResource.model.recordNamePlural

  return (
    <SummaryCard
      actions={<NewAction resource={AppraisalResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={AppraisalResource.routes.index}
    >
      {appraisals.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <AppraisalContent
          appraisal={appraisals[0]}
          previousAppraisal={appraisals[1]}
          inline
          muted
        />
      )}
    </SummaryCard>
  )
}
