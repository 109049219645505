import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tag } from '@/components/ui/tag'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { ConformationNote } from '@/types'
import { formatDate } from '@/utils'

import { ConformationNoteResource } from './resource'

export const ConformationNoteContent = ({
  conformationNote,
}: {
  conformationNote: ConformationNote
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Tag size="xl" className="border border-grey-500 text-2xl" iconOnly>
          {conformationNote.rating || '-'}
        </Tag>
        <div className="flex flex-col gap-2">
          <InlineInfoTile
            label={formatDate(conformationNote.date)}
            icon="Calendar"
            tooltipText="Date"
          />
          <InlineInfoTile label={conformationNote.user.name} icon="User" tooltipText="Person" />
        </div>
        <Tag className="self-start bg-blue text-white">{conformationNote.ageMonths} mos</Tag>
      </div>
      <div className="h-16 overflow-x-auto rounded bg-grey-100 p-3">
        <span className="line-clamp-2 text-xs">{conformationNote.comment}</span>
      </div>
    </div>
  )
}

const ConformationNoteSummaryContent = ({
  conformationNotes,
}: {
  conformationNotes: ConformationNote[]
}) => {
  return <ConformationNoteContent conformationNote={conformationNotes[0]} />
}

export const ConformationNoteCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: conformationNote } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={ConformationNoteResource.routes.index}>{conformationNote.title}</Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={ConformationNoteResource} />
        </CardActions>
      </CardHeader>
      <CardContent>
        <ConformationNoteContent conformationNote={conformationNote} />
      </CardContent>
    </Card>
  )
}

export const ConformationNoteSummaryCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { records: conformationNotes, error, isPending } = useResourceQuery(query)
  const pluralName = 'Conformation Notes'

  return (
    <SummaryCard
      actions={<NewAction resource={ConformationNoteResource} iconOnly />}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleRoute={ConformationNoteResource.routes.index}
    >
      {conformationNotes.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <ConformationNoteSummaryContent conformationNotes={conformationNotes} />
      )}
    </SummaryCard>
  )
}
