import { Action, ActionProps } from '@/components/actions'
import { useResourceT } from '@/hooks/use_resource_t'
import { PrimaryKeyValue, Resource, ResourceRecord } from '@/libs/resource/types'

type ResourceActionProps<T extends ResourceRecord> = ActionProps & {
  name: string
  resource: Resource<T>
  id?: PrimaryKeyValue
}

const ResourceAction = <T extends ResourceRecord>({
  id,
  name,
  resource,
  ...props
}: ResourceActionProps<T>) => {
  const { t } = useResourceT(resource)

  const { route = resource.routes[name], label = t(`actions.${name}`), ...actionProps } = props

  if (route == null) {
    console.warn(
      `Cannot render ResourceAction. Action '${name}' does not exist for resource '${resource.name}'`,
    )
    return null
  }

  return <Action name={name} route={route} label={label} {...actionProps} />
}

const EditAction = <T extends ResourceRecord>({
  resource,
  ...props
}: Omit<ResourceActionProps<T>, 'name'>) => {
  return <ResourceAction resource={resource} name="edit" {...props} />
}

const NewAction = <T extends ResourceRecord>({
  resource,
  ...props
}: Omit<ResourceActionProps<T>, 'name'>) => {
  return <ResourceAction resource={resource} name="new" {...props} />
}

const DestroyAction = <T extends ResourceRecord>({
  resource,
  ...props
}: Omit<ResourceActionProps<T>, 'name'>) => {
  return <ResourceAction resource={resource} name="destroy" {...props} />
}

export { ResourceAction, NewAction, EditAction, DestroyAction }
