import { eachMonthOfInterval, format, parse } from 'date-fns'

export const firstOfCurrentMonth = () => new Date(new Date().setDate(1))

export const DateFormat = 'yyyyMMdd'

export const formatDate = (date: string | Date, dateFormat: string = DateFormat): string =>
  format(date, dateFormat)

export const parseDate = (input: string, dateFormat: string = DateFormat): Date =>
  parse(input, dateFormat, new Date())

export const monthsBetweenDates = (start: Date, end: Date): Date[] =>
  eachMonthOfInterval({ start, end })
