import { PageHeader } from '@/components/header'

function StallionCrops() {
  return (
    <>
      <PageHeader title="Stallion Crops" />
    </>
  )
}

export default StallionCrops
