import React from 'react'

import { cn } from '@/utils'
import {
  AvailableChartColors,
  AvailableChartColorsKeys,
  getColorClassName,
} from '@/utils/chart_colors'

import { TremorTooltip } from './tremor-tooltip'
import { formatNumber, getMarkerBgColor, sumNumericArray } from './utils'

const getPositionLeft = (value: number | undefined, maxValue: number): number =>
  value ? ((maxValue - value) / maxValue) * 100 : 100

const BarLabels = ({ values }: { values: number[] }) => {
  const sumValues = React.useMemo(() => sumNumericArray(values), [values])
  let prefixSum = 0
  let sumConsecutiveHiddenLabels = 0
  const reversedValues = values.slice().reverse()

  return (
    <div
      className={cn(
        // base
        'relative flex h-5 w-full flex-row-reverse text-sm font-medium',
        // text color
        'text-gray-700 dark:text-gray-300',
      )}
    >
      {reversedValues.map((value, index) => {
        const widthPercentage = (value / sumValues) * 100
        prefixSum += value

        const showLabel =
          (value >= 0.1 * sumValues || sumConsecutiveHiddenLabels >= 0.09 * sumValues) &&
          sumValues - prefixSum >= 0.1 * sumValues &&
          prefixSum >= 0.1 * sumValues &&
          prefixSum < 0.9 * sumValues

        sumConsecutiveHiddenLabels = showLabel ? 0 : (sumConsecutiveHiddenLabels += value)

        return (
          <div
            key={`item-${index}`}
            className="flex items-center justify-start"
            style={{ width: `${widthPercentage}%` }}
          >
            <span
              className={cn(
                showLabel ? 'block' : 'hidden',
                '-translate-x-1/2 text-sm tabular-nums',
              )}
            >
              {formatNumber(prefixSum)}
            </span>
          </div>
        )
      })}
      <div className="absolute bottom-0 left-0 flex items-center">{formatNumber(sumValues)}</div>
      <div className="absolute bottom-0 right-0 flex items-center">1</div>
    </div>
  )
}

interface RankBarProps extends React.HTMLAttributes<HTMLDivElement> {
  values: number[]
  colors?: AvailableChartColorsKeys[]
  marker?: { value: number; tooltip?: string; showAnimation?: boolean }
  showLabels?: boolean
}

const defaultValues: number[] = []

const RankBar = React.forwardRef<HTMLDivElement, RankBarProps>(
  (
    {
      values = defaultValues,
      colors = AvailableChartColors,
      marker,
      showLabels = true,
      className,
      ...props
    },
    forwardedRef,
  ) => {
    const markerBgColor = React.useMemo(
      () => getMarkerBgColor(marker?.value, values, colors),
      [marker, values, colors],
    )

    const maxValue = React.useMemo(() => sumNumericArray(values), [values])

    const adjustedMarkerValue = React.useMemo(() => {
      if (marker === undefined) return undefined
      if (marker.value < 0) return 0
      if (marker.value > maxValue) return maxValue
      return marker.value
    }, [marker, maxValue])

    const markerPositionLeft: number = React.useMemo(
      () => getPositionLeft(adjustedMarkerValue, maxValue),
      [adjustedMarkerValue, maxValue],
    )

    return (
      <div
        ref={forwardedRef}
        className={cn(className)}
        aria-label="category bar"
        aria-valuenow={marker?.value}
        tremor-id="tremor-raw"
        {...props}
      >
        <div className="relative flex h-6 w-full items-center">
          <div className="flex h-full flex-1 items-center gap-0.5 overflow-hidden rounded-sm">
            {values.map((value, index) => {
              const barColor = colors[index] ?? 'gray'
              const percentage = (value / maxValue) * 100
              return (
                <div
                  key={`item-${index}`}
                  className={cn(
                    'flex h-full items-center justify-center text-white',
                    getColorClassName(barColor, 'bg'),
                    percentage === 0 && 'hidden',
                  )}
                  style={{ width: `${percentage}%` }}
                >
                  {value}
                </div>
              )
            })}
          </div>

          {marker !== undefined ? (
            <div
              className={cn(
                'absolute w-2 -translate-x-1/2',
                marker.showAnimation && 'transform-gpu transition-all duration-300 ease-in-out',
              )}
              style={{
                left: `${markerPositionLeft}%`,
              }}
            >
              {marker.tooltip ? (
                <TremorTooltip triggerAsChild content={marker.tooltip}>
                  <div
                    aria-hidden="true"
                    className={cn(
                      'relative mx-auto h-8 w-2 rounded-full ring-2',
                      'ring-white dark:ring-gray-950',
                      markerBgColor,
                    )}
                  >
                    <div
                      aria-hidden
                      className="absolute size-7 -translate-x-[45%] -translate-y-[15%]"
                    ></div>
                  </div>
                </TremorTooltip>
              ) : (
                <div
                  className={cn(
                    'mx-auto h-8 w-2 rounded-full ring-2',
                    'ring-white dark:ring-gray-950',
                    markerBgColor,
                  )}
                />
              )}
            </div>
          ) : null}
        </div>
        {showLabels ? <BarLabels values={values} /> : null}
      </div>
    )
  },
)

RankBar.displayName = 'RankBar'

export { RankBar, type RankBarProps }
