/*
 * TODO Implement Index Page for members
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function UsersIndex() {
  return <PageHeader title="Users Index" />
}

export default UsersIndex
