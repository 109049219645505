import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const HfmWinstarClientsIndex = () => {
  const { hfmWinstarClientsIndex } = useLoaderQueryBuilders()
  const hfmWinstarClients = useResourceQuery(hfmWinstarClientsIndex)

  return <Section title="Hfm winstar clients" className="mt-16"></Section>
}

export default HfmWinstarClientsIndex
