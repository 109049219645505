import { Resources } from '@/resources/resources'

import { ResourceRecord } from './record'
import { Resource } from './resource'

const findResource = <T extends ResourceRecord>(name: string): Resource<T> => {
  const resource = Resources[name]

  if (resource === undefined) {
    throw new Error(`Invalid resource name provided: ${name}`)
  }

  return resource
}

export { findResource }
