import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'

import { bloodstockSearchApi } from '@/api'
import { Form, Field, NestedRecords, UseFormProps, useForm } from '@/components/form'
import Icon, { Loader } from '@/components/icon'
import { InfoTile } from '@/components/info_tile'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Text, Currency } from '@/components/ui/text'
import { useAuth } from '@/contexts/auth'
import { usePageContext } from '@/contexts/page'
import { noopQuery } from '@/libs/query/builder'
import { createListQueryBuilder } from '@/libs/query/list-query'
import { CoverInline } from '@/resources/cover/cover-inline'
import { HorseSuggestion } from '@/resources/horse/horse-suggestion'
import { ShareResource } from '@/resources/share'
import { StudFeeResource } from '@/resources/stud_fee'
import { UserResource } from '@/resources/user'
import { SeasonSchema } from '@/schemas/season'
import { Season } from '@/types'
import { cn } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'

import { AmountField } from './amount-field'
import { SeasonResource } from './resource'

/*
 * FormProps
 */
type SeasonFormProps = Omit<UseFormProps<Season>, 'resource'>

/*
 * TODO Implement Form for Season
 */
const SeasonForm: React.FC = ({ zodSchema = SeasonSchema, ...props }: SeasonFormProps) => {
  const { bookYear: year } = useParams()
  const { isSalesAdmin } = useAuth()
  const queryClient = useQueryClient()
  const { navigateBack } = usePageContext()

  const { initialValues, ...otherProps } = props

  const mareQuery = createListQueryBuilder<Horse>(bloodstockSearchApi.search).pageSize(20)

  const initialTransactions =
    initialValues.seasonTransactions.length === 0
      ? [{ purchaserHfmWinstarClientId: null, percentage: 1 }]
      : initialValues.seasonTransactions

  const handleSuccess = (record: Season) => {
    queryClient.invalidateQueries({
      queryKey: ['dbtSeasonDetails'],
      active: true,
    })

    if (props.onSuccess) props.onSuccess(record)

    navigateBack()
  }

  const formConfig = useForm<Season>({
    resource: SeasonResource,
    onSuccess: handleSuccess,
    zodSchema: zodSchema.extend({
      agentCommission: z.union([z.coerce.number(), z.null()]).optional(),
      seasonTransactions: z
        .array(
          z.object({
            id: z.optional(z.coerce.number().int()),
            _destroy: z.optional(z.boolean()),
            purchaserHfmWinstarClientId: z.preprocess(
              (val) => (val == null ? val : Number(val)),
              z.number().int().optional(),
            ),
            percentage: z.optional(z.coerce.number()),
          }),
        )
        .min(0)
        .max(10),
    }),
    initialValues: {
      ...initialValues,
      year: parseInt(year),
      seasonTransactions: initialTransactions,
    },
    ...otherProps,
  })

  const selectedStallionId = formConfig.form.watch('stallionId')
  const selectedMareId = formConfig.form.watch('mareId')

  const isTarget = formConfig.form.watch('target', false)

  const selectedAgentId = formConfig.form.watch('agentHfmWinstarClientId')

  if (!formConfig.form.getValues('agentCommission')) {
    formConfig.form.setValue('agentCommission', 0.05)
  }

  return (
    <div className="flex w-min sm:min-w-[40rem]">
      <Form {...formConfig}>
        <Card>
          <CardContent>
            <Field
              name="stallion"
              resource={StudFeeResource}
              valueAttributeName="stallionId"
              recordQuery={
                selectedStallionId
                  ? StudFeeResource.query.index
                      .filter('year', year)
                      .filter('stallionId', selectedStallionId)
                      .pageSize(1)
                  : noopQuery
              }
              query={StudFeeResource.query.index.scope('winstar', year)}
              onChange={(value) => {
                if (!value) formConfig.form.setValue('amount', null)
              }}
              clientSide
              renderItem={({ suggestion, isSelected }) => (
                <div className="flex w-full gap-0.5">
                  <div className="w-4 py-1">
                    {isSelected && <Icon name="Check" className="self-center" />}
                  </div>
                  <div className="flex w-full justify-between">
                    <Text as="span" size="md" className={cn('font-serif font-semibold')}>
                      {suggestion.stallion?.name}
                    </Text>
                    <Currency value={suggestion.price} className="w-20" />
                  </div>
                </div>
              )}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Field
              name="mare"
              query={mareQuery}
              renderItem={({ suggestion, isSelected }) => (
                <div className="flex w-full gap-0.5">
                  <div className="w-4 py-1">
                    {isSelected && <Icon name="Check" className="self-center" />}
                  </div>
                  <HorseSuggestion horse={suggestion} hideImage={true} hideType={true} />
                </div>
              )}
            />
            <Separator className="my-3" />
            <Suspense fallback="Loading covers">
              <div className="flex flex-col gap-3">
                <CoverInline mareId={selectedMareId} year={year - 1} showActions={!isSalesAdmin} />
              </div>
            </Suspense>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <NestedRecords
              name="seasonTransactions"
              label="Add Season Owner"
              defaultValues={{
                purchaserHfmWinstarClientId: null,
                percentage: 1,
              }}
              renderRecord={(field, index) => (
                <div key={field.id} className="flex w-full justify-between gap-3">
                  <Field
                    key={`transactionClient-${index}`}
                    name={`seasonTransactions.${index}.purchaserHfmWinstarClientId`}
                    label="Mare Owner"
                    filterFn={(query, term) => query.scope('searchBy', term)}
                    disabled={isSalesAdmin}
                    attribute={{
                      name: 'purchaserHfmWinstarClient',
                      kind: 'association',
                      modelName: 'HfmWinstarClient',
                      foreignKey: 'purchaserHfmWinstarClientId',
                    }}
                  />
                  <Field
                    key={`transactionPercentage-${index}`}
                    name={`seasonTransactions.${index}.percentage`}
                    inputType="percentage"
                    className="max-w-60"
                    disabled={isSalesAdmin}
                    attribute={{
                      name: 'percentage',
                      kind: 'column',
                      type: 'decimal',
                      domain: {},
                    }}
                  />
                </div>
              )}
            />
            <Separator className="my-3" />
            <div className="flex gap-3">
              <Field
                name="agent"
                disabled={isSalesAdmin}
                filterFn={(query, term) => query.scope('searchBy', term)}
                onChange={(value) => {
                  if (!value) formConfig.form.setValue('agentCommission', null)
                }}
              />

              {selectedAgentId && (
                <Field
                  name="agentCommission"
                  disabled={isSalesAdmin}
                  inputType="percentage"
                  className="max-w-60"
                />
              )}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <div className="flex gap-3">
              <Field
                name="assignedUser"
                clientSide
                disabled={isSalesAdmin}
                query={UserResource.query.index.scope('sales').scope('active')}
              />
              {!isSalesAdmin && (
                <InfoTile
                  label={'Target'}
                  muted={false}
                  className="mb-2 h-9 self-end rounded-md"
                  icon={
                    <Field
                      name="target"
                      hideLabel={true}
                      className="flex w-28 justify-end self-center"
                    />
                  }
                />
              )}
            </div>
            {isTarget && !isSalesAdmin && <Field name="targetStatus" />}
            {!isSalesAdmin && <Field name="comment" />}
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <div className="flex justify-between gap-3">
              <Field name="seasonType" disabled={isSalesAdmin} />
              <Field
                name="amount"
                disabled={isSalesAdmin}
                inputType="currency"
                className="max-w-60"
              />
            </div>
            <div className="flex justify-between gap-3">
              {
                <Field
                  name="share"
                  disabled={isSalesAdmin}
                  clientSide
                  query={ShareResource.query.index
                    .filter('syndicateStallionId', selectedStallionId, 'eq')
                    .sort('name', 'asc')}
                />
              }
              {selectedStallionId && (
                <InfoTile
                  label={`${year} Stud Fee`}
                  className="mb-2 min-w-60 rounded-md"
                  icon={
                    <Suspense fallback={<Loader className="h-9 animate-spin" />}>
                      <AmountField stallionId={selectedStallionId} year={year} {...formConfig} />
                    </Suspense>
                  }
                />
              )}
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <div className="flex gap-3">
              <Field name="contractStatus" />
              <Field name="contractDate" />
            </div>
            <Field name="contractDeliveryInstructions" />
          </CardContent>
        </Card>
      </Form>
    </div>
  )
}

export { SeasonForm }
