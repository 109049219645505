import { constructPath } from '@/utils'

import { specColumnBuilder } from './column-builder'
import { TableDefinition } from './types'

export const validateColumnDefs = (columns: ColumnDef[]) => {
  const colIds = columns.map((c) => c.colId)

  const validity = columns.map((column) => {
    // TODO: Implement checks for other column types
    if (column.kind === 'compound') {
      return column.columnIds.reduce((acc, colId) => {
        if (!colIds.includes(colId)) {
          console.warn(`Dependent column '${colId}' of compound col '${column.colId} does not exit`)
          return false
        }

        return acc && true
      }, true)
    }

    return true
  })

  return validity
}

export const includeSubTable = (
  currentPath: string,
  subTablePath: string,
  tbl: TableDefinition,
) => {
  const subtbl = tbl.build({ path: constructPath(currentPath, subTablePath) })

  const columns = subtbl.spec.columns
  const columnSpecs = columns.reduce((acc, column) => {
    if (column.kind === 'expander') return acc

    const spec = specColumnBuilder(column)
    // TODO: Make the below work
    // const spec = specColumnBuilder({ ...column, contextPath: subTablePath })
    const localId = constructPath(subTablePath, spec.baseId)

    return { ...acc, [localId]: spec }
  }, {})

  return {
    tbl: subtbl,
    cols: columnSpecs,
  }
}
