import React from 'react'

import { ComponentErrorBoundary } from '@/components/errors/component-error-boundary'
import { type Table } from '@tanstack/react-table'

import { DataTableActions } from './actions'
import { ColumnVisibilityControl } from './columns'
import { FilterControl, TableSearch } from './filters'
import { FilterPresets } from './presets'

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  presets: Record<string, unknown>
}

const defaultPresets = {}

export function DataTableToolbar<TData>({
  table,
  presets = defaultPresets,
}: DataTableToolbarProps<TData>) {
  return (
    <ComponentErrorBoundary>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center justify-between gap-4">
          <FilterPresets table={table} {...presets.filter} />
          <TableSearch table={table} />
          <FilterControl table={table} />
        </div>
        <div className="flex justify-between gap-4">
          <DataTableActions table={table} />
          <ColumnVisibilityControl table={table} />
        </div>
      </div>
    </ComponentErrorBoundary>
  )
}
