import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'

const name = 'FinanceBudget'

const recordNameSingular = 'financeBudget'
const recordNamePlural = 'financeBudgets'
const i18nKey = 'finance::Budget'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'financeBudgets'

const pageHelperKey = 'financeBudgets'

const resourcePathName = 'finance_budget'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  version: {
    kind: 'column',
    name: 'version',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
