import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Entity } from '@/types'

const name = 'Entity'

const recordNameSingular = 'entity'
const recordNamePlural = 'entities'
const i18nKey = 'entity'

const primaryKey = 'id'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'entities'

const pageHelperKey = 'entities'

const resourcePathName = 'entity'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'citext',
  },
  type: {
    kind: 'column',
    name: 'type',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'person',
        },
        {
          value: 1,
          key: 'partnership',
        },
        {
          value: 2,
          key: 'company',
        },
      ],
    },
  },
  externalIdentifier: {
    kind: 'column',
    name: 'externalIdentifier',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<Entity> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
