import { defineTable } from '@/components/data-table/builder'

import { FinanceLocationResource } from './resource'

export const financeLocationTable = defineTable(FinanceLocationResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.locationId(),
    tbl.col.title(),
    tbl.col.parentId(),
    tbl.col.entity(),
    tbl.col.status(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
  ],
}))
