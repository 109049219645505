// JsFromRoutes CacheKey 25a6ba0be6f97d036d2677c1cf9b53c4
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { TrainingReportModel } from '@/models/training_report'

export const trainingReportsApi = {
  index: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'index',
    model: TrainingReportModel,
    parts: ['api', 'training_reports'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'create',
    model: TrainingReportModel,
    parts: ['api', 'training_reports'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'new',
    model: TrainingReportModel,
    parts: ['api', 'training_reports', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'edit',
    model: TrainingReportModel,
    parts: ['api', 'training_reports', { name: 'id', attributeId: 'TrainingReport:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'show',
    model: TrainingReportModel,
    parts: ['api', 'training_reports', { name: 'id', attributeId: 'TrainingReport:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'update',
    model: TrainingReportModel,
    parts: ['api', 'training_reports', { name: 'id', attributeId: 'TrainingReport:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'training_reports',
    actionName: 'destroy',
    model: TrainingReportModel,
    parts: ['api', 'training_reports', { name: 'id', attributeId: 'TrainingReport:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
