import { useTranslation } from 'react-i18next'

import { Resource, ResourceRecord } from '@/libs/resource/types'

const useResourceT = <T extends ResourceRecord>(resource?: Resource<T>, ...prefix: string[]) => {
  const options = resource ? [resource.model.i18nKey, ...prefix] : prefix

  return useTranslation('translation', { keyPrefix: options.join('.') })
}

export { useResourceT }
