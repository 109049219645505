import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { TrainingReportForm } from '@/resources/training_report/form'

const TrainingReportsEdit = () => {
  const { trainingReportsEdit } = useLoaderQueryBuilders()
  const { record: trainingReport } = useResourceQuery(trainingReportsEdit)

  return (
    <Section title={`Edit ${trainingReport.title}`}>
      <TrainingReportForm initialValues={trainingReport} />
    </Section>
  )
}

export default TrainingReportsEdit
