import React from 'react'

import { PageHeader } from '@/components/header'

const OperationsIndex = () => {
  return (
    <>
      <PageHeader title="Operations" />
    </>
  )
}

export default OperationsIndex
