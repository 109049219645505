import React from 'react'

import { PageHeader } from '@/components/header'

const SettingsIndex = () => {
  return (
    <>
      <PageHeader title="Settings" />
    </>
  )
}

export default SettingsIndex
