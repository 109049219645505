import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { StudFeeForm } from '@/resources/stud_fee/form'

const StudFeesEdit = () => {
  const { studFeesEdit } = useLoaderQueryBuilders()
  const { record: studFee } = useResourceQuery(studFeesEdit)

  return (
    <Section title={`Edit ${studFee.title}`}>
      <StudFeeForm initialValues={studFee} />
    </Section>
  )
}

export default StudFeesEdit
