import { Action } from '@/components/actions'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { noopQuery } from '@/libs/query/builder'
import { CoverResource } from '@/resources/cover'
import { HorseResource } from '@/resources/horse'
import { coversRoutes } from '@/routes'
import { formatDate } from '@/utils'

const CoverInline = ({ mareId, year, dateFormat = 'MMM do', showActions = true }) => {
  const { records: covers } = useResourceQuery(
    mareId
      ? CoverResource.query.index
          .filter('mareId', mareId)
          .filter('yearFilter', year, 'scope')
          .sort('coverDate', 'desc')
          .pageSize(1)
      : noopQuery,
  )

  const [cover] = covers

  const { record: stallion } = useResourceQuery(
    cover?.stallionId ? HorseResource.query.show.withOptions({ id: cover.stallionId }) : noopQuery,
  )

  if (!cover) {
    return (
      <div className="flex w-full flex-row gap-3">
        <Text as="span" size="md">
          {year}
        </Text>
        <Text as="span" size="md" muted>
          No cover found
        </Text>
        {showActions ? (
          <Action
            name="new"
            label="New Cover"
            iconOnly
            variant="primary"
            size="sm"
            className="ml-auto"
            route={coversRoutes.new.withOptions({ query: { initialValues: { mareId } } })}
          />
        ) : null}
      </div>
    )
  }

  return (
    <div className="flex w-full flex-row justify-between gap-3">
      <Text as="span" size="md">
        {year}
      </Text>
      <Text as="span" size="md" className="w-1/3">
        {stallion.name}
      </Text>
      <Text as="span" size="md" className="w-1/3">
        {formatDate(cover.coverDate, dateFormat)}
      </Text>
      <div className="flex w-fit flex-row items-center gap-1">
        {showActions ? (
          <Action
            name="edit"
            route={coversRoutes.edit.withOptions({ coverId: cover.id })}
            iconOnly
            variant="primary"
            size="sm"
            className="ml-auto"
          />
        ) : null}
        {showActions ? (
          <Action
            name="new"
            iconOnly
            variant="primary"
            size="sm"
            className="ml-auto"
            route={coversRoutes.new.withOptions({ query: { initialValues: { mareId } } })}
          />
        ) : null}
      </div>
    </div>
  )
}

export { CoverInline }
