import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { BloodstockEntity } from '@/types'

const name = 'BloodstockEntity'

const recordNameSingular = 'bloodstockEntity'
const recordNamePlural = 'bloodstockEntities'
const i18nKey = 'bloodstockEntity'

const primaryKey = 'bloodstockEntityId'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'bloodstockEntities'

const pageHelperKey = 'bloodstockEntities'

const resourcePathName = 'bloodstock_entity'

const attributes: Record<string, AttributeSpec> = {
  bloodstockEntityId: {
    kind: 'column',
    name: 'bloodstockEntityId',
    type: 'string',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  resourceType: {
    kind: 'column',
    name: 'resourceType',
    type: 'text',
  },
  resourceId: {
    kind: 'column',
    name: 'resourceId',
    type: 'integer',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'text',
  },
  nameAge: {
    kind: 'column',
    name: 'nameAge',
    type: 'text',
  },
  nameAgeSex: {
    kind: 'column',
    name: 'nameAgeSex',
    type: 'text',
  },
  nameAgeSexSire: {
    kind: 'column',
    name: 'nameAgeSexSire',
    type: 'text',
  },
  yobSexSire: {
    kind: 'column',
    name: 'yobSexSire',
    type: 'text',
  },
  horseString: {
    kind: 'column',
    name: 'horseString',
    type: 'text',
  },
  horseStringNoDam: {
    kind: 'column',
    name: 'horseStringNoDam',
    type: 'text',
  },
  sireName: {
    kind: 'column',
    name: 'sireName',
    type: 'text',
  },
  sireYob: {
    kind: 'column',
    name: 'sireYob',
    type: 'integer',
  },
  label: {
    kind: 'column',
    name: 'label',
    type: 'text',
  },
  labelTooltip: {
    kind: 'column',
    name: 'labelTooltip',
    type: 'text',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  male: {
    kind: 'column',
    name: 'male',
    type: 'boolean',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  damBloodstockEntityId: {
    kind: 'column',
    name: 'damBloodstockEntityId',
    type: 'string',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  esn: {
    kind: 'column',
    name: 'esn',
    type: 'float',
  },
  numKyStallionsPass: {
    kind: 'column',
    name: 'numKyStallionsPass',
    type: 'integer',
  },
  numKyStallionsThreePlus: {
    kind: 'column',
    name: 'numKyStallionsThreePlus',
    type: 'integer',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  priority: {
    kind: 'column',
    name: 'priority',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<BloodstockEntity> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
