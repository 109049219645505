import { createRoutes } from '@/libs/router/route'

export const BloodstockRoutes = createRoutes({
  routes: [
    {
      path: 'bloodstock',
      children: [
        {
          index: true,
          page: 'bloodstock/index',
        },
        {
          path: 'crops',
          page: 'bloodstock/crops/index',
        },
        {
          path: 'racehorses',
          page: 'bloodstock/racehorses/index',
        },
        {
          path: 'broodmares',
          page: 'bloodstock/broodmares/index',
        },
        {
          path: 'stallions',
          page: 'bloodstock/stallions/index',
        },
      ],
    },
  ],
})
