import React from 'react'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'

export const ellipsisFormatter =
  ({ numChars = 12 } = {}) =>
  ({ getValue }) => {
    const value = getValue() ?? ''

    if (value.length <= numChars) return value

    return (
      <Tooltip>
        <TooltipTrigger>{value.substring(0, numChars)}...</TooltipTrigger>
        <TooltipContent className="drop-shadow-xl">{value}</TooltipContent>
      </Tooltip>
    )
  }
