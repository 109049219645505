import React, { Suspense } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'

import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
} from '@/components/ui/sidebar'
import { Text } from '@/components/ui/text'

import { PanelItem } from './item'
import { PanelConfig } from './panel-types'

interface PanelContentProps {
  title?: string
  config?: PanelConfig
  configFn?: (params, data) => PanelConfig
  children?: React.ReactNode
}

const PanelContent = ({ title, config, configFn, children }: PanelContentProps) => {
  const params = useParams()
  const data = useLoaderData()
  const panelConfig = config ?? configFn?.(params, data) ?? []

  return (
    <>
      {title ? (
        <SidebarHeader className="flex justify-between border-b">
          <Text as="h4" className="flex items-center overflow-hidden">
            <span className="truncate">{title}</span>
          </Text>
        </SidebarHeader>
      ) : null}
      <Suspense>
        <SidebarContent>
          {panelConfig.map((group, i) => (
            <SidebarGroup key={i}>
              {group.label && <SidebarGroupLabel>{group.label}</SidebarGroupLabel>}
              {group.items && (
                <SidebarMenu>
                  {group.items.map((item) => (
                    <PanelItem
                      key={item.label}
                      route={item.route}
                      tooltip={item.tooltip ?? item.label}
                      {...item}
                    />
                  ))}
                </SidebarMenu>
              )}
              {group.groups && group.groups.length > 0 ? (
                <PanelContent config={group.groups} />
              ) : null}
            </SidebarGroup>
          ))}
          {children}
        </SidebarContent>
      </Suspense>
    </>
  )
}

export { PanelContent }
