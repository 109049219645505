import { createRoutes } from '@/libs/router/route'
import { SeasonRoutes } from '@/resources/season/routes'

export const BooksRoutes = createRoutes({
  routes: [
    {
      path: 'books',
      children: [
        {
          index: true,
          page: 'books/index',
        },
        {
          path: ':bookYear',
          handle: {
            routePart: [{ name: 'bookYear', attributeId: 'Season:year' }],
          },
          children: [
            {
              index: true,
              page: 'books/show',
            },
            {
              path: 'targets',
              page: 'books/targets',
            },
            SeasonRoutes,
          ],
        },
      ],
    },
  ],
})
