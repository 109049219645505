// JsFromRoutes CacheKey 058feadced7be6d31f4205c43ce1bd08
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const projectedPurchasesApi = {
  index: definePathHelper('get', '/api/projected_purchases'),
  create: definePathHelper('post', '/api/projected_purchases'),
  new: definePathHelper('get', '/api/projected_purchases/new'),
  edit: definePathHelper('get', '/api/projected_purchases/:id/edit'),
  show: definePathHelper('get', '/api/projected_purchases/:id'),
  update: definePathHelper('patch', '/api/projected_purchases/:id'),
  destroy: definePathHelper('delete', '/api/projected_purchases/:id'),
}
