import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { AppraisalForm } from '@/resources/appraisal/form'

const AppraisalsNew = () => {
  const { appraisalsNew } = useLoaderQueryBuilders()
  const { record: appraisal } = useResourceQuery(appraisalsNew)

  return (
    <Section title="New Appraisal">
      <AppraisalForm initialValues={appraisal} />
    </Section>
  )
}

export default AppraisalsNew
