import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FederalReserveCurrencyExchangeRate } from '@/types'

const name = 'FederalReserveCurrencyExchangeRate'

const recordNameSingular = 'federalReserveCurrencyExchangeRate'
const recordNamePlural = 'federalReserveCurrencyExchangeRates'
const i18nKey = 'federalReserve::CurrencyExchangeRate'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'federalReserveCurrencyExchangeRates'

const pageHelperKey = 'federalReserveCurrencyExchangeRates'

const resourcePathName = 'federal_reserve_currency_exchange_rate'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  sourceCurrency: {
    kind: 'column',
    name: 'sourceCurrency',
    type: 'string',
  },
  destCurrency: {
    kind: 'column',
    name: 'destCurrency',
    type: 'string',
  },
  rate: {
    kind: 'column',
    name: 'rate',
    type: 'float',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<FederalReserveCurrencyExchangeRate> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
