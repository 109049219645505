import * as React from 'react'

import type { DomainConfig, FilterConfig } from '@/components/data-table/types'

import {
  DataTableFilters,
  type DataTableFilterConfig,
} from '@/components/data-table/filters/configs'
import { FilterPrimitive, OperatorDef, buildApplyFn, buildOperator } from '@/libs/filter/operators'
import { type AnyData, type FilterFn, type Row } from '@tanstack/react-table'

interface ColumnFilterValue {
  operatorDef: OperatorDef
  value: FilterPrimitive
}

const buildFilterFn = <TData extends AnyData>(operatorDef: OperatorDef): FilterFn<TData> => {
  if (operatorDef == null) {
    return
  }

  const operator = buildOperator(operatorDef)

  const filterFn = (row: Row<TData>, columnId: string, filterValue: ColumnFilterValue) => {
    if (filterValue == null) {
      return
    }

    const filterApply = buildApplyFn(operator)

    const rowValue = row.getValue(columnId)

    const extractRowValue = (operatorDef: OperatorDef) => {
      if (typeof operatorDef === 'string') {
        return rowValue
      }

      return Object.keys(operatorDef).reduce(
        (acc, key) => ({ ...acc, [key]: extractRowValue(operatorDef[key]) }),
        {},
      )
    }

    const formattedRowValue = extractRowValue(operatorDef)

    return filterApply(filterValue.value)(formattedRowValue)
  }

  return filterFn
}

const buildFilterProps = (domain?: DomainConfig) => {
  if (domain == null) {
    return {}
  }

  return Object.assign({ options: domain })
}

export interface FilterMeta<TData extends AnyData> {
  filterFn: FilterFn<TData>
  operatorDef: OperatorDef
  Component: React.ReactNode
  initialChip: boolean
  props: unknown
}

const buildFilterMeta = <TData extends AnyData>(
  filterConfig?: FilterConfig,
  domain?: DomainConfig,
): FilterMeta<TData> | undefined => {
  if (filterConfig == null) return

  const defaultFilter = DataTableFilters[filterConfig.key]
  if (defaultFilter == null) return

  const {
    operatorDef = defaultFilter.operatorDef,
    Component = defaultFilter.Component,
    initialChip = false,
  } = filterConfig

  return {
    filterFn: buildFilterFn(operatorDef),
    Component,
    operatorDef,
    initialChip,
    props: buildFilterProps(domain),
  }
}

export { buildFilterFn, buildFilterMeta, DataTableFilters, type DataTableFilterConfig }
