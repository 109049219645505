import { Outlet } from 'react-router-dom'

import { Breadcrumbs } from '@/components/breadcrumbs'
import { DefaultErrorBoundary } from '@/components/errors'
import { Nav } from '@/components/nav/nav'
import { PageActions } from '@/components/nav/page-actions'
import { PageSuspense } from '@/components/suspense/default'
import { Separator } from '@/components/ui/separator'
import { SidebarInset, SidebarProvider, SidebarTrigger } from '@/components/ui/sidebar'
import { RequireAuth } from '@/contexts/auth'
import { PageProvider } from '@/contexts/page'
import { useBreakpoints } from '@/hooks'

import { RootLayout } from './root'

export const BaseLayout = () => {
  const { is2XLarge } = useBreakpoints()

  return (
    <RootLayout removeOutlet>
      <RequireAuth>
        <SidebarProvider
          style={
            {
              '--sidebar-width': is2XLarge ? '350px' : '300px',
            } as React.CSSProperties
          }
          vaul-drawer-wrapper=""
        >
          <Nav />
          <SidebarInset className="overflow-y-auto overflow-x-hidden">
            <header className="sticky top-0 flex shrink-0 items-center gap-2 bg-background py-4">
              <SidebarTrigger />
              <Separator orientation="vertical" className="mr-2 h-4" />
              <Breadcrumbs className="ml-4 mt-0.5" />
            </header>
            <main id="main" className="flex flex-1 flex-col p-2 pt-0 sm:p-8 sm:pt-0">
              {/* content */}
              <DefaultErrorBoundary>
                <PageSuspense>
                  <PageProvider>
                    <Outlet />
                  </PageProvider>
                </PageSuspense>
              </DefaultErrorBoundary>
            </main>
            <PageActions />
          </SidebarInset>
        </SidebarProvider>
      </RequireAuth>
    </RootLayout>
  )
}

export default BaseLayout
