import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'

import { AlertTriangle, X } from '@/components/icon'
import { Button } from '@/components/ui/button'

export const DefaultError = ({ error }) => {
  const status = error.response?.status
  if (status === 401) return <Navigate to="/login" />
  return (
    <div
      role="alert"
      className="relative flex size-full flex-col items-center justify-center gap-16 p-8"
    >
      <Button size="lg" className="absolute right-8 top-8" iconLeft={<X />} iconOnly />
      <AlertTriangle className="h-32" />
      <div className="flex items-center gap-2 text-lg">
        <p>Error: </p>
        <pre style={{ color: 'red' }}>{error.message}</pre>
      </div>
      <pre className="-mt-16 w-1/2 overflow-x-auto overflow-y-clip bg-white py-4 text-red-800">
        {error.stack}
      </pre>
    </div>
  )
}

export const DefaultErrorBoundary = (props) => (
  <ErrorBoundary FallbackComponent={DefaultError} {...props} />
)
