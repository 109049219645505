import * as React from 'react'

import { iconSizes } from '@/components/icon'
import { cva, type VariantProps } from '@/utils'
import { Slot, Slottable } from '@radix-ui/react-slot'

const buttonVariants = cva({
  base: 'inline-flex items-center gap-2 justify-center whitespace-nowrap rounded font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-auto disabled:cursor-not-allowed',
  variants: {
    size: {
      xs: 'h-5 px-1 text-xs',
      sm: 'h-7 px-2.5 text-xs',
      md: 'h-9 px-3.5 text-base',
      lg: 'h-11 px-[18px] text-lg',
      xl: 'h-14 px-6 text-xl',
      '2xl': 'h-16 px-7 text-2xl',
      '3xl': 'h-20 px-8 text-3xl',
    },
    variant: {
      primary: 'bg-primary text-primary-foreground hover:bg-primary/80',
      destructive:
        'border border-destructive text-destructive hover:text-plain hover:bg-destructive',
      outline: 'border border-border hover:bg-accent',
      secondary: 'bg-secondary text-secondary-foreground hover:bg-accent',
      ghost: 'hover:bg-accent',
      link: 'text-primary underline-offset-4 hover:underline p-0 size-auto',
      input:
        'w-full rounded-md border border-input px-2 py-1.5 text-sm font-normal focus-within:border-input-focus aria-[expanded=true]:border-input-focus',
      inline: 'p-0 size-auto',
    },
    iconOnly: {
      true: 'rounded-full p-0 justify-center',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
  compoundVariants: [
    {
      size: 'xs',
      iconOnly: true,
      class: 'size-4',
    },
    {
      size: 'sm',
      iconOnly: true,
      class: 'size-5',
    },
    {
      size: 'md',
      iconOnly: true,
      class: 'size-7',
    },
    {
      size: 'lg',
      iconOnly: true,
      class: 'size-9',
    },
    {
      size: 'xl',
      iconOnly: true,
      class: 'size-11',
    },
    {
      size: '2xl',
      iconOnly: true,
      class: 'size-13',
    },
    {
      size: '3xl',
      iconOnly: true,
      class: 'size-15',
    },
  ],
})

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    iconOnly?: boolean
  }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size = 'md', iconLeft, iconRight, asChild = false, ...props }: ButtonProps,
    ref: HTMLButtonElement,
  ) => {
    const Comp = asChild ? Slot : 'button'

    const iconSize = iconSizes[size]
    const iconProps = { style: { width: iconSize, height: iconSize } }

    const leftIcon = React.isValidElement(iconLeft) ? React.cloneElement(iconLeft, iconProps) : null
    const rightIcon = React.isValidElement(iconRight)
      ? React.cloneElement(iconRight, iconProps)
      : null

    const {
      iconOnly = React.Children.count(props.children) === 0 && (!!leftIcon || !!rightIcon),
      ...rest
    } = props

    return (
      <Comp ref={ref} {...rest} className={buttonVariants({ size, iconOnly, ...rest })}>
        {iconLeft && leftIcon}
        <Slottable>{props.children}</Slottable>
        {iconRight && rightIcon}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
