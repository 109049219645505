import { defineTable } from '@/components/data-table/builder'
import { horseFormatter } from '@/components/data-table/formatters/horse_formatter'
import { nameAgeSex, nameYobAbbr, sireDamString } from '@/resources/horse/utils'
import { currentYear } from '@/utils'

import { DBTHorseResource } from './resource'

export const dbtHorseTable = defineTable(DBTHorseResource, {}, (tbl) => ({
  columns: [
    tbl.col.horseId({
      isVisible: false,
      enableHiding: false,
    }),

    tbl.calculatedCol('nameYob', (row) => nameYobAbbr(row), { label: 'Horse' }),

    tbl.calculatedCol('age', (row) => currentYear() - row?.yob, {
      label: 'Age',
    }),

    tbl.calculatedCol('nameAgeSex', (row) => nameAgeSex(row), {
      label: 'Horse',
      cell: (props) => {
        const horse = props.row.original
        return horseFormatter({
          id: props.row.getValue('horseId'),
          nameLabel: props.getValue(),
          ...horse,
        })
      },
    }),

    tbl.calculatedCol('sire', (row) => row?.sireName, {
      label: 'Sire',
      cell: (props) =>
        horseFormatter({
          id: props.row.getValue('stallionId'),
          name: props.getValue(),
          actions: false,
        }),
    }),

    tbl.calculatedCol('dam', (row) => row?.damName, {
      label: 'Dam',
      cell: (props) =>
        horseFormatter({
          id: props.row.getValue('damId'),
          name: props.getValue(),
          actions: false,
        }),
    }),

    tbl.calculatedCol('damSire', (_row) => '-', {
      label: 'Damsire',
      cell: (props) =>
        horseFormatter({
          id: props.row.getValue('damSireId'),
          name: props.getValue(),
          actions: false,
        }),
    }),

    tbl.compoundCol('damOverDamSire', [tbl.colId('dam'), tbl.colId('damSire')]),

    tbl.calculatedCol('sireWithDam', (row) => sireDamString(row), {
      label: 'Sire / Dam',
    }),

    tbl.compoundCol('nameOverSireWithDam', [tbl.colId('nameAgeSex'), tbl.colId('sireWithDam')]),

    tbl.col.name(),
    tbl.col.sex(),
    tbl.col.yob(),
    tbl.col.color(),
    tbl.col.horseType(),

    tbl.col.damName(),
    tbl.col.damId(),

    // tbl.col.damSireName(),
    tbl.col.damSireId(),

    tbl.col.sireName(),
    tbl.col.sireId(),
  ],
}))
