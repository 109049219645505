import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { OBSSale } from '@/types'

const name = 'OBSSale'

const recordNameSingular = 'obsSale'
const recordNamePlural = 'obsSales'
const i18nKey = 'obs::Sale'

const primaryKey = 'id'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'obsSales'

const pageHelperKey = 'obsSales'

const resourcePathName = 'obs_sale'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  startDate: {
    kind: 'column',
    name: 'startDate',
    type: 'date',
  },
  endDate: {
    kind: 'column',
    name: 'endDate',
    type: 'date',
  },
  url: {
    kind: 'column',
    name: 'url',
    type: 'string',
  },
  resultsUrl: {
    kind: 'column',
    name: 'resultsUrl',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  obsId: {
    kind: 'column',
    name: 'obsId',
    type: 'integer',
  },
  saleCode: {
    kind: 'column',
    name: 'saleCode',
    type: 'string',
  },
  saleShortName: {
    kind: 'column',
    name: 'saleShortName',
    type: 'string',
  },
  previousYearOBSSaleId: {
    kind: 'column',
    name: 'previousYearOBSSaleId',
    type: 'integer',
  },
  previousOBSSaleId: {
    kind: 'column',
    name: 'previousOBSSaleId',
    type: 'integer',
  },
  nextOBSSaleId: {
    kind: 'column',
    name: 'nextOBSSaleId',
    type: 'integer',
  },
  saleCategory: {
    kind: 'column',
    name: 'saleCategory',
    type: 'string',
  },
  isDigital: {
    kind: 'column',
    name: 'isDigital',
    type: 'boolean',
  },
  displayToPublicDate: {
    kind: 'column',
    name: 'displayToPublicDate',
    type: 'datetime',
  },
  resultsDisplayToPublicDate: {
    kind: 'column',
    name: 'resultsDisplayToPublicDate',
    type: 'datetime',
  },
  jcDataCheckedDate: {
    kind: 'column',
    name: 'jcDataCheckedDate',
    type: 'datetime',
  },
  jcDataUpdatedDate: {
    kind: 'column',
    name: 'jcDataUpdatedDate',
    type: 'datetime',
  },
  mediaFilesTempPath: {
    kind: 'column',
    name: 'mediaFilesTempPath',
    type: 'string',
  },
  obsCreatedDate: {
    kind: 'column',
    name: 'obsCreatedDate',
    type: 'datetime',
  },
  obsUpdatedDate: {
    kind: 'column',
    name: 'obsUpdatedDate',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<OBSSale> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
