// JsFromRoutes CacheKey 21d60d6fc2e508d55eecaf9811cbc3a0
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { PregnancyCheckModel } from '@/models/pregnancy_check'

export const pregnancyChecksApi = {
  index: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'index',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'create',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'new',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'edit',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks', { name: 'id', attributeId: 'PregnancyCheck:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'show',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks', { name: 'id', attributeId: 'PregnancyCheck:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'update',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks', { name: 'id', attributeId: 'PregnancyCheck:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'pregnancy_checks',
    actionName: 'destroy',
    model: PregnancyCheckModel,
    parts: ['api', 'pregnancy_checks', { name: 'id', attributeId: 'PregnancyCheck:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}
