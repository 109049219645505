import React, { Suspense, useRef, useState } from 'react'

import { Camera, FilePen, Menu, Plus, Upload } from '@/components/icon'
import { NewAction } from '@/components/resource'
import { Button } from '@/components/ui/button'
import { Drawer, DrawerContent } from '@/components/ui/drawer'
import { Input } from '@/components/ui/input'
import { PrimaryKeyValue } from '@/libs/resource/types'
import { ConformationNoteResource } from '@/resources/conformation_note'
import { FiveCrossAction, PedigreeAction, ThoromanagerAction } from '@/resources/horse'
import { ImageForm } from '@/resources/image'
import { VideoForm } from '@/resources/video'
import { cn } from '@/utils'
import { useDirectUpload } from '@docflow/react-activestorage-provider'

export const HorseActions = ({ id }: { id: PrimaryKeyValue }) => {
  const [context, setContext] = useState(null)
  const [signedId, setSignedId] = useState(null)
  const [open, setOpen] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [showPages, setShowPages] = useState(false)

  const inputFileRef = useRef(null)
  const uploadFileRef = useRef(null)

  const { handleUpload } = useDirectUpload({
    onSuccess: (signedIds) => {
      setSignedId(signedIds[0])
      setOpen(true)
    },
  })

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (!file) return
    setContext(file.type.includes('video') ? 'video' : 'image')
    await handleUpload(event.target.files)
  }

  const toggleShowActions = () => {
    setShowActions((prev) => !prev)
    setShowPages(false)
  }

  const toggleShowPages = () => {
    setShowPages((prev) => !prev)
    setShowActions(false)
  }

  return (
    <div className="fixed bottom-24 right-5 flex flex-col items-end gap-4">
      <Input
        ref={inputFileRef}
        className="hidden"
        id="image"
        accept="image/*;video/*"
        type="file"
        placeholder="Image"
        capture="environment"
        required
        onChange={handleFileChange}
      />
      <Input
        ref={uploadFileRef}
        className="hidden"
        id="image"
        accept="image/*;video/*"
        type="file"
        placeholder="Image"
        required
        onChange={handleFileChange}
      />
      <Suspense>
        <Drawer open={open} onOpenChange={setOpen}>
          <DrawerContent>
            {context === 'image' ? (
              <ImageForm
                initialValues={{ file: signedId }}
                onSuccess={() => {
                  setOpen(false)
                }}
                onCancel={() => {
                  setOpen(false)
                }}
              />
            ) : (
              <VideoForm
                initialValues={{ file: signedId }}
                onSuccess={() => {
                  setOpen(false)
                }}
                onCancel={() => {
                  setOpen(false)
                }}
              />
            )}
          </DrawerContent>
        </Drawer>
      </Suspense>

      <ThoromanagerAction
        horseId={id}
        variant="primary"
        className={cn('hidden', showPages && 'inline-flex scale-100')}
        size="3xl"
        format="iconOnly"
        iconOnly
      />
      <FiveCrossAction
        horseId={id}
        variant="primary"
        className={cn('hidden pb-1', showPages && 'inline-flex scale-100')}
        size="3xl"
        format="iconOnly"
        iconOnly
      />
      <PedigreeAction
        horseId={id}
        variant="primary"
        className={cn('hidden', showPages && 'inline-flex scale-100')}
        size="3xl"
        format="iconOnly"
        iconOnly
      />
      <Button
        className={cn(
          'transition-all duration-100 ease-linear hover:bg-black 2xl:hidden',
          showPages && 'm-3 size-8 rotate-45 p-1 text-red-500',
          showActions && 'hidden',
        )}
        size="3xl"
        variant="primary"
        iconLeft={showPages ? <Plus /> : <Menu />}
        iconOnly
        onClick={toggleShowPages}
      />
      <NewAction
        resource={ConformationNoteResource}
        className={cn(
          'hidden scale-0 transition-all duration-100 ease-in-out hover:bg-black 2xl:hidden',
          showActions && 'inline-flex scale-100',
        )}
        size="3xl"
        variant="primary"
        iconOnly
        iconLeft={<FilePen />}
      />
      <Button
        className={cn(
          'hidden scale-0 transition-all duration-100 ease-in-out hover:bg-black 2xl:hidden',
          showActions && 'inline-flex scale-100',
        )}
        size="3xl"
        variant="primary"
        iconLeft={<Camera />}
        iconOnly
        onClick={() => inputFileRef.current.click()}
      />
      <Button
        className={cn(
          'hidden scale-0 transition-all duration-100 ease-in-out hover:bg-black 2xl:hidden',
          showActions && 'inline-flex scale-100',
        )}
        size="3xl"
        variant="primary"
        iconLeft={<Upload />}
        iconOnly
        onClick={() => uploadFileRef.current.click()}
      />
      <Button
        className={cn(
          'transition-all duration-100 ease-linear hover:bg-black 2xl:hidden',
          showActions && 'm-3 size-8 rotate-45 p-1 text-red-500',
        )}
        size="3xl"
        variant="primary"
        iconLeft={<Plus />}
        iconOnly
        onClick={toggleShowActions}
      />
    </div>
  )
}
