import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceCustomer } from '@/types'

const name = 'FinanceCustomer'

const recordNameSingular = 'financeCustomer'
const recordNamePlural = 'financeCustomers'
const i18nKey = 'finance::Customer'

const primaryKey = 'customerId'
const searchKey = 'name'

const parents: Scope[] = []

const apiHelperKey = 'financeCustomers'

const pageHelperKey = 'financeCustomers'

const resourcePathName = 'finance_customer'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  customerId: {
    kind: 'column',
    name: 'customerId',
    type: 'string',
  },
  name: {
    kind: 'column',
    name: 'name',
    type: 'string',
  },
  entity: {
    kind: 'column',
    name: 'entity',
    type: 'string',
  },
  parentId: {
    kind: 'column',
    name: 'parentId',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<FinanceCustomer> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
