import { Section } from '@/components/section'
import { usePageContext } from '@/contexts/page'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { RacehorseCommentForm } from '@/resources/racehorse_comment'
import { useQueryClient } from '@tanstack/react-query'

const RacehorseCommentsEdit = () => {
  const { racehorseCommentsEdit } = useLoaderQueryBuilders()
  const { record: racehorseComment } = useResourceQuery(racehorseCommentsEdit)

  const { navigateBack } = usePageContext()

  const queryClient = useQueryClient()
  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['dbtRacehorseDetails'],
      active: true,
    })

    navigateBack()
  }

  return (
    <Section title={`Edit ${racehorseComment.title}`}>
      <RacehorseCommentForm initialValues={racehorseComment} onSuccess={handleSuccess} />
    </Section>
  )
}

export default RacehorseCommentsEdit
