import { AvailableChartColorsKeys, getColorClassName } from '@/utils/chart_colors'

export const sumNumericArray = (arr: number[]) => arr.reduce((prefixSum, num) => prefixSum + num, 0)

export const getMarkerBgColor = (
  marker: number | undefined,
  values: number[],
  colors: AvailableChartColorsKeys[],
): string => {
  if (marker === undefined) return ''

  if (marker === 0) {
    for (let index = values.length - 1; index >= 0; index--) {
      if (values[index] > 0) {
        return getColorClassName(colors[index], 'bg')
      }
    }
  }

  let prefixSum = 0
  for (let index = values.length - 1; index >= 0; index--) {
    prefixSum += values[index]
    if (prefixSum >= marker) {
      return getColorClassName(colors[index], 'bg')
    }
  }

  return getColorClassName(colors[0], 'bg')
}

export const formatNumber = (num: number): string => {
  if (Number.isInteger(num)) {
    return num.toString()
  }
  return num.toFixed(1)
}
