import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ConformationNote } from '@/types'

const name = 'ConformationNote'

const recordNameSingular = 'conformationNote'
const recordNamePlural = 'conformationNotes'
const i18nKey = 'conformationNote'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'conformationNotes'

const pageHelperKey = 'conformationNotes'

const resourcePathName = 'conformation_note'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  inspector: {
    kind: 'column',
    name: 'inspector',
    type: 'string',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  ageMonths: {
    kind: 'column',
    name: 'ageMonths',
    type: 'integer',
  },
  ageDays: {
    kind: 'column',
    name: 'ageDays',
    type: 'integer',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<ConformationNote> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
