import React from 'react'

import { PageHeader } from '@/components/header'
import { Link } from '@/components/link'
import { booksRoutes } from '@/routes'
import { defaultMatingYear } from '@/utils'

const BooksIndex = () => {
  const year = defaultMatingYear()

  return (
    <>
      <PageHeader title="Books" />
      <div className="flex flex-row gap-4">
        <Link variant="primary" route={booksRoutes.seasons.withOptions({ bookYear: year })}>
          {`${year.toString()} Seasons`}
        </Link>
        <Link variant="primary" route={booksRoutes.targets.withOptions({ bookYear: year })}>
          {`${year.toString()} Targets`}
        </Link>
      </div>
    </>
  )
}

export default BooksIndex
