import React from 'react'

import { Form, Field, UseFormProps, useForm, NestedRecord } from '@/components/form'
import Icon from '@/components/icon'
import { Card, CardContent } from '@/components/ui/card'
import { PregnancyCheckModel } from '@/models/pregnancy_check'
import { CoverSchema } from '@/schemas/cover'
import { Cover } from '@/types'
import { format, addDays, min } from 'date-fns'
import { z } from 'zod'

import { HorseResource, HorseSuggestion } from '../horse'
import { CoverResource } from './resource'

type CoverFormProps = Omit<UseFormProps<Cover>, 'resource'>

const CoverForm = ({ zodSchema = CoverSchema, showCheckedAt = true, ...props }: CoverFormProps) => {
  const formConfig = useForm<Cover>({
    resource: CoverResource,
    zodSchema: zodSchema.extend({
      pregnancyChecks: z.array(
        z.object({
          id: z.optional(z.coerce.number().int()),
          _destroy: z.optional(z.boolean()),
          checkedAt: z.optional(z.coerce.date()),
          status: z.enum(['pregnant', 'barren', 'slipped', 'dead_foal', 'foaled']).optional(),
          sex: z.enum(['male', 'female']).optional().nullable(),
        }),
      ),
    }),
    ...props,
  })

  const selectedCoverDate = formConfig.form.watch('coverDate')

  return (
    <div className="sm:min-w-96">
      <Form {...formConfig}>
        <Card>
          <CardContent>
            <Field
              name="stallion"
              query={HorseResource.query.index.scope('male', true)}
              renderItem={({ suggestion, isSelected }) => (
                <div className="flex w-full gap-0.5">
                  <div className="w-4 py-1">
                    {isSelected ? <Icon name="Check" className="self-center" /> : null}
                  </div>
                  <HorseSuggestion horse={suggestion} className="w-full" hideImage hideType />
                </div>
              )}
            />
            <Field name="mare" />
            <Field name="coverDate" />
            <NestedRecord
              name="pregnancyChecks"
              label="Add Pregnancy Check"
              defaultValues={{
                checkedAt: selectedCoverDate
                  ? format(min([addDays(selectedCoverDate, 345), new Date()]), 'yyyy-MM-dd')
                  : null,
              }}
              renderRecord={(field, index) => (
                <div key={field.id} className="flex w-full justify-between gap-3">
                  {showCheckedAt ? (
                    <Field
                      key={`pregnancyChecksDate-${index}`}
                      name={`pregnancyChecks.${index}.checkedAt`}
                      attribute={{
                        ...PregnancyCheckModel.attributes.checkedAt,
                        type: 'date',
                      }}
                    />
                  ) : null}
                  <Field
                    key={`pregnancyChecksStatus-${index}`}
                    name={`pregnancyChecks.${index}.status`}
                    attribute={PregnancyCheckModel.attributes.status}
                  />
                  <Field
                    key={`pregnancyChecksSex-${index}`}
                    name={`pregnancyChecks.${index}.sex`}
                    attribute={PregnancyCheckModel.attributes.sex}
                  />
                </div>
              )}
            />
          </CardContent>
        </Card>
      </Form>
    </div>
  )
}

export { CoverForm }
