import { EmptySummaryContent, SummaryCard } from '@/components/cards'
import { DataTile } from '@/components/data_tile'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { DNASet } from '@/types'

import { DNASetResource } from './resource'

export const DistancePanelContent = ({ distancePanel }: { distancePanel: DNASet }) => {
  return (
    <div className="flex gap-2">
      <DataTile className="flex-1 bg-purple">
        <span className="text-4xl">{distancePanel.genotype[0]}</span>
      </DataTile>
      <DataTile className="flex-1">
        <span className="text-4xl">{distancePanel.genotype[1]}</span>
      </DataTile>
      <DataTile className="flex-1">
        <span className="text-4xl">{distancePanel.genotype[2]}</span>
      </DataTile>
    </div>
  )
}

export const DistancePanelSummaryContent = ({ dnaSets }) => {
  return <DistancePanelContent distancePanel={dnaSets[0]} />
}

export const DistancePanelSummaryCard = <T extends ResourceRecord>({
  className,
  query,
}: {
  className?: string
  query: QueryBuilder<T>
}) => {
  const { records: dnaSets, error, isPending } = useResourceQuery(query)
  const pluralName = 'DNA Sets'

  return (
    <SummaryCard
      actions={null}
      className={className}
      isPending={isPending}
      pluralName={pluralName}
      queryError={error}
      titleLabel="Distance Panel"
      titleRoute={DNASetResource.routes.index}
    >
      {dnaSets.length === 0 ? (
        <EmptySummaryContent pluralName={pluralName} />
      ) : (
        <DistancePanelSummaryContent dnaSets={dnaSets} />
      )}
    </SummaryCard>
  )
}
