import { useMemo, SyntheticEvent } from 'react'
import { useForm as useRHForm, SubmitHandler } from 'react-hook-form'

import { usePageContext } from '@/contexts/page'
import { useResource } from '@/hooks'
import { Resource, ResourceRecord } from '@/libs/resource/types'
import { expand, filterObject, isObject } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { TError } from '@tanstack/react-query'
import { toast } from 'sonner'
import { z } from 'zod'

export interface UseFormProps<T extends ResourceRecord> {
  resource: Resource<T>
  initialValues?: Partial<T>
  zodSchema: z.ZodType
  onSuccess: (record: T) => null
  onCancel: () => null
}

const useForm = <T extends Record<string, unknown>>({
  initialValues = {},
  zodSchema,
  onSuccess,
  onCancel,
  ...props
}: UseFormProps<T>) => {
  const resource = useResource({ resource: props.resource })

  const { navigateBack } = usePageContext()

  const { getRecordId, primaryKey } = resource.model

  const { upsert, destroy } = resource.useMutate()

  const form = useRHForm<T>({
    defaultValues: filterObject(initialValues),
    values: filterObject(initialValues),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    resolver: zodResolver(zodSchema),
    shouldFocusError: true,
  })

  const recordId = useMemo(() => getRecordId(initialValues), [initialValues])

  const onSubmit: SubmitHandler = async (data: unknown) => {
    const expandedData = Object.entries(expand(data)).reduce((acc, [key, val]) => {
      if (Array.isArray(val) || isObject(val)) {
        return { ...acc, [`${key}Attributes`]: val }
      }

      return { ...acc, [key]: val }
    }, {})

    const upsertData = { ...expandedData, [primaryKey]: recordId }

    const response = await upsert.mutate(upsertData, {
      onSuccess: (newRecord: T) => {
        if (recordId) {
          toast.success('Record updated successfully')
        } else {
          toast.success('Record created successfully')
        }

        if (onSuccess) {
          onSuccess(newRecord)
          return
        }

        navigateBack()
      },
      onError: (error: TError) => {
        if (error.response.status === 422) {
          toast.error('Problems creating item')
          Object.keys(error.body?.errors).forEach((field: string) => {
            form.setError(field, {
              type: error.response.status,
              message: error.body.errors[field].join('\n'),
            })
          })
        }
      },
    })

    return response
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
      return
    }
    navigateBack()
  }

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    destroy.mutate({ [primaryKey]: recordId })
  }

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    form.handleSubmit(onSubmit)(event)
    event.stopPropagation()
  }

  return {
    form,
    resource,
    handleSubmit,
    handleCancel,
    handleDelete,
    isSaving: upsert.isPending,
    isDeleting: destroy.isPending,
    isEdit: recordId !== undefined,
    canDelete: recordId !== undefined,
    isNew: recordId === undefined,
  }
}

export { useForm }
