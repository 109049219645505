export type FilterPrimitive = number | string | boolean | number[] | string[] | boolean[]

export type ConcreteOperatorFn<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
> = (ref: T) => (val?: V | null) => boolean

export type BooleanOperatorFn = (...vals: boolean[]) => boolean

export interface ConcreteOperator<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
> {
  predicate?: string
  op: ConcreteOperatorFn<T, V>
}

export type OpsTree<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
> = Record<string, ConcreteOperator<T, V>>

export type GenericOpsTree<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
> = Record<string, ConcreteOperator<T, V>>

export interface CompoundOperator<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
  S extends OpsTree<T, V> = GenericOpsTree<T, V>,
> {
  predicate?: string
  op: BooleanOperatorFn
  ops: S
}

export type ValueTreeFromOpsTree<
  T extends FilterPrimitive = FilterPrimitive,
  V extends FilterPrimitive = T,
  Tree extends OpsTree<T, V> = GenericOpsTree<T, V>,
> = {
  [K in keyof Tree]?: T
}

export type Operator<T extends FilterPrimitive = FilterPrimitive> =
  | ConcreteOperator<T>
  | CompoundOperator<T>

const scopeOperator: ConcreteOperator<never, never> = {
  op: () => () => true,
}

const eqOperator: ConcreteOperator = {
  predicate: 'eq',
  op: (ref) => (val) => val === ref,
}

const eqStringOperator: ConcreteOperator<string, string> = {
  predicate: 'eq_string',
  op: (ref) => (val) => (val == null ? false : val.toLowerCase() === ref.toLowerCase()),
}

const notEqOperator: ConcreteOperator = {
  predicate: 'not_eq',
  op: (ref) => (val) => val !== ref,
}

const ltOperator: ConcreteOperator = {
  predicate: 'lt',
  op: (ref) => (val) => (val == null ? false : val < ref),
}

const lteqOperator: ConcreteOperator = {
  predicate: 'lteq',
  op: (ref) => (val) => (val == null ? false : val <= ref),
}

const gtOperator: ConcreteOperator = {
  predicate: 'gt',
  op: (ref) => (val) => (val == null ? false : val > ref),
}

const gteqOperator: ConcreteOperator = {
  predicate: 'gteq',
  op: (ref) => (val) => (val == null ? false : val >= ref),
}

// TODO: how to allow both string and number arrays
const includesOperator: ConcreteOperator<string[], string> = {
  predicate: 'in',
  op: (ref) => (val) => (val == null ? false : ref.includes(val)),
}

const startsWithOperator: ConcreteOperator<string, string> = {
  predicate: 'start',
  op: (ref) => (val) => (val == null ? false : val.toLowerCase().startsWith(ref.toLowerCase())),
}

const matchesOperator: ConcreteOperator<string, string> = {
  predicate: 'matches',
  op: (ref) => (val) => (val == null ? false : val.toLowerCase().includes(ref.toLowerCase())),
}

export const Operators: Record<string, ConcreteOperator> = {
  scope: scopeOperator,
  eq: eqOperator,
  eqString: eqStringOperator,
  notEq: notEqOperator,
  lt: ltOperator,
  lteq: lteqOperator,
  gt: gtOperator,
  gteq: gteqOperator,
  includes: includesOperator,
  startsWith: startsWithOperator,
  matches: matchesOperator,
}

const booleanFns: Record<string, BooleanOperatorFn> = {
  and: (...vals: boolean[]) => vals.every((v) => v),
  or: (...vals: boolean[]) => vals.some((v) => v),
  not: (val: boolean) => !val,
}

export type OperatorName = keyof typeof Operators
export type OperatorDef = OperatorName | { [key: string]: OperatorDef }

export const buildOperator = <T extends FilterPrimitive>(spec: OperatorDef): Operator<T> => {
  if (typeof spec === 'string') {
    return Operators[spec]
  }

  return {
    predicate: 'AND',
    op: booleanFns.and,
    ops: Object.keys(spec).reduce((acc, key) => ({ ...acc, [key]: buildOperator(spec[key]) }), {}),
  }
}

export const buildApplyFn =
  <T>(operator: Operator<T>) =>
  (ref: T | Record<string, T>) =>
  (val: T | Record<string, T>) => {
    if (!('ops' in operator)) {
      return operator.op(ref)(val)
    }

    const opFns = Object.keys(operator.ops).reduce((acc, k) => {
      if (ref[k] == null) return acc
      return { ...acc, [k]: operator.ops[k].op(ref[k]) }
    }, {})

    const opFnVals = Object.entries(opFns).map(([k, opFn]) => opFn(val[k]))

    return operator.op(...opFnVals)
  }
