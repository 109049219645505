import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const MonthlyOwnershipsByDivisionsShow = () => {
  const { monthlyOwnershipsByDivisionsShow } = useLoaderQueryBuilders()
  const { record: monthlyOwnershipsByDivision } = useResourceQuery(monthlyOwnershipsByDivisionsShow)

  return <Section title={monthlyOwnershipsByDivision.title} className="mt-16"></Section>
}

export default MonthlyOwnershipsByDivisionsShow
