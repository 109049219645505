import { ErrorBoundary } from 'react-error-boundary'

import { AlertTriangle } from '@/components/icon'
import { InfoTile } from '@/components/info_tile'
import { Link } from '@/components/link'
import { Tile } from '@/components/tile'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Text } from '@/components/ui/text'
import { type RouteHelper } from '@/libs/routes/route'

/** Displays an error that occurred while trying to render a SummaryCard */
function CardFallback(props: { error: Error }) {
  return (
    <>
      <CardHeader>
        <CardTitle>Something went wrong...</CardTitle>
      </CardHeader>
      <CardContent className="h-full">
        <Tile className="p-2">
          <div className="flex items-center gap-2">
            <AlertTriangle className="size-9 text-red-500" />
            <Text as="span" className="flex-1 overflow-y-auto">
              {props.error.message}
            </Text>
          </div>
        </Tile>
      </CardContent>
    </>
  )
}

/**
 * This is a card that is rendered to display the summarized result of
 * retrieving a list of a resource. Aside from basic Card structure,
 * this component also provides a pending state, an error state and
 * an error boundary.
 */
export function SummaryCard(props: {
  actions: React.ReactNode
  children: React.ReactNode
  className?: string
  isPending: boolean
  pluralName: string
  queryError: unknown
  titleLabel?: string
  titleRoute?: RouteHelper
}) {
  const titleLabel = props.titleLabel ?? props.pluralName

  return (
    <Card className={props.className}>
      {props.queryError instanceof Error ? (
        <CardFallback error={props.queryError} />
      ) : (
        <ErrorBoundary FallbackComponent={CardFallback}>
          {props.isPending ? (
            <CardHeader>
              <CardTitle className="animate-pulse">
                {`loading ${props.pluralName.toLowerCase()}`}...
              </CardTitle>
            </CardHeader>
          ) : (
            <>
              <CardHeader>
                <CardTitle>
                  <Link className="capitalize" route={props.titleRoute}>
                    {titleLabel}
                  </Link>
                </CardTitle>
                <CardActions>{props.actions}</CardActions>
              </CardHeader>
              <CardContent>{props.children}</CardContent>
            </>
          )}
        </ErrorBoundary>
      )}
    </Card>
  )
}

/** This can be used when a resource list is empty  */
export function EmptySummaryContent(props: { pluralName: string }) {
  return (
    <InfoTile className="size-full justify-center">
      No {props.pluralName.toLowerCase()} on record
    </InfoTile>
  )
}
