import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { RoutePart } from '@/libs/routes/types'
import { QueryClient } from '@tanstack/react-query'

import { buildLoaderQuery } from './loader_query'

export const buildLoader =
  <T extends ResourceRecord>(
    queryClient: QueryClient,
    query: QueryBuilder<T>,
    routeParts: RoutePart[] = [],
  ) =>
  async ({ params, request }: { params: Record<string, string>; request: Request }) => {
    const search = new URL(request.url).search
    const finalQuery = buildLoaderQuery(query, routeParts, params, search)

    return queryClient.ensureQueryData(finalQuery.build())
  }
