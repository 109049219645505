import { Suspense } from 'react'

import { Actions } from '@/components/actions/actions'
import { SimpleLink } from '@/components/simple-link'
import { HorseResource } from '@/resources/horse'
import {
  FiveCrossAction,
  PedigreeAction,
  ThoromanagerActionWithHorse,
} from '@/resources/horse/actions'

export const horseFormatter = ({
  id,
  name,
  yob,
  damName,
  damYob,
  actions = true,
  fiveCross = false,
  ...props
}: {
  id: number
  name: string
  yob?: number
  damName?: string
  damYob?: number
  actions?: boolean
  fiveCross?: boolean
  nameLabel?: string
}) => {
  const { nameLabel = name } = props
  if (!id) {
    return nameLabel ?? '-'
  }

  const route = HorseResource.routes.show.withOptions({
    horseId: id,
  })

  return (
    <div className="flex w-[230px] justify-between">
      <SimpleLink to={route()} className="text-md w-[160px] justify-start">
        <span className="truncate">{nameLabel}</span>
      </SimpleLink>
      {actions ? (
        <Suspense>
          <Actions numToShow={3}>
            <PedigreeAction horseId={id} variant="ghost" size="sm" simpleLink />
            {fiveCross ? <FiveCrossAction horseId={id} variant="ghost" size="sm" /> : null}
            <ThoromanagerActionWithHorse
              variant="ghost"
              size="sm"
              horse={{ name, yob, damName, damYob }}
              simpleLink
            />
          </Actions>
        </Suspense>
      ) : null}
    </div>
  )
}
