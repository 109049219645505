import { defineTable } from '@/components/data-table/builder'

import { FinanceDepartmentResource } from './resource'

export const financeDepartmentTable = defineTable(FinanceDepartmentResource, {}, (tbl) => ({
  columns: [
    tbl.col.id(),
    tbl.col.recordNo(),
    tbl.col.departmentId(),
    tbl.col.title(),
    tbl.col.parentKey(),
    tbl.col.parentId(),
    tbl.col.parentName(),
    tbl.col.status(),
    tbl.col.custTitle(),
    tbl.col.whenCreated(),
    tbl.col.whenModified(),
    tbl.col.createdBy(),
    tbl.col.modifiedBy(),
    tbl.col.createdAt(),
    tbl.col.updatedAt(),
  ],
}))
