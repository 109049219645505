import { Section } from '@/components/section'
import { usePageContext } from '@/contexts/page'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { BroodmareProspectForm } from '@/resources/broodmare_prospect'
import { useQueryClient } from '@tanstack/react-query'

const BroodmareProspectsNew = () => {
  const { broodmareProspectsNew } = useLoaderQueryBuilders()
  const { record: broodmareProspect } = useResourceQuery(broodmareProspectsNew)

  const { navigateBack } = usePageContext()

  const queryClient = useQueryClient()
  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['broodmareProspectDetails'],
      active: true,
    })

    navigateBack()
  }

  return (
    <Section title="New BroodmareProspect">
      <BroodmareProspectForm initialValues={broodmareProspect} onSuccess={handleSuccess} />
    </Section>
  )
}

export default BroodmareProspectsNew
