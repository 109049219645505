import { useTranslation } from 'react-i18next'

import { HorseImage } from '@/components/horse_image'
import { InfoTile } from '@/components/info_tile'
import { Link } from '@/components/link'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useResourceQuery } from '@/hooks'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { HorseLinkTile } from '@/resources/horse/link-tile'
import { Horse } from '@/types'

import { HorseResource } from './resource'

const HorseCardContent = ({ horse, hideImage }: { horse: Horse; hideImage: boolean }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-[8.5rem] gap-2">
        {hideImage ? null : <HorseImage image={horse.defaultImage} horseId={horse.id} />}
        <div className="grid flex-1 auto-rows-4rem gap-2">
          <InfoTile label="TYPE">
            {t(`activerecord.attributes.horse.horseTypes.${horse.horseType.toString()}`)}
          </InfoTile>
          <InfoTile label="YOB">{horse.yob}</InfoTile>
        </div>
      </div>
      <div className="grid flex-1 auto-rows-4rem gap-2">
        <HorseLinkTile id={horse.sireId} label="SIRE" />
        <HorseLinkTile id={horse.damId} label="DAM" />
      </div>
    </div>
  )
}

const HorseCard = <T extends ResourceRecord>({
  query,
  className,
}: {
  query: QueryBuilder<T>
  className: string
}) => {
  const { record: horse } = useResourceQuery(query)
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={HorseResource.routes.show.withOptions({ horseId: horse.id })}>
            {horse.title}
          </Link>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <HorseCardContent horse={horse} />
      </CardContent>
    </Card>
  )
}

export { HorseCard }
