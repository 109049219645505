import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Permission } from '@/types'

const name = 'Permission'

const recordNameSingular = 'permission'
const recordNamePlural = 'permissions'
const i18nKey = 'permission'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'permissions'

const pageHelperKey = 'permissions'

const resourcePathName = 'permission'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  permission: {
    kind: 'column',
    name: 'permission',
    type: 'enum',
    subtype: 'enum',
    domain: {
      values: [
        {
          value: 'index',
          key: 'index',
        },
        {
          value: 'show',
          key: 'show',
        },
        {
          value: 'create',
          key: 'create',
        },
        {
          value: 'update',
          key: 'update',
        },
        {
          value: 'destroy',
          key: 'destroy',
        },
      ],
    },
  },
  resourceName: {
    kind: 'column',
    name: 'resourceName',
    type: 'string',
  },
  conditional: {
    kind: 'column',
    name: 'conditional',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<Permission> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
