import React from 'react'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { HorseForm } from '@/resources/horse/form'

const HorsesNew = () => {
  const { horsesNew } = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(horsesNew)

  return (
    <Section title="New Horse">
      <HorseForm initialValues={horse} />
    </Section>
  )
}

export default HorsesNew
