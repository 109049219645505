import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTOrganizationHorse } from '@/types'

const name = 'DBTOrganizationHorse'

const recordNameSingular = 'dbtOrganizationHorse'
const recordNamePlural = 'dbtOrganizationHorses'
const i18nKey = 'dbt::OrganizationHorse'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'dbtOrganizationHorses'

const pageHelperKey = 'dbtOrganizationHorses'

const resourcePathName = 'dbt_organization_horse'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'DBTHorse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  division: {
    kind: 'column',
    name: 'division',
    type: 'integer',
  },
  divisionFormatted: {
    kind: 'column',
    name: 'divisionFormatted',
    type: 'text',
  },
  accountId: {
    kind: 'column',
    name: 'accountId',
    type: 'integer',
  },
  arrived: {
    kind: 'column',
    name: 'arrived',
    type: 'date',
  },
  departed: {
    kind: 'column',
    name: 'departed',
    type: 'date',
  },
  expectedDepartureDate: {
    kind: 'column',
    name: 'expectedDepartureDate',
    type: 'date',
  },
  daysOwned: {
    kind: 'column',
    name: 'daysOwned',
    type: 'integer',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
  nameAgeSexSire: {
    kind: 'column',
    name: 'nameAgeSexSire',
    type: 'text',
  },
  horseString: {
    kind: 'column',
    name: 'horseString',
    type: 'text',
  },
  yob: {
    kind: 'column',
    name: 'yob',
    type: 'integer',
  },
  sex: {
    kind: 'column',
    name: 'sex',
    type: 'string',
  },
  damId: {
    kind: 'column',
    name: 'damId',
    type: 'integer',
  },
  sireId: {
    kind: 'column',
    name: 'sireId',
    type: 'integer',
  },
  dob: {
    kind: 'column',
    name: 'dob',
    type: 'date',
  },
  plan: {
    kind: 'column',
    name: 'plan',
    type: 'string',
  },
  rating: {
    kind: 'column',
    name: 'rating',
    type: 'string',
  },
  throatGrade: {
    kind: 'column',
    name: 'throatGrade',
    type: 'string',
  },
  xrayGrade: {
    kind: 'column',
    name: 'xrayGrade',
    type: 'string',
  },
  disposition: {
    kind: 'column',
    name: 'disposition',
    type: 'integer',
  },
  dispositionFormatted: {
    kind: 'column',
    name: 'dispositionFormatted',
    type: 'text',
  },
  category: {
    kind: 'column',
    name: 'category',
    type: 'string',
  },
  situation: {
    kind: 'column',
    name: 'situation',
    type: 'text',
  },
  ownership: {
    kind: 'column',
    name: 'ownership',
    type: 'decimal',
  },
  cost: {
    kind: 'column',
    name: 'cost',
    type: 'decimal',
  },
  totalEarnings: {
    kind: 'column',
    name: 'totalEarnings',
    type: 'decimal',
  },
  totalExpenses: {
    kind: 'column',
    name: 'totalExpenses',
    type: 'decimal',
  },
  projectedValue: {
    kind: 'column',
    name: 'projectedValue',
    type: 'decimal',
  },
  appraisal: {
    kind: 'column',
    name: 'appraisal',
    type: 'decimal',
  },
  actualValue: {
    kind: 'column',
    name: 'actualValue',
    type: 'decimal',
  },
  studFee: {
    kind: 'column',
    name: 'studFee',
    type: 'decimal',
  },
  netNet: {
    kind: 'column',
    name: 'netNet',
    type: 'decimal',
  },
  summaryStatus: {
    kind: 'column',
    name: 'summaryStatus',
    type: 'text',
  },
  summaryStatusTooltip: {
    kind: 'column',
    name: 'summaryStatusTooltip',
    type: 'text',
  },
  finalCost: {
    kind: 'column',
    name: 'finalCost',
    type: 'decimal',
  },
  finalValue: {
    kind: 'column',
    name: 'finalValue',
    type: 'decimal',
  },
  defaultDepartureDate: {
    kind: 'column',
    name: 'defaultDepartureDate',
    type: 'date',
  },
  dateRealized: {
    kind: 'column',
    name: 'dateRealized',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  yearRealized: {
    kind: 'column',
    name: 'yearRealized',
    type: 'decimal',
  },
  monthRealized: {
    kind: 'column',
    name: 'monthRealized',
    type: 'decimal',
  },
}

const modelSpec: ModelSpec<DBTOrganizationHorse> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
