// JsFromRoutes CacheKey b0a9dc17f2035dd1d7e2fea6b6025066
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const deviseInvitationsApi = {
  edit: definePathHelper('get', '/api/users/invitation/accept'),
  destroy: definePathHelper('get', '/api/users/invitation/remove'),
  new: definePathHelper('get', '/api/users/invitation/new'),
  update: definePathHelper('patch', '/api/users/invitation'),
  create: definePathHelper('post', '/api/users/invitation'),
}
