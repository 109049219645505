/*
 * TODO Implement ResetPassword Page for members
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function UsersResetPassword() {
  return <PageHeader title="Users ResetPassword" />
}

export default UsersResetPassword
