import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FinanceAccount } from '@/types'

const name = 'FinanceAccount'

const recordNameSingular = 'financeAccount'
const recordNamePlural = 'financeAccounts'
const i18nKey = 'finance::Account'

const primaryKey = 'accountNo'
const searchKey = 'title'

const parents: Scope[] = []

const apiHelperKey = 'financeAccounts'

const pageHelperKey = 'financeAccounts'

const resourcePathName = 'finance_account'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  recordNo: {
    kind: 'column',
    name: 'recordNo',
    type: 'integer',
  },
  accountNo: {
    kind: 'column',
    name: 'accountNo',
    type: 'string',
  },
  accountPrefix: {
    kind: 'column',
    name: 'accountPrefix',
    type: 'text',
  },
  title: {
    kind: 'column',
    name: 'title',
    type: 'string',
  },
  accountLabel: {
    kind: 'column',
    name: 'accountLabel',
    type: 'text',
  },
  accountType: {
    kind: 'column',
    name: 'accountType',
    type: 'string',
  },
  normalBalance: {
    kind: 'column',
    name: 'normalBalance',
    type: 'string',
  },
  normalBalanceSign: {
    kind: 'column',
    name: 'normalBalanceSign',
    type: 'integer',
  },
  accountTypeFull: {
    kind: 'column',
    name: 'accountTypeFull',
    type: 'text',
  },
  cashFlowGroup: {
    kind: 'column',
    name: 'cashFlowGroup',
    type: 'text',
  },
  cashFlowGroupNoEquineDep: {
    kind: 'column',
    name: 'cashFlowGroupNoEquineDep',
    type: 'text',
  },
  cashFlowAccountGroup: {
    kind: 'column',
    name: 'cashFlowAccountGroup',
    type: 'text',
  },
  cashFlowAccountGroupNoEquineDep: {
    kind: 'column',
    name: 'cashFlowAccountGroupNoEquineDep',
    type: 'text',
  },
  iscfAccountGroup: {
    kind: 'column',
    name: 'iscfAccountGroup',
    type: 'text',
  },
  closingType: {
    kind: 'column',
    name: 'closingType',
    type: 'string',
  },
  closingAccountNo: {
    kind: 'column',
    name: 'closingAccountNo',
    type: 'string',
  },
  closingAccountTitle: {
    kind: 'column',
    name: 'closingAccountTitle',
    type: 'string',
  },
  status: {
    kind: 'column',
    name: 'status',
    type: 'string',
  },
  requireDept: {
    kind: 'column',
    name: 'requireDept',
    type: 'boolean',
  },
  requireLoc: {
    kind: 'column',
    name: 'requireLoc',
    type: 'boolean',
  },
  taxable: {
    kind: 'column',
    name: 'taxable',
    type: 'boolean',
  },
  categoryKey: {
    kind: 'column',
    name: 'categoryKey',
    type: 'string',
  },
  category: {
    kind: 'column',
    name: 'category',
    type: 'string',
  },
  taxCode: {
    kind: 'column',
    name: 'taxCode',
    type: 'string',
  },
  mrcCode: {
    kind: 'column',
    name: 'mrcCode',
    type: 'string',
  },
  closeToAcctKey: {
    kind: 'column',
    name: 'closeToAcctKey',
    type: 'integer',
  },
  alternativeAccount: {
    kind: 'column',
    name: 'alternativeAccount',
    type: 'string',
  },
  whenCreated: {
    kind: 'column',
    name: 'whenCreated',
    type: 'datetime',
  },
  whenModified: {
    kind: 'column',
    name: 'whenModified',
    type: 'datetime',
  },
  createdBy: {
    kind: 'column',
    name: 'createdBy',
    type: 'integer',
  },
  modifiedBy: {
    kind: 'column',
    name: 'modifiedBy',
    type: 'integer',
  },
  subLedgerControlOn: {
    kind: 'column',
    name: 'subLedgerControlOn',
    type: 'boolean',
  },
  megaEntityKey: {
    kind: 'column',
    name: 'megaEntityKey',
    type: 'integer',
  },
  megaEntityId: {
    kind: 'column',
    name: 'megaEntityId',
    type: 'string',
  },
  megaEntityName: {
    kind: 'column',
    name: 'megaEntityName',
    type: 'string',
  },
  requireCustomer: {
    kind: 'column',
    name: 'requireCustomer',
    type: 'boolean',
  },
  requireVendor: {
    kind: 'column',
    name: 'requireVendor',
    type: 'boolean',
  },
  requireEmployee: {
    kind: 'column',
    name: 'requireEmployee',
    type: 'boolean',
  },
  requireClass: {
    kind: 'column',
    name: 'requireClass',
    type: 'boolean',
  },
  recordUrl: {
    kind: 'column',
    name: 'recordUrl',
    type: 'string',
  },
  nonHorse: {
    kind: 'column',
    name: 'nonHorse',
    type: 'boolean',
  },
  unused: {
    kind: 'column',
    name: 'unused',
    type: 'boolean',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  accountGroup: {
    kind: 'column',
    name: 'accountGroup',
    type: 'text',
  },
  accountSector: {
    kind: 'column',
    name: 'accountSector',
    type: 'text',
  },
  accountExpenseType: {
    kind: 'column',
    name: 'accountExpenseType',
    type: 'text',
  },
  eponaAccountExpenseGroup: {
    kind: 'column',
    name: 'eponaAccountExpenseGroup',
    type: 'text',
  },
}

const modelSpec: ModelSpec<FinanceAccount> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
