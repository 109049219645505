import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { RacehorseComment } from '@/types'

const name = 'RacehorseComment'

const recordNameSingular = 'racehorseComment'
const recordNamePlural = 'racehorseComments'
const i18nKey = 'racehorseComment'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'racehorseComments'

const pageHelperKey = 'racehorseComments'

const resourcePathName = 'racehorse_comment'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  date: {
    kind: 'column',
    name: 'date',
    type: 'date',
  },
  tier: {
    kind: 'column',
    name: 'tier',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'A',
        },
        {
          value: 1,
          key: 'P+',
        },
        {
          value: 2,
          key: 'P',
        },
        {
          value: 3,
          key: 'B',
        },
      ],
    },
  },
  comment: {
    kind: 'column',
    name: 'comment',
    type: 'text',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  tierNumeric: {
    kind: 'column',
    name: 'tierNumeric',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<RacehorseComment> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
