import { useCallback } from 'react'

import { ResourceRecord } from '@/libs/resource/record'
import { cartesian, extractPath } from '@/utils'
import { from as aqFrom } from 'arquero'

import { defineTable } from './table-definition'
import { TableDefinition } from './types'

const DELIM = '__'

const usePivotTable = <TData extends ResourceRecord>(
  table: TableDefinition<TData>,
  groupColumns: string[],
  pivotColumns: string[],
  valueColumns: string[],
  attributeColumns: string[] = [],
  pivotValueSpace: unknown[][],
  columnRenameMap?: Record<string, string>,
) => {
  const transformData = useCallback(
    (data: TData) => {
      const table = aqFrom(data)

      const attributeColumnsAq = attributeColumns.map((col) => col.split('.')[0])

      const attributes = table
        .select(...groupColumns, ...attributeColumnsAq)
        .dedupe(...groupColumns)

      const results = table
        .groupby(...groupColumns)
        .pivot(pivotColumns, valueColumns, {
          keySeparator: DELIM,
          valueSeparator: DELIM,
        })
        .join_left(attributes)

      const columnNames = results.columnNames()

      if (columnRenameMap) {
        const renameMap = Object.keys(columnRenameMap).reduce((acc, name) => {
          if (!columnNames.includes(name)) return acc

          return { ...acc, [name]: columnRenameMap[name] }
        }, {})

        return results.rename(renameMap).objects()
      }

      return results.objects()
    },
    [groupColumns, pivotColumns, valueColumns],
  )

  const extractFieldsFromColumnName = (name: string) => {
    const parts = name.split(DELIM)
    const fieldNames = valueColumns.length > 1 ? [...valueColumns, ...pivotColumns] : pivotColumns

    return fieldNames.reduce<Record<string, string>>(
      (res, fieldName, idx) => ({ ...res, [fieldName]: parts[idx] }),
      {},
    )
  }

  const cartesianProduct =
    valueColumns.length > 1
      ? cartesian(valueColumns, ...pivotValueSpace)
      : cartesian(...pivotValueSpace)

  const pivotColumnNames = cartesianProduct.map((valueSet) => valueSet.join(DELIM))

  const pivotColumnDetails = pivotColumnNames.map((pivotName) => {
    const pivotValues = extractFieldsFromColumnName(pivotName)

    const columnName = columnRenameMap ? columnRenameMap[pivotName] : pivotName
    return {
      columnName,
      pivotValues,
    }
  })

  const pivotTable = defineTable(table.resource, { '.': table }, (tbl) => {
    const existingColumns = [...groupColumns, ...attributeColumns].map((columnName) =>
      extractPath(tbl.col, columnName)(),
    )

    const pivotedColumns = pivotColumnDetails.map(({ columnName }) =>
      tbl.calculatedCol(columnName, (row) => row[columnName]),
    )

    return {
      columns: [...existingColumns, ...pivotedColumns],
    }
  })

  return { pivotTable, pivotColumnDetails, transformData }
}

export { usePivotTable }
