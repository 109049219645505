import { parseISO } from 'date-fns'
import { format, FormatOptions, DateType, toZonedTime } from 'date-fns-tz'

const TIME_ZONE = 'America/New_York'

export const today = () => {
  return toZonedTime(new Date(), TIME_ZONE)
}

export const formatDate = (
  date: string | number | DateType,
  formatStr = 'MMMM do, yyyy',
  options: FormatOptions = { timeZone: TIME_ZONE },
) => {
  if (!date) return null
  const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

  const zonedDate = toZonedTime(dateObj, TIME_ZONE)
  return format(zonedDate, formatStr, options)
}

export const defaultMatingYear = () => {
  const zonedDate = toZonedTime(new Date(), TIME_ZONE)
  return zonedDate.getFullYear() + (zonedDate.getMonth() + 1 >= 7 ? 1 : 0)
}

export const currentYear = (offset = 0) => {
  const zonedDate = toZonedTime(new Date(), TIME_ZONE)
  return zonedDate.getFullYear() + offset
}

export const dateWithinRange = (date: Date, start: Date, end: Date) => date >= start && date <= end
