import { HorseImage } from '@/components/horse_image'
import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  CardTitle,
} from '@/components/ui/card'
import { Text } from '@/components/ui/text'
import { bestClassStyle } from '@/resources/dbt/race/best_class_style'
import { FiveCrossAction, PedigreeAction, ThoromanagerAction } from '@/resources/horse'
import { horseNameFormatter } from '@/resources/horse/horse_name_formatter'
import { EsnTag } from '@/resources/models/fpi_prediction'
import { horseDetailsRoutes, horsesRoutes } from '@/routes'
import { HorseDetail } from '@/types'
import { formatCurrency } from '@/utils'

import { HorseDetailResource } from './resource'

const HorseDetailObject = ({ horseDetail }: { horseDetail: HorseDetail }) => {
  return (
    <div className="flex justify-between">
      <div className="mr-4 h-16 w-20 hover:cursor-pointer tablet:col-span-6 tablet:row-span-5 phone:col-span-12 phone:row-span-4">
        <HorseImage
          image={horseDetail.horse.defaultImage}
          size="container"
          behavior="cover"
          horseId={horseDetail.horse.id}
          hideButton
        />
      </div>
      <div className="flex flex-1 flex-col gap-5">
        <InlineInfoTile
          className={bestClassStyle(horseDetail.careerRaceSummary.bestClass)}
          label={horseDetail.careerRaceSummary.bestClassTitle ?? '-'}
          tooltipText="Best Class"
          icon="Medal"
        />
        <InlineInfoTile
          className="col-span-3 row-span-1"
          label={formatCurrency(horseDetail.careerRaceSummary.totalEarnings) ?? '-'}
          tooltipText="Total Earnings"
          icon="DollarSign"
        ></InlineInfoTile>
      </div>
      <div className="flex flex-1 flex-col gap-5">
        <InlineInfoTile
          className="col-span-3 row-span-1"
          label={formatCurrency(horseDetail.lastSaleResult.salePrice) ?? '-'}
          tooltipText="Sale Price"
          icon="DollarSign"
        ></InlineInfoTile>

        <InlineInfoTile
          className="col-span-3 row-span-1"
          label={horseDetail.lastSaleResult.saleTitle ?? '-'}
          tooltipText="Race Location"
          icon="Map"
        ></InlineInfoTile>
      </div>
      <div className="flex h-16 w-24 flex-col gap-2 rounded">
        <Text as="span" size="sm" muted>
          ESN
        </Text>
        {horseDetail.esn.gradedStakesWinner ? (
          <EsnTag
            esn={horseDetail.esn.gradedStakesWinner}
            esnTier={horseDetail.esn.gradedStakesWinnerTier}
            className="h-8 w-14 rounded text-lg"
          />
        ) : null}
      </div>
    </div>
  )
}

export type HorseDetailCardProps = CardProps & {
  horseDetail: HorseDetail
}

export const HorseDetailCard = ({ horseDetail, ...props }: HorseDetailCardProps) => {
  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>
          <Link
            route={horsesRoutes.show.withOptions({
              horseId: horseDetail.horse.horseId,
            })}
          >
            {horseNameFormatter(horseDetail.horse)}
          </Link>
        </CardTitle>
        <CardActions>
          <PedigreeAction horseId={horseDetail.horse.horseId} iconOnly />
          <ThoromanagerAction horseId={horseDetail.horse.horseId} iconOnly />
          <FiveCrossAction horseId={horseDetail.horse.horseId} iconOnly />
        </CardActions>
      </CardHeader>
      <CardContent>
        <HorseDetailObject horseDetail={horseDetail} />
      </CardContent>
    </Card>
  )
}

export const SaleCard = ({
  horseDetail,
  className,
}: {
  horseDetail: HorseDetail
  className?: string
}) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link
            route={horseDetailsRoutes.show.withOptions({
              id: horseDetail.horse.horseId,
            })}
          >
            {horseDetail.title}
          </Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={HorseDetailResource} iconOnly />
        </CardActions>
      </CardHeader>
      <CardContent>
        <HorseDetailCardContent horseDetail={horseDetail} />
      </CardContent>
    </Card>
  )
}
