import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { ModelsModelVersion } from '@/types'

const name = 'ModelsModelVersion'

const recordNameSingular = 'modelsModelVersion'
const recordNamePlural = 'modelsModelVersions'
const i18nKey = 'models::ModelVersion'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'modelsModelVersions'

const pageHelperKey = 'modelsModelVersions'

const resourcePathName = 'models_model_version'

const attributes: Record<string, AttributeSpec> = {
  model: {
    kind: 'association',
    name: 'model',
    modelName: 'ModelsModel',
    foreignKey: 'modelId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  modelId: {
    kind: 'column',
    name: 'modelId',
    type: 'integer',
  },
  versionMajor: {
    kind: 'column',
    name: 'versionMajor',
    type: 'integer',
  },
  versionMinor: {
    kind: 'column',
    name: 'versionMinor',
    type: 'integer',
  },
  versionPatch: {
    kind: 'column',
    name: 'versionPatch',
    type: 'integer',
  },
  artifact: {
    kind: 'column',
    name: 'artifact',
    type: 'integer',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<ModelsModelVersion> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
