import { Section } from '@/components/section'
import { usePageContext } from '@/contexts/page'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { BroodmareProspectForm } from '@/resources/broodmare_prospect'
import { useQueryClient } from '@tanstack/react-query'

const BroodmareProspectsEdit = (props) => {
  const { broodmareProspectsEdit } = useLoaderQueryBuilders()
  const { record: broodmareProspect } = useResourceQuery(broodmareProspectsEdit)

  const { navigateBack } = usePageContext()

  const queryClient = useQueryClient()
  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['broodmareProspectDetails'],
      active: true,
    })

    navigateBack()
  }

  return (
    <Section title={`Edit ${broodmareProspect.title}`}>
      <BroodmareProspectForm
        initialValues={broodmareProspect}
        onSuccess={handleSuccess}
        {...props}
      />
    </Section>
  )
}

export default BroodmareProspectsEdit
