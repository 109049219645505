import { useLocation } from 'react-router-dom'

import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { LayoutOutlet } from '@/pages/layouts/layout-outlet'
import { SeasonForm } from '@/resources/season/form'

const SeasonsNew = () => {
  const { seasonsNew } = useLoaderQueryBuilders()
  const { record: season } = useResourceQuery(seasonsNew)
  const location = useLocation()

  const renderLayout = location.pathname.split('/').reverse()[1] !== 'seasons'

  return (
    <Section title="New Season">
      <SeasonForm initialValues={season} />
      {renderLayout ? <LayoutOutlet /> : null}
    </Section>
  )
}

export default SeasonsNew
