import * as React from 'react'

import { ChevronDown } from '@/components/icons'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Tag, TagProps } from '@/components/ui/tag'
import { Text } from '@/components/ui/text'
import { FilterPrimitive } from '@/libs/filter'

const FilterChipTag = (props: TagProps) => <Tag variant="secondary" size="sm" {...props} />

function FilterChipValue({ value }: { value?: FilterPrimitive | Record<string, FilterPrimitive> }) {
  if (value == null) {
    return null
  }

  if (Array.isArray(value)) {
    if (value.length > 2) {
      return (
        <>
          <Separator orientation="vertical" className="h-4 bg-black/40" />
          <FilterChipTag>{value.length} selected</FilterChipTag>
        </>
      )
    }

    return (
      <>
        <Separator orientation="vertical" className="h-4 bg-black/40" />
        <div className="flex space-x-1">
          {value.map((item) => (
            <FilterChipTag key={item}>{item}</FilterChipTag>
          ))}
        </div>
      </>
    )
  }

  if (typeof value === 'object') {
    if (
      Object.prototype.hasOwnProperty.call(value, 'min') ||
      Object.prototype.hasOwnProperty.call(value, 'max')
    ) {
      return (
        <>
          <Separator orientation="vertical" className="h-4 bg-black/40" />
          <div className="max-w-12 space-x-1 truncate">
            {value.max && value.min
              ? `${value.min} - ${value.max}`
              : value.max
                ? `<${value.max}`
                : `${value.min}+`}
          </div>
        </>
      )
    }

    return (
      <>
        <Separator orientation="vertical" className="h-4 bg-black/40" />
        <div className="max-w-12 space-x-1 truncate">
          {value.max && value.min
            ? `${value.min} - ${value.max}`
            : value.max
              ? `<${value.max}`
              : `${value.min}+`}
        </div>
      </>
    )
  }

  // TODO: Is this required?
  if (value === 'true' || value === 'false') {
    return
  }

  return <FilterChipTag>{value}</FilterChipTag>
}

interface FilterChipProps {
  label?: string
  value?: FilterPrimitive | Record<string, FilterPrimitive>
  children?: React.ReactNode
}

export function FilterChip({ label, value, children }: FilterChipProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="gap-1.5 border-dashed data-[state=open]:border-solid data-[state=open]:border-plain data-[state=open]:bg-plain"
          iconRight={<ChevronDown />}
        >
          <Text as="span">{label}</Text>
          <FilterChipValue value={value} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-w-[30rem] p-0" align="start">
        {children}
      </PopoverContent>
    </Popover>
  )
}
