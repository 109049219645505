/*
 * TODO Implement Edit Page for users/registrations
 */
import React from 'react'

import { PageHeader } from '@/components/header'

function RegistrationsEdit() {
  return <PageHeader title="Users::Registrations Edit" />
}

export default RegistrationsEdit
