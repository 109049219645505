import React from 'react'

import { NegativeTag, NeutralTag, PositiveTag, TagProps } from '@/components/ui/tag'
import { DBTMatingEsn } from '@/types'
import { formatPercentage } from '@/utils'

interface EsnTagProps extends TagProps {
  esn: DBTMatingEsn['gradedStakesWinner']
  esnTier: DBTMatingEsn['gradedStakesWinnerTier']
}

export const EsnTag = ({ esn, esnTier, ...props }: EsnTagProps) => {
  const tierOnly = esn === undefined

  const tagProps = {
    iconOnly: tierOnly,
    size: tierOnly ? 'xxs' : 'xs',
    ...props,
  }

  let Comp = null

  if (esnTier === 0) Comp = NegativeTag
  if (esnTier === 1) Comp = NeutralTag
  if (esnTier === 2) Comp = PositiveTag

  if (!Comp) return null

  return <Comp {...tagProps}>{esn ? formatPercentage(esn) : null}</Comp>
}
