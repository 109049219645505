import React, { Suspense } from 'react'

import { X } from '@/components/icon'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogPrimitiveContent, DialogTrigger } from '@/components/ui/dialog'

import ImageGallery from './image_gallery'

interface ImageGalleryProps {
  horseId: number
  asChild: boolean
  hideAction: boolean
  initialImageId?: number
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}

const ImageGalleryDialog = ({
  horseId,
  asChild = false,
  initialImageId,
  open,
  setOpen,
  children,
}: ImageGalleryProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild={asChild}>{children}</DialogTrigger>
      <Suspense>
        <DialogPrimitiveContent className="h-screen max-w-none items-center border-none bg-transparent shadow-none">
          <DialogClose className="fixed left-0 top-0 z-30 size-full bg-black/80">
            <Button
              className="fixed bottom-5 right-5 z-10 border-2 pc:hidden"
              size="3xl"
              iconLeft={<X />}
              iconOnly
            />
          </DialogClose>
          <ImageGallery horseId={horseId} initialImageId={initialImageId} />
          <DialogClose className="absolute right-5 top-5 z-20">
            <X className="text-white" size={32} />
          </DialogClose>
        </DialogPrimitiveContent>
      </Suspense>
    </Dialog>
  )
}

export default ImageGalleryDialog
