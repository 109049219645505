import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { FasigTiptonSale } from '@/types'

const name = 'FasigTiptonSale'

const recordNameSingular = 'fasigTiptonSale'
const recordNamePlural = 'fasigTiptonSales'
const i18nKey = 'fasigTipton::Sale'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'fasigTiptonSales'

const pageHelperKey = 'fasigTiptonSales'

const resourcePathName = 'fasig_tipton_sale'

const attributes: Record<string, AttributeSpec> = {
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  fasigTiptonId: {
    kind: 'column',
    name: 'fasigTiptonId',
    type: 'integer',
  },
  saleIdentifier: {
    kind: 'column',
    name: 'saleIdentifier',
    type: 'string',
  },
  ipadSaleId: {
    kind: 'column',
    name: 'ipadSaleId',
    type: 'string',
  },
  saleStartDate: {
    kind: 'column',
    name: 'saleStartDate',
    type: 'date',
  },
  maxHip: {
    kind: 'column',
    name: 'maxHip',
    type: 'integer',
  },
  minHip: {
    kind: 'column',
    name: 'minHip',
    type: 'integer',
  },
  pdf: {
    kind: 'column',
    name: 'pdf',
    type: 'string',
  },
  pageUrlBase: {
    kind: 'column',
    name: 'pageUrlBase',
    type: 'string',
  },
  earliestOutDate: {
    kind: 'column',
    name: 'earliestOutDate',
    type: 'date',
  },
  earliestUpdateDate: {
    kind: 'column',
    name: 'earliestUpdateDate',
    type: 'date',
  },
  subtype: {
    kind: 'column',
    name: 'subtype',
    type: 'string',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
}

const modelSpec: ModelSpec<FasigTiptonSale> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
