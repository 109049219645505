import { z } from 'zod'

export const TrainingReportSchema = z.object({
  horseId: z.number().int(),
  organizationId: z.number().int(),
  userId: z.union([z.number().int(), z.null()]).optional(),
  trainer: z.union([z.string(), z.null()]).optional(),
  date: z.string().date().default('2024-09-08'),
  location: z.union([z.string(), z.null()]).optional(),
  status: z
    .enum(['in_training', 'pre_training', 'breaking', 'rehab', 'layup', 'retire'])
    .default('in_training'),
  comment: z.union([z.string(), z.null()]).optional(),
  rating: z.union([z.string(), z.null()]).optional(),
  targetRace: z.union([z.string(), z.null()]).optional(),
  targetRaceDate: z.union([z.string().date(), z.null()]).optional(),
  file: z.union([z.string(), z.null()]).optional(),
})
