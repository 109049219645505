import { useEffect } from 'react'

import { HorsePageHeader } from '@/components/header/horse-page-header'
import { Section } from '@/components/section'
import { useAuth } from '@/contexts/auth'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useRecentlyVisited } from '@/hooks/use_recently_visited'
import { LayoutOutlet } from '@/pages/layouts/layout-outlet'
import { AppraisalResource, AppraisalSummaryCard } from '@/resources/appraisal'
import {
  ConformationNoteResource,
  ConformationNoteSummaryCard,
} from '@/resources/conformation_note'
import { EsnSummaryCard } from '@/resources/dbt/esn_summary/card'
import { DBTMatingEsnResource } from '@/resources/dbt/mating_esn'
import { DBTSaleResultResource } from '@/resources/dbt/sale_result'
import { DistancePanelSummaryCard, DNASetResource } from '@/resources/dna_set'
import { HorseInfo } from '@/resources/horse'
import { HorseDetailResource } from '@/resources/horse_detail'
import { HorseActions } from '@/resources/horse/page-actions'
import { MatingResource, MatingSummaryCard } from '@/resources/mating'
import { OrganizationHorseResource, OwnershipSummaryCard } from '@/resources/organization_horse'
import { ProgenySummaryCard } from '@/resources/progeny/card'
import {
  RacehorseCommentResource,
  RacehorseCommentSummaryCard,
} from '@/resources/racehorse_comment'
import { SaleRecordDetailResource, SaleRecordSummaryCard } from '@/resources/sale_record_detail'
import { StudFeeResource, StudFeeSummaryCard } from '@/resources/stud_fee'
import { TrainingReportResource, TrainingReportSummaryCard } from '@/resources/training_report'

const HorsesShow = () => {
  const { hasPermission } = useAuth()
  const { horsesShow } = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(horsesShow)

  const { add } = useRecentlyVisited<Horse>({ key: 'recentHorses' })

  useEffect(() => {
    if (horse?.id) {
      add(horse)
    }
  }, [horse, add])

  if (horse == null) {
    throw new Error('No horse present on horse page...')
  }

  return (
    <div>
      <HorsePageHeader />
      <HorseInfo />
      <Section className="mt-4" grid>
        {horse.horseType === 'mare' ? (
          <>
            <ProgenySummaryCard
              className="col-span-12 row-span-9 sm:col-span-6 sm:row-span-8 2xl:col-span-6 2xl:row-span-9"
              query={HorseDetailResource.query.index
                .sort('horse__yob', 'desc')
                .filter('horse__dam_id', horse.id)}
            />
            {hasPermission(MatingResource.name, 'index') ? (
              <MatingSummaryCard
                className="col-span-12 row-span-5 sm:col-span-6 sm:row-span-4 2xl:col-span-4 2xl:row-span-3"
                query={MatingResource.query.index
                  .sort('final', 'desc')
                  .sort('year', 'desc')
                  .filter('mareId', horse.id)}
              />
            ) : null}
          </>
        ) : null}
        {hasPermission(DBTSaleResultResource.name, 'index') ? (
          <SaleRecordSummaryCard
            className="col-span-12 row-span-5 sm:col-span-6 sm:row-span-4 2xl:col-span-4 2xl:row-span-3"
            query={SaleRecordDetailResource.query.index
              .filter('horse__horse_id', horse.id)
              .sort('sale__start_date', 'desc')}
          />
        ) : null}
        {hasPermission(AppraisalResource.name, 'index') ? (
          <AppraisalSummaryCard
            className="col-span-12 row-span-5 sm:col-span-6 sm:row-span-4 2xl:col-span-4 2xl:row-span-3"
            query={AppraisalResource.query.index
              .filter('horseId', horse.id)
              .sort('date', 'asc')
              .pageSize(2)}
          />
        ) : null}
        {hasPermission(TrainingReportResource.name, 'index') ? (
          <TrainingReportSummaryCard
            className="col-span-12 row-span-9 sm:col-span-6 sm:row-span-8 2xl:col-span-4 2xl:row-span-7"
            query={TrainingReportResource.query.index
              .filter('horseId', horse.id)
              .sort('date', 'desc')}
          />
        ) : null}
        {hasPermission(DNASetResource.name, 'index') ? (
          <DistancePanelSummaryCard
            className="col-span-12 row-span-2 sm:col-span-6 2xl:col-span-4"
            query={DNASetResource.query.index.filter('horseId', horse.id)}
          />
        ) : null}
        {hasPermission(OrganizationHorseResource.name, 'index') ? (
          <OwnershipSummaryCard
            className="col-span-12 row-span-6 sm:col-span-6 2xl:col-span-4"
            query={OrganizationHorseResource.query.index.filter('horseId', horse.id)}
          />
        ) : null}
        {hasPermission(ConformationNoteResource.name, 'index') ? (
          <ConformationNoteSummaryCard
            className="col-span-12 row-span-4 sm:col-span-6 sm:row-span-4 2xl:col-span-4 2xl:row-span-3"
            query={ConformationNoteResource.query.index.filter('horseId', horse.id)}
          />
        ) : null}
        {
          // TODO Just doing Mating ESN here
          hasPermission(DBTMatingEsnResource.name, 'index') ? (
            <EsnSummaryCard
              title="ESN Summary"
              className="col-span-12 row-span-3 sm:col-span-6 2xl:col-span-4"
              query={DBTMatingEsnResource.query.index
                .filter('horseId', horse.id)
                .sort('modelVersionId', 'asc')}
            />
          ) : null
        }
        {hasPermission(RacehorseCommentResource.name, 'index') ? (
          <RacehorseCommentSummaryCard
            className="col-span-12 row-span-4 sm:col-span-6 sm:row-span-4 2xl:col-span-4 2xl:row-span-3"
            query={RacehorseCommentResource.query.index.filter('horseId', horse.id)}
          />
        ) : null}
        {hasPermission(StudFeeResource.name, 'index') ? (
          <StudFeeSummaryCard
            className="col-span-12 row-span-2 sm:col-span-6 2xl:col-span-4"
            query={StudFeeResource.query.index.filter('horseId', horse.id)}
          />
        ) : null}
      </Section>
      <HorseActions id={horse.id} />
      <LayoutOutlet />
    </div>
  )
}

export { HorsesShow as Component }

export default HorsesShow
