import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { StallionBreedingTarget } from '@/types'

const name = 'StallionBreedingTarget'

const recordNameSingular = 'stallionBreedingTarget'
const recordNamePlural = 'stallionBreedingTargets'
const i18nKey = 'stallionBreedingTarget'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'stallionBreedingTargets'

const pageHelperKey = 'stallionBreedingTargets'

const resourcePathName = 'stallion_breeding_target'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  year: {
    kind: 'column',
    name: 'year',
    type: 'integer',
  },
  mares: {
    kind: 'column',
    name: 'mares',
    type: 'integer',
  },
  mpiG1: {
    kind: 'column',
    name: 'mpiG1',
    type: 'decimal',
  },
  opiG1: {
    kind: 'column',
    name: 'opiG1',
    type: 'decimal',
  },
  mpiGs: {
    kind: 'column',
    name: 'mpiGs',
    type: 'decimal',
  },
  opiGs: {
    kind: 'column',
    name: 'opiGs',
    type: 'decimal',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  standsAndNurses: {
    kind: 'column',
    name: 'standsAndNurses',
    type: 'integer',
  },
  foalMareShares: {
    kind: 'column',
    name: 'foalMareShares',
    type: 'integer',
  },
  breedingRights: {
    kind: 'column',
    name: 'breedingRights',
    type: 'integer',
  },
  shareholderSeasons: {
    kind: 'column',
    name: 'shareholderSeasons',
    type: 'integer',
  },
  averageStudFee: {
    kind: 'column',
    name: 'averageStudFee',
    type: 'integer',
  },
}

const modelSpec: ModelSpec<StallionBreedingTarget> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
