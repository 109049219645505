import { z } from 'zod'

export const HorseSchema = z.object({
  name: z.union([z.string(), z.null()]).optional(),
  yob: z.number().int(),
  origin: z.union([z.string(), z.null()]).optional(),
  color: z.union([z.string(), z.null()]).optional(),
  sex: z.union([z.string(), z.null()]).optional(),
  familyName: z.union([z.string(), z.null()]).optional(),
  damName: z.union([z.string(), z.null()]).optional(),
  damYob: z.union([z.number().int(), z.null()]).optional(),
  sireName: z.union([z.string(), z.null()]).optional(),
  sireYob: z.union([z.number().int(), z.null()]).optional(),
  damId: z.union([z.number().int(), z.null()]).optional(),
  sireId: z.union([z.number().int(), z.null()]).optional(),
  di: z.union([z.string(), z.null()]).optional(),
  dp: z.union([z.string(), z.null()]).optional(),
  cd: z.union([z.string(), z.null()]).optional(),
  ptag: z.union([z.string(), z.null()]).optional(),
  dob: z.union([z.string().date(), z.null()]).optional(),
  foalLocation: z.union([z.string(), z.null()]).optional(),
  damDamName: z.union([z.string(), z.null()]).optional(),
  damDamYob: z.union([z.number().int(), z.null()]).optional(),
  damSireName: z.union([z.string(), z.null()]).optional(),
  damSireYob: z.union([z.number().int(), z.null()]).optional(),
  sireDamName: z.union([z.string(), z.null()]).optional(),
  sireDamYob: z.union([z.number().int(), z.null()]).optional(),
  sireSireName: z.union([z.string(), z.null()]).optional(),
  sireSireYob: z.union([z.number().int(), z.null()]).optional(),
  familyId: z.union([z.number().int(), z.null()]).optional(),
  secondTwin: z.boolean().default(false),
  horseType: z
    .enum(['unknown', 'foal', 'yearling', 'racehorse', 'mare', 'stallion'])
    .default('unknown'),
  breederId: z.union([z.number().int(), z.null()]).optional(),
  userId: z.union([z.number().int(), z.null()]).optional(),
  damDamId: z.union([z.number().int(), z.null()]).optional(),
  sireSireId: z.union([z.number().int(), z.null()]).optional(),
  damSireId: z.union([z.number().int(), z.null()]).optional(),
  sireDamId: z.union([z.number().int(), z.null()]).optional(),
  oppenheimSireLineId: z.union([z.number().int(), z.null()]).optional(),
  registrationNumber: z.union([z.string(), z.null()]).optional(),
  registrationStatus: z.union([z.string(), z.null()]).optional(),
  microchipNumber: z.union([z.string(), z.null()]).optional(),
  certificateIssuedDate: z.union([z.string().date(), z.null()]).optional(),
  dateOfDeath: z.union([z.string().date(), z.null()]).optional(),
  timeOfDeath: z.union([z.string().time(), z.null()]).optional(),
  namedRef: z.union([z.string(), z.null()]).optional(),
  unnamedDamRef: z.union([z.string(), z.null()]).optional(),
  unnamedParentRef: z.union([z.string(), z.null()]).optional(),
  nameProper: z.union([z.string(), z.null()]).optional(),
  studBookCode: z.union([z.string(), z.null()]).optional(),
  studBookAliasId: z.union([z.string(), z.null()]).optional(),
  identifierType: z.number().int().default(0),
  migrationStatus: z.string().default('pending'),
})
