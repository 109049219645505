// JsFromRoutes CacheKey 283efdfede42c36fe0869167e3cc6598
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/routes/endpoint'
import { FinanceMonthlyEntryModel } from '@/models/finance/monthly_entry'

export const financeMonthlyEntriesApi = {
  index: defineEndpoint({
    controllerName: 'finance/monthly_entries',
    actionName: 'index',
    model: FinanceMonthlyEntryModel,
    parts: ['api', 'finance', 'monthly_entries'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'finance/monthly_entries',
    actionName: 'show',
    model: FinanceMonthlyEntryModel,
    parts: ['api', 'finance', 'monthly_entries', { name: 'monthlyEntryId', attributeId: null }],
    httpMethod: 'get',
    type: 'view',
  }),
}
