import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Cover } from '@/types'

const name = 'Cover'

const recordNameSingular = 'cover'
const recordNamePlural = 'covers'
const i18nKey = 'cover'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'covers'

const pageHelperKey = 'covers'

const resourcePathName = 'cover'

const attributes: Record<string, AttributeSpec> = {
  stallion: {
    kind: 'association',
    name: 'stallion',
    modelName: 'Horse',
    foreignKey: 'stallionId',
    associationPrimaryKey: 'id',
  },
  mare: {
    kind: 'association',
    name: 'mare',
    modelName: 'Horse',
    foreignKey: 'mareId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  stallionId: {
    kind: 'column',
    name: 'stallionId',
    type: 'integer',
  },
  mareId: {
    kind: 'column',
    name: 'mareId',
    type: 'integer',
  },
  coverDate: {
    kind: 'column',
    name: 'coverDate',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  defaultYear: {
    kind: 'column',
    name: 'defaultYear',
    type: null,
  },
}

const modelSpec: ModelSpec<Cover> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
