import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { MatingForm } from '@/resources/mating/form'

const MatingsEdit = () => {
  const { matingsEdit } = useLoaderQueryBuilders()
  const { record: mating } = useResourceQuery(matingsEdit)

  return (
    <Section title={`Edit ${mating.title}`}>
      <MatingForm initialValues={mating} />
    </Section>
  )
}

export default MatingsEdit
