import React from 'react'

import { Section } from '@/components/section'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const EntitiesIndex = () => {
  const { entitiesIndex } = useLoaderQueryBuilders()
  const entities = useResourceQuery(entitiesIndex)

  return <Section title="Entities" className="mt-16"></Section>
}

export default EntitiesIndex
