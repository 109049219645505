import React from 'react'

import { cn } from '@/utils'

import * as icons from './icons'
export * from './icons'

export const iconSizes: Record<string, number> = {
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
  '2xl': 28,
  '3xl': 32,
}

const Icons: Record<string, React.ReactNode> = icons

export type IconName = keyof typeof Icons

export type IconProps = React.HTMLAttributes<HTMLOrSVGImageElement> & {
  name: IconName
}

const Icon = ({ name, className, ...props }: IconProps) => {
  const IconComponent = Icons[name]

  if (!IconComponent) {
    return null
  }

  return <IconComponent className={cn('icon-sm', className)} {...props} />
}

export default Icon
