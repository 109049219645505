import { createRoutes } from '@/libs/router/route'
import { RacehorseCommentResourceRoutes } from '@/resources/racehorse_comment/routes'

export const StallionsRoutes = createRoutes({
  defaultStatus: 'preview',
  routes: [
    {
      path: 'stallions',
      children: [
        {
          index: true,
          status: 'released',
          page: 'stallions/index',
        },
        {
          path: 'prospects',
          page: 'stallions/prospects',
        },
        {
          path: 'racing',
          page: 'stallions/racing',
        },
        {
          path: 'sales',
          page: 'stallions/sales',
        },
        {
          path: 'crops',
          page: 'stallions/crops',
        },
        {
          path: ':stallionId',
          page: 'layouts/layout-outlet',
          handle: {
            routePart: [{ name: 'stallionId', attributeId: 'Horse:id' }],
          },
          children: [
            {
              path: 'racehorses',
              status: 'released',
              page: 'stallions/show/racehorses',
              children: [
                RacehorseCommentResourceRoutes.asSubRoute({
                  layoutType: 'sheet',
                }),
              ],
            },
            {
              path: 'sales',
              page: 'stallions/show/sales',
            },
            {
              path: 'crops',
              page: 'stallions/show/crops',
            },
          ],
        },
      ],
    },
  ],
})
