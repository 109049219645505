import React from 'react'

import { Tile, type TileProps } from '@/components/tile'
import { Text, type TextProps } from '@/components/ui/text'
import { cn } from '@/utils'

import Icon from './icon'

export type InfoTileProps = TileProps & {
  muted?: TextProps['muted']
}

export function iconText(iconName: string, text: string) {
  return (
    <div className="flex items-center gap-2">
      <Icon name={iconName} className="size-3" />
      <span>{text}</span>
    </div>
  )
}

const InfoTile = ({
  label,
  children,
  icon,
  muted = true,
  variant,
  className,
  ...props
}: InfoTileProps) => {
  return (
    <Tile
      {...props}
      variant={variant}
      className={cn(
        'flex-row items-center justify-between',
        variant === 'inline' && 'p-0',
        className,
      )}
    >
      <div className="flex flex-col justify-between truncate font-medium">
        <Text className="truncate uppercase" size="xs" muted={muted}>
          {label}
        </Text>
        {React.Children.count(children) > 0 && (
          <span
            className="overflow-hidden truncate text-nowrap text-sm leading-normal"
            key="children"
          >
            {children}
          </span>
        )}
      </div>
      {icon}
    </Tile>
  )
}

export { InfoTile }
