import React from 'react'

import { HorseImage } from '@/components/horse_image'
import { Text } from '@/components/ui/text'
import { useBreakpoints } from '@/hooks'
import { HorseTags } from '@/resources/horse'
import { Horse } from '@/types'
import { cn } from '@/utils'

interface HorseSuggestionProps {
  horse: Horse
  className?: string
}

const HorseSuggestion = ({
  horse,
  className,
  size = 'md',
  hideImage = false,
  hideType = false,
}: HorseSuggestionProps) => {
  const { isPhone } = useBreakpoints()

  return (
    <div className={cn('flex w-full items-center gap-2', className)}>
      {!hideImage && (
        <div className="self-bottom phone:hidden">
          <HorseImage size="sm" behavior="cover" image={horse.defaultImage} />
        </div>
      )}
      <div className={cn('flex flex-1 flex-col')}>
        <div className="flex flex-1 justify-between gap-2">
          <Text as="span" size={size} className={cn('font-serif font-semibold')}>
            {horse.name ?? 'Unnamed'}
          </Text>
          <HorseTags horse={horse} size="xs" hideType={hideType || isPhone} />
        </div>
        <Text as="span" size="xs" className={cn('w-full font-sans')}>
          {horse.sireName ?? '-'} • {horse.damName ?? '-'}
        </Text>
      </div>
    </div>
  )
}

export { HorseSuggestion }
