import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Document } from '@/types'

const name = 'Document'

const recordNameSingular = 'document'
const recordNamePlural = 'documents'
const i18nKey = 'document'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = ['horse']

const apiHelperKey = 'documents'

const pageHelperKey = 'documents'

const resourcePathName = 'document'

const attributes: Record<string, AttributeSpec> = {
  horse: {
    kind: 'association',
    name: 'horse',
    modelName: 'Horse',
    foreignKey: 'horseId',
    associationPrimaryKey: 'id',
  },
  user: {
    kind: 'association',
    name: 'user',
    modelName: 'User',
    foreignKey: 'userId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  horseId: {
    kind: 'column',
    name: 'horseId',
    type: 'integer',
  },
  userId: {
    kind: 'column',
    name: 'userId',
    type: 'integer',
  },
  dateGenerated: {
    kind: 'column',
    name: 'dateGenerated',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  category: {
    kind: 'column',
    name: 'category',
    type: 'enum',
    subtype: 'integer',
    domain: {
      values: [
        {
          value: 0,
          key: 'pedigree',
        },
        {
          value: 1,
          key: 'vet',
        },
        {
          value: 3,
          key: 'x_ray',
        },
        {
          value: 2,
          key: 'other',
        },
      ],
    },
  },
  file: {
    kind: 'attachment',
    name: 'file',
    multiple: false,
  },
}

const modelSpec: ModelSpec<Document> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
