import React from 'react'

import Icon from '@/components/icon'

export const booleanFormatter =
  ({ icon } = {}) =>
  ({ getValue }) => {
    if (icon !== undefined) {
      if (getValue()) {
        return <Icon name={icon} />
      }

      return
    }

    if (getValue() === undefined) {
      return null
    }

    return getValue() ? 'Yes' : 'No'
  }
