import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { Command, CommandEmpty, CommandInput, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { ResourceProvider } from '@/contexts'
import { ResourceRecord } from '@/libs/resource/record'

import { AutocompleteButton } from './button'
import { useAutocomplete, UseAutocompleteProps } from './hooks/use_autocomplete'
import AutocompleteItems, { RenderItemFn } from './items'

interface AutocompleteProps<T extends ResourceRecord> extends UseAutocompleteProps<T> {
  label?: string
  searchLabel?: string
  variant?: 'outline' | 'input'
  renderItem?: RenderItemFn<T>
}

const Autocomplete = <T extends ResourceRecord>({
  variant = 'outline',
  initialSelectedId,
  clientSide,
  valueAttributeName,
  filterFn,
  recordQuery,
  renderItem,
  resource,
  query,
  onSelect,
  ...props
}: AutocompleteProps<T>) => {
  const {
    resource: autocompleteResource,
    selected,
    open,
    setOpen,
    searchTerm,
    onSearchTermChange,
    clientSide: autocompleteClientSide,
    ...autocomplete
  } = useAutocomplete({
    clientSide,
    filterFn,
    recordQuery,
    valueAttributeName,
    initialSelectedId,
    resource,
    query,
    onSelect,
  })
  const { t } = useTranslation()

  const {
    label = t(autocompleteResource.model.recordNameSingular),
    searchLabel = t(autocompleteResource.model.recordNamePlural),
  } = props

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {props.children || (
          <AutocompleteButton variant={variant} {...props}>
            {autocomplete.getValue(selected)
              ? (selected?.title ?? autocomplete.getName(selected))
              : `Select ${label}`}
          </AutocompleteButton>
        )}
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <Command shouldFilter={autocompleteClientSide}>
          <CommandInput
            placeholder={`Search ${searchLabel}`}
            value={searchTerm}
            onValueChange={onSearchTermChange}
          />
          <CommandEmpty>{`No matching ${label} found`}</CommandEmpty>
          <CommandList>
            <ResourceProvider resource={autocompleteResource}>
              <Suspense>
                <AutocompleteItems selected={selected} renderItem={renderItem} {...autocomplete} />
              </Suspense>
            </ResourceProvider>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { Autocomplete, type AutocompleteProps }
