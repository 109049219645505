import { Section } from '@/components/section'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { useResourceQuery } from '@/hooks/use_resource_query'
import { TrainingReportForm } from '@/resources/training_report/form'

const TrainingReportsNew = () => {
  const { trainingReportsNew } = useLoaderQueryBuilders()
  const { record: trainingReport } = useResourceQuery(trainingReportsNew)

  return (
    <Section title="New Training Report">
      <TrainingReportForm initialValues={trainingReport} />
    </Section>
  )
}

export default TrainingReportsNew
