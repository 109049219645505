import React from 'react'

import { PageHeader } from '@/components/header'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'

const SeasonsShow = () => {
  const { seasonsShow } = useLoaderQueryBuilders()
  const { record: season } = useResourceQuery(seasonsShow)

  return <PageHeader title={season.title} />
}

export default SeasonsShow
