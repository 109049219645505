import { z } from 'zod'

export const RacehorseCommentSchema = z.object({
  horseId: z.number().int(),
  organizationId: z.number().int(),
  userId: z.number().int(),
  date: z
    .union([z.string().date().default('2024-12-19'), z.null().default('2024-12-19')])
    .default('2024-12-19'),
  tier: z.enum(['A', 'P+', 'P', 'B']).default('B'),
  comment: z.union([z.string(), z.null()]).optional(),
})
