import { createRoutes } from '@/libs/router/route'

export const FinanceRoutes = createRoutes({
  routes: [
    {
      path: 'finance',
      page: 'finance/index',
    },
  ],
})
