// JsFromRoutes CacheKey 173440136d7292f0b279cfc8d13e89d5
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { definePathHelper } from '@/libs/routes/path-helper'

export const stallionBreedingEstimatesApi = {
  index: definePathHelper('get', '/api/stallion_breeding_estimates'),
  create: definePathHelper('post', '/api/stallion_breeding_estimates'),
  new: definePathHelper('get', '/api/stallion_breeding_estimates/new'),
  edit: definePathHelper('get', '/api/stallion_breeding_estimates/:id/edit'),
  show: definePathHelper('get', '/api/stallion_breeding_estimates/:id'),
  update: definePathHelper('patch', '/api/stallion_breeding_estimates/:id'),
  destroy: definePathHelper('delete', '/api/stallion_breeding_estimates/:id'),
}
