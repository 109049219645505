import { type Resource } from '@/libs/resource/types'
import { compact, deepMerge } from '@/utils'
import { type RowData } from '@tanstack/react-table'

import type {
  DataTableProps,
  TableBuilder,
  TableBuilderConfig,
  TableBuilderSpec,
  TableDefinition,
} from './types'

import { columnSpecHelper } from './column-helper'
import { tableBuilder } from './table-builder'
import { validateColumnDefs } from './utils'

export const defineTable = <TData extends RowData>(
  resource: Resource,
  include: Record<string, TableDefinition<TData>>,
  buildSpec: (tbl: TableBuilder<TData>, props) => TableBuilderSpec<TData>,
): TableDefinition<TData> => {
  /*
   * Instantiates a builder and produces a usable spec
   */
  const build = (cfg: TableBuilderConfig, props: Record<string, unknown>) => {
    const tbl = tableBuilder(resource, include, cfg)

    const spec = buildSpec(tbl, props)

    return { tbl, spec }
  }

  /**
   * Converts the final table spec to usable props
   **/
  const props = (props: DataTableProps = {}, buildProps: Record<string, unknown> = {}) => {
    const {
      tbl,
      spec: { columns, filterPresets, ...spec },
    } = build({ path: '.' }, buildProps)

    const filteredColumns = compact(columns)

    const specifiedIds = new Set(filteredColumns.map((c) => c.colId))

    const dependencyColumns = tbl.currentSpec.columns
      .filter((c) => !specifiedIds.has(c.colId))
      .map(({ opts, ...c }) => ({
        ...c,
        opts: {
          ...opts,
          isVisible: false,
          enableHiding: false,
          enableColumnFilter: false,
        },
      }))

    const columnDefs = [...filteredColumns, ...dependencyColumns].map((col) =>
      columnSpecHelper(col),
    )

    const validity = validateColumnDefs(columnDefs)

    if (!validity) {
      throw new Error('Invalid table definition')
    }

    return deepMerge(
      {
        ...spec,
        presets: {
          filter: {
            presets: filterPresets,
          },
        },
        columns: columnDefs,
        resource: tbl.resource,
      },
      props,
    )
  }

  return {
    resource,
    build,
    props,
  }
}
