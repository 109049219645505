import { createRoutes } from '@/libs/router/route'
import { ShortlistResource } from '@/resources/shortlist/resource'

export const ShortlistResourceRoutes = createRoutes({
  resource: ShortlistResource,
  defaultStatus: 'planned',
  routes: [
    {
      path: 'shortlists',
      children: [
        {
          index: true,
          page: 'shortlists/index',
          query: ShortlistResource.query.index,
        },
        {
          path: 'new',
          page: 'shortlists/new',
          query: ShortlistResource.query.new,
        },
        {
          path: ':id',
          query: ShortlistResource.query.show,
          children: [
            {
              index: true,
              page: 'shortlists/show',
            },
            {
              path: 'edit',
              page: 'shortlists/edit',
            },
            {
              path: 'destroy',
              page: 'shortlists/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const ShortlistRoutes = ShortlistResourceRoutes
