import { Action } from '@/components/actions'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { AppraisalCard } from '@/resources/appraisal/card'
import { appraisalsRoutes } from '@/routes'

const AppraisalsIndex = () => {
  const { appraisalsIndex } = useLoaderQueryBuilders()
  const appraisals = useResourceQuery(appraisalsIndex)

  if (appraisals.records.length === 0) {
    return (
      <div className="mb-4 mt-12 flex w-full items-center justify-between">
        <Text as="h3">No appraisals yet.</Text>
        <div>
          <Action route={appraisalsRoutes.new} name="new" variant="primary" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4 flex w-full items-center justify-between">
        <Text as="h3">Appraisals</Text>
        <Action route={appraisalsRoutes.new} name={'new'} variant="primary" />
      </div>
      <div className="flex flex-col gap-2">
        <AppraisalCard
          id={appraisals.records[0].id}
          appraisal={appraisals.records[0]}
          previousAppraisal={appraisals.records[1]}
          latest
        />
        {appraisals.records.slice(1).map((appraisal) => (
          <AppraisalCard key={appraisal.id} appraisal={appraisal} label="" muted />
        ))}
      </div>
    </>
  )
}

export default AppraisalsIndex
