import { createRoutes } from '@/libs/router/route'
import { AccountRoutes } from '@/resources/account/routes'
import { AppraisalRoutes } from '@/resources/appraisal/routes'
import { BroodmareProspectRoutes } from '@/resources/broodmare_prospect/routes'
import { ConformationNoteRoutes } from '@/resources/conformation_note/routes'
import { CoverRoutes } from '@/resources/cover/routes'
import { DNASetRoutes } from '@/resources/dna_set/routes'
import { DocumentRoutes } from '@/resources/document/routes'
import { EntityRoutes } from '@/resources/entity/routes'
import { HorseDetailRoutes } from '@/resources/horse_detail/routes'
import { HorseRoutes } from '@/resources/horse/routes'
import { ImageRoutes } from '@/resources/image/routes'
import { MatingRoutes } from '@/resources/mating/routes'
import { OrganizationHorseRoutes } from '@/resources/organization_horse/routes'
import { OrganizationRoutes } from '@/resources/organization/routes'
import { PregnancyCheckRoutes } from '@/resources/pregnancy_check/routes'
import { RacehorseCommentRoutes } from '@/resources/racehorse_comment/routes'
import { SaleRecordDetailRoutes } from '@/resources/sale_record_detail/routes'
import { SaleRecordRoutes } from '@/resources/sale_record/routes'
import { SaleRoutes } from '@/resources/sale/routes'
import { SeasonRoutes } from '@/resources/season/routes'
import { ShareRoutes } from '@/resources/share/routes'
import { ShortlistRoutes } from '@/resources/shortlist/routes'
import { StudFeeRoutes } from '@/resources/stud_fee/routes'
import { TrainingReportRoutes } from '@/resources/training_report/routes'
import { UserRoutes } from '@/resources/user/routes'
import { VideoRoutes } from '@/resources/video/routes'

import { BloodstockRoutes } from './bloodstock'
import { BooksRoutes } from './books'
import { BroodmaresRoutes } from './broodmares'
import { FinanceRoutes } from './finance'
import { OperationsRoutes } from './operations'
import { StallionsRoutes } from './stallions'

export const routes = createRoutes({
  routes: [
    {
      id: 'root',
      path: '/',
      page: 'layouts/base',
      children: [
        {
          index: true,
          page: 'home/index',
        },
        AccountRoutes,
        AppraisalRoutes,
        BloodstockRoutes,
        BooksRoutes,
        BroodmaresRoutes,
        BroodmareProspectRoutes,
        ConformationNoteRoutes,
        CoverRoutes,
        DNASetRoutes,
        DocumentRoutes,
        EntityRoutes,
        FinanceRoutes,
        HorseRoutes,
        HorseDetailRoutes,
        ImageRoutes,
        MatingRoutes,
        OperationsRoutes,
        OrganizationRoutes,
        OrganizationHorseRoutes,
        PregnancyCheckRoutes,
        RacehorseCommentRoutes,
        SaleRoutes,
        SaleRecordRoutes,
        SaleRecordDetailRoutes,
        SeasonRoutes,
        ShareRoutes,
        ShortlistRoutes,
        StallionsRoutes,
        StudFeeRoutes,
        TrainingReportRoutes,
        UserRoutes,
        VideoRoutes,
      ],
    },
    {
      id: 'public',
      page: 'layouts/root',
      children: [
        {
          path: '/login',
          page: 'login',
        },
      ],
    },
  ],
})
