import { PageHeader } from '@/components/header'

function StallionsSales() {
  return (
    <>
      <PageHeader title="Stallions Sales" />
    </>
  )
}

export default StallionsSales
