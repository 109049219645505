import { type Table } from '@tanstack/react-table'

interface DataTableActionsProps {
  table: Table<TData>
}

export function DataTableActions({ table }: DataTableActionsProps) {
  const actions = table.options.meta.tableActions ?? []
  return actions.map((action) => action(table))
}
