import { createRoutes } from '@/libs/router/route'
import { HorseResourceRoutes } from '@/resources/horse/routes'
import { SeasonResource } from '@/resources/season/resource'

import { CoverResourceRoutes } from '../cover/routes'

export const SeasonResourceRoutes = createRoutes({
  resource: SeasonResource,
  routes: [
    {
      path: 'seasons',
      children: [
        {
          index: true,
          page: 'seasons/index',
          query: SeasonResource.query.index,
        },
        {
          path: 'new',
          page: 'seasons/new',
          query: SeasonResource.query.new,
        },
        {
          path: ':id',
          query: SeasonResource.query.show,
          children: [
            {
              index: true,
              page: 'seasons/show',
            },
            {
              path: 'edit',
              query: SeasonResource.query.edit,
              page: 'seasons/edit',
            },
            {
              path: 'destroy',
              page: 'seasons/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const SeasonRoutes = createRoutes({
  resource: SeasonResource,
  routes: [
    {
      path: 'seasons',
      page: 'seasons/index',
      query: SeasonResource.query.index,
      children: [
        {
          path: 'new',
          page: 'seasons/new',
          query: SeasonResource.query.new,
          handle: { layoutType: 'sheet', sheetSize: 'xl' },
          children: [CoverResourceRoutes.asSubRoute({ layoutType: 'sheet' })],
        },
        {
          path: ':id',
          query: SeasonResource.query.show,
          handle: { layoutType: 'sheet', sheetSize: 'xl' },
          children: [
            {
              index: true,
              page: 'seasons/show',
            },
            {
              path: 'edit',
              query: SeasonResource.query.edit,
              page: 'seasons/edit',
              handle: { layoutType: 'sheet', sheetSize: 'xl' },
              children: [CoverResourceRoutes.asSubRoute({ layoutType: 'sheet' })],
            },
            {
              path: 'destroy',
              page: 'seasons/destroy',
            },
          ],
        },
        HorseResourceRoutes.asSubRoute({ layoutType: 'sheet' }),
      ],
    },
  ],
})
