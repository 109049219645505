import { useParams } from 'react-router-dom'

import { Action } from '@/components/actions'
import { useDataTable } from '@/components/data-table/hooks'
import { PageHeader } from '@/components/header'
import { useAuth } from '@/contexts/auth'
import { useResourceQuery } from '@/hooks'
import { EnumDomainOption } from '@/libs/resource/domain'
import { dbtSeasonDetailTable } from '@/resources/dbt/season_detail'
import { SeasonResource } from '@/resources/season'
import { StudFeeResource } from '@/resources/stud_fee'
import { seasonsRoutes } from '@/routes'
import { humanize } from '@/utils'
import { calculateCrossTab, flattenData, uniqueValuesAtKey } from '@/utils/data'

const BooksShow = () => {
  const { isSales, isSalesAdmin, isCoalition } = useAuth()
  const { bookYear } = useParams()

  const query = SeasonResource.query.index
    .filter('year', bookYear)
    .filter('onlySeasons', true, 'scope')

  const tableProps = dbtSeasonDetailTable.props(
    {
      query,
      clientSide: bookYear >= 2025,
      verticalScroll: bookYear >= 2025,
      defaultPageSize: 100,
      meta: {
        tableActions: [
          () => (
            <Action
              route={seasonsRoutes.new.withOptions({ bookYear })}
              name="new"
              variant="secondary"
              size="sm"
              label="New Season"
            />
          ),
        ],
      },
    },
    { year: bookYear, isSales, isSalesAdmin, isCoalition, isSeasons: true },
  )

  const { table } = useDataTable(tableProps)

  const { records: stallions } = useResourceQuery(
    StudFeeResource.query.index.scope('winstar', bookYear),
  )

  const stallionNames = uniqueValuesAtKey(stallions, 'title')
  const contractStatusValues = uniqueValuesAtKey<EnumDomainOption>(
    SeasonResource.model.attributes.contractStatus.domain.values,
    'key',
  ) as string[]
  const humanizedContractStatusValues = contractStatusValues.map((value: string) => humanize(value))

  const flattened = table.getRowModel().rows
    ? flattenData(table.getRowModel().rows, 'stallion.name', 'contractStatus')
    : []

  const transformedData = calculateCrossTab(flattened, 'stallion.name', 'contractStatus', true)

  const updatedData = transformedData.map((item) => {
    let contractStatus

    if (item.contractStatus !== undefined) {
      if (typeof item.contractStatus === 'number') {
        contractStatus = humanizedContractStatusValues[item.contractStatus]
      } else {
        contractStatus = item.contractStatus
      }
    } else {
      contractStatus = 'No Status'
    }

    return {
      ...item,
      contractStatus,
    }
  })

  return (
    <>
      <PageHeader title="Books" />

      {/*
      <Card className="my-4 h-[40rem] w-[55rem]">
        <HeatMap
          data={updatedData}
          x="contractStatus"
          y="stallion.name"
          value="count"
          xCategories={[...humanizedContractStatusValues, 'No Status', 'Total']}
          yCategories={[...stallionNames, 'Total']}
          xAxisProps={{ angle: 30, textAnchor: 'start' }}
          yAxisProps={{ width: 145, reversed: true }}
          margin={{ top: 10, right: 20, bottom: 40, left: 0 }}
        />
      </Card>
      */}
    </>
  )
}

export default BooksShow
