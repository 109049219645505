import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { DBTRacehorseDetail } from '@/types'

const name = 'DBTRacehorseDetail'

const recordNameSingular = 'dbtRacehorseDetail'
const recordNamePlural = 'dbtRacehorseDetails'
const i18nKey = 'dbt::RacehorseDetail'

const primaryKey = 'racehorseDetailId'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'dbtRacehorseDetails'

const pageHelperKey = 'dbtRacehorseDetails'

const resourcePathName = 'dbt_racehorse_detail'

const attributes: Record<string, AttributeSpec> = {
  racehorseDetailId: {
    kind: 'column',
    name: 'racehorseDetailId',
    type: 'integer',
  },
  contextFilter: {
    kind: 'column',
    name: 'contextFilter',
    type: 'boolean',
  },
  defaultFilter: {
    kind: 'column',
    name: 'defaultFilter',
    type: 'boolean',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
  horseDetailId: {
    kind: 'column',
    name: 'horseDetailId',
    type: 'integer',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  horse: {
    kind: 'submodel',
    name: 'horse',
    modelName: 'DBTHorse',
    columns: [
      'horseId',
      'name',
      'age',
      'nameAgeSex',
      'yob',
      'sex',
      'foalLocation',
      'sireId',
      'sireName',
      'sireYob',
      'damId',
      'damName',
      'damYob',
      'damSireId',
      'horseType',
    ],
  },
  damSire: {
    kind: 'submodel',
    name: 'damSire',
    modelName: 'DBTHorse',
    columns: ['horseId', 'name', 'yob', 'sex', 'foalLocation'],
  },
  careerRaceSummary: {
    kind: 'submodel',
    name: 'careerRaceSummary',
    modelName: 'DBTCareerRaceSummary',
    columns: [
      'starts',
      'wins',
      'places',
      'shows',
      'totalEarnings',
      'averageEarnings',
      'bestClass',
      'bestClassTitle',
      'lastRacedDate',
      'lastWorkoutDate',
      'daysSinceLastRace',
      'daysSinceLastWork',
    ],
  },
  lastSaleResult: {
    kind: 'submodel',
    name: 'lastSaleResult',
    modelName: 'DBTSaleResult',
    columns: [
      'saleId',
      'saleResultId',
      'saleName',
      'saleHost',
      'saleTitle',
      'reservePrice',
      'salePrice',
      'salePriceSold',
    ],
  },
  comment: {
    kind: 'submodel',
    name: 'comment',
    modelName: 'RacehorseComment',
    columns: [
      'id',
      'horseId',
      'organizationId',
      'userId',
      'date',
      'tierNumeric',
      'tier',
      'comment',
      'createdAt',
      'updatedAt',
    ],
  },
}

const modelSpec: ModelSpec<DBTRacehorseDetail> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
