import { defineTable } from '@/components/data-table/builder'
import { mappedSorter } from '@/components/data-table/sort'
import { financeAccountTable } from '@/resources/finance/account'
import { financeCustomerTable } from '@/resources/finance/customer'
import { financeDepartmentTable } from '@/resources/finance/department'
import { financeEmployeeTable } from '@/resources/finance/employee'
import { financeLocationTable } from '@/resources/finance/location'
import { financeVendorTable } from '@/resources/finance/vendor'

import { FinanceMonthlyEntryResource } from './resource'

export const financeMonthlyEntryTable = defineTable(
  FinanceMonthlyEntryResource,
  {
    account: financeAccountTable,
    customer: financeCustomerTable,
    department: financeDepartmentTable,
    employee: financeEmployeeTable,
    location: financeLocationTable,
    vendor: financeVendorTable,
  },
  (tbl) => ({
    columns: [
      tbl.expanderCol(),

      tbl.col.account.cashFlowGroup(),
      tbl.col.department.title(),

      tbl.col.monthlyEntryId(),
      tbl.col.scenario(),
      tbl.col.version(),
      tbl.col.segment({
        sortingFn: mappedSorter({
          'Office Operations': 0,
          'Farm Operations': 1,
          'Broodmare Band': 2,
          'Racing Stable': 3,
          'Stallion Portfolio': 4,
          Other: 5,
        }),
      }),
      tbl.col.versionLabel(),
      tbl.col.entityId(),
      tbl.col.locationId(),
      tbl.col.accountNo(),
      tbl.col.departmentId(),
      tbl.col.employeeId(),
      tbl.col.customerId(),
      tbl.col.vendorId(),
      tbl.col.classId(),
      tbl.col.bloodstockSituationId(),
      tbl.col.bloodstockEntityId(),
      tbl.col.equineActivityGroup({
        sortingFn: mappedSorter({
          'Operating Revenue': 0,
          'Operating Expenses': 1,
          'Bloodstock Sales': 2,
          'Bloodstock Purchases': 3,
          Other: 4,
        }),
      }),
      tbl.col.equineActivitySubgroup(),
      tbl.col.projectGroup(),
      tbl.col.project(),
      tbl.col.subject(),
      tbl.col.detail(),
      tbl.col.amount(),
      tbl.col.amountNb(),
      tbl.col.amountCf(),
      tbl.col.createdAt(),
      tbl.col.updatedAt(),
    ],
  }),
)
