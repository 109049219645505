import HorsePlaceholder from '@/assets/icons/horse_placeholder.svg'
import { Link } from '@/components/link'
import { Image } from '@/components/ui/image'
import { Tag } from '@/components/ui/tag'
import { useResourceQuery } from '@/hooks'
import { ImageResource } from '@/resources/image'
import { VideoResource } from '@/resources/video'
import { imagesRoutes } from '@/routes'
import { Horse, Image as ImageType } from '@/types'
import { cva, VariantProps } from '@/utils'

import { NewAction } from './resource'

const horseImagePlaceholderVariants = cva({
  base: 'p-4',
  variants: {
    size: {
      original: 'h-full w-auto',
      xs: 'p-0.5',
      sm: 'p-1',
      md: 'p-2',
    },
  },
})

const Placeholder = ({ size }: VariantProps<typeof horseImagePlaceholderVariants>) => (
  <HorsePlaceholder
    className={horseImagePlaceholderVariants({
      size,
      class: 'h-full w-full fill-muted-foreground',
    })}
  />
)

const AgeTag = ({ image }: { image: ImageType | undefined }) => {
  if (!image?.ageMonths || !Number.isFinite(image.ageMonths)) {
    return null
  }

  return (
    <Tag size="sm" className="absolute right-0 top-0 z-[5] m-2 bg-dark px-1 text-white">
      {image.ageMonths} mo
    </Tag>
  )
}

const NewButton = () => {
  return (
    <NewAction
      resource={ImageResource}
      variant="primary"
      className="absolute bottom-2 left-2 z-[5] phone:hidden"
      size="lg"
      iconOnly
    />
  )
}

const MediaCount = ({ horseId }) => {
  const { records: images } = useResourceQuery(
    ImageResource.query.index
      .filter('horse_id', horseId)
      .sort('date', 'desc')
      .sort('created_at', 'desc'),
  )
  const { records: videos } = useResourceQuery(
    VideoResource.query.index
      .filter('horse_id', horseId)
      .sort('date', 'desc')
      .sort('created_at', 'desc'),
  )
  const mediaCount = (images.length ?? 0) + (videos.length ?? 0)
  if (mediaCount > 0) {
    return (
      <Link
        size="sm"
        variant="primary"
        className="absolute bottom-2 left-2 z-[5] rounded-sm bg-dark px-2 text-white"
        noChevron
        route={imagesRoutes.index.withOptions({ horseId })}
      >
        +{mediaCount}
      </Link>
    )
  }
}

const imageSizeVariants: Record<string, string> = {
  container: 'original',
  xs: 'sm',
  sm: 'sm',
  md: 'md',
  lg: 'md',
  xl: 'lg',
}

interface HorseImageProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: ImageType['size']
  variantName?: string
  image?: ImageType
  action?: React.ReactNode
  children?: React.ReactNode
  horseId?: Horse['id']
  hideTag?: boolean
  hideButton?: boolean
}

const HorseImage: React.FC<HorseImageProps> = ({
  size = 'original',
  variantName = null,
  image,
  action,
  children,
  horseId,
  ...props
}) => {
  const srcUrl = image?.variants ? image.variants[variantName ?? imageSizeVariants[size]] : null

  const isSmall =
    ['xs', 'sm', 'md'].includes(size) || (variantName && ['xs', 'sm', 'md'].includes(variantName))

  const { hideTag = isSmall, hideButton = isSmall } = props

  const imagePresent = image ? Object.keys(image).length : null
  return (
    <Image
      src={srcUrl}
      size={size}
      imageWidth={image?.metadata?.width}
      imageHeight={image?.metadata?.height}
      alt={image?.horse ? image.horse.name : horseId}
      placeholder={<Placeholder size={variantName ?? size} />}
      {...props}
    >
      {!hideTag ? <AgeTag image={image} /> : null}
      {imagePresent && horseId && !isSmall ? <MediaCount horseId={horseId} /> : null}
      {!srcUrl && !hideButton ? <NewButton /> : null}
      {action ? action : null}
      {children}
    </Image>
  )
}

export { HorseImage }
