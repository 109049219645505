import { PanelContent } from '@/components/panel/content'
import { PanelItem } from '@/components/panel/item'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { bloodstockRoutes, broodmaresRoutes, matingsRoutes } from '@/routes'

const panelItems = [
  { label: 'Broodmares', route: bloodstockRoutes.broodmares },
  { label: 'Matings', route: matingsRoutes.index },
  { label: 'Claiming', route: broodmaresRoutes.claiming },
]

export const BroodmarePanel = () => {
  return (
    <PanelContent title={'Broodmares'}>
      <SidebarGroup>
        <SidebarGroupLabel>Dashboard</SidebarGroupLabel>
        <SidebarMenu>
          {panelItems.map((item) => (
            <PanelItem key={item.label} {...item} />
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </PanelContent>
  )
}
