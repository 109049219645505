import { initReactI18next } from 'react-i18next'

import { humanize } from '@/utils'
import i18n from 'i18next'

// import translations from './translations.json'
import translations from './translations/translations.json'

// It is necessary to merge all locales into one object

i18n.use(initReactI18next).init({
  lng: 'en',
  parseMissingKeyHandler: (key, defaultValue) => {
    if (defaultValue !== undefined) {
      return defaultValue
    }

    return humanize(key)
  },

  keySeparator: '.',
  fallbackLng: false,

  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translation: translations.en,
    },
  },
})

export default i18n
