import type { ModelSpec, AttributeSpec, Scope } from '@/libs/resource/types'
import type { Budget } from '@/types'

const name = 'Budget'

const recordNameSingular = 'budget'
const recordNamePlural = 'budgets'
const i18nKey = 'budget'

const primaryKey = 'id'
const searchKey = ''

const parents: Scope[] = []

const apiHelperKey = 'budgets'

const pageHelperKey = 'budgets'

const resourcePathName = 'budget'

const attributes: Record<string, AttributeSpec> = {
  organization: {
    kind: 'association',
    name: 'organization',
    modelName: 'Organization',
    foreignKey: 'organizationId',
    associationPrimaryKey: 'id',
  },
  id: {
    kind: 'column',
    name: 'id',
    type: 'integer',
  },
  organizationId: {
    kind: 'column',
    name: 'organizationId',
    type: 'integer',
  },
  version: {
    kind: 'column',
    name: 'version',
    type: 'date',
  },
  createdAt: {
    kind: 'column',
    name: 'createdAt',
    type: 'datetime',
  },
  updatedAt: {
    kind: 'column',
    name: 'updatedAt',
    type: 'datetime',
  },
  active: {
    kind: 'column',
    name: 'active',
    type: 'boolean',
  },
}

const modelSpec: ModelSpec<Budget> = {
  name,
  recordNameSingular,
  recordNamePlural,
  i18nKey,

  resourcePathName,

  primaryKey,
  searchKey,

  apiHelperKey,
  pageHelperKey,

  parents,

  attributes,
}

export default modelSpec
