import React from 'react'

import { PageHeader } from '@/components/header'
import { EditAction } from '@/components/resource'
import { useBreakpoints, useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { HorseTags, FiveCrossAction, PedigreeAction, HorseResource } from '@/resources/horse'

const HorsePageHeader = () => {
  const { isPhone } = useBreakpoints()

  const { horsesShow } = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(horsesShow)

  return (
    <div className="my-4 flex items-center gap-4">
      <PageHeader
        className="w-full"
        title={horse.name}
        badges={!isPhone && [<HorseTags key="badges" size="lg" className="px-4" horse={horse} />]}
        actions={
          !isPhone && [
            <FiveCrossAction key="5-cross" variant="secondary" horseId={horse.id} />,
            <PedigreeAction horseId={horse.id} key="pedigree" variant="secondary" />,
            <EditAction key="edit" resource={HorseResource} variant="primary" />,
          ]
        }
      />
    </div>
  )
}

export { HorsePageHeader }
