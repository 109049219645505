import React from 'react'

import { InlineInfoTile } from '@/components/inline_info_tile'
import { Link } from '@/components/link'
import { NewAction } from '@/components/resource'
import { Card, CardActions, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Sale } from '@/types'
import { dateWithinRange, formatDate } from '@/utils'

import { SaleResource } from './resource'

export const SaleContent = ({ sale }: { sale: Sale }) => {
  const today = new Date()

  const startDate = new Date(sale.startDate)
  const endDate = new Date(sale.endDate)

  // Calculate the two-week offset date
  const offsetStartDate = new Date(startDate)
  offsetStartDate.setDate(offsetStartDate.getDate() - 14)

  const isActiveSale = dateWithinRange(today, offsetStartDate, endDate)

  return (
    <>
      <CardHeader>
        <CardTitle className="flex items-center">
          {sale.title}
          {isActiveSale ? (
            <span className="relative flex size-3">
              <span className="absolute inline-flex size-full animate-ping rounded-full bg-green opacity-75"></span>
              <span className="relative inline-flex size-3 rounded-full bg-green"></span>
            </span>
          ) : null}
        </CardTitle>
        <CardActions></CardActions>
      </CardHeader>
      <div className="mt-4 flex flex-col gap-4">
        <div className="flex justify-between">
          <InlineInfoTile
            label={
              sale.startDate === sale.endDate
                ? formatDate(sale.startDate, 'MMMM do')
                : `${formatDate(sale.startDate, 'MMMM do')} - ${formatDate(sale.endDate, 'MMMM do')}`
            }
            icon="Calendar"
          />
          <InlineInfoTile label={sale.saleType} />
        </div>
        <div className="flex justify-between">
          <InlineInfoTile label={sale.location} icon="Map" />
        </div>
      </div>
    </>
  )
}

export const SaleCard = ({ sale, className }: { sale: Sale; className?: string }) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>
          <Link route={SaleResource.routes.show.withOptions({ saleId: sale.id })}>
            {sale.title}
          </Link>
        </CardTitle>
        <CardActions>
          <NewAction resource={SaleResource} iconOnly />
        </CardActions>
      </CardHeader>
      <CardContent>
        <SaleContent sale={sale} />
      </CardContent>
    </Card>
  )
}
