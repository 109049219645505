import { noopQuery } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { Resource } from '@/libs/resource/resource'
import { PrimaryKeyValue } from '@/libs/resource/types'

import { useResourceQuery } from './use_resource_query'

export interface UseResourceRecordProps<T extends ResourceRecord = ResourceRecord> {
  resource: Resource<T>
  id?: PrimaryKeyValue
  initialValues?: Partial<T>
  fetchNew?: boolean
}

export const useResourceRecord = <T extends ResourceRecord>({
  resource,
  id,
  initialValues = {},
  fetchNew = false,
}: UseResourceRecordProps<T>) => {
  if (resource === undefined) {
    throw new Error('Must provide a resource to `useResourceRecord`')
  }

  const showQueryOptions = id != null ? { pathParams: { [resource.model.primaryKey]: id } } : {}

  const showQuery = resource.query.show.withOptions(showQueryOptions)

  const newQuery =
    resource.query.new && fetchNew
      ? resource.query.new.withOptions({
          query: initialValues,
        })
      : noopQuery

  const recordQuery = id != null ? showQuery : newQuery

  if (recordQuery.routeName === 'noop') {
    // console.warn('`useResourceRecord` had to issue a \'noop\' query for the resource', resource.name)
  }

  const record: T = useResourceQuery(recordQuery)

  return {
    ...record,
    recordQuery,
  }
}
